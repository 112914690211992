import intl from 'react-intl-universal';
function getFormFields() {
  return [
    {
      field: 'username',
      rules: [],
      editable: false,
      icon: 'user'
    },
    {
      field: 'realname',
      rules: [{
        required: true,
        pattern: /^\S{1,20}$/g,
        message: intl.get('USERNAME_LENGTH')
      }],
      maxLength:20,
      editable: true,
      icon: 'user'
    },
    {
      field: 'register_at',
      rules: [],
      editable: false,
      icon: 'calendar'
    },
    {
      field: 'email',
      rules: [
        { type: 'email', message: intl.get('EMAIL_NOT_VALID') },
        { required: true, message: intl.get('EMAIL_NOT_EMPTY') }
      ],
      editable: true,
      icon: 'mail'
    }
  ];
}

export default getFormFields;
