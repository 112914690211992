import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setCheckInColums, setCheckInRejectColums } from '../../utils/displayUtils';
import { checkInTabs } from '../../static/defaultTopTabs';
import SearchTab from './SearchTab';
import intl from 'react-intl-universal';
import { Table, Tabs, Pagination } from 'antd';
import {
  updateChckInQueryInfo,
  updateChckInTab,
  updateChckInType,
  fetchCheckInfoList,
  setChckInInfo,
  setHisInfo
} from '../../actions/checkInAction';
import style from './index.less';
import { formCheckTableInfo,setReduxAge } from '../../utils/dataUtils';
import { cloneDeep } from 'lodash';
const { TabPane } = Tabs;

class CheckTable extends Component {
  constructor() {
    super();
    this.state = {
      dataList: [],
      tableH: 0
    };
  }
  componentDidMount() {
    const { queryInfo } = this.props;
    this.props.updateChckInQueryInfo(queryInfo);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.checkInfoList !== this.props.checkInfoList) {
      let data = formCheckTableInfo(this.props.checkInfoList).data;
      this.setState({ dataList: data }, () => {
        let t = document.querySelector('#checkTable' + this.props.tabName);
        this.setState({
          tableH: t ? t.offsetHeight : 0
        });
      });
    }
  }
  handleTableChange = (pagination, filters, sorter) => {
    const { queryInfo } = this.props;
    this.props.updateChckInQueryInfo({
      page: queryInfo.page,
      sortBy: sorter.columnKey,
      sortAsc: sorter.columnKey === queryInfo.sortBy ? !queryInfo.sortAsc : false
    });
  };
  changePageSize = (current, size) => {
    this.props.updateChckInQueryInfo({ limit: size });
  };
  changeTab = (key) => {
    this.props.updateChckInTab(key);
  };

  getUpdateInfo(record) {
    const { checkInfoList } = this.props;
    let info = cloneDeep(checkInfoList.items.filter((item) => item.apply_id === record.apply_id)[0]);
    info = setReduxAge(info);
    this.props.setHisInfo(info.his);
    this.props.setChckInInfo(info);
    this.props.updateChckInType('update');
    this.props.updateData(info);
  }



  render() {
    let h = document.body.clientHeight - 289;
    let minH = document.body.clientHeight - 235;
    if (document.querySelector('#checkFormInner')) {
      minH = document.querySelector('#checkFormInner').offsetHeight - 152.5;
      h = document.querySelector('#checkFormInner').offsetHeight - 206.5;
    }

    const { queryInfo, tabName, tabCounts, checkInfoList } = this.props;
    const checkInColums = tabName === '0' ? setCheckInColums() : setCheckInRejectColums();
    const { tableH } = this.state;
    const data =
      this.props.checkInfoList.items.length > 0
        ? formCheckTableInfo(this.props.checkInfoList).data
        : [];
    return (
      <div>
        <Tabs type="card" hideAdd={true} activeKey={tabName} onTabClick={this.changeTab}>
          {checkInTabs.map((tab, idx) => {
            return (
              <TabPane tab={intl.get(tab.text) + '(' + tabCounts[idx] + ')'} key={idx}>
                <SearchTab />
                <Table
                  id={'checkTable' + idx}
                  columns={checkInColums}
                  dataSource={data}
                  rowKey={(record) => record.key}
                  key={(record) => record.key}
                  scroll={{
                    x: checkInColums.length > 11 ? 'auto' : null,
                    y: tableH > minH  ? h : null
                  }}
                  onChange={this.handleTableChange}
                  rowClassName={(record, index) =>
                    record.is_urgent ? style.row__quick : style.row__general
                  }
                  pagination={false}
                  style={{ minHeight: minH, maxHeight: minH }}
                  onRow={(record) => ({
                    onClick: this.getUpdateInfo.bind(this, record)
                  })}
                />
              </TabPane>
            );
          })}
        </Tabs>
        <Pagination
          current={queryInfo.page + 1}
          showTotal={(total) =>
            ` ${intl.get('CHECK_LIST_TOTAL_COUNT', { total: checkInfoList.total })}`
          }
          onChange={(page) => {
            this.props.updateChckInQueryInfo({ page: page - 1 });
          }}
          size="small"
          pageSize={queryInfo.limit}
          total={checkInfoList.total}
          showQuickJumper={true}
          showSizeChanger={true}
          onShowSizeChange={this.changePageSize}
          style={{ padding: 20, float: 'right' }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    queryInfo: state.checkIn.queryInfo,
    tabName: state.checkIn.tabName,
    tabCounts: state.checkIn.tabCounts,
    checkInfoList: state.checkIn.checkInfoList
  };
};

export default connect(mapStateToProps, {
  updateChckInQueryInfo,
  updateChckInTab,
  updateChckInType,
  fetchCheckInfoList,
  setChckInInfo,
  setHisInfo
})(CheckTable);
