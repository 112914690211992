import React, { Component } from 'react';
import Navigator from './Navigator';
import style from './index.less';
import { connect } from 'react-redux';

class NavigatorPanel extends Component {
  render() {
    const width = 254;
    const height = 254;

    const zIndex =
      window.osdviewer &&
      (window.osdviewer.navigator.element.style.zIndex = this.props.navigatorVisibility
        ? '1'
        : '-1');
    return (
      <div className={style.panel} style={{ zIndex, width,height}}>
        <Navigator width={width} height={height}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  navigatorVisibility: state.toolStatus.navigatorVisibility,
});

export default connect(mapStateToProps, null)(NavigatorPanel);
