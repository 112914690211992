import { Button, Input, Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import style from './index.less';
import intl from 'react-intl-universal';
import { getSwarmList, joinSwarm } from '../../../actions/configActions';
import { isValidIP, isValidHost } from '../../../utils/regUtils';
// const test = {
//   accepted: [{ name: 'test' }, { name: 'test' }],
//   applying: [{ name: 'test' }, { name: 'test' }],
//   leaved: [{ name: 'test' }, { name: 'test' }],
//   rejected: [{ name: 'test' }, { name: 'test' }],
// };

function ModeHostPort(props) {
  const [keys, setKeys] = useState([]);
  const [applyList, setApplyList] = useState({});
  const [hostPort, setHostPort] = useState('');
  const [ipError, setIpError] = useState('');
  useEffect(() => {
    getList();
  }, [props.modevalue]);

  const getList = () => {
    props.getSwarmList().then((res) => {
      let keys = Object.keys(res);
      setKeys(keys);
      setApplyList(res);
      // let keys = Object.keys(test);
      // setKeys(keys);
      // setApplyList(test);
    });
  };

  const applyJoin = () => {
    const list = hostPort.split(':');
    if (list.length < 2 || !isValidIP(list[0]) || !isValidHost(list[1])) {
      setIpError(intl.get('IP_ERROR'));
    } else {
      props.joinSwarm({ host: list[0], port: list[1] }).then((res) => {
        if (typeof res === 'number') {
          setIpError(intl.get('ERROR_' + res));
        } else {
          setIpError('');
          getList();
        }
      });
    }
  };

  return (
    <div className={style.ip_host_part}>
      <Row>
        <Col span={12} offset={1}>
          <Input onChange={(e) => setHostPort(e.target.value)} />
        </Col>
        <Col span={6} offset={1}>
          <Button type="primary" onClick={applyJoin}>
            {intl.get('APPLY_BTN')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={12} offset={1}>
          <span className={style.ip_error}>{ipError}</span>
        </Col>
      </Row>
      <div className={style.apply_list}>
        {keys.map((item, index) => {
          return (
            <div key={index}>
              {applyList[item] &&
                applyList[item].map((i, idx) => {
                  return (
                    <Row key={idx} className={style.apply_list_item}>
                     <Col span={15} offset={1} className={style.item_ip}>
                          {i.web_host}:{i.web_port}({i.name})
                        </Col>
                      <Col span={6} offset={1}>
                        <span className={style['apply_state_' + item]}>
                          <span className={style.apply_state_dot}>·</span>&nbsp;
                          {intl.get(item.toUpperCase())}
                        </span>
                      </Col>
                    </Row>
                  );
                })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({});
export default connect(mapStateToProps, { getSwarmList, joinSwarm })(ModeHostPort);
