export const initial = {
  id: '',
  username: '',
  realname: '',
  register_at: '',
  email: '',
  is_admin: false,
  units:[]
};

function currentUser(state = initial, action) {
  switch (action.type) {
    case 'SET_CURRENT_USER':
      return { ...state, ...action.user };
    case 'CLEAR_CURRENT_USER':
      return { ...state, ...initial };
    case 'SET_UNITS_LIST':
      return { ...state, units:action.data };
    default:
      return state;
  }
}

export default currentUser;
