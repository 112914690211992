export const setRectangleMode = (rectangleMode) => ({
  type: 'SET_RECTANGLE_MODE',
  rectangleMode
});

export const setCircleMode = (circleMode) => ({
  type: 'SET_CIRCLE_MODE',
  circleMode
});

export const setPointMode = (pointMode) => ({
  type: 'SET_POINT_MODE',
  pointMode
});

export const setPolygonMode = (polygonMode) => ({
  type: 'SET_POLYGON_MODE',
  polygonMode
});

export const setNavigatorVisibility = (navigatorVisibility) => ({
  type: 'SET_NAVIGATOR_VISIBILITY',
  navigatorVisibility
});

export const setContrastVisibility = (contrastVisibility) => ({
  type: 'SET_CONTRAST_VISIBILIYT',
  contrastVisibility
});

export const setTagVisibility = (tagVisibility) => ({
  type: 'SET_TAG_VISIBILIYT',
  tagVisibility
});

export const setAnimationStatus = (status) => ({
  type: 'SET_ANIMATION_STATUS',
  status
});

export const setSiderLeftVisibility = () => ({
  type: 'SET_SIDER_LEFT_VISIBILIYT',
});

export const setFullscreen = () => ({
  type: 'SET_FULL_SCREEN',
});