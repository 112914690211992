// @flow
import { Row, Layout } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Menubar from '../components/Menubar';
import Details from '../components/Details';
import Report from '../components/Report';
// import ImageViewer from '../components/ImageViewer';
import style from './index.less';
import {
  setFromImageId,
  fetchImageInfo,
  updateCurrentImage,
  fetchSearchImageAsync,
  updateNextImage,
  updatePreviousImage
} from '../actions/imageActions';
import { fetchComments } from '../actions/comments';
import {
  updateUpAnnotation,
  updateDownAnnotation,
  updateLeftAnnotation,
  updateRightAnnotation
} from '../actions/annotationSelectionActions';
import { getImageList } from '../selectors';
import HotkeysManager from '../classes/HotkeysManager';
import cfg from '../config';
import { getHotkeyDefinitions } from '../utils/hotKeyUtils';
import SiderMain from './comps/SiderMain';
import ContentRouters from './comps/ContentRouters';
import Timeout from '../components/Timeout';
import Can from '../components/Can';
import { getCurrentUser, getAnnotationUsers } from '../selectors/userSelectors';

const isPrintable = (keycode) => {
  return (
    (keycode > 47 && keycode < 58) || // number keys
    keycode === 32 || // spacebar
    (keycode > 64 && keycode < 91) || // letter keys
    (keycode > 95 && keycode < 112) || // numpad keys
    (keycode > 185 && keycode < 193) || // ;=,-./` (in order)
    (keycode > 218 && keycode < 223)
  ); // [\]' (in order)
};

class ImagesBrowser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      rightSiderWidth: 480
    };
    this._hotkeysManager = null;
  }

  componentDidMount() {
    this.initHotKeys();
    if (window.location.search.includes('imageId')) {
      let imageId = window.location.search.split('=')[1];
      this.props.fetchImageInfo(imageId);
      this.props.setFromImageId(imageId);
      this.props.fetchComments(imageId);
    } else {
      this.updateImage();
    }
    document.addEventListener('keydown', this.textInputHandler.bind(this));
    this.hasScrollbar();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.annotationUsers !== this.props.annotationUsers) {
      this.hasScrollbar();
    }
  }

  componentWillUnmount() {
    this._hotkeysManager.destroy();
    this._hotkeysManager = null;
    document.removeEventListener('keydown', this.textInputHandler.bind(this));
  }

  hasScrollbar() {
    let right = document.querySelectorAll('aside')[1];
    let main = document.querySelector('#image-list-container');
    if (right.scrollHeight > main.offsetHeight) {
      this.setState({ rightSiderWidth: 500 });
    } else {
      this.setState({ rightSiderWidth: 480 });
    }
  }
  updateImage() {
    let { history, images, currentImageInfo } = this.props;
    let history_state = history.location.state;
    const image =
      history_state && history_state.current
        ? history_state.current
        : currentImageInfo
        ? currentImageInfo
        : images && images.length > 0 && images[0];
    if (image) {
      this.props.fetchImageInfo(image.id);
      this.props.setFromImageId(image.id);
      this.props.fetchComments(image.id);
    }
  }

  captureHotKeys = ({ toolName, groupName, commandName }) => {
    this.props[commandName]();
  };

  initHotKeys() {
    if (!this._hotkeysManager) {
      this._hotkeysManager = new HotkeysManager();
    }
    this._hotkeysManager.setDefaultHotKeys([]);
  }
  onMouseEnterDetails = () => {
    this._hotkeysManager.setHotkeys(
      getHotkeyDefinitions(cfg.hotKeys, this.captureHotKeys, 'DetailsOfAnnotations')
    );
  };
  onMouseEnterImage = () => {
    this._hotkeysManager.setHotkeys(
      getHotkeyDefinitions(cfg.hotKeys, this.captureHotKeys, 'ImageFileList')
    );
  };
  onMouseLeave = () => {
    this._hotkeysManager.destroy();
  };
  _searchTagId() {
    const query = { slide_id: this.state.text, page: 0 };
    this.state.text.length > 0 && this.props.fetchSearchImageAsync(query);
  }

  textInputHandler = (e) => {
    if (e.which === 13 && e.target.id === 'headerSearchInput') {
      this._searchTagId();
      this.setState({ text: '' });
    } else if (e.which === 27) {
      this.setState({ text: '' });
    } else if (!isPrintable(e.which)) {
      this.setState({ text: '' });
    } else {
      const text = this.state.text + e.key;
      this.setState({ text });
    }
  };
  render() {
    const { currentUser } = this.props;
    return (
      <div className={style.image__browser}>
        <Row>
          <Menubar showToolbox={true} />
        </Row>

        <Layout className={style.main} id="image-list-container">
          <SiderMain width={700} collapsed={!this.props.siderLeftVisibility}>
            <Report />
          </SiderMain>
          <ContentRouters />
          <SiderMain
            width={this.state.rightSiderWidth}
            collapsed={this.props.fullscreen}
            id="right-sider"
          >
            <Details onMouseEnter={this.onMouseEnterDetails} onMouseLeave={this.onMouseLeave} />
          </SiderMain>
        </Layout>
        <Can
          permissions={currentUser.permissions}
          perform="image:modify:mine"
          yes={() => <Timeout />}
          no={() => ''}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  comments: state.comments.data,
  images: getImageList(state),
  currentImageInfo: state.imageSelection.currentImageInfo,
  fullscreen: state.toolStatus.fullscreen,
  siderLeftVisibility: state.toolStatus.siderLeftVisibility,
  currentUser: getCurrentUser(state),
  annotationUsers: getAnnotationUsers(state)
});

export default connect(mapStateToProps, {
  updateCurrentImage,
  fetchImageInfo,
  setFromImageId,
  fetchSearchImageAsync,
  updateNextImage,
  updatePreviousImage,
  updateUpAnnotation,
  updateDownAnnotation,
  updateLeftAnnotation,
  updateRightAnnotation,
  fetchComments
})(withRouter(ImagesBrowser));
