const initial = {
  loading: false,
  statistics: [],
  negativeAnalysis: [],
  binaryMatrix: [],
  matrixes: [[], [], [], []],
  tabs: [],
  tnr: 0,
  nr: 0
};

export default function analysis(state = initial, action) {
  switch (action.type) {
    case 'LOADING_ANALYSIS_PAGE':
      return {
        ...state,
        loading: action.loading
      };
    case 'UPDATE_STATISTICS':
      return {
        ...state,
        statistics: action.statistics,
        tnr: action.tnr,
        nr: action.nr
      };
    case 'UPDATE_NEGATIVE_ANALYSIS':
      return {
        ...state,
        negativeAnalysis: action.negativeAnalysis
      };
    case 'UPDATE_MATRIX_ANALYSIS':
      return {
        ...state,
        matrixes: action.matrixes
      };
    case 'CLEAR_ANALYSIS':
      return {
        ...state,
        ...initial
      };
    default:
      return state;
  }
}
