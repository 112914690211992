import intl from 'react-intl-universal';

const initial = {
  showLoginModal: false,
  reason: intl.get("TOKEN_EXPIRED")
};

export function auth(state = initial, action) {
  switch (action.type) {
    case 'TOGGLE_LOGIN_MODAL':
      return {
        ...state,
        showLoginModal: action.show,
        reason: action.reason
      };
    default:
      return state;
  }
}

export default { auth };
