import { Button, Modal, message } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { getReportResultList } from '../../actions/reportAction';
import style from '../../less/modal.less';
import ConfirmContent from '../CommonComponent/ConfirmContent';
class BatchPush extends Component {
  static propTypes = {
    selected: PropTypes.object,
    images: PropTypes.object
  };

  constructor() {
    super();
    this.state = {
      pushVisible: false
    };
  }
  showBatchModal = () => {
    const { selected, currentUser } = this.props;
    let l = selected.data.filter((i) => i.reporter !== currentUser.username);
    if (l.length > 0) {
      message.warning(intl.get('PRO_SELECTED_MORE'));
      return false;
    }
    this.setState({
      pushVisible: true
    });
  };

  handleOk = (e) => {
    this.props.setLoading()
    this.props.getReportResultList();
    this.setState({
      pushVisible: false
    });
  };

  handleCancel = (e) => {
    this.setState({
      pushVisible: false
    });
  };
  handleChange(value) {
    console.log(`Selected: ${value}`);
  }
  render() {
    const { selected, currentTab, tabs,forceLoading } = this.props;
    const { pushVisible } = this.state;
    return (
      <span>
        {tabs[currentTab] !== undefined ? (
          <Button
            style={
              tabs[currentTab].text === 'RE_DIAGNOSIS'
                ? { marginRight: '10px', display: 'inline' }
                : { marginRight: '20px', display: 'none' }
            }
            type="primary"
            key="BatchPush"
            onClick={this.showBatchModal}
            disabled={(selected.total > 0 && !forceLoading)? false : true}
          >
            {intl.get('BATCH_PUSH')}
          </Button>
        ) : (
          ''
        )}

        <Modal
          className={style.checkModal}
          title={intl.get('BATCH_PUSH')}
          visible={pushVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              {intl.get('CANCEL')}
            </Button>,
            <Button key="submit" type="primary" onClick={this.handleOk}>
              {intl.get('PUSH_SUBMIT')}
            </Button>
          ]}
        >
          <ConfirmContent>
            <span>{intl.get('BATCH_PUSH_NUM', { num: selected.total })}</span>
          </ConfirmContent>
        </Modal>
      </span>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    images: state.loadImages.images,
    selected: state.loadImages.selected,
    currentTab: state.tabState.currentTab,
    tabs: state.tabState.tabs,
    currentUser: state.currentUser
  };
};

export default connect(mapStateToProps, { getReportResultList })(BatchPush);
