const initial = {
  total: 0,
  used: 0,
  percent: 0,
  left: 0,
  status: 0,
};
  
export function devops(state = initial, action) {
  switch (action.type) {
    case 'UPDATE_DISK_USAGE':
      return {
        ...state,
        total: action.usage.total,
        used: action.usage.used,
        status: action.usage.status,
        percent: action.usage.percent,
        left: ( action.usage.total - action.usage.used ) /1073741824
      };
    default:
      return state;
  }
}
  