import React from 'react';
// import Details from '../components/Details';
import ImageViewer from '../components/ImageViewer';
export const routes = [
  {
    path: '/',
    exact: true,
    main: () => <ImageViewer />
  },
  {
    path: '/images-browser',
    main: () => <ImageViewer />
  }
];
