import React, { Component } from 'react';
import { connect } from 'react-redux';
import style from './index.less';
import intl from 'react-intl-universal';
import ReactToPrint from 'react-to-print';
import { Modal, Button } from 'antd';
import { canRender } from '../Can';
class ProPrint extends Component {
  constructor() {
    super();
    this.state = {
      isModalVisible: false
    };
    this.content = React.createRef();
  }
  showModal() {
    this.setState({ isModalVisible: true });
  }
  handleCancel() {
    this.setState({ isModalVisible: false });
  }
  render() {
    const { infoList, currentUser } = this.props;
    return (
      <>
        {canRender(currentUser.permissions, 'client:section:print') ? (
          <span className={style.pro__print} onClick={this.showModal.bind(this)}>
            {intl.get('PRO_PRINT')}
          </span>
        ) : (
          <span className={style.pro__print} style={{ cursor: 'auto', color: '#999' }}>
            {intl.get('PRO_PRINT')}
          </span>
        )}

        <Modal
          title={intl.get('PRO_PRINT')}
          visible={this.state.isModalVisible}
          className={style.checkModal}
          onCancel={this.handleCancel.bind(this)}
          footer={[
            <Button key="back" onClick={this.handleCancel.bind(this)}>
              {intl.get('CANCEL')}
            </Button>,
            <ReactToPrint
              key="submit"
              trigger={() => (
                <Button key="back" type="primary">
                  {intl.get('PRO_PRINT')}
                </Button>
              )}
              content={() => this.content.current}
              onAfterPrint={this.handleCancel.bind(this)}
            />
          ]}
        >
          <div ref={this.content} className={style.pro__print__content}>
            {intl.get('PATHOLOGY_ID') + ':' + infoList.pathology_id}
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { currentUser: state.currentUser };
};

export default connect(mapStateToProps, {})(ProPrint);
