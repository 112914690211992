import { Progress } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { receiveProgress, removeInProgresses } from '../../actions/progressActions';

class ProgressBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      percent: 0,
      status: 'active'
    };
  }

  myProgress = () => {
    const { progresses, filename } = this.props;
    return progresses.find((item) => item.filename === filename);
  };

  updateProgress = () => {
    const { filename } = this.props;
    if (this.isFinished()) {
      const p = this.myProgress();
      let status = 'success';
      if (p.result === 'failed') status = 'exception';
      this.setState({ percent: 100, status });
      this.props.removeInProgresses(filename);
    }
  };

  isFinished = () => {
    const p = this.myProgress();
    if(p !== undefined) return p.progress === 100;
    return false  
  };

  render() {
    const p = this.myProgress();
    this.updateProgress()
    if (p) return <Progress percent={p.progress} size="small" />;
    return <Progress percent={this.state.percent} size="small" status={this.state.status} />;
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.progress
  };
};

export default connect(mapStateToProps, { removeInProgresses, receiveProgress })(ProgressBar);
