import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import NoMatch from '../Exceptions/404';
import HelpModal from '../HelpModal';
import LoginModal from '../LoginPage/LoginModal';
import ImagesBrowser from '../../ImagesBrowser';
import Login from '../../Login';
import MainPage from '../../MainPage';
import Preference from '../../Preference';
import Expert from '../../Expert';
import DataPlatform from '../DataPlatform';
import { isPartEnd } from '../../utils/urlUtils';
class AppRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partEnd: isPartEnd()
    };
  }
  componentDidUpdate(prevProps) {
  const { general,currentUser } = this.props;
    if (
      prevProps.currentUser !== currentUser ||
      prevProps.general.product_mode !== general.product_mode ||
      prevProps.general.node_mode !== general.node_mode
    ) {
      this.setState({
        partEnd: isPartEnd()
      });
    }
  }
  render() {
    const { partEnd } = this.state;
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/login" component={Login} />
          <Route path="/mainpage" component={MainPage} />
          <Route path="/images-browser" component={ImagesBrowser} />
          <Route path="/preference" component={Preference} />
          <Route path='/dataPlatfrom' component={DataPlatform} />
          {partEnd === 'expert' && <Route path="/expert" component={Expert} />}
          <Route component={NoMatch} />
        </Switch>
        <LoginModal />
        <HelpModal />
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  general: state.config.general,
  currentUser:state.currentUser
});

export default connect(mapStateToProps, {})(AppRouter);
