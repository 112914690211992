import React, { Component } from 'react';
import BaseChart from './BaseChart';
import style from './index.less';
import LoadingSpin from './LoadingSpin';
import intl from 'react-intl-universal';
import {titleStyle,girdInfo} from "./echartInfo"
export class IncidenceRate extends Component {
  getOption() {
    const { data } = this.props;
    let s = data.map((item, index) => {
      return {
        name: item.name,
        data: item.val,
        type: 'line',
        symbol: index === 0 ? 'triangle' : 'rect',
        symbolSize: 5,
        lineStyle: {
          color: index === 0 ? '#FFC52F' : '#5B9AFF',
          width: 2
        },
        itemStyle: {
          borderWidth: 3,
          borderColor: index === 0 ? '#FFC52F' : '#5B9AFF',
          color: index === 0 ? '#FFC52F' : '#5B9AFF'
        }
      };
    });
    let option = {
      title: {
        left: 'left',
        text: intl.get('INCIDENCE_RATE'),
        top: 10,
        textStyle: titleStyle
      },
      legend: {
        data: data.map((i) => i.name.toString()),
        orient: 'vertical',
        x: 'right',
        y: 'top',
        align: 'right'
      },
      grid:girdInfo,
      tooltip: {
        formatter: function(arg) {
          return (
            arg.data[0] + intl.get('CHECK_IN_YEAR') + ':' + arg.data[1].toString().slice(0, 5) + '%'
          );
        }
      },
      xAxis: {
        type: 'category',
        name: intl.get('CHECK_IN_YEAR'),
        boundaryGap: false
      },
      yAxis: {
        type: 'value',
        splitLine: {
          show: false
        }
      },
      series: s
    };

    return option;
  }

  render() {
    const { data } = this.props;
    return (
      <div className={style.chart_container}>
        {data ? (
          <BaseChart style={{ height: '250px', width: '100%' }} option={this.getOption()} />
        ) : (
          <LoadingSpin />
        )}
      </div>
    );
  }
}

export default IncidenceRate;
