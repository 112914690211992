import PropTypes from 'prop-types';
import React, { Component } from 'react';

class FallbackImage extends Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
    onClick: PropTypes.func,
    imgStyle: PropTypes.object,
    imgClass: PropTypes.string
  };

  onImgError = (e) => {
    e.preventDefault();
    e.target.onerror = null;
    e.target.src = require('../../static/images/label_default.png');
  };

  render() {
    const { width, height, onClick, src, imgStyle, imgClass } = this.props;
    return (
      <img
        className={imgClass}
        src={src}
        onError={this.onImgError}
        width={width}
        height={height}
        onClick={onClick}
        style={imgStyle}
      />
    );
  }
}

export default FallbackImage;
