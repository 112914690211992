import { nativePlaceOptions } from '../../static/nativePlaceOptions';
import { nations } from '../../static/nationOptions';
import intl from 'react-intl-universal';
import { setValueOptions, setOptions } from '../../utils/dataUtils';
import { validatePersonCard, validatePersonAge,validateMoney,validatePhone } from '../../utils/regUtils';
import store from '../../store';
import {getUnitsList} from "../../selectors/settingSelectors"
export const ageList = [
  {
    value: 'Y',
    label: 'CHECK_IN_YEAR'
  },
  {
    value: 'M',
    label: 'CHECK_IN_MONTH'
  }
];
export const cardList = [
  {
    value: 'ID',
    label: 'ID_CARD'
  },
  {
    value: 'Officer',
    label: 'OFFICER_CARD'
  },
  {
    value: 'Passport',
    label: 'PASSPORT'
  }
];
const genderList = [
  {
    value: 'M',
    label: 'REPORT_PATIENT_GENDER_M'
  },
  {
    value: 'F',
    label: 'REPORT_PATIENT_GENDER_F'
  },
  {
    value: 'other',
    label: 'GENEREL'
  }
];
const marriageList = [
  {
    value: 'Married',
    label: 'MARRIED'
  },
  {
    value: 'Single',
    label: 'UNMARRIED'
  }
];
const contagionList = [
  {
    value: true,
    label: 'IS_END'
  },
  {
    value: false,
    label: 'NO_END'
  }
];
const menopauseList = {
  value: true,
  label: 'M_END'
};
const quickList = {
  value: true,
  label: 'URGENT_CASE'
};
const PathologyTypeList = [
  {
    value: 'Cytology',
    label: 'CELLS'
  }
];
const privacyList = [
  {
    value: 'general',
    label: 'PAIENT_PRIVACY_NORMAL'
  },
  {
    value: 'private',
    label: 'PAIENT_PRIVACY_PRI'
  }
];
export const seeTypeList = [
  {
    value: 'Clinic',
    label: 'OUTPATIENT_DEP'
  },
  {
    value: 'In hospital',
    label: 'HOSPITALIZATION'
  },
  {
    value: 'Examination',
    label: 'PHUYSICAL_EXAM'
  }
];
const moneyTypeList = [
  {
    value: 'own',
    label: 'OWN_EXPENSE'
  },
  {
    value: 'insurance',
    label: 'MEDICAL_INS'
  }
];
const sampleLocList = [
  {
    value: 'Cervical',
    label: 'CERVICAL'
  }
];
export const sampleNameList = [
  {
    value: 'Cervical',
    label: 'CERVICAL_EXFOLIATED_CELLS'
  }
];
const specimenTypeList = [
  {
    value: 'Cervical LBC',
    label: 'CANCER_CERVICAL'
  }
];
export const rejectList = [
  {
    value: 'REJECT_REASON1',
    label: 'REJECT_REASON1'
  },
  {
    value: 'REJECT_REASON2',
    label: 'REJECT_REASON2'
  },
  {
    value: 'REJECT_REASON3',
    label: 'REJECT_REASON3'
  },
  {
    value: 'REJECT_REASON4',
    label: 'REJECT_REASON4'
  },
  {
    value: 'REJECT_REASON5',
    label: 'REJECT_REASON5'
  },
  {
    value: 'REJECT_REASON6',
    label: 'REJECT_REASON6'
  },
  {
    value: 'REJECT_REASON7',
    label: 'REJECT_REASON7'
  },
  {
    value: 'REJECT_REASON8',
    label: 'REJECT_REASON8'
  }
];
const evlaueList = [
  {
    value: 'EVALUATE_EXCELLENT',
    label: 'EVALUATE_EXCELLENT'
  },
  ...rejectList
];
const ColSpan = {
  wrapperCol: { span: 18 },
  labelCol: { span: 6 }
};

export const initalCheckInfo = {
  id: '',
  apply_id: '',
  pathology_id: '',
  name: '',
  gender: undefined,
  age_type: 'Y',
  age: '',
  ethnic_group: undefined,
  occupation: '',
  origin: undefined,
  patient_telephone: '',
  identity_type: 'ID',
  identity: '',
  marital_status: '',
  is_urgent: false,
  last_m: undefined,
  is_m_end: false,

  apply_date: undefined,
  sample_sent_date: '',
  pathology_type: 'Cytology',
  clinic_type: undefined,
  case_id: '',
  clinic_id: '',
  ward: '',
  admission_id: '',
  bed_id: '',
  fee_type: undefined,
  fee: '',
  sensitivity: 'general',
  infectivity: false,

  sample_unit:"",
  sample_department:"",
  apply_doctor:"",
  sample_type: 'Cervical LBC',
  sample_recevie_date: '',
  sample_region: undefined,
  sample_name: undefined,
  sample_index: '',

  clinic_history: [],
  clinic_summary: '',
  cell_examination_date: '',
  cell_examination_result: '',
  hpv_examination_date: '',
  hpv_examination_result: '',
  clinic_record: '',
  surgery_record: '',
  other_examination: '',
  other_surgery: '',
  other_note: '',

  sample_date:undefined,
  sample_recevier:'',
  rejected_reason:undefined

};

export function getBasicInfo() {
  return [
    {
      name: intl.get('PATIENT_NAME_LABEL'),
      type: 'input',
      key: 'name',
      rules: [
        {
          required: true,
          pattern: /^\S{1,20}$/g,
          message: ''
        },
        {
          pattern: /^[^\s]*$/,
          message: '',
        }
      ],
      maxLength:20,
      span: 8,
      ...ColSpan
    },
    {
      name: intl.get('GENDER'),
      type: 'select',
      key: 'gender',
      options: setValueOptions('select', genderList),
      optionsList: genderList,
      defaultValue: 'M',
      rules: [
        {
          required: true,
          message: ''
        }
      ],
      span: 8,
      ...ColSpan
    },
    {
      name: intl.get('AGE'),
      type: 'input',
      key: 'age',
      addonAfterOptions: setValueOptions('select', ageList),
      defAfterValue: 'Y',
      defAfterOptions: intl.get('CHECK_IN_YEAR'),
      valueList:ageList,
      rules: [
        {
          required: true,
          pattern: /^[1-9]\d*$/,
          message: ''
        }
        ,{
          pattern: /^[^\s]*$/,
          message: '',
        },
        { validator: validateAge }
      ],
      span: 8,
      ...ColSpan
    },
    {
      name: intl.get('CHECK_IN_NATION'),
      type: 'select',
      key: 'ethnic_group',
      options: setOptions(nations),
      optionsList: nations,
      rules: [],
      span: 8,
      ...ColSpan
    },

    {
      name: intl.get('CHECK_IN_WORK'),
      type: 'input',
      key: 'occupation',
      rules: [{
        pattern: /^[^\s]*$/,
        message: '',
      }],
      maxLength:50,
      span: 8,
      ...ColSpan
    },
    {
      name: intl.get('CHECK_IN_NAVTICEPLACE'),
      type: 'select',
      key: 'origin',
      options: setOptions(nativePlaceOptions),
      optionsList: nativePlaceOptions,
      rules: [],
      span: 8,
      ...ColSpan
    },
    {
      name: intl.get('ACCOUNT_PHONE'),
      type: 'input',
      key: 'patient_telephone',
      rules: [
        {
          ...validatePhone,
          message: ''
        },
        {
          pattern: /^[^\s]*$/,
          message: '',
        }
      ],
      maxLength:11,
      span: 8,
      ...ColSpan
    },
    {
      name: intl.get('CARD_TITLE'),
      type: 'input',
      key: 'identity',
      addonAfterOptions: setValueOptions('select', cardList),
      defAfterOptions: intl.get('ID_CARD'),
      defAfterValue:'ID',
      valueList:cardList,
      rules: [{ validator: validateCard }],
      span: 16,
      wrapperCol: { span: 21 },
      labelCol: { span:3 }
    },
    {
      name: intl.get('UNTITLE'),
      type: 'radio',
      key: 'marital_status',
      options: setValueOptions('radio', marriageList),
      optionsList: marriageList,
      defaultValue: '',
      rules: [],
      span: 5,
      wrapperCol: { span: 23 },
      labelCol: { span: 1 }
    },
    {
      name: intl.get('UNTITLE_NULL'),
      type: 'CheckBox',
      key: 'is_urgent',
      options: quickList,
      optionsList: quickList,
      rules: [],
      span: 3,
      wrapperCol: { span: 23 },
      labelCol: { span: 1 }
    },
    {
      name: intl.get('LAST_M'),
      type: 'DatePicker',
      key: 'last_m',
      showTime: false,
      rules: [],
      span: 8,
      ...ColSpan
    },
    {
      name: intl.get('UNTITLE'),
      type: 'CheckBox',
      key: 'is_m_end',
      options: menopauseList,
      optionsList: menopauseList,
      rules: [],
      span: 4,
      wrapperCol: { span: 19 },
      labelCol: { span: 5 }
    }
  ];
}

export function getInspectionInfo() {
  return [
    {
      name: intl.get('SAMPLE_UNIT'),
      type: 'Cascader',
      key: 'department',
      options: getDepartList(getUnitsList(store.getState())),
      span: 16,
      placeholder: intl.get('SAMPLE_PLACEHOLDER'),
      wrapperCol: { span: 21 },
      labelCol: { span: 3 }
    },
    {
      span: 8,
    },
    {
      name: intl.get('APPLY_TIME'),
      type: 'DatePicker',
      key: 'apply_date',
      showTime: true,
      span: 8,
      ...ColSpan
    },
    {
      name: intl.get('INSPECTION_TIME'),
      type: 'DatePicker',
      key: 'sample_sent_date',
      showTime: true,
      rules: [
        {
          required: true,
          message: ''
        }
      ],
      span: 8,
      ...ColSpan
    },
    {
      name: intl.get('PATHOLOGY_TYPE'),
      type: 'select',
      key: 'pathology_type',
      options: setValueOptions('select', PathologyTypeList),
      optionsList: PathologyTypeList,
      rules: [
        {
          required: true,
          message: ''
        }
      ],
      span: 8,
      ...ColSpan
    },
    {
      name: intl.get('SEE_TYPE'),
      type: 'select',
      key: 'clinic_type',
      options: setValueOptions('select', seeTypeList),
      optionsList: seeTypeList,
      rules: [],
      span: 8,
      ...ColSpan
    },
    {
      name: intl.get('PATIENT_ID'),
      type: 'input',
      key: 'case_id',
      rules: [ {
        pattern: /^[^\s]*$/,
        message: '',
      }],
      maxLength:50,
      span: 8,
      ...ColSpan
    },
    {
      name: intl.get('OUTPATIENT_ID'),
      type: 'input',
      key: 'clinic_id',
      rules: [ {
        pattern: /^[^\s]*$/,
        message: '',
      }],
      maxLength:50,
      span: 8,
      ...ColSpan
    },

    {
      name: intl.get('HOSPITAL_AREA'),
      type: 'input',
      key: 'ward',
      rules: [ {
        pattern: /^[^\s]*$/,
        message: '',
      }],
      maxLength:50,
      span: 8,
      ...ColSpan
    },
    {
      name: intl.get('HOSPITAL_ID'),
      type: 'input',
      key: 'admission_id',
      rules: [ {
        pattern: /^[^\s]*$/,
        message: '',
      }],
      span: 8,
      ...ColSpan
    },
    {
      name: intl.get('BED_ID'),
      type: 'input',
      key: 'bed_id',
      rules: [ {
        pattern: /^[^\s]*$/,
        message: '',
      }],
      span: 8,
      maxLength:50,
      ...ColSpan
    },
    {
      name: intl.get('MONEY_TYPE'),
      type: 'select',
      key: 'fee_type',
      options: setValueOptions('select', moneyTypeList),
      optionsList: moneyTypeList,
      rules: [],
      span: 8,
      ...ColSpan
    },
    {
      name: intl.get('MONEY_NUM'),
      type: 'input',
      key: 'fee',
      rules:[{ validator: validateCheckMoney }],
      span: 8,
      maxLength:20,
      ...ColSpan
    },
    {
      name: intl.get('PAIENT_PRIVACY'),
      type: 'select',
      key: 'sensitivity',
      options: setValueOptions('select', privacyList),
      optionsList: privacyList,
      rules: [],
      span: 8,
      defaultValue: 'general',
      ...ColSpan
    },
    {
      name: intl.get('SAMPLE_CONTAGION'),
      type: 'radio',
      key: 'infectivity',
      options: setValueOptions('radio', contagionList),
      optionsList: contagionList,
      defaultValue: '',
      rules: [],
      span: 8,
      ...ColSpan
    }
  ];
}
export function getSampleInfo() {
  return [
    {
      name: intl.get('SPECIMEN_TYPE'),
      type: 'select',
      key: 'sample_type',
      options: setValueOptions('select', specimenTypeList),
      optionsList: specimenTypeList,
      rules: [
        {
          required: true,
          message: ''
        }
      ],
      span: 8,
      ...ColSpan
    },
    {
      name: intl.get('SPECIMEN_DATE'),
      type: 'DatePicker',
      key: 'sample_date',
      showTime: true,
      rules: [],
      span: 8,
      ...ColSpan
    },
    {
      span: 8,
      ...ColSpan
    },
    {
      name: intl.get('SPACEMEN_LOC'),
      type: 'select',
      key: 'sample_region',
      options: setValueOptions('select', sampleLocList),
      optionsList: sampleLocList,
      rules: [],
      span: 8,
      ...ColSpan,
      placeholder: intl.get('SPACEMEN_LOC')
    },
    {
      name: intl.get('SPACEMEN_NAME_PLACEHOLDER'),
      type: 'select',
      key: 'sample_name',
      options: setValueOptions('select', sampleNameList),
      optionsList: sampleNameList,
      rules: [],
      span: 8,
      ...ColSpan,
      placeholder: intl.get('SPACEMEN_NAME_PLACEHOLDER')
    },
    {
      name: intl.get('SPACEMEN_NUM'),
      type: 'input',
      key: 'sample_index',
      rules: [
        {
          required: false,
          pattern: /^[0-9]\d*$/,
          message: ''
        }

      ],
      maxLength:20,
      span: 8,
      ...ColSpan,
      placeholder: intl.get('SPACEMEN_NUM')
    },
    {
      name: intl.get('SPACEMEN_EVALUATE'),
      type: 'select',
      key: 'rejected_reason',
      options: setValueOptions('select', evlaueList),
      optionsList: evlaueList,
      rules: [],
      span: 16,
      wrapperCol: { span: 21 },
      labelCol: { span: 3 },
      placeholder: intl.get('SPACEMEN_EVALUATE')
    }
  ];
}

export const otherInfo = [
  {
    name: 'CLINIC_DIAGNOSIS',
    key: 'clinic_diagnosis',
    span: 24
  },
  {
    name: 'PATIENT_SUMMARY',
    key: 'clinic_summary',
    span: 24
  },
  {
    name: 'CLINICAL_PATIENT',
    key: 'clinic_record',
    span: 24
  },
  {
    name: 'OPERATION_DIAGNOSIS',
    key: 'surgery_record',
    span: 24
  },
  {
    name: 'OTHER_DIAGNOSIS',
    key: 'other_examination',
    span: 24
  }
];


export const numInfo = [
  {
    name: 'PATIENT_TAG_ID',
    type: 'input',
    key: 'pathology_id',
    rules: [],
    disabled:true,
    span: 8,
    ...ColSpan
  },
  {
    name: 'APPLY_ID',
    type: 'input',
    key: 'apply_id',
    rules: [ {
      // required: true,
      pattern: /^\S{1,20}$/g,
      message: ''
    },
  ],
    maxLength:20,
    disabled:false,
    span: 8,
    ...ColSpan
  }
];

export const numInfoModify = [
  {
    name: 'PATIENT_TAG_ID',
    type: 'input',
    key: 'pathology_id',
    rules: [],
    disabled:true,
    span: 8,
    ...ColSpan
  }, 
];
export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
    lg: { span: 6 },
    xl: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
    lg: { span: 18 },
    xl: { span: 18 }
  },
  gutter: [10, 10]
};



export function getSampleLocList() {
  return setValueOptions('select', sampleLocList);
}

export function getSampleNameList() {
  return setValueOptions('select', sampleNameList);
}


export function getDepartList(units) {
  let s = units.map(u=>{
  return {
    id:u.label,
    value:u.label,
    label:u.label,
    children:u.children?(u.children?.length>0 ? getDepartList(u.children):null):null
  }
  })
  return s
}
export function getHisInfo(key, list) {
  if(key!== undefined && list!== undefined){
    let l = list.filter((item) => {
      return item.value!== undefined ? item.value === key : item === key  
    });
    if (l.length > 0) {
      return l[0].label!== undefined ? intl.get(l[0].label): l[0];
    }
    return key;
  }
  return '';
}

export const summaryInfo1 = [
  {
    value: 'OCP',
    label: 'SUMMARY_CHOICE1',
    span: 8
  },
  {
    value: 'IUD',
    label: 'SUMMARY_CHOICE2',
    span: 8
  },
  {
    value: 'pregnant',
    label: 'SUMMARY_CHOICE3',
    span: 8
  },
  {
    value: 'bleeding',
    label: 'SUMMARY_CHOICE4',
    span: 8
  },
  {
    value: 'lactation',
    label: 'SUMMARY_CHOICE5',
    span: 8
  },
  {
    value: 'postpartum',
    label: 'SUMMARY_CHOICE6',
    span: 8
  },
  {
    value: 'HPV_H',
    label: 'SUMMARY_HPV1',
    span: 8
  },
  {
    value: 'HPV_M',
    label: 'SUMMARY_HPV2',
    span: 8
  },
  {
    value: 'HPV_L',
    label: 'SUMMARY_HPV3',
    span: 8
  },
  {
    value: 'other_note',
    label: 'GENEREL',
    span: 16
  }
];

export const summaryInfo3 = [
  {
    name: 'cell',
    children: [
      {
        name: 'cell_examination_date',
        label: 'SUMMARY_CELL_DATE',
        type: 'DatePicker',
        showTime: false,
        span: 8
      },
      {
        name: 'cell_examination_result',
        label: 'SUMMARY_REASULT',
        type: 'Input',
        span: 2
      }
    ]
  },
  {
    name: 'hpv',
    children: [
      {
        name: 'hpv_examination_date',
        label: 'SUMMARY_HPV_DATE',
        type: 'DatePicker',
        showTime: false,
        span: 8
      },
      {
        name: 'hpv_examination_result',
        label: 'SUMMARY_REASULT',
        type: 'Input',
        span: 2
      }
    ]
  }
];

export const opertaionAfterList = [
  { name: 'OPERARION_AFTER_OPTION1', value: 'hysterectomy', text: 'OPERARION_AFTER_OPTION1' },
  { name: 'OPERARION_AFTER_OPTION2', value: 'conization', text: 'OPERARION_AFTER_OPTION2' },
  { name: 'OPERARION_AFTER_OPTION3', value: 'oophorotomy', text: 'OPERARION_AFTER_OPTION3' },
  { name: 'OPERARION_AFTER_OPTION4', value: 'radiotherapy', text: 'OPERARION_AFTER_OPTION4' },
  { name: 'GENEREL', value: 'other_surgery', text: 'GENEREL' }
];

export const initalCheckQuery ={
  page: 0,
  registrate_from: '',
  registrate_to: '',
  sortAsc: '',
  sortBy: 'is_urgent',
  fuzzy: '',
}

const validateCard = (rule, val, callback) => {
  if (val) {
    if (!validatePersonCard(val)) {
      callback(''); //message
    } else {
      callback();
    }
  } else {
    callback();
  }
};

const validateAge = (rule, val, callback) => {
  if (val) {
    if (!validatePersonAge(val)) {
      callback(''); //message
    } else {
      callback();
    }
  } else {
    callback('');
  }
};

const validateCheckMoney = (rule, val, callback) => {
  if (val) {
    if (!validateMoney(val)) {
      callback(''); //message
    } else {
      callback();
    }
  } else {
    callback();
  }
};

