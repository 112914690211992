import React from 'react';
import intl from 'react-intl-universal';
import { defaultTabsInfo } from '../../static/defaultTabs';
import ProgressBar from '../ProgressBar';
import { isPending } from '../../static/detectionStatusMapping';

export const AIResult = ({ image }) => {
  const lev = image.imageLeve;
  const hasVal = lev && defaultTabsInfo[lev];
  return (
    <span style={{ color: hasVal ? defaultTabsInfo[lev].color : '#000' }}>
      {hasVal ? intl.get(defaultTabsInfo[lev].translate) : ''}
      {isPending(image.status) ? <ProgressBar filename={image.filename}/> : undefined }
    </span>
  );
};

export default AIResult;
