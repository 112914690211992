/* @flow */
import React, { Component } from 'react';
import { Tabs} from 'antd';
import intl from 'react-intl-universal';
import UploadPane from './UploadPane'
import { connect } from 'react-redux';
import { getConfig } from '../../selectors/configSelector';

const { TabPane } = Tabs;
class ImageUploader extends Component {
  constructor(props){
    super(props)
    const panes = [
      { 
        title: intl.get("CERVICAL_IMAGE_TYPE"),
        content: <UploadPane sample='cervical' shouldDetect={true}/>,
        key: 'cervical',
        closable: false
      },
      { 
        title: intl.get("BLADDER_IMAGE_TYPE"),
        content: <UploadPane sample='bladder' shouldDetect={true}/>,
        key: 'bladder',
        closable: false
      },
      {
        title: intl.get("URINE_IMAGE_TYPE"),
        content: <UploadPane sample='urine' shouldDetect={true}/>,
        key: 'urine',
        closable: false,
      },
      {
        title: intl.get("GENERAL_IMAGE_TYPE"),
        content: <UploadPane sample='general' shouldDetect={false}/>,
        key: 'general',
        closable: false,
      },
    ].filter(
      (u)=>this.props.config.cancerTypes.map(c=>c.value).includes(u.key)
    )

    this.state = {
      activeKey: panes[0].key,
      panes,
    };
  }

  onChange = activeKey => {
    this.setState({ activeKey });
  };


  render() {
    return (
      <Tabs
        onChange={this.onChange}
        activeKey={this.state.activeKey}
        type="card"
      >
        {this.state.panes.map(pane => (
          <TabPane tab={pane.title} key={pane.key} closable={pane.closable}>
            {pane.content}
          </TabPane>
        ))}
      </Tabs>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: getConfig(state),
  };
};
export default connect(mapStateToProps, null)(ImageUploader);



