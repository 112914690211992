import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col, Input, Select, message } from 'antd';
import intl from 'react-intl-universal';
import styles from '../commonPanel.less';
import { tagIdlabels, tagIdContent } from '../../../static/settingInfo';
import {  updateGeneralConfig } from '../../../actions/configActions';
const { Option } = Select;
function TagId(props) {
  const [tagList, setTagList] = useState({
    pathology_id_prefix_alphabet: props.general.pathology_id_prefix_alphabet,
    pathology_id_prefix_time: props.general.pathology_id_prefix_time,
    pathology_id_prefix_linker: props.general.pathology_id_prefix_linker,
    pathology_id_number_count: props.general.pathology_id_number_count
  });


  const onChange = (data, key) => {
    props.updateGeneralConfig({ ...tagList, [key]: data }).then((res) => {
      if(res){
        message.success(intl.get('MARK_MODIFY_SUCCESS'));
      }
    });
    setTagList({ ...tagList, [key]: data });
  };

  return (
     <Card title={intl.get('TAG_ID_SETTING_TITLE')}>
        <div className={styles.item__div}>
          <Row gutter={20}>
            {tagIdlabels.map((item, index) => {
              return (
                <Col span={6} key={index} className={styles.tag_col}>
                  {intl.get(item)}
                </Col>
              );
            })}
          </Row>
          <Row gutter={20}>
            {tagIdContent.map((item, index) => {
              return (
                <Col span={6} key={index} className={styles.tag_col}>
                  {item.type === 'input' && (
                    <Input
                      value={tagList[item.key]}
                      onChange={(e) => onChange(e.target.value, item.key)}
                    />
                  )}
                  {item.type === 'select' && (
                    <Select
                      showSearch
                      defaultValue={item.defaultValue}
                      onChange={(e) => {
                        onChange(e, item.key);
                      }}
                      value={tagList[item.key]}
                      disabled={item.disabled}
                    >
                      {item.options.map((child, i) => {
                        return (
                          <Option key={child.value} value={child.value}>
                            {intl.get(child.label)}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Col>
              );
            })}
          </Row>
        </div>
      </Card>
  );
}

const mapStateToProps = (state, ownProps) => ({
  general: state.config.general
});
export default connect(mapStateToProps, {  updateGeneralConfig })(TagId);
