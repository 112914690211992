const initial = {
  loading: false,
  lists: []
};

function wsiLists(state = initial, action) {
  switch (action.type) {
    case 'TOGGLE_LOADING_WSI_LISTS':
      return {
        ...state,
        loading: action.loading
      };
    case 'UPDATE_WSI_LIST':
      return {
        ...state,
        lists: [
          ...state.lists.filter((item) => item.position !== action.position),
          { position: action.position, list: action.wsiList }
        ]
      };
    default:
      return state;
  }
}

export default { wsiLists };
