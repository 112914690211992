import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Button, message } from 'antd';
import intl from 'react-intl-universal';
import Can from '../Can';
import BatchAllocation from './BatchAllocation';
import { getCurrentUser } from '../../selectors/userSelectors';
import ExportCsvBtn from './ExportCsvBtn';
import UploadGTBtn from './UploadGTBtn';
import LoadImagesBtn from './LoadImagesBtn';
import BatchPush from './BatchPush';
import { toggleLoadingTable } from '../../actions/tableActions';
import PrintListBtn from './PrintListBtn';
import ExportReport from './ExportReport';
import BatchChange from './BatchChange';
import { batchSendReport, batchPipelineEdited } from '../../actions/imageActions';
import DeleteImageBtn from './DeleteImageBtn';
import Recalculate from './Recalculate';
import { getTableLoading } from '../../selectors/imageSelector';
import { isPartEnd } from '../../utils/urlUtils';
import {
  juniorSynhroPower,
  seniorPower,
  printPower,
  sentPower,
  calPower
} from '../../utils/powerUtils';
import { getMachine } from '../../actions/userActions';
class SearchTabRight extends Component {
  constructor(props) {
    super();
    this.mine = React.createRef();
    this.state = {
      searchValue: props.queryInfo.fuzzy
    };
  }

  componentDidMount() {
    isPartEnd() === 'centerEnd' && this.props.getMachine();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.queryInfo.fuzzy !== this.props.queryInfo.fuzzy) {
      this.setState({
        searchValue: this.props.queryInfo.fuzzy
      });
    }
  }
  batchSend() {
    this.props.toggleLoadingTable(true);
    this.props.batchSendReport();
  }

  batchSynhro() {
    const { selected, currentUser } = this.props;
    let l = selected.data.filter((i) => i.stage !== 3);
    let jun = selected.data.filter((i) => currentUser.username !== i.junior_reporter);
    if (l.length > 0 || jun.length > 0) {
      message.warning(intl.get('PRO_SELECTED_MORE'));
      return false;
    }
    this.props.toggleLoadingTable(true);
    this.props.batchPipelineEdited();
  }
  batchReport() {
    const { selected, currentUser } = this.props;
    let l = selected.data.filter((i) => i.reporter !== currentUser.username);
    if (l.length > 0) {
      message.warning(intl.get('PRO_SELECTED_MORE'));
      return false;
    }
    this.props.toggleLoadingTable(true);
    this.props.batchPipelineEdited();
  }


  render() {
    const { tabState, currentUser, selected, forceLoading } = this.props;
    const { currentTab, tabs } = tabState;
    const btnDis = selected.total > 0 && !forceLoading ? false : true;
    return (
      <>
        <UploadGTBtn key="upload-gt" />
        <ExportCsvBtn key="exportcsv" />
        {/* 删除图像 */}
        { calPower(currentUser) && <DeleteImageBtn setLoading={() => this.props.toggleLoadingTable(true)} />}
        {/* 待分配 */}
        {tabs[currentTab]?.text === 'TO_BE_ALLOCATED' && (
          <>
            {/* 重新计算 */}
            {calPower(currentUser) && (
              <Recalculate setLoading={() => this.props.toggleLoadingTable(true)} />
            )}
            {printPower(currentUser) && <PrintListBtn forceLoading={forceLoading} />}
            <Can
              key="BatchAllocation"
              permissions={currentUser.permissions}
              perform="pipeline:modify:batch"
              yes={() => (
                <BatchAllocation
                  forceLoading={forceLoading}
                  setLoading={() => this.props.toggleLoadingTable(true)}
                />
              )}
              no={() => ''}
            />
          </>
        )}
        {/* 初诊 */}
        {tabs[currentTab]?.text === 'INITIAL_DIAGNOSIS' && juniorSynhroPower(currentUser) && (
          <Button type="primary" onClick={this.batchSynhro.bind(this)} disabled={btnDis}>
            {intl.get('SYNCHRO_AI')}
          </Button>
        )}
        {/* 复核 */}
        {tabs[currentTab]?.text === 'AGAIN_DIAGNOSIS' && seniorPower(currentUser) && (
          <>
            <Button type="primary" onClick={this.batchReport.bind(this)} disabled={btnDis}>
              {intl.get('BATCH_SUMBIT')}
            </Button>
            <BatchChange
              forceLoading={forceLoading}
              setLoading={() => this.props.toggleLoadingTable(true)}
            />
          </>
        )}
        {/* 已诊断 */}
        {tabs[currentTab]?.text === 'RE_DIAGNOSIS' && seniorPower(currentUser) && isPartEnd()!=='expert' && (
          <BatchPush
            forceLoading={forceLoading}
            setLoading={() => this.props.toggleLoadingTable(true)}
          />
        )}
        {/* 待打印 */}
        {tabs[currentTab]?.text === 'WAIT_PRINT' && printPower(currentUser) && (
          <ExportReport name="PRINT_BATCH_REPORT" forceLoading={forceLoading} />
        )}

        {/* 待发放 */}
        {tabs[currentTab]?.text === 'WAIT_SEND' && sentPower(currentUser) && (
          <Button type="primary" onClick={this.batchSend.bind(this)} disabled={btnDis}>
            {intl.get('BATH_SEND')}
          </Button>
        )}
        <Can
          key="LoadImagesBtn"
          permissions={currentUser.permissions}
          perform="client:image:view"
          yes={() => <LoadImagesBtn key="loadimage" />}
          no={() => ''}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: getCurrentUser(state),
    tabState: state.tabState,
    queryInfo: state.loadImages.queryInfo,
    selected: state.loadImages.selected,
    forceLoading: getTableLoading(state)
  };
};

export default connect(mapStateToProps, {
  batchSendReport,
  batchPipelineEdited,
  toggleLoadingTable,
  getMachine
})(SearchTabRight);
