import { apiFetchComments, apiCreateComment, apiRemoveComment } from './api';

export const loadComments = (comments) => ({
  type: 'LOAD_COMMENTS',
  comments
});

export const addComment = (comment) => ({
  type: 'ADD_COMMENT',
  comment
});

export const deleteComment = (commentId) => {
  return {
    type: 'DELETE_COMMENT',
    commentId: commentId
  };
};


export function fetchComments(imageId) {
  return async (dispatch) => {
    apiFetchComments(imageId).then((resp) => {
      if(resp&&resp.data){
        dispatch(loadComments(resp.data.comments))
        console.log()
      }
    }).catch((err) => {
      console.warn(err);
    });
  };
}

export function createComment(imageId, data) {
  return async (dispatch) => {
    apiCreateComment(imageId, data).then((resp) => {
      if(resp&&resp.data){
        this.fetchComments(imageId)
      }
    }).catch((err) => {
      console.warn(err);
    });
  };
}

export function removeComment(imageId, commentId) {
  return async (dispatch) => {
    apiRemoveComment(imageId, commentId).then((resp) => {
      dispatch(deleteComment(commentId))
    }).catch((err) => {
      console.warn(err);
    });
  };
}