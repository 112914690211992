import { Button, Card, Form } from 'antd';
import { isEqual } from 'lodash';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { updateUserInfo } from '../../actions/userActions';
import { getCurrentUser } from '../../selectors/userSelectors';
import { formItemLayout, formTailLayout } from './formProperty';
import generateFormItems from './generateFormItems';
import getFormFields from './getFormFields';

class UserInfoForm extends Component {
  handleSubmit = (e) => {
    const { form, currentUser } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        if (!isEqual(currentUser, { ...currentUser, ...values }))
          this.props.updateUserInfo(currentUser.id, values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { currentUser } = this.props;
    const fields = getFormFields();
    return (
        <Card title={intl.get('USER_INFO')}>
          <Form {...formItemLayout}>
            {generateFormItems(fields, currentUser, getFieldDecorator)}
            <Form.Item {...formTailLayout}>
              <Button type="primary" onClick={this.handleSubmit}>
                {intl.get('CONFIRM_UPDATE_USERINFO')}
              </Button>
            </Form.Item>
          </Form>
        </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: getCurrentUser(state)
  };
};

export default connect(mapStateToProps, { updateUserInfo })(
  Form.create({ name: 'UserInfoForm' })(UserInfoForm)
);
