import React, { Component } from 'react';
import PatientInfo from './PatientInfo';
import ReportTitle from './ReportTitle';
import SampleQuality from './SampleQuality';
import SampleCells from './SampleCells';
import SampleReport from './SampleReport';
import ReportTail from './ReportTail';
import { Divider, } from 'antd';
import { connect } from 'react-redux';
import {
  saveReportPrinted,
} from '../../actions/reportAction';
import style from './index.less';
import {
  updateCurrentImageInfo,} from '../../actions/imageActions';
import { selectEditReportDisabled } from '../../selectors/reportSelector';
function isReportSettingsPage() {
  return window.location.pathname.includes('/preference/reportsettings');
}

class Report extends Component {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    this.state = {
      editing: ''
    };
  }

  componentDidMount() {
    const isSettingsPage = isReportSettingsPage();
    this.setState({ isSettingsPage });
    
  }
  render() {
    const { isSettingsPage, editing } = this.state;
    const { reportConf, report,type  } = this.props;
    const { sample, diagnosis, patient, comments, interpretation } = report;
    let labels = diagnosis && diagnosis.labels ? diagnosis.labels : [];
    return (
        <div
          className={type==='EXPORT_REPORT'?style.printReportContainerDownload:style.printReportContainer}
        >
          <div ref={this.componentRef}>
            <ReportTitle
              editing={editing}
              isSettingsPage={isSettingsPage}
              patient={patient}
              reportConf={reportConf}
            />
            <Divider className={style.divider} style={{ height: '2px' }} />
            <PatientInfo
              editing={editing}
              patient={patient}
              reportConf={reportConf}
            />
            <Divider className={style.divider} />
            <SampleQuality
              labels={labels}
              sample={sample}
              reportConf={reportConf}
            />
            <Divider className={style.divider} />
            <SampleCells
              sample={sample}
              labels={labels}
              isSettingsPage={isSettingsPage}
              reportConf={reportConf}
              printing={true}
            />
            <SampleReport
              interpretation={interpretation}
              labels={labels}
              sample={sample}
              comments={comments}
              reportConf={reportConf}
              printing={true}
            />
            <Divider className={style.divider} />
            <ReportTail report={report} reportConf={reportConf} />
          </div>
          <br/>
        </div>

    );
  }
}

const mapStateToProps = (state) => ({
  currentImageKey: state.imageSelection.currentImageKey,
  currentImageInfo: state.imageSelection.currentImageInfo || {},
  reportConf: state.reportConf,
  disabled: selectEditReportDisabled(state),
  planReporter: state.report.planReporter,
});

export default connect(mapStateToProps, {
  saveReportPrinted,
  updateCurrentImageInfo,
})(Report);
