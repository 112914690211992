import React, { Component } from 'react';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import { Button, message } from 'antd';
import { canRender } from '../Can';
import { startBatchPro, endBatchPro } from '../../actions/productionAction';
import { uniq } from 'lodash';
class StartEndPro extends Component {
  editPro(name) {
    const { selected, notInstage, currentUser } = this.props;
    if (this.props.isNoSelect()) return false;
    let stageList = selected.list.filter((s) => notInstage.includes(s.stage));
    let producerList = uniq(selected.list.map((s) => s.producer));
    if (stageList.length > 0) {
      message.warning(intl.get('PRO_SELECTED_MORE'));
      return false;
    }
    if (producerList.length > 1 || producerList[0] !== currentUser.realname) {
      message.warning(intl.get('PRO_SELECTED_MORE'));
      return false;
    }
    name === 'start' && this.props.startBatchPro();
    name === 'finish' && this.props.endBatchPro();
  }

  render() {
    const { currentUser, label, name } = this.props;
    return (
      <Button
        type="primary"
        disabled={canRender(currentUser.permissions, 'pipeline:produce') ? false : true}
        onClick={() => this.editPro(name)}
      >
        {intl.get(label)}
      </Button>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selected: state.production.selected,
    currentUser: state.currentUser
  };
};

export default connect(mapStateToProps, { startBatchPro, endBatchPro })(StartEndPro);
