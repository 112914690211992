import { message } from 'antd';
import intl from 'react-intl-universal';
import { getCurrentUser } from '../selectors/userSelectors';
import {
  apiFetchGeneralConfig,
  apiGetSwarm,
  apiUpdateGeneralConfig,
  fetchUserConfigurations,
  updateUserConfigurations,
  apiApplyJoinSwarm
} from './api';
import store from '../store';

function updateImageListColumns(imageListColumns) {
  return {
    type: 'UPDATE_IMAGE_LIST_COLUMNS',
    imageListColumns
  };
}

function resetImageListColumns(imageListColumns) {
  return {
    type: 'RESET_IMAGE_LIST_COLUMNS',
    imageListColumns
  };
}

export function updateDefaultCancerType(cancerType) {
  return {
    type: 'UPDATE_DEFAULT_CANCER_TYPE',
    cancerType
  };
}

export function updateCancerTypes(cancerTypes) {
  return {
    type: 'UPDATE_CANCER_TYPES',
    cancerTypes
  };
}

export function updateVisibleConfidence(visibleConfidence) {
  return {
    type: 'UPDATE_VISIBLE_CONFIDENCE',
    visibleConfidence
  };
}

export function updateVisibleNegativeLesion(visibleNegativeLesion) {
  return {
    type: 'UPDATE_VISIBLE_NEGATIVE_LESION',
    visibleNegativeLesion
  };
}

export function setCurrentPage(page) {
  return {
    type: 'SET_CURRENT_PAGE',
    page
  };
}

export function setGeneral(data) {
  return {
    type: 'SET_GENERAL',
    data
  };
}

export function saveVisibleConfidence(visible) {
  return function(dispatch) {
    const user = getCurrentUser(store.getState());
    const data = { is_annotation_prob_visible: visible };
    return updateUserConfigurations(user.id, data).then(
      (response) => {
        dispatch(updateVisibleConfidence(visible));
      },
      (error) => {
        message.error(intl.get('USER_CONFIG_FAILED'));
      }
    );
  };
}

export function saveVisibleNegativeLesion(visible) {
  return function(dispatch) {
    const user = getCurrentUser(store.getState());
    const data = { is_annotation_prob_visible: visible };
    return updateUserConfigurations(user.id, data).then(
      (response) => {
        dispatch(updateVisibleNegativeLesion(visible));
      },
      (error) => {
        message.error(intl.get('USER_CONFIG_FAILED'));
      }
    );
  };
}

export function updateVisibleLabel(visibleLabel) {
  return {
    type: 'UPDATE_VISIBLE_LABEL',
    visibleLabel
  };
}

export function saveVisibleLabel(visible) {
  return function(dispatch) {
    const user = getCurrentUser(store.getState());
    const data = { is_annotation_class_visible: visible };
    return updateUserConfigurations(user.id, data).then(
      (response) => {
        dispatch(updateVisibleLabel(visible));
      },
      (error) => {
        message.error(intl.get('USER_CONFIG_FAILED'));
      }
    );
  };
}

export function updateCurrentClassification(currentClassification) {
  return {
    type: 'UPDATE_CURRENT_CLASSIFICATION',
    currentClassification
  };
}

export function saveCurrentClassification(currentClassification) {
  return function(dispatch) {
    const user = getCurrentUser(store.getState());
    const data = { annotation_classification_count: currentClassification };
    return updateUserConfigurations(user.id, data).then(
      (response) => {
        dispatch(updateCurrentClassification(currentClassification));
      },
      (error) => {
        message.error(intl.get('USER_CONFIG_FAILED'));
      }
    );
  };
}

export function updateSuspectedAnomalousDiameter(suspectedAnomalousDiameter) {
  return {
    type: 'UPDATE_SUSPECTED_ANOMALOUS_DIAMETER',
    suspectedAnomalousDiameter
  };
}

export function saveSuspectedAnomalousDiameter(suspectedAnomalousDiameter) {
  return function(dispatch) {
    const data = { annotation_circle_diameter: suspectedAnomalousDiameter };
    return apiUpdateGeneralConfig(data).then(
      (response) => {
        dispatch(updateSuspectedAnomalousDiameter(suspectedAnomalousDiameter));
      },
      (error) => {
        message.error(intl.get('USER_CONFIG_FAILED'));
      }
    );
  };
}

export function fetchVisibleColumnsAsync() {
  return function(dispatch) {
    const user = getCurrentUser(store.getState());
    return fetchUserConfigurations(user.id).then(
      (response) => {
        const columns = response.data.visible_columns;
        dispatch(resetImageListColumns(columns));
      },
      (error) => {
        message.error(intl.get('USER_CONFIG_FAILED'));
      }
    );
  };
}

export function resetDefaultVisibleColumnsAsync() {
  return function(dispatch) {
    const user = getCurrentUser(store.getState());
    return fetchUserConfigurations(user.id).then(
      (response) => {
        const columns = response.data.default_visible_columns;
        dispatch(saveVisibleColumnsAsync(columns));
      },
      (error) => {
        message.error(intl.get('USER_CONFIG_FAILED'));
      }
    );
  };
}

export function saveVisibleColumnsAsync(columns) {
  return function(dispatch) {
    const user = getCurrentUser(store.getState());
    const data = { visible_columns: columns };
    return updateUserConfigurations(user.id, data).then(
      (response) => {
        dispatch(updateImageListColumns(columns));
      },
      (error) => {
        message.error(intl.get('USER_CONFIG_FAILED'));
      }
    );
  };
}

export function updateConfigurationsByType(data) {
  const user = getCurrentUser(store.getState());
  return updateUserConfigurations(user.id, data).then(
    (response) => {
      return response.data;
    },
    (error) => {
      message.error(intl.get('USER_CONFIG_FAILED'));
    }
  );
}

export function fetchConfigurationsPlayTime() {
  const user = getCurrentUser(store.getState());
  return fetchUserConfigurations(user.id).then(
    (response) => {
      const columns = response.data.annotation_play_interval;
      return columns;
    },
    (error) => {
      message.error(intl.get('USER_CONFIG_FAILED'));
    }
  );
}

export function fetchGeneralConfig() {
  return function(dispatch) {
    const user = getCurrentUser(store.getState());
    // if(user.id){
    return apiFetchGeneralConfig(user.id).then(
      (response) => {
        if (response) {
          dispatch(setGeneral(response.data));
          dispatch(updateSuspectedAnomalousDiameter(response.data.annotation_circle_diameter));
          response.data.product_mode === 1 && dispatch(setCurrentPage('report'));
          return response.data;
        }
      },
      (error) => {
        console.log(error);
      }
    );
    // }
  };
}

export function updateGeneralConfig(data) {
  return function(dispatch) {
    return apiUpdateGeneralConfig(data).then(
      (response) => {
        dispatch(fetchGeneralConfig());
        return response;
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

/**
 * 获取本节点的申请
 * @returns 节点申请列表
 */
export function getSwarmList() {
  return function(dispatch) {
    return apiGetSwarm().then(
      (response) => {
        if (response) {
          return response.data;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

/**
 *申请加入远端节点
 * @param {object} data
 * @returns
 */
export function joinSwarm(data) {
  return function(dispatch) {
    return apiApplyJoinSwarm(data).then(
      (response) => {
        return response.data;
      },
      (error) => {
        return error.response.data.error_code;
      }
    );
  };
}
