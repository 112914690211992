import React, { Component } from 'react';
import { Row, Col, Input } from 'antd';
import intl from 'react-intl-universal';
import { getParameters } from './ServiceInfo';
import { connect } from 'react-redux';
import styles from './index.less';
import { editService } from '../../actions/service';
import { debounce } from 'lodash';
class ParameterSet extends Component {
  constructor(props) {
    super();
    this.state = {
      hisList: {}
    };
  }

  componentDidMount() {
    const { aim } = this.props;
    this.setState({ hisList: aim[0] ? aim[0].configurations.mapper : {} });
  }
  componentDidUpdate(prevProps,prevState){
    if(prevProps.aim!==this.props.aim){
      this.setState({ hisList: this.props.aim[0] ? this.props.aim[0].configurations.mapper : {} });
    }
  }

  delayedChange = debounce((value, name) => {
    const { aim } = this.props;
    this.update(aim[0].id, { [name]: value });
  }, 500);

  onChangeInput = (event, name) => {
    let info = this.state.hisList
    info[name] = event.target.value
    this.setState({
      hisList:{...info}
    })
    this.delayedChange(event.target.value, name);
  };

  update(id, p) {
    const { aim } = this.props;
    let params = {
      ...aim[0].configurations.mapper,
      ...p
    };
    // let mustList =['name','age','gender','pathology_type','sample_type']
    // let l = mustList.filter(i=>params[i] === undefined || params[i] === null || params[i] === '' )
    // console.log(l)
    // if (l.length>0){
    //   message.error('aaaaaaaaa')
    //   return false
    // }
    this.props.editService(id, {
      configurations: {
        mapper: params
      }
    });
  }
  setTitle() {
    let res = [];
    for (let i = 0; i < 5; i++) {
      res.push(
        <Col span={4} offset={i === 0 ? 0 : 1} key={i}>
          <Row>
            <Col span={9}>{intl.get('CHINESE_TITLE')}</Col>
            <Col span={15}>{intl.get('PARAMS_TITLE')}</Col>
          </Row>
        </Col>
      );
    }
    return res;
  }
  setlist() {
    let res = [];
    // const { aim } = this.props;
    const {hisList} = this.state
    const params = getParameters();
    for (let i = 0; i < 5; i++) {
      if (i !== 0) {
        res.push(
          <Col span={1} className={styles.params_col} key={i * 5}>
            <div className={styles.params_col_bor} />
          </Col>
        );
      }
      res.push(
        <Col span={4} className={styles.params_col} key={i}>
          {params.slice(i * 8, (i + 1) * 8).map((item, index) => {
            return (
              <Row key={index} className={styles.params_item}>
                <Col span={2} className={styles.params_index}>
                  {i * 8 + index + 1}
                </Col>
                <Col span={7} className={styles.params_name}>
                  {item.must ? <span className={styles.params_must}>*&nbsp;</span> : ''}
                  {item.name}
                </Col>
                <Col span={14} offset={1}>
                  <Input
                    onChange={(e) => this.onChangeInput(e, item.key)}
                    value={hisList ? hisList[item.key] : ''}
                  />
                </Col>
              </Row>
            );
          })}
        </Col>
      );
    }
    return res;
  }

  render() {
    return (
      <>
        <Row className={styles.service_params_title}>{this.setTitle()}</Row>
        <Row className={styles.service_params_set}>{this.setlist()}</Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  aim:
    state.service.HisParty.length > 0 ? state.service.HisParty : []
});

export default connect(mapStateToProps, { editService })(ParameterSet);
