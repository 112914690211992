const initial = {
  currentTab: 0,
  uploadVisible: false,
  addTabVisible: false,
  tabs: [],
  counts: [],
  currentSecondTab:0,
  secondCounts:[],
  secondTabs:[]
};

const tabState = (state = initial, action) => {
  if (state.currentTab === undefined) state.currentTab = initial.currentTab;
  if (state.currentSecondTab === undefined || state.currentSecondTab === null) state.currentSecondTab = initial.currentSecondTab;
  if (state.tabs === undefined) state.tabs = initial.tabs;
  if (state.counts === undefined) state.counts = initial.counts;
  if (state.secondTabs === undefined) state.secondTabs = initial.secondTabs;
  if (state.secondCounts === undefined) state.secondCounts = initial.secondCounts;
  switch (action.type) {
    case 'TOGGLE_UPLOAD_GT':
      return {
        ...state,
        uploadVisible: action.show
      };
    case 'TOGGLE_ADD_TAB':
      return {
        ...state,
        addTabVisible: action.show
      };
    case 'ADD_TAB':
      return {
        ...state,
        tabs: [...state.tabs, action.tab],
        counts: [...state.counts, 0]
      };
    case 'REMOVE_TAB':
      return {
        ...state,
        tabs: state.tabs.filter((item, idx) => idx !== action.idx),
        counts: state.counts.filter((item, idx) => idx !== action.idx)
      };
    case 'SWITCH_TAB':
      return {
        ...state,
        currentTab: action.tab.next
      };
    case 'SWITCH_SECOND_TAB':
      return {
        ...state,
        currentSecondTab: action.tab.next
      };

    case 'INITIALIZE_SECOND_TABS':
      return {
        ...state,
        secondTabs: action.secondTabs
      };
      case 'UPDATE_COUNT':
      return {
        ...state,
        counts: action.counts
      };
    case 'INITIALIZE_TAB_QUERY':
      return {
        ...state,
        tabs: action.tabs,
        counts: Array(action.tabs.length).fill(0)
      };
      case 'UPDTAE_SECONDTAB_COUNT':
        return {
          ...state,
          secondCounts:action.secondCounts
        };
      
    default:
      return state;
  }
};

export default { tabState };
