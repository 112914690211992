import React, { Component } from 'react';
import CommonPanel from '../commonPanel';
// import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import RegisterTabs from './RegisterTabs';
import UpdateAccount from './UpdateAccount';
import RegisterTable from './RegisterTable';
import { getAllUserByQueryInfo, getMachine } from '../../../actions/userActions';
import { updateRegister } from '../../../actions/setting';
import { fetchDepartmentAll } from '../../../actions/checkInAction';
import { getRegisterPathName } from './RegisterInfo';
class RegisterAccount extends Component {
  componentDidMount() {
    const pathName = getRegisterPathName();
    const { queryInfo } = this.props.register;
    let query =
      pathName === 'expert'
        ? { ...queryInfo, roles: 'expert', page: 0 }
        : { ...queryInfo, roles: undefined, page: 0 };
    this.props.getAllUserByQueryInfo(query);
    this.props.fetchDepartmentAll();
    this.props.getMachine();
    this.props.updateRegister({ selected: [] });
  }
  render() {
    return (
      <CommonPanel span={24}>
        <RegisterTabs />
        <RegisterTable />
        <UpdateAccount />
      </CommonPanel>
    );
  }
}

const mapStateToProps = (state) => ({
  register: state.settings.register
});

export default connect(mapStateToProps, {
  getAllUserByQueryInfo,
  getMachine,
  updateRegister,
  fetchDepartmentAll
})(RegisterAccount);
