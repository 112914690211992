import Setting from '../components/Setting';
import Analysis from '../components/Analysis';
import ReportSettings from '../components/ReportSettings';
import RegisterAccount from '../components/UserSetting/RegisterAccount';
import Visualization from '../components/Visualization';
import AutoPlaySet from '../components/UserSetting/AutoPlaySet';
import ConfigurationAI from '../components/ConfigurationAI';
import WorkStatistics from '../components/UserSetting/WorkStatistics';
import ImageUploader from '../components/ImageUploader';
import DiagnosticMode from '../components/UserSetting/DiagnosticMode';
import ImageFilter from '../components/UserSetting/ImageFilter';
import TagIdSet from '../components/UserSetting/TagIdSet';
import SendDepart from '../components/UserSetting/SendDepart';
import Distribution from '../components/UserSetting/Distribution';
import Service from '../components/Service';
import DataSource from '../components/UserSetting/DataSource';
import Mintor from '../components/CenterPart/Mintor';
import HospitalCard from '../components/CenterPart/HospitalCard';
import ExpertManagement from '../components/CenterPart/ExpertManagement';
import DataPlatform from '../components/DataPlatform'
const allPart = ['all', 'local', 'expert', 'centerEnd','colony'];
const localPart = ['all', 'local','colony'];
export const sideBarItems = [
  {
    key: 'monitor',
    title: {
      icon: '',
      text: 'MONITOR_PREVIEW'
    },
    path: '/monitor',
    permission: 'client:view:general',
    component: Mintor,
    mode: [0, 1],
    partName: ['centerEnd']
  },
  {
    key: 'dataPlatfrom',
    title: {
      icon: '',
      text: 'DATA_PLATFORM'
    },
    path: '/dataPlatfrom',
    permission: 'client:view:general',
    component: DataPlatform,
    mode: [0, 1],
    partName:['centerEnd']
  },
  {
    key: 'hospital',
    title: {
      icon: '',
      text: 'HOSPITAL_CARD'
    },
    path: '/hospital',
    permission: 'client:view:general',
    component: HospitalCard,
    mode: [0, 1],
    partName: ['centerEnd']
  },
  {
    key: 'expertManagement',
    title: {
      icon: '',
      text: 'EXPERT_MANAGEMENT'
    },
    path: '/expertManagement',
    permission: 'client:view:general',
    component: ExpertManagement,
    mode: [0, 1],
    partName: ['centerEnd']
  },
  {
    key: 'profile',
    title: {
      icon: 'icon-setuser',
      text: 'USER_PROFILE'
    },
    path: '/profile',
    permission: 'client:view:general',
    component: Setting,
    mode: [0, 1],
    partName: allPart
  },
  {
    key: 'report',
    title: { icon: 'icon-setreport', text: 'REPORT_CONFIGURE' },
    path: '/reportsettings',
    permission: 'configuration:modify',
    component: ReportSettings,
    mode: [0, 1],
    partName: localPart
  },
  {
    key: 'registerAccount',
    title: { icon: 'icon-usersetting', text: 'REGISTER_ACCOUNT' },
    path: '/registerAccount',
    permission: 'client:user:add',
    component: RegisterAccount,
    mode: [0, 1],
    partName: localPart
  },
  {
    key: 'visualization',
    title: { icon: 'icon-setvisualiation', text: 'VISUALIZATION' },
    path: '/visualization',
    permission: 'statistics:view',
    component: Visualization,
    mode: [0, 1],
    partName: localPart
  },

  {
    key: 'analysis',
    title: {
      icon: 'icon-setalgorithm',
      text: 'ALGO_ANALYSIS'
    },
    path: '/analysis',
    permission: 'analysis:view',
    component: Analysis,
    mode: [0, 1],
    partName: localPart
  },
  {
    key: 'autoPlaySet',
    title: { icon: 'icon-setauto', text: 'AUTO_PLAY_SETTINGS' },
    path: '/autoPlaySet',
    permission: 'client:image:view',
    component: AutoPlaySet,
    mode: [0, 1],
    partName: allPart
  },
  {
    key: 'configurationAI',
    title: { icon: 'icon-setai', text: 'PAGE_AI_RESULT_CONFIG' },
    path: '/configurationai',
    permission: 'client:image:view',
    component: ConfigurationAI,
    mode: [0, 1],
    partName: allPart
  },
  {
    key: 'workStatistics',
    title: { icon: 'icon-setwork', text: 'WORK_STATISTICS' },
    path: '/workStatistics',
    permission: 'statistics:view:self',
    component: WorkStatistics,
    mode: [0, 1],
    partName: allPart
  },
  {
    key: 'uploader',
    title: {
      icon: 'icon-setimage',
      text: 'IMAGE_UPLOAD'
    },
    path: '/dashboard/upload',
    permission: 'client:image:upload',
    component: ImageUploader,
    mode: [0, 1],
    partName: localPart
  },
  {
    key: 'set',
    title: { icon: 'icon-setset', text: 'SETTINGS_LABEL' },
    permission: 'client:view:devops',
    roles: ['client:view:devops'],
    partName: allPart,
    children: [
      {
        key: 'diagnosticMode',
        text: 'DIAGNIOSIC_MODE',
        path: '/diagnosticMode',
        mode: [0, 1],
        component: DiagnosticMode,
        partName: allPart
      },
      {
        key: 'imageFilter',
        text: 'IMAGE_FILTER_SETTING',
        path: '/imageFilter',
        mode: [0, 1],
        component: ImageFilter,
        partName: allPart
      },
      {
        key: 'tagIdSetting',
        text: 'TAG_ID_SETTING',
        path: '/tagIdSet',
        mode: [0],
        component: TagIdSet,
        partName: ['all','centerEnd','colony']
      },
      {
        key: 'sendDepartmentSetting',
        text: 'SEND_DEPARTMENT_SETTING',
        path: '/SendDepart',
        mode: [0, 1],
        component: SendDepart,
        partName: allPart
      },
      {
        key: 'distributionSetting',
        text: 'DISTRIBUTION_SETTING',
        path: '/Distribution',
        mode: [0, 1],
        component: Distribution,
        partName: allPart
      },
      {
        key: 'service',
        text: 'SERVICE_CONFIG',
        path: '/service',
        mode: [0, 1],
        component: Service,
        partName: allPart
      },
      {
        key: 'dataCource',
        text: 'DATASOURCE',
        path: '/dataSource',
        mode: [0, 1],
        component: DataSource,
        partName: allPart
      }
    ]
  }
];

export const sideBarItemKeys = sideBarItems.map((item) => item.key);
