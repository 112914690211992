import React, { Component } from 'react';
import intl from 'react-intl-universal';
import style from './index.less';
import { connect } from 'react-redux';
import { Drawer, Form, Select, Button, Upload, Input, Row, Col } from 'antd';
import { updateRegister } from '../../../actions/setting';
import { getAddAccount, getEditAccount, expertValue, getRegisterPathName } from './RegisterInfo';
import styles from '../../../less/form.less';
import ImgCrop from 'antd-img-crop';
import { addUser, editUser } from '../../../actions/userActions';
import { cloneDeep, debounce, uniq } from 'lodash';
class UpdateAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: this.props.register.signature,
      confirmDirty: false
    };
  }
  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.register.registerUpdateVisible !== this.props.register.registerUpdateVisible) {
      this.updateFormInitalValue();
      let signature = this.props.register.registerInfo.signature;
      this.setState({
        imgSrc: [null, undefined, ''].includes(signature) ? '' : signature
      });
    }
  }
  onClose() {
    this.props.updateRegister({ registerUpdateVisible: false, isLoading: false });
  }
  customRequest(option) {
    let that = this;
    const formData = new FormData();
    formData.append('files[]', option.file);
    const reader = new FileReader();
    reader.readAsDataURL(option.file);
    reader.onloadend = function(e) {
      if (e && e.target && e.target.result) {
        that.handleChange(e);
        option.onSuccess();
      }
    };
  }
  handleChange(e) {
    const { register } = this.props;
    this.setState({
      imgSrc: e.target.result
    });
    this.props.updateRegister({
      registerInfo: { ...register.registerInfo, signature: e.target.result }
    });
  }

  delayedChange = debounce((value, name) => {
    const { register } = this.props;
    this.props.updateRegister({ registerInfo: { ...register.registerInfo, [name]: value } });
  }, 500);

  onChangeInput = (event, name) => {
    if (name === 'admin_key') {
      this.delayedChange(event.target.value, name);
    } else {
      this.delayedChange(event.target.value.replace(/\s*/g, ''), name);
    }
  };
  onChangeSelect(value, name) {
    const { register } = this.props;
    this.props.updateRegister({ registerInfo: { ...register.registerInfo, [name]: value } });
  }
  handleSubmit = (e) => {
    e.preventDefault();
    const { register } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        register.modalName === 'add' ? this.add(this.props.form) : this.edit(this.props.form);
      }
    });
  };

  formInfo() {
    const { register } = this.props;
    let info = cloneDeep(register.registerInfo);
    const pathName = getRegisterPathName();
    info.roles =
      pathName === 'expert'
        ? expertValue.split('|')
        : info.roles
        ? uniq(info.roles.map((i) => i.split('|')).flat())
        : [];
    delete info.repwd;
    delete info.departmentName;
    return info;
  }

  add(form) {
    let info = this.formInfo();
    this.props.addUser(info, form);
  }
  edit(form) {
    const { register } = this.props;
    let info = this.formInfo();
    this.props.editUser(register.registerInfo.id, info, form);
  }

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value !== form.getFieldValue('password')) {
      callback(intl.get('TWO_PASSWORD_NOT_MATCH'));
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['repwd'], { force: true });
    }
    callback();
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  updateFormInitalValue(data) {
    const { register } = this.props;
    let Info = register.modalName === 'add' ? getAddAccount() : getEditAccount();
    Info.forEach((element) => {
      if (element.key !== undefined) {
        this.props.form.setFieldsValue({
          [element.key]: this.props.register.registerInfo[element.key]
        });
      }
    });
  }

  render() {
    const { register } = this.props;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 5, offset: 2 },
      wrapperCol: { span: 14 },
      labelAlign: 'left',
      colon: false
    };
    const pathName = getRegisterPathName();
    let Info =
      register.modalName === 'add'
        ? getAddAccount().filter((i) => i.partName.includes(pathName))
        : getEditAccount().filter((i) => i.partName.includes(pathName));
    const imageProps = {
      width: 100,
      height: 300,
      resize: true,
      resizeAndDrag: true
    };
    const addTitle =
      pathName === 'expert' ? intl.get('REGISTER_ADD_EXPERT') : intl.get('REGISTER_ADD');
    const editTitle =
      pathName === 'expert' ? intl.get('REGISTER_EDIT_EXPERT') : intl.get('REGISTER_EDIT');
    return (
      <Drawer
        placement="right"
        onClose={this.onClose.bind(this)}
        visible={register.registerUpdateVisible}
        width={480}
        className={styles.update_acc + ' ' + style.update_acc}
      >
        <Row>
          <Col className={style.update_title} span={10} offset={2}>
            {register.modalName === 'add' ? addTitle : editTitle}
          </Col>
        </Row>
        <Form {...formItemLayout} className={styles.formLeft}>
          {Info.map((item, index) => {
            return (
              <Form.Item label={item.name} key={index}>
                {item.type === 'input' &&
                  getFieldDecorator(item.key, {
                    rules: item.rules,
                    initialValue: register.registerInfo[item.key]
                  })(
                    <Input
                      disabled={
                        register.modalName === 'edit' && item.key === 'username' ? true : false
                      }
                      onChange={(e) => this.onChangeInput(e, item.key)}
                      autocomplete="off"
                      maxLength={item.maxLength}
                    />
                  )}
                {item.type === 'select' &&
                  getFieldDecorator(item.key, {
                    rules: item.rules,
                    initialValue: register.registerInfo[item.key]
                  })(
                    <Select
                      showSearch
                      allowClear
                      onChange={(e) => {
                        this.onChangeSelect(e, item.key);
                      }}
                      placeholder={item.placeholder}
                      mode={item.mode === 'multiple' && 'multiple'}
                    >
                      {item.options}
                    </Select>
                  )}
                {item.type === 'password' &&
                  getFieldDecorator(item.key, {
                    rules: [
                      ...item.rules,
                      {
                        validator:
                          item.key === 'repwd'
                            ? this.compareToFirstPassword
                            : this.validateToNextPassword
                      }
                    ],
                    initialValue: register.registerInfo[item.key]
                  })(
                    <Input.Password
                      autocomplete="new-password"
                      onChange={(e) => this.onChangeInput(e, item.key)}
                      maxLength={item.maxLength}
                      onBlur={item.key === 'repwd' ? this.handleConfirmBlur : () => {}}
                    />
                  )}
              </Form.Item>
            );
          })}
          <Form.Item label="　" className={style.up_btn}>
            <ImgCrop {...imageProps}>
              <Upload
                name="avatar"
                listType="picture-card"
                customRequest={this.customRequest.bind(this)}
                showUploadList={false}
              >
                <Button type="primary">{intl.get('ACCOUNT_AUTOGRAPH')}</Button>
              </Upload>
            </ImgCrop>
          </Form.Item>
          <Form.Item label="　" className={style.up_img}>
            {this.state.imgSrc !== '' ? (
              <img src={this.state.imgSrc} alt="avatar" style={{ width: '100%' }} />
            ) : (
              <div className={style.up_img_div} />
            )}
          </Form.Item>
          <Row className={styles.acc_btn}>
            <Col span={4} offset={12}>
              <Button type="default" onClick={this.onClose.bind(this)}>
                {intl.get('CANCEL')}
              </Button>
            </Col>
            <Col span={4} offset={1}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={this.handleSubmit}
                disabled={register.isLoading}
              >
                {intl.get('REPORT_SAVE')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => ({
  register: state.settings.register
});

export default connect(mapStateToProps, { updateRegister, addUser, editUser })(
  Form.create()(UpdateAccount)
);
