export function clearForm(form, fields) {
  const obj = {};
  fields.forEach((k) => {
    obj[k] = '';
  });
  form.setFieldsValue(obj);
}

export function limitDecimals (value)  {
  return value.replace(/\D+/g,'');
  // return value.replace(/[^0-9\-\+]/g,'');
};