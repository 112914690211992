import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import intl from 'react-intl-universal';

export const StageTag = ({ stage, stageInfo }) => {
  return (
    <span>
      {stage!==""&&stageInfo[stage] ? (
        <Tag color={stageInfo[stage].color}>
          {intl.get(stageInfo[stage].translate)}
        </Tag>
      ) : null}
    </span>
  );
};

StageTag.propTypes = {
  stage: PropTypes.number.isRequired
};

export default StageTag;
