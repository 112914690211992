import { message } from 'antd';
import intl from 'react-intl-universal';
import { all, call, cancel, delay, fork, put, select, take } from 'redux-saga/effects';
import { getTabs } from '../selectors/tabSelectors';
import { fetchBatchTriggerDetection, fetchDetection } from './api';
import { unreadImage } from './imageActions';
import { toggleTab } from './tabActions';

function* handleDetectionSuccess(resp, image) {
  if (resp.status === 200) {
    const msg = resp.data.message;
    if (msg.indexOf('detecting') !== -1) {
      message.info(intl.get('IMG_IS_DETECTING', { filename: image.filename }));
    }
  }
  yield put({ type: 'TRIGGER_DETECTION_SUCCESS', image: image });
}

function handleDetectionFailure(err, image) {
  console.log(err);
  const resp = err.response;
  if (resp) {
    const msg = resp.data.message;
    const filename = resp.data.filename;
    message.error(intl.get('ERROR_FAIL_TO_TRIGGER_DETECTION', { filename }));
    if (resp.status === 400) {
      if (msg.indexOf('queue') !== -1) {
        message.error(intl.get('ERROR_QUEUE_FULL'));
      } else if (msg.indexOf('trigger') !== -1) {
        message.error(intl.get('ERROR_BAD_TRIGGER_PARAM'));
      }
    }
  }
}

export function* triggerDetectionForSingleImg(image, type) {
  try {
    const resp = yield call(fetchDetection, image, type);
    yield fork(handleDetectionSuccess, resp, image);
  } catch (err) {
    yield fork(handleDetectionFailure, err, image);
  }
}

export function* watchDetectionSuccess() {
  let task;
  let action;
  while (true) {
    action = yield take('TRIGGER_DETECTION_SUCCESS');
    yield put(unreadImage(action.image.filename));
    if (task) yield cancel(task);
    task = yield fork(function* () {
      yield delay(500);
      const tabs = yield select(getTabs);
      const tab = tabs.findIndex((item) => item.query.status === 1);
      if (tab !== undefined && tab !== null && !isNaN(tab) && tab !== -1) yield put(toggleTab(tab));
      else message.error(intl.get('DETECTING_TAB_NOT_FOUND'));
    });
  }
}

export function* watchTriggerDetection() {
  while (true) {
    const action = yield take('TRIGGER_DETECTION');
    yield all(
      yield action.images.map((img) =>
        fork(triggerDetectionForSingleImg, img, action.detection_type)
      )
    );
  }
}

export const triggerDetection = (images, detection_type) => ({
  type: 'TRIGGER_DETECTION',
  images,
  detection_type
});

export function batchTriggerDetection(option) {
  return async (dispatch) => {
    fetchBatchTriggerDetection(option)
      .then((resp) => {
        if (resp.status === 204) {
          message.info(intl.get('TRIGGER_DETECTION_SUCCESS'));
        }
      })
      .catch((err) => {
        console.log(err);
        message.info(intl.get('TRIGGER_DETECTION_FAILED'));
      });
  };
}
