import { Table, Tooltip } from 'antd';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateQueryInfo } from '../../actions/tableActions';
import { sum } from '../../utils/analysisUtils';
import ImageLevelResultTag from '../ImageLevelResultTag';
import style from './matrixTable.less';

class MatrixTable extends Component {
  static propTypes = {
    matrix: PropTypes.arrayOf(PropTypes.array),
    header: PropTypes.array,
    showPercent: PropTypes.bool,
    size: PropTypes.string
  };

  static defaultProps = {
    size: 'normal'
  };

  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      tableHeader: []
    };
  }

  componentDidMount() {
    this.renderTable();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.matrix, this.props.matrix)) {
      this.renderTable();
    }
  }

  loadCellImages = (record, index, e) => {
    const { header } = this.props;
    const colIndex = parseInt(e.currentTarget.getAttribute('colindex'), 10);
    // const rowIndex = index;
    if (record[header[colIndex]] !== 0) {
      this.props.updateQueryInfo({
        // aiResult: header[colIndex],
        // gtResult: header[rowIndex]
      });
      // this.props.history.push('/images-browser');
    }
  };

  drawColor = (text, line) => {
    const header = this.props.header;
    const record = header.map((h) => line[h]);
    const total = sum(record);
    let ss = {};
    if (header.length === 4) ss = { lineHeight: '380%' };
    if (total > 0 && parseInt(text, 10) !== 0) {
      const transparancy = parseInt(text, 10) / total;
      const content = this.props.showPercent ? (transparancy * 100).toFixed(0) + '%' : text;
      ss = Object.assign(ss, { backgroundColor: `rgba(79, 185, 240, ${transparancy})` });
      return (
        <Tooltip title={String(content)}>
          <div className={style.cellContent} style={ss}>
            {content}
          </div>
        </Tooltip>
      );
    } else {
      const content = this.state.showPercent ? (text * 100).toFixed(0) : text;
      return (
        <Tooltip title={String(content)}>
          <div className={style.cellContent} style={ss}>
            {content}
          </div>
        </Tooltip>
      );
    }
  };

  renderTable() {
    const { matrix, header, size } = this.props;
    let cellSize = parseInt(220 / header.length, 10);
    if (size === 'small') cellSize = 30;
    const title = [
      {
        title: 'GT\\AI',
        dataIndex: 'title',
        key: 'title',
        align: 'center',
        onCell: () => ({
          style: {
            height: cellSize,
            width: cellSize,
            fontWeight: 'bold',
            padding: 0
          }
        }),
        onHeaderCell: () => ({ style: { backgroundColor: 'white', padding: 0 } })
      }
    ].concat(
      header.map((h, index) => ({
        title: <ImageLevelResultTag result={h} />,
        dataIndex: h,
        key: h,
        align: 'center',
        render: this.drawColor,
        onCell: (record, idx) => ({
          style: { width: cellSize, height: cellSize },
          colindex: index,
          onDoubleClick: (e) => {
            this.loadCellImages(record, idx, e);
          }
        }),
        onHeaderCell: () => ({
          style: { backgroundColor: 'white', fontWeight: 'bold', padding: 0 }
        })
      }))
    );
    const data = [];
    matrix.forEach((line, idx) => {
      const col = { key: idx, title: <ImageLevelResultTag result={header[idx]} /> };
      header.forEach((h, i) => {
        col[h] = line[i];
      });
      data.push(col);
    });
    this.setState({ tableData: data, tableHeader: [...title] });
  }
  render() {
    return (
      <div>
        <Table
          className={style.matrixTable}
          dataSource={this.state.tableData}
          columns={this.state.tableHeader}
          pagination={false}
        />
      </div>
    );
  }
}

export default connect((state) => ({}), { updateQueryInfo })(withRouter(MatrixTable));
