/* @flow */
import './main-page.css';
import Can from '../components/Can';
import { connect } from 'react-redux';
import { getCurrentUser } from '../selectors/userSelectors';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import Menubar from '../components/Menubar';
import TableTabs from '../components/TableTabs';
import Timeout from '../components/Timeout';
import CheckIn from '../components/CheckIn';
import Production from '../components/Production';
import {fetchGeneralConfig } from '../actions/configActions';
class MainPage extends Component {
  componentDidMount(){
    this.props.fetchGeneralConfig();
  }
  render() {
    const { currentUser, pageName } = this.props;
    return (
      <div className="main-page">
        <Menubar showToolbox={false} />
        {pageName === 'check' && <CheckIn />}
        {pageName === 'report' && (
          <>
            <TableTabs />
            <Can
              permissions={currentUser.permissions}
              perform="image:modify:mine"
              yes={() => <Timeout />}
              no={() => ''}
            />
          </>
        )}
        {pageName === 'production' && <Production />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  pageName: state.config.pageName
});

export default connect(mapStateToProps,{fetchGeneralConfig})(withRouter(MainPage));
