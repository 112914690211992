import { Button, Modal, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import style from '../../less/modal.less';
// import styles from './index.less';
import ReactToPrint from 'react-to-print';
class PrintListBtn extends Component {
  static propTypes = {
    selecteds: PropTypes.object,
    images: PropTypes.object
  };

  constructor() {
    super();
    this.state = {
      listVisible: false
    };
    this.print = React.createRef();
  }

  setVal = (img) => {
    if (img.rescan) {
      return 'PLEASE_RE_SCAN';
    } else {
      let sugRescan = img.shangpixibao && img.yangxing !== 'H' && img.mohu !== 'L';
      let sugPro = img.yangxing === 'H';
      if (sugRescan && img.imageLevelResult === 'NILM') {
        return 'SUGGEST_RE_SCAN';
      } else if (sugPro && img.imageLevelResult === 'NILM') {
        return 'SUGGEST_RE_PRO';
      } else {
        return 'SCAN_END';
      }
    }
  };
  showBatchModal = () => {
    this.setState({
      listVisible: true
    });
  };

  handleOk = (e) => {
    this.setState({
      listVisible: false
    });
  };

  handleCancel = (e) => {
    this.setState({
      listVisible: false
    });
  };

  setContent() {
    const { selected } = this.props;
    let cir = 30;
    const len = Math.ceil(selected.total / cir);
    const fs = { fontSize: '14px', lineHeight: '32px' };
    let list = [];
    for (let i = 0; i < len; i++) {
      list.push(
        <div style={{ paddingTop: '20px', paddingBottom: '108px' }} key={i}>
          <Row style={{ fontSize: '18px', lineHeight: '32px', fontWeight: 700 }}>
            <Col span={4} offset={2}>
              {intl.get('SERIAL_NUMBER')}
            </Col>
            <Col span={10}>{intl.get('PATIENT_TAG_ID')}</Col>
            <Col span={7} offset={1}>
              {intl.get('EXPLAIN')}
            </Col>
          </Row>
          {selected.data.slice(cir * i, cir * (i + 1)).map((item, index) => {
            return (
              <Row key={index + 1 + cir * i}>
                <Col span={4} offset={2} style={fs}>
                  {index + 1 + cir * i}
                </Col>
                <Col span={10} style={fs}>
                  {item.tagId}
                </Col>
                <Col span={7} offset={1} style={fs}>
                  {intl.get(this.setVal(item))}
                </Col>
              </Row>
            );
          })}
        </div>
      );
    }
    return list;
  }
  render() {
    const { listVisible } = this.state;
    const { selected, forceLoading } = this.props;
    return (
      <>
        <Button
          type="primary"
          disabled={selected.data.length > 0 && !forceLoading ? false : true}
          onClick={this.showBatchModal.bind(this)}
        >
          {intl.get('PRINT_LIST')}
        </Button>

        <Modal
          className={style.checkModal}
          title={intl.get('PRINT_LIST')}
          visible={listVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width="800px"
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              {intl.get('CANCEL')}
            </Button>,
            <ReactToPrint
              key="submit"
              trigger={() => <Button type="primary">{intl.get('PRINT_REPORT')}</Button>}
              content={() => this.print.current}
              onAfterPrint={this.handleCancel}
            />
          ]}
        >
          <div ref={this.print}>{this.setContent()}</div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selected: state.loadImages.selected
  };
};

export default connect(mapStateToProps, {})(PrintListBtn);
