import React, { Component } from 'react';
import { connect } from 'react-redux';
import style from './index.less';
import intl from 'react-intl-universal';
import { Popconfirm } from 'antd';
import { updateRegister } from '../../../actions/setting';
import { getUserById, delUserById } from '../../../actions/userActions';
import { isPartEnd } from '../../../utils/urlUtils';
class regAction extends Component {
  constructor() {
    super();
    this.state = {};
  }
  showUpdate() {
    getUserById(this.props.infoList.id).then((res) => {
      if (res) {
        this.props.updateRegister({
          registerUpdateVisible: true,
          modalName: 'edit',
          registerInfo: { ...this.props.infoList, signature: res.signature }
        });
      }
    });
  }
  confirm(id) {
    this.props.delUserById(id);
  }

  render() {
    const { infoList } = this.props;
    return infoList.rolesList?.includes('expert') && isPartEnd()!=='centerEnd' ? (
      <div className={style.reg_action} />
    ) : (
      <div className={style.reg_action}>
        <span className={style.reg_edit} onClick={this.showUpdate.bind(this)}>
          {intl.get('EDIT_LABEL')}
        </span>
        &nbsp;&nbsp;
        <span className={style.reg_del}>
          {!infoList.essential ? (
            <Popconfirm
              title={intl.get('DEL_WARNING', { name: infoList.username })}
              onConfirm={this.confirm.bind(this, infoList.id)}
              okText={intl.get('YES')}
              cancelText={intl.get('CANCEL')}
            >
              {intl.get('REMOVE_LABEL')}
            </Popconfirm>
          ) : (
            <span />
          )}
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser
  };
};

export default connect(mapStateToProps, { updateRegister, delUserById })(regAction);
