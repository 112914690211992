import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fabric, initOverlay, OpenSeadragon } from 'openseadragon-fabricjs-overlay';
import { getUnhiddenAnnotations } from '../../selectors/annotationSelectors';
import AnnotationOfWSI from './AnnotationOfWSI';

class AnnotationsOfWSI extends Component {
  constructor(props) {
    super(props);
    this.state = { overlay: null };
  }

  componentDidMount() {
    initOverlay(OpenSeadragon, fabric);
    const overlay = window.osdviewer.fabricjsOverlay({ scale: 1 });
    overlay.fabricCanvas().clear();

    this.setState({ overlay });
    window.osdoverlay = overlay;
  }

  componentWillUnmount() {
    this.state.overlay.fabricCanvas().clear();
  }

  render() {
    return (
      <div>
        {this.props.annotations.map((anno, idx) =>
          this.state.overlay ? (
            <AnnotationOfWSI key={anno.id} overlay={this.state.overlay} annotation={anno} />
          ) : (
            ''
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    annotations: getUnhiddenAnnotations(state)
  };
};

export default connect(mapStateToProps)(AnnotationsOfWSI);
