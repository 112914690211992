import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCurrentUser } from '../../selectors/userSelectors';
import Can from '../Can';
import ChangeAdminKeyForm from './ChangeAdminKeyForm';
import { ChangePasswordForm, ChangeSmbPasswordForm } from './PasswordForm';
import UserInfoForm from './UserInfoForm';
import CommonPanel from '../UserSetting/commonPanel';
class Setting extends Component {
  render() {
    const { currentUser } = this.props;

    return (
        <CommonPanel>
          <UserInfoForm />
          <ChangePasswordForm />
          <ChangeSmbPasswordForm />
          <Can
            permissions={currentUser.permissions}
            perform="user:modify:batch"
            yes={() => <ChangeAdminKeyForm />}
            no={() => <div />}
          />
        </CommonPanel>
    );
  }
}

const mapStateToProps = (state) => ({ currentUser: getCurrentUser(state) });

export default connect(mapStateToProps)(Setting);
