import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';
import intl from 'react-intl-universal';
import { formAutoCtrl2 } from '../../utils/statusUtils';
class AutoCtrl extends Component {
  static propTypes = {
    image: PropTypes.object,
    results: PropTypes.string
  };
  constructor() {
    super();
    this.state = {
      tagval: { value: '0', color: '', translate: 'ALL' }
    };
  }
  componentDidMount() {
    const { image } = this.props;
    this.setState({
      tagval: formAutoCtrl2(
        image.imageLeve,
        image.mohu,
        image.yanxing,
        image.yiwu,
        image.shangpixibao
      )
    });
  }

  setTra() {
    const { tagval } = this.state;
    let str = '';
    let list = tagval.translate.split(',');
    list.forEach((i, index) => {
      if (list.length > 1) {
        if (index === 0) {
          str += intl.get(i) + '(';
        } else if (index === list.length - 1) {
          str += intl.get(i) + ')';
        } else {
          str += intl.get(i) + ',';
        }
      } else {
        str += intl.get(i);
      }
    });
    return str;
  }
  render() {
    const { tagval } = this.state;
    // return <Tag color={tagval.color}>{intl.get(tagval.translate)}</Tag>;
    const info = tagval.translate.split(",").map(v=>intl.get(v))
    const text = info.filter((it, i, arr)=>(arr.indexOf(it, 0) === i)).join(",") 
    return (
      <Tooltip title={text}>
        <div style={{color:tagval.color}}>{text}</div>
      </Tooltip>
    );
  }
}

const mapStateToProps = (state) => ({
  queryInfo: state.loadImages.queryInfo
});

export default connect(mapStateToProps, {})(AutoCtrl);
