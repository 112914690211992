import { Button, Checkbox, Form, Input } from 'antd';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { login } from '../../actions/authActions';
import style from './LoginCenterForm.less';

const FormItem = Form.Item;

class LoginCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agree: true
    };
  }
  handleLogin = (e) => {
    e.preventDefault();
    const { form, history } = this.props;
    form.validateFields((err) => {
      if (!err) {
        this.props.login(form, history);
      }
    });
  };

  render() {
    const { form,parent } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Form
        className={parent==="mainPageLogin"?style.login_expertmainPage_Form:style.login_expert_Form}
        onSubmit={(e) => {
          this.handleLogin(e);
        }}
      >
        <FormItem className={style.formItem}   colon={false} >
          {getFieldDecorator('username', {
            rules: [
              {
                required: true,
                message: intl.get('ACCOUNT_IS_NULL_MSG')
              }
            ]
          })(
            <Input
              placeholder={intl.get('PLACRE_HOLDER_NAME', { account: '' })}
            />
          )}
        </FormItem>
        <FormItem className={style.formItem} colon={false} >
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: intl.get('PASSWORD_IS_NULL_MSG')
              }
            ]
          })(
            <Input.Password  onCopy={() => false}  placeholder={intl.get('PLACRE_HOLDER_PWD')}/>
            )}
        </FormItem>
        <Button className={style.loginButton} type="primary" htmlType="submit" disabled={!this.state.agree}>
          {intl.get('LOGIN')}
        </Button>
        <span>
        <Checkbox checked={this.state.agree} onChange={(e)=>{      
          this.setState({agree: e.target.checked})
        }} > {intl.get('AGREE_TERMS')}</Checkbox> <a href= {intl.getInitOptions().currentLocale === 'en' ? require('../../static/docs/ImsightSoftwareLicenseAgreenment.pdf'): require('../../static/docs/视见医疗科技有限公司软件使用许可协议.pdf')}> {intl.get('SOFTWARE_LICENSE')} </a> </span>
      </Form>
    );
  }
}

const LoginCenterForm = Form.create({ name: 'normal_login' })(LoginCenter);

export default connect(null, { login })(withRouter(LoginCenterForm));
