import colorTable from '../../static/colorTable';

export const shortHeaders = {
  cervical: [
    ['N', 'P'],
    ['N', 'L', 'H', 'G'],
    ['N', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'],
    ['N', 'A', 'B', 'C', 'D', 'E']
  ],
  urine: [
    ['N', 'P'],
    ['N', 'A', 'S', 'H', 'L', 'Other']
  ],
  bladder: [
    ['N', 'P'],
    ['N', 'P']
  ]
};

export const fullNameHeaders = {
  cervical: [
    ['NILM', 'ASC-US|LSIL|ASC-H|HSIL|SCC|AGC|AGC-N|ADC|AIS'],
    ['NILM', 'ASC-US|LSIL', 'ASC-H|HSIL|SCC', 'AGC|AGC-N|ADC'],
    ['NILM', 'ASC-US', 'LSIL', 'ASC-H', 'HSIL', 'SCC', 'AGC', 'AGC-N', 'ADC', 'AIS'],
    ['NORMAL', 'FUNGI', 'TV', 'CC', 'ACTINO', 'HSV']
  ],
  urine: [
    ['NHGUC', 'AUC|SHGUC|HGUC|LGUN|Other'],
    ['NHGUC', 'AUC', 'SHGUC', 'HGUC', 'LGUN', 'Other']
  ],
  bladder: [
    ['NOR', 'ABN'],
    ['NOR', 'ABN']
  ]
};

export const COLOR_TABLE = {
  cervical: [
    [colorTable.green2, colorTable.purple4],
    [colorTable.green2, colorTable.blue2, colorTable.pink2, colorTable.purple4],
    [
      colorTable.green2,
      colorTable.blue1,
      colorTable.blue2,
      colorTable.pink1,
      colorTable.pink2,
      colorTable.pink3,
      colorTable.purple1,
      colorTable.purple2,
      colorTable.purple3,
      colorTable.purple5_5,
    ],
    [
      colorTable.green2,
      colorTable.yellow1,
      colorTable.yellow2,
      colorTable.yellow3,
      colorTable.yellow4,
      colorTable.yellow5
    ]
  ],
  urine: [
    [colorTable.green2, colorTable.purple4],
    [
      colorTable.green2,
      colorTable.blue2,
      colorTable.pink2,
      colorTable.purple4,
      colorTable.yellow5,
      colorTable.grey
    ]
  ],
  bladder: [
    [colorTable.green2, colorTable.purple4],
    [colorTable.green2, colorTable.purple4]
  ]
};
