import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { Form, Input, Modal, Col, Row,Tooltip } from 'antd';
import styles from './index.less';
import SummaryInfo from './SummaryInfo';
import { updateChckInInfoParams } from '../../actions/checkInAction';
import {RatioBetween} from "../../utils/lessUtils"
const { TextArea } = Input;
class OtherInfo extends Component {
  constructor() {
    super();
    this.state = {
      showSummary: false
    };
  }

  componentDidMount() {}

  showSummaryModal() {
    this.setState({ showSummary: true }, () => {
      if (this.child) {
        this.child.initalSummaryValue();
      }
    });
  }

  onRef = (ref) => {
    this.child = ref;
  };

  handleOk = () => {
    this.child.saveSummaryInfo();
    this.setState({ showSummary: false });
  };
  handleCancel = () => {
    this.setState({ showSummary: false });
  };

  onChange = (event, name) => {
    this.props.updateChckInInfoParams({ [name]: event.target.value });
  };
  getFields() {
    let children = [];
    const { column, info, location,hisInfo } = this.props;
    const cannotEdit = location === 'report' ? true : false;
    for (let i = 0; i < column.length; i++) {
      children.push(
        <Row key={i}>
          <Col span={column[i].span}>
            <Form.Item
              name={column[i].name}
              label={intl.get(column[i].name)}
              rules={column[i].rules}
              colon={false}
            >
              {column[i].name === 'PATIENT_SUMMARY' ? (
                   <Tooltip
                   placement="top"
                   title={
                     hisInfo[column[i].key] === info[column[i].key] ||
                     [null, undefined, ''].includes(hisInfo[column[i].key])
                       ? ''
                       : intl.get('HIS_TITLE',{info:hisInfo[column[i].key]}) 
                   }
                 >
                <TextArea
                  disabled={cannotEdit}
                  onClick={this.showSummaryModal.bind(this)}
                  value={info[column[i].key]}
                />
                </Tooltip>
              ) : (
                <Tooltip
                placement="top"
                title={
                  hisInfo[column[i].key] === info[column[i].key] ||
                  [null, undefined, ''].includes(hisInfo[column[i].key])
                    ? ''
                    :intl.get('HIS_TITLE',{info:hisInfo[column[i].key]}) 
                }
              >
                <TextArea
                  disabled={cannotEdit}
                  rows="3"
                  onChange={(e) => this.onChange(e, column[i].key)}
                  value={info[column[i].key]}
                />
                </Tooltip>
              )}
            </Form.Item>
          </Col>
        </Row>
      );
    }
    return children;
  }

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 2 },
        lg: { span: 2 },
        xl: { span: 2 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 22 },
        lg: { span: 22 },
        xl: { span: 22 }
      },
      gutter: [16, 14]
      //   labelAlign:'left'
    };
    const { showSummary } = this.state;
    let minH = 0;
    if(RatioBetween(document)) {
      minH = document.body.clientHeight - 900;
    } else {
      minH = document.body.clientHeight - 1000;
    }

    return (
      <>
        <Form {...formItemLayout} className={styles.other_info} style={{ minHeight: minH }}>
          {this.getFields()}
        </Form>
        <Modal
          title={intl.get('SUMMARY_TITLE')}
          visible={showSummary}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={950}
          className={styles.checkModal}
        >
          <SummaryInfo onRef={this.onRef} />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    checkInfo: state.checkIn.checkInfo
  };
};

export default connect(mapStateToProps, { updateChckInInfoParams })(Form.create()(OtherInfo));
