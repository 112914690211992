/* @flow */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addAnnotationChangeAsync, setShowAnnotationClass } from '../../actions/annotationActions';
import {
  // toggleAnnotationSelection,
  setHoveredAnnotationId,
  setCurrentAnnotationId
} from '../../actions/annotationSelectionActions';
import AnnotationThumbnail from './AnnotationThumbnail';
import AnnotationClassMenu from './AnnotationClassMenu';
import { getInvisibleClasses, getLoginUser, getCurrentClassification } from '../../selectors';
import { getLinesToUserOfClass } from '../../selectors/classSelectors';
import AnnotationScroller from './AnnotationScroller';
import VizSensor from 'react-visibility-sensor';
import { isEqual } from 'lodash';
import ThumbnailActions from './ThumbnailActions';
import config from '../../config';
class Annotation extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      visible: true
    };
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.selected !== nextProps.selected ||
      this.props.hovered !== nextProps.hovered ||
      // this.props.checked !== nextProps.checked ||
      this.props.annotationShowUsers !== nextProps.annotationShowUsers ||
      !isEqual(this.props.item, nextProps.item)
    );
  }

  onChangeClass = (item) => {
    const old = getLinesToUserOfClass(this.props.item.lines).find(
      (l) => l.user === this.props.loginUser
    );
    const change = {
      old: old ? old.clazz :null,
      new: item.key,
      user: this.props.loginUser,
      status: 'uploading'
    };
    this.props.addAnnotationChangeAsync(change, this.props.item.id);
    if (this.props.showAnnotationClass !== 'ALL') {
      if (this.props.currentClassification !== 15) {
        const current_classification = config.classifications['class'+this.props.currentClassification];
        for (let v of current_classification) {
          if (v.groundTruth.includes(item.key)) {
            this.props.setShowAnnotationClass(v.name);
          }
        }
      } else {
        this.props.setShowAnnotationClass(item.key);
      }
    }
  };

  // onSelect = () => {
  //   // this.props.toggleAnnotationSelection(this.props.item.id);
  // };

  render() {
    const { item } = this.props;
    const probility = (Number(item.prob) * 100).toFixed(0);

    const selected = this.props.selected;

    const hovered = this.props.hovered;

    let selectStyle = selected ? { border: '1px solid red',width:'102px' } : { border: '1px solid white',width:'102px' };

    selectStyle = hovered ? { border: '1px solid orange',width:'102px' } : selectStyle;

    const classes = getLinesToUserOfClass(item.lines)
      .filter((l) => this.props.annotationShowUsers.includes(l.user))
      .map((l) => l.clazz)
      .filter((c) => !this.props.hiddenClasses.includes(c));
   
    return (
      <div style={selectStyle} ref={this.ref}>
        <AnnotationScroller
          annoRef={this.ref}
          selected={selected}
          shouldScroll={!this.state.visible}
        />
        <AnnotationClassMenu
          clump={item.clump}
          prob={probility}
          classes={classes}
          points={item.points}
          users={item.users}
          lines={item.lines}
          onMenuItemClicked={this.onChangeClass.bind(this)}
        />
        <div
          onMouseOver={() => {
            this.props.setHoveredAnnotationId(item.id);
          }}
          onMouseOut={() => {
            this.props.setHoveredAnnotationId(null);
          }}
        >
          <VizSensor
            onChange={(isVisible) => {
              this.setState({ visible: isVisible });
            }}
          > 
            <AnnotationThumbnail
              item={item}
              setCurrentAnnotationId={this.props.setCurrentAnnotationId}
            />
          </VizSensor>

          <ThumbnailActions item={item} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  annotationShowUsers: state.annotationUsers.annotationShowUsers,
  showAnnotationClass: state.imageSelection.showAnnotationClass,
  hiddenClasses: getInvisibleClasses(state),
  loginUser: getLoginUser(state),
  currentClassification: getCurrentClassification(state)
});

export default connect(mapStateToProps, {
  setCurrentAnnotationId,
  setHoveredAnnotationId,
  // toggleAnnotationSelection,
  addAnnotationChangeAsync,
  setShowAnnotationClass
})(Annotation);
