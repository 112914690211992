import React, { Component } from 'react';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import { Button, Modal, Upload } from 'antd';
// import config from '../../config';
import  style from './index.less'
import Icon from '../Iconfont';
const { Dragger } = Upload;
class AddHospital extends Component {
  constructor() {
    super();
    this.state = {
      uploadVisible: false,
      fileList:[]
    };
  }
  showBatchModal = () => {
    this.setState({
      uploadVisible: true
    });
  };

  handleOk = (e) => {
    this.setState({
      uploadVisible: false
    });
  };

  handleCancel = (e) => {
    this.setState({
      uploadVisible: false
    });
  };

  uploadGT=(info )=>{
    let fileList = [...info.fileList];
    fileList = fileList.map(file => {
      if (file.status==='error') {
        file.response = intl.get('UPLOAD_RESULT_ERROR');
      }
      return file;
    });

    this.setState({ fileList });
  }
  render() {
    const { uploadVisible } = this.state;
    const headers = {
      authorization: `Bearer ${localStorage.getItem('access_token')}`
    };
    return (
      <>
        <Button type='primary' className={style.add_hos}  onClick={this.showBatchModal}>{intl.get('ADD_HOSPITAL')}</Button>
        <Modal
          visible={uploadVisible}
          closable={false}
          footer={null}
          destroyOnClose={true}
          afterClose={this.handleCancel}
          title={intl.get('ADD_HOSPITAL')}
          onCancel={() => {
            this.handleCancel(false);
          }}
        >
          <Dragger
            visible={uploadVisible}
            onChange={this.uploadGT}
            // action={`${config.baseUrl}/api/data/import`}
            method="post"
            name="data"
            headers={headers}
            fileList={this.state.fileList}
          >
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">{intl.get('ADD_HOS_TEXT')}</p>
          </Dragger>
        </Modal>
      </>
    );
  }
}

export default connect((state) => ({}), {})(AddHospital);
