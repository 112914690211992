import React from 'react';
import Consistency from '../Visualization/Consistency';
import CommonPanel from './CommonPanel';
import intl from 'react-intl-universal';
import {fontColor} from "./dataInfo"
function ConsistencyPlatform(props){
  const { data } = props;
  return (
    <CommonPanel name={intl.get('CONSISTENCY')}>
      <Consistency
        data={data}
        parentName="dataPlatform"
        series={{
          chartType: 'line',
          symbol: 'circle',
          symbolSize:8,
          itemStyle: { borderWidth:5, borderColor: 'rgba(42,157,255,0.4)' }
        }}
        otherStyle={{ xyColor:fontColor,height:"90%" }}
        
      />
    </CommonPanel>
  );
}

export default ConsistencyPlatform;
