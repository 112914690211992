import React, { Component } from 'react';
import { connect } from 'react-redux';
import DateSearch from '../CheckIn/DateSearch';
import { Row, Col, Input, DatePicker, Checkbox } from 'antd';
import style from './index.less';
import intl from 'react-intl-universal';
import { setProQueryInfo } from '../../actions/productionAction';
import moment from 'moment';
import RightBtn from './RightBtn';
import Icon from '../Iconfont';
import { initalQuery } from './productionInfo';
import { setStartDate } from '../../utils/dateUtils';
const { Search } = Input;
const { RangePicker } = DatePicker;

class SearchTab extends Component {
  constructor(props) {
    super();
    this.state = {
      searchValue: props.queryInfo.fuzzy
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.queryInfo.fuzzy !== this.props.queryInfo.fuzzy) {
      this.setState({
        searchValue: this.props.queryInfo.fuzzy
      });
    }
  }

  changeDataRange(data) {
    let p = {
      page: 0,
      registrate_from: data.From,
      registrate_to: data.To
    };
    this.props.setProQueryInfo(p);
  }
  changeFuzzy = (e) => {
    this.setState({
      searchValue: e.target.value
    });
  };

  onSearch = (value) => {
    this.props.setProQueryInfo({ page: 0, fuzzy: value });
  };

  isProducer(e) {
    const { queryInfo } = this.props;
    let v = queryInfo.producer !== '' ? '' : e.target.value;
    this.props.setProQueryInfo({ producer: v, page: 0 });
  }

  changeDate(value, dateString) {
    let p = {
      page: 0,
      registrate_from: dateString[0],
      registrate_to: dateString[1]
    };
    this.props.setProQueryInfo(p);
  }
  resetQuery() {
    this.props.setProQueryInfo({ ...this.props.queryInfo, ...initalQuery });
    this.setState({ searchValue: '' });
  }
  disabledDate(current) {
    return setStartDate(current);
  }
  render() {
    const { queryInfo, currentUser } = this.props;
    const { searchValue } = this.state;
    let timeList =
      queryInfo.registrate_from !== '' && queryInfo.registrate_to !== ''
        ? [moment(queryInfo.registrate_from), moment(queryInfo.registrate_to)]
        : null;
    return (
      <Row gutter={30} className={style.pro__search} style={{ marginLeft: 0, marginRight: 0 }}>
        <Col span={3}>
          <Search
            placeholder={intl.get('SEARCH_TEXT')}
            value={searchValue}
            onChange={this.changeFuzzy}
            onSearch={this.onSearch}
          />
        </Col>
        <Col span={5}>
          <RangePicker
            disabledDate={this.disabledDate.bind(this)}
            showTime={{
              format: 'HH:mm',
              defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')]
            }}
            value={timeList}
            onChange={this.changeDate.bind(this)}
            style={{ width: '100%' }}
          />
        </Col>
        <Col span={8} className={style.search_right}>
          <DateSearch
            parent={this}
            dateRange={{ From: queryInfo.registrate_from, To: queryInfo.registrate_to }}
            style={{ width: '100%' }}
          />
          <div className={style.pro__is__me}>
            <Checkbox
              value={currentUser.id}
              checked={queryInfo.producer}
              onChange={this.isProducer.bind(this)}
            >
              {intl.get('PRO_IS_MINE')}
            </Checkbox>
          </div>
          <span onClick={this.resetQuery.bind(this)}>
            <Icon type="icon-fresh" />
          </span>
        </Col>
        <RightBtn />
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selected: state.production.selected,
    queryInfo: state.production.queryInfo,
    currentUser: state.currentUser
  };
};

export default connect(mapStateToProps, {
  setProQueryInfo
})(SearchTab);
