import { Form, Input } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import Icon from '../Iconfont';

export default function generateFormItems(fields, currentUser, getFieldDecorator) {
  return fields.map((item, idx) => {
    return (
      <Form.Item key={idx} label={intl.get(item.field.toUpperCase())}>
        {getFieldDecorator(item.field, {
          rules: item.rules,
          initialValue: currentUser[item.field]
        })(
          <Input
            prefix={<Icon type={item.icon} />}
            type={item.type === 'password' ? 'password' : 'text'}
            disabled={!item.editable}
            maxLength={item.maxLength}
          />
        )}
      </Form.Item>
    );
  });
}
