import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import styles from './logo.module.css';
import { connect } from 'react-redux';
import { canRender } from '../Can';
import { toggleTab } from '../../actions/tabActions';
import { getCurrentUser } from '../../selectors/userSelectors';
import { getImageById } from '../../actions/api';
import { updateQueryInfo } from '../../actions/tableActions';
import { setFromImageId } from '../../actions/imageActions';
class Logo extends Component {
  componentDidMount() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.backPage, false);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.backPage, false);
  }

  backPage = () => {
    if (window.location.pathname === '/images-browser') {
      this.selectCurrentImage();
    }
  };

  toMainPage = () => {
    if (window.location.pathname === '/images-browser') {
      this.selectCurrentImage();
    } else {
      this.props.history.push('/mainpage');
    }
  };


  selectCurrentImage = () => {
    const { currentImageInfo, currentTab, loadImages } = this.props;
    const { images, mode, selected } = loadImages;

    const list = mode === 'select' ? selected.data : images.data;
    let l = list.filter((i) => i.id === currentImageInfo.id);
    if (l.length > 0) {
      this.props.setFromImageId(currentImageInfo.id);
      this.props.updateQueryInfo({ page: l[0].page });
    }

    const general = canRender(this.props.currentUser.permissions, 'client:view:general');
    getImageById(currentImageInfo.id)
      .then((response) => {
        if (
          response.data.items[0].distribute_stage === 4 &&
          general &&
          [1, 2].includes(currentTab)
        ) {
          this.props.toggleTab(4);
          this.props.updateQueryInfo({ page: 0 });
        }
        this.props.history.push('/mainpage');
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  render() {
    return (
      <div className={styles.logo__div}>
        <Link onClick={this.toMainPage} to="" className={styles.logo__link}>
          {window.config.isModeTIB ? intl.get('TIB_TRADEMARK_SUB') : intl.get('TRADEMARK')}       
          {window.config.isModeTIB ? undefined : <sup>®</sup> }
          {window.config.isModeTIB ? undefined : <sub> { intl.get('TRADEMARK_SUB')} </sub> }
        </Link>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentImageInfo: state.imageSelection.currentImageInfo,
    currentUser: getCurrentUser(state),
    currentTab: state.tabState.currentTab,
    loadImages: state.loadImages
  };
};

export default connect(mapStateToProps, {
  toggleTab,
  getImageById,
  updateQueryInfo,
  setFromImageId
})(Logo);
