import { createSelector } from 'reselect';
import {
  getClasses,
  getShowUsers,
  getHoveredAnnotationId,
  getCurrentAnnotationId,
  getAnnotations,
  getShowClass,
  getReportAnnotationIds,
  getInvisibleClasses,
  isAnnotationNotSubsetOfClasses,
  isAnnotationOfUsers,
  isAnnotationOfClasses,
  isAnnotationOfUsersAndClasses,
  getClassLimit,
  getClassMarkLimit,
  getCurrentClassification
} from '.';
import {visibleNegativeLesion} from '../selectors';
import config from '../config';
export const getUnhiddenAnnotations = createSelector(
  [getAnnotations, getInvisibleClasses],
  (annotations, invisibles) => {
    const filtered =
      annotations && annotations.filter((anno) => isAnnotationNotSubsetOfClasses(anno, invisibles));
    return annotations ? filtered : [];
  }
);

const getUnlimitAnnotationsOfClasses = createSelector(
  [getUnhiddenAnnotations, getShowUsers, getClasses],
  (annotations, users, classes) => {
    const filtered =
      annotations &&
      classes &&
      users &&
      annotations.filter((anno) => {
        let included = isAnnotationOfUsersAndClasses(anno, users, classes);
        return included;
      });
    return annotations ? filtered : [];
  }
);

export const selectReportAnnotations = (state) => state.report.annotations;
export const getUnlimitMarkAnnotationsOfClasses = createSelector(
  [getUnlimitAnnotationsOfClasses, selectReportAnnotations],
  (annotations, reportAnnos) => {
    const filtered =
      annotations &&
      annotations.filter((anno) => {
        if (
          (reportAnnos && reportAnnos.includes(anno.id)) ||
          (anno.markers && anno.markers.length > 0)
        ) {
          return true;
        }
        return false;
      });
    return annotations ? filtered : [];
  }
);

export const getLimitedAnnotations = createSelector(
  [getUnhiddenAnnotations, getClassLimit],
  (annotations, limit) => {
    const limted =
      limit > 0
        ? annotations.reduce((annos, anno) => {
            const onlyAI =
              anno.users && anno.users.length === 1 && anno.users[0].slice(0, 3) === 'ai_';
            const onlyOneClass = anno.classes && anno.classes.length === 1;
            const filterAnnos =
              onlyOneClass && annos.filter((a) => a.classes.includes(anno.classes[0]));
            const notEnough = filterAnnos && filterAnnos.length < limit;
            return onlyAI ? (notEnough ? annos.concat(anno) : annos) : annos.concat(anno);
          }, [])
        : annotations;
    return limted;
  }
);

export const getLimitedMarkAnnotations = createSelector(
  [getUnlimitMarkAnnotationsOfClasses, getClassMarkLimit],
  (annotations, limit) => {
    const limted =
      limit > 0
        ? annotations.reduce((annos, anno) => {
            const onlyAI =
              anno.users && anno.users.length === 1 && anno.users[0].slice(0, 3) === 'ai_';
            const onlyOneClass = anno.classes && anno.classes.length === 1;
            const filterAnnos =
              onlyOneClass && annos.filter((a) => a.classes.includes(anno.classes[0]));
            const notEnough = filterAnnos && filterAnnos.length < limit;
            return onlyAI ? (notEnough ? annos.concat(anno) : annos) : annos.concat(anno);
          }, [])
        : annotations;
    return limted;
  }
);

export const getAnnotationsOfClasses = createSelector(
  [
    getCurrentClassification,
    getClassLimit,
    getUnlimitAnnotationsOfClasses,
    getShowUsers,
    getClasses
  ],
  (currentClassification, limit, annotations, users, classes) => {
    if(classes.length === 2 && classes[0] === 'Suspected_Anomalous_Areas' && !visibleNegativeLesion()){
        return [];
    }
    
    // console.log("---getAnnotationsOfClasses is",  currentClassification, limit, annotations, users, classes)
    // let filtered = annotations
    let filtered =
      annotations &&
      classes &&
      users &&
      annotations.filter((anno) => {
        let is = isAnnotationOfUsersAndClasses(anno, users, classes);
        // console.log("---return is", is, anno, classes)
        return is;
      });

    if (currentClassification !== 15) {
      const current_classification = config.classifications['class'+ currentClassification];
      let show_class = [];
      for (let cls of current_classification) {
        if (cls.name === classes[0]) {
          show_class = cls.groundTruth;
          break;
        }
      }

      filtered.sort((a, b) => {
        if (a.clump === b.clump) {
          let i_a = show_class.indexOf(a.classes[0]);
          let i_b = show_class.indexOf(b.classes[0]);
          if (i_a === i_b) {
            return b.prob - a.prob;
          } else {
            return i_a - i_b;
          }
        } else {
          return a.clump ? -1 : 1;
        }
      });
    }
    if (limit > 0) {
      if (filtered.length > 8 && filtered[8].clump) {
        let clump_data = filtered.slice(0, 8);
        for (let i = 8; i < filtered.length; i++) {
          if (!filtered[i].clump) {
            let data = filtered.slice(i, Math.min(limit - 8 + i, filtered.length));
            filtered = clump_data.concat(data);
            break;
          }
        }
      }
      filtered.splice(limit);
    }

    // console.log("getAnnotationsOfClasses------------start", classes, filtered.length)
    // filtered.forEach(v=>console.log("getAnnotationsOfClasses",v.id, v.clump, v.classes, v.prob))
    // console.log("getAnnotationsOfClasses------------end", classes)

    return annotations ? filtered : [];
  }
);

export const getAnnotationsOfCollected = createSelector(
  [getLimitedMarkAnnotations, getShowUsers, getClasses],
  (annotations, users, classes) => {
    const filtered =
      annotations &&
      classes &&
      users &&
      annotations.filter((anno) => {
        return isAnnotationOfUsersAndClasses(anno, users, classes);
      });
    return annotations ? filtered : [];
  }
);

export const hasMoreAnnotationsOfClasses = createSelector(
  [getAnnotationsOfClasses, getUnlimitAnnotationsOfClasses],
  (limited, unlimited) => {
    return limited.length < unlimited.length;
  }
);

export const hasMoreMarkAnnotationsOfClasses = createSelector(
  [getAnnotationsOfCollected, getUnlimitMarkAnnotationsOfClasses],
  (limited, unlimited) => {
    return limited.length < unlimited.length;
  }
);

export const getAnnotationIdsOfAllUsersOfClasses = createSelector(
  [getLimitedAnnotations, getClasses],
  (annotations, classes) => {
    const filtered =
      annotations &&
      classes &&
      annotations
        .filter((anno) => {
          return isAnnotationOfClasses(anno, classes);
        })
        .map((anno) => anno.id);
    return annotations ? filtered : [];
  }
);

export const getHoveredAnnotationOfClass = createSelector(
  [getAnnotationsOfClasses, getHoveredAnnotationId],
  (annotations, hoveredId) =>
    annotations ? annotations.find((anno) => hoveredId === anno.id) : null
);

export const getCureentAnnotationOfClass = createSelector(
  [getAnnotationsOfClasses, getCurrentAnnotationId],
  (annotations, currentId) =>
    annotations ? annotations.find((anno) => currentId === anno.id) : null
);

export const getShowingAnnotations = createSelector(
  [getUnhiddenAnnotations, getClassLimit, getShowClass, getShowUsers],
  (annotations, limit, clazz, users) => {
    let show_class = [clazz];
    const currentClassification = getCurrentClassification();

    if (currentClassification !== 15) {
      const current_classification = config.classifications['class'+ currentClassification];
      for (let cls of current_classification) {
        if (cls.name === clazz) {
          show_class = cls.groundTruth;
          break;
        }
      }
    }
    let vannos;
    if (clazz === 'Suspected_Anomalous_Areas') {
      vannos =
        annotations &&
        annotations.filter((a) => a.type === 'Circle').filter((a) => isAnnotationOfUsers(a, users));
    } else {
      vannos =
        annotations &&
        annotations
          .filter(
            (a) =>
              clazz !== 'Suspected_Anomalous_Areas' &&
              a.type !== 'Circle' &&
              !!a.classes.find((c) => {
                return show_class.includes(c) || show_class.includes('ALL');
              })
          )
          .filter((a) => isAnnotationOfUsers(a, users));
    }
    if (currentClassification !== 15) {
      vannos.sort((a, b) => {
        if (a.clump === b.clump) {
          let i_a = show_class.indexOf(a.classes[0]);
          let i_b = show_class.indexOf(b.classes[0]);

          if (i_a === i_b) {
            return b.prob - a.prob;
          } else {
            return i_a - i_b;
          }
        } else {
          return a.clump ? -1 : 1;
        }
      });
    }
    if (limit > 0) {
      if (vannos.length > 8 && vannos[8].clump) {
        let clump_data = vannos.slice(0, 8);
        for (let i = 8; i < vannos.length; i++) {
          if (!vannos[i].clump) {
            let data = vannos.slice(i, Math.min(limit - 8 + i, vannos.length));
            vannos = clump_data.concat(data);
            break;
          }
        }
      }
      vannos.splice(limit);
    }
    return annotations ? vannos : [];
  }
);

export const getVisibleAnnotationIds = createSelector([getShowingAnnotations], (annotations) => {
  return annotations && annotations.map((anno) => anno.id);
});

export const getCureentAnnotation = createSelector(
  [getUnhiddenAnnotations, getCurrentAnnotationId],
  (annotations, id) => (annotations ? annotations.find((anno) => anno.id === id) : null)
);

export const getSelectedAnnotations = createSelector(
  [getShowingAnnotations, getCurrentAnnotationId],
  (annotations, currentAnnotationId) => {
    const index = annotations.findIndex((anno) => anno.id === currentAnnotationId);
    const filterAnnos = annotations.filter((anno, ii) => {
      return ii >= index;
    });
    return filterAnnos;
  }
);

export const getSelectedAnnotationIds = createSelector(
  [getVisibleAnnotationIds, getCurrentAnnotationId],
  (annotationIds, currentAnnotationId) => {
    const index = annotationIds.findIndex((id) => id === currentAnnotationId);
    const filterIds = annotationIds.filter((id, ii) => {
      return ii >= index;
    });
    return filterIds;
  }
);

export const getReportAnnotations = createSelector(
  [getAnnotations, getReportAnnotationIds],
  (annotations, ids) =>
    annotations && ids ? annotations.filter((anno) => ids.includes(anno.id)) : []
);

export const getPointSetAnnotations = createSelector([getShowingAnnotations], (annotations) =>
  annotations.filter((anno) => anno.type === 'Pointset')
);

export const getUpAnnotation = createSelector(
  [getShowingAnnotations, getCurrentAnnotationId],
  (annos, id) => {
    let currentIndex = annos.findIndex((anno) => anno.id === id);
    let upIndex = currentIndex - 3;
    if (upIndex > -1) return annos[upIndex];
    return null;
  }
);

export const getDownAnnotation = createSelector(
  [getShowingAnnotations, getCurrentAnnotationId],
  (annos, id) => {
    let currentIndex = annos.findIndex((anno) => anno.id === id);
    let downIndex = currentIndex + 3;
    if (downIndex < annos.length) return annos[downIndex];
    return null;
  }
);

export const getLeftAnnotation = createSelector(
  [getShowingAnnotations, getCurrentAnnotationId],
  (annos, id) => {
    let currentIndex = annos.findIndex((anno) => anno.id === id);
    let leftIndex = currentIndex - 1;
    if (leftIndex > -1) return annos[leftIndex];
    return null;
  }
);

export const getRightAnnotation = createSelector(
  [getShowingAnnotations, getCurrentAnnotationId],
  (annos, id) => {
    let currentIndex = annos.findIndex((anno) => anno.id === id);
    let rightIndex = currentIndex + 1;
    if (rightIndex < annos.length) return annos[rightIndex];
    return null;
  }
);
