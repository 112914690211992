/* jshint esversion: 6 */
export default {
  TRADEMARK: '爱思见',
  TRADEMARK_SUB: '数字病理图像处理软件',
  TIB_TRADEMARK_SUB: '宫颈细胞学数字图像处理软件',
  PLATFORM_NAME: '数字病理图像处理软件',
  PLATFORM_VERSION:
    '<div>Pathology-Sight {middleV}',
  PLATFORM_VERSIONS: 'Pathology-Sight {middleV}',
  CURRENTLOCALE: 'English',
  COMPANY_NAME_TEXT: '深圳视见医疗科技有限公司',
  IMSIGHT_COPYRIGHT: '版权所有 ©2019 深圳视见医疗科技有限公司',

  // USER
  USER_NOT_EXISTS: '用户{username}不存在',
  USER_ALREADY_EXISTS: '用户{username}已存在',
  USERNAME: '用户名',
  REALNAME_NOT_EMPTY: '真实姓名不能为空',
  EMAIL_NOT_VALID: 'EMAIL不符合规范',
  BACK_TO_LOGIN: '返回登录',
  ADMIN_KEY: '管理员密码',
  ADMIN_KEY_NOT_EMPTY: '管理员密码不能为空',
  REGISTER: '建立新用户',
  ACCOUNT: '帐号',
  PASSWORD: '密码',
  PASSWORD_CONFIRM: '确认密码',
  PASSWORD_FORMAT: '密码长度需8-16位, 且包含大小写字母和数字',
  PASSWORD_NOT_EMPTY: '密码不能为空',
  TWO_PASSWORD_NOT_MATCH: '两次输入的密码不一致',
  SMB_PASSWORD: '传图密码',
  REGISTER_SUCCESS: '注册成功',
  REGISTER_FAILURE: '注册失败{reason}',
  ADMIN_KEY_ERROR: '管理员密码错误',
  NAME: '姓名{name} ',
  EMAIL: '邮箱',
  REGISTER_AT: '注册时间',
  EMAIL_NOT_EMPTY: '邮箱不能为空',
  REALNAME: '真实姓名',
  PLACRE_HOLDER_NAME:'请输入用户名',
  PLACRE_HOLDER_PWD:'请输入密码',
  ACCOUNT_IS_NULL_MSG: '用户名不可为空!',
  PASSWORD_IS_NULL_MSG: '密码不可为空!',
  LOGIN: '登录',
  LOGOUT: '登出',
  ACCOUNT_PASSWORD_ERROR: '账号不存在或密码错误',
  UPDATE: '更新 ',
  PROFILE: '信息',
  UPDATE_PROFILE: '更新信息',
  UPDATE_PASSWORD: '更新密码',
  LOGOUT_SUCCESS: '登出成功',
  TOKEN_EXPIRED: '登录信息过期，请重新登录',
  TOKEN_REVOKED: '登录已被撤销，请重新登录',
  USER_CONFIG_FAILED: '获取用户配置失败',
  AGREE_TERMS:  '我已经全部阅读并同意',
  SOFTWARE_LICENSE:'《软件使用许可协议》',

  // form
  FILL_INFO: '请填写必要信息',

  // image
  FILENAME: '文件名',
  PATIENT_TAG_ID: '病理号',
  SLIDE_ID: '玻片号',
  TAG: '图像标签',
  UPLOADDATE: '上传时间',
  READFLAG: '未读标识',
  UPLOADER: '上传用户',
  IMAGELEVELRESULT: 'AI判读',
  IMAGE_LEVEL_RESULT_AI: 'AI判读',
  PROB: '阴性概率',
  OPERATIONS: '人工复核',
  CLAZZ: '送检样本',
  LASTMODIFY: '最近修改',
  GROUND_TRUTH_RESULT: '诊断意见',
  REMARK: '病例说明',
  IMAGE_PREVIEW: '图像预览',
  PATIENT_NAME: '患者姓名',
  PATIENT_AGE: '患者年龄',
  PATIENT_SEX: '患者性别',
  IMAGE_NOT_EXISTS: '图片不存在',
  UPLOAD_IMAGE_SUCCESS: '上传图像{filename}成功',
  UPLOAD_IMAGE_FAILED: '上传{filename}失败',
  NEGATIVE_RATE: '阴性概率',
  STATUS: '状态',
  IMAGE_CLASS: '送检样本',
  CERVICAL: '宫颈',
  GENEREL: '其他',
  INPUT_GROUND_TRUTH: '请输入诊断结果',
  INPUT_GT_HELP: '按下回车可以提交诊断结果',
  MODIFY_GT_RESULT: '修改诊断结果',
  SCAN_TAG: '扫描',
  UPDATE_TAG: '更新',
  RETRY_RECOGNIZE: '重新识别',

  // Details
  UPLOAD_IMAGE: '点击上传<br>{fileType}',
  IMAGE_TYPE:
    '{sample, plural, =cervical {宫颈液基细胞} =bladder {膀胱组织病理} =urine {尿液脱落细胞} =general {其它图像} }',
  CERVICAL_IMAGE_TYPE: '宫颈液基细胞',
  BLADDER_IMAGE_TYPE: '膀胱组织病理',
  URINE_IMAGE_TYPE: '尿液脱落细胞',
  GENERAL_IMAGE_TYPE: '其它图像',
  CELL_COUNT_GOOD: '满意',
  CELL_COUNT_BAD: '不满意',
  CELL_COUNT_LABEL: '细胞数量',
  DETAIL_RESULT_LABEL: '人工判读',
  PATIENT_INFO_TITLE: '病例资料',
  PATIENT_ID_LABEL: '病理号',
  PATIENT_NAME_LABEL: '姓名',
  PATIENT_GENDER_LABEL: '性别',
  PATIENT_AGE_LABEL: '年龄',
  PATIENT_OPERATION_DONE_TEXT: '病人接受了放射治疗、化疗、子宫切除术',
  DETAIL_REPORT_BUTTON: '查看报告',
  DETAIL_FILE_NAME: '文件名',
  DETAIL_PATIENT_ID: '病理号',
  DETAIL_DETECTION_RESULT: '检测结果',
  DETAIL_ABNORMAL_POSIBILITY: '阳性概率',
  DETAIL_GENERATE_REPORT: '生成报告',
  DETAIL_PRINTED_REPORT: '查看报告',
  DETAIL_BTN_LOCKED: '占用中',
  DETAIL_BTN_LOCKED_TIP: '其他用户占用中',
  DETAIL_RADIOLOGY: '放射治疗',
  DETAIL_CHEMICAL: '化疗',
  DETAIL_CUT: '子宫切除术',
  AI_USERS: 'AI判读',
  OTHER_USERS: '人工复核',
  CHANGE_TRUTH: '修改',
  CHANGE_TRUTH_POPUP_TITLE: '修改诊断结果',
  ANNOTATION_DRAFT: '草稿',

  IMAGE_QUALITY_SATISFACTORY: '{isGood, plural, =true {样本质量满意，} =false {样本质量不满意，} }',
  IMAGE_QUALITY_COUNT:
    '{isEnough, plural, =true {细胞数量（{count}）} =false {细胞数量（{count}）} }',
  IMAGE_QUALITY_COUNT_CRITICAL:
    '{isEnough, plural, =true {大于{critical}} =false {小于{critical}} }',
  IMAGE_QUALITY_CHENGFEN:
    '{isChengfen, plural, =true {可见宫颈管/移行区成分} =false {未见宫颈管/移行区成分} }',
  IMAGE_ID_MISMATCH: '病人信息不匹配，AI无法识别，请联系技术支持。',
  IMAGE_QUALITY_DIRTY: '因为{reason}，不适合评估上皮内病变。',
  IMAGE_QUALITY_YANXING: '{isYanxing, plural, =true {炎性细胞遮盖} =false {}  }',
  IMAGE_QUALITY_XUEYE: '{isXueye, plural, =true {血液遮盖} =false {}  }',
  IMAGE_QUALITY_YIWU: '{isYiwu, plural, =true {异物遮盖} =false {}  }',
  IMAGE_QUALITY_SECHA: '{isSecha, plural, =true {制片染色色差过大} =false {}  }',
  IMAGE_QUALITY_MOHU: '{isMohu, plural, =true {图像模糊或变形} =false {}  }',
  IMAGE_QUALITY_EPITHELIAL_LESS: '上皮细胞数量偏少',
  IMAGE_QUALITY_HAS_RARITY: '异物',
  IMAGE_QUALITY_SAMPLE_AMBIGUITY: '扫描模糊',
  IMAGE_QUALITY_EFFECT_OVER: '超过{percent}有效区',
  IMAGE_QUALITY_SAMPLE_NUM: '样本数量(细胞数量：{count})',
  IMAGE_QUALITY_SAMPLE_CELLS_NUM: '细胞数量：{count}',
  // Image list on Viewer page
  IMAGE_LIST_END_MESSAGE: '共{count}个',
  IMAGE_LIST_SEARCH: '输入玻片号跳转',

  // desc about cells
  NILM: '未见上皮内病变细胞或恶性细胞（NILM）',
  NORMAL: '正常',
  ABNORMAL: '异常',
  INFLAMMATION: '炎症',
  INFLAMMATORY_CELL: '炎细胞',
  MILD: '轻度',
  MODERATE: '中度',
  SEVERE: '重度',
  RADIOTHERAPY_RESPONSE: '放疗反应',
  IUD: '宫内节育器反应(IUD)',
  ATROPHY: '表皮细胞萎缩',
  SQUAMOUS_CELLS: '鳞状细胞',
  EPITHELIAL_CELLS: '上皮细胞',
  ASC: '非典型鳞状上皮细胞',
  ASC_US: 'ASC-US',
  ASC_H: 'ASC-H',
  LSIL: 'LSIL',
  HSIL: 'HSIL',
  SCC: 'SCC',
  GLANDULAR_CELLS: '腺细胞',
  AGC: 'AGC',
  AGC_EC: '宫颈管',
  AGC_EM: '宫内膜',
  AGC_NONE: '不能确定来源',
  AGC_N: '非典型腺细胞，倾向于肿瘤(AGC-N)',
  AGC_N_EC: '宫颈管',
  AGC_N_NONE: '不能确定来源',
  AIS: '子宫颈原位腺癌(AIS)',
  ADC: '腺癌(ADC)',
  ADC_EC: '宫颈管',
  ADC_EM: '宫内膜',
  ADC_EU: '子宫以外',
  ADC_NONE: '不能确定来源',
  BIOLOGICAL_PATHOGENS: '生物性病原体',
  NOT_SEEN: '未见',
  TV: '滴虫(TV)',
  FUNGI: '霉菌(FUNGI)',
  ACTINO: '放线菌(ACTINO)',
  HSV: '疱疹病毒(HSV)',
  CC: '线索细胞(CC)',
  CYTOMEGALOVIRUS: 'HPV',
  OTHER_NON_TUMOR: '其他非肿瘤所见',
  EMC: '有子宫内膜细胞(EMC)(≥45岁)',
  GC_HYSTERECTOMY: '有腺细胞(子宫切除后)',
  undefined: 'Undefined',
  // 'ADC':"ADC",
  //   'AIS': "AIS",
  //   'AGC-N':"AGC-N",
  //   'AGC':'AGC',
  //   'SCC':'SCC',
  //   'HSIL':'HSIL',
  //   'ASC-H':'ASC-H',
  //   'LSIL':'LSIL',
  //   'ASC-US':'LSIL',
  //   'NILM':'NILM',
  //   'FUNGI':'FUNGI',
  //   'TV':'TV',
  //   'CC':"CC",
  //   'ACTINO':'ACTINO',
  //   'HSV': 'HSV',
  //   'NORMAL': 'NORMAL',
  // report results
  COMMENTS1: '建议定期筛查',
  COMMENTS2: '建议1个月后复查',
  COMMENTS3: '建议4-6个月后复查',
  COMMENTS4: '建议1年后复查',
  COMMENTS5: '建议阴道镜检查及活体组织检查或临床考虑',
  COMMENTS6: '建议结合临床考虑',
  COMMENTS7: '建议宫颈管搔刮和子宫内膜活检',
  COMMENTS8: '建议阴道镜检查及宫颈管搔刮',
  COMMENTS9: '建议子宫内膜活检或诊刮',
  COMMENTS10: '建议治疗后复查',
  COMMENTS11: '建议重新取材送检',
  COMMENTS12: '建议阴道镜检查及活体组织检查，及宫颈管搔刮和子宫内膜活检',
  COMMENTS13: '建议阴道镜检查及活体组织检查，及宫颈管搔刮',
  COMMENTS14: '建议阴道镜检查及活体组织检查，及子宫内膜活检或诊刮',
  COMMENTS15: '请重新扫描',
  COMMENTS16: '请重新制片',
  COMMENTS_SELECT_PLACEHOLDER: '常见模板',

  // 判读结果自定义
  COMMENT_NORMAL: '未见上皮内病变细胞或恶性细胞（NILM）',
  COMMENT_INFLAMMATION: '炎症所致的反应性细胞改变',
  COMMENT_RADIOTHERAPY_RESPONSE: '放射性治疗所致的反应性细胞改变',
  COMMENT_IUD: '宫内节育器所致的反应性细胞改变',
  COMMENT_ATROPHY: '表皮细胞萎缩',
  COMMENT_ASC: '非典型鳞状上皮细胞',
  COMMENT_ASC_US: '非典型鳞状上皮细胞，不能明确意义(ASC-US)',
  COMMENT_ASC_H: 'ASC-H',
  COMMENT_LSIL: '低级别鳞状上皮内病变(LSIL)',
  COMMENT_HSIL: 'HSIL',
  COMMENT_SCC: 'SCC',
  COMMENT_AGC: '非典型腺细胞，非特异性(AGC)',
  COMMENT_AGC_EC: '非典型子宫颈管腺细胞，非特异性(AGC)',
  COMMENT_AGC_EM: '非典型子宫内膜腺细胞，子宫内膜腺细胞(AGC)',
  COMMENT_AGC_NONE: '非典型腺细胞，非特异性，不能确定来源(AGC)',
  COMMENT_AGC_N: '非典型，倾向于肿瘤(AGC-N)',
  COMMENT_AGC_N_EC: '非典型子宫颈管腺细胞，倾向于肿瘤(AGC-N)',
  COMMENT_AGC_N_NONE: '非典型腺细胞，倾向于肿瘤，不能确定来源(AGC-N)',
  COMMENT_AIS: '子宫颈管原位腺癌(AIS)',
  COMMENT_ADC: '腺癌(ADC)',
  COMMENT_ADC_EC: '子宫颈管腺癌(ADC)',
  COMMENT_ADC_EM: '子宫内膜腺癌(ADC)',
  COMMENT_ADC_EU: '子宫外腺癌(ADC)',
  COMMENT_ADC_NONE: '腺癌，不能确定来源(ADC)',
  COMMENT_NOT_SEEN: '未见生物性病原体感染',
  COMMENT_TV: '阴道滴虫',
  COMMENT_FUNGI: '形态与白念珠菌符合的真菌',
  COMMENT_ACTINO: '形态与放线菌符合的细菌',
  COMMENT_HSV: '细胞学改变符合单纯疱疹病毒感染',
  COMMENT_CC: '菌群失调，显示细菌性阴道病',
  COMMENT_CYTOMEGALOVIRUS: '细胞学改变符合巨细胞病毒感染',
  COMMENT_EMC: '有子宫内膜细胞(EMC)',
  COMMENT_GC_HYSTERECTOMY: '腺细胞(子宫切除后)',
  // Navigator panel
  NUCLEAR_SIZE_AND_RATIO: '核大小/核质比',
  BRIGHTNESS: '亮度',
  CONTRAST: '对比度',

  // WSI
  WSI_CAVITATION: '挖空',

  // REPORT
  REPORT: '诊断报告',
  REPORT_PATIENT_IFNO_LABEL: '病例信息',
  REPORT_MODE: '报告模式',
  SEE_MODE: '所见即所得',
  SIMPLE_MODE: '简约',
  HOSPITAL_NAME: '医院名称',
  REPORT_SUB_TITLE: '报告名称',
  LOGO: '医院图标',
  PATHOLOGY_ID: '病理号',
  REPORT_LOGO_UPLOAD: '点击上传',
  REPORT_HEADER_INFO: '页头设置',
  REPORT_BASIC_INFO: '基本信息',
  REPORT_FOOTER_INFO: '页脚设置',
  REPORT_SETTING: '报告设置',
  REPORT_SAVE: '保存',
  REPORT_SUBMIT: '提交',
  REPORT_SUBMIT_FAILED: '提交失败',
  REPORT_SUBMIT_SUCCESS: '提交成功',
  REPORT_RESUBMIT: '重新提交',
  REPORT_OCCUPIED: '占用中',

  SCAN_CODE: '条形码',
  GENDER: '性别',
  AGE: '年龄',
  LAST_M: '末次月经',
  IS_M_END: '是否绝经',
  IS_END: '是',
  NO_END: '否',
  SAMPLE_PLACEHOLDER: '送检单位/送检科室/送检医生',
  SAMPLE_UNIT: '送检单位',
  SAMPLE_DEPARTMENT: '送检科室',
  SAMPLE_SENT_DOCTOR: '送检医生',
  SAMPLE_SENT_DATE: '送检日期',
  APPLY_DOCTOR: '申请医师',
  CLINIC_ID: '门诊号',
  ADMISSION_ID: '住院号',
  BED_ID: '床号',
  SAMPLE_TYPE: '送检样本',
  SAMPLE_RECEVIE_DATE: '接收日期',
  PATIENT_ADDRESS: '联系地址',
  PATIENT_TELEPHONE: '联系电话',
  CLINIC_HISTORY: '病史',
  JUNIOR_DOCTOR: '初诊医生',
  SENIOR_DOCTOR: '审核医生',
  REPORT_DOCTOR: '报告医师',
  REPORT_TIME: '报告时间',
  RESPONSIBILITY_STATEMENT: '免责声明',
  COMPANY_NAME: '公司名称',
  COMPANY_WEB: '公司网址',
  COMPANY_TELEPHONE: '服务电话',
  SCREENSHOT_COUNT: '截图最大个数',

  REPORT_PATIENT_GENDER_M: '男',
  REPORT_PATIENT_GENDER_F: '女',
  REPORT_DIAGNOSIS_HISTORY_LABEL: '临床诊断',
  REPORT_RADIOLOGY: '放射治疗',
  REPORT_CHEMICAL: '化疗',
  REPORT_CUT: '子宫切除术',
  REPORT_SATISFIED: '满意',
  REPORT_UNSATISFIED: '不满意',
  REPORT_INTERPRETATION_LABEL: '判读／结果',
  REPORT_REMARK_SUGGESTION: '附注／意见',
  REPORT_HINT: '本系统检测结果仅供参考，最终结果以临床为准，公司不承担任何责任。',
  REPORT_HINT_SIMPLE: '报告仅对来样负责，结果仅供参考',
  Others: "微生物",
  // Error
  ERROR_NETWORK_ERROR: '网络异常，请检查网络连接',
  ERROR_SERVER_ERROR: '服务器异常，请与管理员联系',
  ERROR_DATA_ERROR: '数据异常，可能是格式不正确',
  ERROR_NETWORK_TIMEOUT: '请求超时，请稍后再试',
  ERROR_FAIL_TO_TRIGGER_DETECTION: '检测 {filename} 失败！',
  ERROR_BAD_TRIGGER_PARAM: '检测参数错误',
  ERROR_QUEUE_FULL: '检测队列已满，请稍后再试',
  ERROR_PARAMS_ERROR: '参数错误，请检查后再试',
  ERROR_URL_TIMEOUT: '{ url } 请求超时，请稍后重试',

  // Update
  UPDATE_SUCCESS: '更新{what}成功! {message}',
  UPDATE_FAILED: '更新{what}失败！ {message}',
  NO_NEED_UPDATE: '无需更新',

  // Confirm
  YES: '确定',
  CANCEL: '取消',
  RECOVER: '还原',
  // image-viewer
  CURRENT_SCALE: '当前倍数',

  // Tabs
  HEADER_POSITIVE: '阳性',
  HEADER_SUSPECT: '疑似',
  MICROBIOLOGY: '微生物',
  HEADER_NEGATIVE: '阴性',
  HEADER_UNSATISFIED: '质控不合格',
  HEADER_DETECTED: '检测完成',
  HEADER_DETECTING: '检测列表',
  HEADER_DETECTION_FAILED: '检测失败',
  HEADER_GENERAL: '其他类别',
  HEADER_COLLECTIONS: '我的收藏',
  ADD_TAB: '添加标签页',
  ADD_TAB_TITLE: '页签名：',
  ADD_TAB_INPUT_TITLE: '请输入页签名',
  ADD_TAB_QUERY: '条件：',
  ADD_TAB_QUERY_BUTTON: '添加条件',
  USE_DEFAULT_TABS: '找不到自定义页签, 将采用默认页签',

  // Preference
  PREFERENCE: '偏好设置',
  ALGO_ANALYSIS: '算法分析',
  USER_PROFILE: '用户设置',
  USER_INFO_SET: '用户信息',
  MANAGEMENT: '平台管理',
  USER_MANAGEMENT: '用户管理',
  DASHBOARD: '控制中心',
  IMAGE_UPLOAD: '图像上传',
  USER_ROLES: '权限管理',
  MISSING_WSI: '遗漏图片',
  HELP: '帮助信息',
  PAGE_CONFIG: '页面配置',
  PAGE_AI_RESULT_CONFIG: 'AI 检测结果配置',
  DISPALY: '显示',
  LABEL: '标签',
  CONFIDENCE: '置信度',
  NEGATIVELESION: '疑似异常区域',
  CLASSIFICATION_TYPE: '分类样式',
  CUSTOM_7CLASSIFICATION: '自定义7分类',
  CUSTOM_5CLASSIFICATION: '自定义5分类',
  TBS15CLASSIFICATION: 'TBS15分类',
  SERVICE_CONFIG: '接口配置',
  REPORT_CONFIGURE: '报告设置',
  VISUALIZATION: '统计分析',

  // Analysis
  EXPORT_ANALYSIS_RESULT: '导出',
  SENSITIVITY: '敏感性',
  SENSITIVITY_PLUS: '{clazz, plural, =cervical {LSIL} =urine {AUC}}+敏感性',
  SPECIFIC: '特异性',
  M_SENSITIVITY: '微生物敏感性',
  M_SPECIFIC: '微生物特异性',
  CRUDE_CLASSIFY: '合并分类',
  DETAIL_CLASSIFY: '详细分类',
  MICROBE_CLASSIFY: '微生物',
  MISSING: '漏检',
  FLCHART_TITLE: '漏检/误检表',
  NEGATIVE_ANALYSIS: '阴性分级',
  NR: '排阴率',
  TNR: '真阴率',
  INR: '阴性概率',
  ONLY_POSITIVE: '只看阳性',
  PERCENTAGE: '百分比',
  NUMBER: '数字',
  POSITIVE: '阳性',
  NEGATIVE: '阴性',
  PROB_LT_NR: '阴性概率小于{nr}',
  LOAD_PROP_IMAGES: '加载阴性概率小于{prob}%的图',
  REMOVE_NILM_GRADING_RECORD: '删除',
  NILM_GRADING_EXPLAINATION: `
按照阴性概率，对AI结果为NILM的图像进行降序排列，设当前图像排序位置为N：<br>
- 横坐标表示，前N个图像中，真阴数量占阴性总数的百分比，即“排阴率”<br>
- 纵坐标表示，前N个图像中，真阴图像的百分比，即“真阴率”<br>
鼠标悬停时，同时显示N对应的阴性概率
`,
  ANALYSIS_ONLY_SUPPORT_CERVICAL: '性能分析暂时只支持宫颈液基细胞',
  ANALYSIS_CANCER_TYPE_ERROR: '送检样本错误',

  // Annotation
  ANNOTATION_DELETE: '删除标注',
  ANNOTATION_EMPTY: '无数据',
  CONFLICT: '异议',
  ALL_CLASSES: '所有',
  All: '所有',
  Suspected_Anomalous_Areas: '疑似异常区域',
  Suspected_Anomalous_Size: '疑似异常显示大小',
  Diameter: '直径',
  CLASS_OF_DRAFT: '草稿箱',
  POINTS: '点集',
  NEW_ANNOTATION_CLASS: '+ 新分类',
  CUSTOM_CLAESS: '自定义分类',
  ANNOTATION_LIST_END_MESSAGE:
    '{hasMore, plural, =true {已加载{count}个标注} =false {共{count}个标注}}',
  LOAD_MORE_ANNOTATIONS: '更多',
  DRAG_TO_DRAW_RECT: '标注过大，已使用默认大小',

  // Image Table
  SHOULD_SELECT_IMAGE: '请先勾选图片',
  LOADIMAGES: '{selected, plural, =true {加载所选} =false {加载全部}}',
  OPERATION: '人工复核',
  CHECK_IMAGE: '修改',
  CONFIRM_IMAGE: '确认',
  SELECTED_IMAGE_COUNT: '已选中{count}张图',
  LIST_TOTAL_COUNT: '共{total}张图',
  DELETE_IMAGES_SUCCESS: '成功删除{count}张图',
  DELETE_IMAGES_FAILURE: '以下{count}张图未删除成功：{failure}',
  DELETE_IMAGES_TITLE: '确定删除所选图片吗？({count})',
  DELETE_ANNO_TITLE: '确定删除所选标注吗？',
  TRIGGER_DETECTION_FOR_SELECTED_IMAGES: '检测已选图片',
  ANALYSIS_IMAGES: '分析图片',
  ANALYSIS_BTN_TITLE: '请选择要分析的送检样本',
  EXPORT_CSV: '导出图像信息',
  REFRESH_TABLE: '清空搜索结果并刷新',
  DOWLOAD_RESULT: '下载病人阅片结果',
  DELETE_SELECTED_IMAGES: '删除图片 ({count})',
  UPLOAD_GT_SUCCESS: '上传完成，共：{total}例，失败：{failed}例，成功：{success}例。',
  UPLOAD_GT: '上传诊断结果',
  UPLOAD_GT_FAILED: '上传诊断结果失败，请检查源文件',
  UPLOAD_GT_HELP: `
文件格式： csv 或 xlsx <br>
内容示例: <br>
- 第一列为完整文件名或病人标签 <br>
- 第二列为 NILM 或上皮细胞异常（列明类别） <br>
- 第三列为生物性病原体
`,
  DRAG_UPLOAD_GT: '请点击选择文件，或将文件拖入区域内',
  DRAG_UPLOAD_GT_HINT: '诊断结果文件可以是 csv 或 xlsx 格式',
  DETECTING_TAB_NOT_FOUND: '未找到"检测中"TAB", 请手动切换页签',
  SORT_BY: '排序',
  SORTTING: '顺序',
  SORT_ASC: '升序',
  SORT_DESC: '降序',
  Other: "其他",
  GT_LABEL: '{index, plural, =1 {上皮细胞异常} =2 {生物性病原体}}',
  GT_LABEL_NAME:
    '{name, plural, =eca {上皮细胞异常} =organism {生物性病原体} =urine_abn {尿路上皮细胞异常}}',
  SEARCH_CHAR_HELP: '与：*<br>或：|<br>非：\\\\<br>',

  // Detection
  TRIGGER_DETECTION_TITLE: '请选择检测类型',
  IMG_IS_DETECTING: '图片 {filename} 正在检测中，请稍后',
  TRIGGER_DETECTION_SUCCESS: '送检成功',
  TRIGGER_DETECTION_FAILED: '送检失败',

  // Button
  RECTANGLE_BUTTON: '框标注',
  NAVIGATOR_BUTTON: '导航',
  TAG_BUTTON: '标签',
  COMMENTS_NOTE: '备注',
  SENT_TO_REPORT_BUTTON: '发送到报告',
  MARK: '标记',
  MARKED: '已标记',
  DELETE_BUTTON: '删除标注',
  PLAY_BUTTON: '播放标注',
  CHOOSE_TO_PLAY: '播放',
  NOTHING_TO_PLAY: '片级结果正常，请手动选择标注播放',
  POINT_BUTTON: '点标注',

  // STATUS
  DETECTING: '正在检测',
  WAITING: '等待中',
  QUEUING: '排队中',
  DETECTION_FAILURE: '检测失败',
  UPLOAD_FAILURE: '上传失败',
  SLIDE_ID_USED: '玻片重复使用',
  PIPELINE_TIMEOUT: '计算超时',
  BROKEN_FILE: '源文件损坏',
  NETWORK_ERROR: '网络异常',
  VERIFICATION_SERVICE_DOWN: '服务异常',
  BAD_JSON_FILE: '解析标注错误',
  SLIDE_ID_NOT_REGISTER: '玻片未注册',
  SLIDE_ID_INVALID: '玻片无效',
  JSON_FILE_NOT_FOUND: '没有生成标注',
  FEATURE_NOT_EXISTS: '功能尚未授权',
  FEATURE_ID_EXPIRED: '功能已过期',
  SHARING_VIOLATION: '授权服务冲突',
  MESSAGE_QUEUE_FAIL: '算法出错(1001)',
  INVALID_TASK_TYPE: '无效任务',
  FEATURE_ID_NOT_A_NUMBER: '玻片号字符错误',
  PIPELINE_A_ERROR: '算法出错(a)',
  PIPELINE_B_ERROR: '算法出错(b)',
  UNKOWN_DETECTION_FAILURE: '算法出错(未知)',

  // Dashboard
  // WSI
  UPLOAD_WSI: '上传',

  // Batch Detection
  DETECTION_TOOL_BOX: '检测工具',

  // SETTING
  USER_INFO: '用户信息',
  CONFIRM_UPDATE_USERINFO: '确认修改',
  OLD_PASSWORD: '旧密码',
  NEW_PASSWORD_CONFIRM: '确认密码',
  NEW_PASSWORD: '新密码',
  CHANGE_PASSWORD: '修改密码',
  CHANGE_SMBPASS: '修改传图密码',
  CHANGE_ADMIN_KEY: '修改管理员密钥',
  CONFIRM_CHANGE_PASSWORD: '确认修改',
  OLD_ADMIN_KEY: '旧管理员密钥',
  NEW_ADMIN_KEY: '新管理员密钥',
  OLD_ADMIN_KEY_NOT_EMPTY: '旧管理员密钥不能为空',
  NEW_ADMIN_KEY_NOT_EMPTY: '新管理员密钥不能为空',
  ADMIN_KEY_FORMAT: '管理员密钥需要大于8位或小于16位, 且包含大小写字母和数字',
  OLD_ADMIN_KEY_NOT_MATCH: '旧管理员密码错误，请重试',
  OLD_PASSWORD_NOT_MATCH: '旧密码不正确，请重试',

  // Authorization
  NO_AUTH_TO_DO: '没有权限执行操作',

  // Missing wsi
  WSI: '存储目录',
  SAMBA: '上传目录',

  // Detection Tool Box
  DETECT_ALL_IMAGE: '全部',
  DETECT_FAILED_IMAGE: '检测失败',
  DETECT_PERIOD: '时间段',
  CONFIRM_DETECT_TEXT: '确认检测所选图片吗？({count})',

  // Platform Tool Box
  PLATFORM_TOOLBOX_TITLE: '平台工具',
  RESTART_PLATFORM: '重启平台',
  RESTART_PLATFORM_HINT: '重启平台需要花费1-2分钟，是否继续？',
  RESTART_PLATFORM_SUCCESS: '重启平台成功！',
  RESTART_PLATFORM_FAIL: '重启平台失败，请联系运维人员',
  RESTARTING_PLATFORM: '重启平台中，请稍后...',

  // TCT
  TCT_NILM: '无上皮内病变或恶性病变',
  TCT_ROI: '其他',
  'TCT_ASC-US': '不典型鳞状上皮细胞：属意义不明者',
  TCT_LSIL: '低级别鳞状上皮内病变',
  'TCT_ASC-H': '不典型鳞状上皮细胞：不能排除 HSIL',
  TCT_HSIL: '高级别鳞状上皮内病变',
  TCT_SCC: '鳞状细胞癌',
  TCT_AGC: '非典型腺细胞',
  'TCT_AGC-N': '非典型腺细胞，倾向于肿瘤',
  TCT_AIS: '子宫颈管原位腺癌',
  TCT_ADC: '腺癌',

  // Organism
  ORG_FUNGI: '真菌',
  ORG_TV: '滴虫',
  ORG_CC: '线索细胞',
  ORG_ACTINO: '放线菌',
  ORG_HSV: '疱疹',
  ORG_EMC: '子宫内膜细胞',

  // Cancer types
  CANCER_CERVICAL: '宫颈液基细胞',
  CANCER_URINE: '尿液脱落细胞',
  CANCER_BLADDER: '膀胱组织病理',
  CANCER_GENERAL: '其他图像',
  //
  GlandularEpithelium: '腺上皮细胞异常',
  HighGradeSquamous: '高级别鳞状细胞异常',
  LowGradeSquamous: '低级别鳞状细胞异常',
  Microorganisms: '微生物',
  Endometrial: '宫内膜细胞',
  IMAGE_REMOVED:"图像已删除",
  // Page config

  IMAGE_LIST_COLUMNS: '图像列表展示项',
  RESET_IMAGE_LIST_COLUMNS: '重置',
  DEFAULT_CANCER_TYPE_SETTING: '送检样本设置',
  DEFAULT_CANCER_TYPE: '默认送检样本: ',
  AVAILABLE_CANCER_TYPES: '可选送检样本: ',

  // disk usage
  DISK_CLEAN: '清理',
  DISK_USAGE_WARNING: '存储空间不足',
  DISK_USAGE_ERROR: '服务器存储空间已满，暂停服务。',
  DISK_CLEAN_INFO: "请选择清理磁盘日期范围",
  // image collection
  COLLECTION_TITLE: '{collected, plural, =true {修改收藏} =false {收藏病例}}',
  COLLECTION_CONFIRM: '{collected, plural, =true {修改} =false {收藏}}',
  COLLECTION_CANCEL: '{collected, plural, =true {移除} =false {取消}}',
  COLLECTION_REMARK_INPUT: '添加收藏说明',
  COLLECT_SUCCESS: '收藏成功',
  MARK_MODIFY_SUCCESS: '修改成功',
  GET_COLLECTION_FAILED: '获取收藏说明失败',
  MARK_MODIFY_FAILED: '修改失败',
  COLLECT_FAILED: '收藏失败',
  DISCARD_SUCCESS: '取消收藏',
  DISCARD_FAILED: '取消收藏失败',
  HOSPITAL: '单位',
  TODAY_UPLOADED: '今日上传量',
  TODAY_ANALYSIS: '今日AI诊断量',
  ACC_UPLOADED: '累计上传量',
  ACC_ANALYSIS: 'AI累计诊断量',
  TODAY_UPLOADED_TITLE: '今日上传量',
  TODAY_ANALYSIS_TITLE: '今日诊断量',
  ACC_UPLOADED_TITLE: '累计上传',
  ACC_ANALYSIS_TITLE: 'AI累计诊断',
  NEGATIVE_PER_SLIDE: '阴性判读时间(s)',
  POSITIVE_PER_SLIDE: '阳性判读时间(s)',
  NEG_POS_TIME:"阴/阳/平均AI诊断时间",
  STORE_PERCENT:"数据存储量/占比",
  LICENSE_USE_TOTAL:'授权',
  ALL_RUN_TIME:"累计运行时间",
  PRO_VERSION:'产品版本',
  HOST_PORT:'单机节点IP',
  JAN: '一月',
  FEB: '二月',
  MAR: '三月',
  APR: '四月',
  MAY: '五月',
  JUN: '六月',
  JUL: '七月',
  AUG: '八月',
  SEP: '九月',
  OCT: '十月',
  NOV: '十一月',
  DEC: '十二月',
  HOSPITAL_POSITION: '医院位置',
  ALGO_PERFORMANCE: '算法性能',
  LSIL_SENSITIVITY: 'LISL及以上敏感性',
  AI_JUDGE: 'AI判读',
  HUMAN_JUDGE: '人工判读',
  ANNUAL_WORKLOAD: '本年度工作量',
  CONSISTENCY: '人机判读一致性',
  VISUALIZATION_TITLE: '大数据综合分析平台',
  LOADING_TEXT: '资源正在加载中',
  POSITIVE_RATE: '阳性率',
  TOTAL: '总量',
  QUALITY_CONTROL_PERCENT: '质控满意度',
  PRINT_REPORT: '打印',
  SORT: '排序',
  MOST_SELECTED_WARNING: '最多选择{n}项展示',
  LAYOUT_CHANGE: '布局修改',
  LIMIT_TEXT_LENGTH: '字数限制在{n}字以内',
  REPORT_STAGE_FREE: '待复核',
  REPORT_STAGE_OCCUPIED: '正在编辑',
  REPORT_STAGE_EDITING: '已复核',
  REPORT_STAGE_PRINTED: '已打印',
  STAGE: '工作状态',
  PIPELINE_NOT_FOUND: '打开图像超时，请关闭后重新打开',
  PIPELINE_INCONSISTENT_USER: '图像已被他人占用',
  PIPELINE_NOT_REVERSABLE: '流程不可逆',
  PIPELINE_NOT_SKIPPABLE: '流程不可略过',
  ALL: '全部',
  CERVICAL_LESIONS_DISTRIBUTION: '宫颈病变分布',
  CERVICAL_MICROBIOLOGY_DISTRIBUTION: '宫颈微生物分布',

  IMAGE_NOT_FOUND: '暂无图像',
  SHARE: '分享',
  SHARE_MODAL_TITLE: '分享此页面',
  SHARE_LABEL: '分享链接',
  COLLECTION: '收藏',
  COPY: '复制',
  COPY_TO_CLIPBOARD: '复制到剪贴板',
  POLYGON: '多边形',
  FULLSCREEN: '全屏',
  TEXT_NOT_EMPTY: '文本内容不为空',
  ACTION: '操作',
  ORAN_NAME: '厂家名称',
  SERVICE_HOST: '服务地址',
  SERVICE_PORT: '服务端口',
  TODAY: '今天',
  THREE_DAYS: '近3天',
  ONE_WEEK: '近7天',
  ONE_MONTH: '近1个月',
  DATE_UNLIMIT: '全部',
  REGISTER_DATE: '登记时间',
  REPORTER: '复诊医生',
  PUSH_BEFORE: '未推送',
  PUSH_PENDING: '推送中',
  PUSH_SUCCESS: '推送成功',
  PUSH_FAIL: '推送失败',
  PUSH_STATUS: '推送状态',
  NULL: '无',
  ACTION_VIEW: '阅片',
  ACTION_DELETING: '正在删除',
  ACTION_TO_FETCH: '获取图像',
  ACTION_FETCHING: '正在获取',
  ACTION_FETCH_FAILED: '获取失败！',
  IMAGE_STATUS: '操作',
  SYSTEM_SETTINGS: '系统设置',
  PATHLOGY_ID_FROM: '病理号来源',
  FROM_FILENAME: '从文件名读取',
  FROM_OCR: 'OCR识别',
  DISK_CLEAN_THRESHOLD: '磁盘清理阈值',
  DISK_CLEAN_THRESHOLD_LOWER_LIMIT_WARNING: '磁盘清理阈值不低于{num}%',
  DISK_CLEAN_THRESHOLD_UPPER_LIMIT_WARNING: '磁盘清理阈值不高于{num}%',

  //toptab
  WAIT_INITIAL_DIAGNOSIS: '待初诊',
  INITIAL_DIAGNOSIS: '初诊',
  WAIT_RE_DIAGNOSIS: '待复诊',
  AGAIN_DIAGNOSIS: '复核',
  RE_DIAGNOSIS: '已诊断',
  RE_TIMEOUT: '超时',
  MY_COLLECTION: '我的收藏',

  TO_BE_ALLOCATED: '待分配',
  PUSHED: '已推送',

  //status
  YANXING_DEGREE: '炎性细胞程度',
  YIWU_DEGREE: '异物遮盖程度',
  MOHU_DEGREE: '扫描质量',

  WORK_STATISTICS: '工作统计',
  STATISTICS_DOCTOR_TITLE: '液基细胞学诊断',
  STATISTICS_YEAR_TITLE: '全年工作量统计',
  JUNIOR_STATISTICS: '初诊',
  REPORT_STATISTICS: '复诊',
  ALL_STATISTICS: '全部',

  MANUAL_ADJUSTMENT: '手动调整',
  UPLOAD_RESULT: '导入结果',
  DOWNLOAD_LIST: '导出列表',
  DOWNLOAD_ERROR: '导出结果无数据，请检查您的搜索条件',
  DOWNLOAD_ERROR_MORE: '导出结果数据量较大，请检查您的搜索条件',
  UPLOAD_RESULT_TEXT: '请点击选择文件，或将文件拖入区域内',
  UPLOAD_RESULT_FILE: '文件必须为zip格式',
  UPLOAD_RESULT_ERROR: '上传文件错误',
  ZIP_NAME: '细胞辅助阅片列表',

  ALLOCATION_SETTINGS: '分配设置',
  RECOVERY_TIME: '报告超时回收时间',
  RECOVERY_TIME_MIN: '回收时间不可小于{num}天',
  RECOVERY_TIME_MAX: '回收时间不可大于{num}天',
  RECOVERY_TIME_UNIT: '天',

  AUTO_PLAY_SETTINGS: '自动播放设置',
  PLAY_SPAN: '播放时间间隔:',
  PLAY_UNIT: '秒',
  PLAY_SPAN_MIN: '自动播放不可小于{num}秒',
  PLAY_SPAN_MAX: '自动播放不可大于{num}秒',

  BASE_INFO: '基本信息',
  CLINICAL_INFO: '临床信息',
  GYNEC_INFO: '妇科信息',
  SAMPLE_INFO: '取材信息',
  APPLY_INFO: '申请信息',

  //tabletabs
  BATCH_ALLOCATION: '批量分配',
  SINGLE_ALLOCATION: '单个分配',
  ALLOCATION: '分配',
  ASSIGNED: '已分配',
  TIMEOUT: '已超时',
  WAIT_ALLOCATION: '待分配',
  CANNOT_ALLOCATION: '不可分配',
  SELECT_DOCTOR: '请选择医生',
  SELECTED_IMAGE_NUM: '选中{num}个玻片',
  ALLOCATION_WARN: '选择的医生数量超过要分配的数据量',

  BATCH_PUSH_NUM: '选中{num}张报告推送?',
  BATCH_PUSH_MEG: '成功加入推送队列{success}个,加入失败{failed}个',
  BATCH_PUSH: '批量推送',
  PUSH_SUBMIT: '推送',
  RECALCULATE: '重新计算',
  DEL_IMAGE: '删除图像',
  IS_DEL_IMAGE: '是否删除图像',
  HSD_REVIEWS:'已审核',

  SLIDE_SRC: '片源',
  SCAN_TIMES: '扫描倍数',
  AUTO_QUALITY_CTRL: '自动质控',
  JUNIOR_TIME: '初诊时间',
  SENIOR_TIME: '复诊时间',
  DISTRIBUTE_STAGE: '分配状态',
  JUNIOR_REPORTER: '初诊医生',

  TIMEOUT_TITLE: '您有超时报告，请尽快处理！',
  TIMEOUT_READ: '标记已读',
  TIMEOUT_HAS_READ: '已读',
  TIMEOUT_IGNORE: '忽略',
  TIMEOUT_LIST_TITLE: '消息列表',

  REPORT_STAGE_DISABLE: '不可分配',
  REPORT_STAGE_WAIT_ALLOCATION: '待分配',
  REPORT_STAGE_WAIT_JUNIOR: '待初诊',
  REPORT_STAGE_JUNIORING: '正在初诊',
  REPORT_STAGE_JUNIORED: '已初诊',
  REPORT_STAGE_WAIT_SENIOR: '待复核',
  REPORT_STAGE_SENIORING: '正在复核',
  REPORT_STAGE_WAIT_SENIORED: '已复核',
  REPORT_STAGE_WAIT_PRINTED: '已打印',

  SUBMIT_SENIOR: '审核',
  IMAGE_QUERY_YANXING: '炎细胞遮挡率超75%',
  IMAGE_QUERY_XUEYE: '血细胞遮挡率超75%',

  REALNAME_NOT_LEN: '真实姓名不可超过10个字符',

  UPLOAD_LOGO_ERROR: '图片仅限JPG/PNG格式',
  UPLOAD_LOGO_SIZE_ERROR: '图片大小应该小于512kb!',
  EMAIL_NOT_CHINESE: '邮箱不能含有中文字符',
  LABEL_ERROR_LEN: '页签名不可超过10个字符',
  LABEL_ERROR_QUERY: '页签条件不可超过5个',
  ADD_TAB_ERROR: '条件不可重复',

  AUTO_IMAGE_SATISFACTORY: '合格',
  AUTO_IMAGE_UNSATISFACTORY: "不合格",
  AUTO_LESS: '细胞量偏少',
  AUTO_LESS_MOHU_M: '细胞量偏少（扫描部分模糊）',
  AUTO_LESS_MOHU_H: '细胞量偏少（扫描质量差）',
  AUTO_LESS_YANXING_H: '细胞量偏少（炎细胞遮挡率超75%）',
  AUTO_LESS_YIWU_H: '细胞量偏少（血细胞遮挡率超75%）',
  AUTO_LESS_YANXING_YIWU_H: '细胞量偏少（炎细胞&血细胞遮挡率超75%）',
  AUTO_NORMAL: '细胞量满意',

  /*V14*********************************************************************** */

  CHECK_PAGE: '登记',
  CHECK_IMPORT: '导入',
  PRODUCTION_PAGE: '制片',
  REPORT_PAGE: '报告',
  CHECK_NULL: '请输入申请号！',
  CHECK_FORM: '登记表',
  APPLY_ID: '申请号',
  BASIC_INFO: '基本信息',
  INSPECTION_INFO: '送检信息',
  CHECK_SAMPLE_INFO: '标本信息',
  UNTITLE: '　',
  UNTITLE_NULL: '',
  CHECK_IN_NATION: '民族',
  CHECK_IN_WORK: '职业',
  CHECK_IN_NAVTICEPLACE: '籍贯',
  MARRIED: '已婚',
  UNMARRIED: '未婚',
  MARRY: '婚否',
  M_END: '绝经',
  URGENT_CASE: '加急病例',
  OUTPATIENT_DEP: '门诊',
  HOSPITALIZATION: '住院',
  PHUYSICAL_EXAM: '体检',
  CELLS: '细胞',
  OWN_EXPENSE: '自费',
  MEDICAL_INS: '合作医疗',
  CERVICAL_EXFOLIATED_CELLS: '宫颈脱落细胞',
  CHECK_IN_YEAR: '岁',
  CHECK_IN_MONTH: '月',
  CHECK_IN_DAY: '日',
  CARD_TITLE: '证件号',
  ID_CARD: '身份证',
  OFFICER_CARD: '军官证',
  PASSPORT: '护　照',
  APPLY_TIME: '申请时间',
  INSPECTION_TIME: '送检时间',
  PATHOLOGY_TYPE: '病理类型',
  INSPECTION_PERSON: '送检人',
  INSPECTION_SEPARTMENT: '送检机构',
  REJECT_DOCTOR: '拒收医生',
  REJECT_TIME: '拒收时间',
  REJECT_REASON: '拒收原因',
  SEE_TYPE: '就诊类型',
  PATIENT_ID: '病历号',
  OUTPATIENT_ID: '门诊号',
  HOSPITAL_ID: '住院号',
  HOSPITAL_AREA: '病区',
  MONEY_NUM: '金额',
  MONEY_TYPE: '费别',
  PAIENT_PRIVACY: '病人隐私',
  PAIENT_PRIVACY_NORMAL: '普通',
  PAIENT_PRIVACY_PRI: '隐私',
  SAMPLE_CONTAGION: '传染性标本',
  PATIENT_SUMMARY: '病历摘要',
  CLINICAL_PATIENT: '临床病历',
  CHECK_LIST_TOTAL_COUNT: '共{total}条',
  CHECK_SUCCESS: '登记成功',
  ADD_SAMPLE: '添加样本',
  APPLY_ERROR: '获取不到数据，请重新查询',
  OPERATION_DIAGNOSIS: '手术所见',
  OTHER_DIAGNOSIS: '其他检查',
  SPECIMEN_TYPE: '标本类型',
  SPECIMEN_DATE: '取样日期',
  SPACEMEN_LOC: '取材部位',
  SPACEMEN_NAME_PLACEHOLDER: '标本名称',
  SPACEMEN_EVALUATE: '标本评价',
  CLINIC_DIAGNOSIS: '临床诊断',
  SPACEMEN_NUM: '标本号',
  RECEIVE_TIME: '接收时间',
  RECEIVE_PERSON: '接收人',
  CHECK_SAVE: '保存并新建',
  MODIFY_SAVE:'保存',
  CHECK_ADD: '新建',
  CHECK_REJECT: '拒收',
  CHECK_CANCEL: '取消登记',
  CHECK_BACK: '撤销登记',
  BACK_TITLE: '是否撤销登记？',
  BACK_TEXT: '撤销登记将会删除病例，请谨慎操作',
  BACK_PLACEHOLDER: '请输入申请号',
  UPDATE_CHECK_SUCCESS: '保存成功',

  HIS_TITLE: '修改前：{info}',
  SAVE_MUST_IS_NULL: '请完善信息',
  REJECT_REASON1: '申请单中内容与送检标本名称/数量不符合',
  REJECT_REASON2: '申请单不符，信息不全',
  REJECT_REASON3: '送检标本未贴标签',
  REJECT_REASON4: '送检标签破损无法识别',
  REJECT_REASON5: '标本严重自融、腐败、干涸等',
  REJECT_REASON6: '送检标本瓶(袋)未见组织',
  REJECT_REASON7: '送检标本申请单医嘱停止或作废',
  REJECT_REASON8: '其他可能影响病理检查可行性和诊断准确性的情况',
  REJECT_REASONS: '请选择拒收理由',

  CANCEL_TITLE: '取消登记',
  CANCEL_TEXT: '是否取消登记',
  SAVE_TITLE: '信息缺失',
  SAVE_TEXT: '请完善信息',
  SAVE_APPLY_ID: '请导入申请号',
  RE_REGISTER: '申请号重复',
  SAVE_TEXT_ERROR: '信息验证错误，请检查',
  BACK_ID_ERROR: '申请号与当前等级包申请号信息不符',
  ADD_CHECK_ERROR: '申请号已存在',
  EVALUATE_EXCELLENT: '优',
  EVALUATE_GOOD: '良',
  EVALUATE_BAD: '差',
  EVALUATE_BAD_REASON1: '背景脏',
  EVALUATE_BAD_REASON2: '溢胶',
  EVALUATE_BAD_REASON3: '重叠',
  EVALUATE_BAD_REASON4: '染色差',
  EVALUATE_BAD_REASON5: '细胞数量少',
  EVALUATE_BAD_REASON6: '气泡',
  SELECTED_DIS: '请选择分配员',

  CHECK_TAB1: '接收标本',
  CHECK_TAB2: '拒收标本',

  SUMMARY_TITLE: '病例摘要（请选择所有适合的项目）',
  SUMMARY_CHOICE1: '口服避孕药或避孕针',
  SUMMARY_CHOICE2: '宫内避孕器（IUD）',
  SUMMARY_CHOICE3: '怀孕',
  SUMMARY_CHOICE4: '不正常流血',
  SUMMARY_CHOICE5: '哺乳期',
  SUMMARY_CHOICE6: '产后4个月',
  SUMMARY_HPV1: '高危型HPV检测（+）',
  SUMMARY_HPV2: '中危型HPV检测（+）',
  SUMMARY_HPV3: '低危型HPV检测（+）',
  SUMMARY_CELL_DATE: '以往细胞学检查日期',
  SUMMARY_HPV_DATE: '以往HPV检查日期',
  SUMMARY_REASULT: '结果',
  OPERARION_AFTER: '手术后',
  OPERARION_AFTER_OPTION1: '子宫切除术',
  OPERARION_AFTER_OPTION2: '宫颈锥切术或Leep术',
  OPERARION_AFTER_OPTION3: '卵巢切除术',
  OPERARION_AFTER_OPTION4: '宫颈癌放疗后',
  SEARCH_TEXT: '姓名/病理号',

  REGISTER_ID: '登记号',
  SAMPLE_NUM: '标本件数',
  PRO_ADD: '增加玻片',
  PRO_DISTRIBUTION: '制片分配',
  PRO_START: '开始制片',
  PRO_END: '完成制片',
  PRO_FINISH: '结束制片',
  PRO_WAIT: '等待制片',
  PRO_ING: '正在制片',
  PRO_EVALUE: '切片评价',
  PRO_PRINT: '打印标签',
  PRO_IS_MINE: '本人',
  PRO_SELECTED_WARNING: '请选择玻片',
  PRO_SELECTED_MORE: '操作越界，请重新选择',
  PRO_NO_PRODUCER: '此玻片未分配',
  SECTION_NUM: '切片数量',
  SECTION_INDEX: '切片编号',
  SECTION_MAKER: '制片人',
  SECTION_STATUS: '制片状态',
  SECTION_START_TIME: '开始制片时间',
  SECTION_END_TIME: '结束制片时间',
  TOTAL_COUNT: '共{total}条',
  PRINT_LIST: '打印清单',
  WAIT_PRINT: '待打印',
  WAIT_SEND: '待发放',
  RE_SEND: '已发放',
  SEND_SUCCESS: '发放成功',
  PRINT_SUCCESS: '打印成功',
  PRINT_WARNING: '是否打印完成',
  UPDATE_TAG_ID: '修改病理号',
  UPDATE_TAG_ID_IMAGE: '玻片片头图像',
  UPDATE_TAG_ID_INPUT: '手动输入病理号',
  SYNCHRO_AI: '同步AI结果',
  BATCH_SUMBIT: '批量审核',
  BATCH_CHANGE: '批量改派',
  CHANGE_DOCTOR: '改派',
  CHANGE_DOCTORS: '改派医生',
  CHANGE_DOCTORS_SUCCESS: '改派成功',
  BATH_SEND: '批量发放',
  WITHDRAW: '撤回',
  WITHDRAW_SUCCESS: '撤回成功',
  PREVIEW: '预览',
  SEND: '发放',
  SERIAL_NUMBER: '序号',
  EXPLAIN: '说明',
  DIS_SUCCESS: '分配成功',
  RESTORE_SUCCESS: '重新检测中',
  RESTORE_IMAGE_ERROR: '包含正在检测中的数据，不可重新计算',

  SETTINGS_LABEL: '产品配置',
  TAG_ID_SETTING: '病理号设置',
  SEND_DEPARTMENT_SETTING: '送检单位设置',
  DISTRIBUTION_SETTING: '派片设置',
  TAG_ID_SETTING_TITLE: '病理号设置(液基细胞学)',

  SERVICE_SET: '从报告页面获取',
  VIEW_API_SET: '从申请页面获取',
  SQL_TYPE: '数据库类型',
  SQL_USERNAME: '数据库用户名',
  SQL_PWD: '数据库密码',
  SQL_ADDR: '数据库地址',
  SQL_NAME: '数据库名称',
  VIEW_NAME: '视图名称',
  MYSQL: 'mysql',
  SQLSERVER: 'sqlserver',
  CHINESE_TITLE: '中文名称',
  PARAMS_TITLE: '字段名',

  PREFIX: '前缀',
  DEFAULT_NUMBER: '默认编号',
  CONNECTOR: '连接符',
  NUM_LEN: '数字长度',
  YYYY: 'YYYY',
  YY: 'YY',
  TAG_ID_SHORT: '-',
  TAG_ID_LINE: '一',
  TAG_ID_WAVE: '～',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  ADD_LABEL: '新增',
  EDIT_LABEL: '编辑',
  REMOVE_LABEL: '删除',

  TITLE_ORGANIZATION: '送检机构',
  TITLE_DEPARTMENT: '送检科室',
  TITLE_DOCTOR: '送检医生',
  ADD_ORGANIZATION: '新增机构',
  EDIT_ORGANIZATION: '编辑机构',
  DEL_ORGANIZATION: '删除机构',
  ADD_DEPARTMENT: '新增科室',
  EDIT_DEPARTMENT: '编辑科室',
  DEL_DEPARTMENT: '删除科室',
  ADD_DOCTOR: '新增医生',
  EDIT_DOCTOR: '编辑医生',
  DEL_DOCTOR: '删除医生',
  DEL_WARNING: '确定删除"{name}"',
  DEL_MODAL_WARNING: '确定删除:',
  EDIT_WARING: '请选择一条数据',
  DIS_MODE: '派片方式',
  DIS_SCOPE: '可分配范围',
  DIS_ACTOR: '派片角色',
  AUTO_LABEL: '自动',
  HAND_LABEL: '手动',
  DIS_TIME_LABEL: '指定自动派片时间',
  DIS_ERROR: '派片角色为必选项',
  DIS_TIME_LAN: '连号',
  DIS_TIME_AVER: '阳性平均',
  DIS_TIME_TIME: '每天',
  INNER_LABEL: '院内',
  OUTTER_LABEL: '院外',
  AI: 'AI',
  EXPERT: '专家',
  OVERTIME_SET: '超时设置：',
  REPORT_HISITATION_PERIOD: '报告犹豫期：',
  HISITATION_WARN: '报告犹豫期取值范围为2-120小时',
  HOURS: '小时',
  INCIDENCE_RATE: '发病率（1/10万）',
  DIAGNIOSIC_MODE: '节点设置',
  NODE_MODE:"节点模式",
  NODE_TYPE:"节点类型",
  DIAGNIOSIC_MODE_ONLINE: '全业务流程',
  DIAGNIOSIC_MODE_ALONE: '报告模式',
  REGISTER_ACCOUNT: '注册账户',
  REGISTER_ADD: '新建用户',
  REGISTER_EDIT: '编辑用户',
  REGISTER_DEL: '删除用户',
  REGISTER_ADD_EXPERT: '新建专家',
  REGISTER_EDIT_EXPERT: '编辑专家',
  REGISTER_DEL_EXPERT: '删除专家',
  REGISTER_DEL_WARN: '已选用户中包含不可删除用户，请重新选择',
  REGISTER_DEL_MESSAGE: '确定删除选中用户吗？',
  ACCOUNT_LABEL: '账号名称',
  ACCOUNT_NAME: '用户姓名',
  ACCOUNT_ACTOR: '角色',
  ACCOUNT_UNITS: '单位',
  ACCOUNT_PWD: '密码',
  ACCOUNT_RE_PWD: '确认密码',
  ACCOUNT_TEL: '联系电话',
  ACCOUNT_PHONE: '电话',
  ACCOUNT_EMAIL: '邮箱',
  ACCOUNT_AUTOGRAPH: '上传签名照',
  IMAGE_FILTER_SETTING: '图像过滤设置',
  LIMIT_FILE: '限制文件格式',
  THRESHOLD: '阈值',
  PLEASE_SELECT: '请选择',
  DIY_FILE: '自定义',
  DATA_CLEAN: '数据清洗',
  NODE_MODE_LOC: '单机节点',
  NODE_MODE_REMOTE: '中心节点',
  NODE_MODE_COLONY: '集群节点',
  APPLY_BTN:"申请接入",
  ACCEPTED:"接入成功",
  APPLYING:"等待审批",
  REJECTED:"已拒绝",
  LEAVING:"",
  LEAVED:"已暂停",
  ACCEPTED_STATE:"已经接入",
  REJECTED_STATE:"已拒绝",
  LEAVING_STATE:"",
  LEAVED_STATE:"已断开",
  IP_ERROR:"请检查IP地址和端口是否正确！",
  AGREE:"同意",
  REJECT:"拒绝",
  BREAKOFF:"断开",
  CONCENT:"连接",

  // report
  PRINT_BATCH_REPORT: '打印报告',
  EXPORT_REPORT: '导出报告',
  COLLECT_LABELS: '收藏病例',
  STAGE_EXPLAIN: '状态',
  CANCEL_REPORT: '取消报告',
  CANCEL_REPORT_TITLE: '请选择取消报告原因',
  CANCEL_REPORT_REASON1: '扫描模糊，请重新扫描',
  CANCEL_REPORT_REASON2: '制片质量不合格，请重新制片',
  SUGGEST_RE_SCAN: '建议重新扫描',
  PLEASE_RE_SCAN: '请重新扫描',
  SUGGEST_RE_PRO: '建议重新制片',
  PLEASE_RE_PRO: '请重新制片',
  CANCEL_SUCCESS: '取消成功',
  CANCEL_SUCCESS_EXPERT: '取消成功,2秒后返回报告列表',
  CANCEL_FAILED: '取消失败',
  SCAN_END: '分析完成',
  JUNIOR_PART: '初诊阶段',
  OUTTER_DOCTOR: '院外专家',
  RE_PART: '复诊阶段',
  PRINT_TIME: '打印时间',
  SEND_TIME: '发放时间',
  JUNIOR_RESULT: '初诊意见',
  SENIOR_RESULT: '复诊意见',
  DIS_TIME: '分配时间',
  BATCH_REPORT_DOENLOAD: '批量报告',
  DEL_SUCCESS: '删除成功',
  ADD_SUCCESS: '添加成功',
  INFO_CENTER: '消息中心',
  DATASOURCE: '数据来源',
  DOMAIN_NAME: '域名',
  ACTION_SUCCESS: '操作成功',
  START_PALY_ERROR: '图像正在进行框标注，播放功能暂停使用',
  NAME_NOT_NULL: '名称不可为空，请检查',
  RE_FILE: '文件名重复!',
  TAG_ID_NOT_NULL: '病理号不可为空',
  DIS_TO_ALL: '已分配/计算完成({dis}/{all})',
  REPORT_IS_NULL:'请对报告做出判读',
  NOT_HAS_IMAGE:"当前图像不存在或无权访问",
  //visualization
  HALF_YEAR: '近半年',
  ONE_YEAR: '近一年',
  TWO_YEAR: '近两年',
  QUALITY_CTRL: '质控合格率',
  REPORT_CHECK_INFO: '修改登记表',
  ALGO_VERSION: '算法版本：',
  POS_THRESHOLD: '阳性阈值：',
  SUS_THRESHOLD: '疑似阈值：',

  //中心端
  MONITOR_PREVIEW: '监控预览',
  CENTER_TITLE_SUB: '在下面输入您的账号和密码',
  EXPERT_MANAGEMENT: '专家账号管理',
  HOSPITAL_CARD: '医院证书',
  MAPPING_ADDRESS: '映射域名',
  CARD_MARKER: '标识',
  DOWNLOAD_LOG: '下载日志',
  ADD_HOSPITAL: '添加医院',
  ADD_HOS_TEXT: '请点击选择文件，或将证书拖入区域内',
  DATA_PLATFORM:"大数据平台",
  DATA_PLATFORM_NAME:'宫颈癌细胞学人工智能大数据平台',
  // 专家端
  ACCOUNT_TITLE: '账户信息',
  SETTING: '设置',
  HAD_CANCEL: '已取消',
  WAIT_DIAGNOSIS: '待诊断',
  EXPERT_SOURCE: '报告来源',
  STOP_DIS: '分配数据',
  DISTRIBUTABLE:"正常",
  NOT_DISTRIBUTABLE:"停用",

  //error_code
  ERROR_5005: '不可修改！',
  ERROR_5008: '报告不可撤回！',
  ERROR_5011: '不可打印！',
  ERROR_5012: '不可发放！',
  ERROR_5015: '请先分配初诊医生',
  ERROR_502: '502:内部网络拥堵',
  ERROR_5103: '尝试请求第三方接口失败！',
  ERROR_5201: '单位未查询到',
  ERROR_5203: '存在制片任务，不可切换，请完成所有制片任务',
  ERROR_5501: '登记信息已存在，请重新导入！',
  ERROR_5602: '远程注册信息不存在！',
  ERROR_5604: '未查询到第三方接口！',
  ERROR_5650: '视图配置错误！',
  ERROR_5651: '数据库错误！',
  ERROR_5652: '未查寻到病人登记前信息！',
  ERROR_5653: '	远程诊断配置缺少信息！',
  ERROR_5654: '	向中心端注册失败！',
  ERROR_5701: '机构/科室/医生已经存在',
  ERROR_5707: '机构根源获取不到',
  ERROR_5803: '不可重新制片',
  ERROR_6000: '用户不存在',
  ERROR_6002: '账号不存在或密码错误',
  ERROR_6003: '密钥错误',
  ERROR_6013: '用户涉及数据，不可删除',
  ERROR_6014: '不可删除自己',
  ERROR_6015: '用户已被删除',
  ERROR_6019: '当前节点该用户不存在',
  ERROR_9003: '该用户名已被注册！',
  ERROR_6300:'请勿重复提交！',
  ERROR_6301:'IP或端口错误或后端版本不兼容',
  //actor
  DEVOPS_ACTTOR: '运维人员',
  ADMIN_ACTTOR: '管理员',
  DISTRIBUTOR_ACTTOR: '分配员',
  PRODUCER_ACTTOR: '细胞制片员',
  JUNIOR_DOCTOR_ACTTOR: '初诊医生',
  REGISTER_ACTTOR: '登记员',
  SENIOR_DOCTOR_ACTTOR: '复诊医生',
  REPORTER_ACTOR: '报告员',
  SUPER_ADMIN_ACTTOR: '超级管理员',
  OUT_HOSPITAL_ACTOR: '院外人员',

  //user
  ACCOUNT_LENGTH: '账号名称长度为1-20个字符,不可输入空格',
  ACCOUNT_NULL: '账号名称不可为空',
  USERNAME_LENGTH: '用户姓名长度为1-20个字符,不可输入空格',
  ACTOR_NULL: '请选择角色',
  UNITS_NULL: '请选择单位',
  TEL_ERROR: '联系电话格式错误',
  EMAIL_ERROR: '邮箱格式错误',
  REPWD_ERROR: '请再次输入密码',
  SPAN_ERROR: '禁止输入空格',
  ACCOUNT_ADMIN_KEY: '密钥',
  ADMIN_KEY_LENGTH: '密钥长度为1-20个字符',
  ACCOUNT_AI: '用户名不可以ai开头',
  FAILED_REASON: '失败原因',
  DEL_SUCCESS_FAILSED: '删除成功：{success}例/删除失败：{failed}例',

  //excel
  PRODUCTION_NAME: '产品名称',
  EDITION_NAME: '产品版本信息',
  PRINT_NAME: '打印信息',
  AI_RES_CLASS: 'AI分析结果',
  EXCEL_NAME: '爱思见宫颈癌细胞图像AI辅助阅片结果',
  WARN_CHOOSE: '请选择要导出的数据',

  //大屏
  POSITIVE_AGE:"发病率年龄段分布",
  BACK:"返回",


  //自动质控
  AUTO_CTRL_NUMS_GOOD:'细胞数量满意',
  AUTO_CTRL_NUMS_LESS:'细胞数量少',
  CELL_SHELTER:"炎细胞遮挡",
  SCAN_QUAILITY_GOOD:"扫描质量优",
  SCAN_QUAILITY_VAGUE:"扫描质量模糊",
  SCAN_QUAILITY_BAD:"扫描质量差",
};
