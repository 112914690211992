import React,{useState,useEffect} from 'react'
import { connect } from 'react-redux'
import styles from '../TableTabs/DateSearchContainer.less'
import intl from 'react-intl-universal'
import moment from "moment/moment";
import {GetDateDiff} from "../../utils/dateUtils"
const configs = [
  "TODAY",
  "THREE_DAYS",
  "ONE_WEEK",
  "ONE_MONTH",
  "DATE_UNLIMIT"
]

function getRangeDate(num){
  let day = new Date();
  day.setDate(day.getDate() - num);
  let dateFrom= moment(day).format('YYYY-MM-DD')+" 00:00:00"
  let dateTo= moment(new Date().getTime()).format('YYYY-MM-DD')+" 23:59:59"
  return {
    From:dateFrom,
    To:dateTo 
  }
}

export const DateSearchContainer = (props) => {
  const [activeKey, setActiveKey] = useState("")

  useEffect(() => {
    let nowDate= moment(new Date().getTime()).format('YYYY-MM-DD')+" 23:59:59"

    if(props.dateRange.To === nowDate){
      let dayDif = GetDateDiff(props.dateRange.From,nowDate,'day')
      
      if(dayDif === 0){
        setActiveKey("TODAY")
      }else if(dayDif === 2){
        setActiveKey("THREE_DAYS")
      }else if(dayDif === 6){
        setActiveKey("ONE_WEEK")
      }else if(dayDif === 29){
        setActiveKey("ONE_MONTH")
      }else{
        setActiveKey("")
      }
    }else{
      setActiveKey("")
    }
    if(!props.dateRange.From||!props.dateRange.To){
      setActiveKey("DATE_UNLIMIT")
    }
    return
  }, [props.dateRange.From,props.dateRange.To])

  const onChange=(key) => {
    let rangeDate={ From:'',To:'' };

    switch (key) {
      case 'TODAY':
        rangeDate = getRangeDate(0)
        break;
      case "THREE_DAYS":
        rangeDate = getRangeDate(2)
        break;
      case "ONE_WEEK":
        rangeDate = getRangeDate(6)
        break;
      case "ONE_MONTH":
        rangeDate = getRangeDate(29)
        break;
      default:
        break;
    }
    setActiveKey(key)
    props.parent.changeDataRange({...rangeDate})
  }

  return (
    <div className={styles.date__search__div}>
      <ul>
        {configs.map(key=>{
          return <li key={key} onClick={()=>onChange(key)} className={activeKey===key?styles.active__li:styles.normal__li}>{intl.get(key)}</li>
        })
        }
      </ul>
    </div>
  )
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps, {
})(DateSearchContainer)
