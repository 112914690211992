import React, { Component } from 'react';
import AnnotatonTable from '../AnnotationTable';
import { Col, Row, Spin,  } from 'antd';
import { connect } from 'react-redux';
import {
  addReportAnnotationIds,
  setCurrentAnnotationId
} from '../../actions/annotationSelectionActions';
import { saveReportResult } from '../../actions/reportAction';
import AnnotationUserSelector from './AnnotationUserSelector';
import {
  getSelectedAnnotationIds
} from '../../selectors/annotationSelectors';
import style from './index.less';
import AnnotationPlayer from '../AnnotationPlayer';
import { selectEditReportDisabled } from '../../selectors/reportSelector';

class DetailsOfAnnotations extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    document.addEventListener('drop', this.preventDefault.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener('drop', this.preventDefault.bind(this));
  }

  preventDefault = (e) => {
    e.preventDefault();
  };

  render() {
    const imageId = this.props.currentImageKey;
    return (
      <div onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave}>
        <Row className={style.operations}>
          <Col span={12}>
            <AnnotationUserSelector />
          </Col>
          <Col span={10} align="right">
            <div className={style.right_btn}>
            <AnnotationPlayer preset={false} classes={[]} />
            <span  style={{color:"#fff",width:"25px",textAlign:"left"}}>{this.props.selectedAnnotationIds.length}</span>
            </div>
          
            {/* <Badge
              count={this.props.selectedAnnotationIds.length}
              showZero={true}
              style={{marginBottom:'5px',verticalAlign:"text-top",backgroundColor: '#1890ff',height:'18px',lineHeight:'18px' }}
              title=" "
            />         */}
          </Col>
        </Row>
        {imageId && this.props.loadingStatus === 'success' ? (
          <AnnotatonTable id="annotation-table" key={imageId} imageId={imageId} />
        ) : (
          <Spin />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedAnnotationIds: getSelectedAnnotationIds(state),
    currentImageKey: state.imageSelection.currentImageKey,
    loadingStatus: state.annotationLoad.status,
    disabled: selectEditReportDisabled(state),
  };
};

export default connect(mapStateToProps, {
  addReportAnnotationIds,
  setCurrentAnnotationId,
  saveReportResult,
})(DetailsOfAnnotations);

