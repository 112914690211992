import { initReduxData } from '../utils/dataUtils';
const initial = { 
  thirdParty: [],
  HisParty:[]
};


const service = (state = initial, action) => {
  state.HisParty = initReduxData(initial.HisParty, state.HisParty);
  switch (action.type) {
    case 'LOAD_SERVICE':
      return {
        ...state,
        thirdParty: action.service
      };
    case 'ACTIVATE_SERVICE':
      return {
        ...state,
        thirdParty: [...state.thirdParty].map(item=>{
          if(item.id===action.serviceId){
            item.active =!item.active
          }else{
            item.active = false
          }
          return {...item}
        })
      };
    case 'UPDATE_SERVICE':
      return {
        ...state,
        thirdParty:[...state.thirdParty].map(item=>{
          if(item.id===action.serviceId){
            return {...item,...action.data.configurations,configurations:{
              ...action.configurations,...action.data.configurations
            }}
          }
          return item
        })
      };
      case 'SET_HIS_PART_INFO':
        return {
          ...state,
          HisParty: action.data
        };
    default:
      return state;
  }
};

export default service;
