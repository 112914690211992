import { createSelector } from 'reselect';

export const getCurrentTabIdx = (state) => state.tabState.currentTab;
export const getCurrentSecTabIdx = (state) => state.tabState.currentSecondTab;

export const getTabs = (state) => state.tabState.tabs;

export const getSecondsTabs = (state) => state.tabState.secondTabs;

export const getCurrentTab = createSelector(getCurrentTabIdx, getTabs, (idx, tabs) => tabs[idx]);

export const getSecondCurrentTab = createSelector(getCurrentSecTabIdx, getSecondsTabs, (idx, tabs) => tabs[idx]);

export const getUploadGTVisibles = (state) => state.tabState.uploadVisible;
