import { Table, Divider, Icon, Spin, Modal, Form, Input, Button } from 'antd';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { useFetch } from '../../hooks/useHttp';
import { fetchService, activateService, editService } from '../../actions/service';
import { connect } from 'react-redux';
import styles from './index.less';
const formItemLayout = {
  labelCol: {
    xs: { span: 4 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 20 },
    sm: { span: 20 }
  }
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};
function ServiceList(props) {
  const { service } = props;
  let [loading, error] = useFetch(props.fetchService);
  const { getFieldDecorator } = props.form;
  const [visible, setVisible] = useState(false);
  const [activeId, setActiveId] = useState(false);

  const onActivate = (serviceId) => {
    props.activateService(serviceId);
  };

  const onEdit = (e) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        props.editService(activeId, {
          configurations: values
        });
        handleCancel();
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const columns = [
    {
      title: intl.get('ORAN_NAME'),
      dataIndex: 'name',
      key: 'id'
    },
    {
      title: intl.get('SERVICE_HOST'),
      dataIndex: 'host',
      editable: true
    },
    {
      title: intl.get('SERVICE_PORT'),
      dataIndex: 'port',
      editable: true
    },
    {
      title: intl.get('ACTION'),
      key: 'action',
      render: (text, record) => (
        <span>
          <a
            onClick={() => {
              setActiveId(record.id);
              setVisible(true);
            }}
          >
            <Icon type="edit" />
          </a>
          <Divider type="vertical" />
          <a
            onClick={() => onActivate(record.id)}
            style={{ color: record.active ? 'green' : 'red' }}
          >
            <Icon type={record.active ? 'check-square' : 'close-square'} />
          </a>
        </span>
      )
    }
  ];

  return (
    <>
      {!error || loading ? (
        <Table
          size="small"
          dataSource={service}
          columns={columns}
          className={styles.service_table}
        />
      ) : (
        <Spin loading />
      )}
      <Modal
        title={intl.get('SERVICE_CONFIG')}
        visible={visible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form onSubmit={onEdit} {...formItemLayout}>
          <Form.Item label={intl.get('SERVICE_HOST')}>
            {getFieldDecorator('host', {})(<Input maxLength="200" />)}
          </Form.Item>
          <Form.Item label={intl.get('SERVICE_PORT')}>
            {getFieldDecorator('port', {})(<Input maxLength="20" />)}
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button onClick={handleCancel}>{intl.get('CANCEL')}</Button>
            <Divider type="vertical" />
            <Button type="primary" htmlType="submit">
              {intl.get('YES')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

ServiceList.propTypes = {};
const mapStateToProps = (state, ownProps) => ({
  service: state.service.thirdParty
});

export default connect(mapStateToProps, {
  fetchService,
  activateService,
  editService
})(Form.create({ name: 'EditServiceForm' })(ServiceList));
