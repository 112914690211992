import React, { Component } from 'react';
import { connect } from 'react-redux';
import style from './index.less';

class Navigator extends Component {
  render() {
    window.osdviewer &&
      (window.osdviewer.navigator.element.style.display = this.props.navigatorVisibility
        ? 'inline-block'
        : 'none');
    return (<div
      id="image-navigator"
      className={style.navigator}
      style={{
        width:this.props.width,
        height:this.props.height
      }}
    />)
  }
}

const mapStateToProps = (state) => ({
  navigatorVisibility: state.toolStatus.navigatorVisibility
});

export default connect(mapStateToProps, null)(Navigator);
