import { message } from 'antd';
import jwtDecode from 'jwt-decode';
import intl from 'react-intl-universal';
import { initial as initialUser } from '../reducers/currentUser';
import { getCurrentUser as _getCurrentUser } from '../selectors/userSelectors';
import {
  setRoles,
  setDepartmentIdByLabel,
  getRegisterPathName
} from '../components/UserSetting/RegisterAccount/RegisterInfo';
import store from '../store';
import {
  fetchUpdateAdminKey,
  fetchUpdatePassword,
  fetchUpdateSambaPass,
  fetchUpdateUser,
  getUserInfoFromServer,
  apiAddUser,
  apifetchUserByRole,
  apifetchAllUserByRole,
  apiUserAll,
  apiGetmachine,
  apiEditOtherUser,
  apiGetUserByQueryInfo,
  apiGetUserById,
  apiDelUserById,
  apiBatchcDelUser
} from './api';
import { classifyRoles } from '../utils/authUtils';
import { updateRegister } from './setting';
import { getRegister } from '../selectors/settingSelectors';
import { updateRolesUsers } from './reportAction';
export const toggleAnnotationUserVisibility = (user) => ({
  type: 'TOGGLE_ANNOTATION_USER_VISIBILITY',
  user
});

export const setLoginUser = (user) => ({
  type: 'SET_LOGIN_USER',
  user
});

export const setCurrentUser = (user) => ({
  type: 'SET_CURRENT_USER',
  user
});

export const clearCurrentUser = () => ({
  type: 'CLEAR_CURRENT_USER'
});

const getUserInfoRequest = (userId) => ({
  type: 'GET_USER_INFO_REQUEST',
  userId
});

const getUserInfoSuccess = (info) => ({
  type: 'GET_USER_INFO_SUCCESS',
  info
});

const getUserInfoFailure = (error) => ({
  type: 'GET_USER_INFO_FAILURE',
  error
});

export const setUnitsList = (data) => ({
  type: 'SET_UNITS_LIST',
  data
});

export function getCurrentUser(dispatch) {
  try {
    let user = _getCurrentUser(store.getState());
    if (!user || !user.id) {
      dispatch(refreshCurrentUser());
      user = _getCurrentUser(store.getState());
    }
    return user;
  } catch (err) {
    return initialUser;
  }
}

export function getUserInfoAsync(userId) {
  return function(dispatch) {
    dispatch(getUserInfoRequest(userId));
    return getUserInfoFromServer(userId).then(
      (response) => {
        dispatch(getUserInfoSuccess(response.data));
      },
      (error) => {
        dispatch(getUserInfoFailure(error));
      }
    );
  };
}

export function getUserInfoById(userId) {
  return function(dispatch) {
    // console.log(dispatch)
    return getUserInfoFromServer(userId).then(
      (response) => {return response.data;},
      (error) => {console.log(error);}
    );};
}

export function refreshCurrentUser(t) {
  return (dispatch) => {
    const token = t ? t : localStorage.getItem('access_token');
    const decoded = { ...jwtDecode(token) };
    const permissions = classifyRoles(decoded.permissions.flat());
    const currentUser = { ...decoded, permissions };
    dispatch(setCurrentUser(currentUser));
  };
}

export const updateUserInfo = (userId, user) => {
  return async (dispatch) => {
    fetchUpdateUser(userId, user)
      .then((resp) => {
        if (resp.status === 204) {
          dispatch(setCurrentUser(user));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

function encrypt(obj, keys, encoder) {
  const encrypted = { ...obj };
  keys.forEach((k) => {
    encrypted[k] = encoder(encrypted[k]);
  });
  return encrypted;
}

export const updatePassword = (option) => {
  const encrypted = encrypt(option, ['old', 'new'], btoa);
  return async (dispatch) => {
    fetchUpdatePassword(encrypted)
      .then((resp) => {
        if (resp.status === 204) {
          message.success(intl.get('UPDATE_SUCCESS', { what: intl.get('PASSWORD'), message: '' }));
        }
      })
      .catch((err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.data.error_code === 6005) message.error(intl.get('OLD_PASSWORD_NOT_MATCH'));
        }
      });
  };
};

export const updateAdminKey = (option) => {
  const encrypted = encrypt(option, ['old', 'new'], btoa);
  return async (dispatch) => {
    fetchUpdateAdminKey(encrypted)
      .then((resp) => {
        if (resp.status === 204) {
          message.success(intl.get('UPDATE_SUCCESS', { what: intl.get('ADMIN_KEY'), message: '' }));
        }
      })
      .catch((err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.data.error_code === 6003) {
            message.error(intl.get('OLD_ADMIN_KEY_NOT_MATCH'));
          }
        }
      });
  };
};

export function updateSambaPass(option) {
  return function(dispatch) {
    fetchUpdateSambaPass({ password: option.new })
      .then((resp) => {
        if (resp.status === 204) {
          message.success(
            intl.get('UPDATE_SUCCESS', { what: intl.get('SMB_PASSWORD'), message: '' })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function updateList(dispatch){
  let register = getRegister(store.getState());
  const pathName =getRegisterPathName()
  pathName === 'expert'
          ? dispatch(getAllUserByQueryInfo({ ...register.queryInfo, roles: 'expert' }))
          : dispatch(getAllUserByQueryInfo({...register.queryInfo, roles: undefined}));
}

export function addUser(data, form) {
  return function(dispatch) {
    dispatch(updateRegister({ isLoading: true }));
    apiAddUser(data)
      .then((resp) => {
        message.success(intl.get('REGISTER_SUCCESS'));
        dispatch(updateRegister({ registerUpdateVisible: false, isLoading: false }));
        updateList(dispatch)
        dispatch(updateRolesUsers());
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.error_code === 9003) {
            form.setFields({ username: { errors: [new Error(intl.get('ERROR_9003'))] } });
          }
          if (err.response.data.error_code === 6003) {
            form.setFields({ admin_key: { errors: [new Error(intl.get('ERROR_6003'))] } });
          }
        }
        dispatch(updateRegister({ isLoading: false }));
      });
  };
}

export function editUser(id, data, form) {
  return function(dispatch) {
    dispatch(updateRegister({ isLoading: true }));
    apiEditOtherUser(id, data)
      .then((resp) => {
        message.success(intl.get('MARK_MODIFY_SUCCESS'));
        dispatch(updateRegister({ registerUpdateVisible: false, isLoading: false }));
        updateList(dispatch)
        dispatch(updateRolesUsers());
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.error_code === 9003) {
            form.setFields({ username: { errors: [new Error(intl.get('ERROR_9003'))] } });
          }
        }
        dispatch(updateRegister({ isLoading: false }));
      });
  };
}

export function getUserByRole(name) {
  return apifetchUserByRole(name)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      console.warn(err);
    });
}

export function getAllUser2() {
  return apifetchAllUserByRole()
    .then((resp) => {
      return resp.roles;
    })
    .catch((err) => {
      console.warn(err);
    });
}

export function getAllUser() {
  return function(dispatch) {
    apiUserAll()
      .then((resp) => {
        // return resp.data
        let newlist = resp.data.users.map((u) => {
          let v = setRoles(u);
          u.roles = v.value;
          u.roleName = v.label;
          u.departmentName = u.department;
          u.department = setDepartmentIdByLabel(u);
          return u;
        });
        dispatch(updateRegister({ registerList: newlist }));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function getMachine() {
  return function(dispatch) {
    apiGetmachine()
      .then((resp) => {
        let list = resp.data.items.map((i) => {
          return {
            value: i.id,
            label: i.configurations.hospital_name,
            hostName: i.name,
            active: i.active
          };
        });
        dispatch(setUnitsList(list));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function getAllUserByQueryInfo(queryInfo) {
  return function(dispatch) {
    apiGetUserByQueryInfo(queryInfo)
      .then((resp) => {
        let newlist = resp.data.items.map((u) => {
          u.rolesList = u.roles 
          let v = setRoles(u);
          u.roles = v.value;
          u.roleName = v.label;
          u.departmentName = u.department;
          u.department = setDepartmentIdByLabel(u);
          return u;
        });
        dispatch(updateRegister({ registerList: newlist, total: resp.data.total }));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function getUserById(id) {
  return apiGetUserById(id)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      console.warn(err);
    });
}

export function delUserById(id) {
  return function(dispatch) {
    dispatch(updateRegister({ isLoading: true }));
    apiDelUserById(id)
      .then((resp) => {
        message.success(intl.get('DEL_SUCCESS'));
        updateList(dispatch)
        dispatch(updateRegister({ isLoading: false }));
      })
      .catch((err) => {
        dispatch(updateRegister({ isLoading: false }));
        console.warn(err);
      });
  };
}

export function delBatchUser(users) {
  return apiBatchcDelUser(users)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      console.warn(err);
    });
}
