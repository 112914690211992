const defaultImageColumns = [
  'tag',
  'patient_name',
  'patient_tag_id',
  'register_date',
  'clazz',
  'image_level_result_AI',
  'ground_truth_result',
  'reporter',
  'stage',
  'push_status',
  "image_status"
];

export default defaultImageColumns;
