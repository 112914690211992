import { Tooltip } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';

export default function detailBuilder(title, content) {
  let isTooLong = content && content.length > 18
  let procossContent = content ? content : "--"
  return (
    <div style={{ wordBreak: "break-all" }}>
      {intl.get(title)}：
      {
        isTooLong ? <Tooltip title={content}>{content.slice(0, 18) + "..."}</Tooltip> : procossContent
      }
    </div>
  )
}
