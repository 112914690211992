import React from 'react';
import ServiceSet from './ServiceSet';
import CommonPanel from '../UserSetting/commonPanel';
export default function Service(props) {
  return (
    <CommonPanel span={24}>
      <ServiceSet />
    </CommonPanel>
  );
}
