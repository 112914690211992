import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setCardColums, dataList } from './cardInfo.js';
import { Table, Pagination } from 'antd';
class HospitalCard extends Component {
  render() {
    const CardColums = setCardColums();
    const h = document.body.clientHeight - 310;
    const minH = document.body.clientHeight - 255;
    return (
      <div>
        <Table
          columns={CardColums}
          dataSource={dataList}
          rowKey={(record) => record.id}
          key={(record) => record.id}
          scroll={{
            // x: checkInColums.length > 10 ? window.screen.availWidth : null,
            y: dataList.length > 20 ? h : null
          }}
          pagination={false}
          style={{ minHeight: minH }}
        />
        <Pagination
          current={1}
          // showTotal={(total) =>
          //   ` ${intl.get('CHECK_LIST_TOTAL_COUNT', { total: checkInfoList.total })}`
          // }
          // onChange={(page) => {
          //   this.props.updateChckInQueryInfo({ page: page - 1 });
          // }}
          size="small"
          // pageSize={queryInfo.limit}
          total={200}
          showQuickJumper={true}
          showSizeChanger={true}
          // onShowSizeChange={this.changePageSize}
          style={{ padding: 20, float: 'right' }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(HospitalCard);
