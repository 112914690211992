import { uniq } from 'lodash';
import { createSelector } from 'reselect';
import { getInvisibleClasses } from '.';
import { getUnhiddenAnnotations } from './annotationSelectors';
import { getLinesToUserOfClass } from './classSelectors';

export const getAnnotationUsers = createSelector(
  [getUnhiddenAnnotations, getInvisibleClasses],
  (annotations, invisibles) =>
    uniq(
      annotations
        .map((a) =>
          getLinesToUserOfClass(a.lines)
            .filter((l) => !invisibles.includes(l.clazz))
            .map((l) => l.user)
        )
        .flat()
    )
);

export const getCurrentUser = (state) => state.currentUser;

export const getUserUnits = (state) => state.currentUser.units;

