import React, { useEffect, useState } from 'react';
import CommonPanel from '../commonPanel';
import { Card, InputNumber, message } from 'antd';
import intl from 'react-intl-universal';
import { debounce } from 'lodash';
import styles from '../commonPanel.less';
import {
  updateConfigurationsByType,
  fetchConfigurationsPlayTime
} from '../../../actions/configActions';
import { connect } from 'react-redux';
import {limitDecimals} from "../../../utils/formUtils"
function AutoPlaySet(props) {
  const [playTime, setPlayTime] = useState(2);

  useEffect(() => {
    fetchConfigurationsPlayTime().then((res) => {
      setPlayTime(res);
    });
  }, []);

  const onThreshholdChange = (data) => {
    if (data < 1) {
      message.warning(intl.get('PLAY_SPAN_MIN', { num: 2 }));
      return;
    }
    if (data > 100) {
      message.warning(intl.get('PLAY_SPAN_MAX', { num: 10 }));
      return;
    }
    updateConfigurationsByType({ annotation_play_interval: data }).then(() => {
      setPlayTime(data);
    });
  };
  return (
    <CommonPanel>
      <Card title={intl.get('AUTO_PLAY_SETTINGS')}>
        <div className={styles.item__div}>
          <span className={styles.item__label}>{intl.get('PLAY_SPAN')}</span>
          <InputNumber
            min={2}
            max={10}
            formatter={limitDecimals}  
            parser={limitDecimals} 
            value={playTime}
            onChange={debounce(onThreshholdChange, 500)}
          />
          <span className={styles.item__unit}>{intl.get('PLAY_UNIT')}</span>
        </div>
      </Card>
    </CommonPanel>
  );
}

const mapStateToProps = (state, ownProps) => ({});
export default connect(mapStateToProps, {
  updateConfigurationsByType,
  fetchConfigurationsPlayTime,
})(AutoPlaySet);
