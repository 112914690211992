import { Icon, Input } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import style from '../commonPanel.less';
import { cloneDeep } from 'lodash';
function AddComponent(props, ref) {
  const [addList, setList] = useState(['']);
  const addCom = useRef();
  useEffect(() => {
    if (!props.isModalVisible) {
      setList(['']);
      props.getAdd(['']);
    }
  }, [props.isModalVisible]);

  const onChange = (index, value) => {
    let l = cloneDeep(addList);
    l[index] = value.target.value;
    setList(l);
    props.getAdd(l);
  };
  const addItem = () => {
    let l = [...addList, ''];
    setList(l);
    props.getAdd(l);
  };
  const delItem = (index) => {
    let l = cloneDeep(addList);
    l.splice(index, 1);
    setList(l);
    props.getAdd(l);
  };

  return (
    <div className={style.add_org_component} ref={addCom}>
      {addList.map((item, index) => {
        return (
          <div key={index}>
            <Input value={item} onChange={onChange.bind(this, index)} minLength={1} maxLength={16}/>
            &nbsp; &nbsp;
            {index === 0 ? (
              <Icon type="plus-circle" onClick={addItem.bind(this)} />
            ) : (
              <Icon type="minus-circle" onClick={delItem.bind(this, index)} />
            )}
          </div>
        );
      })}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  organizationList: state.settings.organizationList
});

export default connect(mapStateToProps, {})(AddComponent);
