import { indexOf } from 'lodash';

function progress(state = { progresses: [] }, action) {
  switch (action.type) {
    case 'RECEIVE_PGS':
      return {
        ...state,
        progresses: [
          ...state.progresses.filter((item) => item.filename !== action.message.filename),
          action.message
        ]
      };
    case 'REMOVE_IN_PROGRESSES':
      return {
        ...state,
        progresses: state.progresses.filter((item) => item.filename !== action.filename)
      };
    case 'REMAIN_IN_PROGRESSES':
      return {
        ...state,
        progresses: state.progresses.filter(
          (item) => indexOf(action.filenames, item.filename) !== -1
        )
      };
    case 'RESET_IMAGES_PROGRESSES':
      return {
        ...state,
        progresses: []
      };
    default:
      return state;
  }
}

export default {
  progress
};
