import React, { Component } from 'react';
import { connect } from 'react-redux';
import style from './index.less';
import intl from 'react-intl-universal';
import { Tooltip } from 'antd';
import { getRegister } from '../../../selectors/settingSelectors';
import { getUserUnits } from '../../../selectors/userSelectors';
import { editUser } from '../../../actions/userActions';
import HospitalSelect from '../../CommonComponent/HospitalSelect';

class ReportSource extends Component {
  onChange(value) {
    const { infoList } = this.props;
    this.props.editUser(infoList.id, { groups: value });
  }

  setGroupsText() {
    const { infoList, units } = this.props;
    let list = [];
    if (infoList.groups) {
      infoList.groups.forEach((g) => {
        for (let i = 0; i < units.length; i++) {
          if (units[i].value === g) {
            list.push(units[i].label);
            break;
          }
        }
      });
    }
    return list.join(',');
  }
  render() {
    const { infoList, register } = this.props;
    return register.selectedSingle?.id === infoList.id ? (
      <HospitalSelect
        mode="multiple"
        onChange={this.onChange.bind(this)}
        width="100%"
        defaultValue={infoList?.groups}
        value={infoList?.groups}
        placeholder={intl.get('EXPERT_SOURCE')}
      />
    ) : (
      <Tooltip placement="top" title={this.setGroupsText()}>
        <div className={style.reg_droups}>{this.setGroupsText()}</div>
      </Tooltip>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    register: getRegister(state),
    units: getUserUnits(state)
  };
};

export default connect(mapStateToProps, { editUser })(ReportSource);
