import { message } from 'antd';
import { difference, indexOf, isEqual, remove } from 'lodash';
import intl from 'react-intl-universal';
import { updateImage } from '../actions/imageActions';
import config from '../config';

const { tctGroupMapping } = config;

export function arrayToDB(result, gtList) {
  let gtResults = result;
  const groupName = Object.keys(gtList);
  if (!Array.isArray(result)) gtResults = result.split(',');
  const labels = [];
  for (let i = 0; i < groupName.length; i++) labels[i] = [];
  gtResults.forEach((item) => {
    groupName.forEach((group, i) => {
      if (indexOf(gtList[group], item) !== -1) labels[i].push(item);
    });
  });
  let ret = '';
  let suffix = '';
  labels.forEach((label, i) => {
    let newLabel = label;
    if (newLabel.length > 1) newLabel = label.filter((c) => c !== gtList[groupName[i]][0]);
    ret = newLabel.length ? ret + suffix + newLabel.join('+') : ret;
    suffix = !newLabel.length ? '' : ',';
  });

  return ret;
}

export function dbToArray(result) {
  if (result) {
   const strLabel = result.split(',').map(i=>i.split('+')).flat()
   return strLabel
  } else {
    return [];
  }
}

export function pickResultFromArray(arr, gtList, group) {
  const groups = gtList[group];
  return arr.filter((item) => groups.indexOf(item) !== -1);
}

export function pickValidResults(pickedOld, pickedNew, option) {
  const { special, state } = option;
  if (isEqual(pickedOld, pickedNew) || (pickedNew.length && pickedNew.indexOf(special) === -1))
    return pickedNew;

  const ret = difference(pickedNew, pickedOld);

  if (state === 'absence') remove(ret, (t) => t === special);
  if (state === 'presence' && !ret.length) ret.push(special);

  return ret;
}

export function pickFromInput(oldValue, newValue, gtList) {
  const groups = Object.keys(gtList);
  const oldCervical = pickResultFromArray(oldValue, gtList, groups[0]);
  const newCervical = pickResultFromArray(newValue, gtList, groups[0]);

  const valid = pickValidResults(oldCervical, newCervical, {
    special: gtList[groups[0]][0],
    state: 'presence'
  });
  let valid2 = [];

  if (groups.length > 1) {
    const oldMic = pickResultFromArray(oldValue, gtList, groups[1]);
    const newMic = pickResultFromArray(newValue, gtList, groups[1]);
    valid2 = pickValidResults(oldMic, newMic, { special: gtList[groups[1]][0], state: 'absence' });
  }

  if (!valid.length & valid2.length) return [gtList[groups[0]][0]].concat(valid2);
  return valid.concat(valid2);
}

export function isInGroup(result, group) {
  return tctGroupMapping[result] === group;
}

export function updateGTResult(dispatch, image, newVal) {
  if (image.groundTruthResult !== newVal) {
    dispatch(updateImage(image.id, { ground_truth_result: newVal }));
  } else {
    message.success(intl.get('NO_NEED_UPDATE'));
  }
}
