import React from 'react';
import NavigatorBtn from './NavigatorBtn';
import RectangleBtn from './RectangleBtn';
import TagBtn from './TagBtn';
import FullscreenBtn from './FullscreenBtn';
import MsgBtn from './MsgBtn';
import { isPartEnd } from '../../utils/urlUtils';
function index() {
  const partEnd = isPartEnd();
  return (
    <>
      <FullscreenBtn />
      {partEnd !== 'centerEnd' && <RectangleBtn />}
      <NavigatorBtn />
      <TagBtn />
      <MsgBtn />
    </>
  );
}

export default index;
