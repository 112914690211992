import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown,Input } from 'antd';
import {
  fetchSearchImageAsync,
  setSearchedFromImageId,
  updateCurrentImage,
  updateLoadImageMode,
  fetchImageInfo,
  setCachePage
} from '../../actions/imageActions';
import { updateSearchBoxInfo,updateSearchBoxImageId} from '../../actions/searchBoxAction';
import { getSelectedImages, getRemark, isCurrentImageCollected, getSearchedFromImageInfo, getSearchedFromImageId} from '../../selectors/imageSelector';

import styles from './SearchBox.less';
import { getImageTotalCount } from '../../selectors';
import  SearchTable from './SearchTable'
const { Search } = Input;

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      searchText:""
    };
  }

  componentDidMount(){
    document.addEventListener('click', (e) => {
      this.setState({
        visible: false,
      })
    })
    if(this.props.searchValue!==""){
      this.setState({ 
        searchText :this.props.searchValue,
      })
      this.props.fetchImageInfo(this.props.searchedImageId)
      this.setState({ visible:false })
    }
  }

  componentWillUnmount(){
    this.props.updateSearchBoxInfo("")
    this.props.updateSearchBoxImageId("")
    this.setState = ()=>false;
  }
  hide = () => {
    this.setState({
      visible: false,
    });
  };

/**
 * 搜索防抖
 * @param {*} fn 
 * @param {*} delay 
 * @returns 
 */
  debounce = (fn, delay) => {
    let timer = null;
    return function(event) {
      timer && clearTimeout(timer);
      event.persist && event.persist()
      timer = setTimeout(() => {
        fn.call(this, event)
      }, delay) 
    }
  }

  delayedChange = this.debounce(() => {
    if(this.state.searchText ===""){
      this.props.fetchImageInfo(this.props.fromImageId)
      this.hide()
      this.props.updateLoadImageMode('query')
    }else{
      this.updateTableInfo(this.state.searchText)
      this.props.updateSearchBoxInfo(this.state.searchText)
    }
   
  }, 1000);
  
  handleChange = (e) => {
    const searchText = e.target.value
    this.setState({ searchText :searchText})
    this.delayedChange(e);
  };

  updateTableInfo=(searchText,page=0)=>{
    const query = { fuzzy:searchText, page: page }
    if(searchText.length > 0){
      this.props.fetchSearchImageAsync(query).then((res)=>{
        if(res.data.items.length ===0){
          this.setState({ visible:false }) 
        }else{
          this.props.updateSearchBoxImageId(res.data.items[0].id)
          this.setState({ visible:true })
        }
      })
      this.props.setCachePage('search',[0]);
    }else{
      let mode = (this.props.selectedImages.data.length>0)?"select":"query"
      this.props.updateLoadImageMode(mode)
      let image = this.props.searchedFromImageInfo
      if (image) {
        this.props.updateCurrentImage(this.props.searchedFromImageInfo)
      }
      this.setState({ visible:false })
    }
  }

  handleDivClick=(e)=>{
    e.nativeEvent.stopImmediatePropagation();
  }

  render() {
    return (
      <Dropdown visible={this.state.visible} overlay={<SearchTable hideTable={this.hide} searchText={this.state.searchText} updateTableInfo={this.updateTableInfo}/>} placement="bottomLeft" >
        <div id="headerSearchBox" onClick={this.handleDivClick} className={styles.search__box} >
          <Search 
            id="headerSearchInput" 
            className={styles.search__input}
            onChange={this.handleChange}
            onSearch={this.delayedChange}
            value={this.state.searchText}
            allowClear
          />
        </div>
      </Dropdown>
    );
  }
}

const mapStateToProps = (state) => ({
  currentImageKey: state.imageSelection.currentImageKey,
  isCollected: isCurrentImageCollected(state),
  remark: getRemark(state),
  total:getImageTotalCount(state),
  searchedFromImageId:getSearchedFromImageId(state),
  searchedFromImageInfo:getSearchedFromImageInfo(state),
  selectedImages:getSelectedImages(state),
  fromImageId:state.loadImages.images.fromImageId,
  searchValue:state.searchBox.value,
  searchedImageId:state.searchBox.imageId
});

export default connect(mapStateToProps, {
  fetchSearchImageAsync,
  setSearchedFromImageId,
  updateCurrentImage,
  updateLoadImageMode,
  fetchImageInfo,
  updateSearchBoxInfo,
  updateSearchBoxImageId,
  setCachePage
})(Index);