import React from 'react';
import { connect, useSelector } from 'react-redux';
import { exportCsv } from '../../actions/tableActions';
import colorTable from '../../static/colorTable';
import intl from 'react-intl-universal';
import PopoverIconButton from '../PopoverIconButton';
import { getCurrentUser } from '../../selectors/userSelectors';
import Can from '../Can'
import {formImageText} from "../../utils/dataUtils"
import { tableToExcel } from './Excel';
import cfg from '../../config';
import {  filteredColumnExcel} from '../../utils/displayUtils';
import { getYmdhms } from '../../utils/dateUtils';
import { message } from 'antd';
function ExportCsvBtn(props) {
  const onClick = () => {
    // props.exportCsv(true);
    const { data } = props.selected;
    if(data.length===0){
      message.warning(intl.get('WARN_CHOOSE'))
      return
    }
    let excelName =intl.get('EXCEL_NAME')
    let titleVal =
      intl.get('COMPANY_NAME') +
      ':' +
      intl.get('COMPANY_NAME_TEXT') +
      "<br style='mso-data-placement:same-cell'/>" +
      intl.get('PRODUCTION_NAME') +
      ':' +
      intl.get('TRADEMARK') +
      intl.get('PLATFORM_NAME') +
      "<br style='mso-data-placement:same-cell'/>" +
      intl.get('EDITION_NAME') +
      ':' +
      intl.get('PLATFORM_VERSIONS', { middleV: cfg.version }) +
      "<br style='mso-data-placement:same-cell'/>" +
      intl.get('PRINT_NAME') +
      ':' +
      getYmdhms();

    tableToExcel(formImageText(data), filteredColumnExcel(), excelName,titleVal);
  }

  const currentUser = useSelector(getCurrentUser)

  return <Can
    permissions={currentUser.permissions}
    perform='image:view:batch'
    yes={() => (
      <PopoverIconButton
        id='export-csv-btn'
        popContent={intl.get('DOWLOAD_RESULT')}
        icon='icon-download1'
        color={colorTable.blue}
        onClick={onClick}
        place="bottom"
      />)}
    no={() => ('')}
  />

}

const mapStateToProps = (state) => {
  return {
    selected: state.loadImages.selected
  };
};
export default connect(mapStateToProps, { exportCsv })(ExportCsvBtn);
