const initial = {
    mode: 'simple_mode',
    hospital_name: "深圳市视见医疗科技有限公司",
    logo: "",
    report_sub_title: "液基细胞学检测报告单",
    visible_elements: [],
    hospital_position:"",
    screenshot_count:0,
    findings_and_interpretation_strings:[]
}

export function reportConf(state = initial, action) {
    switch (action.type) {
        case 'UPDATE_REPORT_CONF':
            return {
                ...state,
                ...action.data
            };
        case 'INIT_REPORT_CONF':
            return {
                ...initial,
                ...state,
            };
        default:
            return state;
    }
}  