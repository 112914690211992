import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setProInColums } from '../../utils/displayUtils';
import { Table } from 'antd';
import style from './index.less';
import { setProSelectedList,setProQueryInfo ,setProSelectedSingle} from '../../actions/productionAction';
import {formProTableInfo,filterJson} from "../../utils/dataUtils"
import { uniq } from 'lodash';
class ProTable extends Component {
  constructor() {
    super();
    this.state = {
      dataList: [],
      tableH:0
    };
  }
  componentDidMount() {
    this.props.setProSelectedList({
      list: [],
      total: 0
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.productionList !== this.props.productionList) {
      let data = formProTableInfo(this.props.productionList).data;
      this.setState({ dataList: data }, () => {
        let t = document.querySelector('#proTable');
        this.setState({
          tableH: t ? t.offsetHeight : 0
        });
      });
    }
  }
  selectRows = (keys,rows) => {
    const { selected } = this.props;
    let selectedRows = selected.list;
    let ks = uniq(keys);
    selectedRows = filterJson(selectedRows.concat(rows),'id').filter((item) => {
      return ks.indexOf(item.id) !== -1;
    });
    this.props.setProSelectedList({ list: selectedRows, total: ks.length });
  };
  handleTableChange = (pagination, filters, sorter) => {
    const { queryInfo } = this.props;
    this.props.setProQueryInfo({
      page: queryInfo.page,
      sortBy: sorter.columnKey,
      sortAsc: sorter.columnKey === queryInfo.sortBy ? !queryInfo.sortAsc : false
    });
  };
  render() {
    const { tableH } = this.state;
    const {selected}= this.props;
    const checkInColums = setProInColums();
    const rowSelection = {
      selectedRowKeys: selected.list.map((item) => item.id),
      columnWidth: '40px',
      fixed: 'left',
      onChange: this.selectRows
    };
    const h = document.body.clientHeight - 310;
    const minH =  document.body.clientHeight-252;
    const data = this.props.productionList.items.length>0 ?formProTableInfo(this.props.productionList).data:[];
    return (
      <Table
        id='proTable'
        columns={checkInColums}
        rowSelection={rowSelection}
        dataSource={data}
        rowKey={(record) => record.id}
        key={(record) => record.id}
        onChange={this.handleTableChange}
        rowClassName={(record, index) => (record.is_urgent ? style.row__quick : style.row__general)}
        scroll={{
          // x: checkInColums.length > 10 ? window.screen.availWidth : null,
          x:'100%',
          y: tableH > minH ? h : null
        }}
        pagination={false}
        className={style.pro__table}
        style={{ minHeight: minH, maxHeight: minH }}
        onRowClick={(record)=>{this.props.setProSelectedSingle(record)}}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    queryInfo: state.production.queryInfo,
    productionList:state.production.productionList,
    selected: state.production.selected,
  };
};

export default connect(mapStateToProps, { setProSelectedList,setProQueryInfo,setProSelectedSingle })(ProTable);
