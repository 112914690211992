/**
 * @fileOverview
 * @name reportStatus.js
 * @author
 * @license
 reportStatus types structure:
 value: @type {string} actual value of reportStatus
 translate: @type {string} translation key of value
 */

const reportStatus = [
  { value: "1", translate: 'REPORT_STAGE_DISABLE' },
  { value: "2", translate: 'REPORT_STAGE_WAIT_ALLOCATION' },
  { value: "3", translate: 'REPORT_STAGE_WAIT_JUNIOR' },
  { value: "4", translate: 'REPORT_STAGE_JUNIORING' },
  { value: "5", translate: 'REPORT_STAGE_JUNIORED' },
  { value: "6", translate: 'REPORT_STAGE_WAIT_SENIOR' },
  { value: "7", translate: 'REPORT_STAGE_SENIORING' },
  { value: "8", translate: 'REPORT_STAGE_WAIT_SENIORED' },
  { value: "9", translate: 'REPORT_STAGE_WAIT_PRINTED' },
];

export const statusInfo = {
  1: {
    translate: 'REPORT_STAGE_DISABLE',
    color: "green"
  },
  2: {
    translate: 'REPORT_STAGE_WAIT_ALLOCATION',
    color: "green"
  },
  3: {
    translate: 'REPORT_STAGE_WAIT_JUNIOR',
    color: "green"
  },
  4: {
    translate: 'REPORT_STAGE_JUNIORING',
    color: "green"
  },
  5: {
    translate: 'REPORT_STAGE_JUNIORED',
    color: "green"
  },
  6: {
    translate: 'REPORT_STAGE_WAIT_SENIOR',
    color: "green"
  },
  7: {
    translate: 'REPORT_STAGE_SENIORING',
    color: "blue"
  },
  8: {
    translate: 'REPORT_STAGE_WAIT_SENIORED',
    color: "orange"
  },
  9: {
    translate: 'REPORT_STAGE_WAIT_PRINTED',
    color: "orange"
  },
}
export default reportStatus;
