import React, { Component } from 'react';
import BaseChart from './BaseChart';
import style from './index.less';
import LoadingSpin from './LoadingSpin';
import { formatNDigit, isDataPaltform } from './utils';
import intl from 'react-intl-universal';
import { titleStyle, axisLineX, axisLineY,gridInFo } from './echartInfo';
export class Consistency extends Component {
  getOption() {
    const { data, series, parentName, otherStyle } = this.props;
    const colorList = ['#5B9AFF', '#82F3BE', '#8AE0FF', '#FFC52F', '#FF7A5B'];
    let fromatData = [];
    let formatXAxis = [];
    data.forEach((item) => {
      fromatData.push(formatNDigit(item.data * 100, 0));
      formatXAxis.push(item.label);
    });
    const isDataPlatform = isDataPaltform(parentName);
    let option = {
      backgroundColor: '',
      title: {
        show:!isDataPlatform,  
        left: 'left',
        text: intl.get('CONSISTENCY'),
        top: 10,
        textStyle: titleStyle
      },
      tooltip: {
        formatter: '{b}\n{c}%'
      },
      grid: gridInFo(parentName),
      xAxis: {
        data: formatXAxis,
        axisTick: {
          show: false
        },
        axisLine: axisLineX,
        axisLabel: {
          interval: 0, //横轴信息全部显示
          rotate: formatXAxis.length > 10 ? 30 : '', //30度角倾斜显示
          textStyle: {
            color: otherStyle.xyColor,
            fontSize: 12
          }
        }
      },
      yAxis: [
        {
          type: 'value',
          show: true,
          axisTick: {
            show: false
          },
          axisLine: axisLineY,
          axisLabel: {
            show: true,
            interval: 'auto',
            formatter: '{value} %', //以百分比显示
            textStyle: {
              color: otherStyle.xyColor
            }
          },
          splitArea: {
            areaStyle: {
              color: 'rgba(255,255,255,.5)'
            }
          },
          splitLine: {
            show: !isDataPlatform,
            lineStyle: {
              color: '#666',
              width: 0.5,
              type: 'dashed'
            }
          }
        }
      ],
      series: [
        {
          type: series.chartType,
          barCategoryGap: '30%',
          barWidth: '25%',
          symbol: series.symbol,
          symbolSize: series.symbolSize,
          barMaxWidth: '20px',
          label: {
            show: true,
            formatter: '{c}%',
            position: 'top',
            distance: 8,
            color: otherStyle.xyColor,
            fontSize: '10.6667px'
          },
          itemStyle: {
            normal: {
              color: isDataPlatform
                ? '#129CFF'
                : function(params) {
                    return colorList[params.dataIndex % 5];
                  },
              lineStyle: {
                color: '#129CFF'
              },
              ...series.itemStyle
            },
            emphasis: {
              opacity: 1
            },
            borderColor: '#EE6666'
          },
          data: fromatData
        }
      ]
    };

    return option;
  }

  render() {
    const { data, parentName,otherStyle } = this.props;
    const isDataPlatform = isDataPaltform(parentName);
    return (
      <div className={isDataPlatform ? style.chart_container_consistency : style.chart_container} >
        {data ? (
          <BaseChart style={{ width: '100%', height:otherStyle.height}} option={this.getOption()} />
        ) : (
          <LoadingSpin />
        )}
      </div>
    );
  }
}

export default Consistency;
