import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { setCurrentAnnotationId } from '../../actions/annotationSelectionActions';
import { getAnnotationClassesOfUsers } from '../../selectors/classSelectors';
import AnnotationGroupOfNavigator from './AnnotationGroupOfNavigator';

class AnnotationsOfNavigator extends Component {
  shouldComponentUpdate(nextProps) {
    if (
      !isEqual(this.props.imageVisualInfo, nextProps.imageVisualInfo) ||
      !isEqual(this.props.groups, nextProps.groups) ||
      this.props.showAnnotationClass !== nextProps.showAnnotationClass
    ) {
      return true;
    } else {
      return false;
    }
  }

  componentWillUnmount() {
    window.osdviewer && window.osdviewer.navigator.clearOverlays();
  }

  render() {
    const groups =
      this.props.showAnnotationClass === 'ALL' || !this.props.showAnnotationClass
        ? this.props.groups
        : [this.props.showAnnotationClass];

    return (
      <div>
        {window.osdviewer &&
          this.props.imageVisualInfo &&
          groups.map((group) => (
            <AnnotationGroupOfNavigator
              key={group}
              width={this.props.imageVisualInfo.dimensions[0]}
              height={this.props.imageVisualInfo.dimensions[1]}
              classes={[group]}
            />
          ))}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    showAnnotationClass: state.imageSelection.showAnnotationClass,
    groups: getAnnotationClassesOfUsers(state),
    imageVisualInfo: state.imageSelection.imageVisualInfo
  };
};

export default connect(mapStateToProps, { setCurrentAnnotationId })(AnnotationsOfNavigator);
