import { message } from 'antd';
import axios from 'axios';
import intl from 'react-intl-universal';
import request from './apiUtils';

function saveTokens(accessToken, refreshToken) {
  localStorage.setItem('access_token', accessToken);
  localStorage.setItem('refresh_token', refreshToken);
}

export function getToken(type = 'access_token') {
  if (type === 'access_token') return localStorage.getItem('access_token');
  else return localStorage.getItem('refresh_token');
}

export function setAuthToken() {
  const token = localStorage.getItem('access_token');
  if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
}

export function login(username, password) {
  return new Promise((resolve, reject) => {
    request('/auth/login', {
      method: 'POST',
      data: { username, password }
    })
      .then((resp) => {
        if (resp.status === 200) {
          const tokens = resp.data;
          saveTokens(tokens.access_token, tokens.refresh_token);
          setAuthToken();
        }
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function refresh() {
  return new Promise((resolve, reject) => {
    const refresh_token = localStorage.getItem('refresh_token');
    if (!refresh_token) throw new Error('No refresh token in localStorage');
    request('/auth/refresh', {
      method: 'POST',
      headers: { Authorization: 'Bearer ' + refresh_token }
    })
      .then((resp) => {
        if (resp.status === 200) {
          saveTokens(resp.data.access_token, refresh_token);
          setAuthToken();
          resolve(resp);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function logout() {
  return new Promise((resolve, reject) => {
    request('/auth/logout', { method: 'DELETE' })
    .then((resp) => {
      if (resp?.status === 200) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        setAuthToken();
        resolve(true);
      }
    })
  
  });
}

export function register(options) {
  return new Promise((resolve, reject) => {
    request('/user/register', { method: 'POST', data: options })
      .then((resp) => {
        if (resp.status === 200) {
          const tokens = resp.data;
          saveTokens(tokens.access_token, tokens.refresh_token);
          message.success(intl.get('REGISTER_SUCCESS'));
          resolve(resp);
        }
      })
      .catch((err) => {
        if (err.response) {
          const errData = err.response.data;
          if (errData) {
            let reason = '';
            if (errData.error_code === 9003) {
              reason =","+ intl.get('USER_ALREADY_EXISTS', { username: options.username });
            } else if (errData.error_code === 6003) {
              reason =","+  intl.get('ADMIN_KEY_ERROR');
            }
            message.error(intl.get('REGISTER_FAILURE', { reason }));
          }
        }
        reject(err);
      });
  });
}

export function classifyRoles(permissions){
  const s = permissions.flat().filter((r)=>r.indexOf("static")>-1).map((r)=>r.replace("static:",""))
  const d = permissions.flat().filter((r)=>r.indexOf("dynamic")>-1).map((r)=>r.replace("dynamic:",""))
  return {"static":s,"dynamic":d}
}