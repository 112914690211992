import { createSelector } from 'reselect';
import { getCurrentImageInfo } from '.';
import { getCurrentUser } from './userSelectors';
import {isNoVal} from "../utils/dataUtils"
export const selectEditReportDisabled = createSelector(
  [getCurrentImageInfo, getCurrentUser],
  (currentImageInfo, currentUser) => {
    const { junior_reporter,reporter, stage,rescan } = currentImageInfo || {}
    const { username } = currentUser
    // const disabled = stage === 9 ? true : (reporter ? (reporter === username ? false : true) : true)
    // let disabled = stage === 9 ? true : (reporter ||junior_reporter  ? (reporter === username  || junior_reporter === username  ? false : true) : true)
    let disabled ;
    let infoNotFull =false
    if(isNoVal(currentImageInfo.patient_name) && isNoVal(currentImageInfo.gender) && isNoVal(currentImageInfo.age)){
      infoNotFull=true
    }
    if(rescan || infoNotFull){
      disabled=true
      return disabled
    }
    if([1,2,9,10,11].includes(stage)){
      disabled=true
    }else if([3,4,5].includes(stage)){
      disabled= junior_reporter === username ?false:true
    }else if([6,7,8].includes(stage)){
      disabled=reporter === username?false:true
    }
    
    return disabled
  }
);
export const getReportJunior = (state) => state.report.junior_doctor;

export const getReportSenior = (state) => state.report.senior_doctor;

export const selectSample = state => state.report.sample;

export const selectComments = state => state.reportConf.findings_and_interpretation_strings;
