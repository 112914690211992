function col(columns) {
  let str = '';
  for (let col of columns) {
    str += `<td>${col.title}</td>`;
  }
  return str;
}
function colVal(item, columns) {
  let str = '';
  for (let col of columns) {
      if([null,undefined,''].includes(item[col.excelKey])){
        str += `<td></td>`;
      }else{
        str += `<td>${item[col.excelKey]}</td>`;
      }
   
  }
  return str;
}
export function tableToExcel(data, columns, excelName, titleVal) {
  // 自定义的表格
  let len = columns.length;
  //表格名称
  let tableStr =
    ` <tr style="text-align: center;font-size: 24px;">
                        <th colspan=` +
    len +
    `>` +
    excelName +
    `</th>
                    </tr>`;
  //表格内容描述
  tableStr +=
    ` <tr style="text-align: left;font-size: 16px;">
                        <td colspan=` +
    len +
    `>` +
    titleVal +
    `</td>
                    </tr>`;
  //表头
  tableStr +=
    `<tr style="text-align: left;font-size: 14px;font-weight:bold">` + col(columns) + `</tr>`;
  //内容
  for (let item of data) {
    tableStr += `<tr style="text-align: left;font-size: 14px;">` + colVal(item, columns) + `</tr>`;
  }
  //Worksheet名
  let worksheet = 'Sheet1';
  let uri = 'data:application/vnd.ms-excel;base64,';
  // 真正要导出（下载）的HTML模板
  let exportTemplate = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" 
                    xmlns="http://www.w3.org/TR/REC-html40">
                        <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
                            <x:Name>${worksheet}</x:Name>
                                <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
                            </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
                        </head>
                        <body>
                            <table style="table-layout: fixed;word-wrap: break-word; word-break: break-all;font-family:'宋体'">${tableStr}</table>
                        </body>
                    </html>`;
  //下载模板
  let link = uri + base64(exportTemplate);
  let a = document.createElement("a");
  a.download =excelName+".xlsx";
  a.href = link;
  a.click();
}
//输出base64编码
function base64(s) {
  return window.btoa(unescape(encodeURIComponent(s)));
}
