import intl from 'react-intl-universal';
import { login as _login, register as _register, classifyRoles } from '../utils/authUtils';
import { refreshCurrentUser, setLoginUser } from './userActions';
import { fetchVisibleColumnsAsync, fetchGeneralConfig } from './configActions';
import { initReportResult } from './reportAction';
import { initProQueryInfo } from './productionAction';
import { getReportConf } from './reportConfAction';
import { resetQueryInfo } from './tableActions';
import { initTimeoutImage } from './timeoutAction';
import { RatioBetween } from '../utils/lessUtils';
import { updateChckInQueryInfo } from '../actions/checkInAction';
import { setProQueryInfo } from '../actions/productionAction';
import { updateQueryInfo } from '../actions/tableActions';
import {updateSelectedHospital} from '../actions/statistics'
import jwtDecode from 'jwt-decode';
import { canRender } from '../components/Can';
// import moment from 'moment';
const proData = {
  registrate_from: '',
  registrate_to: '',
  producer: '',
  fuzzy: ''
};
const imageData = { registerFrom: '', registerTo: '', owner: '' };
export const toggleLoginModal = (show, reason) => ({
  type: 'TOGGLE_LOGIN_MODAL',
  show,
  reason
});

export function login(form, history) {
  return async (dispatch) => {
    const { username, password } = form.getFieldsValue();
    _login(username, password)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(setLoginUser(username));
          dispatch(refreshCurrentUser(resp.data.access_token));
          dispatch(fetchVisibleColumnsAsync());
          dispatch(resetQueryInfo({}, imageData));
          dispatch(initProQueryInfo(proData));
          dispatch(toggleLoginModal(false));
          dispatch(initReportResult());
          dispatch(getReportConf());
          dispatch(initTimeoutImage());
          dispatch(updateSelectedHospital({ data: [], total: 0 }));
          let user = jwtDecode(resp.data.access_token);
          let checkP;
          let proP;
          let imgP;
          if (RatioBetween(document)) {
            checkP = { limit: 20 };
            proP = { limit: 20, producer: user.id };
            imgP = { limit: 15, owner: user.id, machines: undefined };
          } else {
            checkP = { limit: 20 };
            proP = { limit: 20, producer: user.id };
            imgP = { limit: 10, owner: user.id, machines: undefined };
          }
          dispatch(updateChckInQueryInfo(checkP));
          dispatch(setProQueryInfo(proP));
          dispatch(updateQueryInfo(imgP));
          dispatch(fetchGeneralConfig()).then((res) => {
            toPage(res.node_mode, resp.data.access_token, history);
          });
        }
      })
      .catch((err) => {
        const { setFields } = form;
        if (err.response) {
          if (err.response.data.error_code === 6002)
            setFields({ password: { errors: [new Error(intl.get('ACCOUNT_PASSWORD_ERROR'))] } });
        }
      });
  };
}

function toPage(nodeMode, t, history) {
  const token = t ? t : localStorage.getItem('access_token');
  const decoded = { ...jwtDecode(token) };
  const permissions = classifyRoles(decoded.permissions.flat());
  if (window.location.pathname === '/') {
    [0,2].includes(nodeMode) && history.push('mainpage');
    if ([1].includes(nodeMode)) {
      canRender(permissions, 'client:report:expert')
        ? history.push('expert')
        : history.push('preference/monitor');
    }
  } else {
    setTimeout(() => window.location.reload(), 1000);
  }
}

export function registerGo(values, history) {
  return async (dispatch) => {
    _register(values).then((resp) => {
      dispatch(setLoginUser(values.username));
      dispatch(refreshCurrentUser(resp.data.access_token));
      dispatch(fetchVisibleColumnsAsync());
      dispatch(resetQueryInfo({}, imageData));
      dispatch(initProQueryInfo(proData));
      dispatch(initReportResult());
      dispatch(initTimeoutImage());
      dispatch(getReportConf());
      dispatch(fetchGeneralConfig());
      history.push('/mainpage');
    });
  };
}
