import React, { Component } from 'react';
import './index.css';
import { connect } from 'react-redux';
import Toolbox from '../Toolbox'
import { getImageTotalCount } from '../../selectors';
import { updateNextImage, updatePreviousImage } from '../../actions/imageActions';
import Zoomer from './Zoomer'
class MainConsole extends Component {

  render() {
    return (
      <div className="main__console">
        <Zoomer/>
        <Toolbox/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    total: getImageTotalCount(state),
  };
};

export default connect(mapStateToProps, { updateNextImage, updatePreviousImage })(MainConsole);
