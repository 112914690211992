import React, { Component } from 'react';
import intl from 'react-intl-universal';
import styles from './index.less';
import TextArea from 'antd/lib/input/TextArea';
import { Select } from 'antd';
import { connect } from 'react-redux';
import {isNoVal} from "../../utils/dataUtils"
import {getFindings} from "../../utils/reportUtils"
const { Option } = Select;

export function qualifiedSelect(currentImageInfo,interpretation) {
  if (currentImageInfo.rescan) {
    return 'COMMENTS15';
  } else if (currentImageInfo.reproduce) {
    return 'COMMENTS16';
  } else {
    // return getSelectInterpretation(labels, sample);
    return interpretation
  }
}
// eslint-disable-next-line complexity
export function getSelectInterpretation(labels, sample) {
  if (!sample || !labels) return '';
  if (sample.sample_satisfaction && labels.includes('NILM')) {
    return 'COMMENTS1';
  }
  if (!sample.sample_satisfaction) {
    return 'COMMENTS2';
  }

  let Condition_SHANGPI =
    labels.includes('SCC') ||
    labels.includes('HSIL') ||
    labels.includes('LSIL') ||
    labels.includes('ASC-H');
  let Condition_EC =
    labels.includes('AGC-EC') ||
    labels.includes('AGC-N-EC') ||
    labels.includes('ADC-EC') ||
    labels.includes('AIS');
  let Condition_EC_EU = Condition_EC || labels.includes('ADC-EU');
  let Condition_EM = labels.includes('AGC-EM') || labels.includes('ADC-EM');
  let Condition_NONE = labels.includes('AGC') || labels.includes('AGC-N') || labels.includes('ADC');

  // 组合情况
  if (Condition_EC_EU && Condition_SHANGPI) {
    return 'COMMENTS13';
  }
  if (Condition_EM && Condition_SHANGPI) {
    return 'COMMENTS14';
  }
  if (Condition_NONE && Condition_SHANGPI) {
    return 'COMMENTS12';
  }

  // 其他情况
  if (Condition_EC) {
    return 'COMMENTS8';
  }

  if (Condition_EM) {
    return 'COMMENTS9';
  }

  if (Condition_NONE) {
    return 'COMMENTS7';
  }

  if (Condition_SHANGPI) {
    return 'COMMENTS5';
  }

  if (labels.includes('EMC')) {
    return 'COMMENTS6';
  }

  if (labels.includes('ASC-US')) {
    return 'COMMENTS3';
  }
  return '';
}

class SampleReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropDown: false
    };
  }

  handler = (e) => {
    let val = e.target && e.target.value ? e.target.value : '';
    this.props.onChange({ comments: val });
  };
  debounce = (fn, delay) => {
    let timer = null;
    return function(event) {
      timer && clearTimeout(timer);
      event.persist && event.persist();
      timer = setTimeout(() => {
        fn.call(this, event);
      }, delay);
    };
  };
  onChangeHandler = this.debounce(this.handler, 1000);

  render() {
    const {  comments, interpretation, printing,labels,reportConf,currentImageInfo} = this.props;
    const results = isNoVal(reportConf.findings_and_interpretation_strings)?[]:reportConf.findings_and_interpretation_strings
    let COMMENTS_DATA = results.filter(item=>item.value.startsWith('COMMENTS') && item.value.indexOf("_") === -1)
    COMMENTS_DATA = COMMENTS_DATA.map((item) => ({...item, label: intl.get(item.value)}));
    let text = interpretation ? interpretation : qualifiedSelect(currentImageInfo,interpretation);
    let value = isNoVal(text) ?''  :results.filter(i=>i.value===text)[0]?.value ;
  
    if(value){
      value = intl.get(value);
    }
    

    return (
      <div className={styles.printing}>
        <h3>
          <b>{intl.get('REPORT_INTERPRETATION_LABEL')}</b>
        </h3>
        <div id="ReportResult" className={printing ? styles.reportDivPrinting : styles.reportDiv}>
          {getFindings(labels)}
          {/* {findings} */}
        </div>
        <h3>
          <b>{intl.get('REPORT_REMARK_SUGGESTION')}</b>
        </h3>
        <div className={printing ? styles.reportDivPrinting : styles.reportDiv}>
          {printing ? (
            <>
            {value}
            <br />
            {comments}
          </>
          ) : (
            <>
              <Select
                value={interpretation}
                style={{ width: '100%', marginBottom: '5px', color: '#169BD5' }}
                onChange={(val) => {
                  this.props.onChange({ interpretation: val ? val : '' });
                }}
              >
                <Option key="nothing" value="">
                  {intl.get('NULL')}
                </Option>
                {COMMENTS_DATA.map((item) => {
                  return (
                    <Option key={item.value} value={item.value}>
                      {item.label}
                    </Option>
                  );
                })}
              </Select>
              <TextArea
                placeholder={intl.get('LIMIT_TEXT_LENGTH', { n: 200 })}
                maxLength={200}
                autoSize={{ minRows: 2, maxRows: 3 }}
                onChange={(e) => this.onChangeHandler(e)}
                defaultValue={comments}
                key={comments}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentImageInfo: state.imageSelection.currentImageInfo || {},
  reportConf: state.reportConf,
});

export default connect(mapStateToProps, {})(SampleReport);
