/* jshint esversion: 9 */
/* @flow */
import React, { Component } from 'react';
import config from '../../config';
import FineUploaderTraditional from 'fine-uploader-wrappers'
import { connect } from 'react-redux';
import { addAndTriggerImageAsync } from '../../actions/imageActions'
import { getCurrentUser } from '../../selectors/userSelectors';
import Gallery from 'react-fine-uploader'
import 'react-fine-uploader/gallery/gallery.css'
import intl from 'react-intl-universal';

class UploadPane extends Component {
  componentDidMount() {
    this.uploader.on('complete', this.completeHandler)
  }

  componentWillUnmount() {
    this.uploader.off('complete', this.completeHandler)
  }

  uploader = new FineUploaderTraditional({
    options: {
      chunking: {
        enabled: true
      },
      deleteFile: {
        enabled: true,
        endpoint: '/uploads'
      },
      request: {
        endpoint: `${config.baseUrl}/api/upload`
      },
      retry: {
        enableAuto: true
      }
    }
  })

  completeHandler = (id, name, response) => {
    const info = {
      add: {
        filename: name,
        uploader: this.props.loginUser,
        clazz: this.props.sample,
      },
      trigger: {
        image: name,
        trigger_by: 'upload',
        detector_type: this.props.sample,
      },
      shouldDetect: this.props.shouldDetect
    }
    this.props.addAndTriggerImageAsync(info)
  }

  render() {
    const fileType = intl.get('IMAGE_TYPE', { sample: this.props.sample })
    return (
      <Gallery
        uploader={this.uploader}
        fileInput-children={
          <span>
            {intl.getHTML("UPLOAD_IMAGE", { fileType })}
          </span>
        }
      />
    )
  }
}

const mapStateToProps = (state) => ({
  loginUser: getCurrentUser(state).username
});

export default connect(mapStateToProps, { addAndTriggerImageAsync })(UploadPane);

