import React, { Component } from 'react';
import style from './index.less';
import intl from 'react-intl-universal';
import { Col, Row } from 'antd';
import Icon from '../Iconfont';

export default class DataCard extends Component {
  constructor(props) {
    super();
    this.state = {
      infoList: []
    };
  }
  componentDidMount() {
    this.sumData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.sumData();
    }
  }
  sumData() {
    let today_uploaded = 0;
    let today_analysis = 0;
    let acc_analysis = 0;
    let acc_uploaded = 0;
    const { data, selected } = this.props;
    let selectedIds = selected.data.map((i) => i.id);
    let selectedData = data
      ? selected.total > 0
        ? data.filter((d) => selectedIds.includes(d.id))
        : data
      : [];
    if (selectedData) {
      selectedData.forEach((item) => {
        today_uploaded += item.today_uploaded;
        today_analysis += item.today_analysis;
        acc_uploaded += item.acc_uploaded;
        acc_analysis += item.acc_analysis;
      });
    }
    let l = [
      {
        name: intl.get('ACC_ANALYSIS'),
        nums: acc_analysis,
        class: style.card__index1,
        icon: <Icon type="icon-AIleijizhenduanliang" />
      },
      {
        name: intl.get('ACC_UPLOADED'),
        nums: acc_uploaded,
        class: style.card__index2,
        icon: <Icon type="icon-leijishangchuanliang" />
      },
      {
        name: intl.get('TODAY_ANALYSIS'),
        nums: today_analysis,
        class: style.card__index3,
        icon: <Icon type="icon-jinriAIzhenduanliang" color="#fff" />
      },
      {
        name: intl.get('TODAY_UPLOADED'),
        nums: today_uploaded,
        class: style.card__index4,
        icon: <Icon type="icon-jinrishangchuanliang" />
      }
    ];
    this.setState({ infoList: l });
  }

  render() {
    return (
      <div className={style.top_container}>
        <Row gutter={10}>
          {this.state.infoList.map((item, index) => {
            return (
              <Col span={6} key={index}>
                <div className={style.card__outter + ' ' + item.class}>
                  <div className={style.card__inner}>
                    <div>{item.icon}</div>
                    <div>
                      <div className={style.card__name}> {item.name}</div>
                      <div className={style.card__nums}> {item.nums}</div>
                    </div>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
}
