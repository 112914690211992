// import intl from 'react-intl-universal';
// import { formatNDigit } from './utils';
import { isDataPaltform } from './utils';
export const titleStyle = {
  color: '#000',
  fontSize: 14,
  fontWeight: '700'
};
export const girdInfo = {
  top: '50px',
  left: '50px',
  right: '30px',
  bottom: '35px'
};

export function gridInFo(parentName) {
  const p = isDataPaltform(parentName);
  return {
    top: p ? '30px' : '50px',
    left: '50px',
    right: p ? '40px' : '30px',
    bottom: '35px'
  };
}
export const girdInfoRe = {
  top: '50px',
  left: '30px',
  right: '30px',
  bottom: '50px'
};
export const axisLineX = {
  lineStyle: {
    color: 'rgba(255, 129, 109,.1)',
    width: 1
  }
};
export const axisLineY = {
  lineStyle: {
    color: 'rgba(255, 129, 109,.1)',
    width: 0
  }
};

export const beautifulTableCol = [
  {
    key: 'hospital',
    name: 'HOSPITAL',
    data: ['hospital'],
    unit: '',
    concentLine: '/',
    part: ['platform', 'statisics']
  },
  {
    key: 'acc_uploaded',
    name: 'ACC_UPLOADED_TITLE',
    data: ['acc_uploaded'],
    unit: '',
    concentLine: '/',
    part: ['platform', 'statisics']
  },
  {
    key: 'acc_analysis',
    name: 'ACC_ANALYSIS_TITLE',
    data: ['acc_analysis'],
    unit: '',
    concentLine: '/',
    part: ['platform', 'statisics']
  },
  {
    key: 'today_uploaded',
    name: 'TODAY_UPLOADED_TITLE',
    data: ['today_uploaded'],
    unit: '',
    concentLine: '/',
    part: ['platform', 'statisics']
  },
  {
    key: 'today_analysis',
    name: 'TODAY_ANALYSIS_TITLE',
    data: ['today_analysis'],
    unit: '',
    concentLine: '/',
    part: ['platform', 'statisics']
  },

  {
    key: 'negative_per_slide',
    name: 'NEG_POS_TIME',
    data: ['negative_per_slide', 'positive_per_slide', 'diagnosis_per_slide'],
    unit: 's',
    concentLine: '/',
    part: ['platform', 'statisics']
  },
  {
    key: 'disk_percent',
    name: 'STORE_PERCENT',
    data: ['disk_total', 'disk_percent'],
    unit: '%',
    concentLine: '/',
    part: ['platform', 'statisics']
  },
  {
    key: 'license_used',
    name: 'LICENSE_USE_TOTAL',
    data: ['license_used', 'license_total'],
    unit: '',
    concentLine: '/',
    part: ['platform', 'statisics']
  },
  {
    key: 'running_at',
    name: 'ALL_RUN_TIME',
    data: ['running_at'],
    unit: '天',
    concentLine: '/',
    part: ['platform', 'statisics']
  },
  {
    key: 'version',
    name: 'PRO_VERSION',
    data: ['version'],
    unit: '',
    concentLine: '/',
    part: ['platform', 'statisics']
  },
  {
    key: 'hostPort',
    name: 'HOST_PORT',
    data: ['ip', 'web_port'],
    unit: '',
    concentLine: ':',
    part: ['statisics']
  },
  {
    key: 'action',
    name: 'ACTION',
    data: ['state'],
    unit: '',
    concentLine: '/',
    part: ['statisics']
  }
];

export const colorsList = [
  '#065DFF',
  '#5334ED',
  '#FFCF5F',
  '#4DD1FF',
  '#1B7FC8',
  '#0440B0',
  '#2806CF',
  '#FFB710',
  '#02AAE4',
  '#0C5E9A',
  '#1B7FC8',
  '#7D68E9',
  '#FDDA89',
  '#7CDDFF',
  '#129BFF'
];
