import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import { Radio, message, Card } from 'antd';
import {
  fetchGeneralConfig,
  updateGeneralConfig,
  setCurrentPage
} from '../../../actions/configActions';
// import style from './index.less';
import ModeHostPort from './ModeHostPort';
import CommonPanel from '../commonPanel';
function DiagnosticMode(props) {
  const [value, setValue] = useState('');
  const [modevalue, setModeValue] = useState('');
  useEffect(() => {
    props.fetchGeneralConfig().then((res) => {
      if (res) {
        setValue(res.product_mode);
        setModeValue(res.node_mode);
      }
    });
  }, []);
  const onChange = (e) => {
    props.updateGeneralConfig({ product_mode: e.target.value }).then((res) => {
      if (res) {
        message.success(intl.get('MARK_MODIFY_SUCCESS'));
        setValue(e.target.value);
      }
    });
  };
  const onChangeMode = (e) => {
    props.updateGeneralConfig({ node_mode: e.target.value }).then((res) => {
      if (res) {
        message.success(intl.get('MARK_MODIFY_SUCCESS'));
        setModeValue(e.target.value);
        if (e.target.value === 1) {
          props.setCurrentPage('report');
        }
      }
    });
  };
  return (
    <CommonPanel>
      <Card title={intl.get('NODE_MODE')}>
        <Radio.Group onChange={onChange} value={value}>
          <Radio value={0}>{intl.get('DIAGNIOSIC_MODE_ONLINE')}</Radio>
          <Radio value={1}>{intl.get('DIAGNIOSIC_MODE_ALONE')}</Radio>
        </Radio.Group>
      </Card>
      <br />
      <Card title={intl.get('NODE_TYPE')}>
        <Radio.Group onChange={onChangeMode} value={modevalue}>
          <Radio value={0}>{intl.get('NODE_MODE_LOC')}</Radio>
          <Radio value={2}>{intl.get('NODE_MODE_COLONY')}</Radio>
          <Radio value={1}>{intl.get('NODE_MODE_REMOTE')}</Radio>
        </Radio.Group>
        {modevalue === 0 && <ModeHostPort modevalue={modevalue}/>}
      </Card>
    </CommonPanel>
  );
}

const mapStateToProps = (state) => ({
  general: state.config.general
});

export default connect(mapStateToProps, {
  fetchGeneralConfig,
  updateGeneralConfig,
  setCurrentPage
})(DiagnosticMode);
