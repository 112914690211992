const initial = {
  loading: false,
  sample: {},
  patient: {},
  diagnosis: {},
  historyVisible: false,
  doctorList: [],
  planReporter: [],
  roleUsers: {
    admin:[],
    producer:[],
    general:[],
    viewer:[],
    validator:[],
    distributor:[],
    register:[],
    general_doctor:[],
    junior_doctor:[],
    senior_doctor:[],
    report_exporter:[],
    devops:[],
    expert:[],
    ai:[],
  }
};

export function report(state = initial, action) {
  switch (action.type) {
    case 'UPDATE_REPORT_RESULT':
      return {
        ...state,
        ...action.data
      };
    case 'UPDATE_REPORT_NESTED':
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          ...action.data
        }
      };
    case 'INIT_REPORT_RESULT':
      return {
        ...initial,
        ...state
      };
    case 'SET_PLAN_REPORTER':
      return {
        ...state,
        planReporter: action.data
      };
    case 'SET_ROLES_USERS':
      return {
        ...state,
        roleUsers:action.data
      };

    case 'SET_HISTORY_VISIBLE':
      return {
        ...state,
        historyVisible: action.show
      };
    case 'SET_DOCTOR_LIST':
      return {
        ...state,
        doctorList: action.data
      };
    default:
      return state;
  }
}
