import {
  fetchStatisDistribution,
  fetchStatisSummary,
  fetchStatisPositive,
  fetchStatisConsistency,
  fetchStatisWorkload,
  fetchStatisPerformance,
  fetchStatisQuality,
  fetchStatisWorkUser,
  fetchStatisWorkSummary,
  fetchStatisIncidence,
  fetchQualityMonth,
  apiGetMinitor,
  apiGetIncidenceAll,
  apiUpdateSwarmBymachineId,
  apiDeleteSwarmBymachineId
} from './api';
import { getStatisticsQueryInfo } from '../selectors/statisticsSelector';
import store from '../store';
import { put, takeLatest } from 'redux-saga/effects';
import { canRender } from '../components/Can';
import { getCurrentUser } from '../selectors/userSelectors';
import { uniq } from 'lodash';
export const updateStatistics = (data, field) => ({
  type: 'UPDATE_STATISTICS',
  data,
  field: field
});
import qs from 'qs';
import { getSwarmList } from './configActions';
export function updateWorkStatisticsYear(data) {
  return {
    type: 'UPDATE_WORK_STATISTICS_YEAR',
    data
  };
}

export function updateWorkStatisticsUser(data) {
  return {
    type: 'UPDATE_WORK_STATISTICS_USER',
    data
  };
}

export function updateStatisticsQueryInfo(data) {
  return {
    type: 'UPDATE_STATISTICS_QUERY_INFO',
    data
  };
}

export function updateSelectedHospital(data) {
  return {
    type: 'UPDATE_SELECTED_HOSPITAL',
    data
  };
}
export function* watchUpdateStatistic() {
  yield takeLatest('UPDATE_STATISTICS_QUERY_INFO', function*(action) {
    yield put(StatisticByTime());
  });
}

export function initStatis() {
  return async (dispatch) => {
    getStatisDistribution()(dispatch);
    getStatisSummary()(dispatch);
    getStatisPositive()(dispatch);
    getStatisConsistency()(dispatch);
    getStatisWorkload()(dispatch);
    getStatisPerformance()(dispatch);
    getStatisQuality()(dispatch);
    getStatisIncidence()(dispatch);
    getQualityMonth()(dispatch);
    getIncidenceAll()(dispatch);
  };
}

export function StatisticByTime() {
  return async (dispatch) => {
    getStatisDistribution()(dispatch);
    getStatisSummary()(dispatch);
    getStatisPositive()(dispatch);
    getStatisConsistency()(dispatch);
    getStatisWorkload()(dispatch);
    getStatisPerformance()(dispatch);
    getStatisQuality()(dispatch);
    getStatisIncidence()(dispatch);
    getQualityMonth()(dispatch);
    getIncidenceAll()(dispatch);
  };
}

export function getStatisQuality() {
  let query = formQueryInfo();
  return async (dispatch) => {
    fetchStatisQuality(query)
      .then((resp) => {
        dispatch(updateStatistics(resp.data, 'quality'));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function getStatisWorkloadUser(params) {
  let p = {
    from: params[0],
    to: params[1]
  };
  return async (dispatch) => {
    fetchStatisWorkUser(p)
      .then((resp) => {
        let list = [resp.data.senior, resp.data.junior, resp.data.total];
        dispatch(updateWorkStatisticsUser(list));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function getStatisWorkloadSummary() {
  return async (dispatch) => {
    fetchStatisWorkSummary()
      .then((resp) => {
        dispatch(updateWorkStatisticsYear(resp.data));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function getStatisDistribution() {
  let query = formQueryInfo();
  return async (dispatch) => {
    fetchStatisDistribution(query)
      .then((resp) => {
        dispatch(updateStatistics(resp.data, 'distribution'));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function getStatisSummary() {
  const queryInfo = getStatisticsQueryInfo(store.getState());
  return async (dispatch) => {
    fetchStatisSummary(queryInfo)
      .then((resp) => {
        getMinior(resp.data.data)(dispatch);
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function getStatisPositive() {
  let query = formQueryInfo('machines');
  return async (dispatch) => {
    fetchStatisPositive(query)
      .then((resp) => {
        dispatch(updateStatistics(resp.data.data, 'positive'));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}
export function getStatisConsistency() {
  let query = formQueryInfo();
  return async (dispatch) => {
    fetchStatisConsistency(query)
      .then((resp) => {
        dispatch(updateStatistics(resp.data.data, 'consistency'));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function getStatisWorkload() {
  let query = formQueryInfo();
  return async (dispatch) => {
    fetchStatisWorkload(query)
      .then((resp) => {
        dispatch(updateStatistics(resp.data.data, 'workload'));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function getStatisPerformance() {
  let query = formQueryInfo();
  return async (dispatch) => {
    fetchStatisPerformance(query)
      .then((resp) => {
        dispatch(updateStatistics(resp.data, 'performance'));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function getIncidenceAll() {
  let query = formQueryInfo('machines');
  return async (dispatch) => {
    apiGetIncidenceAll(query)
      .then((resp) => {
        dispatch(updateStatistics(resp.data.data, 'incidenceAll'));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function getStatisIncidence() {
  let query = formQueryInfo('machines');
  return async (dispatch) => {
    fetchStatisIncidence(query)
      .then((resp) => {
        let res = resp.data.data;
        let yList = uniq(res.map((r) => r.year))
          .sort()
          .reverse();
        if (yList.length > 2) {
          yList = yList.slice(0, 2);
        }
        let resList = [];
        yList.forEach((y) => {
          let inner = [];
          res.forEach((r) => {
            if (r.year === y) {
              inner.push([r.age, r.data * 100]);
            }
          });
          resList.push({ name: y, val: inner });
        });
        dispatch(updateStatistics(resList, 'incidence'));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function getQualityMonth() {
  let query = formQueryInfo('machines');
  return async (dispatch) => {
    fetchQualityMonth(query)
      .then((resp) => {
        dispatch(updateStatistics(resp.data.data, 'qualityMonth'));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

function formQueryInfo(type) {
  const queryInfo = getStatisticsQueryInfo(store.getState());
  return qs.stringify(
    type === 'machines'
      ? { registerFrom: '', registerTo: '', machines: queryInfo.machines }
      : queryInfo,
    {
      arrayFormat: 'repeat'
    }
  );
}

export function getMinior(summaryData) {
  let leastData = [];
  const initData = {
    disk_percent: '',
    disk_total: '',
    license_total: '',
    license_used: '',
    running_at: '',
    version: ''
  };
  return async (dispatch) => {
    const user = getCurrentUser(store.getState());
    apiGetMinitor()
      .then((resp) => {
        let resData = resp.data.data;
        summaryData.forEach((s) => {
          let appendData = initData;
          for (let i = 0; i < resData.length; i++) {
            if (s.id === resData[i].id) {
              appendData = resData[i];
              break;
            }
          }
          leastData.push({ ...s, ...appendData });
        });
        if( canRender(user.permissions, 'swarm:view')){
          dispatch(getSwarmStateList(leastData));
        }else{
          dispatch(updateStatistics(leastData, 'summary'));
        }
        
      })
      .catch((err) => {
        dispatch(updateStatistics(summaryData, 'summary'));
        console.warn(err);
      });
  };
}

export function getSwarmStateList(dataList) {
  let list = dataList;
  return async (dispatch) => {
    dispatch(getSwarmList()).then((SwarmList) => {
      let keys = Object.keys(SwarmList);
      list.forEach((l) => {
        for (let i = 0; i < keys.length; i++) {
          let ipInfo = SwarmList[keys[i]].filter(swarm=>swarm.id===l.id)
          if(ipInfo.length>0 && keys[i]!=='rejected'){
            l.state = keys[i];
            l.ip=ipInfo[0].name;
            l.web_port=ipInfo[0].web_port;
            break;
          }
        }
      });
      dispatch(updateStatistics(list, 'summary'));
    }) .catch((err) => {
      dispatch(updateStatistics(dataList, 'summary'));
      console.warn(err);
    });;
  };
}


export function updateSwarmBymachineId(machineId) {
  return function(dispatch) {
    return apiUpdateSwarmBymachineId(machineId).then(
      (response) => {
        console.log(response);
        dispatch(getStatisSummary())
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

export function delSwarmBymachineId(machineId) {
  return function(dispatch) {
    return apiDeleteSwarmBymachineId(machineId).then(
      (response) => {
        console.log(response);
        dispatch(getStatisSummary())
      },
      (error) => {
        console.log(error);
      }
    );
  };
}


