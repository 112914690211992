
const initial = { 
    computedTime: 0,
    notNoteTimeoutList: {
      data:[],
      total:0
    },
     preTimeoutList: {
      data:[],
      total:0
    },
    timeoutList:  {
      data:[],
      total:0
    },
    notReadTimeoutList:  {
      data:[],
      total:0
    },
    isInit:false
  };
export function timeout(state = initial, action) {
    if (state.computedTime=== undefined) state.computedTime = initial.computedTime;
    if (state.timeoutList === undefined) state.timeoutList = initial.timeoutList;
    if (state.preTimeoutList === undefined) state.preTimeoutList = initial.preTimeoutList;
    if (state.notNoteTimeoutList === undefined) state.notNoteTimeoutList = initial.notNoteTimeoutList;
    if (state.notReadTimeoutList === undefined) state.notReadTimeoutList = initial.notReadTimeoutList;
    switch (action.type) {
        case 'UPDATE_COMPUTED_TIME':
            return {
              ...state,
              computedTime: action.data
            };
          case 'SET_TIMEOUT_LIST':
            return {
              ...state,
              timeoutList: action.data
            };
          case 'SET_PRE_TIMEOUT_LIST':
            return {
              ...state,
              preTimeoutList: action.data
              };
          case 'SET_NOT_NOTE_TIMEOUT_LIST':
            return {
              ...state,
              notNoteTimeoutList: action.data
            };
          case 'SET_NOT_READ_TIMEOUT_LIST':
              return {
                ...state,
                notReadTimeoutList: action.data
              };
          case 'CLEAR_TIMEOUT':
                return {...state, ...initial };
          case 'SET_INIT_LABEL':
               return {
                ...state,
                isInit: action.data
               }  
        default:
            return state;
    }
}  