import React, { Component } from 'react';
import { connect } from 'react-redux';
import DateSearch from './DateSearch';
import { Row, Col, DatePicker } from 'antd';
import moment from 'moment';
import { updateStatisticsQueryInfo } from '../../actions/statistics';
import { isPartEnd } from '../../utils/urlUtils';
import AddHospital from './AddHospital';
const { RangePicker } = DatePicker;
class SearchTab extends Component {
  constructor() {
    super();
    this.state = {};
  }

  changeDate(value, dateString) {
    let p = {
      registerFrom: dateString[0],
      registerTo: dateString[1]
    };
    this.props.updateStatisticsQueryInfo(p);
  }
  changeDataRange(data) {
    let p = {
      registerFrom: data.From,
      registerTo: data.To
    };
    this.props.updateStatisticsQueryInfo(p);
  }
  render() {
    const { queryInfo,isplatform } = this.props;
    let timeList =
      queryInfo.registerFrom !== '' && queryInfo.registerTo !== ''
        ? [moment(queryInfo.registerFrom), moment(queryInfo.registerTo)]
        : null;
    const partEnd = isPartEnd();
    return (
      <Row gutter={30} style={{ marginLeft: 0, marginRight: 0, paddingTop: 15, paddingBottom: 15 }}>
        <Col span={8} offset={1}>
          <RangePicker
            showTime={{
              format: 'HH:mm',
              defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')]
            }}
            value={timeList}
            onChange={this.changeDate.bind(this)}
            style={{ width: '100%' }}
          />
        </Col>
        <Col span={10} offset={1}>
          <DateSearch
            parent={this}
            dateRange={{ From: queryInfo.registerFrom, To: queryInfo.registerTo }}
            isplatform={isplatform}
          />
        </Col>
        {partEnd === 'centerEnd' && (
          <Col span={3} >
           <AddHospital />
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    queryInfo: state.statistics.queryInfo
  };
};

export default connect(mapStateToProps, { updateStatisticsQueryInfo })(SearchTab);
