import { uniqBy } from 'lodash';
import cfg from '../config';

const initial = {
  images: {
    data: [],
    total: 0,
    fromImageId: ''
  },
  selected: {
    data: [],
    total: 0
  },
  searched: {
    data: [],
    total: 0,
    fromImageId: ''
  },
  mode: 'query',
  queryInfo: {
    page: 0,
    limit: 10,
    registerFrom: '',
    registerTo: '',
    sortBy: 'register_date',
    sortAsc: false,
    patient_name: '',
    filename: '',
    clazz: cfg.defaultCancerType,
    owner:"",
    fuzzy:"",
  },
  searchQuery: {
    page: 0,
    limit: 10,
    registerFrom: '',
    registerTo: '',
    sortBy: 'register_date',
    filename: '',
    patient_name: '',
    clazz: cfg.defaultCancerType,
    owner:"",
    fuzzy:"",
  },
  forceLoading: false,
  readedList: [],
  cachePage:{
    search:[],
    query:[]
  }
  
};

const addImagesByMode = (state, images, total,name) => {
  if(name==='pre'){
    switch (state.mode) {
      case 'query':
        return {
          ...state,
          images:{...state.images,data: uniqBy(images.concat(state.images.data), 'key'),total}
        };
      case 'select':
        return {
          ...state,
          selected: {
            ...state.images,
            data: uniqBy(state.selected.data.concat(images), 'key'),
            total
          }
        };
      case 'search':
        return {
          ...state,
          searched: {
            ...state.images,
            data: uniqBy(images.concat(state.searched.data), 'key'),
            total
          }
        };
      default:
        return {
          ...state,
          images: { ...state.images, data: uniqBy(images.concat(state.images.data), 'key'), total }
        };
    }
    }else{
      switch (state.mode) {
        case 'query':
          return {
            ...state,
            images: { ...state.images, data: uniqBy(state.images.data.concat(images), 'key'), total }
          };
        case 'select':
          return {
            ...state,
            selected: {
              ...state.images,
              data: uniqBy(state.selected.data.concat(images), 'key'),
              total
            }
          };
        case 'search':
          return {
            ...state,
            searched: {
              ...state.images,
              data: uniqBy(state.searched.data.concat(images), 'key'),
              total
            }
          };
        default:
          return {
            ...state,
            images: { ...state.images, data: uniqBy(state.images.data.concat(images), 'key'), total }
          };
      }
    }

};

const loadImages = (state = initial, action) => {
  if (!state.readedList) state.readedList = [];
  if (!state.selected) state.selected = initial.selected;
  if (!state.cachePage) state.cachePage = initial.cachePage;
  switch (action.type) {
    
    case 'LOAD_IMAGES':
      return {
        ...state,
        images: {
          ...state.images,
          ...action.images
        },
        mode: 'query'
      };
    case 'LOAD_QUERY_INFO':
      return {
        ...state,
        queryInfo: action.queryInfo
      };
    case 'LOADING_TABLE':
      return {
        ...state,
        forceLoading: action.loading
      };
    case 'UPDATE_QUERY_INFO':
      return {
        ...state,
        queryInfo: {
          ...state.queryInfo,
          ...action.condition
        }
      };
    case 'ADD_IMAGES':
      return addImagesByMode(state, action.images, action.total,action.name);
    case 'UPDATE_CURRENT_IMAGE_INFO':
      return {
        ...state,
        images: {
          ...state.images,
          data: state.images.data.map((img) =>
            img.key === action.key ? { ...img, ...action.update } : img
          )
        }
      };
    case 'SET_CACHE_PAGE':
      return{
        ...state,
        cachePage:{
          ...state.cachePage,
          [action.name]:action.data
        }
      }
    case 'READ_IMAGE':
      return {
        ...state,
        readedList: [...state.readedList.filter((fn) => fn !== action.filename), action.filename]
      };
    case 'UNREAD_IMAGE':
      return {
        ...state,
        readedList: state.readedList.filter((fn) => fn !== action.filename)
      };
    case 'UPDATE_IMAGE_SELECTION':
      return {
        ...state,
        selected: action.selection,
        mode: 'select'
      };
    case 'UPDATE_SEARCH_IMAGES':
      return {
        ...state,
        searched: { ...state.searched, ...action.resp },
        mode: 'search'
      };
    case 'UPDATE_SEARCH_QUERY':
      return {
        ...state,
        searchQuery: { ...state.searchQuery, ...action.query }
      };
    case 'UPDATE_LOAD_IMAGE_MODE':
      return {
        ...state,
        mode: action.mode
      };
    case 'SET_FROM_IMAGE_ID':
      return {
        ...state,
        images: {
          ...state.images,
          fromImageId: action.fromImageId
        }
      };
    case 'SET_SEARCHED_FROM_IMAGE_ID':
      return {
        ...state,
        searched: {
          ...state.searched,
          fromImageId: action.fromImageId
        }
      };
    default:
      return state;
  }
};

export default loadImages;
