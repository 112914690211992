import { message } from 'antd';
import intl from 'react-intl-universal';
import { getCurrentTab, getCurrentTabIdx, getSecondsTabs } from '../selectors/tabSelectors';
import store from '../store';
import downloadCsv from '../utils/csvUtils';
import { fetchCsv, exportList, fetchDeleteImages } from './api';
import { toggleTab } from './tabActions';
import { omit } from 'lodash';
import { getConfig } from '../selectors/configSelector';
import { getQueryInfo } from '../selectors/imageSelector';
import { toggleDownload } from './setting';
import { isPartEnd } from '../utils/urlUtils';
export function toggleLoadingTable(loading) {
  return { type: 'LOADING_TABLE', loading };
}

export const updateQueryInfo = (condition) => ({
  type: 'UPDATE_QUERY_INFO',
  condition
});

export function resetQueryInfo(reserveKeys, initialKeys = {}) {
  const secondsTabs = getSecondsTabs(store.getState());
  let partEnd = isPartEnd();
  return function(dispatch) {
    const cleanCommon = {
      filename: '',
      patient_name: '',
      tagId: '',
      page: 0,
      registerFrom: '',
      registerTo: '',
      sortBy: 'register_date',
      sortAsc: false,
      clazz: getConfig(store.getState()).defaultCancerType,
      fuzzy: '',
      machines: undefined,
      junior_reporter:'',
      reporter:'',
      aiResult: '',
      gtResult: '',
    };
    const clean = partEnd === 'expert' ? cleanCommon : { ...cleanCommon, owner: '' };
    let ad = secondsTabs.length === 0 ? { image_level: '', junior_reporter: '', reporter: '' } : {};
    const omited = omit(clean, reserveKeys);
    const reserved = { ...getQueryInfo(store.getState()), ...ad, ...omited, ...initialKeys };
    dispatch(updateQueryInfo(reserved));
  };
}

function getCurrentQuery(store) {
  const currState = store.getState().loadImages;
  const tab = getCurrentTab(store.getState());
  const { queryInfo, images } = currState;
  return { ...queryInfo, limit: images.total, ...tab.query };
}

export const exportCsv = () => {
  return async (dispatch) => {
    const query = getCurrentQuery(store);
    const currState = store.getState().loadImages;
    const { selected } = currState;
    dispatch(toggleLoadingTable(true));
    fetchCsv(
      query,
      selected.data.map((item) => item.key)
    )
      .then((resp) => {
        if (resp.status === 200) {
          downloadCsv(resp.data, {}, 'export.csv', 'text/plain;charset=utf-8');
        }
      })
      .finally(() => {
        dispatch(toggleLoadingTable(false));
      });
  };
};

export const downloadList = () => {
  return async (dispatch) => {
    const query = getCurrentQuery(store);
    dispatch(toggleDownload(true));
    exportList(query)
      .then((resp) => {
        if (resp.status === 200) {
          if (resp.data.size === 5) {
            message.error(intl.get('DOWNLOAD_ERROR'));
          } else {
            downloadCsv(resp.data, {}, intl.get('ZIP_NAME'), 'application/zip');
          }
        }
      })
      .catch((error) => {
        if (error.response.data.size === 725) {
          message.error(intl.get('DOWNLOAD_ERROR_MORE'));
        }
      })
      .finally(() => {
        dispatch(toggleDownload(false));
      });
  };
};

export const deleteImages = () => {
  return async (dispatch) => {
    const query = getCurrentQuery(store);
    const currState = store.getState().loadImages;
    const currentTab = getCurrentTabIdx(store.getState());
    const { selected, images } = currState;
    fetchDeleteImages(
      query,
      selected.data.map((item) => item.key)
    )
      .then((resp) => {
        if (resp.status === 204) {
          const count = selected.total === 0 ? images.total : selected.total;
          message.success(intl.get('DELETE_IMAGES_SUCCESS', { count }));
        }
      })
      .finally(() => {
        dispatch(toggleTab(currentTab));
      });
  };
};
