import React, { Component } from 'react';
import { connect } from 'react-redux';
import style from './index.less';
import intl from 'react-intl-universal';
import ProPrint from './ProPrint';
import { updateSection, startPro, endPro } from '../../actions/productionAction';
import { message } from 'antd';
import {setActionDisabled} from '../../utils/urlUtils'
class ProAction extends Component {
  constructor() {
    super();
    this.state = {};
  }

  start(info) {
    if (info.producer === '' || info.producer === null) {
      message.warning(intl.get('PRO_NO_PRODUCER'));
      return false;
    } else if (info.producer !== this.props.currentUser.realname) {
      message.warning(intl.get('PRO_SELECTED_MORE'));
      return false;
    } else {
      this.props.startPro(info.id);
    }
  }
  end(info) {
    if (info.producer !== this.props.currentUser.realname) {
      message.warning(intl.get('PRO_SELECTED_MORE'));
      return false;
    } else {
      this.props.endPro(info.id);
    }
  }

  setAction() {
    const { infoList } = this.props;
    if (infoList.stage === 0) {
      return (
        <span
          className={style.pro__start}
          onClick={() => {
            this.start(infoList);
          }}
        >
          {intl.get('PRO_START')}
        </span>
      );
    } else if (infoList.stage === 1) {
      return (
        <span
          className={style.pro__finish}
          onClick={() => {
            this.end(infoList);
          }}
        >
          {intl.get('PRO_FINISH')}
        </span>
      );
    } else {
      return <span className={style.pro__end}>{intl.get('PRO_END')}</span>;
    }
  }

  render() {
    const { infoList } = this.props;
    const partStyle =setActionDisabled()
    return (
      <div className={style.pro__action} style={partStyle}>
        <ProPrint infoList={infoList} />
        &nbsp;&nbsp;
        <span className={style.pro__line}>|</span>
        &nbsp;&nbsp;
        {this.setAction()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser
  };
};

export default connect(mapStateToProps, { updateSection, startPro, endPro })(ProAction);
