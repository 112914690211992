import intl from 'react-intl-universal';
export function getEvaluateList() {
    return [
      {
        value:  intl.get('EVALUATE_EXCELLENT'),
        label: intl.get('EVALUATE_EXCELLENT')
      },
      {
        value: intl.get('EVALUATE_GOOD'),
        label: intl.get('EVALUATE_GOOD'),
        children: [
          {
            value: intl.get('EVALUATE_BAD_REASON1'),
            label: intl.get('EVALUATE_BAD_REASON1')
          },
          {
            value: intl.get('EVALUATE_BAD_REASON2'),
            label: intl.get('EVALUATE_BAD_REASON2')
          },
          {
            value: intl.get('EVALUATE_BAD_REASON3'),
            label: intl.get('EVALUATE_BAD_REASON3')
          },
          {
            value:  intl.get('EVALUATE_BAD_REASON4'),
            label: intl.get('EVALUATE_BAD_REASON4')
          },
          {
            value: intl.get('EVALUATE_BAD_REASON5'),
            label: intl.get('EVALUATE_BAD_REASON5')
          },
          {
            value: intl.get('EVALUATE_BAD_REASON6'),
            label: intl.get('EVALUATE_BAD_REASON6')
          }
        ]
      },
      {
        value:intl.get('EVALUATE_BAD'),
        label: intl.get('EVALUATE_BAD'),
        children: [
          {
            value: intl.get('EVALUATE_BAD_REASON1'),
            label: intl.get('EVALUATE_BAD_REASON1')
          },
          {
            value: intl.get('EVALUATE_BAD_REASON3'),
            label: intl.get('EVALUATE_BAD_REASON3')
          },
          {
            value: intl.get('EVALUATE_BAD_REASON4'),
            label: intl.get('EVALUATE_BAD_REASON4')
          },
          {
            value: intl.get('EVALUATE_BAD_REASON5'),
            label: intl.get('EVALUATE_BAD_REASON5')
          }
        ]
      }
    ];
  }

  export const initalQuery=  {
    page: 0,
    registrate_from: '',
    registrate_to: '',
    sortBy: 'latest_time',
    sortAsc: false,
    fuzzy: '',
    producer: ''
  }