import intl from 'react-intl-universal';
export function getColumns(){
return [
    {
      title: intl.get("HOSPITAL_NAME"),
      dataIndex: 'label',
      key: 'label',
      align: 'center',
    },
    {
      title: intl.get("DOMAIN_NAME"),
      dataIndex: 'hostName',
      key: 'hostName',
      align: 'center',
    },
    // {
    //   title: intl.get("ACTION"),
    //   key: 'action',
    //   align: 'center',
    //   render: (text, record) => (
    //    <SourceAction record={record}/>
    //   ),
    //   with:'100px'
    // },
  ];
}
