import { uniq, isEqual } from 'lodash';
const initial = { annotationShowUsers: [], loginUser: null, infos: [] };

const toogledAnnotationUsersVisibility = (olds, user) => {
  const idx = olds.indexOf(user);
  const removed = olds.slice(0, idx).concat(olds.slice(idx + 1));
  const added = olds.concat(user);
  return idx > -1 ? removed : added;
};

const concatUserInfos = (infos, info) => {
  const isNew =
    infos &&
    !infos.find((old) => {
      return isEqual(old, info);
    });
  return isNew ? infos.concat(info) : infos;
};

const annotationUsers = (state = initial, action) => {
  switch (action.type) {
    case 'LOAD_ANNOTATIONS_SUCCESS':
      return {
        ...state,
        annotationShowUsers: action.users
      };
    case 'TOGGLE_ANNOTATION_USER_VISIBILITY':
      return {
        ...state,
        annotationShowUsers: toogledAnnotationUsersVisibility(
          state.annotationShowUsers,
          action.user
        )
      };
    case 'ADD_ANNOTATION_CHANGE_REQUEST':
      return {
        ...state,
        annotationShowUsers: uniq(state.annotationShowUsers.concat(action.change.user))
      };
    case 'ADD_ANNOTATION_REQUEST':
      return {
        ...state,
        annotationShowUsers: uniq(
          state.annotationShowUsers.concat(action.annotation.changes.find((c) => true).user)
        )
      };
    case 'SET_LOGIN_USER':
      return {
        ...state,
        loginUser: action.user
      };
    case 'GET_USER_INFO_SUCCESS':
      return {
        ...state,
        infos: concatUserInfos(state.infos, action.info)
      };
    default:
      return state;
  }
};

export default annotationUsers;
