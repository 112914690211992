import { Button, DatePicker, Drawer, Form, Input } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import intl from 'react-intl-universal';

const { RangePicker } = DatePicker;

const AnalysisQueryForm = Form.create({
  name: 'analysis-filter',
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields);
  },
  mapPropsToFields(props) {
    return {
      label: Form.createFormField({ value: props.label }),
      uploader: Form.createFormField({ value: props.uploader }),
      uploadDates: Form.createFormField({
        value: props.uploadDates
      })
    };
  }
})((props) => {
  const { getFieldDecorator } = props.form;
  return (
    <Form layout="inline">
      <Form.Item label={intl.get('FILENAME')}>
        {getFieldDecorator('label', {})(<Input allowClear />)}
      </Form.Item>
      <Form.Item label={intl.get('UPLOADDATE')}>
        {getFieldDecorator(
          'uploadDates',
          {}
        )(<RangePicker disabledDate={(current) => current > moment()} allowClear />)}
      </Form.Item>
      <Form.Item label={intl.get('UPLOADER')}>
        {getFieldDecorator('uploader', {})(<Input allowClear />)}
      </Form.Item>
    </Form>
  );
});

class QuerySettingDrawer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    placement: PropTypes.string,
    queryInfo: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      query: { ...props.queryInfo }
    };
    this.form = undefined;
    this.fields = ['label', 'uploader', 'uploadDates'];
  }

  onFieldsChange = (changedFields) => {
    const { query } = this.state;
    for (let k in changedFields) {
      if (this.fields.indexOf(changedFields[k].name) !== -1) {
        if (changedFields[k].name !== 'uploadDates')
          query[changedFields[k].name] = changedFields[k].value;
        else {
          const value = changedFields[k].value;
          query['uploadDates'] = value;
          if (value[0] && value[1]) {
            query.dateFrom = value[0].format('YYYY-MM-DD');
            query.dateTo = value[1].format('YYYY-MM-DD');
          }
        }
      }
    }
    this.setState({ query });
  };

  render() {
    return (
      <Drawer
        visible={this.props.visible}
        onClose={this.props.onClose}
        placement={this.props.placement}
      >
        <AnalysisQueryForm {...this.state.query} onChange={this.onFieldsChange} />
        <Button
          type="primary"
          onClick={() => {
            this.props.onConfirm(this.state.query);
          }}
        >
          {intl.get('YES')}
        </Button>
      </Drawer>
    );
  }
}

export default QuerySettingDrawer;
