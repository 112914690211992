import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Card, Table, Pagination } from 'antd';
import intl from 'react-intl-universal';
import styles from '../commonPanel.less';
import { getColumns } from './sourceInfo';
import style from '../../../less/table.less';
function SourceList(props) {
  const [page, setPage] = useState(0);
  const columns = getColumns();
  const limit = 10;
  return (
      <Card title={intl.get('DATASOURCE')}>
        <div className={styles.item__div}>
          <Table
            className={style.source_table}
            columns={columns}
            dataSource={props.units.slice(page * limit, (page + 1) * limit)}
            rowKey={(record) => record.value}
            pagination={false}
          />
          <Pagination
            total={props.units.length}
            current={page + 1}
            onChange={(page) => {
              setPage(page - 1);
            }}
            pageSize={limit}
            style={{ padding: 20, float: 'right' }}
          />
        </div>
      </Card>
  );
}

const mapStateToProps = (state, ownProps) => ({
  units: state.currentUser.units
});
export default connect(mapStateToProps, {  })(SourceList);
