import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from '../../less/modal.less';
import intl from 'react-intl-universal';
import { Button, Modal, Select, message } from 'antd';
import { cancelReport } from '../../actions/reportAction';
import ConfirmContent from '../CommonComponent/ConfirmContent';
import { withRouter } from 'react-router-dom';
import { isPartEnd } from '../../utils/urlUtils';
const { Option } = Select;
class CancelReport extends Component {
  constructor(props) {
    super();
    this.state = {
      isModalVisible: false,
      reason: ''
    };
  }
  showModal() {
    this.setState({ isModalVisible: true });
  }
  handleOk() {
    const { reason } = this.state;
    const { image } = this.props;

    let p = {};
    let c = {};
    if (reason === '') {
      message.warning(intl.get('CANCEL_REPORT_TITLE'));
      return false;
    } else {
      if (reason === 'rescan') {
        p = { rescan: true, reproduce: false };
        c = { interpretation: 'COMMENTS15' };
      } else if (reason === 'reproduce') {
        p = { rescan: true, reproduce: true };
        c = { interpretation: 'COMMENTS16' };
      }
    }
    this.props.cancelReport(image.id, p, c).then(() => {
      const partEnd = isPartEnd();
      partEnd === 'expert' &&
        setTimeout(() => {
          this.props.history.push('/mainpage');
        }, 2000);
    });

    this.setState({ isModalVisible: false });
  }
  handleCancel() {
    this.setState({ isModalVisible: false });
  }
  selectReason(value) {
    this.setState({ reason: value });
  }
  render() {
    const { isModalVisible } = this.state;
    const Reasonlist = [
      {
        value: 'rescan',
        label: 'CANCEL_REPORT_REASON1'
      },
      {
        value: 'reproduce',
        label: 'CANCEL_REPORT_REASON2'
      }
    ];
    return (
      <>
        <Button
          type="danger"
          onClick={() => {
            this.showModal();
          }}
          style={{ marginLeft: '35px' }}
        >
          {intl.get('CANCEL_REPORT')}
        </Button>
        <Modal
          title={intl.get('CANCEL_REPORT_TITLE')}
          visible={isModalVisible}
          onOk={this.handleOk.bind(this)}
          onCancel={this.handleCancel.bind(this)}
          className={styles.checkModal}
        >
          <ConfirmContent>
            <Select
              allowClear
              style={{ width: 300 }}
              showSearch={true}
              onChange={this.selectReason.bind(this)}
            >
              {Reasonlist.map((item, index) => {
                return (
                  <Option key={index} value={item.value}>
                    {intl.get(item.label)}
                  </Option>
                );
              })}
            </Select>
          </ConfirmContent>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});
export default connect(mapStateToProps, { cancelReport })(withRouter(CancelReport));
