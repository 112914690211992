import React, { Component } from 'react';
import { connect } from 'react-redux';
import style from './index.less';
import intl from 'react-intl-universal';
class ProStatus extends Component {
  constructor() {
    super();
    this.state = {};
  }

  setAction() {
    const { infoList } = this.props;
    if (infoList.stage=== 0) {
      return <span className={style.pro__wait}>{intl.get('PRO_WAIT')}</span>;
    } else if (infoList.stage=== 1) {
      return <span className={style.pro__ing}>{intl.get('PRO_ING')}</span>;
    } else {
      return <span className={style.pro__finish}>{intl.get('PRO_FINISH')}</span>;
    }
  }

  render() {
    return <div className={style.pro_status}>{this.setAction()}</div>;
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(ProStatus);
