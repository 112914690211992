import { createSelector } from 'reselect';
import { getCurrentImageInfo, getImageList } from '.';
import { isSlideIDValid } from '../static/detectionStatusMapping';
import { getCurrentTab } from './tabSelectors';
import { getCurrentUser } from './userSelectors';

export const getImagePlace = createSelector([getImageList, getCurrentImageInfo], (list, current) =>{
  return list.indexOf(list.find((i) => i.key === (current && current.key)))
}
);

export const getLastImageInfo = createSelector(
  [getImageList, getImagePlace, getCurrentImageInfo],
  (list, currentIdx) => (list.length > 0 && currentIdx > 0 ? list[currentIdx - 1] : null)
);

export const getNextImageInfo = createSelector(
  [getImageList, getImagePlace, getCurrentImageInfo],
  (list, currentIdx) =>
    list.length > 0 && currentIdx + 1 >= 0 && currentIdx + 1 < list.length
      ? list[currentIdx + 1]
      : null
);

export const isCurrentImageCollected = createSelector(
  [getCurrentImageInfo, getCurrentUser],
  (image, user) =>
    image && !!image.collectors && image.collectors.includes(user.id)
);

export const getRemark = createSelector(
  [getCurrentImageInfo],
  (image) => image && image.remark
)

export const getFilename = createSelector(
  [getCurrentImageInfo],
  (info) => info && info.filename
);

export const getPatientID = createSelector(
  [getCurrentImageInfo],
  (info) => info && info.tagId
)

export const getSlideID = createSelector(
  [getCurrentImageInfo],
  (info) => info && info.slide_id
)

export const getPatientName = createSelector(
  [getCurrentImageInfo],
  (info) => info && info.patient_name
)

export const getImageClass = createSelector(
  [getCurrentImageInfo],
  (info) => info && info.clazz
)

export const getNucleiNumber = createSelector(
  [getCurrentImageInfo],
  (info) => info && info.detectedNucleiNumber
)

export const getUploader = createSelector(
  [getCurrentImageInfo],
  (info) => info && info.uploader
)

export const getImageLevelResult = createSelector(
  [getCurrentImageInfo],
  (info) => info && info.imageLevelResult
)

export const getPatientAge = createSelector(
  [getCurrentImageInfo],
  (info) => info && info.age
)

export const getIsChengfen = createSelector(
  [getCurrentImageInfo],
  (info) => info && false
)

export const getIsYanXingXiBao = createSelector(
  [getCurrentImageInfo],
  (info) => info && false
)

export const getIsXueYeXiBao = createSelector(
  [getCurrentImageInfo],
  (info) => info && false
)

export const getIsYiWu = createSelector(
  [getCurrentImageInfo],
  (info) => info && false
)

export const getIsSeCha = createSelector(
  [getCurrentImageInfo],
  (info) => info && false
)

export const getIsMohu = createSelector(
  [getCurrentImageInfo],
  (info) => info && false
)

export const getIsIDCorrect = createSelector(
  [getCurrentImageInfo],
  (info) => info && isSlideIDValid(info.status)
)

export const getQueryInfo = (state) => state.loadImages.queryInfo;

export const getLoadImageMode = (state) => state.loadImages.mode;

export const getSearchQuery = (state) => state.loadImages.searchQuery;

export const getQueryWithCurrentTab = createSelector(
  [getQueryInfo, getCurrentTab],
  (query, tab) => { return tab ? { ...query, ...tab.query } : { ...query } }
)

export const getQueryByMode = createSelector(
  [getLoadImageMode, getQueryWithCurrentTab, getSearchQuery],
  (mode, load, search) => mode === 'search' ? search : load
)

export const getSelectedImages = (state) => state.loadImages.selected;

export const getImages = (state) => state.loadImages.images;
export const getFromImageId = (state) => state.loadImages.images.fromImageId;
export const getSearchedFromImageId = (state) => state.loadImages.searched.fromImageId;
export const getSearchedFromImageInfo =  createSelector(
  [getSearchedFromImageId,getFromImageId, getImages, getSelectedImages],
  (searchedFromImageId,fromImageId, images,selected) => { 
    let imageId = searchedFromImageId?searchedFromImageId:fromImageId
    if(selected.data.length>0){
      let image = selected.data.find(item=>item.id===imageId)
      return image
    }else{
      let image = images.data.find(item=>item.id===imageId)
      return  image
    }
  }
)
export const getTableLoading = (state) => state.loadImages.forceLoading;

export const getReadedList = (state) => state.loadImages.readedList;

export const getNotNoteTimeoutList = (state) => state.timeout.notNoteTimeoutList;
export const getTimeoutList = (state) => state.timeout.timeoutList;