import React, { Component } from 'react';
import { Checkbox } from 'antd';
import intl from 'react-intl-universal';
import styles from './SampleCells.less';
import Screenshots from './Screenshots';
// import { getSelectInterpretation} from './SampleReport'
import { getFindings } from '../../utils/reportUtils';
// text属性对应与intl中的标识
const EPITHELIAL_CELLS = {
  "NILM": { span: 8, text: "NORMAL" },
}

const SQUAMOUS_CELLS = {
  "ASC": { text: "ASC" },
  "ASC-US": { text: "ASC_US" },
  "ASC-H": { text: "ASC_H" },
  "LSIL": { text: "LSIL" },
  "HSIL": { text: "HSIL" },
  "SCC": { text: "SCC" }
}

// const INFLAMMATORY_CELL={
//   "INFLAMMATORY-L": { text: "MILD" },
//   "INFLAMMATORY-M": { text: "MODERATE" },
//   "INFLAMMATORY-H": { text: "SEVERE" },
//   "INFLAMMATORY": { text: "INFLAMMATORY" }     
// }

const GLANDULAR_CELLS = {
  'AGC': { text: "AGC" },
  'AGC-EC': { text: "AGC_EC" },
  'AGC-EM': { text: "AGC_EM" },
  'AGC-NONE': { text: "AGC_NONE" },

  'AGC-N': { text: "AGC_N" },
  'AGC-N-EC': { text: "AGC_N_EC" },
  'AGC-N-NONE': { text: "AGC_N_NONE" },

  'AIS': { text: "AIS" },

  'ADC': { text: "ADC" },
  'ADC-EC': { text: "ADC_EC" },
  'ADC-EM': { text: "ADC_EM" },
  'ADC-EU': { text: "ADC_EU" },
  'ADC-NONE': { text: "ADC_NONE" }
}

const BIOLOGICAL_PATHOGENS = {
  "NORMAL": { text: "NOT_SEEN" },
  "TV": {  text: "TV" },
  "FUNGI": { text: "FUNGI" },
  "ACTINO": { text: "ACTINO" },
  "HSV": {  text: "HSV" },
  "CC": { text: "CC" },
  "CYTOMEGALOVIRUS": { text: "CYTOMEGALOVIRUS" },
}

const OTHER = {
  "NILM-IN-NILM":{text:"NORMAL"},
  "INFLAMMATION": {  text: "INFLAMMATION" },
  "ATROPHY": {  text: "ATROPHY" },
  "IUD": {  text: "IUD" },
  "RADIATION": { text: "RADIOTHERAPY_RESPONSE" },
  "GC-HYSTERECTOMY": {  text: "GC_HYSTERECTOMY" },
  "EMC": { text: "EMC" },
}

const arrOther = ["NILM-IN-NILM", "INFLAMMATION","ATROPHY","IUD","RADIATION","GC-HYSTERECTOMY","EMC"]
// arrOther1 arrOther2 互斥关系;其他同理
const arrOther1 = ["NILM-IN-NILM"]
const arrOther2 = ["INFLAMMATION","ATROPHY","IUD","RADIATION","GC-HYSTERECTOMY","EMC"]

const arrINFLAMMATORY  = ["INFLAMMATORY-L", "INFLAMMATORY-M","INFLAMMATORY-H"]

const arrASC = ["ASC-US", "ASC-H"]
const arrAGC = ["AGC-EC", "AGC-EM", "AGC-NONE"]
const arrAGC_N = ["AGC-N-EC", "AGC-N-NONE"]
const arrADC = ["ADC-EC", "ADC-EM", "ADC-EU", "ADC-NONE"]

const arrSpecial1 = ['ASC', 'LSIL', 'HSIL', 'SCC']
const arrSpecial2 = ['AGC', 'AGC-N', 'AIS', 'ADC'] // 互斥
const arrBiology = ["NORMAL", "TV", "FUNGI", "ACTINO", "HSV", "CC", "CYTOMEGALOVIRUS"]
const arrShangpi = ['NILM', ...arrSpecial1, ...arrSpecial2, ...arrASC, ...arrAGC, ...arrAGC_N, ...arrADC]
export { EPITHELIAL_CELLS, SQUAMOUS_CELLS, GLANDULAR_CELLS, BIOLOGICAL_PATHOGENS, OTHER }

function doCancelDetailedName(params, arr) {
  arr.forEach(item => {
    let idx = params.indexOf(item)
    if (idx > -1) params.splice(idx, 1)
  })
}

function doCancelParentCancelDetailed(params, parentName) {
  switch (parentName) {
    case 'INFLAMMATORY':
      doCancelDetailedName(params, arrINFLAMMATORY)
      break;
    case 'NILM':
      doCancelDetailedName(params, arrOther)
      break;
    case 'ASC':
      doCancelDetailedName(params, arrASC)
      break;
    case 'AGC':
      doCancelDetailedName(params, arrAGC)
      break;
    case 'AGC-N':
      doCancelDetailedName(params, arrAGC_N)
      break;
    case 'ADC':
      doCancelDetailedName(params, arrADC)
      break;
    case 'BIOLOGICAL_PATHOGEN':
      doCancelDetailedName(params, arrBiology)
      break;
    default:
      break;
  }
}

function doCheckedOneCancelOtherExclusion(params, parentName, name) {
  switch (parentName) {
    case 'INFLAMMATORY':
      doMutualExclusion(params, name, arrINFLAMMATORY)
      break;
    case 'NILM':
      doMutualExclusion(params, name, arrOther1, arrOther2)
      break;
    case 'ASC':
      doMutualExclusion(params, name, arrASC)
      break;
    case 'AGC':
      doMutualExclusion(params, name, arrAGC)
      break;
    case 'AGC-N':
      doMutualExclusion(params, name, arrAGC_N)
      break;
    case 'ADC':
      doMutualExclusion(params, name, arrADC)
      break;
    case 'BIOLOGICAL_PATHOGEN':
      break;
    default:
      break;
  }
}
function doExclusion(params, name) {
  // 鳞状细胞和腺细胞各子项互斥
  if (arrSpecial1.includes(name)) {
    Object.keys(SQUAMOUS_CELLS).forEach(item => {
      let idx = params.indexOf(item)
      if (idx > -1) params.splice(idx, 1)
    })
  } else if (arrSpecial2.includes(name)) {
    Object.keys(GLANDULAR_CELLS).forEach(item => {
      let idx = params.indexOf(item)
      if (idx > -1) params.splice(idx, 1)
    })
  }
}

function doBiologyExclusion(params, name) {
  // 微生物未见与其他异常互斥
  if (arrBiology.includes(name)) {
    if (name === "NORMAL") {
      arrBiology.forEach((item, index) => {
        if (index !== 0 && params.includes(item)) {
          params.splice(params.indexOf(item), 1)
        }
      })
    } else {
      if (params.includes('NORMAL')) params.splice(params.indexOf('NORMAL'), 1)
    }
  }
}

function doShangpiExclusion(params, name) {
  // 未见上皮内病变与其他异常互斥
  if (arrShangpi.includes(name)) {
    if (name === "NILM") {
      arrShangpi.forEach((item, index) => {
        if (index !== 0 && params.includes(item)) {
          params.splice(params.indexOf(item), 1)
        }
      })
    } else {
      if (params.includes('NILM')) {
        params.splice(params.indexOf('NILM'), 1)
        Object.keys(OTHER).forEach(item => {
          let idx = params.indexOf(item)
          if (idx > -1) params.splice(idx, 1)
        })
      }
    }
  }
}

// eslint-disable-next-line max-params
function doMutualExclusion(params, name, arr1, arr2) {
  if(!arr2){
    if (arr1.includes(name)) {
      arr1.forEach(item => {
        if (item!==name) {
          if(params.indexOf(item)>-1){
            params.splice(params.indexOf(item), 1)
          }
        }
      })
    }
  }else{
    if (arr1.includes(name)) {
      arr2.forEach(item => {
        if (params.indexOf(item) > -1) {
          params.splice(params.indexOf(item), 1)
        }
      })
    } else {
      arr1.forEach(item => {
        if (params.indexOf(item) > -1) {
          params.splice(params.indexOf(item), 1)
        }
      })
    }
  }
  
}

function getDisabledStatus(labels) {
  let is_ASC_Disabled = true
  let is_AGC_Disabled = true
  let is_AGC_N_Disabled = true
  let is_ADC_Disabled = true
  let is_other_Disabled = true
  let is_INFLAMMATORY_Disabled = true
  let is_SHENGWU_Disabled = true
  if (labels.indexOf('INFLAMMATORY') > -1) {
    is_INFLAMMATORY_Disabled = false
  }
  if (labels.indexOf('NILM') > -1) {
    is_other_Disabled = false
  }
  if (labels.indexOf('ASC') > -1) {
    is_ASC_Disabled = false
  }
  if (labels.indexOf('AGC') > -1) {
    is_AGC_Disabled = false
  }
  if (labels.indexOf('AGC-N') > -1) {
    is_AGC_N_Disabled = false
  }
  if (labels.indexOf('ADC') > -1) {
    is_ADC_Disabled = false
  }
  if (labels.indexOf('BIOLOGICAL_PATHOGEN') > -1) {
    is_SHENGWU_Disabled = false
  }
  

  return {
    is_ASC_Disabled,
    is_AGC_Disabled,
    is_AGC_N_Disabled,
    is_ADC_Disabled,
    is_other_Disabled,
    is_INFLAMMATORY_Disabled,
    is_SHENGWU_Disabled
  }
}

class SampleCells extends Component {
  constructor(props) {
    super(props);
    this.state = { labels: this.props.labels };
  }
  componentDidMount() {
    this.setState({
      labels: this.formatLabels(this.props.labels.slice())
    })
  }
  componentDidUpdate(prevProps) {
    if (prevProps.labels !== this.props.labels) {
      this.setState({
        labels: this.formatLabels(this.props.labels.slice())
      })
    }
  }

    onChange = (checked, name, parentName = "") => {
    let params = this.state.labels.slice()
    if (checked) {
      doShangpiExclusion(params, name)
      doExclusion(params, name)
    }
    if (!parentName || parentName.length === 0) {
      let idx = params.findIndex((item) => item === name)
      if (idx === -1) {
        doBiologyExclusion(params, name)
        params.push(name)
      } else {
        params.splice(idx, 1)
      }
    } else {
      // 有子类的组合交互逻辑
      if (checked) {
        params.push(name)
        if (parentName !== name) doCheckedOneCancelOtherExclusion(params, parentName, name) // 勾选子类，去勾选互斥的其他子类
      } else {
        let idx = params.findIndex((item) => item === name)
        params.splice(idx, 1)

        if (parentName === name) doCancelParentCancelDetailed(params, parentName)  // 去勾选父分类,去勾选父类对应的子类
      }
    }
    let newLabels = Array.from(new Set(params))
    let reportResult =getFindings(newLabels);
    this.props.onChange({
      "diagnosis": { labels:  newLabels},
      // "interpretation":getSelectInterpretation(newLabels,this.props.sample),
      'findings':reportResult
    })
  }

  formatLabels = (labels) => {
    if (arrINFLAMMATORY.some(item => labels.indexOf(item) > -1)) {
      labels.push('INFLAMMATORY')
    }
    if (arrOther.some(item => labels.indexOf(item) > -1)) {
      labels.push('NILM')
    }
    if (arrASC.some(item => labels.indexOf(item) > -1)) {
      labels.push('ASC')
    }
    if (arrAGC.some(item => labels.indexOf(item) > -1)) {
      labels.push('AGC')
    }
    if (arrAGC_N.some(item => labels.indexOf(item) > -1)) {
      labels.push('AGC-N')
    }
    if (arrADC.some(item => labels.indexOf(item) > -1)) {
      labels.push('ADC')
    }
    if (arrBiology.some(item => labels.indexOf(item) > -1)) {
      labels.push('BIOLOGICAL_PATHOGEN')
    }
    
    return Array.from(new Set(labels)) // 有可能重复出现ASC
  }

  getStyles(checkedText){
    const { labels } = this.state
    return labels.includes(checkedText)?styles.active__checkbox:styles.normal__checkbox
  }


  render() {
    const { isSettingsPage, reportConf, printing } = this.props
    const { mode } = reportConf;
    const { labels } = this.state
    const { is_ASC_Disabled,is_SHENGWU_Disabled,is_other_Disabled, is_AGC_Disabled, is_AGC_N_Disabled, is_ADC_Disabled } = getDisabledStatus(labels)
    return (
      <div className={styles.printing}>
        <div className={styles.sample__cells}>
          {(mode === 'simple_mode' && printing) || (mode === 'simple_mode' && isSettingsPage) ? (
            <div className={styles.cells__info__simple}>{null}</div>
          ) : (
            <div className={styles.cells__info}>
              <div className={styles.cells__info__top}>
               <div>
                  {/* <div>
                    <Checkbox key={INFLAMMATORY_CELL['INFLAMMATORY'].text} className={this.getStyles('INFLAMMATORY')} checked={labels.includes('INFLAMMATORY')} value="INFLAMMATORY" onChange={(evt) => this.onChange(evt.target.checked,'INFLAMMATORY', 'INFLAMMATORY')}>
                      {intl.get("INFLAMMATORY_CELL")}
                    </Checkbox>
                    {arrINFLAMMATORY.map(item => {
                      return (
                        <Checkbox key={item} disabled={is_INFLAMMATORY_Disabled} className={this.getStyles(item)} checked={labels.includes(item)} value={item} onChange={(evt) => this.onChange(evt.target.checked, item, 'INFLAMMATORY')}>
                          {intl.get(INFLAMMATORY_CELL[item].text)}
                        </Checkbox>
                      )                    
                    })}
                  </div> */}
                  <div className={styles.wrap__child7}>
                    <div className={styles.tree__left}>
                      <Checkbox key={EPITHELIAL_CELLS['NILM'].text} checked={labels.includes('NILM')} value="NILM" onChange={(evt) => this.onChange(evt.target.checked,'NILM', 'NILM')}/>
                      <span className={styles.text__span+" "+this.getStyles('NILM')}>{intl.get('NILM')}</span>
                    </div>
                    <div className={styles.tree__right}>
                      {Object.keys(OTHER).map(key => {
                        return <div key={key} className={styles.child__item}>
                          <Checkbox className={this.getStyles(key)} disabled={is_other_Disabled} checked={labels.includes(key)} value={key} onChange={(evt) => this.onChange(evt.target.checked, key,'NILM')}>
                            {intl.get(OTHER[key].text)}
                          </Checkbox>
                        </div>
                      })}
                    </div>
                  </div>
                  <div className={styles.wrap__child2}>
                    <div className={styles.tree__left}>
                      <Checkbox  checked={labels.includes("ASC")} value="ASC" onChange={(evt) => this.onChange(evt.target.checked, "ASC", "ASC")}/>
                      <span className={styles.text__span+" "+this.getStyles('ASC')}>{intl.get(SQUAMOUS_CELLS['ASC'].text)}</span>
                    </div>
                    <div className={styles.tree__right}>
                      {arrASC.map(item => {
                        return (
                          <div key={item} className={styles.child__item}>
                            <Checkbox className={this.getStyles(item)} disabled={is_ASC_Disabled} checked={labels.includes(item)} value={item} onChange={(evt) => this.onChange(evt.target.checked, item, 'ASC')}>
                              {intl.get(SQUAMOUS_CELLS[item].text)}
                            </Checkbox>
                          </div>
                        )                    
                      })}
                    </div>
                  </div>
                  <div>
                    <Checkbox checked={labels.includes("LSIL")} value="LSIL" onChange={(evt) => this.onChange(evt.target.checked, "LSIL")}/>
                    <span  className={styles.text__span+" "+this.getStyles('LSIL')}>{intl.get(SQUAMOUS_CELLS["LSIL"].text)}</span>
                  </div>
                  <div>
                    <Checkbox checked={labels.includes("HSIL")} value="HSIL" onChange={(evt) => this.onChange(evt.target.checked, "HSIL")}/>
                    <span className={styles.text__span+" "+this.getStyles('HSIL')}>{intl.get(SQUAMOUS_CELLS["HSIL"].text)}</span>
                  </div>
                  <div>
                    <Checkbox checked={labels.includes("SCC")} value="SCC" onChange={(evt) => this.onChange(evt.target.checked, "SCC")}/>
                    <span className={styles.text__span +" "+this.getStyles('SCC')}>{intl.get(SQUAMOUS_CELLS["SCC"].text)}</span>
                  </div>
                  <div className={styles.wrap__child3}>
                    <div className={styles.tree__left}>
                      <Checkbox checked={labels.includes("AGC")} value="AGC" onChange={(evt) => this.onChange(evt.target.checked, "AGC", "AGC")}/>
                      <span className={styles.text__span+" "+this.getStyles('AGC')}>{intl.get(GLANDULAR_CELLS['AGC'].text)}</span>
                    </div>
                    <div className={styles.tree__right}>
                      {
                        arrAGC.map(item => {
                          return (
                            <div key={item} className={styles.child__item}>
                              <Checkbox className={this.getStyles(item)} disabled={is_AGC_Disabled} checked={labels.includes(item)} value={item} onChange={(evt) => this.onChange(evt.target.checked, item, 'AGC')}>
                                {intl.get(GLANDULAR_CELLS[item].text)}
                              </Checkbox>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
                <div className={styles.img__div}>{!isSettingsPage && <Screenshots printing={this.props.printing} />}</div>
              </div>
             
              <div style={{display:"flex",justifyContent:"space-between"}}>
                <div>
                  <div className={styles.wrap__child2}>
                    <div className={styles.tree__left}>
                      <Checkbox  checked={labels.includes("AGC-N")} value="AGC-N" onChange={(evt) => this.onChange(evt.target.checked, "AGC-N", "AGC-N")}/>
                      <span className={styles.text__span+" "+this.getStyles('AGC-N')}>{intl.get(GLANDULAR_CELLS['AGC-N'].text)}</span>
                    </div>
                    <div className={styles.tree__right}>
                      {arrAGC_N.map(item => {
                        return (
                          <div  key={item}  className={styles.child__item}>
                            <Checkbox className={this.getStyles(item)} disabled={is_AGC_N_Disabled} checked={labels.includes(item)} value={item} onChange={(evt) => this.onChange(evt.target.checked, item, 'AGC-N')}>{intl.get(GLANDULAR_CELLS[item].text)}</Checkbox>
                          </div>
                        )
                      })
                      }
                    </div>
                  </div>
                  <div>
                    <Checkbox checked={labels.includes("AIS")} onChange={(evt) => this.onChange(evt.target.checked, "AIS")} value="AIS"/>
                    <span className={styles.text__span+" "+this.getStyles('AIS')}>{intl.get(GLANDULAR_CELLS['AIS'].text)}</span>
                  </div>
                  <div className={styles.wrap__child4}>
                    <div className={styles.tree__left}>
                      <Checkbox checked={labels.includes("ADC")} onChange={(evt) => this.onChange(evt.target.checked, "ADC", "ADC")}/>
                      <span className={styles.text__span+" "+this.getStyles('ADC')}>{intl.get(GLANDULAR_CELLS['ADC'].text)}</span>
                    </div>
                    <div className={styles.tree__right}>
                      {
                        arrADC.map(item => {
                          return (
                            <div  key={item}  className={styles.child__item}>
                              <Checkbox className={this.getStyles(item)} disabled={is_ADC_Disabled} key={item} checked={labels.includes(item)} onChange={(evt) => this.onChange(evt.target.checked, item, 'ADC')}>{intl.get(GLANDULAR_CELLS[item].text)}</Checkbox>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
             
                <div>
                  <div className={styles.wrap__child7}>
                    <div className={styles.tree__left} style={{width:'132px'}}>
                      <Checkbox checked={labels.includes("BIOLOGICAL_PATHOGEN")} onChange={(evt) => this.onChange(evt.target.checked, "BIOLOGICAL_PATHOGEN", "BIOLOGICAL_PATHOGEN")}/>
                      <span className={styles.text__span+" "+this.getStyles('BIOLOGICAL_PATHOGEN')}>{intl.get("BIOLOGICAL_PATHOGENS")}</span>
                    </div>
                    <div className={styles.tree__right}>
                      {Object.keys(BIOLOGICAL_PATHOGENS).map(key => {
                        return  <div key={key} className={styles.child__item}>
                          <Checkbox className={this.getStyles(key)} disabled={is_SHENGWU_Disabled} checked={labels.includes(key)} onChange={(evt) => this.onChange(evt.target.checked, key)}>
                            {intl.get(BIOLOGICAL_PATHOGENS[key].text)}
                          </Checkbox>
                        </div>
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SampleCells;  