import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './SearchTable.less';
import { Table } from 'antd';
import { getImageList,getImageTotalCount } from '../../selectors';
import { getQueryByMode } from '../../selectors/imageSelector';
import intl from 'react-intl-universal'
import DetectionStatus from '../DetectionStatus';
import ImageLevelResultTag from '../ImageLevelResultTag';
import StageTag from '../StageTag';
import cfg from '../../config';
import { updateSearchBoxImageId} from '../../actions/searchBoxAction';
import {  updateCurrentImage,setCachePage } from '../../actions/imageActions';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    let currentPage = pagination.current-1;
    this.props.updateTableInfo(this.props.searchText,currentPage)
    this.props.setCachePage('search',[currentPage]);
  };

  handleClick = (e) => {
    e.nativeEvent.stopImmediatePropagation();
  };

  render() {
    const columns = [
      {
        title: intl.get("PATIENT_TAG_ID"),
        dataIndex: 'tagId',
        key: 'tagId',
      },
      {
        title: intl.get("PATIENT_NAME"),
        dataIndex: 'patient_name',
        key: 'patient_name',
      },
      {
        title: intl.get('IMAGELEVELRESULT'),
        dataIndex: 'imageLevelResult',
        key: 'image_level_result_AI',
        align: 'center',
        render: (results, record) => {
          return <DetectionStatus results={results} image={record} />;
        }
      },
      {
        title: intl.get('GROUND_TRUTH_RESULT'),
        dataIndex: 'groundTruthResult',
        key: 'ground_truth_result',
        align: 'center',
        render: (results, img) => {
          if (img.clazz === 'cervical') return <ImageLevelResultTag result={results} shouldBefiltered={true} />;
          else return <span>{results}</span>;
        }
      },
      {
        title: intl.get('STAGE'),
        dataIndex: 'stage',
        key: 'stage',
        align: 'left',
        render: (results, img) => {
          return <StageTag stageInfo={cfg.reportStatusInfo} stage={results} reporter={img.reporter} />
        }
      },
    ];
    return (
      <div className={styles.search__list} onClick={this.handleClick}>
        <p className={styles.search__title}>图像列表</p>
        <Table size="small" 
          dataSource={this.props.images} 
          columns={columns} 
          rowKey={(record) => record.id}
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (_) => {
                let image = record
                this.props.updateCurrentImage(image)
                this.props.updateSearchBoxImageId(image.id)
                this.props.hideTable()
              },
            };
          }}
          onChange={this.handleTableChange}
          pagination={{
            current: this.props.query.page+1,
            pageSize: this.props.query.limit,
            total: this.props.total,
            showTotal: (total, range) => {
              return <span>{intl.get('IMAGE_LIST_END_MESSAGE', { count: total })}</span>;
            }
          }}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  images:getImageList(state),
  total:getImageTotalCount(state),
  query:getQueryByMode(state)
});

export default connect(mapStateToProps, {updateCurrentImage,updateSearchBoxImageId,setCachePage})(Index);
