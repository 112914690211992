import React, { Component } from 'react';
import intl from 'react-intl-universal';
import style from './index.less';
import { connect } from 'react-redux';
import { Button, Row, Modal, Col,  message } from 'antd';
import { updateRegister } from '../../../actions/setting';
import { delBatchUser, getAllUserByQueryInfo } from '../../../actions/userActions';
import styles from '../../../less/modal.less';
import { setFailedReasonTitle,getRegisterPathName } from './RegisterInfo';
import ConfirmContent from "../../CommonComponent/ConfirmContent"
class RegisterTabs extends Component {
  constructor() {
    super();
    this.state = {
      isModalVisible: false,
      failedList: [],
      successList: [],
      failedVisible: false
    };
  }
  componentDidMount() {}
  showAdd() {
    this.props.updateRegister({ registerUpdateVisible: true, modalName: 'add', registerInfo: {} });
  }
  showDel() {
    const { selected } = this.props.register;
    let u = selected.filter((i) => i.essential === true);
    if (u.length > 0) {
      message.warning(intl.get('REGISTER_DEL_WARN'));
      return false;
    }
    this.setState({ isModalVisible: true });
  }
  handleOk() {
    const { selected } = this.props.register;
    let u = selected.map((i) => i.id);
    this.props.updateRegister({ isLoading: true });
    this.setState({ isModalVisible: false });
    delBatchUser({ users: u }).then((res) => {
      const { register } = this.props;
      if (res.failed.length > 0) {
        this.setState({ successList: res.success, failedList: res.failed, failedVisible: true });
      } else {
        this.setState({ failedVisible: false });
        message.success(intl.get('DEL_SUCCESS'));
        this.props.updateRegister({ selected: [], isLoading: false });
      }
      this.props.getAllUserByQueryInfo(register.queryInfo);
    });
  }
  handleCancel(name) {
    this.setState({ [name]: false });
    name === 'failedVisible' && this.props.updateRegister({ selected: [], isLoading: false });
  }
  render() {
    const { isModalVisible, failedVisible, failedList, successList } = this.state;
    const { register } = this.props;
    const dailTitle = setFailedReasonTitle();
    const pathName = getRegisterPathName()
    const DelTitle = pathName==='expert'?intl.get('REGISTER_DEL_EXPERT'):intl.get('REGISTER_DEL')
    return (
      <>
        <Row className={style.register_tab}>
          <Button
            type="primary"
            onClick={this.showDel.bind(this)}
            disabled={register.selected.length > 0 ? false : true}
          >
            {DelTitle}
          </Button>
          <Button type="primary" onClick={this.showAdd.bind(this)}>
            {pathName==='expert'?intl.get('REGISTER_ADD_EXPERT'):intl.get('REGISTER_ADD')}
          </Button>
        </Row>
        <Modal
          title={DelTitle}
          visible={isModalVisible}
          onOk={this.handleOk.bind(this)}
          onCancel={this.handleCancel.bind(this, 'isModalVisible')}
          className={styles.checkModal}
        >
          <ConfirmContent>
            <span>{intl.get('REGISTER_DEL_MESSAGE')}</span>
          </ConfirmContent>
        </Modal>
        <Modal
          title={intl.get('DEL_SUCCESS_FAILSED', {
            success: successList.length,
            failed: failedList.length
          })}
          visible={failedVisible}
          onCancel={this.handleCancel.bind(this, 'failedVisible')}
          className={styles.checkModal}
          footer={null}
        >
          <Row>
            {dailTitle.map((item, index) => {
              return (
                <Col key={index} span={item.span}>
                  {intl.get(item.name)}
                </Col>
              );
            })}
          </Row>
          {failedList.map((item, index) => {
            return (
              <Row key={index}>
                <Col span={6}>{item.username}</Col>
                <Col span={6}>{item.username}</Col>
                <Col span={12}>
                  {[6013, 6014].includes(item.error_code)
                    ? intl.get('ERROR_' + item.error_code)
                    : item.reason}
                </Col>
              </Row>
            );
          })}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  register: state.settings.register
});

export default connect(mapStateToProps, { updateRegister, getAllUserByQueryInfo })(RegisterTabs);
