import { Badge, Button } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import colorTable from '../../static/colorTable';
import { loadModeImages } from '../../actions/imageActions';
import { isPartEnd } from '../../utils/urlUtils';
class LoadImagesBtn extends Component {
  static propTypes = {
    selected: PropTypes.object,
    images: PropTypes.object,
    counts: PropTypes.array,
    currentTab: PropTypes.number
  };

  redirectToBrowser = () => {
    const { selected, images } = this.props;
    let path = loadModeImages(selected, images);
    this.props.history.push(path);
  };

  render() {
    const { counts, currentTab, selected } = this.props;
    const partEnd = isPartEnd();
    return (
      <Badge
        count={selected.total}
        showZero={false}
        style={{ background: colorTable.blue }}
        offset={[0, 7]}
      >
        <Button
          id="load-image-btn"
          type="primary"
          key="loadimage"
          onClick={this.redirectToBrowser}
          disabled={counts[currentTab] === 0}
          style={
            partEnd === 'centerEnd'
              ? {
                  marginRight: 0,
                  cursor: 'pointer',
                  pointerEvents: 'auto',
                  opacity: 1,
                }
              : { marginRight: 0 }
          }
        >
          {intl.get('LOADIMAGES', {
            selected: selected.total !== 0
          })}
        </Button>
      </Badge>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selected: state.loadImages.selected,
    images: state.loadImages.images,
    counts: state.tabState.counts,
    currentTab: state.tabState.currentTab
  };
};

export default connect(mapStateToProps, { loadModeImages })(withRouter(LoadImagesBtn));
