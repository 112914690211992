export function getHotkeyDefinitions(hotKeys, bindFunc, groupName) {
    if (groupName) {
        let hotkeyDefinitions = []
        hotKeys.forEach(hotKey => {
            if (groupName === hotKey.groupName) {
                hotkeyDefinitions.push({
                    ...hotKey,
                    bindFunc: bindFunc
                })
            }
        })
        return hotkeyDefinitions
    }

    return hotKeys.map(hotKey => { return { ...hotKey, bindFunc: bindFunc } })
}