import intl from 'react-intl-universal';
import {
  apiRegistration,
  apifetchPathologyId,
  apifetchCheckInfoByApplyId,
  apifetchDepartment,
  apifetchGetCheckInfoList,
  apiBackCheckInfo,
  apiUpdateRegistration,
  apifetchCheckInfoListCount,
  apiEditDepartment,
  apiBatchDelDepartment,
  apiBatchAddDepartment
} from './api';
import { initalCheckInfo } from '../components/CheckIn/checkInfo';
import { getCheckQueryInfo, getCheckTabName } from '../selectors/checkInSelectors';
import store from '../store';
import { put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import { checkInTabs } from '../static/defaultTopTabs';
import { setOrganization } from './setting';
import { getOrganization } from '../selectors/settingSelectors';
import { cloneDeep } from 'lodash';
import {setReduxAge} from "../utils/dataUtils"
export function setChckInInfo(data) {
  return { type: 'SET_CHECK_IN_INFO', data };
}
export function setHisInfo(data) {
  return { type: 'SET_HIS_INFO', data };
}

export function updateChckInInfoParams(data) {
  return { type: 'UPDATE_CHECK_IN_INFO_PARAMS', data };
}

export function updateChckInType(data) {
  return { type: 'UPDATE_CHECK_IN_TYPE', data };
}

export function updateChckInListInfo(data) {
  return { type: 'UPDATE_CHECK_IN_LIST_INFO', data };
}
export function updateChckInQueryInfo(data) {
  return { type: 'UPDATE_CHECK_IN_QUERY_INFO', data };
}

export function updateChckInTab(data) {
  return { type: 'UPDATE_CHECK_IN_TAB', data };
}

export function updateChckInCounts(data) {
  return { type: 'UPDATE_CHECK_IN_COUNTS', data };
}

export function* watchUpdateCheckQueryInfo() {
  yield takeLatest('UPDATE_CHECK_IN_QUERY_INFO', function*(action) {
    yield put(fetchCheckInfoList());
    yield put(updateCheckCounting());
  });
}

export function* watchUpdateCheckTab() {
  yield takeLatest('UPDATE_CHECK_IN_TAB', function*(action) {
    yield put(updateChckInQueryInfo({ page: 0 }));
  });
}

export function registration(params, form) {
  delete params.his;
  return async (dispatch) => {
    apiRegistration(params)
      .then((resp) => {
        message.success(intl.get('CHECK_SUCCESS'));
        console.log("---registration-",params,  resp)
        // dispatch(setHisInfo(initalCheckInfo));
        // dispatch(setChckInInfo(initalCheckInfo));
        dispatch(setHisInfo(resp.data));
        dispatch(setChckInInfo(resp.data));
        dispatch(fetchCheckInfoList());
        dispatch(updateCheckCounting());
      })
      .catch((err) => {
        if (err.response.data.error_code === 5501) {
          form.setFields({ apply_id: { errors: [new Error('')] } });
        }
        console.warn(err);
      });
  };
}

export function fetchPathologyId(data) {
  return async (dispatch) => {
    apifetchPathologyId()
      .then((resp) => {
        if (resp && resp.data) {
          let path = { ...data, pathology_id: resp.data.pathology_id };
          dispatch(updateChckInInfoParams(path));

          console.log("---fetchPathologyId-",data,  resp)
        }
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function getCheckInfoByApplyId(applyId, type) {
  return apifetchCheckInfoByApplyId(applyId)
    .then((resp) => {
      console.log("---getCheckInfoByApplyId-",applyId,  resp)
      return resp;
    })
    .catch((err) => {
      console.warn(err);
    });
}

export function fetchCheckInfoList(pathology_id="") {
  const queryInfo = getCheckQueryInfo(store.getState());
  const tabName = getCheckTabName(store.getState());
  const tabQuery = checkInTabs[parseInt(tabName, 10)].query;
  return async (dispatch) => {
    apifetchGetCheckInfoList({ ...queryInfo, ...tabQuery })
      .then((resp) => {
        if (resp && resp.data) {
          dispatch(updateChckInListInfo(resp.data));         
          let checkin_info = initalCheckInfo;
          const checkin_items = resp.data.items;
          for(let i =0; i < checkin_items.length; i++){
            if(checkin_items[i].pathology_id === pathology_id){
              checkin_info = checkin_items[i];
              break;
            }
          }
          dispatch(setHisInfo(checkin_info));
          dispatch(setChckInInfo(checkin_info));
        }
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function fetchCheckInfoByPathId(data) {
  return async (dispatch) => {
    apifetchGetCheckInfoList(data)
      .then((resp) => {
        if (resp && resp.data) {
          if (resp.data.items[0]) {
            let s = resp.data.items[0];
            s = setReduxAge(s);
            dispatch(setChckInInfo(s));
            dispatch(setHisInfo(resp.data.items[0].his));
          } else {
            dispatch(setHisInfo({}));
            dispatch(setChckInInfo(initalCheckInfo));
          }
        }
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function hasRegistation(id) {
  return apifetchCheckInfoListCount({ apply_id: id })
    .then((resp) => {
      if (resp) {
        return resp.data;
      }
    })
    .catch((err) => {
      console.warn(err);
    });
}

function _updateCheckCounting(dispatch) {
  const queryInfo = getCheckQueryInfo(store.getState());
  const promises = [];
  checkInTabs.forEach((item, i) => {
    promises.push(apifetchCheckInfoListCount({ ...queryInfo, ...item.query }));
  });

  Promise.all(promises)
    .then((results) => {
      const counts = results.map((resp) => resp.data);
      dispatch(updateChckInCounts(counts));
    })
    .catch((err) => {
      console.log(err);
    });
}

export function updateCheckCounting() {
  return async (dispatch) => {
    _updateCheckCounting(dispatch);
  };
}

export function backCheckInfo(registrationId) {
  return async (dispatch) => {
    apiBackCheckInfo(registrationId)
      .then(() => {
        dispatch(setChckInInfo(initalCheckInfo));
        dispatch(fetchCheckInfoList());
        dispatch(updateCheckCounting());
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function updateRegistration(registrationId, data, name) {
  let p = cloneDeep(data);
  delete p.pathology_id;
  delete p.id;
  delete p.his;
  return async (dispatch) => {
    apiUpdateRegistration(registrationId, p)
      .then((resp) => {
        message.success(intl.get('UPDATE_CHECK_SUCCESS'));
        dispatch(fetchCheckInfoList());
        dispatch(updateChckInInfoParams({ sample_recevier: name }));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

function formatUnits(data, childName, grandsonName) {
  let list = [];
  data.forEach((item) => {
    list.push({
      id: item.id,
      value: item.id,
      label: item.name,
      children:
        grandsonName !== ''
          ? formatUnits(item[childName], grandsonName, '')
          : childName !== ''
          ? formatUnits(item[childName], '', '')
          : null
    });
  });
  return list;
}

export function fetchDepartmentAll(type) {
  return async (dispatch) => {
    apifetchDepartment()
      .then((resp) => {
        if (resp && resp.data) {
          let units = formatUnits(resp.data.sample_units, 'sample_departments', 'apply_doctors');
          dispatch(setOrganization('organizationList', units));
          dispatch(
            setOrganization('database', {
              id: resp.data.id
            })
          );
          type === 'init' &&
            (units.length > 0 ? initOrganization(dispatch, units, 'init') : initReduxOrg(dispatch));
          ['add', 'edit', 'del'].includes(type) && freshOrganization(dispatch, units, type);
        }
      })
      .catch((err) => {
        initReduxOrg(dispatch);
        console.warn(err);
      });
  };
}

function initReduxOrg(dispatch) {
  dispatch(setOrganization('organizationList', []));
  dispatch(setOrganization('departmentList', []));
  dispatch(setOrganization('doctorList', []));
  dispatch(setOrganization('checkedOrganization', []));
  dispatch(setOrganization('checkedDepartment', []));
  dispatch(setOrganization('checkedDoctor', []));
  dispatch(setOrganization('checkedOrganizationList', []));
  dispatch(setOrganization('checkedDepartmentList', []));
  dispatch(setOrganization('checkedDoctorList', []));
}

function initOrganization(dispatch, units, type) {
  let initOrg = units[0];
  dispatch(setOrganization('checkedOrganization', [initOrg]));
  dispatch(setOrganization('checkedOrganizationList', [initOrg.value]));
  if (initOrg.children.length > 0) {
    let initDep = initOrg.children;
    setInitVal(dispatch, initDep, 'department', type);
    setInitVal(dispatch, initDep[0].children.length > 0 ? initDep[0].children : [], 'doctor', type);
  } else {
    setInitVal(dispatch, [], 'department', type);
    setInitVal(dispatch, [], 'doctor', type);
  }
}

function freshOrganization(dispatch, units, type) {
  let org = cloneDeep(getOrganization(store.getState()));
  if (org.checkedOrganizationList?.length === 1) {
    let initDep = units.filter((u) => u.id === org.checkedOrganizationList[0])[0].children;
    setInitVal(dispatch, initDep, 'department', type);
    if (org.checkedDepartmentList?.length === 1) {
      let initDoc = initDep.filter((u) => u.id === org.checkedDepartmentList[0])[0].children;
      setInitVal(dispatch, initDoc, 'doctor', type);
    }
  } else {
    setInitVal(dispatch, [], 'department', type);
    setInitVal(dispatch, [], 'doctor', type);
  }
}

function setInitVal(dispatch, val, name, type) {
  let org = cloneDeep(getOrganization(store.getState()));
  dispatch(setOrganization(name + 'List', val));
  let Aname = name.charAt(0).toUpperCase() + name.slice(1);
  if (type === 'init') {
    dispatch(setOrganization('checked' + Aname, val.length > 0 ? [val[0]] : []));
    dispatch(setOrganization('checked' + Aname + 'List', val.length > 0 ? [val[0].value] : []));
  } else {
    let checkedIds = org['checked' + Aname].map((i) => i.id);
    let checkedList = [];
    val.forEach((items) => {
      checkedIds.includes(items.id) && checkedList.push(items);
    });
    dispatch(setOrganization('checked' + Aname, checkedList));
  }
}

export function _editDepartment(dispatch, checkedOut, checkList, part, list) {
  const promises = [];
  checkList.forEach((item, index) => {
    if (checkedOut[index].label !== list[index]) {
      promises.push(apiEditDepartment(item, { name: list[index] }));
    }
  });
  Promise.all(promises)
    .then((resp) => {
      if (resp) {
        message.success(intl.get('MARK_MODIFY_SUCCESS'));
        dispatch(fetchDepartmentAll('edit'));
      }
    })
    .catch((err) => {
      console.warn(err);
    });
}

export function editDepartment(checkedOut, checkList, part, list) {
  return async (dispatch) => {
    _editDepartment(dispatch, checkedOut, checkList, part, list);
  };
}

export function batchDelDepartment(data, part, son) {
  return async (dispatch) => {
    apiBatchDelDepartment(data)
      .then((resp) => {
        if (resp) {
          message.success(intl.get('DEL_SUCCESS'));
          if (son !== '') {
            let s = son.toLowerCase();
            dispatch(setOrganization(s + 'List', []));
          }
          dispatch(setOrganization('checked' + part + 'List', []));
          dispatch(setOrganization('checked' + part, []));
          dispatch(fetchDepartmentAll('del'));
        }
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function batchAddDepartment(data, part) {
  return async (dispatch) => {
    apiBatchAddDepartment(data)
      .then((resp) => {
        if (resp && resp.data) {
          message.success(intl.get('ADD_SUCCESS'));
          dispatch(fetchDepartmentAll('add'));
        }
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}
