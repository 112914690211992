import { without } from 'lodash';
import config from '../config';
import store from '../store';

export const getShowUsers = (state, props) => state.annotationUsers.annotationShowUsers;

export const getLoginUser = (state) => state.annotationUsers.loginUser;

export const getClasses = (state, props) => props.classes;

export const getShowClass = (state, props) => state.imageSelection.showAnnotationClass;

export const getAnnotations = (state) => state.annotationLoad.annotationsLoaded;

export const getReportAnnotationIds = (state) => state.report.annotations;

export const getCurrentAnnotationId = (state) => state.annotationSelection.currentAnnotationId;

export const getHoveredAnnotationId = (state) => state.annotationSelection.hoveredAnnotationId;

export const getInvisibleClasses = () => ['null', null];

export const getClassLimit = (state) =>
  state.annotationLoad.limit ? state.annotationLoad.limit : 16;

export const getClassMarkLimit = (state) =>
  state.annotationLoad.markLimit ? state.annotationLoad.markLimit : 7;

export const getImageList = (state) => {
  switch (state.loadImages.mode) {
    case 'query':
      return state.loadImages.images.data;
    case 'select':
      return state.loadImages.selected.data;
    case 'search':
      return state.loadImages.searched.data;
    default:
      return state.loadImages.images.data;
  }
};

export const getCurrentImageInfo = (state) => state.imageSelection.currentImageInfo;

export const getImageTotalCount = (state) => {
  switch (state.loadImages.mode) {
    case 'query':
      return state.loadImages.images.total;
    case 'select':
      return state.loadImages.selected.total;
    case 'search':
      return state.loadImages.searched.total;
    default:
      return state.loadImages.images.total;
  }
};

export const isAnnotationNotSubsetOfClasses = (anno, classes) => {
  return without(anno.classes, ...classes).length > 0;
};

export const isAnnotationOfClasses = (anno, classes) => {
  return !!classes.find((c) => anno.classes.includes(c));
};

export const isAnnotationOfUsers = (anno, users) => {
  return !!users.find((u) => anno.users.includes(u));
};

export const visibleConfidence = () => {
  const state_config = store.getState().config;
  if (state_config.visibleConfidence) {
    return state_config.visibleConfidence;
  } else {
    return config.visibleConfidence;
  }
};

export const visibleNegativeLesion = () =>{
  const state_config = store.getState().config;
  if (state_config.visibleNegativeLesion) {
    return state_config.visibleNegativeLesion;
  } else {
    return config.visibleNegativeLesion;
  }  
};

export const visibleLabel = () => {
  const state_config = store.getState().config;
  if (state_config.visibleLabel) {
    return state_config.visibleLabel;
  } else {
    return config.visibleLabel;
  }
};

export const getCurrentClassification = () => {
  const state = store.getState();
  if (state && state.config && state.config.currentClassification) {
    return state.config.currentClassification;
  } else {
    return config.currentClassification;
  }
};

export const getSuspectedAnomalousDiameter = () => {
  const state_config = store.getState().config;
  if (state_config.suspectedAnomalousDiameter) {
    return state_config.suspectedAnomalousDiameter;
  } else {
    return config.suspectedAnomalousDiameter;
  }
};

export const isAnnotationOfUsersAndClasses = (anno, users, classes) => {
  let is_contains = false;
 
  const currentClassification = getCurrentClassification();
  let lines = [];
  if (classes.includes('Suspected_Anomalous_Areas') && classes.length === 1) {
    classes.forEach((c) => {
      for (let u of users) {
        if (c === 'Suspected_Anomalous_Areas' && anno.type === 'Circle') {
          for (let a of anno.lines) {
            let al = a.split(',');
            if (al[0] === u && al[1] !== 'null') {
              is_contains = true;
              break;
            }
          }
        }
        if (is_contains) {
          break;
        }
      }
    });
  } else {
    if (currentClassification !== 15) {
      const current_classification = config.classifications['class'+ currentClassification];    
      classes.forEach((c) => {
        for (let u of users) {
          let cl;
          for (let v of current_classification) {
            if (v.name === c) {
              v.groundTruth.forEach((gt) => lines.push(u + ',' + gt));
              break;
            }
          }
          cl = classes.filter((cs) => !current_classification.map((f) => f.name).includes(cs));
          if (cl.includes('Suspected_Anomalous_Areas')) {
            for (let a of anno.lines) {
              let al = a.split(',');
              if (al[0] === u && al[1] !== 'null') {
                is_contains = true;
                break;
              }
            }
          } else {
            lines = [...lines, ...cl.map((c) => u + ',' + c)];
            is_contains =!!lines.flat().find((l) => {
              return anno.lines.includes(l);
            });
          }
          if (is_contains) {
            break;
          }
        }
      });
    } else {
      lines = users.map((u) => classes.map((c) => u + ',' + c));
      is_contains = !!lines.flat().find((l) => anno.lines.includes(l));
    }
  }
  
  return is_contains;
};
