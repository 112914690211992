import React from 'react';
import PositiveRate from '../Visualization/PositiveRate';
import CommonPanel from './CommonPanel';
import intl from 'react-intl-universal';
import { fontColor } from './dataInfo';

function PositivePart(props){
  const { data } = props;
  return (
    <CommonPanel name={intl.get('POSITIVE_RATE')} height="33%">
      <PositiveRate
        data={data}
        parentName="dataPlatform"
        otherStyle={{ xyColor: fontColor, height: '90%' }}
        series={{
          type: 'line',
          itemStyle: {
            color: 'rgba(6, 93, 255, 1)',
            width: 5,
            borderWidth: 5,
            borderColor: 'rgba(6, 93, 255, 0.5)'
          }
        }}
      />
    </CommonPanel>
  );
}

export default PositivePart;
