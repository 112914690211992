import { Row, Col, Modal, message } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import DoctorList from '../DoctorList';
import {  disDocToImage } from '../../actions/imageActions';
import style from './index.less';
import styles from '../../less/modal.less';
import { disDocList } from '../../static/defaultTopTabs';
class PreAllocation extends Component {
  static propTypes = {
    selected: PropTypes.object,
    images: PropTypes.object
  };

  constructor() {
    super();
    this.state = {
      batchVisible: false,
      senior_reporter: [],
      junior_reporter: []
    };
  }
  componentDidMount() {}

  showBatchModal = () => {
    this.setState({
      batchVisible: true
    });
  };
  getDoctor(parentName, index, data) {
    let list = this.state[parentName];
    list[index] = data;
    this.setState({
      [parentName]: list
    });
  }

  handleOk = (e) => {
    const { general } = this.props;
    let params = {
      junior_reporter: this.state.junior_reporter.filter((i) => i !== '' && i !== undefined),
      senior_reporter: this.state.senior_reporter.filter((i) => i !== '' && i !== undefined)
    };
    let jlen = params.junior_reporter.length;
    let slen = params.senior_reporter.length;
    if (general.junior_distribute_roles.includes('ai') && slen === 0) {
      message.error(intl.get('SELECT_DOCTOR'));
      return false;
    } else if (!general.junior_distribute_roles.includes('ai') && (jlen === 0 || slen === 0)) {
      message.error(intl.get('SELECT_DOCTOR'));
      return false;
    } else if (jlen > 1 || slen > 1) {
      message.error(intl.get('ALLOCATION_WARN'));
      return false;
    }
    this.props.disDocToImage(this.props.image.id, params);
    this.setState({
      batchVisible: false
    });
  };

  handleCancel = (e) => {
    this.setState({
      batchVisible: false
    });
  };
  render() {
    const { image, roleUsers, general } = this.props;
    const { batchVisible } = this.state;
    return (
      <>
        <span
          onClick={this.showBatchModal}
          style={
            image.stage === 2 ? { textAlign: 'center', cursor: 'pointer' } : { textAlign: 'center' }
          }
        >
          {intl.get('ALLOCATION')}
        </span>
        <Modal
          width={800}
          title={intl.get('SINGLE_ALLOCATION')}
          visible={batchVisible}
          onOk={this.handleOk.bind(this)}
          onCancel={this.handleCancel.bind(this)}
          className={styles.checkModal}
        >
          {disDocList.map((part, idx) => {
            return (
              <div className={style.dis__Doc} key={idx}>
                {general[part.role].length !== 0 && !general[part.role].includes(part.disValue) && (
                  <div className={style.dis__Doc}>
                    <Row>
                      <Col span={12} className={style.dis__title}>
                        {intl.get(part.title)}:
                      </Col>
                    </Row>
                    <Row>
                      {part.content.map((item, index) => {
                        return (
                          general[part.role].includes(item.name) && (
                            <Col span={8} key={index}>
                              <span className={style.dis__label}>
                                {intl.get(item.label)}:&nbsp;&nbsp;
                              </span>
                              <DoctorList
                                selected={this.getDoctor.bind(this, part.name, index)}
                                parentName="single"
                                keyValue={[]}
                                width="180px"
                                doctorList={
                                  roleUsers[item.name] !== undefined ? roleUsers[item.name].filter(r=>r.distributable===true) : []
                                }
                              />
                            </Col>
                          )
                        );
                      })}
                    </Row>
                  </div>
                )}
              </div>
            );
          })}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    images: state.loadImages.images,
    selected: state.loadImages.selected,
    currentTab: state.tabState.currentTab,
    roleUsers: state.report.roleUsers,
    general: state.config.general
  };
};

export default connect(mapStateToProps, { disDocToImage })(PreAllocation);
