import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { getConfig } from '../../selectors/configSelector';
import { getCurrentUser } from '../../selectors/userSelectors';
import { connect } from 'react-redux';
import {Tabs } from 'antd';
import { initializeIO, resetImagesProgress } from '../../actions/progressActions';
import {stoggleSecondTab } from '../../actions/tabActions';
import { updateQueryInfo } from '../../actions/tableActions';
import styles from './index.less';
import InnerTabPane from './InnerTabPane'
const { TabPane } = Tabs;
class SecondTabs extends Component {
  constructor() {
    super();
    this.state = {
      TopTabsList: []
    };
  }

  componentWillUnmount() {
    this.props.resetImagesProgress();
  }


  changeTab = (key) => {
    const clicked = parseInt(key, 10);
    this.props.stoggleSecondTab(clicked);
  };

  getTabHeader = (tab, idx) => {
    const { secondCounts } = this.props;

    const header = tab.origin ? intl.get(tab.text) || tab.text : tab.text;
    return (
      <span style={tab.styles ? tab.styles : {}}>
        {header}({secondCounts[idx]})
      </span>
    );
  };

  render() {
    const {currentSecondTab,SecondTabsList } = this.props;
    return (
      <div className={'main__content ' + styles.main__content}>
        {SecondTabsList.length === 0 ? (
         <InnerTabPane  className={styles.secTab}/>
        ) : (
          <Tabs
            type="line"
            hideAdd={true}
            activeKey={currentSecondTab.toString()}
            onTabClick={this.changeTab}
            className={styles.secTab}
            style={{boxShadow:'none'}}
          >
            {SecondTabsList.map((tab, idx) => {
              return (
                <TabPane size='default' tab={this.getTabHeader(tab, idx)} key={idx} closable={!tab.origin}>
                 <InnerTabPane />
                </TabPane>
              );
            })}
          </Tabs>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.loadImages,
    ...state.tabState,
    config: getConfig(state),
    currentUser: getCurrentUser(state)
  };
};
export default connect(mapStateToProps, {
  updateQueryInfo,
  initializeIO,
  stoggleSecondTab,
  resetImagesProgress
})(SecondTabs);
