import { Table } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Resizable } from 'react-resizable';
import Cookies from 'js-cookie';
import {
  updateSelectedImages,
  loadModeImages,
  setSearchedFromImageId,
  setFromImageId
} from '../../actions/imageActions';
import { updateQueryInfo } from '../../actions/tableActions';
import { getConfig } from '../../selectors/configSelector';
import { getCurrentTabIdx, getSecondsTabs } from '../../selectors/tabSelectors';
import {
  getImages,
  getQueryInfo,
  getSelectedImages,
  getTableLoading
} from '../../selectors/imageSelector';
import { pickColumnsByTab } from '../../utils/displayUtils';
import { isEqual, uniq } from 'lodash';
import { canRender } from '../Can';
import './index.css';
import styles from './index.less';
import { filterJson } from '../../utils/dataUtils';
export function shouldLoad(elem) {
  const node = elem.nodeName.toLowerCase();
  const className = elem.className;
  return (
    node !== 'input' &&
    node !== 'svg' &&
    className.indexOf('dropdown') === -1 &&
    className.indexOf('select') === -1 &&
    !['roateImg','ant-tag','cellImage'].includes(className) &&
    !className.startsWith('ant-modal') &&
    !className.startsWith('ant-row')
  );
}

const ResizeableTitle = props => { 
  const { onResize, width, onClick,...restProps } = props; 
  let resizing = false;
  if (!width) {
    return <th {...restProps} />;
  }
 
  return (
    <Resizable width={width} height={0} onResize={onResize}
      onResizeStart={() => {
          resizing = true;
      }}
 
      onResizeStop={() => { 
        resizing = true; 
        setTimeout(() => { 
          resizing = false; 
        },100); 
      }}>
 
      <th {...restProps} 
        onClick={(...args) => { 
          if (!resizing) { 
            onClick(...args); 
          } 
      }}/> 
    </Resizable>
 
  );
 
};

class ImageTable extends Component {
  static propTypes = {
    history: PropTypes.object,
    queryInfo: PropTypes.object,
    updateQueryInfo: PropTypes.func,
    updateSelectedImages: PropTypes.func,
    isCollection: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      titles: [],
      tableH: 0
    };
  }

  componentDidMount() {
    this.getColumnHeader();
    window.addEventListener('resize', this.setfixedheight);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.config, this.props.config)) {
      // eslint-disable-next-line react/no-did-update-set-state
      // this.setState({ titles: pickColumns(this.props.config, this.props.isCollection) });
      this.setState({ titles: pickColumnsByTab(this.props.tabIdx) });
    }
    if (prevProps.images.data.length === 0 && this.props.images.data.length !== 0) {
      let bodyDivRef = document.querySelector('#imageTable .ant-table-scroll .ant-table-body');
      bodyDivRef.scrollLeft = 0;
      this.setfixedheight();
    }
    if (prevProps.tabIdx !== this.props.tabIdx) {
      this.setfixedheight();
    }
    if (prevProps.images.data !== this.props.images.data) {
      let t = document.querySelector('#imageTable .ant-table-body .ant-table-fixed');
      this.setState({
        tableH: t ? t.offsetHeight : 0
      });
      this.setScroll();
    }
  }

  componentWillUnmount() {
    if (this.timeoutFunc) {
      this.timeoutFunc = null;
      clearTimeout(this.timeoutFunc);
    }
    window.removeEventListener('resize', this.setfixedheight);
  }

  setfixedheight() {
    const bodyDiv = document.querySelector('#imageTable .ant-table-scroll .ant-table-header');
    if (bodyDiv) {
      bodyDiv.style.marginBottom = 0;
      bodyDiv.style.overflow = 'hidden';
    }
  }

  setScroll = () => {
    const bodyDiv = document.querySelector('#imageTable .ant-table-scroll .ant-table-body');
    const els = document.querySelectorAll('#imageTable .ant-table-body-inner');
    if (els && bodyDiv) {
      els.forEach((el) => {
        el.style.overflowX = 'hidden';
        // if (bodyDiv.scrollWidth > bodyDiv.clientWidth) {
        //   el.style.overflowX = 'scroll';
        // } else {
        //   el.style.overflowX = 'hidden';
        // }
      });
    }
  };
  getColumnHeader = () => {
    // this.setState({ titles: pickColumns(this.props.config, this.props.isCollection) });
    this.setState({ titles: pickColumnsByTab(this.props.tabIdx) });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { queryInfo } = this.props;
    this.props.updateQueryInfo({
      page: queryInfo.page,
      sortBy: sorter.columnKey,
      sortAsc: sorter.columnKey === queryInfo.sortBy ? !queryInfo.sortAsc : false
    });
  };

  setTableH(name) {
    const { secondTabs } = this.props;
    if (secondTabs !== null && secondTabs !== undefined) {
      let ch = window.innerHeight;
      if (name === 'min') return secondTabs.length === 0 ? ch - 273 : ch - 326;
      if (name === 'h') return secondTabs.length === 0 ? ch - 347 : ch - 380;
    }
  }

  selectRows = (keys, rows) => {
    const { selected } = this.props;
    let selectedRows = selected.data;
    let ks = uniq(keys);
    selectedRows = filterJson(selectedRows.concat(rows), 'id').filter((item) => {
      return ks.indexOf(item.key) !== -1;
    });
    this.props.updateSelectedImages({ data: selectedRows, total: ks.length });
  };

  handleResize = index => (e, { size }) => {
    this.setState(({ titles }) => {
      const nextColumns = [...titles];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      let width_titles = {}
      nextColumns.forEach(item=>{     
        const dataIndex = item.dataIndex;
        width_titles[dataIndex] = item.width;   
      });
      Cookies.set('imageTableWidthTitles', width_titles);
      return { titles: nextColumns };
    });
  };

  sortedColumns = () => {
    const { queryInfo } = this.props;
    const { titles } = this.state;
    const { sortBy, sortAsc } = queryInfo;
    const sortDirection = sortAsc ? 'ascend' : 'descend';
    return titles.map((item, index) => ({
      ...item,
      sortOrder: item.key === sortBy && sortDirection,
      onHeaderCell: column => ({
        width: column.width,
        onResize: this.handleResize(index),
      }),
    }));
  };
  
  onKeyDown = () => {
    if (window.event.keyCode === 13) {
      const { selected,images } = this.props;
      let record = images.data.find((i) => (i.id === images.fromImageId));
      let path = loadModeImages(selected, images, record);
      this.props.history.push(path);
    }
  };


  render() {
    const { images, selected, forceLoading, currentUser } = this.props;
    const { data, total } = images;
    const rowSelection = {
      selectedRowKeys: selected.data.map((item) => item.key),
      onChange: this.selectRows,
      columnWidth: '40px',
      fixed: 'left'
    };
    const h = this.setTableH('h');
    const minH = this.setTableH('min');
    return (
      <div className="image-table" onKeyDown={this.onKeyDown.bind(this)}>
        <Table
          id="imageTable"
          components={
            {header: {
              cell: ResizeableTitle,
            },}
          }
          fixed
          // bordered 
          className={styles.image_table + ' image-info-table'}
          rowSelection={rowSelection}
          rowKey={(record) => record.id}
          key={(record) => record.id}
          size="middle"
          rowClassName={(record, index) => {
            if (record.id === images.fromImageId) {
              if (this.timeoutFunc) {
                clearTimeout(this.timeoutFunc);
              }
              this.timeoutFunc = setTimeout(() => {
                this.props.setSearchedFromImageId('');
              }, 500);
              return 'image-info-table-row image__info__table__row__memorized';
            } else {
              if (record.is_urgent) {
                return 'image-info-table-row image-info-table-row_urgent';
              }
            }
            return 'image-info-table-row';
          }}
          columns={this.sortedColumns()}
          ellipsis
          total={total}
          dataSource={data}
          loading={forceLoading}
          pagination={false}
          onChange={this.handleTableChange}
          scroll={{
            // x: config.imageListColumns.length > 10 ? window.screen.availWidth : null,
            x: window.innerWidth > 2265 ? '100%' : window.innerWidth,
            // x: '100%',
            y: this.state.tableH > minH ? h : null
          }}
          onRow={(record) => {
            return {
              onClick: (event) => {
                this.props.setFromImageId(record.id);
              },
              onDoubleClick: (event) => {
                if (canRender(currentUser.permissions, 'client:image:view')) {
                  if (shouldLoad(event.target)) {
                    const { selected, images } = this.props;
                    let path = loadModeImages(selected, images, record);
                    this.props.history.push(path);
                  }
                }
              }
            };
          }}
          style={{ minHeight: minH, maxHeight: minH }}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    forceLoading: getTableLoading(state),
    queryInfo: getQueryInfo(state),
    images: getImages(state),
    selected: getSelectedImages(state),
    config: getConfig(state),
    tabIdx: getCurrentTabIdx(state),
    secondTabs: getSecondsTabs(state),
    currentUser: state.currentUser
  }),
  { updateQueryInfo, updateSelectedImages, loadModeImages, setSearchedFromImageId, setFromImageId }
)(withRouter(ImageTable));
