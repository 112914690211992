import React, { Component } from 'react';
import { connect } from 'react-redux';
import style from './index.less';
import intl from 'react-intl-universal';
import ReactToPrint from 'react-to-print';
import { Modal, Button } from 'antd';
import ProPrintLabel from './ProPrintLabel';
import { canRender } from '../Can';
class ProPrint extends Component {
  constructor() {
    super();
    this.state = {
      isModalVisible: false
    };
    this.content = React.createRef();
  }
  showModal() {
    if (this.props.isNoSelect()) return false;
    this.setState({ isModalVisible: true });
  }
  handleCancel() {
    this.setState({ isModalVisible: false });
  }
  render() {
    const {currentUser} = this.props
    return (
      <>
        <Button
          type="primary"
          onClick={this.showModal.bind(this)}
          disabled={canRender(currentUser.permissions, 'client:section:print') ? false : true}
        >
          {intl.get('PRO_PRINT')}
        </Button>
        <Modal
          title={intl.get('PRO_PRINT')}
          visible={this.state.isModalVisible}
          className={style.checkModal}
          onCancel={this.handleCancel.bind(this)}
          footer={[
            <Button key="back" onClick={this.handleCancel.bind(this)}>
              {intl.get('CANCEL')}
            </Button>,
            <ReactToPrint
              key="submit"
              trigger={() => (
                <Button key="back" type="primary">
                  {intl.get('PRO_PRINT')}
                </Button>
              )}
              content={() => this.content.current}
              onAfterPrint={this.handleCancel.bind(this)}
            />
          ]}
        >
          <div ref={this.content}>
            <ProPrintLabel />
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(ProPrint);
