// @flow
import { Tabs } from 'antd';
import React, { Component } from 'react';
import AnnotationList from '../AnnotationList';
import { connect } from 'react-redux';
import { setShowAnnotationClass, addAnnotationChangeAsync } from '../../actions/annotationActions';
import style from './index.less';
import { getAnnotationClassesOfUsers } from '../../selectors/classSelectors';
import AnnotationListTab from '../AnnotationList/AnnotationListTab';
import { setAnimationStatus } from '../../actions/toolActions';
 
import { getLoginUser, visibleNegativeLesion, getCurrentClassification } from '../../selectors';
import intl from 'react-intl-universal';
// import config from '../../config';

const { TabPane } = Tabs;

class AnnotationTable extends Component {
  imageLeve_D(groups, isClassification15, defaultActiveKey) {
    // console.log("---imageLeve_D groups", this.props.showAnnotationClass, defaultActiveKey)
    return (
      <Tabs
        activeKey={groups.length > 0 ? this.props.showAnnotationClass:defaultActiveKey}
        defaultActiveKey={defaultActiveKey}
        className={style.tab}
        tabPosition="left"
        type="card"
        tabBarGutter={0}
        onChange={(key) => {
          this.props.setShowAnnotationClass(key);
          this.props.setAnimationStatus('stopped');
        }}
      >
        {groups.length > 0 ? (
          groups.map((group) => {
            
            return group === 'ALL' ? (
              <TabPane
                key="ALL"
                tab={
                  <AnnotationListTab
                    isAll={true}
                    classes={groups.filter(v=> (v !== "Others" ))}
                    classification15={isClassification15}
                  />
                }
              >
                <AnnotationList classes={groups.filter(v=> (v !== "Others" ))} />
              </TabPane>
            ) : (
              <TabPane
                key={group}
                tab={
                  <AnnotationListTab
                    classes={[group]}
                    classification15={isClassification15}
                    loginUser={this.props.loginUser}
                    addAnnotationChangeAsync={this.props.addAnnotationChangeAsync}
                  />
                }
              >
                <AnnotationList classes={[group]} />
              </TabPane>
            );
          })
        ) : (
          <TabPane
            key="ALL"
            tab={
              <AnnotationListTab
                isAll={true}
                classes={groups.filter(v=> (v !== "Others" ))}
                classification15={isClassification15}
              />
            }
          >
            <AnnotationList classes={groups.filter(v=> (v !== "Others" ))} />
          </TabPane>
        )}
      </Tabs>
    );
  }

  render() {
    // .tab { :global {.ant-tabs-tab {height: 32px !important;}}}
    let groups = this.props.annotationClasses; 

    if(groups.length > 2 && groups[0] !== 'ALL'){
      groups.unshift("ALL")
    }
    if(!visibleNegativeLesion() && groups.includes("Suspected_Anomalous_Areas")) {
      groups = groups.filter(it=>(it !== 'Suspected_Anomalous_Areas' && it !== 'ALL'));
      setTimeout(() => {
        this.props.setShowAnnotationClass(groups.length > 0 ? groups[0] : "");
      }, 10);
    }
    if(!groups.includes(this.props.showAnnotationClass)){
      setTimeout(() => {
        this.props.setShowAnnotationClass(groups.length > 0 ? groups[0] : "");
      }, 10);
    }
    let defaultActiveKey = groups.length > 0 ? groups[0] : "";
    let is_classification15 = getCurrentClassification() === 15; 

    // console.log("---------groups",this.props.showAnnotationClass,  groups, defaultActiveKey)
    let renderTabs = groups.length > 0
      ? this.imageLeve_D(groups, is_classification15, defaultActiveKey)
      : undefined;
    return (
      <div>
        {renderTabs}
        <div className={style.mark__div}>
          <div className={style.mark__left__div}>{intl.get('MARKED')}</div>
          <div className={style.mark__right__div}>
            <AnnotationList role="mark" height="274px" classes={groups} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showAnnotationClass: state.imageSelection.showAnnotationClass,
    annotationClasses: getAnnotationClassesOfUsers(state),
    loginUser: getLoginUser(state)
  };
};

export default connect(mapStateToProps, {
  setShowAnnotationClass,
  addAnnotationChangeAsync,
  setAnimationStatus
})(AnnotationTable);
