
import { initReduxData } from '../utils/dataUtils';
const initial = { 
  data: [],
};



const comments = (state = initial, action) => {
  state.data = initReduxData(initial.data, state.data);
  switch (action.type) {
    case 'LOAD_COMMENTS':
      return {
        ...state,
        data: action.comments
      };
    case 'ADD_COMMENT':
      return {
        ...state,
        data: [...state.data,action.comment]
      };
    case 'DELETE_COMMENT':
      return {
        ...state,
        data:state.data.filter((item, idx) => item.id!== action.commentId),
      };
    default:
      return state;
  }
};

export default comments;
