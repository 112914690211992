import store from '../store';
import { getGeneral } from '../selectors/configSelector';
import { getCurrentUser } from '../selectors/userSelectors';
import { canRender } from '../components/Can';
import config from '../config';
/**
 * 根据本地/远程，获取将访问的图像域名与端口
 * @param {string} baseUrl  本地地址
 * @param {object} currentImageInfo 当前图像信息
 * @returns 访问图像地址前缀
 */
export default function setBaseUrl(baseUrl, currentImageInfo) {
  let imageBeforeUrl;
  const nodeMode = getGeneral(store.getState()).node_mode;
  imageBeforeUrl =
    nodeMode === 1
      ? 'http://' + currentImageInfo?.web_host + ':' + currentImageInfo?.web_port
      : baseUrl;
  return imageBeforeUrl;
}

export function getNodeMode() {
  return getGeneral(store.getState()).node_mode;
}
/**
 *
 * @returns 当前端
 */
export function isPartEnd() {
  const general = getGeneral(store.getState());
  const user = getCurrentUser(store.getState());
  //本地
  if (general.node_mode === 0) {
    //全流程 || 单机
    return general.product_mode === 0 ? 'all' : 'local';
  } else if (general.node_mode === 1) {
    //远程
    //专家  ||  中心端
    return canRender(user.permissions, 'client:report:expert') ? 'expert' : 'centerEnd';
  } else {
    return 'colony';
  }
}
/**
 * 根据地址栏地址判断端
 * 用于登录页面
 */
export function setEndbyUrl() {
  let l = config.urlList.filter((u) => u.url === document.domain);
  return l.length > 0 ? l[0].name : '';
}

/**
 * 中心端数据操作不可用
 * @returns 不可操作样式
 */
export function setActionDisabled() {
  const partEnd = isPartEnd();
  return partEnd === 'centerEnd' ? { pointerEvents: 'none', opacity: 0.65 } : {};
}
