import intl from 'react-intl-universal';
const tctList = [
  'NILM',
  'ROI',
  'ASC-US',
  'LSIL',
  'ASC-H',
  'HSIL',
  'SCC',
  'AGC',
  'AGC-N',
  'AIS',
  'ADC'
];
const orgList = ['FUNGI', 'TV', 'CC', 'ACTINO', 'HSV', 'EMC'];

export default function getTctMapping() {
  const mapping = {};
  // tct
  tctList.forEach((item) => {
    mapping[item] = intl.get('TCT_' + item);
  });

  // Org
  orgList.forEach((item) => {
    mapping[item] = intl.get('ORG_' + item);
  });
  return mapping;
}

export function tctGroupMapping() {
  const mapping = {};
  tctList.forEach((item) => {
    mapping[item] = 1;
  });
  orgList.forEach((item) => {
    mapping[item] = 2;
  });
  return mapping;
}
