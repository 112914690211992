import { initReduxData } from '../utils/dataUtils';
import { initalCheckInfo } from '../components/CheckIn/checkInfo';
export const initial = {
  checkInfo: initalCheckInfo,
  checkInfoList:{
    items: [],
    total: 0  
  },
  queryInfo: {
    page: 0,
    limit: 10,
    registrate_from: '',
    registrate_to: '',
    sortAsc: '',
    sortBy: 'is_urgent',
    fuzzy: '',
    is_rejected: false
  },
  hisInfo: {},
  tabName: '0',
  checkType: 'add',
  tabCounts: [0, 0],
};

function checkIn(state = initial, action) {
  state.checkInfo = initReduxData(initial.checkInfo, state.checkInfo);
  state.queryInfo = initReduxData(initial.queryInfo, state.queryInfo);
  state.tabName = initReduxData(initial.tabName, state.tabName);
  state.checkType = initReduxData(initial.checkType, state.checkType);
  switch (action.type) {
    case 'SET_CHECK_IN_INFO':
      return { ...state, checkInfo: action.data };
    case 'SET_HIS_INFO':
      return { ...state, hisInfo: action.data };
    case 'UPDATE_CHECK_IN_INFO_PARAMS':
      return {
        ...state,
        checkInfo: {
          ...state.checkInfo,
          ...action.data
        }
      };
    case 'UPDATE_CHECK_IN_TYPE':
      return {
        ...state,
        checkType: action.data
      };

    case 'UPDATE_CHECK_IN_LIST_INFO':
      return { ...state, checkInfoList: action.data };
    case 'UPDATE_CHECK_IN_QUERY_INFO':
      return { ...state, queryInfo: { ...state.queryInfo, ...action.data } };
    case 'UPDATE_CHECK_IN_TAB':
      return { ...state, tabName: action.data };
    case 'UPDATE_CHECK_IN_COUNTS':
      return { ...state, tabCounts: action.data };
    case 'UPDATE_CHECK_IN_MSG':
      return { ...state, msg: action.data };
    default:
      return state;
  }
}

export default checkIn;
