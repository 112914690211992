import { Layout, Menu } from 'antd';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Icon from '../components/Iconfont';
import { connect } from 'react-redux';
import { getCurrentUser } from '../selectors/userSelectors';
import { canRender } from '../components/Can/index';
import { isPartEnd } from '../utils/urlUtils';
const { Sider } = Layout;
const { SubMenu } = Menu;

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedKeys: [''],
      openKeys: [''],
      rootMenus: this.props.sideBarItemKeys
    };
  }

  componentDidMount() {
    this.setDefaultActiveItem(this.props.history);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setDefaultActiveItem(this.props);
    }
  }

  setDefaultActiveItem = ({ location }) => {
    const { pathname } = location;
    this.props.sideBarItems.forEach((item) => {
      if ('path' in item) {
        if (pathname.match(item.path)) {
          this.setState({
            selectedKeys: [item.key]
          });
        }
      } else if ('children' in item) {
        item.children
          .filter((i) => i.mode.includes(this.props.general.product_mode))
          .forEach((child) => {
            if (pathname.match(child.path)) {
              this.setState({
                selectedKeys: [child.key],
                openKeys: [item.key]
              });
            }
          });
      }
    });
  };

  onOpenSubMenu = (openKeys) => {
    const avaliableKeys = openKeys.filter((key) => this.state.rootMenus.indexOf(key) !== -1);
    this.setState({ openKeys: avaliableKeys });
  };

  render() {
    const prefix = this.props.history.location.pathname.split('/')[1];
    const partEnd = isPartEnd();
    const Menus = (
      <Menu
        theme="light"
        mode="inline"
        defaultSelectedKeys={[this.state.rootMenus[0]]}
        selectedKeys={this.state.selectedKeys}
        openKeys={this.state.openKeys}
        onOpenChange={this.onOpenSubMenu}
      >
        {this.props.sideBarItems.map((item) => {
          if ('path' in item) {
            return canRender(this.props.currentUser.permissions, item.permission) &&
              item.partName.includes(partEnd) ? (
              <Menu.Item
                key={item.key}
                onClick={() => {
                  this.setState({ selectedKeys: [item.key] });
                }}
              >
                <Link to={item.key === 'dataPlatfrom' ? `${item.path}` : `/${prefix}${item.path}`}>
                  {!['centerEnd'].includes(partEnd) && <Icon type={item.title.icon} />}
                  <span>{intl.get(item.title.text)}</span>
                </Link>
              </Menu.Item>
            ) : item.permission === undefined ? (
              <Menu.Item
                key={item.key}
                onClick={() => {
                  this.setState({ selectedKeys: [item.key] });
                }}
              >
                <Link to={`/${prefix}${item.path}`}>
                  {partEnd !== 'centerEnd' && <Icon type={item.title.icon} />}
                  <span>{intl.get(item.title.text)}</span>
                </Link>
              </Menu.Item>
            ) : (
              ''
            );
          } else if ('children' in item) {
            return canRender(this.props.currentUser.permissions, item.permission) &&
              item.partName.includes(partEnd) ? (
              <SubMenu
                key={item.key}
                onClick={() => {
                  this.setState({ openKeys: [item.key] });
                }}
                title={
                  <span>
                    {!['centerEnd'].includes(partEnd) && <Icon type={item.title.icon} />}
                    <span>{intl.get(item.title.text)}</span>
                  </span>
                }
              >
                {item.children
                  .filter((i) => i.partName.includes(partEnd))
                  .map((child) => {
                    return (
                      <Menu.Item
                        key={child.key}
                        onClick={() => {
                          this.setState({ selectedKeys: [child.key] });
                        }}
                      >
                        <Link to={`/${prefix}${child.path}`}>{intl.get(child.text)}</Link>
                      </Menu.Item>
                    );
                  })}
              </SubMenu>
            ) : (
              ''
            );
          } else {
            return <span  key={item.key}/>;
          }
        })}
      </Menu>
    );

    return (
      <Sider theme="light" collapsible>
        {Menus}
      </Sider>
    );
  }
}

export default connect((state) => ({
  currentUser: getCurrentUser(state),
  general: state.config.general
}))(withRouter(Sidebar));
