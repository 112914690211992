import { canRender } from '../components/Can';
/**报告页签******************************************************************* */
//初诊-同步AI结果
//junior || expert
export function juniorSynhroPower(currentUser) {
  return (
    canRender(currentUser.permissions, 'client:report:junior') ||
    canRender(currentUser.permissions, 'client:report:expert')
  );
}

//复核-改派、审核
//已诊断-推送
//senior || expert
export function seniorPower(currentUser) {
  return (
    canRender(currentUser.permissions, 'client:report:senior') ||
    canRender(currentUser.permissions, 'client:report:expert')
  );
}

//待打印
export function printPower(currentUser) {
  return canRender(currentUser.permissions, 'client:report:print');
}

//待发放
export function sentPower(currentUser) {
  return canRender(currentUser.permissions, 'client:report:sent');
}

//重新计算
export function calPower(currentUser) {
  return (
    canRender(currentUser.permissions, 'client:view:devops') ||
    canRender(currentUser.permissions, 'client:view:super')
  );
}
