import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isPending } from '../../static/detectionStatusMapping';
import ImageLevelResultTag from '../ImageLevelResultTag';
import ProgressBar from '../ProgressBar';
import StatusMessage from './StatusMessage';
// import {  Tooltip } from 'antd';
class DetectionStatus extends Component {
  static propTypes = {
    image: PropTypes.object,
    results: PropTypes.string
  };

  render() {
    const { results, image } = this.props;
    if (image.clazz === 'general') {
      return <ImageLevelResultTag result="---" />;
    }
    // if (image.satisfactory !== null && image.satisfactory !== undefined && image.satisfactory === false) {
    //   return <StatusMessage status={40003} />
    // }
    if (image.status < 10 && results) {
      return <ImageLevelResultTag result={results} shouldBefiltered={true} bgStyle='no'/>;
      // return <Tooltip  title={results}><div>{results}</div></Tooltip>
    } else if (isPending(image.status)) {
      return <ProgressBar filename={image.filename} />;
    }
    return <StatusMessage status={image.status} />;
  }
}

export default DetectionStatus;
