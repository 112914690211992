/**
 * @fileOverview
 * @name detectionTypes.js
 * @author
 * @license
 detection types structure:
 value: @type {string} actual value of detection
 translate: @type {string} translation key of value
 */

const detectionTypes = [
  { value: 'cervical', translate: 'CANCER_CERVICAL' }
];

export default detectionTypes;
