import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Menubar from '../components/Menubar';
import { Layout, Col, Row } from 'antd';
import UserInfoPart from './UserInfoPart';
import UserAction from './UserAction'
import styles from "./index.less"
import {menuList1,menuList2} from './UserInfo'
class Expert extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {history} = this.props
    return (
      <Layout style={{ height: '100%' }}>
        <div className="header" style={{ width: '100%' }}>
          <Menubar />
        </div>
        <Row gutter={20} className={styles.expert_content}>
          <Col span={6} offset={3}>
            <UserInfoPart />
          </Col>
          <Col span={6}><UserAction menuList={menuList1} history={history}/></Col>
          <Col span={6}><UserAction menuList={menuList2} history={history}/></Col>
        </Row>
      </Layout>
    );
  }
}

export default withRouter(Expert);
