import { Button, Card, Form } from 'antd';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { updatePassword, updateSambaPass } from '../../actions/userActions';
import { getCurrentUser } from '../../selectors/userSelectors';
import { clearForm } from '../../utils/formUtils';
import { formItemLayout, formTailLayout } from './formProperty';
import generateFormItems from './generateFormItems';
import getPasswordFormFields from './getPasswordFormFields';

function passwordFormBuilder(title, submitBtnName, onSubmit) {
  class PasswordForm extends Component {
    compareToPassword = (rule, value, callback) => {
      const { form } = this.props;
      if (value && value !== form.getFieldValue('new_password')) {
        callback(true);
      }
      callback();
    };

    compareToPasswordConfirm = (rule, value, callback) => {
      const { form } = this.props;
      if (value && value !== form.getFieldValue('new_password_confirm')) {
        form.validateFields(['new_password_confirm'], { force: true });
      }
      callback();
    };

    onSubmit = (e) => {
      const { form } = this.props;
      form.validateFields((err, values) => {
        if (!err) {
          this.props.onSubmit({ old: values.old_password, new: values.new_password });
          clearForm(form, ['old_password', 'new_password', 'new_password_confirm']);
        }
      });
    };

    render() {
      const fields = getPasswordFormFields(this);
      const { form, currentUser } = this.props;
      const { getFieldDecorator } = form;
      return (
          <Card title={intl.get(title)}>
            <Form {...formItemLayout}>
              {generateFormItems(fields, currentUser, getFieldDecorator)}
              <Form.Item {...formTailLayout}>
                <Button type="primary" onClick={this.onSubmit}>
                  {intl.get(submitBtnName)}
                </Button>
              </Form.Item>
            </Form>
          </Card>
      );
    }
  }

  const mapStateToProps = (state) => ({ currentUser: getCurrentUser(state) });
  return connect(mapStateToProps, { onSubmit })(Form.create({ name: title })(PasswordForm));
}

export const ChangePasswordForm = passwordFormBuilder(
  'CHANGE_PASSWORD',
  'CONFIRM_CHANGE_PASSWORD',
  updatePassword
);
export const ChangeSmbPasswordForm = passwordFormBuilder(
  'CHANGE_SMBPASS',
  'CONFIRM_CHANGE_PASSWORD',
  updateSambaPass
);
