/* @flow */
import { Component } from 'react';

class AnnotationScroller extends Component {
  shouldComponentUpdate(nextProps) {
    this.props.selected &&
      this.props.annoRef.current &&
      this.props.annoRef.current.scrollIntoView({ behavior: 'auto', block: 'center' });
    return nextProps.shouldScroll && this.props.selected !== nextProps.selected;
  }

  render() {
    // this.props.selected &&
    //   this.props.annoRef.current &&
    //   this.props.annoRef.current.scrollIntoView({ behavior: 'auto', block: 'center' });
    return null;
  }
}

export default AnnotationScroller;
