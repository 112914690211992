import React, { Component } from 'react';
import { Result, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class NoMatch extends Component {
  static propTypes = {
    noHomeButton: PropTypes.bool
  };

  backToHome = () => {
    this.props.history.push({
      pathname: '/'
    });
  };

  render() {
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" onClick={this.backToHome}>
            Back to Home
          </Button>
        }
      />
    );
  }
}

export default withRouter(NoMatch);
