import { fabric } from 'openseadragon-fabricjs-overlay';
import { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { getInvisibleClasses,visibleConfidence, visibleLabel, getSuspectedAnomalousDiameter} from '../../selectors';
import { newCircleOverlay, pointText } from './PointAnnotation';
class AnnotationOfWSI extends Component {
  componentDidMount() {
    this.props.annotation && this.addAnnotation();
    this.props.annotation && this.addLabel();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.annotation.classes !== this.props.annotation.classes) {
      this.removeAnno();
      this.addAnnotation();
      this.addLabel();
    }
  }

  componentWillUnmount() {
    this.rect && this.props.overlay.fabricCanvas().remove(this.rect);
    this.text && this.props.overlay.fabricCanvas().remove(this.text);
    this.polygon && this.props.overlay.fabricCanvas().remove(this.polygon);
    this.circle && this.props.overlay.fabricCanvas().remove(this.circle);
    this.p && this.props.overlay.fabricCanvas().remove(this.p);
    this.t && this.props.overlay.fabricCanvas().remove(this.t);
  }
  
  unique (arr) {
    return Array.from(new Set(arr))
  }
  
  judgeUser(anno) {
    let labName;
    const per = (Number(anno.prob) * 100).toFixed(0);
    let aiList = anno.users.filter((item) => {
      return item.startsWith('ai_');
    });
    let classesList = this.unique(anno.classes)
    if (aiList.length > 0) {
      labName = [ classesList.join(' ') + (visibleConfidence() ? '(' + per + '%)':"")];
    } else {
      labName = [' * ' + classesList.join(' ') +  (visibleConfidence() ?'(' + per + '%)':"")];
    }

    return labName;
  }

  addLabel() {
    if(visibleConfidence() || visibleLabel()){
      const anno = this.props.annotation;
      const label = this.judgeUser(anno)
        .filter((c) => !this.props.hiddenClasses.includes(c))
        .map((c) => {
          const tp = c === 'Points' ? intl.get('POINTS') : c;
          return c === 'Draft' ? intl.get('ANNOTATION_DRAFT') : tp;
        })
        .reduce((l, c) => (visibleLabel() ? (l + ' ' + c):"")+ (anno.clump ? ".":""));

      const fontSize = 30;
      this.addText(label, { x: anno.x_start, y: anno.y_start - fontSize }, fontSize, anno.id);
    }
  }

  addText(t, { x, y }, fontSize, id) {
    const hiddenColor = 'rgba(0,0,0,0)';
    const fillColor = 'rgba(0,0,0,1)';
    const backgroundColor = 'rgba(214,214,214,0.8)';
    const annoStrokeColor = 'rgba(0,255,0,0.8)';
    this.text = new fabric.Text(t, {
      id: 'text' + id,
      left: x,
      top: y,
      textAlign: 'left',
      fontSize: fontSize,
      selectable: false,
      falseClearCache: true,
      fill: fillColor,
      backgroundColor,
      fontFamily: 'Microsoft YaHei',
      hoverCursor: 'pointer'
    });

    const colorSwitcher = () => {
      const textColor = this.text.get('fill') === hiddenColor ? fillColor : hiddenColor;
      const bgColor =
      this.text &&   this.text.get('backgroundColor') === hiddenColor ? backgroundColor : hiddenColor;
      const strokeColor = this.text.get('fill') === hiddenColor ? annoStrokeColor : hiddenColor;
      this.text &&  this.text.set('fill', textColor);
      this.text &&  this.text.set('backgroundColor', bgColor);
       this.rect &&  this.rect.set('stroke', strokeColor);
       this.circle && this.circle.set('stroke', strokeColor);
    };

    if (t.length > 0) {
      this.props.overlay.fabricCanvas().add(this.text);
      this.text.on('mousedown', colorSwitcher);
    }
  }

  addAnnotation() {
    const anno = this.props.annotation;

    if (anno.px && anno.py) {
      const w = anno.x_range ? Number(anno.x_range) : 350;
      const h = anno.y_range ? Number(anno.y_range) : 350;
      const { x, y } = window.osdviewer.viewport.imageToViewportCoordinates(
        Number(anno.px) - w / 2.0,
        Number(anno.py) - h / 2.0
      );
      if (anno.type === 'Box' || anno.type === 'Pointset') {
        this.rect = new fabric.Rect({
          id: 'rect' + anno.id,
          left: x,
          top: y,
          fill: 'rgba(0,0,0,0)',
          stroke: 'rgba(0,255,0,0.8)',
          strokeWidth: 8,
          width: w,
          height: h,
          editable: false,
          selectable: false,
          evented: false
        });
        this.props.overlay.fabricCanvas().add(this.rect);
      } else if (anno.type === 'Polygon') {
        this.polygon = new fabric.Polygon(anno.points, {
          fill: 'rgba(0, 255, 0, 0)',
          stroke: 'rgba(0, 255, 0, 0.8)',
          strokeWidth: 40,
          editable: false,
          selectable: false,
          evented: false
        });
        this.props.overlay.fabricCanvas().add(this.polygon);
      }else if(anno.type === "Circle"){
        const { x, y } = window.osdviewer.viewport.imageToViewportCoordinates(
          Number(anno.px),
          Number(anno.py)
        );
        this.circle = new fabric.Circle({
          left: x-getSuspectedAnomalousDiameter()/2.0,
          top: y-getSuspectedAnomalousDiameter()/2.0,
          radius: getSuspectedAnomalousDiameter()/2.0,
          strokeDashArray:[120,80],
          fill: 'rgba(0, 255, 0, 0)',
          stroke: 'rgba(0, 255, 0, 0.8)',
          strokeWidth: 10,
          editable: false,
          selectable: false,
          evented: false
        });
        this.props.overlay.fabricCanvas().add(this.circle);
      }
    } else {
      console.log('annotation x or y undfined', anno);
    }

    if (anno.points && anno.type === 'Pointset') {
      anno.points.map((point, idx) => {
        const newp = window.osdviewer.viewport.imageToViewportCoordinates(
          Number(point.x),
          Number(point.y)
        );
        const radius = 16;
        this.p = newCircleOverlay(newp, 'rgba(255,255,0,1)', radius);
        this.props.overlay.fabricCanvas().add(this.p);
        this.t = pointText((idx + 1).toString(), newp, radius);
        this.props.overlay.fabricCanvas().add(this.t);
        return point;
      });
    }
  }


  removeAnno() {
    this.rect &&  this.props.overlay.fabricCanvas().remove(this.rect);
    this.text &&  this.props.overlay.fabricCanvas().remove(this.text);
    this.circle && this.props.overlay.fabricCanvas().remove(this.circle);
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    showAnnotationInfo: state.toolStatus.showAnnotationInfo,
    hiddenClasses: getInvisibleClasses(state),
    currentImageInfo: state.imageSelection.currentImageInfo
  };
};

export default connect(mapStateToProps)(AnnotationOfWSI);
