/* @flow */

import { Empty, Spin, List } from 'antd';
import React, { Component } from 'react';
import Annotation from '../Annotation';
import { connect } from 'react-redux';
import {
  getAnnotationsOfClasses,
  getAnnotationsOfCollected,
  hasMoreMarkAnnotationsOfClasses,
  hasMoreAnnotationsOfClasses
} from '../../selectors/annotationSelectors';
import style from './index.less';
import InfiniteScroll from 'react-infinite-scroll-component';
import EndMessage from './EndMessage';

const height = window.innerHeight - 415;
const pageSize = (height / 100) * 3;

class AnnotationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      annotations: this.props.annotations.slice(0, pageSize),
      total: this.props.annotations.length,
      page: 0
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const annotations = nextProps.annotations.slice(0, (prevState.page + 1) * pageSize);
    return { ...prevState, annotations, total: nextProps.annotations.length };
  }

  handleInfScroll = () => {
    const page = this.state.page + 1;
    const sliced = this.props.annotations
      .map((a) => a)
      .slice(page * pageSize, (page + 1) * pageSize);

    this.setState({
      annotations: this.state.annotations
        .map((a) => a)
        .concat(sliced)
        .flat(),
      page
    });
  };




 
  render() {
    return (
      <div
        className={style.list}
        style={{
          height: this.props.height
        }}
      >
        {this.props.annotations.length > 0 ? (
          <InfiniteScroll
            dataLength={this.state.annotations.length}
            next={this.handleInfScroll.bind(this)}
            hasMore={this.state.annotations.length < this.state.total}
            loader={<Spin />}
            height={this.props.height ? this.props.height : height}
            className="scrollBar"
            id="annoLists"
            style={{ overflowX: 'hidden' }}
            endMessage={
              <EndMessage
                role={this.props.role}
                canLoadMore={this.props.canLoadMore}
                total={this.state.total}
              />
            }
          >
            <List
              grid={{ gutter: 24, column: 3 }}
              itemLayout="vertical"
              dataSource={this.state.annotations}
              renderItem={(item) => {
                const selected = item.id === this.props.currentAnnotationId;
                const hovered = item.id === this.props.hoveredAnnotationId;
                return (
                  <List.Item>
                    <Annotation key={item.id} item={item} selected={selected} hovered={hovered} />
                  </List.Item>
                );
              }}
            />
          </InfiniteScroll>
        ) : (
          <Empty className={style.emptyDiv} image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentAnnotationId: state.annotationSelection.currentAnnotationId,
  hoveredAnnotationId: state.annotationSelection.hoveredAnnotationId,
  annotations:
    ownProps.role === 'mark'
      ? getAnnotationsOfCollected(state, ownProps)
      : getAnnotationsOfClasses(state, ownProps),
  canLoadMore:
    ownProps.role === 'mark'
      ? hasMoreMarkAnnotationsOfClasses(state, ownProps)
      : hasMoreAnnotationsOfClasses(state, ownProps)
});

export default connect(mapStateToProps)(AnnotationList);
