/* jshint esversion: 9 */
/* @flow */
import { useEffect } from 'react';
import config from '../../config';
import setBaseUrl from  '../../utils/urlUtils'
function ImageOpener(props) {
  const { keyIndex, error, info,currentImageInfo } = props
  useEffect(() => {
    const showImage = (opacity = 1) => {
      const { baseUrl} = config;
      const image = info;
      const imgBeforeUrl = setBaseUrl(baseUrl,currentImageInfo)
      window.osdviewer.open({
        width: image.dimensions[0],
        opacity: opacity,
        tileSource: imgBeforeUrl + image.slide_url,
        pixelPerMeter: image.slide_mpp ? 1e6 / image.slide_mpp : 0
      });
    }
    showImage(error ? 0 : 1)
    return () => { }
  }, [keyIndex, error])

  return null;
}

export default ImageOpener
