import React, { Component } from 'react';
import { connect } from 'react-redux';
// import style from './index.less';
// import intl from 'react-intl-universal';
import { editUser } from '../../../actions/userActions';
import { Switch } from 'antd';
class StopDis extends Component {
  onChange(checked) {
    const { infoList } = this.props;
    this.props.editUser(infoList.id, { distributable: checked });
  }
  render() {
    const { infoList } = this.props;
    return <Switch checked={infoList.distributable} onChange={this.onChange.bind(this)} />;
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { editUser })(StopDis);
