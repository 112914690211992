import intl from 'react-intl-universal';

function fixedPercent(number) {
  return number * 100;
}

export function rawToStatistics(raw, clazz = 'cervical') {
  const statistics = [
    {
      key: 'sensitive',
      title: intl.get('SENSITIVITY'),
      value: fixedPercent(raw.cell.sensitivity)
    },
    {
      key: 'specific',
      title: intl.get('SPECIFIC'),
      value: fixedPercent(raw.cell.specific)
    }
  ];
  if (raw.cell.sensitivity_L >= 0) {
    statistics.splice(1, 0, {
      key: 'sensitive1',
      title: intl.get('SENSITIVITY_PLUS', { clazz }),
      value: fixedPercent(raw.cell.sensitivity_L)
    });
  }

  if (raw.microbe) {
    statistics.push(
      {
        key: 'mSensitivity',
        title: intl.get('M_SENSITIVITY'),
        value: fixedPercent(raw.microbe.sensitivity)
      },
      {
        key: 'mSpecific',
        title: intl.get('M_SPECIFIC'),
        value: fixedPercent(raw.microbe.specific)
      }
    );
  }

  return statistics;
}

export function sum(arr) {
  if (arr) return arr.reduce((accu, curr) => accu + curr, 0);
  else return 0;
}

export function transpose(matrix) {
  if (matrix.length >= 1 && matrix[0]) {
    return matrix[0].map((col, i) => {
      return matrix.map((row) => row[i]);
    });
  } else return [];
}
