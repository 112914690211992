import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import PrintReport from '../../components/Report/PrintReport';
import ReactToPrint from 'react-to-print';
import { getPrintResult } from '../../actions/reportAction';
import { printReport } from '../../actions/imageActions';
import { Modal, Button } from 'antd';
import style from '../../less/modal.less';
import { canRender } from '../Can';
import ConfirmContent from '../CommonComponent/ConfirmContent';
class PrintAction extends Component {
  constructor() {
    super();
    this.print = React.createRef();
    this.state = {
      report: {},
      isModalVisible: false,
      isPrintVisible: false
    };
  }
  componentDidMount() {}

  showModal = () => {
    const { image } = this.props;
    getPrintResult(image.id).then((res) => {
      this.setState({
        report: res,
        isModalVisible: true
      });
    });
  };
  handleOk = () => {};
  afterPrint = () => {
    this.setState({
      isPrintVisible: true,
      isModalVisible: false
    });
  };
  afterPrintSend = () => {
    this.setState({
      isPrintVisible: false,
      isModalVisible: false
    });
  };
  printPage = (id) => {
    this.props.printReport(id);
    this.handleCancel('isPrintVisible');
  };

  handleCancel = (name) => {
    this.setState({
      [name]: false
    });
  };
  render() {
    const { title, image, currentUser, stage } = this.props;
    const fs = { cursor: 'auto', color: '#999' };
    return (
      <>
        {title === 'preview' ? (
          canRender(currentUser.permissions, 'client:report:print') ? (
            <span style={{ cursor: 'pointer' }} onClick={this.showModal.bind(this)}>
              {intl.get('PREVIEW')}
            </span>
          ) : (
            <span style={fs}>{intl.get('PREVIEW')}</span>
          )
        ) : canRender(currentUser.permissions, 'client:report:print') && !image.rescan ? (
          <span style={{ cursor: 'pointer' }} onClick={this.showModal.bind(this)}>
            {intl.get('PRINT_REPORT')}
          </span>
        ) : (
          <span style={fs}>{intl.get('PRINT_REPORT')}</span>
        )}

        <Modal
          title={title === 'preview' ? intl.get('PREVIEW') : intl.get('PRINT_REPORT')}
          width="800px"
          visible={this.state.isModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel.bind(this, 'isModalVisible')}
          className={style.checkModal}
          footer={
            title === 'preview'
              ? ''
              : [
                  <Button key="back" onClick={this.handleCancel.bind(this, 'isModalVisible')}>
                    {intl.get('CANCEL')}
                  </Button>,
                  <ReactToPrint
                    key="submit"
                    trigger={() => <Button type="primary">{intl.get('PRINT_REPORT')}</Button>}
                    content={() => this.print.current}
                    onAfterPrint={
                      stage === 10 ? this.afterPrintSend.bind(this) : this.afterPrint.bind(this)
                    }
                  />
                ]
          }
        >
          <span ref={this.print}>
            <PrintReport report={this.state.report} />
          </span>
        </Modal>
        <Modal
          title={title === 'preview' ? intl.get('PREVIEW') : intl.get('PRINT_REPORT')}
          visible={this.state.isPrintVisible}
          className={style.checkModal}
          onCancel={this.handleCancel.bind(this, 'isPrintVisible')}
          footer={[
            <Button key="back" onClick={this.handleCancel.bind(this, 'isPrintVisible')}>
              {intl.get('NO_END')}
            </Button>,
            <Button key="submit" type="primary" onClick={this.printPage.bind(this, image.id)}>
              {intl.get('IS_END')}
            </Button>
          ]}
        >
          <ConfirmContent>
          <span>{intl.get('PRINT_WARNING')}</span>
          </ConfirmContent>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser
  };
};

export default connect(mapStateToProps, { printReport })(PrintAction);
