import { uniq, omit } from 'lodash';
import { getLinesToUserOfClass } from '../selectors/classSelectors';
const initial = { annotationsLoaded: [], status: 'Loading' , limit:16, marklimit:7};

const getNewLine = (l, change) => {
  if (l.user === change.user) return { ...l, clazz: change.new };
  else return { ...l };
};

const currentAddedAnnotationChange = (state, action) => {
  return state.annotationsLoaded.map((anno) => {
    if (anno.id === action.annotationId) {
      const lastLines = getLinesToUserOfClass(anno.lines);
      const userLine = lastLines.find((l) => l.user === action.change.user);
      const newLines = userLine
        ? lastLines.map((l) => getNewLine(l, action.change))
        : lastLines.concat({ user: action.change.user, clazz: action.change.new });
      const newClasses = newLines.map((l) => l.clazz);
      return {
        ...anno,
        users: uniq(anno.users.concat(action.change.user)),
        classes: newClasses,
        lines: newLines.map((l) => l.user + ',' + l.clazz),
        status: 'uploading'
      };
    }
    return anno;
  });
};

const currentAddedPointSet = (state, action) => {
  return state.annotationsLoaded.map((anno) => {
    if (anno.id === action.annotationId) {
      return {
        ...anno,
        points: anno.points ? anno.points.concat(action.points) : action.points
      };
    } else {
      return anno;
    }
  });
};

const currentAddedAnnotationChangeStatus = (state, action, status) => {
  return state.annotationsLoaded.map((anno) => {
    if (anno.id === action.annotationId) {
      return {
        ...anno,
        status
      };
    }
    return anno;
  });
};

const addAnnotation = (state, anno) => {
  const user = anno.changes.find((c) => true).user;
  const clazz = anno.changes.find((c) => true).new;
  const line = user + ',' + clazz;
  const annotation = {
    ...omit(anno, 'changes'),
    lines: [line],
    users: [user],
    classes: [clazz],
    status: 'uploading'
  };
  const added = state.annotationsLoaded.concat(annotation);
  return added;
};

const currentAddedAnnotationStatus = (state, action, status) => {
  return state.annotationsLoaded.map((anno) => {
    if (anno.id === action.annotation.id) {
      return {
        ...anno,
        id: status === 'success' ? action.newId : anno.id,
        status
      };
    }
    return anno;
  });
};

const annotationLoad = (state = initial, action) => {
  switch (action.type) {
    case 'LOAD_ANNOTATIONS_REQUEST':
      return {
        ...state,
        annotationsLoaded: [],
        status: 'Loading'
      };
    case 'LOAD_ANNOTATIONS_SUCCESS':
      return {
        ...state,
        annotationsLoaded: action.annotations,
        status: 'success'
      };
    case 'LOAD_ANNOTATIONS_FAILURE':
      return {
        ...state,
        annotationsLoaded: [],
        status: 'failure'
      };
    case 'ADD_ANNOTATION_CHANGE_REQUEST':
      return {
        ...state,
        annotationsLoaded: currentAddedAnnotationChange(state, action)
      };
    case 'ADD_ANNOTATION_CHANGE_SUCCESS':
      return {
        ...state,
        annotationsLoaded: currentAddedAnnotationChangeStatus(state, action, 'success')
      };
    case 'ADD_ANNOTATION_CHANGE_FAILURE':
      return {
        ...state,
        annotationsLoaded: currentAddedAnnotationChangeStatus(state, action, 'failure')
      };
    case 'ADD_POINTS_OF_POINTSET_TO_REQUEST':
      return {
        ...state,
        annotationsLoaded: currentAddedPointSet(state, action)
      };
    case 'ADD_POINTS_OF_POINTSET_TO_SUCCESS':
      return {
        ...state,
        annotationsLoaded: currentAddedAnnotationChangeStatus(state, action, 'success')
      };
    case 'ADD_POINTS_OF_POINTSET_TO_FAILURE':
      return {
        ...state,
        annotationsLoaded: currentAddedAnnotationChangeStatus(state, action, 'failure')
      };
    case 'ADD_ANNOTATION_REQUEST':
      return {
        ...state,
        annotationsLoaded: addAnnotation(state, action.annotation)
      };
    case 'ADD_ANNOTATION_SUCCESS':
      return {
        ...state,
        annotationsLoaded: currentAddedAnnotationStatus(state, action, 'success')
      };
    case 'ADD_ANNOTATION_FAILURE':
      return {
        ...state,
        annotationsLoaded: currentAddedAnnotationStatus(state, action, 'failure')
      };
    case 'UPDATE_ANNOTATION_LIMIT':
      return {
        ...state,
        limit: action.limited ? 16: -1
      }
    case 'UPDATE_MARK_ANNOTATION_LIMIT':
      return {
        ...state,
        markLimit: action.limited ? 7: -1
      }
    case 'UPDATE_ANNOTATION_INFO':
      return {
        ...state,
        annotationsLoaded: state.annotationsLoaded.map((anno) =>
          anno.id === action.annotationId ? { ...anno, ...action.update } : anno
        )
      }
    default:
      return state;
  }
};

export default annotationLoad;
