import React, { Component } from 'react';
import { connect } from 'react-redux';
import style from './index.less';
import intl from 'react-intl-universal';
import { Cascader } from 'antd';
import { getEvaluateList } from './productionInfo';
import { updateSection } from '../../actions/productionAction';
import { canRender } from '../Can';
import { isPartEnd } from '../../utils/urlUtils';
class ProEvaluation extends Component {
  constructor() {
    super();
    this.state = {};
  }

  onChange(value) {
    let ev = value.join('/');
    const { infoList } = this.props;
    this.props.updateSection(infoList.id, { evaluation: ev });
    // this.props.updateSection(infoList.id, { reproduce: true,distribute_stage:1 });
  }
  render() {
    const evaluationList = getEvaluateList();
    const { infoList, currentUser } = this.props;
    const partEnd = isPartEnd();
    return (
      <div className={style.pro_status}>
        {infoList.stage === 2 &&
        canRender(currentUser.permissions, 'section:modify') &&
        partEnd !== 'centerEnd' &&
        ([null, undefined, ''].includes(infoList.evaluation) ||
          this.props.selectedSingle?.id === infoList.id) ? (
          <Cascader
            options={evaluationList}
            onChange={this.onChange.bind(this)}
            placeholder={intl.get('PRO_EVALUE')}
            defaultValue={infoList.evaluation !== null ? infoList.evaluation.split('/') : ''}
          />
        ) : (
          <span>{infoList.evaluation}</span>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedSingle: state.production.selectedSingle,
    currentUser: state.currentUser
  };
};

export default connect(mapStateToProps, { updateSection })(ProEvaluation);
