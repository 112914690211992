import React from 'react';
import CommonPanel from '../commonPanel';
import { connect } from 'react-redux';
import DisSet from "./DisSet"
import TimeSet from './TimeSet'
function Distribution(props) {
  
  return (
    <CommonPanel>
      <DisSet />
      <br />
      <TimeSet/>
    </CommonPanel>
  );
}

const mapStateToProps = (state, ownProps) => ({});
export default connect(mapStateToProps, {})(Distribution);
