import { Tag, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import colorTable from '../../static/colorTable';
import { isInGroup } from '../../utils/imageLevelResultUtils';
import style from './index.less';

const { cervicalResultTagColorTable } = config;

function getFilterResult(params, separator, shouldBefiltered) {
  if (!shouldBefiltered) return [params];
  if (params) {
    let result = params.split(separator);
    const FILTER = config.groundTruthList['cervical'].eca.concat(
      config.groundTruthList['cervical'].organism
    );
    return FILTER.filter((item) => result.includes(item));
  } else {
    return [];
  }
}
class ImageLevelResultTag extends Component {
  static propTypes = {
    result: PropTypes.string,
    separator: PropTypes.string
  };
  static defaultProps = {
    separator: ','
  };

  hexToRgb = (hex, alpha = 0.15) => {
    const rgb = [];

    let h = hex.substr(1);

    if (h.length === 3) {
      h = h.replace(/(.)/g, '$1$1');
    }

    h.replace(/../g, function(color) {
      rgb.push(parseInt(color, 0x10));
    });

    return 'rgb(' + rgb.join(',') + ',' + alpha + ')';
  };

  getTooltipContent = (tag) => {
    if (isInGroup(tag, 1)) {
      return intl.get('TCT_' + tag);
    } else if (isInGroup(tag, 2)) {
      return intl.get('ORG_' + tag);
    } else {
      return tag;
    }
  };

  render() {
    const { result, separator, shouldBefiltered, bgStyle } = this.props;
    const filterResult = result
      ? result.includes('|')
        ? result.split(separator)
        : getFilterResult(result, separator, shouldBefiltered)
      : [];
    return filterResult.map((r) => {
      const rr = r ? r : '_';
      let color = colorTable[cervicalResultTagColorTable[rr]];
      if (!color) color = colorTable[cervicalResultTagColorTable['_']];
      const background = this.hexToRgb(color, 0.1);
      const border = this.hexToRgb(color, 0.5);
      return (
        <Tooltip key={rr} mouseEnterDelay={1} title={this.getTooltipContent(rr)}>
          {bgStyle === 'no' ? (
            <div className={style.ImageLevelResultTag} key={rr} style={{ color: color}}>
              {r}
            </div>
          ) : (
            <Tag
              className={style.ImageLevelResultTag}
              key={rr}
              style={{ color: color, backgroundColor: background, borderColor: border }}
            >
              {r}
            </Tag>
          )}
        </Tooltip>
      );
    });
  }
}

export default ImageLevelResultTag;
