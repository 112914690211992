import React, { useState } from 'react';
import styles from './ThumbnailActions.less';
import './index.less';
import { Popconfirm } from 'antd';
import PopoverIconButton from '../PopoverIconButton';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { getLinesToUserOfClass } from '../../selectors/classSelectors';
import { addReportAnnotationIds } from '../../actions/annotationSelectionActions';
import {
  removeAnnotationsOfUsers,
  addAnnotationToMaskList,
  removeAnnotationFromMaskList
} from '../../actions/annotationActions';
import { saveReportResult } from '../../actions/reportAction';
import { selectEditReportDisabled } from '../../selectors/reportSelector';
import store from '../../store';
import { isPartEnd } from '../../utils/urlUtils';

const ThumbnailActions = React.memo(function MyComponent(props) {
  const [sendIcon, setSendIcon] = useState('icon-send');
  const [flagIcon, setFlagIcon] = useState('icon-flag');
  const [shanchuIcon, setShanchuIcon] = useState('icon-shanchu');
  const {
    item,
    loginUser,
    visible,
    marked,
    reported,
    currentImageKey,
    reportAnnos,
    disabled
  } = props;
  const visibleDel =
    item.lines.includes(loginUser + ',null') || !item.users.includes(loginUser) ? false : true;
  const submitDeteletion = () => {
    const line = getLinesToUserOfClass(item.lines).find((l) => l.user === loginUser);
    const changes = [
      {
        annotationId: item.id,
        change: { user: loginUser, old: !line ? null : line.clazz, new: null }
      }
    ];
    props.removeAnnotationsOfUsers(changes);
  };

  const submitMark = () => {
    if (item && item.id) {
      if (marked) {
        reported ? submitToReport() : props.removeAnnotationFromMaskList(item.id);
      } else {
        props.addAnnotationToMaskList(item.id);
      }
    }
  };

  const submitToReport = () => {
    if (item && item.id) {
      let data = [...reportAnnos];
      if (reported) {
        if (data.length > 0) {
          let index = data.indexOf(item.id);
          data.splice(index, 1);
        }
      } else {
        data = [item.id];
        if (reportAnnos.length > 0) {
          data.unshift(reportAnnos[reportAnnos.length - 1]);
        }
      }
      props.saveReportResult(currentImageKey, { annotations: data }).then(() => {
        data.includes(item.id)
          ? props.addAnnotationToMaskList(item.id)
          : props.removeAnnotationFromMaskList(item.id);
      });
    }
  };
  const onHover = (name) => {
    name.startsWith('send') && setSendIcon('icon-' + name);
    name.startsWith('flag') && setFlagIcon('icon-' + name);
    name.startsWith('shanchu') && setShanchuIcon('icon-' + name);
  };
  const partEnd = isPartEnd();
  return (
    <div
      className={styles.action__div}
      style={{
        visibility: visible ? 'visible' : 'hidden',
        top: store.getState().config.visibleConfidence ? '2px' : '2px',
        right: '8px'
      }}
    >
      {(!disabled && partEnd !== 'centerEnd') && (
        <PopoverIconButton
          popContent={intl.get('SENT_TO_REPORT_BUTTON')}
          btnClass={styles.btn__style}
          key="sendToReport"
          color={reported ? 'blue' : 'black'}
          icon={reported ? 'icon-sendfill' : sendIcon}
          onClick={submitToReport}
          onMouseOver={onHover.bind(this, 'sendhover')}
          onMouseOut={onHover.bind(this, 'send')}
        />
      )}
      {partEnd !== 'centerEnd' && (
        <PopoverIconButton
          popContent={intl.get('MARK')}
          btnClass={styles.btn__style}
          color={marked ? 'blue' : 'black'}
          icon={marked ? 'icon-flagfill' : flagIcon}
          onClick={submitMark}
          onMouseOver={onHover.bind(this, 'flaghover')}
          onMouseOut={onHover.bind(this, 'flag')}
        />
      )}

      {visibleDel && (
        <Popconfirm
          title={intl.get('DELETE_ANNO_TITLE')}
          onConfirm={submitDeteletion}
          okText={intl.get('YES')}
          cancelText={intl.get('CANCEL')}
        >
          <PopoverIconButton
            popContent={intl.get('DELETE_BUTTON')}
            btnClass={styles.btn__style}
            key="deleteSingleAnno"
            color="black"
            icon={shanchuIcon}
            onMouseOver={onHover.bind(this, 'shanchuhover')}
            onMouseOut={onHover.bind(this, 'shanchu')}
          />
        </Popconfirm>
      )}
    </div>
  );
});

const mapStateToProps = (state, ownProps) => ({
  reportAnnos: state.report.annotations || [],
  loginUser: state.annotationUsers.loginUser,
  disabled: selectEditReportDisabled(state),
  currentImageKey: state.imageSelection.currentImageKey,
  reported:
    ownProps.item && state.report.annotations && state.report.annotations.includes(ownProps.item.id)
      ? true
      : false,
  visible:
    ownProps.item &&
    (ownProps.item.id === state.annotationSelection.currentAnnotationId ||
      ownProps.item.id === state.annotationSelection.hoveredAnnotationId)
      ? true
      : false,
  marked: ownProps.item && ownProps.item.markers && ownProps.item.markers.length > 0 ? true : false
});

export default connect(mapStateToProps, {
  removeAnnotationsOfUsers,
  addAnnotationToMaskList,
  removeAnnotationFromMaskList,
  saveReportResult,
  addReportAnnotationIds
})(ThumbnailActions);
