import io from 'socket.io-client';
import { getBaseUrlHost } from '../utils/apiUtils';

export function receiveProgress(message) {
  return {
    type: 'RECEIVE_PGS',
    message
  };
}

export function removeInProgresses(filename) {
  return {
    type: 'REMOVE_IN_PROGRESSES',
    filename
  };
}

export function remainImagesInProgress(filenames) {
  return {
    type: 'REMAIN_IN_PROGRESSES',
    filenames
  };
}

export function resetImagesProgress(){
  return {
    type: "RESET_IMAGES_PROGRESSES",
  }
}
function initIO(dispatch) {
  if (!window.socket) {
    const addr = getBaseUrlHost();
    window.socket = io(`ws://${addr}/`, {
      path: '/api/models_socket.io',
      transports: ['websocket', 'polling']
    });
    window.socket.on('progress', (message) => {
      dispatch(receiveProgress(JSON.parse(message)));
    });
  }
}


export function initializeIO() {
  return async (dispatch) => {
    initIO(dispatch);
  };
}

export function destroyIO() {
  if (window.socket) {
    window.socket.close();
    window.socket = null;
  }
}
