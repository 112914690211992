import React, { Component } from 'react';
import style from './index.less';
import PositiveRate from './PositiveRate';
// import ChinaMap from './ChinaMap';
// import QualityControl from './QualityControl';
import Consistency from './Consistency';
import AlgoPerformance from './AlgoPerformance';
import LesionDistribution from './LesionDistribution';
import AnnualData from './AnnualData';
import DataCard from './DataCard';
import BeautifulTable from './BeautifulTable';
import DistributionBar from './DistributionBar';
import IncidenceRate from './IncidenceRate';
import { connect } from 'react-redux';
import { initStatis } from '../../actions/statistics';
import { Col, Row } from 'antd';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router-dom';
class Visualization extends Component {
  componentDidMount() {
    this.props.initStatis();
  }
  render() {
    // const { positive, summary, consistency, quality, workload, distribution, performance } = this.props.statistics
    const {
      summary,
      workload,
      positive,
      consistency,
      distribution,
      performance,
      qualityMonth,
      incidence,
      selected
    } = this.props.statistics;
    const grid = {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
      lg: { span: 24 },
      xl: { span: 12 }
    };
    return (
      <div className={style.visualization__page}>
        <DataCard data={summary} selected={selected} />
        <Row gutter={10}>
          <Col span={15}>
            <BeautifulTable data={summary} history={this.props.history} />
            <AnnualData
              data={workload}
              parentName="visualization"
              otherStyle={{ xyColor: '#333', height: '221px' }}
              series={{ itemStyle: { color: 'rgba(42,157,255,1)' } }}
            />
            <Row gutter={10}>
              <Col span={12}>
                <PositiveRate
                  data={positive}
                  parentName="visualization"
                  otherStyle={{ xyColor: '#333', height: '204px' }}
                  series={{
                    type: 'line',
                    itemStyle: {
                      width:5,
                      color: '#409EFF',
                      borderColor: 'ECF5FF',
                      borderWidth: 3
                    }
                  }}
                />
              </Col>
              <Col span={12}>
                <Consistency
                  data={consistency}
                  series={{ chartType: 'bar', symbol: 'rectangle', itemsStyle: {} }}
                  otherStyle={{ xyColor: '#333', height: '204px' }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={9}>
            <Row gutter={10}>
              <Col {...grid}>
                <LesionDistribution
                  title={intl.get('CERVICAL_LESIONS_DISTRIBUTION')}
                  data={distribution.ai_endothelial}
                  parentName="visualization"
                  otherStyle={{ xyColor: '#333', height: '245px' }}
                />
              </Col>
              <Col {...grid}>
                <LesionDistribution
                  title={intl.get('CERVICAL_MICROBIOLOGY_DISTRIBUTION')}
                  data={distribution.ai_microbe}
                  parentName="visualization"
                  otherStyle={{ xyColor: '#333', height: '245px' }}
                />
              </Col>
            </Row>
            <Row gutter={10}>
              <Col {...grid}>
                <AlgoPerformance data={performance} parentName="visualization" height="260px" />
              </Col>
              <Col {...grid}>
                <DistributionBar
                  data={qualityMonth}
                  parentName="visualization"
                  otherStyle={{ xyColor: '#333', height: '260px' }}
                  series={{
                    background: {},
                    itemStyle: {}
                  }}
                />
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <IncidenceRate
                  data={incidence}
                  parentName="visualization"
                  otherStyle={{ xyColor: '#333', height: '250px' }}
                  series={{
                    background: {},
                    itemStyle: {}
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  statistics: state.statistics
});

export default connect(mapStateToProps, {
  initStatis
})(withRouter(Visualization));
