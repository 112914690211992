import {
  Upload,
  Drawer,
  Col,
  Row,
  Checkbox,
  Card,
  Descriptions,
  Radio,
  Icon,
  message,
  Cascader,
  Select
} from 'antd';
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector,connect } from 'react-redux';
import intl from 'react-intl-universal';
import {
  getConfigsByPlace,
  isPatientInfo,
  isFooterInfo,
  getValuesFromVisEles
} from '../../utils/reportUtils';
import styles from './report-configuration.less';
import { saveReportConf, getReportConf } from '../../actions/reportConfAction';
import { fetchDepartmentAll } from '../../actions/checkInAction';
import MoveItem from './MoveItem';
import { cityArray } from './cityData';
import ImgCrop from 'antd-img-crop';
import CustomInputComp from './CustomInput';
const { Option } = Select;
function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error(intl.get('UPLOAD_LOGO_ERROR'));
  }
  const isLtM = file.size / 1024 / 1024 < 0.5;
  if (!isLtM) {
    message.error(intl.get('UPLOAD_LOGO_SIZE_ERROR'));
  }
  return isJpgOrPng && isLtM;
}

function ReportConfiguration(props) {
  const dispatch = useDispatch();
  const reportConf = useSelector((state) => state.reportConf);
  const [visible, setVisible] = useState(false);
  const [orderKey, setOrderKey] = useState('basic_info');

  useEffect(() => {
    getReportConf()(dispatch);
    props.fetchDepartmentAll();
  }, []);

  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const customRequest = (option) => {
    const formData = new FormData();
    formData.append('files[]', option.file);
    const reader = new FileReader();
    reader.readAsDataURL(option.file);
    reader.onloadend = function(e) {
      if (e && e.target && e.target.result) {
        handleChange(e, 'logo');
        option.onSuccess();
      }
    };
  };

  const handleChange = (evt, type, isInVisArr) => {
    let val = evt.target ? evt.target.value || evt.target.result : evt;
    if (isInVisArr) {
      let { visible_elements } = reportConf;
      let idx = visible_elements.findIndex((ele) => ele === type);
      if (idx > -1) {
        visible_elements.splice(idx, 1);
      } else {
        let patientInfoNum = 0;
        let footerInfoNum = 0;
        visible_elements.forEach((info) => {
          if (isPatientInfo(info)) {
            patientInfoNum++;
          }
          if (isFooterInfo(info)) {
            footerInfoNum++;
          }
        });

        if (isPatientInfo(type) && patientInfoNum >= 10) {
          message.warning(intl.get('MOST_SELECTED_WARNING', { n: 10 }));
          dispatch({ type: 'UPDATE_REPORT_CONF', data: reportConf });
          return;
        }

        if (isFooterInfo(type) && footerInfoNum >= 6) {
          message.warning(intl.get('MOST_SELECTED_WARNING', { n: 6 }));
          dispatch({ type: 'UPDATE_REPORT_CONF', data: reportConf });
          return;
        }

        visible_elements.push(type);
      }

      let index = visible_elements.indexOf('patient_address');
      if (index > -1) {
        visible_elements.splice(index, 1);
        visible_elements.push('patient_address');
      }
      
      saveReportConf({ visible_elements: Array.from(new Set(visible_elements)) })(dispatch);
      // dispatch({ type: 'UPDATE_REPORT_CONF',data:reportConf})
    } else {
      saveReportConf({ [type]: val })(dispatch);
      // dispatch({ type: 'UPDATE_REPORT_CONF',data:{[type]:val}})
    }
  };

  const moveCard = useCallback(
    (dragItem, hoverItem) => {
      let { visible_elements } = reportConf;
      let dragIndex = visible_elements.findIndex((item) => item === dragItem.id);
      let hoverIndex = visible_elements.findIndex((item) => item === hoverItem.id);
      visible_elements.splice(dragIndex, 1);
      visible_elements.splice(hoverIndex, 0, dragItem.id);
      saveReportConf({ visible_elements: Array.from(new Set(visible_elements)) })(dispatch);
    },
    [reportConf.visible_elements]
  );

  const orderLayout = (oKey) => {
    const { visible_elements } = reportConf;
    let info = getValuesFromVisEles(visible_elements)[oKey];
    return (
      <ul>
        {info.map((item, index) => {
          return (
            <li key={item}>
              <MoveItem id={item} index={index} moveCard={moveCard}>
                {intl.get(item.toUpperCase(), { name: '' })}
              </MoveItem>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <Card
      className={styles.report__configuration}
      title={intl.get('REPORT_SETTING')}
      extra={
        <a className="ant-dropdown-link" onClick={() => setVisible(true)}>
          {intl.get('SORT')}
        </a>
      }
    >
      <Descriptions title={intl.get('REPORT_MODE')}>
        <Descriptions.Item span={3}>
          <Radio.Group value={reportConf.mode}>
            {getConfigsByPlace('mode').map((m) => (
              <Radio
                onChange={(evt) => handleChange(evt, 'mode')}
                value={m.key}
                key={m.key}
                style={{ lineHeight: '32px' }}
              >
                {intl.get(m.key.toUpperCase())}
              </Radio>
            ))}
          </Radio.Group>
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title={intl.get('REPORT_HEADER_INFO')}>
        {getConfigsByPlace('header').map((m) => (
          <Descriptions.Item
            span={m.span ? m.span : 1}
            label={intl.get(m.key.toUpperCase())}
            key={m.key}
          >
            {m.method === 'upload' ? (
              <div className={styles.logo_div}>
                <ImgCrop beforeCrop={beforeUpload}>
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    customRequest={customRequest}
                    className={styles.logo__upload}
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                  >
                    {reportConf.logo ? (
                      <img src={reportConf.logo} alt="avatar" style={{ width: '100%' }} />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </ImgCrop>{' '}
                <Icon
                  type="delete"
                  className={styles.logo_del}
                  onClick={() => {
                    handleChange('', 'logo');
                  }}
                />
              </div>
            ) : m.method === 'checkbox' ? (
              <Checkbox
                onChange={(evt) => handleChange(evt, m.key, true)}
                checked={reportConf.visible_elements.includes(m.key)}
                style={{ lineHeight: '32px' }}
              />
            ) : m.method === 'cascader' ? (
              <Cascader
                options={cityArray}
                value={reportConf[m.key].split('-')}
                onChange={(evt) => handleChange(evt.join('-'), m.key, false)}
                placeholder=""
                style={{width:'300px'}}
              />
            ) : m.method === 'select' ? (
              <Select
                placeholder=""
                value={reportConf[m.key]}
                onChange={(evt) => handleChange(evt, m.key)}
                style={{width:'300px'}}
              >
                {props.organizationList.map((o) => {
                  return <Option key={o.label} value={o.label}>{o.label}</Option>;
                })}
              </Select>
            ) : (
              <CustomInputComp
                maxLength={16}
                value={reportConf[m.key]}
                onChange={(evt) => handleChange(evt, m.key)}
              />
            )}
          </Descriptions.Item>
        ))}
      </Descriptions>

      <Descriptions title={intl.get('REPORT_BASIC_INFO')}>
        <Row>
          {getConfigsByPlace('basic_info').map((m, index) => (
            <Col span={6} key={m.key}>
              <Checkbox
                onChange={(evt) => handleChange(evt, m.key, true)}
                checked={reportConf.visible_elements.includes(m.key)}
                style={{ lineHeight: '32px' }}
              >
                {intl.get(m.key.toUpperCase(), { name: '' })}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Descriptions>

      <Descriptions title={intl.get('REPORT_FOOTER_INFO')}>
        <Row>
          {getConfigsByPlace('footer').map((m) => (
            <Col span={6} key={m.key}>
              <Checkbox
                onChange={(evt) => handleChange(evt, m.key, true)}
                checked={reportConf.visible_elements.includes(m.key)}
                style={{ lineHeight: '32px' }}
              >
                {intl.get(m.key.toUpperCase())}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Descriptions>

      <Drawer
        title={intl.get('LAYOUT_CHANGE')}
        placement="right"
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
        getContainer={false}
        style={{ position: 'absolute' }}
      >
        <Radio.Group
          defaultValue={orderKey}
          onChange={(evt) => setOrderKey(evt.target.value)}
          buttonStyle="solid"
        >
          <Radio.Button value="basic_info">{intl.get('REPORT_BASIC_INFO')}</Radio.Button>
          <Radio.Button value="footer_info">{intl.get('REPORT_FOOTER_INFO')}</Radio.Button>
        </Radio.Group>
        <br />
        <br />
        {orderLayout(orderKey)}
      </Drawer>
    </Card>
  );
}
const mapStateToProps = (state) => ({
  organizationList: state.settings.organization.organizationList
});

export default connect(mapStateToProps,{fetchDepartmentAll})(ReportConfiguration);
