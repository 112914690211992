import React from 'react';
import IncidenceRatePla from '../Visualization/IncidenceRatePla';
import CommonPanel from './CommonPanel';
import intl from 'react-intl-universal';
import { fontColor } from './dataInfo';

function AgeData(props){
  const { data } = props;
  return (
    <CommonPanel name={intl.get('POSITIVE_AGE')} height="33%">
      <IncidenceRatePla data={data} parentName='dataPlatform'  otherStyle={{ xyColor: fontColor,height:'90%' }}/>
    </CommonPanel>
  );
}

export default AgeData;
