import React from 'react';
import getTctMapping from '../../static/tctMapping';

export default function HelpPanel() {
  const tctMapping = getTctMapping();
  return (
    <div>
      {Object.keys(tctMapping).map((item) => (
        <div key={item}>{`${item} -- ${tctMapping[item]}`}</div>
      ))}
    </div>
  );
}
