import React, { useCallback } from 'react';
import intl from 'react-intl-universal';
import { useDispatch } from 'react-redux';
import { toggleHelp } from '../../actions/setting';
import Icon from '../Iconfont';
import style from './helpBtn.less';

const HelpBtn = () => {
  const dispatch = useDispatch();
  const onClick = useCallback(() => dispatch(toggleHelp(true)), [dispatch]);

  return (
    <span className={style.helpBtn} onClick={onClick}>
      <Icon type="question-circle" className={style.icon} />
      <span>{intl.get('HELP')}</span>
    </span>
  );
};

export default HelpBtn;
