import React from 'react';
import AnnualData from '../Visualization/AnnualData';
import CommonPanel from './CommonPanel';
import intl from 'react-intl-universal';
import { fontColor } from './dataInfo';
function AnnualWork(props){
  const { data } = props;
  return (
    <CommonPanel name={intl.get('ANNUAL_WORKLOAD')}>
      <AnnualData
        data={data}
        parentName="dataPlatform"
        otherStyle={{ xyColor: fontColor,height:'90%' }}
        series={{ itemStyle: {color:"rgba(42,157,255,0.4)", borderWidth:2, borderColor: 'rgba(42,157,255,1)' } }}
      />
    </CommonPanel>
  );
}

export default AnnualWork;
