import { Card, Radio, InputNumber, message, Row, Col } from 'antd';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { fetchGeneralConfig, updateGeneralConfig } from '../../actions/configActions';
import { connect } from 'react-redux';
import styles from './SystemMain.less';
import { debounce } from 'lodash';

function TagIdSource(props) {
  const [val, setVal] = useState(props.general.pathology_id_source);
  const [left, setLeftVal] = useState(props.general.pathology_id_left);
  const [right, setRightVal] = useState(props.general.pathology_id_right);

  
  const onChange = (e) => {
    props.updateGeneralConfig({ pathology_id_source: e.target.value }).then((res) => {
      if(res){
        setVal(e.target.value);
      }
    });
  };
  const onLeftchange = (data) => {
    props.updateGeneralConfig({ pathology_id_left: data }).then((res) => {
      if(res){
      message.success(intl.get('MARK_MODIFY_SUCCESS'));
      setLeftVal(data);
      }
    });
  };
  const onRightchange = (data) => {
    props.updateGeneralConfig({ pathology_id_right: data }).then((res) => {
      if(res){
        message.success(intl.get('MARK_MODIFY_SUCCESS'));
        setRightVal(data);
      }
    });
  };

  const limitDecimals = (value) => {
    return value.replace(/\D+/g, '');
    // return value.replace(/[^0-9\-\+]/g,'');
  };

  return (
    <Card title={intl.get('PATHLOGY_ID_FROM')}>
      <Row>
        <Col span={24}>
          <div className={styles.item__div}>
            <Radio.Group onChange={onChange} value={val}>
              <Radio value="filename">{intl.get('FROM_FILENAME')}</Radio>
              {val === 'filename' ? (
                <span>
                  从第&nbsp;&nbsp;
                  <InputNumber
                    className={styles.item_input}
                    formatter={limitDecimals}
                    parser={limitDecimals}
                    value={left}
                    onChange={debounce(onLeftchange, 500)}
                  />
                  &nbsp;&nbsp;位到第&nbsp;&nbsp;
                  <InputNumber
                    className={styles.item_input}
                    formatter={limitDecimals}
                    parser={limitDecimals}
                    value={right}
                    onChange={debounce(onRightchange, 500)}
                  />
                  &nbsp;&nbsp;位
                </span>
              ) : (
                ''
              )}
              <br />
              <Radio value="ocr">{intl.get('FROM_OCR')}</Radio>
            </Radio.Group>
          </div>
        </Col>
      </Row>
    </Card>
  );
}

TagIdSource.propTypes = {};
const mapStateToProps = (state, ownProps) => ({
  general: state.config.general
});

export default connect(mapStateToProps, {
  fetchGeneralConfig,
  updateGeneralConfig
})(TagIdSource);
