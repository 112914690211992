import React, { Component } from 'react';
import { connect } from 'react-redux';
import Visualization from '../../Visualization';
class Mintor extends Component {
  render() {
    return ( <Visualization />
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(Mintor);
