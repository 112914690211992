import { Modal } from 'antd';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleHelp } from '../../actions/setting';
import { getHelpModalVisible } from '../../selectors/settingSelectors';
import HelpPanel from './HelpPanel';

const HelpModal = () => {
  const visible = useSelector(getHelpModalVisible);
  const dispatch = useDispatch();
  const onClose = useCallback(() => dispatch(toggleHelp(false)), [dispatch]);

  const prop = {
    visible,
    footer: null,
    destroyOnClose: true,
    onCancel: onClose
  };

  return (
    <Modal {...prop}>
      <HelpPanel />
    </Modal>
  );
};

export default HelpModal;
