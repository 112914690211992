import { pick } from 'lodash';
import config from '../config';
import request, { getBaseUrlHost } from '../utils/apiUtils';
import setBaseUrl, { getNodeMode } from '../utils/urlUtils';
import { getCurrentImageInfo } from '../selectors';
import store from '../store';
export function addAnnotationWithChanges(anno, image) {
  const currentImageInfo = getCurrentImageInfo(store.getState());
  const imgBeforeUrl = setBaseUrl(config.baseUrl, currentImageInfo);
  const annotation = {
    ...pick(
      anno,
      'name',
      'px',
      'py',
      'x_start',
      'y_start',
      'x_range',
      'y_range',
      'size',
      'prob',
      'is_koilocyte',
      'nc_size',
      'nc_ratio',
      'type',
      'points'
    ),
    changes: anno.changes.map((c) => {
      return pick(c, 'old', 'new', 'user');
    })
  };

  return request(
    getNodeMode() === 1 ? imgBeforeUrl + '/api/annotation' :'/annotation',
    {
      method: 'POST',
      data: { ...annotation, image }
    }
  );
}

export function addAnnotationChangeToServer(change, annotation_id) {
  return request(`/annotation/${annotation_id}`, {
    method: 'PUT',
    data: { ...pick(change, 'user', 'old', 'new') }
  });
}

export function apiAddAnnotationToMaskList(annotation_id) {
  return request(`/annotation/${annotation_id}/mark`, {
    method: 'PUT'
  });
}
export function apiRemoveAnnotationFromMaskList(annotation_id) {
  return request(`/annotation/${annotation_id}/mark`, {
    method: 'DELETE'
  });
}

export function addPointsOfPointSetToServer(points, annotation_id) {
  return request(`/annotation/${annotation_id}/points`, {
    method: 'POST',
    data: { points }
  });
}

export function loadAnnotationsFromServer(imageId) {
  return request('/annotation', {
    params: {
      image_id: imageId,
      limit: 1000
    }
  });
}

export function loadImageVisualInfoFromServer(filename) {
  return request('/deepzoom/load', {
    method: 'POST',
    data: { filename }
  });
}

export function getUserInfoFromServer(userId) {
  return request(`/user/${userId}`, { method: 'GET' });
}

export function fetchSlideAndPathologyID(imageId) {
  return request(`/image/tag/${imageId}`, { method: 'GET' });
}

export function fetchTagIdValidation(slide_id) {
  return request('/authorizer', {
    method: 'POST',
    data: {
      taskType: 'verify',
      serialString: slide_id,
      machineKey: 'None'
    }
  });
}

export function fetchDetection(image, type) {
  return request('/detection/trigger', {
    method: 'POST',
    data: {
      image: image.filename,
      detector_type: type,
      trigger_by: 'click'
    }
  });
}

export function getDetectingImageName() {
  return request('/image/detecting-list', { method: 'GET' });
}

export function getCounting(queryInfo) {
  return request('/image/count', { method: 'GET', params: queryInfo });
}

export function getImages(queryInfo) {
  return request('/image', { method: 'GET', params: queryInfo });
}
export function apiFetchImageInfo(imageId) {
  return request(`/image`, { method: 'GET', params: { imageId } });
}

export function getImageById(imageId) {
  return request('/image', { method: 'GET', params: { imageId: imageId } });
}

export function fetchCsv(queryInfo, selectedImageIds) {
  return request('/image/csv', {
    method: 'POST',
    params: queryInfo,
    data: { selectedImages: selectedImageIds },
    timeout: 2000000
  });
}

export function exportList(queryInfo) {
  return request('/data/export', {
    method: 'GET',
    params: queryInfo,
    timeout: 2000000,
    responseType: 'blob'
  });
}

export function fetchDeleteImages(queryInfo, selectedImageIds) {
  return request('/image', {
    method: 'DELETE',
    params: queryInfo,
    data: { selectedImages: selectedImageIds }
  });
}

export function fetchUpdateImage(imageId, option) {
  return request(`/image/${imageId}`, { method: 'PUT', data: { ...option } });
}

export function getCollectionImageAPI(image) {
  return request(`/collection/${image}`, { method: 'GET' });
}

export function collectImageAPI(image, remark) {
  return request(`/collection`, { method: 'POST', data: { remark, image } });
}

export function markImageAPI(image, remark) {
  return request(`/collection`, { method: 'PUT', data: { remark, image } });
}

export function discardImageAPI(image, remark = '') {
  return request(`/collection`, { method: 'DELETE', data: { remark, image } });
}

export function addUploadedImageToDatabase(info) {
  return request('/image', { method: 'POST', data: { ...info } });
}

export function fetchWsi(position) {
  return request('/devops/wsi', { method: 'GET', params: { position } });
}

export function fetchDiskUsage(position) {
  return request('/devops/disk', { method: 'GET', params: { position } });
}

export function cleanDisk(option) {
  return request('/data/clean', { method: 'POST', data: { ...option } });
}

export function fetchUploadImage(option) {
  return request('/image', { method: 'POST', data: { ...option } });
}

export function fetchBatchTriggerDetection(option) {
  return request('/detection/batch-trigger', { method: 'POST', data: { ...option } });
}

export function triggerDetectionOfImage(option) {
  return request('/detection/trigger', { method: 'POST', data: { ...option } });
}

export function fetchUpdateUser(userId, option) {
  const valid = pick(option, ['email', 'realname']);
  return request(`/user/${userId}`, { method: 'PUT', data: { ...valid } });
}

export function fetchUpdatePassword(option) {
  return request('/user/pwd', { method: 'POST', data: option });
}

export function fetchUpdateAdminKey(option) {
  return request('/user/admin-key', { method: 'POST', data: option });
}

export function fetchSaveTabs(userId, tabs) {
  return request(`/user-config/${userId}`, { method: 'POST', data: tabs });
}

export function fetchTabs(userId) {
  return request(`/user-config/${userId}`, { method: 'GET' });
}

export function fetchRestartPlatform() {
  const host = getBaseUrlHost();
  return request(`http://${host}:8616/`, {
    method: 'POST',
    data: { restart_pass_phrase: config.restart_pass_phrase }
  });
}

export function fetchExportAnalysisResult(payload) {
  return request('/analysis/csv', {
    method: 'GET',
    params: payload
  });
}

export function fetchAnalysisResult(payload) {
  return request('/analysis', { method: 'GET', timeout: 1200000, params: payload });
}

export function fetchUpdateSambaPass(option) {
  return request('/user/samba', { method: 'POST', data: option });
}

export function fetchSaveReportConf(data) {
  return request('/configuration/report', { method: 'PUT', data: data });
}

export function fetchReportConf() {
  return request('/configuration/report', { method: 'GET' });
}

export function fetchUserConfigurations(userId) {
  return request(`/configuration/${userId}`, { method: 'GET' });
}

export function updateUserConfigurations(userId, data) {
  return request(`/configuration/${userId}`, { method: 'PUT', data: data });
}

export function fetchReportResult(imageId) {
  return request(`/report/${imageId}`, { method: 'GET' });
}

export function putReportResult(imageId, data) {
  return request(`/report/${imageId}`, { method: 'PUT', data: data });
}

export function deleteReportResult(imageId, data) {
  return request(`/report/${imageId}`, { method: 'DELETE', data: data });
}

export function postReportResult(imageId, data) {
  return request(`/report/${imageId}`, { method: 'POST', data: data });
}

export function pushReportResult(imageId) {
  return request(`/report/push/${imageId}`, { method: 'POST' });
}

export function fetchStatisConsistency(data) {
  return request(`/statistics/consistency?${data}`, { method: 'GET' });
}

export function fetchStatisDistribution(data) {
  return request(`/statistics/distribution?${data}`, { method: 'GET' });
}

export function fetchStatisPerformance(data) {
  return request(`/statistics/performance?${data}`, { method: 'GET' });
}

export function fetchStatisPositive(data) {
  return request(`/statistics/positive?${data}`, { method: 'GET' });
}

export function fetchStatisQuality(data) {
  return request(`/statistics/quality?${data}`, { method: 'GET' });
}

export function fetchStatisSummary(data) {
  return request(`/statistics/summary`, { method: 'GET', params: data });
}

export function fetchStatisWorkSummary() {
  return request(`/statistics/workload/summary`, { method: 'GET' });
}

export function fetchStatisWorkUser(time) {
  return request(`/statistics/workload/user`, { method: 'GET', params: time });
}

export function fetchStatisWorkload(data) {
  return request(`/statistics/workload?${data}`, { method: 'GET' });
}

export function apiPostPipeline(imageId, data) {
  return request(`/pipeline/${imageId}`, { method: 'POST', data: data });
}
export function apiFetchPipeline(imageId) {
  return request(`/pipeline/${imageId}`, { method: 'GET' });
}
export default addAnnotationWithChanges;

export function apiFetchComments(imageId) {
  return request(`/chat/${imageId}`, { method: 'GET' });
}
export function apiCreateComment(imageId, data) {
  return request(`/chat/${imageId}`, { method: 'POST', data: data });
}
export function apiRemoveComment(imageId, commentId) {
  return request(`/chat/${imageId}/${commentId}`, { method: 'DELETE' });
}

export function apiFetchService() {
  return request('/group/3rd', { method: 'GET' });
}

export function apiFetchHisService() {
  return request('/group/his-view', { method: 'GET' });
}

export function apiActivateService(serviceId) {
  return request(`/group/activate/${serviceId}`, { method: 'POST' });
}
export function apiUpdateService(serviceId, data) {
  return request(`/group/${serviceId}`, { method: 'PUT', data: data });
}

//获取基础配置
export function apiFetchGeneralConfig() {
  return request('/configuration/general', { method: 'GET' });
}
//修改配置
export function apiUpdateGeneralConfig(data) {
  return request('/configuration/general', { method: 'PUT', data });
}

export function apiUserAll() {
  return request('/user/all', { method: 'GET' });
}

export function apiPipelineEdit(data) {
  return request('/pipeline/editor', { method: 'POST', data: data });
}

export function apiPipelineReEdit(Id, data) {
  return request(`/pipeline/editor/${Id}`, { method: 'POST', data: data });
}

export function apiPipelineEditing(imageId) {
  return request(`/pipeline/editing/${imageId}`, { method: 'POST' });
}

export function apiPipelineEdited(imageId) {
  return request(`/pipeline/edited/${imageId}`, { method: 'POST' });
}

//批量完成编辑
export function apiBatchPipelineEdited(data) {
  return request(`/pipeline/edited`, { method: 'POST', data: data });
}
//超时标记已读
export function apiNoteImage(imageId) {
  return request(`/image/noted/${imageId}`, { method: 'PUT' });
}
//批量推送报告
export function apiBatchPushReport(data) {
  return request(`/report/push`, { method: 'POST', data: data });
}

/*check*********************************************************** */

export function apifetchPathologyId() {
  return request(`/registration/next`, { method: 'GET' });
}

export function apifetchCheckInfoByApplyId(applyId) {
  return request(`/registration/pull/${applyId}`, { method: 'GET' });
}

export function apiRegistration(data) {
  return request(`/registration`, { method: 'POST', data: data });
}

export function apifetchGetCheckInfoList(queryInfo) {
  return request(`/registration`, { method: 'GET', params: queryInfo });
}

export function apiBackCheckInfo(registrationId) {
  return request(`/registration/${registrationId}`, { method: 'DELETE' });
}

export function apifetchCheckInfoListCount(queryInfo) {
  return request(`/registration/count`, { method: 'GET', params: queryInfo });
}

export function apiUpdateRegistration(registrationId, data) {
  return request(`/registration/${registrationId}`, { method: 'POST', data: data });
}

export function apifetchDepartment() {
  return request(`/department`, { method: 'GET' });
}
//新增机构、科室或医生（下一级）
export function apiAddDepartment(id, data) {
  return request(`/department/${id}`, { method: 'POST', data: data });
}

//新增机构、科室或医生（同级）
export function apiAddSibDepartment(id, data) {
  return request(`/department/sibling/${id}`, { method: 'POST', data: data });
}
//删除机构、科室或医生
export function apiDelDepartment(id) {
  return request(`/department/${id}`, { method: 'DELETE' });
}
//修改机构、科室或医生
export function apiEditDepartment(id, data) {
  return request(`/department/${id}`, { method: 'PUT', data: data });
}

//批量删除机构、科室或医生
export function apiBatchDelDepartment(data) {
  return request(`/department`, { method: 'DELETE', data: data });
}

//批量新增机构、科室或医生
export function apiBatchAddDepartment(data) {
  return request(`/department`, { method: 'POST', data: data });
}

/**production************************************************************ */

export function apifetchProductionList(queryInfo) {
  return request(`/section`, { method: 'GET', params: queryInfo });
}

export function apifetchProductionListCount(queryInfo) {
  return request(`/section/count`, { method: 'GET', params: queryInfo });
}
//修改制片信息
export function apiUpdateSection(section_id, data) {
  return request(`/section/${section_id}`, { method: 'PUT', data: data });
}
//批量修改制片信息
export function apiUpdateBatchSection(data) {
  return request(`/section`, { method: 'PUT', data: data });
}

export function apiAddSection(section_id) {
  return request(`/section/${section_id}`, { method: 'POST' });
}

//根据角色获取用户
export function apifetchUserByRole(role_nam) {
  return request(`/user/role/${role_nam}`, { method: 'GET' });
}
//获取所用用户列表（按角色列出）
export function apifetchAllUserByRole() {
  return request(`/user/roles`, { method: 'GET' });
}

//获取所有角色用户
export function apifetchRolesUser() {
  return request(`/user/roles`, { method: 'GET' });
}

export function apifetchRole() {
  return request(`/role`, { method: 'GET' });
}

//开始制片
export function apiStartPro(section_id) {
  return request(`/pipeline/producing/${section_id}`, { method: 'POST' });
}

//批量开始制片
export function apiBatchStartPro(data) {
  return request(`/pipeline/producing`, { method: 'POST', data: data });
}

//完成制片
export function apiEndPro(section_id) {
  return request(`/pipeline/produced/${section_id}`, { method: 'POST' });
}
//批量完成制片
export function apiBatchEndPro(data) {
  return request(`/pipeline/produced`, { method: 'POST', data: data });
}
//分配制片
export function apiDistributeProducer(data) {
  return request(`/pipeline/producer`, { method: 'POST', data: data });
}

/**report***************************************************** */

//取消报告
export function apiCancelReport(image_id, data) {
  return request(`/pipeline/ban/${image_id}`, { method: 'POST', data: data });
}

//撤回报告
export function apiWithdrawReport(image_id) {
  return request(`/pipeline/recall/${image_id}`, { method: 'POST' });
}

//改派
export function apiRedistribute(image_id, data) {
  return request(`/pipeline/editor/redistribute/${image_id}`, { method: 'POST', data: data });
}

//批量改派
export function apiBatchRedistribute(data) {
  return request(`/pipeline/editor/redistribute`, { method: 'POST', data: data });
}

// 发放报告
export function apiSendReport(image_id) {
  return request(`/pipeline/sent/${image_id}`, { method: 'POST' });
}

// 批量发放报告
export function apiBatchSendReport(data) {
  return request(`/pipeline/sent`, { method: 'POST', data: data });
}

// 打印报告
export function apiPrintReport(image_id) {
  return request(`/pipeline/printed/${image_id}`, { method: 'POST' });
}

// 批量打印报告
export function apiBatchPrintReport(data) {
  return request(`/pipeline/printed`, { method: 'POST', data: data });
}

// 批量分配报告
export function apiBatchDisReport(data) {
  return request(`/pipeline/editor`, { method: 'POST', data: data });
}

// 修改图像信息
export function apiEditByImageId(image_id, data) {
  return request(`/image/${image_id}`, { method: 'PUT', data: data });
}

//批量重新检测
export function apiBatchRestore(images) {
  return request(`/detection/restore`, { method: 'POST', data: images });
}

/*statistic发病率**************************************************************** */
export function fetchStatisIncidence(data) {
  return request(`/statistics/incidence?${data}`, { method: 'GET' });
}

export function fetchQualityMonth(data) {
  return request(`/statistics/quality/month?${data}`, { method: 'GET' });
}
/*user**************************************************************** */
//注册用户
export function apiAddUser(data) {
  return request(`/user/register`, { method: 'POST', data: data });
}

//修改用户信息
export function apiEditUser(id, data) {
  return request(`/user/${id}`, { method: 'PUT', data: data });
}

//修改其他用户信息
export function apiEditOtherUser(id, data) {
  return request(`/user/${id}`, { method: 'POST', data: data });
}

//获取单位
export function apiGetmachine(data) {
  return request(`/group/machine`, { method: 'GET' });
}

//获取用户
export function apiGetUserByQueryInfo(queryInfo) {
  return request(`/user`, { method: 'GET', params: queryInfo });
}

//根据id用户信息
export function apiGetUserById(id) {
  return request(`/user/${id}`, { method: 'GET' });
}

//删除用户
export function apiDelUserById(id) {
  return request(`/user/${id}`, { method: 'DELETE' });
}

//批量删除用户
export function apiBatchcDelUser(users) {
  return request(`/user`, { method: 'DELETE', data: users });
}

//第三方监控数据
export function apiGetMinitor() {
  return request(`/third-part/center/platform/monitor`, { method: 'GET' });
}
//发病率年龄段分布

export function apiGetIncidenceAll() {
  return request(`/statistics/incidence/all`, { method: 'GET' });
}


//获取本节点的申请（中心、单机）
export function apiGetSwarm() {
  return request(`/swarm`, { method: 'GET' });
}

//申请加入远端节点（单机）
export function apiApplyJoinSwarm(data) {
  return request(`/swarm`, { method: 'POST', data: data });
}

//允许加入或离开本节点(中心端)
export function apiUpdateSwarmBymachineId(machine_id) {
  return request(`/swarm/${machine_id}`, { method: 'PUT'});
}

//申请离开远端节点（中心端）
export function apiDeleteSwarmBymachineId(machine_id) {
  return request(`/swarm/${machine_id}`, { method: 'DELETE'});
}
