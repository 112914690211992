import { Row, Col, Spin, Tooltip } from 'antd';
import intl from 'react-intl-universal';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.less';
import { isPatientInfo, getGender, getIsMEnd, getSampleType } from '../../utils/reportUtils';
import formatTimestamp from '../../utils/dateUtils';
import {setAgeVal} from '../../utils/dataUtils';

const ColorBlueList = ['name', 'gender', 'age'];
class PatientInfo extends Component {



  getTransText = (text, key) => {
    if (['last_m'].includes(key)) return text && text.split('T') ? text.split('T')[0] : '';
    if (['is_m_end'].includes(key)) return getIsMEnd(text);
    if (['gender'].includes(key)) return getGender(text);
    if (['age'].includes(key)) return setAgeVal(text);
    if (['sample_type'].includes(key)) return getSampleType(this.props.sample[key]);
    if (
      this.props.sample.hasOwnProperty(key) &&
      ['sample_sent_date', 'sample_recevie_date'].includes(key)
    )
      return formatTimestamp(this.props.sample[key], 'YYYY-MM-DD');
    if (this.props.sample.hasOwnProperty(key)) return this.props.sample[key];
    return text;
  };

  onChange = (checked, name) => {
    let patient = { ...this.props.patient };
    if (patient.clinic_history) {
      let idx = patient.clinic_history.findIndex((item) => item === name);
      if (idx === -1) {
        patient.clinic_history.push(name);
      } else {
        patient.clinic_history.splice(idx, 1);
      }
    } else {
      patient = { clinic_history: [name] };
    }
    this.props.onChange({
      patient
    });
  };

  render() {
    const { visible_elements } = this.props.reportConf;
    const { patient, editing } = this.props;
    return (
      <div className={styles.printing}>
        <Spin spinning={editing === 'loading'}>
          <Row className={styles.row}>
            {visible_elements.map((item) => {
              return isPatientInfo(item) && item !== 'clinic_diagnosis' ? (
                <span
                  key={item}
                  style={{ color: ColorBlueList.includes(item) ? '#169BD5' : 'black' }}
                >
                  <Col className={styles.col} span={item==="patient_address"?24:8}>
                    <Row>
                      <Col className={styles.col} span={item==="patient_address"?3:8}>
                        {intl.get(item.toUpperCase(), { name: '' }) + ':'}
                      </Col>
                      <Col className={styles.col}  span={item==="patient_address"?21:16}>
                        {this.getTransText(patient[item], item) ? (
                          <Tooltip title="">
                            <p className={styles.printText}>
                              {this.getTransText(patient[item], item)}
                            </p>
                          </Tooltip>
                        ) : (
                          <p className={styles.printText} />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </span>
              ) : null;
            })}
          </Row>

          {visible_elements.includes('clinic_diagnosis') ? (
            <Row>
              <Col span={3}>{intl.get('CLINIC_DIAGNOSIS') + ':'}</Col>
              <Col span={18}>
                <Row>
                  {patient.clinic_diagnosis}
                  {/* <Checkbox
                    onChange={(evt) => this.onChange(evt.target.checked, 'radiotherapy')}
                    checked={
                      patient.clinic_history
                        ? patient.clinic_history.includes('radiotherapy')
                        : false
                    }
                  >
                    {intl.get('REPORT_RADIOLOGY')}
                  </Checkbox>
                  <Checkbox
                    onChange={(evt) => this.onChange(evt.target.checked, 'chemotherapy')}
                    checked={
                      patient.clinic_history
                        ? patient.clinic_history.includes('chemotherapy')
                        : false
                    }
                  >
                    {intl.get('REPORT_CHEMICAL')}
                  </Checkbox>
                  <Checkbox
                    onChange={(evt) => this.onChange(evt.target.checked, 'hysterectomy')}
                    checked={
                      patient.clinic_history
                        ? patient.clinic_history.includes('hysterectomy')
                        : false
                    }
                  >
                    {intl.get('REPORT_CUT')}
                  </Checkbox> */}
                </Row>
              </Col>
            </Row>
          ) : null}

        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentImageInfo: state.imageSelection.currentImageInfo,
  sample: state.report.sample,
});

export default connect(mapStateToProps, {  })(PatientInfo);
