import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getLoginUser } from '../../selectors';
import { getCurrentUser } from '../../selectors/userSelectors';
import Logo from '../Logo';
import style from './index.less';
import Messages from './Messages';
import UserDropdown from './UserDropdown';
import ShareBtn from './ShareBtn';
import Collection from './Collection';
import SearchBox from './SearchBox';
import BellBtn from './BellBtn';
import Can from '../Can';
import PageTab from './PageTab';
import { clearCurrentUser ,refreshCurrentUser} from '../../actions/userActions';
import { logout } from '../../utils/authUtils';
import { clearTimeout, setTimeoutLabel } from '../../actions/timeoutAction';
import {setActionDisabled} from "../../utils/urlUtils"
// import CheckInfoBtn from "./CheckInfoBtn"
class Menubar extends Component {
  static propTypes = {
    history: PropTypes.object
  };
  componentDidMount() {
    window.addEventListener('storage', (res) => this.toLogin(res));
    const sta = localStorage.getItem('refresh_token');
    if(sta){
      this.props.refreshCurrentUser() 
    }
   
  }

  componentWillUnmount() {
    window.removeEventListener('storage', (res) => this.toLogin(res));
    this.setState = ()=>false;
  }

  toLogin(res) {
    if (res.key === 'refresh_token') {
      if ([null, undefined, ''].includes(res.newValue)) {
        logout().then(() => {
          this.props.clearCurrentUser();
          this.props.clearTimeout();
          this.props.setTimeoutLabel(false);
          window.location.href = '/login';
        });
      }
    }
  }
  render() {
    const { currentUser } = this.props;
    const disStyle =setActionDisabled()
    return (
      <div className={style.menubar}>
        <div className={style.left__div}>
          <Logo history={this.props.history} />
        </div>
        <div className={style.center__div}>
          <Messages />
          {this.props.showToolbox === true ? (
            <SearchBox />
          ) : window.location.pathname.includes('/mainpage') ? (
            <PageTab />
          ) : (
            ''
          )}
        </div>
        <div className={style.right__div}>
          {this.props.showToolbox === true ? (
            <>
              {/* <div className={style.right__div__item}>
            <CheckInfoBtn />
              </div> */}

              <div className={style.right__div__item} >
                <ShareBtn />
              </div>
              <div className={style.right__div__item} style={disStyle}>
                <Collection />
              </div>
            </>
          ) : null}
          <div className={style.right__div__item}>
            {window.location.pathname === '/mainpage' ? (
              <Can
                permissions={currentUser.permissions}
                perform="image:modify:mine"
                yes={() => <BellBtn />}
                no={() => ''}
              />
            ) : (
              ''
            )}

            <UserDropdown key="2" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginUser: getLoginUser(state),
    currentUser: getCurrentUser(state)
  };
};

export default connect(mapStateToProps, { clearCurrentUser, clearTimeout, setTimeoutLabel,refreshCurrentUser })(
  withRouter(Menubar)
);
