import { Button, message } from 'antd';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { getSelectedImages } from '../../selectors/imageSelector';
import {batchRestore} from '../../actions/imageActions'
import { isPending } from '../../static/detectionStatusMapping';
class Recalculate extends Component {
  recalculate = () => {
    const { selected } = this.props;
    let l= selected.data.filter(i=>isPending(i.status));
    if(l.length >0){
      message.warning(intl.get('RESTORE_IMAGE_ERROR'));
      return
    }
    this.props.setLoading()
    this.props.batchRestore()
  };

  render() {
    const { selected } = this.props;
    return (
      <Button
        type="primary"
        onClick={this.recalculate.bind(this)}
        disabled={selected.total > 0 ? false : true}
      >
        {intl.get('RECALCULATE')}
      </Button>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selected: getSelectedImages(state)
  };
};

export default connect(mapStateToProps, {batchRestore})(Recalculate);
