import React, { Component } from 'react';
import style from './index.less';

class CommonPanel extends Component {
  render() {
    const { name, height, children } = this.props;
    return (
      <div className={style.common_title} style={{ height: height }}>
        <div className={style.common_title_name}>{name}</div>
        {children}
      </div>
    );
  }
}

export default CommonPanel;
