import React, { Component } from 'react';
import { Col, Row, Icon } from 'antd';
import style from "../../less/modal.less"
class ConfirmContent extends Component {
  render() {
    return (
      <Row>
        <Col
          span={2}
          offset={1}
          className={style.checkModalIcon}
          style={{ width: '21px', height: '21px', color: '#faad14' }}
        >
          <Icon type="exclamation-circle" theme="filled" />
        </Col>
        <Col span={17} offset={1}>{this.props.children}</Col>
      </Row>
    );
  }
}

export default ConfirmContent;
