import { indexOf } from 'lodash';

const iconfontList = [
  // v14
  'setauto',
  'setai',
  'setalgorithm',
  'setset',
  'setuser',
  'usersetting',
  'setreport',
  'setimage',
  'setwork',
  'setvisualiation',
  'tool10x',
  'tool20x',
  'tool40x',
  'toolcomment',
  'toolnav',
  'toolfull',
  'toollabel',
  'toolrect',
  'daifenpei',
  'chuzhen',
  'fuzhen',
  'yizhenduan',
  'chaoshi',
  'daidayin',
  'daifafang',
  'yifafang',
  'quanbu',
  'daifenpeiactive',
  'chuzhenactive',
  'settingactive',
  'tongjiactive',
  'cancelactive',
  'fuzhenactive',
  'yizhenduanactive',
  'chaoshiactive',
  'daidayinactive',
  'daifafangactive',
  'yifafangactive',
  'quanbuactive',
  'download',
  'upload',
  'download1',
  'upload1',
  'fresh',
  'gongzuotai',
  'user',
  'arrowdown',
  'reproduce',
  'flagfill',
  'flag',
  'flaghover',
  'sendfill',
  'send',
  'sendhover',
  'shanchu',
  'shanchuhover',
  'share',
  'star',
  'starline',
  'account',
  'jinrishangchuanliang',
  'jinriAIzhenduanliang',
  'AIleijizhenduanliang',
  'leijishangchuanliang',
];

function isIconInIconfont(icon) {
  return indexOf(iconfontList, icon) !== -1;
}

export default isIconInIconfont;
