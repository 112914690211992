import { findIndex } from 'lodash';
// import intl from 'react-intl-universal';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { getCurrentTabIdx, getTabs, getCurrentSecTabIdx } from '../selectors/tabSelectors';
import store from '../store';
import { fetchSaveTabs, getCounting } from './api';
import { updateSelectedImages } from './imageActions';
import { updateQueryInfo } from './tableActions';
// import { getCurrentUser } from './userActions';
export const toggleUploadGT = (show) => ({
  type: 'TOGGLE_UPLOAD_GT',
  show
});

export const toggleAddTab = (show) => ({
  type: 'TOGGLE_ADD_TAB',
  show
});

export const toggleTab = (tab) => {
  const currentTab = getCurrentTabIdx(store.getState());
  return {
    type: 'SWITCH_TAB',
    tab: { prev: currentTab, next: tab }
  };
};

export const stoggleSecondTab = (tab) => {
  const SecTab = getCurrentSecTabIdx(store.getState());
  return {
    type: 'SWITCH_SECOND_TAB',
    tab: { prev: SecTab, next: tab }
  };
};
export const updateTabImageCount = (counts) => ({
  type: 'UPDATE_COUNT',
  counts
});

export const addTab = (tab) => ({
  type: 'ADD_TAB',
  tab
});

export const removeTab = (idx) => ({
  type: 'REMOVE_TAB',
  idx
});

export const initializeSecondTabs = (secondTabs) => ({
  type: 'INITIALIZE_SECOND_TABS',
  secondTabs
});

export const initializeSecondCount = (secondCounts) => ({
  type: 'UPDTAE_SECONDTAB_COUNT',
  secondCounts
});

function getDefaultTabIdx(tabs) {
  const idx = findIndex(tabs, { query: { status: 0 } });
  return idx !== -1 ? idx : 0;
}

/**
 * Initialize tabs
 * @param {object} initialTabs default tabs
 * @returns {}
 */
export function initializeTabs(initialTabs) {
  return async (dispatch) => {
    // const currentUser = getCurrentUser(dispatch);
    // const userId = currentUser.id;
    /*    fetchTabs(userId)
      .then((resp) => {
        dispatch({ type: 'INITIALIZE_TAB_QUERY', tabs: [...initialTabs, ...resp.data.tabs] });
      })
      .catch((err) => {
        console.warn(intl.get('USE_DEFAULT_TABS'));
        dispatch({ type: 'INITIALIZE_TAB_QUERY', tabs: initialTabs });
      })
      .finally(() => {
        const currentTab = getCurrentTabIdx(store.getState());
        const tabs = getTabs(store.getState());
        if (currentTab < tabs.length) {
          dispatch(toggleTab(currentTab));
        } else {
          dispatch(toggleTab(getDefaultTabIdx(tabs)));
        }
      });
      */
    dispatch({ type: 'INITIALIZE_TAB_QUERY', tabs: initialTabs });
    const currentTab = getCurrentTabIdx(store.getState());
    const tabs = getTabs(store.getState());
    if (currentTab < tabs.length) {
      dispatch(toggleTab(currentTab));
    } else {
      dispatch(toggleTab(getDefaultTabIdx(tabs)));
    }
  };
}

function* saveTabs(userId, tabs) {
  try {
    const resp = yield call(fetchSaveTabs, userId, { tabs });
    console.log('Save tabs success', resp);
  } catch (err) {}
}

function* handleSwitchTab(action) {
  yield put(updateSelectedImages({ data: [], total: 0 }));
  const state = yield select((state) => state.loadImages);
  const { tab } = action;
  if (tab.prev === tab.next) yield put(updateQueryInfo(state.queryInfo));
  else yield put(updateQueryInfo({ ...state.queryInfo, page: 0 }));
}

function* handleTabCountChange(action) {
  const tabs = yield select((state) => state.tabState.tabs);
  const currentUserId = yield select((state) => state.currentUser.id);
  const customTabs = tabs.filter((tab) => !tab.origin);
  const currentTab = getCurrentTabIdx(store.getState());
  yield fork(saveTabs, currentUserId, customTabs);
  if (action.type === 'ADD_TAB') {
    yield put(toggleTab(tabs.length - 1));
  } else {
    if (currentTab > tabs.length) {
      yield put(toggleTab(getDefaultTabIdx(tabs)));
    } else if (currentTab === tabs.length) {
      yield put(toggleTab(tabs.length - 1));
    }
  }
}

export function* watchSwitchTab() {
  yield takeLatest('SWITCH_TAB', handleSwitchTab);
  yield takeLatest('SWITCH_SECOND_TAB', handleSwitchTab);
}

export function* watchAddTab() {
  yield takeLatest('ADD_TAB', handleTabCountChange);
}

export function* watchRemoveTab() {
  yield takeLatest('REMOVE_TAB', handleTabCountChange);
}

export function getCountByQuery(query) {
 return getCounting(query)
    .then((resp) => {
      return resp.data;
    }).catch((err) => {
      console.log(err);
    });
}

// function _updateCounting(dispatch) {
//   const currentState = store.getState();
//   const promises = [];
//   let conQuery = currentState.tabState.secondTabs.length === 0 ? {} : { image_level: '' };
//   currentState.tabState.tabs.forEach((item, i) => {
//     promises.push(
//       getCounting({ ...currentState.loadImages.queryInfo, ...conQuery, ...item.query })
//     );
//   });

//   Promise.all(promises)
//     .then((results) => {
//       const counts = results.map((resp) => resp.data);
//       dispatch(updateTabImageCount(counts));
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// }

export function updateCounting() {
  return async (dispatch) => {
    // _updateCounting(dispatch);
    const currentState = store.getState();
    const conQuery = currentState.tabState.secondTabs.length === 0 ? {} : { image_level: '' };
    getCounting({ ...currentState.loadImages.queryInfo, ...conQuery,}).then((results) => {
      const {data} = results;
      const image_count = data.map(v=>v['All']);
      const {currentTab, tabs} = currentState.tabState;
      const {typeID} = tabs[currentTab];

      // #"Other": 其他, "MO": 微生物, "Neg": 阴性, "Sus": 疑似, "Pos": 阳性, "All": 全部
      const {Pos, Sus, MO, Neg, Other, All} = data[typeID];
      const  secondCounts = [Pos, Sus, MO, Neg, Other, All]
      dispatch(updateTabImageCount(image_count));
      dispatch(initializeSecondCount(secondCounts));
    }).catch((err) => {
      console.log("Error: updateCounting", err);
    });
  };
}

// export function _updateSecondCount(dispatch) {
//   const currentState = store.getState();
//   let s = currentState.tabState.tabs.filter((item, i) => {
//     return i === currentState.tabState.currentTab;
//   });
//   let nowTabQuery = s.length > 0 ? s[0].query : {};
//   const promises = [];
//   currentState.tabState.secondTabs.forEach((item) => {
//     promises.push(
//       getCounting({ ...currentState.loadImages.queryInfo, ...nowTabQuery, ...item.query })
//     );
//   });
//   Promise.all(promises)
//     .then((results) => {
//       const counts = results.map((resp) => resp.data);
//       dispatch(initializeSecondCount(counts));
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// }
// export function updateSecondCount() {
//   return async (dispatch) => {
//     _updateSecondCount(dispatch);
//   };
// }
