import React from 'react';
// import BeautifulTable from '../Visualization/BeautifulTable';
import BeautifulTableDynamic from '../Visualization/BeautifulTableDynamic';

function DataTable(props){
  const { data, history } = props;
  return (
    <div style={{height:'36%'}}>
      {/* <BeautifulTable data={data} history={history} parentName="dataPlatform" /> */}
      <BeautifulTableDynamic  data={data} history={history} parentName="dataPlatform" />
    </div>
  );
}

export default DataTable;
