import hotkeys from './hotkeys';
import log from './log.js';

/**
 *
 * @typedef {Object} HotkeyDefinition
 * @property {String} toolName - Command to call
 * @property {String} label - Display name for hotkey
 * @property {String[]} keys - Keys to bind; Follows Mousetrap.js binding syntax
 */
export class HotkeysManager {
  constructor() {
    this.hotkeyDefinitions = {};
    this.hotkeyDefaults = [];
    this.isEnabled = true;
  }

  /**
   * Disables all hotkeys. Hotkeys added while disabled will not listen for
   * input.
   */
  disable() {
    this.isEnabled = false;
    hotkeys.pause();
  }

  /**
   * Enables all hotkeys.
   */
  enable() {
    this.isEnabled = true;
    hotkeys.unpause();
  }

  /**
   * Registers a list of hotkeydefinitions.
   *
   * @param {HotkeyDefinition[] | Object} hotkeyDefinitions Contains hotkeys definitions
   */
  setHotkeys(hotkeyDefinitions) {
    const definitions = Array.isArray(hotkeyDefinitions) ? [...hotkeyDefinitions] : this._parseToArrayLike(hotkeyDefinitions);

    definitions.forEach(definition => this.registerHotkeys(definition));
  }

  /**
   * Set default hotkey bindings. These
   * values are used in `this.restoreDefaultBindings`.
   *
   * @param {HotkeyDefinition[] | Object} hotkeyDefinitions Contains hotkeys definitions
   */
  setDefaultHotKeys(hotkeyDefinitions) {
    const definitions = Array.isArray(hotkeyDefinitions) ? [...hotkeyDefinitions] : this._parseToArrayLike(hotkeyDefinitions);

    this.hotkeyDefaults = definitions;
  }

  /**
   * It parses given object containing hotkeyDefinition to array like.
   * Each property of given object will be mapped to an object of an array. And its property name will be the value of a property named as toolName
   *
   * @param {HotkeyDefinition[] | Object} hotkeyDefinitions Contains hotkeys definitions
   * @returns {HotkeyDefinition[]}
   */
  _parseToArrayLike(hotkeyDefinitionsObj) {
    const copy = { ...hotkeyDefinitionsObj };
    return Object.entries(copy).map(entryValue =>
      this._parseToHotKeyObj(entryValue[0], entryValue[1])
    );
  }

  /**
   * Return HotkeyDefinition object like based on given property name and property value
   * @param {string} propertyName property name of hotkey definition object
   * @param {object} propertyValue property value of hotkey definition object
   *
   * @example
   *
   * const hotKeyObj = {hotKeyDefA: {keys:[],....}}
   *
   * const parsed = _parseToHotKeyObj(Object.keys(hotKeyDefA)[0], hotKeyObj[hotKeyDefA]);
   *  {
   *   toolName: hotKeyDefA,
   *   keys: [],
   *   ....
   *  }
   *
   */
  _parseToHotKeyObj(propertyName, propertyValue) {
    return {
      toolName: propertyName,
      ...propertyValue,
    };
  }

  registerHotkeys({ toolName, keys, label, bindFunc, groupName, commandName } = {}) {
    if (!toolName) {
      log.warn(`No command was defined for hotkey "${keys}"`);
      return;
    }

    const previouslyRegisteredDefinition = this.hotkeyDefinitions[toolName];

    if (previouslyRegisteredDefinition) {
      const previouslyRegisteredKeys = previouslyRegisteredDefinition.keys;
      this._unbindHotkeys(toolName, previouslyRegisteredKeys);
    }

    // Set definition & bind
    this.hotkeyDefinitions[toolName] = { toolName, keys, label, bindFunc, groupName };
    this._bindHotkeys(toolName, keys, groupName, bindFunc, commandName);
  }

  /**
   * Uses most recent
   *
   * @returns {undefined}
   */
  restoreDefaultBindings() {
    this.setHotkeys(this.hotkeyDefaults);
  }

  /**
   *
   */
  destroy() {
    this.hotkeyDefaults = [];
    this.hotkeyDefinitions = {};
    hotkeys.reset();
  }

  /**
   * Binds one or more set of hotkey combinations for a given command
   *
   * @private
   * @param {string} toolName - The name of the command to trigger when hotkeys are used
   * @param {string[]} keys - One or more key combinations that should trigger command
   * @returns {undefined}
   */
  _bindHotkeys(toolName, keys, groupName, bindFunc, commandName) {
    const isKeyDefined = keys === '' || keys === undefined;
    if (isKeyDefined) {
      return;
    }

    const isKeyArray = keys instanceof Array;
    if (isKeyArray) {
      keys.forEach(key => this._bindHotkeys(toolName, key, groupName, bindFunc, commandName));
      return;
    }

    hotkeys.bind(keys, evt => {
      bindFunc({ toolName, groupName, commandName })
    });
  }

  /**
   * unbinds one or more set of hotkey combinations for a given command
   *
   * @private
   * @param {string} toolName - The name of the previously bound command
   * @param {string[]} keys - One or more sets of previously bound keys
   * @returns {undefined}
   */
  _unbindHotkeys(toolName, keys) {
    const isKeyDefined = keys !== '' && keys !== undefined;
    if (!isKeyDefined) {
      return;
    }

    const isKeyArray = keys instanceof Array;
    if (isKeyArray) {
      keys.forEach(key => this._unbindHotkeys(toolName, key));
      return;
    }

    hotkeys.unbind(keys);
  }
}

export default HotkeysManager;
