import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';

export const PushStatus = ({ stage, stageInfo }) => {
  return (
    <span>
      {stage!==""&&stageInfo[stage] ? (
        <span style={{color:stageInfo[stage].color}}><span style={{fontWeight:'bold'}}>·</span>&nbsp;{intl.get(stageInfo[stage].translate)}</span>
      ) : null}
    </span>
  );
};

PushStatus.propTypes = {
  stage: PropTypes.number.isRequired
};

export default PushStatus;
