import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAnnotationsOfClasses } from '../../selectors/annotationSelectors';
import AnnotationOfNavigator from './AnnotationOfNavigator';

class AnnotationGroupOfNavigator extends Component {
  render() {
    return (
      <div>
        {this.props.annotations.map((anno, idx) => (
          <AnnotationOfNavigator
            key={idx}
            width={this.props.width}
            height={this.props.height}
            annotation={anno}
          />
        ))}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    annotations: getAnnotationsOfClasses(state, ownProps)
  };
};

export default connect(mapStateToProps)(AnnotationGroupOfNavigator);
