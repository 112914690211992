/**
 * @fileOverview
 * @name defaultTabs.js
 * @author
 * @license
 Tab structure:
 text: @type {string} title of tab, can be tranlation key or plain text
 origin: @type {boolean} if tab is closable
 query: @type {object} query of tab
 */

const highlight__styles = {
  fontWeight: '700',
  fontSize: '16px'
};
// const no_highlight__styles = {
//   color: "#666",
// }
const defaultTabs = [
  {
    text: 'HEADER_POSITIVE',
    styles: highlight__styles,
    origin: true,
    query: { image_level: 'H', }
  },
  {
    text: 'HEADER_SUSPECT',
    styles: highlight__styles,
    origin: true,
    query: { image_level: 'M',  }
  },
  { text: 'MICROBIOLOGY', 
    styles: highlight__styles, 
    origin: true, 
    query: { image_level: 'MS,LS',  } 
  }, 
  {
    text: 'HEADER_NEGATIVE',
    styles: highlight__styles,
    origin: true,
    query: { image_level: 'L', }
  },
  {
    text: 'Other',
    styles: highlight__styles,
    origin: true,
    query: { image_level: 'N', }
  },
  {
    text: 'ALL',
    styles: highlight__styles,
    origin: true,
    query: { image_level: '', }
  },
];

 export const defaultTabsInfo = {
  'H': {
    color:'#F56C6C',
    translate: 'HEADER_POSITIVE',
  },
  'M': {
    color:'#67C23A',
    translate: 'HEADER_SUSPECT',
  },
  'MS': {
    color:'#F2B13E',
    translate: 'MICROBIOLOGY',
  },
  'LS': {
    color:'#F2B13E',
    translate: 'MICROBIOLOGY',
  },
  'L':{
    color:'#67C23A',
    translate: 'HEADER_NEGATIVE',
  }
}

export default defaultTabs;
