import intl from 'react-intl-universal';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {
  EPITHELIAL_CELLS,
  SQUAMOUS_CELLS,
  GLANDULAR_CELLS,
  BIOLOGICAL_PATHOGENS,
  OTHER
} from '../components/Report/SampleCells';

import { selectComments } from '../selectors/reportSelector';
import store from '../store';

export function defaultReportConfigs() {
  return [
    // header
    { key: 'scan_code', place: 'header', method: 'checkbox', span: 1 },
    { key: 'pathology_id', place: 'header', method: 'checkbox', span: 2 },
    {
      key: 'hospital_name',
      place: 'header',
      method: 'select',
      span: 3
    },
    {
      key: 'hospital_position',
      place: 'header',
      method: 'cascader',
      span: 3
    },
    {
      key: 'report_sub_title',
      place: 'header',
      method: 'input',
      span: 3
    },
    {
      key: 'logo',
      place: 'header',
      method: 'upload',
      span: 3
    },
    // patient
    { key: 'name', place: 'basic_info', method: 'checkbox' },
    { key: 'gender', place: 'basic_info', method: 'checkbox' },
    { key: 'age', place: 'basic_info', method: 'checkbox' },
    { key: 'last_m', place: 'basic_info', method: 'checkbox' },
    { key: 'is_m_end', place: 'basic_info', method: 'checkbox' },
    { key: 'clinic_id', place: 'basic_info', method: 'checkbox' },
    { key: 'admission_id', place: 'basic_info', method: 'checkbox' },
    { key: 'bed_id', place: 'basic_info', method: 'checkbox' },
    { key: 'patient_address', place: 'basic_info', method: 'checkbox' },
    { key: 'patient_telephone', place: 'basic_info', method: 'checkbox' },
    { key: 'clinic_diagnosis', place: 'basic_info', method: 'checkbox' },

    // sample
    { key: 'sample_type', place: 'basic_info', method: 'checkbox' },
    { key: 'sample_unit', place: 'basic_info', method: 'checkbox' },
    { key: 'sample_department', place: 'basic_info', method: 'checkbox' },
    { key: 'apply_doctor', place: 'basic_info', method: 'checkbox' },
    { key: 'sample_sent_date', place: 'basic_info', method: 'checkbox' },
    { key: 'sample_recevie_date', place: 'basic_info', method: 'checkbox' },

    // doctor
    { key: 'junior_doctor', place: 'footer', method: 'checkbox' },
    { key: 'senior_doctor', place: 'footer', method: 'checkbox' },
    { key: 'report_doctor', place: 'footer', method: 'checkbox' },
    { key: 'report_time', place: 'footer', method: 'checkbox' },

    // vendor
    { key: 'responsibility_statement', place: 'footer', method: 'checkbox' },
    { key: 'company_name', place: 'footer', method: 'checkbox' },
    { key: 'company_web', place: 'footer', method: 'checkbox' },
    { key: 'company_telephone', place: 'footer', method: 'checkbox' },

    // mode
    { key: 'see_mode', place: 'mode', method: 'checkbox' },
    { key: 'simple_mode', place: 'mode', method: 'checkbox' },

    // other
    { key: 'screenshot_count', place: 'other', method: 'checkbox' }
  ];
}

export function getConfigsByPlace(place) {
  return defaultReportConfigs().filter((c) => c.place === place);
}

export function getValuesFromVisEles(visEles) {
  let basic_info = [];
  let footer_info = [];
  visEles.forEach((item) => {
    if (isPatientInfo(item)) {
      basic_info.push(item);
    }
    if (isFooterInfo(item)) {
      footer_info.push(item);
    }
  });
  return { footer_info, basic_info };
}

export function isPatientInfo(val) {
  let basic_info_tp = getConfigsByPlace('basic_info');
  return basic_info_tp.some((item) => item.key === val);
}

export function isFooterInfo(val) {
  let footer_tp = getConfigsByPlace('footer');
  return footer_tp.some((item) => item.key === val);
}

export function getGender(gender) {
  if (gender === 'M') {
    return intl.get('REPORT_PATIENT_GENDER_M');
  } else if (gender === 'F') {
    return intl.get('REPORT_PATIENT_GENDER_F');
  } else {
    return '';
  }
}

export function getIsMEnd(data) {
  if (data === true) {
    return intl.get('IS_END');
  } else if (data === false) {
    return intl.get('NO_END');
  } else {
    return '';
  }
}
export function getSampleType(data) {
  if (data === 'cervical') {
    return intl.get('CERVICAL_IMAGE_TYPE');
  } else if (data === 'bladder') {
    return intl.get('BLADDER_IMAGE_TYPE');
  } else if (data === 'general') {
    return intl.get('GENERAL_IMAGE_TYPE');
  } else if (data === 'urine') {
    return intl.get('URINE_IMAGE_TYPE');
  } else {
    return '';
  }
}
export function formDateTime(data) {
  if (data !== '' && data !== undefined && data !== null) {
    return data.slice(0, 10);
  } else {
    return '';
  }
}

/**导出报告.pdf************************************************************************************* */

export const exportPDF = (title, node) => {
  const element = document.getElementById(node); // 这个dom元素是要导出pdf的div容器
  const w = element.offsetWidth; // 获得该容器的宽
  const h = element.offsetWidth; // 获得该容器的高
  const offsetTop = element.offsetTop; // 获得该容器到文档顶部的距离
  const offsetLeft = element.offsetLeft; // 获得该容器到文档最左的距离
  const canvas = document.createElement('canvas');
  let abs = 0;
  const win_i = document.body.clientWidth; // 获得当前可视窗口的宽度（不包含滚动条）
  const win_o = window.innerWidth; // 获得当前窗口的宽度（包含滚动条）
  if (win_o > win_i) {
    abs = (win_o - win_i) / 2; // 获得滚动条长度的一半
  }
  canvas.width = w * 2; // 将画布宽&&高放大两倍
  canvas.height = h * 2;
  const context = canvas.getContext('2d');
  context.scale(2, 2);
  context.translate(-offsetLeft - abs, -offsetTop);
  // 这里默认横向没有滚动条的情况，因为offset.left(),有无滚动条的时候存在差值，因此
  // translate的时候，要把这个差值去掉
  html2canvas(element, {
    allowTaint: true,
    scale: 2 // 提升画面质量，但是会增加文件大小
  }).then(function(canvas) {
    const contentWidth = canvas.width;
    const contentHeight = canvas.height;
    //一页pdf显示html页面生成的canvas高度;
    const pageHeight = (contentWidth / 592.28) * 841.89;
    //未生成pdf的html页面高度
    let leftHeight = contentHeight;
    //页面偏移
    let position = 0;
    //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
    const imgWidth = 595.28;
    const imgHeight = (592.28 / contentWidth) * contentHeight;

    const pageData = canvas.toDataURL('image/jpeg', 1.0);
    const str = '';
    const pdf = new JsPDF(str, 'pt', 'a4');

    //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
    //当内容未超过pdf一页显示的范围，无需分页
    if (leftHeight < pageHeight) {
      pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
    } else {
      // 分页
      while (leftHeight > 0) {
        pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
        leftHeight -= pageHeight;
        position -= 841.89;
        //避免添加空白页
        if (leftHeight > 0) {
          pdf.addPage();
        }
      }
    }
    pdf.save(`${title}.pdf`);
  });
};


/**报告判读/结果************************************************************************************** */
export function getSquaData(labels) {
  // ASC-US < LSIL < ASC-H < HSIL < SCC
  if (labels.includes('SCC')) {
    return ['SCC'];
  } else if (labels.includes('HSIL')) {
    return ['HSIL'];
  } else if (labels.includes('ASC-H')) {
    return ['ASC-H'];
  } else if (labels.includes('LSIL')) {
    return ['LSIL'];
  } else if (labels.includes('ASC-US')) {
    return ['ASC-US'];
  } else if (labels.includes('ASC')) {
    return ['ASC'];
  } else {
    return [];
  }
}

export function getGlanData(labels) {
  // AGC < AGC-N < AIS < ADC
  if (labels.includes('ADC')) {
    let temp_data_ADC = labels.filter((item) => item.includes('ADC-'));
    return temp_data_ADC.length === 0 ? ['ADC'] : temp_data_ADC;
  } else if (labels.includes('AIS')) {
    return ['AIS'];
  } else if (labels.includes('AGC-N')) {
    let temp_data_AGC_N = labels.filter((item) => item.includes('AGC-N-'));
    return temp_data_AGC_N.length === 0 ? ['AGC-N'] : temp_data_AGC_N;
  } else if (labels.includes('AGC')) {
    let temp_data_AGC = labels.filter((item) => item.includes('AGC-'));
    return temp_data_AGC.length === 0 ? ['AGC'] : temp_data_AGC;
  } else {
    return [];
  }
}

export function renderItem(obj, labels) { 
  let comments = selectComments(store.getState());  
  let filterData = Object.keys(obj).filter((key) => labels.includes(key));
  if (filterData.length === 0) {
    return null;
  } else {    
    return filterData
      .map((item) => {
       let comment =  comments.filter((c) =>c.value === 'COMMENT_' + obj[item].text);
       return intl.get(comment[0].value)
        // return comment[0].label;
      })
      .join('； ');
  }
}

export function getFindings(labels) {
  const squaData = getSquaData(labels);
  const glanData = getGlanData(labels);
 
  const epiRender = renderItem(EPITHELIAL_CELLS, labels);  
  const squaRender = renderItem(SQUAMOUS_CELLS, squaData);
  const glanRender = renderItem(GLANDULAR_CELLS, glanData);
  const bioRender = renderItem(BIOLOGICAL_PATHOGENS, labels);
  const othRender = !labels.includes('NILM-IN-NILM') ? renderItem(OTHER, labels) : '';
 
  const e = epiRender ? epiRender + ';' : '';
  const s = squaRender ? squaRender + ';' : '';
  const g = glanRender ? glanRender + ';' : '';
  const b = bioRender ? bioRender + ';' : '';
  const o = othRender ? othRender + ';' : '';
  const f = e + s + g + b + o;
 
  return f;
}
/*************************************************************************************** */

export function getDoctorNotMine(roleUsers, currentUser) {
  return roleUsers.senior_doctor.filter(
    (i) => i.username !== currentUser.username && i.distributable === true
  );
}
