// import React from 'react';
import intl from 'react-intl-universal';
export function setCardColums() {
    return [
      {
        title: intl.get('HOSPITAL_NAME'),
        width: 100,
        dataIndex: 'pathology_id',
        key: 'pathology_id',
        align: 'center',
      },
      {
        title: intl.get('USERNAME'),
        width: 100,
        dataIndex: 'name',
        align: 'center',
        key: 'name1',
        // fixed: 'left'
      },
      {
        title: intl.get('PASSWORD'),
        width: 50,
        dataIndex: 'gender',
        align: 'center',
        key: 'gender'
      },
      {
        title: intl.get('MAPPING_ADDRESS'),
        dataIndex: 'age',
        key: 'age',
        align: 'center',
        width: 50
      },
      {
        title: intl.get('CARD_MARKER'),
        dataIndex: 'sample_unit',
        key: 'sample_unit',
        align: 'center',
        width: 100
      },
      {
        title: 'ssh-sk',
        dataIndex: 'sample_count',
        key: 'sample_count',
        align: 'center',
        width: 100
      },
    ];
  }
  export const dataList =[
      {
          id:"id",
          name:"aaa",
      }
  ]