import React, { Component } from 'react';
import BaseChart from './BaseChart';
import style from './index.less';
import LoadingSpin from './LoadingSpin';
import colorTable from '../../static/colorTable';
import config from '../../config';
import { titleStyle, gridInFo,colorsList } from './echartInfo';
import { isDataPaltform } from './utils';
const NewColorTable = {
  ...config.cervicalResultTagColorTable,
  normal: 'green3'
};
function formatData(data) {
  let pieData = [];
  let colorData = [];
  data.forEach((item) => {
    if (item.data !== 0) {
      pieData.push({
        value: item.data,
        name: item.label
      });
      colorData.push(colorTable[NewColorTable[item.label]]);
    }
  });
  return { pieData, colorData };
}
export class LesionDistribution extends Component {
  getOption() {
    const { data, title, parentName ,otherStyle} = this.props;
    let { pieData, colorData } = formatData(data);
    const isp = isDataPaltform(parentName);
    let option = {
      color:isp?colorsList: colorData,
      title: {
        show: !isp,
        text: title,
        subtext: '',
        left: 'left',
        top: 10,
        textStyle: titleStyle
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c} ({d}%)'
      },
      legend: {
        left: '0px',
        orient: 'vertical',
        x: 'left',
        y: 'bottom',
        align: 'left',
        textStyle: {
          color: otherStyle.xyColor,
          fontSize: '12px',
        },
        icon: isp?'circle':'rect',
        itemGap:5
      },
      grid: gridInFo(parentName),
      series: [
        {
          name: '',
          type: 'pie',
          radius: ['32%', '50%'],
          avoidLabelOverlap: true,
          // center: ['55%', '60%'],
          label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            show: false
          },
          data: pieData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          center: ["60%", "50%"],
        }
      ]
    };
    return option;
  }
  render() {
    const { parentName, otherStyle } = this.props;
    const isDataPlatform = isDataPaltform(parentName);
    return (
      <div
        className={isDataPlatform ? style.chart_container_dis : style.chart_container}
        style={{ marginTop: 0, minWidth: '200px' }}
      >
        {this.props.data ? (
          <BaseChart
            style={{ height: otherStyle.height, width: '100%' }}
            option={this.getOption()}
          />
        ) : (
          <LoadingSpin />
        )}
      </div>
    );
  }
}

export default LesionDistribution;
