import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Input, Modal, Col, message } from 'antd';
import intl from 'react-intl-universal';
import styles from '../commonPanel.less';
import style from '../../../less/modal.less';
import { gradeNameList } from '../../../static/settingInfo';
import AddComponent from './AddComponent';
import {
  editDepartment,
  batchDelDepartment,
  batchAddDepartment
} from '../../../actions/checkInAction';
import { cloneDeep } from 'lodash';
import { setOrganization } from '../../../actions/setting';
import ConfirmContent from '../../CommonComponent/ConfirmContent';
// import { canRender } from '../../Can';
// const { Search } = Input;
const SendDepart = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ModalTitle, setModalTitle] = useState('title');
  const [ModalInnerTitle, setModalInnerTitle] = useState('title');
  const [checkedName, setCheckedName] = useState([]);
  const [addList, setAddList] = useState([]);

  const preVal = 'TITLE_';

  const setTitle = (name, val) => {
    setModalTitle(name + '_' + val);
    setModalInnerTitle(preVal + val);
  };
  const showAdd = (name) => {
    const { organization } = props;
    let l = gradeNameList.filter((item) => {
      return organization[item.name + 'List'].length !== 0;
    });
    //title
    if (l.length > 0) {
      if (l[l.length - 1].children.length !== 0) {
        setTitle(name, l[l.length - 1].children[0].label);
      } else {
        setTitle(name, l[l.length - 1].value);
      }
      // setTitle(name, l[l.length - 1].value);
    } else {
      setTitle(name, gradeNameList[0].value);
    }
    setIsModalVisible(true);
  };

  const showModal = (name) => {
    const { organization } = props;
    let l = gradeNameList.filter((item) => {
      return organization[item.name + 'List'].length !== 0;
    });
    let len = l.length;
    if (len > 0) {
      setTitle(name, l[len - 1].value);
      let nameList =
        organization[l[len - 1].name].length > 0
          ? organization[l[len - 1].name].map((i) => i.label)
          : [organization[l[len - 1].name].label];
      setCheckedName(nameList);
      setIsModalVisible(true);
    } else {
      message.error(intl.get('EDIT_WARING'));
    }
  };

  const add = (type, typeList) => {
    const { database, checkedOrganizationList, checkedDepartmentList } = props.organization;
    if (typeList.includes('') || typeList.length === 0) {
      message.error(intl.get('NAME_NOT_NULL'));
      return false;
    }
    if (ModalInnerTitle.indexOf('ORGANIZATION') !== -1) {
      addAction('Organization', { object_ids: [database.id], names: typeList });
    } else if (ModalInnerTitle.indexOf('DEPARTMENT') !== -1) {
      addAction('Department', { object_ids: checkedOrganizationList, names: typeList });
    } else if (ModalInnerTitle.indexOf('DOCTOR') !== -1) {
      addAction('Doctor', { object_ids: checkedDepartmentList, names: typeList });
    }
    setIsModalVisible(false);
  };

  const addAction = (part, data) => {
    props.batchAddDepartment(data, part);
  };

  const editAction = (checkedOut, outList, part, list) => {
    props.editDepartment(checkedOut, outList, part, list);
  };

  const delAction = (checkEdList, part, son) => {
    props.batchDelDepartment({ object_ids: checkEdList }, part, son);
  };

  const updateByType = (type, typeList) => {
    const {
      checkedOrganization,
      checkedDepartment,
      checkedDoctor,
      checkedOrganizationList,
      checkedDepartmentList,
      checkedDoctorList
    } = props.organization;
    if (typeList.includes('') || typeList.length === 0) {
      message.error(intl.get('NAME_NOT_NULL'));
      return false;
    }
    if (ModalInnerTitle.indexOf('ORGANIZATION') !== -1) {
      type === 'EDIT' &&
        editAction(checkedOrganization, checkedOrganizationList, 'Organization', typeList);
      type === 'DEL' && delAction(checkedOrganizationList, 'Organization', 'Department');
    } else if (ModalInnerTitle.indexOf('DEPARTMENT') !== -1) {
      type === 'EDIT' &&
        editAction(checkedDepartment, checkedDepartmentList, 'Department', typeList);
      type === 'DEL' && delAction(checkedDepartmentList, 'Department', 'Doctor');
    } else if (ModalInnerTitle.indexOf('DOCTOR') !== -1) {
      type === 'EDIT' && editAction(checkedDoctor, checkedDoctorList, 'Doctor', typeList);
      type === 'DEL' && delAction(checkedDoctorList, 'Doctor', '');
    }
    setIsModalVisible(false);
  };

  const handleOk = () => {
    ModalTitle.startsWith('ADD_') && add('ADD', addList);
    ModalTitle.startsWith('DEL_') && updateByType('DEL', checkedName);
    ModalTitle.startsWith('EDIT_') && updateByType('EDIT', checkedName);
  };

  // 获取addComponent的值
  const getAddList = (val) => {
    setAddList(val);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const updateContent = (idx, val) => {
    let l = cloneDeep(checkedName);
    l[idx] = val.target.value;
    setCheckedName(l);
  };
  return (
    <div className={styles.send_depart_title}>
      <Button
        type="primary"
        // disabled={canRender(props.currentUser.permissions, 'department:add') ? false : true}
        className={styles.first_btn}
        onClick={showAdd.bind(this, 'ADD')}
      >
        {intl.get('ADD_LABEL')}
      </Button>
      <Button
        type="primary"
        onClick={showModal.bind(this, 'EDIT')}
        // disabled={canRender(props.currentUser.permissions, 'department:modify') ? false : true}
      >
        {intl.get('EDIT_LABEL')}
      </Button>
      <Button
        type="danger"
        onClick={showModal.bind(this, 'DEL')}
        // disabled={canRender(props.currentUser.permissions, 'department:delete') ? false : true}
      >
        {intl.get('REMOVE_LABEL')}
      </Button>
      {/* <Search /> */}
      <Modal
        title={intl.get(ModalTitle)}
        visible={isModalVisible}
        onOk={handleOk.bind(this)}
        onCancel={handleCancel.bind(this)}
        className={style.checkModal}
        okType={ModalTitle.startsWith('DEL_') ? 'danger' : 'primary'}
      >
        <ConfirmContent>
          {ModalTitle.startsWith('ADD_') && (
            <>
              <Col span={6}>{intl.get(ModalInnerTitle)}</Col>
              <Col span={16} offset={1}>
                <AddComponent getAdd={getAddList} isModalVisible={isModalVisible} />
              </Col>
            </>
          )}
          {ModalTitle.startsWith('EDIT_') && (
            <>
              <Col span={6}>{intl.get(ModalInnerTitle)}</Col>
              <Col span={16} offset={1}>
                {/* <Input value={checkedName} /> */}
                {checkedName.map((item, idx) => {
                  return (
                    <Input
                      key={idx}
                      value={item}
                      onChange={updateContent.bind(this, idx)}
                      style={{ marginBottom: '10px' }}
                      minLength={1}
                      maxLength={16}
                    />
                  );
                })}
              </Col>
            </>
          )}
          {ModalTitle.startsWith('DEL_') && (
            <>
              <span>{intl.get('DEL_MODAL_WARNING')}</span>
              {checkedName.map((item, idx) => {
                return <span key={idx}>{item}、</span>;
              })}
            </>
          )}
        </ConfirmContent>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  organization: state.settings.organization,
  currentUser: state.currentUser
});
export default connect(mapStateToProps, {
  editDepartment,
  setOrganization,
  batchDelDepartment,
  batchAddDepartment
})(SendDepart);
