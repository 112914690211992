import throttle from 'lodash/throttle';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import thunk from 'redux-thunk';
import { watchLoadImageNILMGrading } from './actions/analysisActions';
import { watchDetectionSuccess, watchTriggerDetection } from './actions/detectionActions';
import { watchLoadImage, watchUpdateQueryInfo } from './actions/imageActions';
import {  watchUpdateCheckQueryInfo,watchUpdateCheckTab} from "./actions/checkInAction";
import { watchAddTab, watchRemoveTab, watchSwitchTab } from './actions/tabActions';
import {watchUpdateStatistic} from "./actions/statistics"
import {watchUpdateProductionQueryInfo} from "./actions/productionAction"
import reducers from './reducers';
import config from './config';



const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    } else {
      let state = JSON.parse(serializedState);
      if (state.config.frontVersion !== config.frontVersion) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        return undefined;
      } else {
        return state;
      } 
    }
    // return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

const peristedState = loadState();

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (e) {
    // Ignore write errors;
  }
};

const saga = createSagaMiddleware();
let composeEnhancer = null;
if (config.debug) composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
else composeEnhancer = (v) => v;
const store = createStore(reducers, peristedState, composeEnhancer(applyMiddleware(thunk, saga)));


function* rootSaga() {  
  yield all([
    watchLoadImage(),
    watchTriggerDetection(),
    watchDetectionSuccess(),
    watchSwitchTab(),
    watchUpdateCheckTab(),
    watchAddTab(),
    watchRemoveTab(),
    watchUpdateQueryInfo(),
    watchUpdateProductionQueryInfo(),
    watchUpdateCheckQueryInfo(),
    watchLoadImageNILMGrading(),
    watchUpdateStatistic()
  ]);
}
saga.run(rootSaga);


window.onbeforeunload = (e) => {
  store.dispatch({
    type: 'VERSION_UPDATE',
    version: config.frontVersion
  })
};

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000)
);

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store
}
export default store;
