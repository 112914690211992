import { Icon } from 'antd';
import React, { Component } from 'react';
import {updateComputedTime,readsingleImage,updateNotNote} from '../../actions/timeoutAction';
import { updateQueryInfo } from '../../actions/tableActions';
import styles from './index.less';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';

class Timeout extends Component {

  constructor() {
    super();
    this.state = {
      showAlert: true,
    };
  }

  markRead() {
    this.props.updateComputedTime(0);
    this.props.readsingleImage(this.props.dataSource,'panel')
    this.setState({
      showAlert: false
    });
  }
  markIngore(){
    this.props.updateComputedTime(0);
    this.props.updateNotNote()
    this.setState({
      showAlert: false
    });
  }
  render() {
    const { showAlert } = this.state;
    return (
      <div>
        {showAlert ? (
          <div className={styles.timeout_alert}>
            <div className={styles.timeout_content}>
              <div className={styles.timeout_text}>
                <div>
                  <Icon type="warning" />
                </div>
                <div>{intl.get('TIMEOUT_TITLE')}</div>
              </div>
              <div className={styles.timeout_btn}>
              <div
                  onClick={() => {
                    this.markIngore();
                  }}
                >
                  {intl.get('TIMEOUT_IGNORE')}
                </div>
                <div
                  onClick={() => {
                    this.markRead();
                  }}
                >
                  {intl.get('TIMEOUT_READ')}
                </div>
                
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    computedTime: state.timeout.computedTime,
    queryInfo:state.loadImages.queryInfo,
    timeoutList:state.timeout.timeoutList,
    notNoteTimeoutList:state.timeout.notNoteTimeoutList,

  };
};

export default connect(mapStateToProps, { updateComputedTime,readsingleImage,updateNotNote,updateQueryInfo})(Timeout);
