import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import styles from '../commonPanel.less';
import OrganizationPart from './OrganizationPart';
import OrganizationBtn from './OrganizationBtn';
import { fetchDepartmentAll } from '../../../actions/checkInAction';
function SendDepart(props) {
  useEffect(() => {
    props.fetchDepartmentAll('init')
  }, []);
  const { organization } = props;

  return (
    <div className={styles.send_depart}>
      <OrganizationBtn />
      <div className={styles.send_depart_content}>
         <OrganizationPart
          title={intl.get('INSPECTION_SEPARTMENT')}
          list={organization.organizationList}
          grade="departmentList"
          checkName="checkedOrganization"
          parentsNames={[]}
          childrenName={['checkedDepartment', 'checkedDoctor']}
        />
        <OrganizationPart
          title={intl.get('SAMPLE_DEPARTMENT')}
          list={organization.departmentList}
          grade="doctorList"
          checkName="checkedDepartment"
          parentsName={['checkedOrganization']}
          childrenName={['checkedDoctor']}
        />
        <OrganizationPart
          title={intl.get('SAMPLE_SENT_DOCTOR')}
          list={organization.doctorList}
          checkName="checkedDoctor"
          parentsName={['checkedOrganization', 'checkedDepartment']}
          childrenName={[]}
        /> 
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  organization: state.settings.organization
});
export default connect(mapStateToProps, { fetchDepartmentAll })(SendDepart);
