import ReactEcharts from 'echarts-for-react';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import styles from './index.less';
import { connect } from 'react-redux';
const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

class YearStatistics extends Component {
  formList(data) {
    let fromatData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    months.forEach((month, ii) => {
      if (data.length !== 0) {
        data.forEach((item, jj) => {
          if (Number(item.time.split('-')[1]) - 1 === ii) {
            fromatData[ii] = item.data;
          }
        });
      }
    });
    return fromatData;
  }

  getOption = () => {
    let juniorList = this.formList(
      this.props.yearList.junior === undefined ? [] : this.props.yearList.junior
    );
    let seniorList = this.formList(
      this.props.yearList.senior === undefined ? [] : this.props.yearList.senior
    );
    let totalList = this.formList(
      this.props.yearList.senior === undefined ? [] : this.props.yearList.total
    );
    return {
      title: {
        text: intl.get('STATISTICS_YEAR_TITLE'),
        x: 'left',
        left:'20px',
        top:'8px',
        textStyle: {
          fontSize: 12,
          color: 'rgba(0,0,0,1)',
        }
      },
      color:['#FFC52F','#FF7A5B','#5B9AFF'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        y: 'right',
        top:"8px",
        right:"20px",
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '5%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: months.map((i) => intl.get(i))
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false
        }
      },
      series: [
        {
          name: intl.get('JUNIOR_STATISTICS'),
          data: juniorList,
          type: 'bar'
        },
        {
          name: intl.get('REPORT_STATISTICS'),
          data: seniorList,
          type: 'bar'
        },
        {
          name: intl.get('ALL_STATISTICS'),
          data: totalList,
          type: 'bar'
        }
      ]
    };
  };
  render() {
    return (
      <div className={styles.innerStatistic}>
        <ReactEcharts
          option={this.getOption()}
          notMerge={true}
          lazyUpdate={true}
          style={{ width: '100%', height: '500px' }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  yearList: state.statistics.yearList
});

export default connect(mapStateToProps, {})(YearStatistics);
