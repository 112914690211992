import { uniq } from 'lodash';

function mergeClass(merged, old) {
  const idx = merged.indexOf(old);
  return idx > -1 ? merged.slice(0, idx).concat(merged.slice(idx + 1)) : merged;
}
// [{user:'user1',clazz:'HSIL'},{user:'user2',clazz:'LSIL'}, ...]
export function getAnnotationClassOfUsers(annotation, users) {
  if (!annotation) return [];
  // ['user1','user2',...]
  const usersInClasses = uniq(
    annotation.changes
      .filter((change) => (users ? users.indexOf(change.user) > -1 : true))
      .map((change) => change.user)
  );
  // [{user:'user1',news:['HSIL','LSIL']},{user:'user2',news:['HSIL']}]
  const allNew = usersInClasses.map((u) => {
    return {
      user: u,
      news: annotation.changes.filter((change) => change.user === u).map((change) => change.new)
    };
  });
  // [{user:'user1',olds:['null','LSIL']},{user:'user2',olds:[null]}]
  const allOld = usersInClasses.map((u) => {
    return {
      user: u,
      olds: annotation.changes.filter((change) => change.user === u).map((change) => change.old==='null'?null:change.old)
    };
  });
  // [{user:'user1',clazz:'HSIL'},{user:'user2',clazz:'HSIL'}]
  const classes = allOld.map((c) => {
    const clazz = c.olds
      .reduce(
        mergeClass,
        allNew
          .filter((news) => news.user === c.user)
          .map((n) => n.news)
          .flat()
      )
      .find((c) => true);

    return {
      user: c.user,
      clazz: clazz ? clazz : null
    };
  });
  return classes;
}
