import React from 'react';
import intl from 'react-intl-universal';
// import DetectionStatus from '../components/DetectionStatus';
import ImageLevelResultTag from '../components/ImageLevelResultTag';
import ImageTag from '../components/ImageTable/ImageTag';
import PushStatus from '../components/ImageTable/PushStatus';
import Action from '../components/ImageTable/Action';
import AutoCtrl from '../components/AutoCtrl';
import cfg from '../config';
import ProAction from '../components/Production/ProAction';
import ProStatus from '../components/Production/ProStatus';
import ProEvaluation from '../components/Production/ProEvaluation';
import ProPathId from '../components/Production/ProPathId';
import StageExplain from '../components/ImageTable/StageExplain';
import AIResult from '../components/ImageTable/AIResult';
import Cookies from 'js-cookie';
import { Tooltip } from 'antd';
import { isPartEnd } from './urlUtils';

export const defaultColumnHedersFields = [
  'tag',
  'patient_name',
  'patient_tag_id',
  'register_date',
  'clazz',
  'image_level_result_AI',
  'ground_truth_result',
  'reporter',
  'stage',
  'push_status',
  'image_status'
];
const width_titles = Cookies.get('imageTableWidthTitles') ? JSON.parse(Cookies.get('imageTableWidthTitles')) : undefined;
export function defaultColumnHeaders() { 
  return [
    {
      title: intl.get('TAG'),
      dataIndex: 'id',
      key: 'tag',
      align: 'center',
      render: (imageId, record) => <ImageTag image={record} />,
      width: width_titles ? width_titles.id : 120,
      permissions: ['admin', 'devops', 'general'],
      tabPermission: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      expertPermission: [0, 1, 2, 3, 4],
      localPermission: [0, 1, 2, 3, 4, 5],
      excelCol: false
    },
    {
      title: intl.get('PATIENT_TAG_ID'),
      dataIndex: 'tagId',
      key: 'patient_tag_id',
      align: 'center',
      editable: true,
      sorter: true,
      width: width_titles ? width_titles.tagId : 120,
      render: (_, record) => (
        <Tooltip title={record.tagId}>
          <div>{record.tagId}</div>
        </Tooltip>
      ),
      permissions: ['admin', 'devops', 'general'],
      tabPermission: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      expertPermission: [0, 1, 2, 3, 4],
      localPermission: [0, 1, 2, 3, 4, 5],
      excelCol: true,
      excelKey: 'tagId'
    },

    {
      title: intl.get('PATIENT_NAME_LABEL'),
      dataIndex: 'patient_name',
      key: 'patient_name',
      align: 'center',
      width: width_titles ? width_titles.patient_name : 100,
      render: (_, record) => (
        <Tooltip title={record.patient_name}>
          <div>{record.patient_name}</div>
        </Tooltip>
      ),
      permissions: ['admin', 'devops', 'general'],
      tabPermission: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      expertPermission: [0, 1, 2, 3, 4],
      localPermission: [0, 1, 2, 3, 4, 5],
      excelCol: true,
      excelKey: 'patient_name'
    },
    {
      title: intl.get('AGE'),
      dataIndex: 'ageValue',
      key: 'age',
      align: 'center',
      sorter: true,
      width: width_titles ? width_titles.ageValue :80,
      permissions: ['admin', 'devops', 'general'],
      tabPermission: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      expertPermission: [0, 1, 2, 3, 4],
      localPermission: [0, 1, 2, 3, 4, 5],
      excelCol: true,
      excelKey: 'ageValue'
    },
    {
      title: intl.get('GENDER'),
      width: width_titles ? width_titles.gender :80,
      permissions: ['admin', 'devops', 'general'],
      dataIndex: 'gender',
      key: 'gender',
      align: 'center',
      tabPermission: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      expertPermission: [0, 1, 2, 3, 4],
      localPermission: [0, 1, 2, 3, 4, 5],
      excelCol: true,
      excelKey: 'gender'
    },
    {
      title: intl.get('SPACEMEN_NAME_PLACEHOLDER'),
      dataIndex: 'clazz',
      key: 'clazz',
      align: 'center',
      width: width_titles ? width_titles.clazz : 120,
      permissions: ['admin', 'devops', 'general'],
      tabPermission: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      expertPermission: [0, 1, 2, 3, 4],
      localPermission: [0, 1, 2, 3, 4, 5],
      excelCol: true,
      excelKey: 'clazz_text',
      render: (clazz) => (
        <div>{intl.get(`CANCER_${clazz.toUpperCase()}`)}</div>
      )
    },
    {
      title: intl.get('SAMPLE_UNIT'),
      dataIndex: 'sample_unit',
      key: 'sample_unit',
      align: 'center',
      width: width_titles ? width_titles.sample_unit : 120,
      sorter: true,
      permissions: ['admin', 'devops'],
      render: (_, record) => (
        <Tooltip title={record.sample_unit}>
          <div>{record.sample_unit}</div>
        </Tooltip>
      ),
      tabPermission: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      expertPermission: [0, 1, 2, 3, 4],
      localPermission: [0, 1, 2, 3, 4, 5],
      excelCol: true,
      excelKey: 'sample_unit'
    },
    {
      title: intl.get('REGISTER_DATE'),
      dataIndex: 'registerDate',
      key: 'register_date',
      align: 'center',
      sorter: true,
      width: width_titles ? width_titles.registerDate : 160,
      permissions: ['admin', 'devops', 'general'],
      tabPermission: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      expertPermission: [0, 1, 2, 3, 4],
      localPermission: [0, 1, 2, 3, 4, 5],
      excelCol: true,
      excelKey: 'registerDate'
    },
    {
      title: intl.get('SCAN_TIMES'),
      dataIndex: 'scan_times',
      key: 'scan_times',
      align: 'center',
      width: width_titles ? width_titles.scan_times : 90,
      permissions: ['admin', 'devops', 'general'],
      tabPermission: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      expertPermission: [0, 1, 2, 3, 4],
      localPermission: [0, 1, 2, 3, 4, 5],
      excelCol: true,
      excelKey: 'scan_times'
    },
    {
      title: intl.get('AUTO_QUALITY_CTRL'),
      width: width_titles ? width_titles.auto_quality_ctrl : 100,
      permissions: ['admin', 'devops', 'general'],
      tabPermission: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      expertPermission: [0, 1, 2, 3, 4],
      localPermission: [0, 1, 2, 3, 4, 5],
      dataIndex: 'auto_quality_ctrl',
      key: 'auto_quality_ctrl',
      align: 'center',
      excelCol: true,
      excelKey: 'auto_quality_ctrl_text',
      render: (_, record) => {
        return <AutoCtrl image={record} />;
      }
    },
    /********************************************************************************* */
    {
      title: intl.get('JUNIOR_REPORTER'),
      dataIndex: 'junior_reporter',
      key: 'junior_reporter',
      align: 'center',
      width: width_titles ? width_titles.junior_reporter : 100,
      sorter: true,
      render: (_, record) => (
        <Tooltip title={record.junior_reporter}>
          <div>{record.junior_reporter}</div>
        </Tooltip>
      ),
      permissions: ['admin', 'devops', 'general'],
      tabPermission: [1, 2, 3, 4, 5, 6, 7, 8],
      expertPermission: [0, 1, 2, 3, 4],
      localPermission: [1, 2, 3, 4, 5],
      excelCol: true,
      excelKey: 'junior_reporter'
    },
    {
      title: intl.get('REPORTER'),
      dataIndex: 'reporter',
      key: 'reporter',
      align: 'center',
      width: width_titles ? width_titles.reporter : 100,
      sorter: true,
      render: (_, record) => (
        <Tooltip title={record.reporter}>
          <div>{record.reporter}</div>
        </Tooltip>
      ),
      permissions: ['admin', 'devops', 'general'],
      tabPermission: [1, 2, 3, 4, 5, 6, 7, 8],
      expertPermission: [0, 1, 2, 3, 4],
      localPermission: [1, 2, 3, 4, 5],
      excelCol: true,
      excelKey: 'reporter'
    },
    {
      title: intl.get('AI_RES_CLASS'),
      key: 'image_level',
      dataIndex: 'imageLeve',
      align: 'center',
      width: width_titles ? width_titles.imageLeve : 110,
      sorter: true,
      tabPermission: [0,1,2,3,4,5,6,7,8],
      expertPermission: [0, 1, 2, 3, 4],
      localPermission: [0, 1, 2, 3, 4, 5],
      excelCol: true,
      excelKey: 'image_level_text',
      render: (_, record) => {
        return <AIResult image={record} />;
      }
    },
    // {
    //   title: intl.get('IMAGELEVELRESULT'),
    //   dataIndex: 'imageLevelResult',
    //   key: 'image_level_result_AI',
    //   align: 'center',
    //   sorter: true,
    //   width: width_titles ? width_titles.imageLevelResult : 120,
    //   permissions: ['admin', 'devops', 'general'],
    //   tabPermission: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    //   expertPermission: [0, 1, 2, 3, 4],
    //   localPermission: [0, 1, 2, 3, 4, 5],
    //   excelCol: true,
    //   excelKey: 'imageLevelResult',
    //   render: (results, record) => {
    //     return <DetectionStatus results={results} image={record} />;
    //   }
    // },
    {
      title: intl.get('UPLOADER'),
      dataIndex: 'uploader',
      key: 'uploader',
      align: 'center',
      width: width_titles ? width_titles.uploader : 120,
      sorter: true,
      permissions: ['admin', 'devops'],
      tabPermission: [0],
      expertPermission: [],
      localPermission: [0],
      excelCol: true,
      excelKey: 'uploader'
    },
    {
      title: intl.get('UPLOADDATE'),
      dataIndex: 'uploadDate',
      key: 'upload_date',
      align: 'center',
      sorter: true,
      width: width_titles ? width_titles.uploadDate : 160,
      permissions: ['admin', 'devops'],
      tabPermission: [0],
      expertPermission: [],
      localPermission: [0],
      excelCol: true,
      excelKey: 'uploadDate'
    },
    {
      title: intl.get('NEGATIVE_RATE'),
      dataIndex: 'prob',
      key: 'prob',
      align: 'center',
      sorter: true,
      width: width_titles ? width_titles.prob : 90,
      permissions: ['admin', 'devops'],
      tabPermission: [0],
      expertPermission: [],
      localPermission: [0],
      excelCol: true,
      excelKey: 'prob_text',
      render: (rate, img) => {
        if (img.clazz !== 'cervical' || img.status !== 0)
          return <ImageLevelResultTag result="---" />;
        else return `${(rate * 100).toFixed(2)}%`;
      }
    },
    {
      title: intl.get('STAGE_EXPLAIN'),
      width: width_titles ? width_titles.auto_ctrl_val : 100,
      dataIndex: 'auto_ctrl_val',
      key: 'auto_ctrl_val',
      align: 'center',
      permissions: ['admin', 'devops', 'general'],
      tabPermission: [0],
      expertPermission: [],
      localPermission: [0],
      excelCol: true,
      excelKey: 'auto_ctrl_val_text',
      render: (results, img) => {
        return <StageExplain auto_ctrl={results} img={img} />;
      }
    },
    

    {
      title: intl.get('DIS_TIME'),
      dataIndex: 'junior_dtime',
      key: 'junior_dtime',
      align: 'center',
      sorter: true,
      width: width_titles ? width_titles.junior_dtime : 160,
      permissions: ['admin', 'devops', 'general'],
      tabPermission: [1, 4],
      expertPermission: [0, 3],
      localPermission: [1, 4],
      excelCol: true,
      excelKey: 'junior_dtime'
    },
    {
      title: intl.get('JUNIOR_TIME'),
      dataIndex: 'junior_time',
      key: 'junior_time',
      align: 'center',
      sorter: true,
      width: 160,
      permissions: ['admin', 'devops', 'general'],
      tabPermission: [2, 3, 4, 5, 8],
      expertPermission: [1, 2, 3, 4],
      localPermission: [2, 3, 4, 5],
      excelCol: true,
      excelKey: 'junior_time'
    },
    {
      title: intl.get('SENIOR_TIME'),
      dataIndex: 'senior_time',
      key: 'senior_time',
      align: 'center',
      sorter: true,
      width: 160,
      permissions: ['admin', 'devops', 'general'],
      tabPermission: [3, 5, 6, 8],
      expertPermission: [2, 4],
      localPermission: [3, 5],
      excelCol: true,
      excelKey: 'senior_time'
    },

    {
      title: intl.get('PRINT_TIME'),
      dataIndex: 'print_time',
      key: 'print_time',
      align: 'center',
      sorter: true,
      width: 160,
      permissions: ['admin', 'devops', 'general'],
      tabPermission: [6, 7,8],
      expertPermission: [4],
      localPermission: [5],
      excelCol: true,
      excelKey: 'print_time'
    },
    {
      title: intl.get('SEND_TIME'),
      dataIndex: 'send_time',
      key: 'send_time',
      align: 'center',
      sorter: true,
      width: 160,
      permissions: ['admin', 'devops', 'general'],
      tabPermission: [7,8],
      expertPermission: [4],
      localPermission: [5],
      excelCol: true,
      excelKey: 'send_time'
    },
    {
      title: intl.get('JUNIOR_RESULT'),
      dataIndex: 'junior_result',
      key: 'junior_result',
      sorter: true,
      align: 'center',
      width: 130,
      permissions: ['admin', 'devops'],
      tabPermission: [1, 2, 3, 4, 8],
      expertPermission: [0, 1, 2, 3, 4],
      localPermission: [1, 2, 3, 4, 5],
      excelCol: true,
      excelKey: 'junior_result',
      render: (_, img) => {
        return (
          <Tooltip title={img.junior_result}>
            <div>{img.junior_result}</div>
          </Tooltip>
        );
      }
    },
    {
      title: intl.get('SENIOR_RESULT'),
      dataIndex: 'senior_result',
      key: 'senior_result',
      sorter: true,
      align: 'center',
      width: 130,
      permissions: ['admin', 'devops'],
      excelCol: true,
      excelKey: 'senior_result',
      tabPermission: [2, 3, 4],
      expertPermission: [1, 2, 3],
      localPermission: [2, 3, 4],
      render: (_, img) => {
        return (
          <Tooltip title={img.senior_result}>
            <div>{img.senior_result}</div>
          </Tooltip>
        );
      }
    },
    {
      title: intl.get('GROUND_TRUTH_RESULT'),
      dataIndex: 'groundTruthResult',
      key: 'ground_truth_result',
      align: 'center',
      sorter: true,
      // render: (results, img) => {
      //   if (img.clazz === 'cervical')
      //     return <ImageLevelResultTag result={results} shouldBefiltered={true} />;
      //   else return <span>{results}</span>;
      // },
      render: (_, img) => {
        return (
          <Tooltip title={img.groundTruthResult}>
            <div>{img.groundTruthResult}</div>
          </Tooltip>
        );
      },
      width: 130,
      permissions: ['admin', 'devops', 'general'],
      tabPermission: [5, 6, 7, 8],
      expertPermission: [4],
      localPermission: [5],
      excelCol: true,
      excelKey: 'groundTruthResult'
    },
    {
      title: intl.get('PUSH_STATUS'),
      width: 100,
      permissions: ['admin', 'devops', 'general'],
      tabPermission: [3, 4, 8],
      expertPermission: [],
      localPermission: [3, 4, 5],
      excelCol: true,
      excelKey: 'push_status_text',
      dataIndex: 'push_status',
      key: 'push_status',
      sorter: true,
      align: 'center',
      render: (results, img) => {
        return <PushStatus stage={results} stageInfo={cfg.pushStatusInfo} />;
      }
    },
    {
      title: intl.get('ACTION'),
      key: 'image_status',
      dataIndex: 'image_status',
      align: 'center',
      render: (_, record) => <Action image={record} stageInfo={cfg.imageStatusInfo} />,
      fixed: 'right',
      width:  220,
      permissions: ['admin', 'devops', 'general'],
      tabPermission: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      expertPermission: [0, 1, 2, 3, 4],
      localPermission: [0, 1, 2, 3, 4, 5],
      excelCol: false
    }
  ];
}

export const getColumnsKeyBypermissions = (permission) => {
  let columns = defaultColumnHeaders();
  columns = columns.filter((item) => item.permissions.includes(permission));
  return columns.map((item) => item.key);
};

export const allImageListColumns = () => {
  let columns = defaultColumnHeaders();
  // if (cfg.isCloud) columns = columns.filter((i) => i.key !== 'uploader');
  return columns.map((item) => item.key);
};

export const filteredColumnHeaders = () => {
  let columns = defaultColumnHeaders();
  // if (cfg.isCloud) columns = columns.filter((i) => i.key !== 'uploader');
  return columns;
};

export const filteredColumnExcel = () => {
  let columns = defaultColumnHeaders();
  columns = columns.filter((item) => item.excelCol === true);
  return columns;
};

export function pickColumns(cfg, isCollection = false) {
  const titles = defaultColumnHeaders();
  const { imageListColumns } = cfg;
  const visibles = isCollection
    ? imageListColumns && imageListColumns.concat('remark')
    : imageListColumns && imageListColumns.filter((k) => k !== 'remark');
  return !visibles ? titles : titles.filter((t) => visibles.includes(t.key));
}

export function pickColumnsByTab(tab) {
  const titles = defaultColumnHeaders();
  const partEnd = isPartEnd();
  let columns = titles.filter((item) =>
    partEnd === 'expert'
      ? item.expertPermission.includes(tab)
      : partEnd === 'local'
      ? item.localPermission.includes(tab)
      : item.tabPermission.includes(tab)
  );
  return columns;
}

export function setCheckInColums() {
  return [
    {
      title: intl.get('APPLY_ID'),
      dataIndex: 'apply_id',
      align: 'center',
      key: 'apply_id',
      sorter: true,
      width: 100
    },
    {
      title: intl.get('PATIENT_NAME_LABEL'),
      dataIndex: 'name',
      align: 'center',
      key: 'name',
      sorter: true,
      width: 100
    },
    {
      title: intl.get('PATIENT_GENDER_LABEL'),
      dataIndex: 'gender',
      key: 'gender',
      align: 'center',
      sorter: true,
      width: 60
    },
    {
      title: intl.get('PATIENT_AGE_LABEL'),
      dataIndex: 'ageValue',
      key: 'age',
      align: 'center',
      sorter: true,
      width: 60
    },
    {
      title: intl.get('HOSPITAL_ID'),
      dataIndex: 'admission_id',
      key: 'admission_id',
      align: 'center',
      sorter: true,
      width: 80
    },
    {
      title: intl.get('PATIENT_TAG_ID'),
      dataIndex: 'pathology_id',
      key: 'pathology_id',
      align: 'center',
      sorter: true,
      width: 100
    },
    {
      title: intl.get('SEE_TYPE'),
      dataIndex: 'clinic_type',
      key: 'clinic_type',
      align: 'center',
      sorter: true,
      width: 80
    },
    {
      title: intl.get('SAMPLE_NUM'),
      dataIndex: 'sample_num',
      key: 'sample_num',
      align: 'center',
      sorter: true,
      width: 80
    },
    {
      title: intl.get('SPACEMEN_NAME_PLACEHOLDER'),
      dataIndex: 'sample_name',
      key: 'sample_name',
      align: 'center',
      width: 120
    },
    {
      title: intl.get('RECEIVE_PERSON'),
      dataIndex: 'sample_recevier',
      key: 'sample_recevier',
      align: 'center',
      width: 100
    },
    // {
    //   title: intl.get('REGISTER_DATE'),
    //   dataIndex: 'time',
    //   key: '12',
    //   align: 'center',
    //   sorter: true,
    //   width:130
    // },
    {
      title: intl.get('RECEIVE_TIME'),
      dataIndex: 'sample_recevie_date',
      key: 'sample_recevie_date',
      align: 'center',
      sorter: true,
      width: 120
    }
  ];
}

export function setCheckInRejectColums() {
  return [
    {
      title: intl.get('APPLY_ID'),
      dataIndex: 'apply_id',
      align: 'center',
      sorter: true,
      key: 'apply_id',
      width: 100
      // fixed: 'left'
    },
    {
      title: intl.get('PATIENT_NAME_LABEL'),
      dataIndex: 'name',
      align: 'center',
      sorter: true,
      key: 'name',
      width: 150
    },
    {
      title: intl.get('PATIENT_GENDER_LABEL'),
      dataIndex: 'gender',
      key: 'gender',
      align: 'center',
      sorter: true,
      width: 80
    },
    {
      title: intl.get('PATIENT_AGE_LABEL'),
      dataIndex: 'ageValue',
      key: 'ageValue',
      align: 'center',
      sorter: true,
      width: 80
    },
    // {
    //   title: intl.get('REGISTER_ID'),
    //   dataIndex: 'id',
    //   key: '3',
    //   align: 'center',
    //   sorter: true,
    // },
    {
      title: intl.get('INSPECTION_SEPARTMENT'),
      dataIndex: 'sample_unit',
      key: 'sample_unit',
      align: 'center',
      width: 120
    },
    {
      title: intl.get('REJECT_DOCTOR'),
      dataIndex: 'sample_recevier',
      key: 'sample_recevier',
      align: 'center',
      width: 120
    },
    {
      title: intl.get('RECEIVE_TIME'),
      dataIndex: 'sample_recevie_date',
      key: 'sample_recevie_date',
      align: 'center',
      width: 150
    },
    {
      title: intl.get('REJECT_REASON'),
      dataIndex: 'rejected_reason',
      key: 'rejected_reason',
      align: 'center'
    }
  ];
}

export function setProInColums() {
  return [
    {
      title: intl.get('PATIENT_TAG_ID'),
      width: 80,
      dataIndex: 'pathology_id',
      key: 'pathology_id',
      align: 'center',
      render: (_, record) => {
        return <ProPathId infoList={record} />;
      }
    },
    {
      title: intl.get('PATIENT_NAME_LABEL'),
      width: 100,
      dataIndex: 'name',
      align: 'center',
      key: 'name1',
      render: (_, record) => (
        <Tooltip title={record.name}>
          <div>{record.name}</div>
        </Tooltip>
      )
      // fixed: 'left'
    },
    {
      title: intl.get('PATIENT_GENDER_LABEL'),
      width: 50,
      dataIndex: 'gender',
      align: 'center',
      key: 'gender'
    },
    {
      title: intl.get('PATIENT_AGE_LABEL'),
      dataIndex: 'ageValue',
      key: 'ageValue',
      align: 'center',
      width: 50
    },
    {
      title: intl.get('SAMPLE_UNIT'),
      dataIndex: 'sample_unit',
      key: 'sample_unit',
      align: 'center',
      width: 120,
      render: (_, record) => (
        <Tooltip title={record.sample_unit}>
          <div>{record.sample_unit}</div>
        </Tooltip>
      )
    },
    {
      title: intl.get('SAMPLE_NUM'),
      dataIndex: 'sample_count',
      key: 'sample_count',
      align: 'center',
      width: 100
    },
    {
      title: intl.get('SPACEMEN_NAME_PLACEHOLDER'),
      dataIndex: 'sample_name',
      key: 'sample_name',
      align: 'center',
      width: 100
    },
    // {
    //   title: intl.get('SECTION_NUM'),
    //   dataIndex: 'slide_count',
    //   key: 'slide_count',
    //   align: 'center',
    //   width: 80
    // },
    {
      title: intl.get('SECTION_INDEX'),
      dataIndex: 'section_index',
      key: 'section_index',
      align: 'center',
      width: 80
    },
    {
      title: intl.get('SECTION_MAKER'),
      dataIndex: 'producer',
      key: 'producer',
      align: 'center',
      width: 100,
      render: (_, record) => (
        <Tooltip title={record.producer}>
          <div>{record.producer}</div>
        </Tooltip>
      )
    },
    {
      title: intl.get('SECTION_STATUS'),
      dataIndex: 'stage',
      key: 'stage',
      align: 'center',
      width: 100,
      render: (_, record) => {
        return <ProStatus infoList={record} />;
      }
    },

    {
      title: intl.get('SECTION_START_TIME'),
      dataIndex: 'start',
      key: 'start',
      align: 'center',
      width: 100
    },
    {
      title: intl.get('SECTION_END_TIME'),
      dataIndex: 'end',
      key: 'end',
      align: 'center',
      width: 100
    },
    {
      title: intl.get('PRO_EVALUE'),
      dataIndex: 'address',
      key: '91',
      align: 'center',
      width: 100,
      render: (_, record) => {
        return <ProEvaluation infoList={record} />;
      }
    },
    {
      title: intl.get('ACTION'),
      dataIndex: '',
      key: '101',
      width: 100,
      align: 'center',
      render: (_, record) => {
        return <ProAction infoList={record} />;
      }
    }
  ];
}
