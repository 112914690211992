import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setRegisterColums, getRegisterPathName } from './RegisterInfo';
import { Table, Pagination, Spin } from 'antd';
import style from './index.less';
import { updateRegister, updateRegisterQueryInfo } from '../../../actions/setting';
import { getAllUserByQueryInfo, getUserByRole } from '../../../actions/userActions';
import { uniq } from 'lodash';
import intl from 'react-intl-universal';
import { filterJson } from '../../../utils/dataUtils';
import { getRole } from '../../../actions/productionAction';
import { isPartEnd } from '../../../utils/urlUtils';
class RegisterTable extends Component {
  constructor(props) {
    super();
  }
  componentDidMount() {
    this.props.updateRegister({ isLoading: false });
  }

  selectRows = (keys, rows) => {
    const { register } = this.props;
    let selectedRows = register.selected;
    let ks = uniq(keys);
    selectedRows = filterJson(selectedRows.concat(rows), 'id').filter((item) => {
      return ks.indexOf(item.id) !== -1;
    });
    this.props.updateRegister({ selected: selectedRows });
  };
  changePageSize = (current, size) => {
    const { register } = this.props;
    this.props.getAllUserByQueryInfo({ ...register.queryInfo, page: 0, limit: size });
    this.props.updateRegisterQueryInfo({ page: 0, limit: size });
  };

  setPageColums = () => {
    const colums = setRegisterColums();
    const pathname = getRegisterPathName();
    return colums.filter((c) => c.partName.includes(pathname));
  };
  render() {
    const colums = this.setPageColums();
    const { register } = this.props;
    const queryInfo = register.queryInfo;
    const rowSelection = {
      selectedRowKeys: register.selected.map((item) => item.id),
      onChange: this.selectRows,
      getCheckboxProps: (record) => {
        return {
            disabled:
              isPartEnd() !== 'centerEnd' && record.rolesList?.includes('expert') ? true : false
        };
      }
    };
    const h = document.body.clientHeight - 310;
    const minH = document.body.clientHeight - 255;
    const dataList = register.registerList !== undefined ? register.registerList : [];
    return (
      <div className={style.regist__spin}>
        <Spin size="large" spinning={register.isLoading === true}>
          <Table
            columns={colums}
            rowSelection={rowSelection}
            dataSource={dataList}
            rowKey={(record) => record.id}
            key={(record) => record.id}
            onChange={this.handleTableChange}
            scroll={{
              x: '100%',
              y: dataList.length >= 20 ? h : null
            }}
            pagination={false}
            className={style.regist__table}
            style={{ minHeight: minH }}
            rowClassName={(record, index) => {
              return record.id === register.selectedSingle?.id ? style.selected_row : '';
            }}
            onRowClick={(record) => {
              this.props.updateRegister({ selectedSingle: record });
            }}
          />
          <Pagination
            current={queryInfo.page + 1}
            showTotal={(total) =>
              ` ${intl.get('CHECK_LIST_TOTAL_COUNT', { total: register.total })}`
            }
            onChange={(page) => {
              this.props.getAllUserByQueryInfo({ ...queryInfo, page: page - 1 });
              this.props.updateRegisterQueryInfo({ page: page - 1 });
            }}
            size="small"
            total={register.total}
            pageSize={queryInfo.limit}
            showQuickJumper={true}
            showSizeChanger={true}
            onShowSizeChange={this.changePageSize}
            style={{ padding: 20, display: 'flex', justifyContent: 'end' }}
          />
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    register: state.settings.register,
    units: state.currentUser.units
  };
};

export default connect(mapStateToProps, {
  updateRegister,
  updateRegisterQueryInfo,
  getAllUserByQueryInfo,
  getRole,
  getUserByRole
})(RegisterTable);
