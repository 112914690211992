import React, { useEffect } from 'react';
import ServiceList from './ServiceList';
import ViewSet from "./ViewSet"
import ParameterSet from "./ParameterSet"
import { Card, } from 'antd';
import intl from 'react-intl-universal';
import  {fetchHisService} from '../../actions/service'
import { connect } from 'react-redux';
import styles from './index.less';
function ServiceSet(props) {
   useEffect(() =>{
     props.fetchHisService()
   },[])
  return (
      <Card title={intl.get('SERVICE_CONFIG')}>
          <div className={styles.service_check}>{intl.get('SERVICE_SET')}</div>
          <ServiceList />
          <div className={styles.service_check}>{intl.get('VIEW_API_SET')}</div>
          <ViewSet />
          <ParameterSet />
      </Card>
  );
}
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, { fetchHisService })(ServiceSet);
