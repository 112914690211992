const initial = {
  reportAnnotationIds: [],
  hoveredAnnotationId: null,
  currentAnnotationId: null
};
import { uniq } from 'lodash';

const removeReportAnnotationId = (olds, id) => {
  const idx = olds.indexOf(id);
  const removed = olds.slice(0, idx).concat(olds.slice(idx + 1));
  return idx > -1 ? removed : olds;
};

const annotationSelection = (state = initial, action) => {
  switch (action.type) {
    case 'SET_HOVERED_ANNOTATION_ID':
      return {
        ...state,
        hoveredAnnotationId: action.id
      };
    case 'SET_CURRENT_ANNOTATION_ID':
      return {
        ...state,
        currentAnnotationId: action.id === state.currentAnnotationId ? null : action.id
      };
    case 'ADD_REPORT_ANNOTATION_IDS':
      return {
        ...state,
        reportAnnotationIds: state.reportAnnotationIds
          ? uniq(state.reportAnnotationIds.concat(action.ids))
          : action.ids
      };
    case 'REMOVE_REPORT_ANNOTATION_ID':
      return {
        ...state,
        reportAnnotationIds: removeReportAnnotationId(state.reportAnnotationIds, action.id)
      };
    default:
      return state;
  }
};

export default annotationSelection;
