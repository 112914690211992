import { Modal } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleLoginModal } from '../../actions/authActions';
import { getLoginModalVisible, getLoginReason } from '../../selectors/authSelectors';
// import LoginForm from './LoginForm';
import LoginCenter from "./LoginCenterForm"
import style from './LoginModal.less';
import intl from 'react-intl-universal';
import company_logo from '../../static/images/company_logo.png';
import tib_company_logo from '../../static/images/tib_company_logo.png';
class LoginModal extends Component {
  render() {
    const {reason} = this.props
    let reasonTitle = reason?reason:intl.get("TOKEN_REVOKED")
    return (
      <Modal
        visible={this.props.showLoginModal}
        keyboard={false}
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        maskStyle={{
          opacity:1,
          background:"#FFFFFF"
        }}
        footer={null}
        onCancel={() => {
          this.props.toggleLoginModal(false);
        }}
        className={style.re_login}
        width="600px"
      >
        <div className={style.center_form_logo}>
          <img src={window.config.isModeTIB ? tib_company_logo : company_logo} />
        </div>
       <p className={style.title}>{reasonTitle}</p>
       {/* <p className={style.title_sub}>{intl.get('CENTER_TITLE_SUB')}</p> */}
        {/* <LoginForm showRegister={false} /> */}
        <LoginCenter parent='mainPageLogin'/>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showLoginModal: getLoginModalVisible(state),
    reason: getLoginReason(state)
  };
};

export default connect(mapStateToProps, { toggleLoginModal })(LoginModal);
