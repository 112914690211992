import { Card,InputNumber, message, Row, Col } from 'antd';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import {  updateGeneralConfig } from '../../actions/configActions';
import { connect } from 'react-redux';
import styles from './SystemMain.less';
import { debounce } from 'lodash';

function DataClean(props) {
  const [percent, setPercent] = useState(props.general.warning_disk_percent);

  const onThreshholdChange = (data) => {
    if (data < 50) {
      message.warning(intl.get('DISK_CLEAN_THRESHOLD_LOWER_LIMIT_WARNING', { num: 50 }));
      return;
    }
    if (data > 100) {
      message.warning(intl.get('DISK_CLEAN_THRESHOLD_UPPER_LIMIT_WARNING', { num: 100 }));
      return;
    }
    props.updateGeneralConfig({ warning_disk_percent: data }).then((res) => {
      if(res){
        setPercent(data);
      }
     
    });
  };


  return (
    <Card title={intl.get('DATA_CLEAN')}>
      <Row>
        <Col span={24}>
          <div className={styles.item__div}>
            <span className={styles.item__label}>{intl.get('DISK_CLEAN_THRESHOLD')}</span>
            <InputNumber
              min={50}
              max={100}
              value={percent}
              onChange={debounce(onThreshholdChange, 500)}
            />
            &nbsp;&nbsp; %
          </div>
        </Col>
      </Row>
    </Card>
  );
}

DataClean.propTypes = {};
const mapStateToProps = (state, ownProps) => ({
  general: state.config.general
});

export default connect(mapStateToProps, {
  updateGeneralConfig
})(DataClean);
