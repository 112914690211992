import React, { Component } from 'react';
import { connect } from 'react-redux';
import RegisterAccount from "../../UserSetting/RegisterAccount"
class ExpertManagement extends Component {
  render() {
    return (<RegisterAccount/>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(ExpertManagement);
