export const clean = {
  filename: '',
  patient_name: '',
  tagId: '',
  page: 0,
  registerFrom: '',
  registerTo: '',
  sortBy: 'register_date',
  sortAsc: false,
  clazz: 'cervical',
  fuzzy: '',
  owner: '',
  aiResult: '',
  gtResult: '',
  machines: undefined
};
