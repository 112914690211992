const initial = {
  workload: [],
  consistency: [],
  distribution: {},
  performance: {},
  positive: [],
  summary: [],
  yearList: {
    junior: [],
    senior: [],
    total: []
  },
  userWorkList: [],
  queryInfo: {
    registerFrom: '',
    registerTo: ''
  },
  incidence: [
    { name: '', val: [] },
    { name: '', val: [] }
  ],
  incidenceAll:[],
  selected:{
    data:[],
    total:0
  }
};

export function statistics(state = initial, action) {
  if (state.yearList === undefined) state.yearList = initial.yearList;
  if (state.userWorkList === undefined) state.userWorkList = initial.userWorkList;
  if (state.queryInfo === undefined) state.queryInfo = initial.queryInfo;
  if (state.incidence === undefined) state.incidence = initial.incidence;
  switch (action.type) {
    case 'UPDATE_STATISTICS':
      return {
        ...state,
        [action.field]: action.data
      };
    case 'UPDATE_WORK_STATISTICS_YEAR':
      return {
        ...state,
        yearList: action.data
      };
    case 'UPDATE_WORK_STATISTICS_USER':
      return {
        ...state,
        userWorkList: action.data
      };
    case 'UPDATE_STATISTICS_QUERY_INFO':
      return {
        ...state,
        queryInfo: { ...state.queryInfo, ...action.data }
      };
    case 'UPDATE_SELECTED_HOSPITAL':
      return {
        ...state,
        selected: action.data
      };

    default:
      return state;
  }
}
