import React from 'react';
import ToolButton from '../ToolButton';
import { connect } from 'react-redux';
import MyIcon from '../Iconfont';

class Zoomer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nowZoom: 0,
      scrollZoom: this.props.zoom
    };
  }

  componentDidUpdate(prevProps) {
    const pre = prevProps.zoom.toFixed(6);
    const propZoom = this.props.zoom.toFixed(6);
    if (propZoom > 40) {
      window.osdviewer.viewport.zoomTo(window.osdviewer.viewport.imageToViewportZoom(0.05 * 40));
      this.setState({
        nowZoom: 40
      });
    } else if (propZoom < 40 && pre !== propZoom) {
      this.setState({
        nowZoom:propZoom
      });
    }
  }

  zoomIn(x) {
    window.osdviewer.viewport.zoomTo(window.osdviewer.viewport.imageToViewportZoom(0.05 * x));
    this.setState({
      nowZoom:x
    });
  }

  render() {
    return [10, 20, 40].map((i) => (
      <ToolButton
        key={i}
        icon={<MyIcon type={'icon-tool' + i + 'x'} />}
        status={
          this.state.nowZoom === i || this.state.nowZoom  === i.toFixed(6)
            ? 'active'
            : 'normal'
        }
        onClick={() => this.zoomIn(i)}
      />
    ));
  }
}

const mapStateToProps = (state) => {
  return {
    zoom: state.openSeadragonOperation.zoom
  };
};

export default connect(mapStateToProps)(Zoomer);
