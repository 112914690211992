import { Dropdown, Menu, message } from 'antd';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { clearCurrentUser } from '../../actions/userActions';
import { getLoginUser } from '../../selectors';
import { logout } from '../../utils/authUtils';
import Icon from '../Iconfont';
import style from './userdropdown.less';
import SwitchLanguage from '../SwitchLanguage';
import HelpBtn from '../HelpModal/HelpBtn';
import { clearTimeout, setTimeoutLabel } from '../../actions/timeoutAction';
import { isPartEnd } from '../../utils/urlUtils';
class UserDropdown extends Component {
  onLogoutClick = () => {
    logout().then(() => {
      this.props.clearCurrentUser();
      this.props.clearTimeout();
      this.props.setTimeoutLabel(false);
      message.success(intl.get('LOGOUT_SUCCESS'));
      this.props.history.push('/');
    });
  };

  menu = () => {
    return (
      <Menu style={{ right: '15px' }}>
        <Menu.Item>
          <Link to={isPartEnd() === 'centerEnd' ? '/preference/monitor' : '/preference/profile'}>
            <Icon type="idcard" className={style.icon} />
            <span>{intl.get('USER_PROFILE')}</span>
          </Link>
        </Menu.Item>
        <Menu.Item>
          <HelpBtn />
        </Menu.Item>
        <Menu.Item>
          <SwitchLanguage placement="menubar" />
        </Menu.Item>
        <Menu.Item id="logout-btn" onClick={this.onLogoutClick}>
          <Icon type="icondengchu" className={style.dangerIcon} />
          <span>{intl.get('LOGOUT')}</span>
        </Menu.Item>
      </Menu>
    );
  };
  render() {
    return (
      <Dropdown key="dropdown" placement="bottomLeft" overlay={this.menu()}>
        <span>
          <Icon type="icon-user" className={style.userIcon} />
          <span className={style.user__span}>
            {this.props.currentUser.username}
            <Icon type="icon-arrowdown" className={style.down__span} />
          </span>
        </span>
      </Dropdown>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginUser: getLoginUser(state),
    currentUser: state.currentUser
  };
};

export default connect(mapStateToProps, { clearCurrentUser, clearTimeout, setTimeoutLabel })(
  withRouter(UserDropdown)
);
