import { apiFetchService, apiActivateService, apiUpdateService,apiFetchHisService } from './api';
import {getMachine} from "./userActions"
export const loadService = (service) => ({
  type: 'LOAD_SERVICE',
  service
});

export const applyService = (serviceId) => ({
  type: 'ACTIVATE_SERVICE',
  serviceId
});

export const updateService = (serviceId, data) => {
  return {
    type: 'UPDATE_SERVICE',
    serviceId,
    data
  };
};

export const setHisPartInfo = (data) => {
  return { type: 'SET_HIS_PART_INFO', data };
};

export function fetchService(imageId) {
  return async (dispatch) => {
    apiFetchService(imageId)
      .then((resp) => {
        if (resp.data && resp.data.items) {
          let raw = resp.data.items;
          let process = raw.map((item) => {
            item.host = item.configurations.host;
            item.port = item.configurations.port;
            return item;
          });
          dispatch(loadService(process));
        }
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function fetchHisService() {
  return async (dispatch) => {
    apiFetchHisService()
      .then((resp) => {
        if (resp.data ) {
           dispatch(setHisPartInfo(resp.data.items))
        }
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}



export function activateService(serviceId) {
  return async (dispatch) => {
    apiActivateService(serviceId)
      .then((resp) => {
        dispatch(applyService(serviceId));
        dispatch(getMachine());
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function editService(serviceId, data) {
  return async (dispatch) => {
    apiUpdateService(serviceId, data)
      .then((resp) => {
        dispatch(updateService(serviceId, data));
        dispatch(fetchHisService())
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}
