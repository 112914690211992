import React, { Component } from 'react';
import { connect } from 'react-redux';
// import intl from 'react-intl-universal';
import { Select } from 'antd';
import { getUserUnits } from '../../selectors/userSelectors';
const { Option } = Select;

class HospitalSelect extends Component {
  getHospital(value) {
    this.props.onChange(value);
  }

  render() {
    const { units, mode, width, defaultValue, placeholder,value } = this.props;
    return (
      <Select
        mode={mode}
        allowClear
        style={{ width: width }}
        defaultValue={defaultValue}
        value={value}
        onChange={this.getHospital.bind(this)}
        placeholder={placeholder}
      >
        {units.map((item, index) => {
          return (
            <Option key={index} value={item.value}  title={item.label}>
              {item.label}
            </Option>
          );
        })}
      </Select>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    units: getUserUnits(state)
  };
};

export default connect(mapStateToProps, {})(HospitalSelect);
