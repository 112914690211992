import { fetchSaveReportConf, fetchReportConf } from './api';

export const updateReportConf = (data) => ({
  type: 'UPDATE_REPORT_CONF',
  data
});

export function initReportConf(data) {
  return {
    type: 'INIT_REPORT_CONF',
    data
  };
}

export function saveReportConf(data,callback) {
  return async (dispatch) => {
    fetchSaveReportConf(data).then((resp) => {
      dispatch(updateReportConf(data));
      callback&&callback()
    });
  };
}

export function getReportConf(initialData) {
  return async (dispatch) => {
    fetchReportConf()
      .then((resp) => {
        dispatch(updateReportConf(resp.data));
      })
      .catch((err) => {
        console.warn(err);
        dispatch(updateReportConf(initialData));
      });
  };
}
