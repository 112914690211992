

const pushStatus = [
  { value: "", translate: 'ALL' },
  { value: "0", translate: 'ACTION_VIEW' },
  { value: "1", translate: 'ACTION_DELETING' },
  { value: "2", translate: 'ACTION_TO_FETCH' },
  { value: "3", translate: 'ACTION_FETCHING' },
  { value: "4", translate: 'ACTION_FETCH_FAILED' },
];


export const statusInfo = {
  0: {
    translate: 'ACTION_VIEW',
    color: "#1890ff"
  },
  1: {
    translate: 'ACTION_DELETING',
    color: "green"
  },
  2: {
    translate: 'ACTION_TO_FETCH',
    color: "gray"
  },
  3: {
    translate: 'ACTION_FETCHING',
    color: "orange"
  },
  4: {
    translate: 'ACTION_FETCH_FAILED',
    color: "orange"
  },
}

export default pushStatus;
