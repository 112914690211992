import { Card, Radio} from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
 
// import { allImageListColumns } from '../../utils/displayUtils';
 
import {useDispatch} from 'react-redux';
// import useQueryInfo from '../../hooks/useQueryInfo';
import { getCurrentClassification } from '../../selectors';
import {saveCurrentClassification } from '../../actions/configActions';

export default function Classification(props) {
 
    const dispatch = useDispatch();
    // const {reloadQueryInfo} = useQueryInfo();
    // const onChange = (checkedValue) =>{
    //     saveVisibleColumnsAsync(checkedValue)(dispatch)
    //     reloadQueryInfo(checkedValue)
    // }

    return (
        
        <Card title={intl.get('CLASSIFICATION_TYPE')}>
            <Radio.Group defaultValue={getCurrentClassification()} onChange={e=>{
                let value = e.target.value;
                saveCurrentClassification(value)(dispatch)
            }}>
                <Radio value={7}>{intl.get(("CUSTOM_7CLASSIFICATION").toUpperCase())}</Radio>
                <br />
                <Radio value={5}>{intl.get(("CUSTOM_5CLASSIFICATION").toUpperCase())}</Radio>
                <br />
                <Radio value={15}>{intl.get(("TBS15CLASSIFICATION").toUpperCase())}</Radio>
        </Radio.Group>
        </Card>
    );
}

Classification.propTypes = {};
  