import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Icon, TimePicker, Radio } from 'antd';
import intl from 'react-intl-universal';
import styles from '../commonPanel.less';
import {  numModeList } from '../../../static/settingInfo';
import { updateGeneralConfig } from '../../../actions/configActions';
import moment from 'moment';

const format = 'HH:mm';

function TimeSet(props) {
  const [timeList, setTime] = useState(['']);
  const [mode,setMode]=useState('')

  useEffect(()=>{
    setMode(props.general.auto_distribute_mode)
    setTime(formTime(props.general.auto_distribute_times))
  },[])

  const formTime=(time)=>{
   return  time.reduce(function(list,cur){

     if(cur.hour<10 && cur.minute<10){
      list.push("0"+cur.hour.toString()+":0"+cur.minute.toString())
     }else if(cur.hour<10 && cur.minute>=10){
      list.push("0"+cur.hour.toString()+":"+cur.minute.toString())
     }else if(cur.hour>=10 && cur.minute>=10){
      list.push(cur.hour.toString()+":"+cur.minute.toString())
     }else{
      list.push(cur.hour.toString()+":0"+cur.minute.toString())
     }
      return list
    },[])
  }

  const onChangeRadio = (event) => {
    props.updateGeneralConfig({ auto_distribute_mode: event.target.value }).then((res) => {
      if(res){
        setMode(event.target.value);
      }
      
    });
  };
  const addList = () => {
    setTime([...timeList, '']);
  };
  const updateList = (index) => {
    let t = timeList;
    t.splice(index, 1);
    let list = t.reduce(function(arr,cur){
      arr.push({
        hour:parseInt(cur.slice(0,2),10),
        minute:parseInt(cur.slice(3,5),10)
      })
      return arr
    },[])
    props.updateGeneralConfig({ auto_distribute_times:list}).then((res) => {
      if(res){
        setTime([...t]);
      }

    });
  };

  const onChangeTime = (index, time, timeString) => {
    let t = timeList;
    t[index] = timeString;
    let list = t.reduce(function(arr,cur){
      arr.push({
        hour:parseInt(cur.slice(0,2),10),
        minute:parseInt(cur.slice(3,5),10)
      })
      return arr
    },[])
    props.updateGeneralConfig({ auto_distribute_times:list}).then((res) => {
      if(res){
        setTime([...t]);
      }

    });
  };

  return (
    <div className={styles.distribution}>
      <div className={styles.dis_time_lab}>{intl.get('DIS_TIME_LABEL')}</div>
      <div className={styles.dis_time_set}>
        <div className={styles.dis_day}> {intl.get('DIS_TIME_TIME')}&nbsp;&nbsp;</div>
        <div className={styles.dis_time_list}>
          {timeList.map((item, index) => {
            return (
              <div key={index} className={styles.dis_picker}>
                <TimePicker
                  format={format}
                  value={item===""?null:moment(item, format)}
                  onChange={onChangeTime.bind(this, index)}
                  allowClear={false}
                />
                &nbsp;&nbsp;
                {index === 0 ? (
                  <Icon type="plus-circle" onClick={addList.bind(this)} />
                ) : (
                  <Icon type="minus-circle" onClick={updateList.bind(this, index)} />
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div >
        <Radio.Group
          onChange={(e) => {
            onChangeRadio(e);
          }}
          className={styles.dis_item2}
          value={mode}
        >
          {numModeList.map((item, index) => {
            return (
              <div key={index} className={styles.dis_time_rad} >
                <Radio key={item.value} value={item.value}>
                  {intl.get(item.label)}
                </Radio>
              </div>
            );
          })}
        </Radio.Group>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  general: state.config.general
});
export default connect(mapStateToProps, {updateGeneralConfig})(TimeSet);
