import ReactEcharts from 'echarts-for-react';
import { isEqual } from 'lodash';
import React, { Component } from 'react';
import { getCurrentLocale } from '../../static/locales';
import { sum, transpose } from '../../utils/analysisUtils';

function chartComponentBuilder(optionFunc, style = {}, events = {}) {
  return class NewComponent extends Component {
    constructor(props) {
      super(props);
      this.state = { option: {}, total: 0, data: null, selected: null };
      this.data = null;
      this.getOptions = optionFunc.bind(this);
      this.events = {};
      for (let e in events) {
        if (typeof events[e] === 'function') this.events[e] = events[e].bind(this);
      }
    }

    componentDidMount() {
      this.reload();
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevState.total !== this.state.total) {
        this.reload();
      }
      if (prevProps.isGT !== this.props.isGT || !isEqual(this.props.matrix, prevProps.matrix)) {
        this.reload(true);
      }
    }

    reload(isAll) {
      if (isAll) {
        this.data = null;
        this.setState({ option: this.getOptions(), total: 0, data: null, selected: null });
      } else {
        this.setState({ option: this.getOptions() });
      }
    }

    render() {
      return (
        <ReactEcharts
          option={this.state.option}
          lazyUpdate={true}
          style={style}
          onEvents={this.events}
        />
      );
    }
  };
}

export const PieChart = chartComponentBuilder(
  function () {
    let { matrix, header, colorTable } = this.props;
    const { isGT } = this.props;
    if (!isGT) matrix = matrix.map((m) => transpose(m));

    const data =
      this.data ||
      matrix.map((m, i) => {
        return m.map((line, idx) => {
          const ret = {
            name: header[i][idx],
            value: sum(line)
          };
          if (colorTable[i] && colorTable[i].length === header[i].length) {
            ret['itemStyle'] = { color: colorTable[i][idx] };
          }
          return ret;
        });
      });
    this.data = data;
    if (!isEqual(data, this.state.data)) this.setState({ data });
    const radius = [
      [0, '30%'],
      ['40%', '50%'],
      ['70%', '90%']
    ];
    const series = data.map((d, i) => {
      const se = {
        name: i,
        type: 'pie',
        data: d,
        radius: data.length > 1 ? radius[i] : radius[1],
        minAngle: 0,
        avoidLabelOverlap: true
      };

      if (i === data.length - 1) {
        se.labelLine = { show: true, length: 20, length2: 30 };
        se.label = {
          formatter: (params) => {
            const { name, value, percent } = params;
            return `{a|${name}}\n ${value} {per|${percent}%}`;
          },
          position: 'outside',
          backgroundColor: '#eee',
          borderColor: '#aaa',
          borderWidth: 1,
          borderRadius: 4,
          rich: {
            a: {
              color: '#999',
              lineHeight: 22,
              align: 'center',
              fontWeight: 'bold'
            },
            hr: {
              borderColor: '#aaa',
              width: '100%',
              borderWidth: 0.5,
              height: 0
            },
            b: {
              fontSize: 16,
              lineHeight: 33
            },
            per: {
              color: '#eee',
              backgroundColor: '#334455',
              padding: [2, 4],
              borderRadius: 2
            }
          }
        };
      } else {
        se.labelLine = {
          show: false
        };
        se.label = {
          normal: {
            show: false
          },
          emphasis: {
            show: false,
            textStyle: {
              fontSize: '16',
              fontWeight: 'bold'
            }
          }
        };
      }
      return se;
    });

    const total =
      this.state.total ||
      data[data.length - 1].reduce((accu, curr) => {
        return accu + curr.value;
      }, 0);

    // echarts这种富文本方式和intl的方式不兼容，只能采用这种比较丑陋的方式
    const lang = getCurrentLocale();
    const text = lang === 'zh' ? `共{a|${total}}张图` : `Total Images: {a|${total}}`;

    // 自动隐藏数量为 0 的项
    const selected = {};
    !!this.state.selected ||
      data[data.length - 1].forEach((item) => {
        selected[item.name] = !!item.value;
      });

    const options = {
      title: {
        text: text,
        left: 0,
        bottom: 20,
        textStyle: {
          fontSize: 13,
          rich: {
            a: {
              fontSize: 20,
              color: '#1CA9F3',
              fontWeight: 'bold',
              fontFamily: 'Microsoft Yahei'
            }
          }
        }
      },
      legend: {
        orient: 'vertical',
        left: 0,
        // right: 0,
        selected: this.state.selected || selected,
        data: header[header.length - 1]
      },
      tooltip: {
        trigger: 'item',
        formatter: (params) => {
          const { value, percent, name } = params;
          return `${name}: ${value}(${percent}%)`;
        }
      },
      grid: {
        left: 10,
        top: 10,
        bottom: 10
      },
      series
    };
    return options;
  },
  { height: 400 },
  {
    legendselectchanged: function (params) {
      const { selected } = params;
      const { data } = this;
      let total = data[data.length - 1].reduce((accu, curr) => {
        if (!selected[curr.name]) return accu;
        return accu + curr.value;
      }, 0);
      if (total === 0 && data.length >= 2) {
        total = data[data.length - 2].reduce((accu, curr) => {
          if (selected[curr.name] !== false) return accu;
          return accu + curr.value;
        }, 0);
      }
      this.setState({ total, selected });
    }
  }
);
