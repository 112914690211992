import { Button, Spin } from 'antd';
import React, { Component } from 'react';
import { DragSource } from 'react-dnd';
import { connect } from 'react-redux';
import { saveReportResult } from '../../actions/reportAction';
import config from '../../config';
import Icon from '../Iconfont';
import style from './index.less';
import setBaseUrl, { isPartEnd } from '../../utils/urlUtils';
function isAnnotationChangesUploading(anno) {
  return anno.status === 'uploading';
}

function isAnnotationChangesFailure(anno) {
  return anno.status === 'failure';
}

class AnnotationThumbnail extends Component {
  removeAnnotation = () => {
    let annoIds = this.props.reportAnnoIds.slice();
    const removeReportAnnotationId = (olds, id) => {
      const idx = olds.indexOf(id);
      const removed = olds.slice(0, idx).concat(olds.slice(idx + 1));
      return idx > -1 ? removed : olds;
    };
    let annotations = removeReportAnnotationId(annoIds, this.props.item.id);
    this.props.saveReportResult(this.props.currentImageKey, { annotations });
  };

  checked(item) {
    this.props.setCurrentAnnotationId(item.id);
    if (window.osdoverlay !== undefined) {
      let cur = window.osdoverlay._fabricCanvas._objects.filter(
        (i) => i.id === 'text' + item.id || i.id === 'rect' + item.id
      );
      let text;
      let rect;
      cur.forEach((item) => {
        item.id.startsWith('rect') ? (rect = item) : (text = item);
      });
      this.setColor(text, rect);
    }
  }

  setColor = (text, rect) => {
    const fillColor = 'rgba(0,0,0,1)';
    const backgroundColor = 'rgba(214,214,214,0.8)';
    const annoStrokeColor = 'rgba(0,255,0,0.8)';
    if (text) {
      text.set('fill', fillColor);
      text.set('backgroundColor', backgroundColor);
    }
    if(rect){
      rect.set('stroke', annoStrokeColor);
    }
  };

  render() {
    const partEnd = isPartEnd();
    const { item, isReport,currentImageInfo } = this.props;
    const { baseUrl} = config;
    const imgBeforeUrl = setBaseUrl(baseUrl,currentImageInfo)
    const idUrl = `${imgBeforeUrl}/api/annotation/${item.id}`;
    const regionUrl =
      item.x_start &&
      item.y_start &&
      `${imgBeforeUrl}/api/annotation/region/${
        this.props.currentImageKey
      }?quality=75&level=0&x_start=${item.x_start.toFixed(0)}&y_start=${item.y_start.toFixed(
        0
      )}&width=${item.size.toFixed(0)}&height=${item.size.toFixed(0)}&rw=100&ry=100`;
    const imgUrl = ['all','local'].includes(partEnd) ? idUrl : regionUrl;

    return (
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        {this.props.removable ? (
          <Button
            shape="circle"
            size="small"
            onClick={this.removeAnnotation.bind(this)}
            style={{
              position: 'absolute',
              width: '20px',
              minWidth: '20px',
              height: '20px',
              fontSize: '10px',
              right: '2px',
              top: '2px',
              border: 'none',
              backgroundColor: 'blue'
            }}
          >
            <Icon type="close" style={{ color: 'white' }} />
          </Button>
        ) : (
          ''
        )}
        <img
          ref={this.props.connectDragSource}
          src={imgUrl}
          className={isReport ? style.img__auto : style.img}
          alt=""
          onClick={() => {
            this.props.setCurrentAnnotationId && this.checked(item);
          }}
        />
        <Spin
          indicator={
            isAnnotationChangesFailure(item) ? (
              <Icon type="warning" style={{ fontSize: 10, color: 'red' }} />
            ) : (
              <Icon type="loading" style={{ fontSize: 10 }} spin />
            )
          }
          spinning={
            (isAnnotationChangesUploading(item) || isAnnotationChangesFailure(item)) &&
            !this.props.removable
          }
          size="small"
          style={{
            zIndex: 3,
            position: 'absolute',
            width: '10px',
            height: '10px',
            top: 0,
            right: '10px'
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentImageKey: state.imageSelection.currentImageKey,
  currentImageInfo: state.imageSelection.currentImageInfo,
  reportAnnoIds: state.report.annotations
});

export default DragSource(
  'ANNOTATION_CHANGE',
  {
    beginDrag: (props) => {
      return props.item;
    }
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  })
)(connect(mapStateToProps, { saveReportResult })(AnnotationThumbnail));
