import { Col, Row } from 'antd';
import React from 'react';
import ReportConfiguration from './ReportConfiguration';
import Report from '../Report'
import ReportHesitation from "./ReportHesitation"
export default function ReportSettings() {
  return (
    <Row gutter={16}>
      <Col md={24} lg={24} xl={12}>
          <ReportConfiguration />
          <ReportHesitation />
      </Col>
      <Col md={24} lg={24} xl={12} style={{overflow:"hidden"}}>
          <Report/>
      </Col>
    </Row>
  );
}
