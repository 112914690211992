import { createSelector } from 'reselect';
import { getUnhiddenAnnotations } from '../selectors/annotationSelectors';
import { getCurrentClassification } from '../selectors';
import { getShowUsers, getInvisibleClasses } from '.';
import { uniq } from 'lodash';
import config from '../config';
import store from '../store';

const { cervicalGroundTruthList} = config;

export const getLinesToUserOfClass = (lines) => {
  const userAndClass = lines.map((l) => {
    const splited = l.split(',');
    return { user: splited[0], clazz: splited[1] };
  });
  return userAndClass;
};

const sortclasses = (a, b) => {
  const aIdx = cervicalGroundTruthList.findIndex((g) => g === a);
  const bIdx = cervicalGroundTruthList.findIndex((g) => g === b);
  return aIdx - bIdx;
};

export const getAnnotationClassesOfUsers = createSelector(
  [getUnhiddenAnnotations, getShowUsers, getInvisibleClasses],
  (annotations, users, invisibles) => {
    let person = users.filter((u) => !u.startsWith('ai_'));
    let annotation_classes = uniq(
      annotations
        .map((a) =>
          getLinesToUserOfClass(a.lines)
            .filter((l) => users.includes(l.user))
            .filter((l) => !invisibles.includes(l.clazz))
            .map((l) => l.clazz)
        )
        .flat()
    ).sort(sortclasses);
    let annotation_classes_L = uniq(
      annotations
        .map((a) =>
          getLinesToUserOfClass(a.lines)
            .filter((l) => person.includes(l.user))
            .filter((l) => !invisibles.includes(l.clazz))
            .map((l) => l.clazz)
        )
        .flat()
    ).sort(sortclasses);

  
    const currentClassification = getCurrentClassification();
    let { imageLeve } = store.getState().imageSelection.currentImageInfo;
    if (currentClassification !== 15) {
      annotation_classes =  imageLeve === 'L' || imageLeve === 'LS' ? annotation_classes_L : annotation_classes;
      // annotation_classes = imageLeve === 'L' ? annotation_classes_L : annotation_classes;
    
      const current_classification = config.classifications['class'+ currentClassification];
      let groups = current_classification
        .filter((l) => {
          let includes = false;
          annotation_classes.forEach((ac) => {
            if (l.groundTruth.includes(ac)) {
              includes = true;
            }
          });
          return includes;
        })
        .map((v) => v.name);
      let diyList = diy(annotation_classes, current_classification);
      groups = groups.concat(diyList);
      (imageLeve === 'L' || imageLeve === 'LS') && groups.unshift('Suspected_Anomalous_Areas');
      // (imageLeve === 'L') && groups.unshift('Suspected_Anomalous_Areas');
      if(currentClassification === 5){
        groups.push("Microorganisms")
      }else{
        groups.push("Others")
      }
      return groups;
    } else {
      if (imageLeve === 'L' || imageLeve === 'LS') {
      // if (imageLeve === 'L') {
        annotation_classes_L.splice(0, 0, 'Suspected_Anomalous_Areas');
        return annotation_classes_L;
      } else {
        annotation_classes.splice(0, 0, 'ALL');
        return annotation_classes;
      }
    }
  }
);

function diy(classList, fiveList) {
  let list = [];
  classList.forEach((item) => {
    for (let i = 0; i < fiveList.length; i++) {
      if (fiveList[i].groundTruth.includes(item)) {
        break;
      }
      if (i === fiveList.length - 1) {
        list.push(item);
      }
    }
  });
  return uniq(list);
}
