const allocationStatus = [
    { value: '', translate: 'ALL' },
    { value: '1', translate: 'CANNOT_ALLOCATION' },
    { value: '2', translate: 'WAIT_ALLOCATION' },
    { value: '3', translate: 'ASSIGNED' },
    { value: '4', translate: 'TIMEOUT' },
  ];

  export const statusInfo = {
    '': {
      translate: 'ALL',
    },
    1: {
      translate: 'CANNOT_ALLOCATION',
    },
    2: {
      translate: 'WAIT_ALLOCATION',
    },
    3: {
      translate: 'ASSIGNED',
    },
    4: {
      translate: 'TIMEOUT',
    },
  }
export default allocationStatus