import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setTagVisibility } from '../../actions/toolActions';
import ToolButton from '../ToolButton'
import MyIcon from '../Iconfont'
import {Tooltip} from 'antd'
import intl from 'react-intl-universal';

class TagBtn extends Component {
  render() {
    return (
      <Tooltip placement="top" title={intl.get('TAG_BUTTON')}>
        <ToolButton 
          icon={<MyIcon type="icon-toollabel"/>}
          onClick={() => this.props.setTagVisibility(!this.props.tagVisibility)}
          status={this.props.tagVisibility ?"active":"normal"}
        />
      </Tooltip>
    );
  }
}

const mapStateToProps = (state) => ({
  tagVisibility: state.toolStatus.tagVisibility
});

export default connect(mapStateToProps, { setTagVisibility })(TagBtn);


