const check = (permissions, action, data) => {
  if (!permissions) {
    // permission is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

const Can = (props) =>
  check(props.permissions, props.perform, props.data) ? props.yes() : props.no();

Can.defaultProps = {
  yes: () => null,
  no: () => null
};

export default Can;

export function canRender(permissions, perform, data) {
  return check(permissions, perform, data);
}

export function rolePart(permissions, roles) {
  if (!permissions) {
    // permission is not present in the rules
    return false;
  }
  const staticPermissions = permissions.static;
  for (let i = 0; i < roles.length; i++) {
    if (staticPermissions && staticPermissions.includes(roles[i])) {
      // static rule not provided for action
      return true;
    }
  }
  return false;
}
