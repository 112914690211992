import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import MyIcon from '../Iconfont';
import { Button, Modal,Icon } from 'antd';
import styles from './ShareBtn.less';
import copy from 'copy-to-clipboard';
function getSharedUrl(imageId){
  if(window.location.href.includes("?imageId")){
    return window.location.href
  }
  return window.location.href+'?imageId='+imageId
}

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      url:window.location.href,
      tipShow:false
    };
    this.inputRef =React.createRef()
  }

  componentDidMount(){
    this.setState({
      url: getSharedUrl(this.props.currentImageKey)
    })
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.currentImageKey!== this.props.currentImageKey){
      this.setState({
        url: getSharedUrl(this.props.currentImageKey)
      })
    }
  }


  closeModal = () => {
    this.setState({ visible: false });
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  urlChange = (e) => {
    this.setState({ url: e.target.value });
  };
  coppyUrl(url){
    copy(url);
    this.setState({tipShow: true})
  }

  render() {
    return (
      <>
        <span  onClick={this.showModal}>
          <MyIcon type="icon-share" className={styles.share__icon} />
          <span className={styles.share__span}>
            {intl.get('SHARE')}
          </span>
        </span>
        <Modal
          title={intl.get('SHARE_MODAL_TITLE')}
          visible={this.state.visible}
          onOk={this.onCollect}
          onCancel={this.closeModal}
          footer={null}
        >
          <div className={styles.share__div}>
            {intl.get('SHARE_LABEL')}
            <div className={styles.share__input__div}>
              {this.state.url}
              <span style={{visibility:this.state.tipShow?"visible":'hidden'}}>
                <Icon type="check-circle" theme="filled" style={{color:'green',margin:'0px 5px'}} />
                {intl.get('COPY_TO_CLIPBOARD')}
              </span>
            </div>
          
            <Button key="collect" type="primary" onClick={()=>this.coppyUrl(this.state.url)}>
              {intl.get('COPY')}
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  currentImageKey: state.imageSelection.currentImageKey,
});

export default connect(mapStateToProps, {

})(Index);
