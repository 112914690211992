import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Button,
  Modal,
  Cascader,
  Select,
  message
} from 'antd';
import style from './index.less';
import intl from 'react-intl-universal';
import { getEvaluateList } from './productionInfo';
import {
  updateBatchSection,
  distributeProducer,
  setProSelectedProducer,
} from '../../actions/productionAction';
import {
  getUserByRole,
} from '../../actions/userActions';
import { canRender } from '../Can';
import ProBatchPrint from './ProBatchPrint';
import AddPro from './AddPro';
import StartEndPro from './StartEndPro';
import ConfirmContent from '../CommonComponent/ConfirmContent';
import { setActionDisabled } from '../../utils/urlUtils';
const { Option } = Select;

class RightBtn extends Component {
  constructor() {
    super();
    this.print = React.createRef();
    this.state = {
      isModalVisible: false,
      nowModal: ' ',
      evaluation: '',
      producerList: []
    };
  }

  showModal(name) {
    const { selected } = this.props;
    if (this.isNoSelect()) return false;
    if (name === 'PRO_DISTRIBUTION') {
      let not = selected.list.filter((item) => [1, 3].includes(item.distribute_stage));
      if (not.length > 0) {
        message.warning(intl.get('PRO_SELECTED_MORE'));
        return;
      }
      getUserByRole('producer').then((res) => {
        this.setState({ producerList: res.users });
      });
    }
    this.setState({
      isModalVisible: true,
      nowModal: name
    });
  }

  handleOk = (name) => {
    if (name === 'PRO_EVALUE') {
      this.props.updateBatchSection({ evaluation: this.state.evaluation });
    } else if (name === 'PRO_DISTRIBUTION') {
      const { selected, selectedProducer } = this.props;
      let l = selected.list.map((item) => item.id);
      if(selectedProducer.length===0){
        message.warning(intl.get('SELECTED_DIS'))
        return 
      }
      this.props.distributeProducer({ users: selectedProducer, sections: l });
    }
    this.handleCancel();
  };

  handleCancel = () => {
    this.props.setProSelectedProducer([]);
    this.setState({ isModalVisible: false, nowModal: ' ' });
  };

  getProducer(val) {
    const { selected } = this.props;
    if(selected.total>1 && val.length>selected.total){
      message.warning(intl.get('ALLOCATION_WARN'))
      return false
    }
    
    this.props.setProSelectedProducer(val);
  }

  changEval(value) {
    this.setState({
      evaluation: value.join('/')
    });
  }

  updateEval(notInstage) {
    const { selected } = this.props;
    if (this.isNoSelect()) return false;
    let stageList = selected.list.filter((s) => notInstage.includes(s.stage));
    if (stageList.length > 0) {
      message.warning(intl.get('PRO_SELECTED_MORE'));
      return false;
    } else {this.showModal('PRO_EVALUE');}
  }

  isNoSelect() {
    const { selected } = this.props;
    if (selected.total === 0) {
      message.warning(intl.get('PRO_SELECTED_WARNING'));
      return true;
    }
    return false;
  }
  
  render() {
    const { isModalVisible, nowModal, producerList } = this.state;
    const evaluationList = getEvaluateList();
    const {  currentUser,selected } = this.props;
    const partStyle = setActionDisabled()
    return (
      <>
          <Col span={8} className={style.pro__btn} style={partStyle}>
            <AddPro isNoSelect={this.isNoSelect.bind(this)} currentUser={currentUser} />
            <Button
              type="primary"
              disabled={canRender(currentUser.permissions, 'pipeline:modify:batch') ? false : true}
              onClick={() => this.showModal('PRO_DISTRIBUTION')}
            >
              {intl.get('PRO_DISTRIBUTION')}
            </Button>
            <StartEndPro
              isNoSelect={this.isNoSelect.bind(this)}
              currentUser={currentUser}
              label="PRO_START"
              name="start"
              notInstage={[1, 2]}
            />
            <StartEndPro
              isNoSelect={this.isNoSelect.bind(this)}
              currentUser={currentUser}
              label="PRO_FINISH"
              name="finish"
              notInstage={[0, 2]}
            />
            <Button
              type="primary"
              disabled={canRender(currentUser.permissions, 'section:modify') ? false : true}
              onClick={() => this.updateEval([0, 1])}
            >
              {intl.get('PRO_EVALUE')}
            </Button>
            <ProBatchPrint currentUser={currentUser} isNoSelect={this.isNoSelect.bind(this)}/>
          </Col>
        <Modal
          title={intl.get(nowModal)}
          visible={isModalVisible}
          onOk={this.handleOk.bind(this, nowModal)}
          onCancel={this.handleCancel.bind(this)}
          className={style.checkModal}>
            <ConfirmContent>
            {nowModal === 'PRO_DISTRIBUTION' && (
                  <Select
                    placeholder={intl.get('PRO_DISTRIBUTION')}
                    allowClear
                    style={{ width: 300 }}
                    showSearch={true}
                    onChange={this.getProducer.bind(this)}
                    defaultValue={selected.total>1?[]:''}
                    mode={selected.total>1 && "multiple"}
                  >
                    {producerList.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item.realname}
                        </Option>
                      );
                    })}
                  </Select>
                )}
                {nowModal === 'PRO_EVALUE' && (
                  <Cascader
                    options={evaluationList}
                    onChange={this.changEval.bind(this)}
                    placeholder={intl.get('PRO_EVALUE')}
                  />
                )}
            </ConfirmContent>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selected: state.production.selected,
    queryInfo: state.production.queryInfo,
    currentUser: state.currentUser,
    selectedProducer: state.production.selectedProducer
  };
};

export default connect(mapStateToProps, {
  updateBatchSection,
  distributeProducer,
  setProSelectedProducer,
})(RightBtn);
