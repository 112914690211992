/* jshint esversion: 6 */
export default {
  TRADEMARK: '',
  TRADEMARK_SUB: 'Cervical-Sight',
  TIB_TRADEMARK_SUB: 'Pathology Platform',
  PLATFORM_NAME: 'Cervical-Sight',
  PLATFORM_VERSION:
    '<div>Pathology-Sight {middleV}</div>',
  CURRENTLOCALE: '中文',
  COMPANY_NAME_TEXT: 'Imsight Technology',
  IMSIGHT_COPYRIGHT:
    '© 2019 Copyright by ShenZhen Imsight Medical Technology Co. Ltd. All Rights Reserved',

  // User
  REGISTER: 'Sign up',
  ACCOUNT: 'Account',
  PASSWORD: 'Password',
  PASSWORD_CONFIRM: 'Confirm Password',
  USERNAME: 'Username',
  REGISTER_AT: 'Register Time',
  NAME: 'Name {name}',
  EMAIL: 'Email Address',
  REALNAME: 'Full Name',
  PLACRE_HOLDER_NAME:'Please enter your account',
  PLACRE_HOLDER_PWD:'Please enter your password',
  ACCOUNT_IS_NULL_MSG: 'Please enter your account number!',
  PASSWORD_IS_NULL_MSG: 'Please enter your password!',
  LOGIN: 'LOGIN',
  LOGOUT: 'Logout',
  ACCOUNT_PASSWORD_ERROR: 'Account does not exist or wrong password',
  UPDATE: 'Update',
  PROFILE: 'Profile',
  UPDATE_PROFILE: 'Update Profile',
  UPDATE_PASSWORD: 'Update Password',
  ADMIN_KEY_ERROR: 'Wrong admin key',
  PASSWORD_FORMAT:
    'Password must between 8 and 16 characters, including a number and an uppercase letter',
  PASSWORD_NOT_EMPTY: 'Password cannot be empty',
  SMB_PASSWORD: 'Upload Password',
  TWO_PASSWORD_NOT_MATCH: 'Passwords are not matched',
  REGISTER_SUCCESS: 'Sign up successfully!',
  REGISTER_FAILURE: 'Sign up failed  {reason}',
  USER_NOT_EXISTS: 'User {username} does not exists!',
  USER_ALREADY_EXISTS: 'User {username} already existed!',
  REALNAME_NOT_EMPTY: 'Full name cannot be empty',
  EMAIL_NOT_VALID: 'Not a valid email',
  BACK_TO_LOGIN: 'Log in',
  ADMIN_KEY: 'Admin key',
  ADMIN_KEY_NOT_EMPTY: 'Admin key cannot be empty',
  LOGOUT_SUCCESS: 'Logout Successful',
  TOKEN_EXPIRED: 'Your session has timed out, please login again.',
  TOKEN_REVOKED: 'Your session has been revoked, please login again',
  USER_CONFIG_FAILED: 'Error fetching configurations',
  AGREE_TERMS:  'I have read and fully agree to the terms of',
  SOFTWARE_LICENSE:'Software License Agreement',

  // form
  FILL_INFO: 'Please fill in the form',

  // image
  TAG: 'Image Tag',
  FILENAME: 'Filname',
  PATIENT_TAG_ID: 'Pathology ID',
  SLIDE_ID: 'Slide ID',
  UPLOADDATE: 'Upload Date',
  UPLOAD_DATE: 'Upload Date',
  UPLOADER: 'Uploaded By',
  READFLAG: 'Unread Flag',
  IMAGELEVELRESULT: 'A.I. Diagnosis',
  IMAGE_LEVEL_RESULT_AI: 'A.I. Diagnosis',
  PROB: 'Prob',
  OPERATIONS: 'Operations',
  CLAZZ: 'Image Type',
  LASTMODIFY: 'Last Modified',
  GROUND_TRUTH_RESULT: 'Ground Truth',
  REMARK: 'Remark',
  IMAGE_PREVIEW: 'Image Preview',
  PATIENT_NAME: 'Name',
  PATIENT_AGE: 'Age',
  PATIENT_SEX: 'Gender',
  IMAGE_NOT_EXISTS: 'Image does not exists',
  UPLOAD_IMAGE_SUCCESS: 'Image {filename} has been successfully uploaded!',
  UPLOAD_IMAGE_FAILED: 'Image failed to upload {filename}',
  NEGATIVE_RATE: 'NILM Prob.',
  STATUS: 'Status',
  IMAGE_CLASS: 'Image Type',
  CERVICAL: 'Cervical',
  GENEREL: 'Others',
  INPUT_GROUND_TRUTH: 'Please enter ground truth',
  INPUT_GT_HELP: 'Press "enter" to submit GT result',
  MODIFY_GT_RESULT: 'Modify diagnostic result',
  SCAN_TAG: 'Scan',
  UPDATE_TAG: 'Update',
  RETRY_RECOGNIZE: 'Rescan',
  // Details
  UPLOAD_IMAGE: 'Click to Upload<br>{fileType}',
  IMAGE_TYPE:
    '{sample, plural, =cervical {Cervical Cytology} =bladder {Bladder Histology} =urine {Urine Cytology} =general {General Image} }',
  CERVICAL_IMAGE_TYPE: 'Cervical Cytology',
  BLADDER_IMAGE_TYPE: 'Bladder Histology',
  URINE_IMAGE_TYPE: 'Urine Cytology',
  GENERAL_IMAGE_TYPE: 'General Image',
  CELL_COUNT_GOOD: 'Satisfactory<br> Cell Count',
  CELL_COUNT_BAD: 'Unsatisfactory<br> Cell Count',
  CELL_COUNT_LABEL: 'Cell Count',
  DETAIL_RESULT_LABEL: 'Result',
  PATIENT_INFO_TITLE: 'Patient Info.',
  PATIENT_ID_LABEL: 'Patient ID',
  PATIENT_NAME_LABEL: 'Name',
  PATIENT_GENDER_LABEL: 'Gender',
  PATIENT_AGE_LABEL: 'Age',
  PATIENT_OPERATION_DONE_TEXT: 'The patient received radiotherapy, chemotherapy, hysterectomy',
  DETAIL_REPORT_BUTTON: 'View Report',
  DETAIL_FILE_NAME: 'File name',
  DETAIL_PATIENT_ID: 'Patient ID',
  DETAIL_DETECTION_RESULT: 'A.I. result',
  DETAIL_ABNORMAL_POSIBILITY: 'Abnormal Prob.',
  DETAIL_GENERATE_REPORT: 'Generate report',
  DETAIL_PRINTED_REPORT: 'Check report',
  DETAIL_BTN_LOCKED: 'Locked',
  DETAIL_BTN_LOCKED_TIP: 'Other user occupied',
  DETAIL_RADIOLOGY: 'Radiotherapy',
  DETAIL_CHEMICAL: 'Chemotherapy',
  DETAIL_CUT: 'Hysterectomy',
  AI_USERS: 'A.I. Diagnosis',
  OTHER_USERS: 'Truth',
  CHANGE_TRUTH: 'Modify',
  CHANGE_TRUTH_POPUP_TITLE: 'Modify Truth',
  ANNOTATION_DRAFT: 'Draft',

  IMAGE_QUALITY_SATISFACTORY: '{isGood, plural, =true {Quantity of sample satisfied，} =false {Quantity of sample dissatisfied} }',
  IMAGE_QUALITY_COUNT:
    '{isEnough, plural, =true {Cells Count（{count}）} =false {Cells Count（{count}）} }',
  IMAGE_QUALITY_COUNT_CRITICAL:
    '{isEnough, plural, =true {greater than {critical}} =false {less than {critical}} }',
  IMAGE_QUALITY_CHENGFEN:
    '{isChengfen, plural, =true {Visible Endocervical Canal} =false {Invisible Endocervical Canal} }',
  IMAGE_ID_MISMATCH: 'patient information does not match AI cannot be identified, please contact technical support.',
  IMAGE_QUALITY_DIRTY: 'By {reason}, Not suitable for evaluation of intraepithelial lesions',
  IMAGE_QUALITY_YANXING: '{isYanxing, plural, =true {Inflammatory cell occlusion} =false {}  }',
  IMAGE_QUALITY_XUEYE: '{isXueye, plural, =true {Blood covering} =false {}  }',
  IMAGE_QUALITY_YIWU: '{isYiwu, plural, =true {Foreign body covering} =false {}  }',
  IMAGE_QUALITY_SECHA: '{isSecha, plural, =true {The color difference of the production dyeing is too large} =false {}  }',
  IMAGE_QUALITY_MOHU: '{isMohu, plural, =true {The image is blurry or distorted} =false {}  }',
  IMAGE_QUALITY_EPITHELIAL_LESS: 'Quantity of Epithelial cells is low',
  IMAGE_QUALITY_HAS_RARITY: 'Foreign matter',
  IMAGE_QUALITY_SAMPLE_AMBIGUITY: 'Scan blurry',
  IMAGE_QUALITY_EFFECT_OVER: 'Valid area {percent} exceeded',
  IMAGE_QUALITY_SAMPLE_NUM: 'Specimen Adequacy: {count}',
  IMAGE_QUALITY_SAMPLE_CELLS_NUM: 'Cells Count: {count}',
  // Image list on Viewer page
  IMAGE_LIST_END_MESSAGE: 'Total {count}',
  IMAGE_LIST_SEARCH: 'Type slide id',

  // desc about cells
  NILM: 'NILM',
  NORMAL: 'Normal',
  ABNORMAL: 'Abnormal',
  INFLAMMATION: 'Reactive changes associated with Inflammation ',
  INFLAMMATORY_CELL: 'Inflammation Cell',
  MILD: 'Mild',
  MODERATE: 'Moderate',
  SEVERE: 'Severe',
  RADIOTHERAPY_RESPONSE: 'Radiation changes',
  IUD: 'IUD',
  ATROPHY: 'Atrophy',
  SQUAMOUS_CELLS: 'ASCUS',
  EPITHELIAL_CELLS: 'LEC',
  ASC: 'ASC',
  ASC_US: 'ASC-US',
  ASC_H: 'ASC-H',
  LSIL: 'LSIL',
  HSIL: 'HSIL',
  SCC: 'SCC',
  GLANDULAR_CELLS: 'AGUS',
  AGC: 'AGC',
  AGC_EC: 'Endocervical',
  AGC_EM: 'Endometrial ',
  AGC_NONE: 'Undetermined origin',
  AGC_N: 'AGC-FN',
  AGC_N_EC: 'Endocervical',
  AGC_N_NONE: 'Undetermined origin',
  AIS: 'AIS',
  ADC: 'ADC',
  ADC_EC: 'Endocervical',
  ADC_EM: 'Endometrial',
  ADC_EU: 'Extrauterine',
  ADC_NONE: 'Undetermined origin',
  BIOLOGICAL_PATHOGENS: 'Others',
  NOT_SEEN: 'Negative',
  TV: 'TRICH',
  FUNGI: 'CAN',
  ACTINO: 'ACTIN',
  HSV: 'HSV',
  CC: 'CC',
  CYTOMEGALOVIRUS: 'HPV',
  OTHER_NON_TUMOR: 'Other Non-oncological Findings',
  EMC: 'Endometrial Cells (EMC) (>/ 45 y/o)',
  GC_HYSTERECTOMY: 'Glandular Cell/ Adenocyte',
  // report results
  COMMENTS1: 'Regular Screening is recommended. ',
  COMMENTS2: 'A review is recommended after a month',
  COMMENTS3: 'A review is recommended after 4 – 6 months. ',
  COMMENTS4: 'A review is recommended after a year.',
  COMMENTS5: 'Colposcopy and Biopsy or Clinical consideration are recommended. ',
  COMMENTS6: 'Combine Clinical consideration is recommended. ',
  COMMENTS7: 'Both Endocervical curettage and Endometrial biopsy are recommended.',
  COMMENTS8: 'Colposcopy and Endocervical curettage are recommended.',
  COMMENTS9: 'Endometrial biopsy or Dilation and Curettage is recommended. ',
  COMMENTS10: 'Review is recommended after treatment.',
  COMMENTS11: 'It is suggested to recollect materials for inspection.',
  COMMENTS12: 'Colposcopy, biopsy, endocervical curettage and endometrial biopsy are recommended.',
  COMMENTS13: 'Colposcopy, biopsy, and endocervical curettage are recommended. ',
  COMMENTS14: 'Colposcopy and biopsy (with endometrial biopsy or endocervical curettage) are recommended. ',
  COMMENTS15: 'Please rescreen it. ',
  COMMENTS16: 'Please re-production the image. ',
  COMMENTS_SELECT_PLACEHOLDER: 'Normal template',
  // 判读结果自定义
  COMMENT_NORMAL: 'NILM',
  COMMENT_ABNORMAL: 'Abnormal',
  COMMENT_INFLAMMATION: 'Reactive changes associated with Inflammation',
  COMMENT_RADIOTHERAPY_RESPONSE: 'Reactive changes associated with Radiation changes',
  COMMENT_IUD: 'Reactive changes associated with Intrauterine contraceptive device (IUD)',
  COMMENT_ATROPHY: 'Atrophy',
  COMMENT_ASC: 'Atypical Squamous Cells',
  COMMENT_ASC_US: 'Atypical squamous cells of undetermined significance  (ASC-US)',
  COMMENT_ASC_H: 'Atypical squamous cells cannot exclude HSIL (ASC-H)',
  COMMENT_LSIL: 'Low-grade Squamous Intraepithelial Lesion (LSIL)',
  COMMENT_HSIL: 'High-grade Squamous Intraepithelial Lesion (HSIL)',
  COMMENT_SCC: 'Squamous Cell Carcinoma (SCC)',
  COMMENT_AGC: 'Atypical Glandular Cells (not otherwise specified)',
  COMMENT_AGC_EC: 'Atypical endocervical cells (NOS)',
  COMMENT_AGC_EM: 'Atypical endometrial cells (NOS)',
  COMMENT_AGC_NONE: 'AGC-NOS (Undetermined origin)',
  COMMENT_AGC_N: 'Atypical glandular cells (Favor neoplastic)',
  COMMENT_AGC_N_EC: 'Atypical endocervical cells (Favor neoplastic)',
  COMMENT_AGC_N_NONE: 'AGC-FN (Undetermined origin)',
  COMMENT_AIS: 'Endocervical adenocarcinoma in-situ',
  COMMENT_ADC: 'Adenocarcinoma (ADC)',
  COMMENT_ADC_EC: 'Endocervical ADC',
  COMMENT_ADC_EM: 'Endometrial ADC',
  COMMENT_ADC_EU: 'Extrauterine ADC',
  COMMENT_ADC_NONE: 'Undetermined origin (ADC)',
  COMMENT_NOT_SEEN: 'NILM',
  COMMENT_TV: 'TRICH',
  COMMENT_FUNGI: 'CAN',
  COMMENT_ACTINO: 'ACTIN',
  COMMENT_HSV: 'HSV',
  COMMENT_CC: 'CC',
  COMMENT_CYTOMEGALOVIRUS: 'HPV',
  COMMENT_EMC: 'Endometrial Cells (EMC) (>/45 y/o)',
  COMMENT_GC_HYSTERECTOMY: 'Glandular Cells (after hysterectomy)',
  // Navigator panel
  NUCLEAR_SIZE_AND_RATIO: 'NC Ratio / Nuclei Size',
  BRIGHTNESS: 'Brightness',
  CONTRAST: 'Contrast',

  // WSI
  WSI_CAVITATION: 'Cavitated',

  // Report
  REPORT: 'Report',
  REPORT_PATIENT_IFNO_LABEL: 'Patient Info.',
  REPORT_MODE: 'Layout Mode',
  SEE_MODE: 'Complete',
  SIMPLE_MODE: 'Lite',
  HOSPITAL_NAME: 'Hospital',
  REPORT_SUB_TITLE: 'Title',
  LOGO: 'Logo',
  PATHOLOGY_ID: 'Pathology ID',
  REPORT_LOGO_UPLOAD: 'Click to Upload',
  REPORT_HEADER_INFO: 'Header',
  REPORT_BASIC_INFO: 'Basic Info.',
  REPORT_FOOTER_INFO: 'Footer',
  REPORT_SETTING: 'Report settings',
  REPORT_SAVE: 'Save',
  REPORT_SUBMIT: 'Submit',
  REPORT_SUBMIT_FAILED: 'Submit failed',
  REPORT_SUBMIT_SUCCESS: 'Submit success',
  REPORT_RESUBMIT: 'Resubmit',
  REPORT_OCCUPIED: 'Occupied',

  SCAN_CODE: 'Scan code',
  GENDER: 'Gender',
  AGE: 'Age',
  LAST_M: 'Last M.',
  IS_M_END: 'M. ended',
  IS_END: 'YES',
  NO_END: 'NO',
  SAMPLE_PLACEHOLDER: 'Referred Site/Referral Unit/Referrer',
  SAMPLE_UNIT: 'Referred Site',
  SAMPLE_DEPARTMENT: 'Referral Unit',
  SAMPLE_SENT_DOCTOR: 'Referrer',
  SAMPLE_SENT_DATE: 'Referral Date',
  APPLY_DOCTOR: 'Apply doctor',
  CLINIC_ID: 'Clinic ID',
  ADMISSION_ID: 'Patient ID',
  BED_ID: 'Bed ID',
  SAMPLE_TYPE: 'Specimen type',
  SAMPLE_RECEVIE_DATE: 'Receive date',
  PATIENT_ADDRESS: 'address',
  PATIENT_TELEPHONE: 'phone',
  CLINIC_HISTORY: 'Clinic history',
  JUNIOR_DOCTOR: 'Initial Reader',
  SENIOR_DOCTOR: 'Senior',
  REPORT_DOCTOR: 'Reported By',
  REPORT_TIME: 'Report time',
  RESPONSIBILITY_STATEMENT: 'Disclaimer',
  COMPANY_NAME: 'Company Name',
  COMPANY_WEB: 'Company web',
  COMPANY_TELEPHONE: 'Company telephone',
  SCREENSHOT_COUNT: 'Maximum number of screenshots',
  REPORT_PATIENT_GENDER_M: 'Male',
  REPORT_PATIENT_GENDER_F: 'Female',
  REPORT_DIAGNOSIS_HISTORY_LABEL: 'clinical diagnosis',
  REPORT_RADIOLOGY: 'Radiotherapy',
  REPORT_CHEMICAL: 'Chemotherapy',
  REPORT_CUT: 'Hysterectomy',
  REPORT_SATISFIED: 'Satisfactory for evaluation',
  REPORT_UNSATISFIED: 'Unsatisfactory for evaluation',
  REPORT_INTERPRETATION_LABEL: 'Findings/ Result',
  REPORT_REMARK_SUGGESTION: 'Notes/ Comments',
  REPORT_HINT: 'The A.I. results provided are for reference only.',
  REPORT_HINT_SIMPLE: 'The results are for reference only',
  // Error
  ERROR_NETWORK_ERROR: 'NETWORK ERROR, please check the network connection',
  ERROR_SERVER_ERROR: 'SERVER ERROR, please contact IT support',
  ERROR_DATA_ERROR: 'DATA ERROR, could be due to incorrect format',
  ERROR_NETWORK_TIMEOUT: 'Timeout, please try again later',
  ERROR_FAIL_TO_TRIGGER_DETECTION: 'Detection failed for {filename}!',
  ERROR_BAD_TRIGGER_PARAM: 'Detect parameter error',
  ERROR_QUEUE_FULL: 'Detection queue is FULL, please try again later',
  ERROR_PARAMS_ERROR: 'Parameter error, please check and try again later',
  ERROR_URL_TIMEOUT: '{ url } timeout, please try again later',

  // Update
  UPDATE_SUCCESS: 'Successfully update {what}! {message}',
  UPDATE_FAILED: 'Failed to update {what}! {message}',
  NO_NEED_UPDATE: 'No need to update',

  // Confirm
  YES: 'Yes',
  CANCEL: 'Cancel',
  RECOVER: 'Recover',
  // image-viewer
  CURRENT_SCALE: 'Scale',

  // Tabs
  HEADER_POSITIVE: 'Positive',
  HEADER_SUSPECT: 'Suspect',
  HEADER_NEGATIVE: 'Negative',
  HEADER_UNSATISFIED: 'Unsatisfied',
  HEADER_DETECTED: 'Detection Completed',
  HEADER_DETECTING: 'Detection List',
  HEADER_DETECTION_FAILED: 'Detection Failed',
  HEADER_GENERAL: 'General',
  HEADER_COLLECTIONS: 'Collections',
  ADD_TAB: 'Add tab',
  ADD_TAB_TITLE: 'Tab name:',
  ADD_TAB_INPUT_TITLE: 'Input tab name here',
  ADD_TAB_QUERY: 'Condition:',
  ADD_TAB_QUERY_BUTTON: 'Add',
  USE_DEFAULT_TABS: 'Cannot find custom tabs, default tabs will be used',

  // Preference
  PREFERENCE: 'Preference',
  ALGO_ANALYSIS: 'Analysis',
  USER_PROFILE: 'Account Settings',
  MANAGEMENT: 'Platform Manage',
  USER_MANAGEMENT: 'User Manage',
  DASHBOARD: 'Dashboard',
  IMAGE_UPLOAD: 'Image Uploading',
  USER_ROLES: 'User Roles',
  MISSING_WSI: 'Missing wsi',
  HELP: 'Help',
  PAGE_CONFIG: 'Preferences',
  PAGE_AI_RESULT_CONFIG: 'AI Preferences',
  DISPALY: 'Display',
  LABEL: 'Label',
  SERVICE_CONFIG: 'Service',
  REPORT_CONFIGURE: 'Report Settings',
  VISUALIZATION: 'Visualization',

  // Analysis
  SENSITIVITY: 'Sensitivity',
  EXPORT_ANALYSIS_RESULT: 'Export',
  SENSITIVITY_PLUS: '{clazz, plural, =cervical {LSIL} =urine {AUC}}+',
  SPECIFIC: 'Specificity',
  M_SENSITIVITY: 'Organisms Sensitivity',
  M_SPECIFIC: 'Organisms Specificity',
  CRUDE_CLASSIFY: 'Merged Abnormalities',
  DETAIL_CLASSIFY: 'Details',
  MICROBE_CLASSIFY: 'Organisms',
  MISSING: 'FP',
  FLCHART_TITLE: 'FN/FP',
  NEGATIVE_ANALYSIS: 'NILM GRADING',
  NR: 'Negative Exclusion Rate',
  TNR: 'NPV',
  INR: 'NILM Probability',
  ONLY_POSITIVE: 'Only positive',
  PERCENTAGE: 'Portion',
  NUMBER: 'Counts',
  POSITIVE: 'Negative',
  NEGATIVE: 'Positive',
  PROB_LT_NR: 'Prob <= {nr}',
  LOAD_PROP_IMAGES: 'Load Image(s) with prob less than {prob}%',
  REMOVE_NILM_GRADING_RECORD: 'Remove',
  NILM_GRADING_EXPLAINATION: `
For all images that have been analysed as NILM are sorted into descending order according to the A.I. confidence score. Each image has assigned a sort position as N (n1, n2, n3 and so on).<br>
> The x-axis represents the percentage of true negative in the total number of negative, that is, the "negative rate"<br>
> The y-axis represents the percentage of true negative images corresponding to N position, that is, the "negative predictive value"(NPV)<br>
> When hovering the mouse over a specific point, the true negative rate corresponding to N is displayed at that certain point.
`,
  ANALYSIS_ONLY_SUPPORT_CERVICAL: 'Analysis only support cervical type',
  ANALYSIS_CANCER_TYPE_ERROR: 'Image Type ERROR',
  undefined: 'Undefined',
  // annotation
  ANNOTATION_DELETE: 'Confirm to remove',
  ANNOTATION_EMPTY: 'No Data',
  CONFLICT: 'Conflict',
  ALL_CLASSES: 'All',
  All: 'All',
  Suspected_Anomalous_Areas: 'Suspected Cells',
  CLASS_OF_DRAFT: 'Draft',
  POINTS: 'Points',
  NEW_ANNOTATION_CLASS: '+ New Class',
  CUSTOM_CLAESS: 'Custom Classes',
  ANNOTATION_LIST_END_MESSAGE:
    '{hasMore, plural, =true {{count} labels loaded} =false {Total {count} labels}}',
  LOAD_MORE_ANNOTATIONS: 'Load More',
  DRAG_TO_DRAW_RECT: 'Applied default size for this label',

  // Image Table
  SHOULD_SELECT_IMAGE: 'Please select images first',
  LOADIMAGES: '{selected, plural, =true {Load Selected} =false {Load All}}',
  OPERATION: 'Operation',
  CHECK_IMAGE: 'Modify',
  CONFIRM_IMAGE: 'Confirm',
  SELECTED_IMAGE_COUNT: 'Selected {count} images',
  LIST_TOTAL_COUNT: 'Total: {total} images',
  DELETE_IMAGES_SUCCESS: 'Successfully deleted {count} images',
  DELETE_IMAGES_FAILURE: 'Failed to delete {count} images: {failure}',
  DELETE_IMAGES_TITLE: 'Are you sure you want to DELETE these image(s)?({count})',
  TRIGGER_DETECTION_FOR_SELECTED_IMAGES: 'Start detection for selected images',
  ANALYSIS_BTN_TITLE: 'Please select the analysis type',
  ANALYSIS_IMAGES: 'Analysis images',
  EXPORT_CSV: 'Export CSV',
  REFRESH_TABLE: 'Clear query and refresh',
  DOWLOAD_RESULT: 'Download patient reading results',
  DELETE_SELECTED_IMAGES: 'Delete Images ({count})',
  DELETE_ANNO_TITLE: 'Delete Annotations ?',
  UPLOAD_GT_SUCCESS: 'Completed uploading ground truth result, success: {total}, failure: {failed}',
  UPLOAD_GT: 'Upload ground truth',
  UPLOAD_GT_FAILED: 'Upload ground truth failed, please check your file',
  UPLOAD_GT_HELP: `
Ground truth must be csv or xlsx format, and meet the following requirements:<br>
- The first column must be filename with extension or patient tag id of the image.<br>
- The second column must be ground truth result(s) of the image, if there are multiple results, use "+" to connect them.<br>
- The third column must be organisms ground truth result(s) of the image, if there are multiple results, use "+" to connect them. If there is no result, just leave it blank.
`,

  DRAG_UPLOAD_GT: 'Click or drag file to this area to upload',
  DRAG_UPLOAD_GT_HINT: 'Ground truth must be csv or xlsx format',
  DETECTING_TAB_NOT_FOUND: 'Cannot find tab of "Detecting", please switch manually',
  SORT_BY: 'Arrangement By',
  SORTTING: 'Orderring',
  SORT_ASC: 'Ascending order',
  SORT_DESC: 'Descending order',
  GT_LABEL: '{index, plural, =1 {Epithelial Cell ABN} =2 {Organisms}}',
  GT_LABEL_NAME:
    '{name, plural, =eca {Epithelial Cell ABN} =organism {Organisms} =urine_abn {Abnormal urothelial cells}}',
  SEARCH_CHAR_HELP: 'and: *<br>or: |<br>not: \\\\<br>',

  // Detection
  TRIGGER_DETECTION_TITLE: 'Please select the detection type',
  IMG_IS_DETECTING: 'Detection in progress for {filename}.',
  TRIGGER_DETECTION_SUCCESS: 'Succeed in triggering detection',
  TRIGGER_DETECTION_FAILED: 'Failed to trigger detection',

  // Button
  RECTANGLE_BUTTON: 'Annotate',
  NAVIGATOR_BUTTON: 'Navigator',
  TAG_BUTTON: 'Tag',
  COMMENTS_NOTE: 'Comments',
  SENT_TO_REPORT_BUTTON: 'Sent to report',
  MARK: 'Mark',
  MARKED: 'Marked',
  DELETE_BUTTON: 'Delete Annotation',
  PLAY_BUTTON: 'Play Annotations',
  CHOOSE_TO_PLAY: 'Select annotations to play.',
  NOTHING_TO_PLAY: 'No abnormal in image level, Select annotations to play.',
  POINT_BUTTON: 'Point Drawer',

  // STATUS
  DETECTING: 'Detecting',
  WAITING: 'Waiting',
  QUEUING: 'Queuing',
  DETECTION_FAILURE: 'Detection failed',
  UPLOAD_FAILURE: 'Upload failed',
  SLIDE_ID_USED: 'Slide is Duplicated',
  PIPELINE_TIMEOUT: 'Calculate timeout',
  BROKEN_FILE: 'Broken source file',
  NETWORK_ERROR: 'Network error',
  VERIFICATION_SERVICE_DOWN: 'Services down',
  BAD_JSON_FILE: 'Bad annotations',
  SLIDE_ID_NOT_REGISTER: 'Slide not Registered',
  SLIDE_ID_INVALID: 'Slide is Invalid',
  JSON_FILE_NOT_FOUND: 'No annotation file',
  FEATURE_NOT_EXISTS: 'Not authorized access <feature>',
  FEATURE_ID_EXPIRED: 'Feature has expired ',
  SHARING_VIOLATION: 'Authorization Service Conflict',
  MESSAGE_QUEUE_FAIL: 'Algorithum error (1001)',
  INVALID_TASK_TYPE: 'Invalid Task',
  FEATURE_ID_NOT_A_NUMBER: 'Error in Slide No.',
  PIPELINE_A_ERROR: 'Algorithm Error (a)',
  PIPELINE_B_ERROR: 'Algorithm Error (b)',
  UNKOWN_DETECTION_FAILURE: 'Algorithm Error (unknown)',

  // Dashboard
  // WSI
  UPLOAD_WSI: 'Upload',

  // Batch Detection
  DETECTION_TOOL_BOX: 'Detection Tools',

  // SETTING
  USER_INFO: 'User Information',
  CONFIRM_UPDATE_USERINFO: 'Confirm Change',
  OLD_PASSWORD: 'Old Password',
  NEW_PASSWORD_CONFIRM: 'Confirm Password',
  NEW_PASSWORD: 'New Password',
  CHANGE_PASSWORD: 'Change Password',
  CHANGE_SMBPASS: 'Change Upload Password',
  CHANGE_ADMIN_KEY: 'Change Admin Key',
  OLD_ADMIN_KEY: 'Old Admin Key',
  NEW_ADMIN_KEY: 'New Admin Key',
  CONFIRM_CHANGE_PASSWORD: 'Confirm Change',
  OLD_ADMIN_KEY_NOT_EMPTY: 'Old admin key cannot be empty',
  NEW_ADMIN_KEY_NOT_EMPTY: 'New admin key cannot be empty',
  ADMIN_KEY_FORMAT:
    'Password must between 8 and 16 characters, including a number and an uppercase letter',
  OLD_PASSWORD_NOT_MATCH: 'Old password is incorrect, please try again',
  OLD_ADMIN_KEY_NOT_MATCH: 'Old admin key is incorrect, please try again',

  // Authorization
  NO_AUTH_TO_DO: "You don't currently have permission to do that",
  // Missing wsi
  WSI: 'INVENTORY',
  SAMBA: 'Upload Folder',

  // Detection Tool Box
  DETECT_ALL_IMAGE: 'ALL',
  DETECT_FAILED_IMAGE: 'FAILURE',
  DETECT_PERIOD: 'Time Period',
  CONFIRM_DETECT_TEXT: 'Confirm to detect the selected picture?({count})',

  // Platform Tool Box
  PLATFORM_TOOLBOX_TITLE: 'Platform Tools',
  RESTART_PLATFORM: 'Restart Platform',
  RESTART_PLATFORM_HINT: 'Are you sure you want to restrat platform? It will take 1-2 minutes.',
  RESTART_PLATFORM_SUCCESS: 'Successfully restarted platform!',
  RESTART_PLATFORM_FAIL: 'Failed to restart platform, please contact IT support',
  RESTARTING_PLATFORM: 'Restarting platform, please wait...',

  GlandularEpithelium: 'Glandular Epithelium',
  HighGradeSquamous: 'High Grade Squamous',
  LowGradeSquamous: 'Low Grade Squamous',
  Microorganisms: 'Others',
  Endometrial: 'Endometrial',
  // TCT
  TCT_NILM: 'Negative for Intraepithelial Lesion or Malignancy',
  TCT_ROI: 'Other',
  'TCT_ASC-US': 'Atypical squamous cells of undetermined significance',
  TCT_LSIL: 'Low-grade squamous intraepithelial lesion',
  'TCT_ASC-H': 'Atypical squamous cells cannot exclude HSIL',
  TCT_HSIL: 'High-grade squamous intraepithelial lesion',
  TCT_SCC: 'Squamous cell carcinoma',
  TCT_AGC: 'Atypical glandular cells',
  'TCT_AGC-N': 'Atypical glandular cells, favor neoplastic',
  TCT_AIS: 'Endocervical adenocarcinoma in situ',
  TCT_ADC: 'Adenocarcinoma',

  // Organism
  ORG_FUNGI: 'Candida spp.',
  ORG_TV: 'Trichomonas vaginalis',
  ORG_CC: 'Clue cell',
  ORG_ACTINO: 'Actinomyces spp.',
  ORG_HSV: 'Herpes simplex virus',
  ORM_EMC: 'Endometrial cells',

  // Cancer type
  CANCER_CERVICAL: 'CERVICAL',
  CANCER_URINE: 'URINE',
  CANCER_BLADDER: 'BLADDER',
  CANCER_GENREAL: 'GENREAL',

  // Page config
  IMAGE_LIST_COLUMNS: 'Image List Columns Display',
  RESET_IMAGE_LIST_COLUMNS: 'Reset',
  DEFAULT_CANCER_TYPE_SETTING: 'Image Type Settings',
  DEFAULT_CANCER_TYPE: 'Default Image Type: ',
  AVAILABLE_CANCER_TYPES: 'Available Image Type: ',
  IMAGE_REMOVED:"Image Removed",
  // disk usage
  DISK_CLEAN: 'Clean',
  DISK_USAGE_WARNING: 'Server is running out of storage space.',
  DISK_USAGE_ERROR: 'Server is running out of storage space and is out of service.',
  DISK_CLEAN_INFO: "Select a date range to clean up the disk",
  // image collection
  COLLECTION_TITLE: '{collected, plural, =true {Modify Collection} =false {Make a Collection}}',
  COLLECTION_CONFIRM: '{collected, plural, =true {Modify} =false {Collect}}',
  COLLECTION_CANCEL: '{collected, plural, =true {Discard} =false {Cancel}}',
  COLLECTION_REMARK_INPUT: 'Remark',
  COLLECT_SUCCESS: 'Collected',
  MARK_MODIFY_SUCCESS: 'Modified',
  GET_COLLECTION_FAILED: 'Failed to fetch collection',
  MARK_MODIFY_FAILED: 'Failed to Modify',
  COLLECT_FAILED: 'Collection failed',
  DISCARD_SUCCESS: 'discarded',
  DISCARD_FAILED: 'Discard failed',
  HOSPITAL: 'Unit',
  TODAY_UPLOADED: 'Daily Uploaded',
  TODAY_ANALYSIS: 'A.I. Daily Diagnosed',
  ACC_UPLOADED: 'Total Uploaded',
  ACC_ANALYSIS: 'Total diagonsis',
  TODAY_UPLOADED_TITLE: 'Daily Uploaded',
  TODAY_ANALYSIS_TITLE: 'A.I. Daily Diagnosed',
  ACC_UPLOADED_TITLE: 'Total Uploaded',
  ACC_ANALYSIS_TITLE: 'Total diagonsis run by A.I.',
  NEGATIVE_PER_SLIDE: 'Neg A.I. average time for diagnosis',
  POSITIVE_PER_SLIDE: 'Pos A.I. average time for diagnosis',
  NEG_POS_TIME: 'AI Diagnosis Time(-ve/+ve/avg)',
  STORE_PERCENT: 'Data storage volume/proportion',
  LICENSE_USE_TOTAL: 'Authorized',
  ALL_RUN_TIME: 'Cumulative Running Time',
  PRO_VERSION: 'Product Version',
  JAN: 'JAN',
  FEB: 'FEB',
  MAR: 'MAR',
  APR: 'APR',
  MAY: 'MAY',
  JUN: 'JUN',
  JUL: 'JUL',
  AUG: 'AUG',
  SEP: 'SEP',
  OCT: 'OCT',
  NOV: 'NOV',
  DEC: 'DEC',
  HOSPITAL_POSITION: 'Hospital Location',
  ALGO_PERFORMANCE: 'AI Performance',
  LSIL_SENSITIVITY: 'Sensitivity (LSIL and above)',
  AI_JUDGE: 'AI',
  HUMAN_JUDGE: 'Artificial',
  ANNUAL_WORKLOAD: 'Annual Overview',
  CONSISTENCY: 'Interpretative Consistency',
  VISUALIZATION_TITLE: 'Cervical Cancer Cytology A.I. Big Data Platform',
  LOADING_TEXT: 'Loading',
  POSITIVE_RATE: 'Postive Rate',
  TOTAL: 'Total',
  QUALITY_CONTROL_PERCENT: 'Quantity Satisfaction',
  PRINT_REPORT: 'Print',
  SORT: 'Arrangement',
  MOST_SELECTED_WARNING: 'Select up to {n} items to display',
  LAYOUT_CHANGE: 'Layout Modification',
  LIMIT_TEXT_LENGTH: 'Max. {n} items',
  REPORT_STAGE_FREE: 'Free',
  REPORT_STAGE_EDITING: 'Work in progress',
  REPORT_STAGE_OCCUPIED: 'Editing',
  REPORT_STAGE_PRINTED: 'Printed',
  STAGE: 'Working condition',
  PIPELINE_NOT_FOUND: 'Pipeline not found',
  PIPELINE_INCONSISTENT_USER: 'Inconsistent user',
  PIPELINE_NOT_REVERSABLE: 'Pipeline not reversable',
  PIPELINE_NOT_SKIPPABLE: 'Pipeline not skippable',
  ALL: 'Overall',
  CERVICAL_LESIONS_DISTRIBUTION: 'Distribution of Cerival Lesions',
  CERVICAL_MICROBIOLOGY_DISTRIBUTION: 'Distribution of Cerival Microorganisms',
  IMAGE_NOT_FOUND: 'Image not found',

  SHARE: 'Share',
  SHARE_MODAL_TITLE: 'Share this page',
  SHARE_LABEL: 'Share Link',
  COLLECTION: 'Bookmark this page',
  COPY: 'Copy',
  COPY_TO_CLIPBOARD: 'Copy to clipboard',
  POLYGON: 'Polygon',
  FULLSCREEN: 'Full Screen',
  TEXT_NOT_EMPTY: 'Content is required',
  ACTION: 'Action',
  ORAN_NAME: 'Site',
  SERVICE_HOST: 'Site Address',
  SERVICE_PORT: 'Site Port',
  TODAY: 'Today',
  THREE_DAYS: 'Three days',
  ONE_WEEK: 'Seven days',
  ONE_MONTH: 'Thirty days',
  DATE_UNLIMIT: 'ALL',
  REGISTER_DATE: 'Register date',
  REPORTER: 'Follow-up Reader',
  PUSH_BEFORE: 'Not push',
  PUSH_PENDING: 'Pending',
  PUSH_SUCCESS: 'Push success',
  PUSH_FAIL: 'Failed to push',
  PUSH_STATUS: 'Push status',
  NULL: 'NULL',
  ACTION_VIEW: 'View',
  ACTION_DELETING: 'Deleting',
  ACTION_TO_FETCH: 'Access to image',
  ACTION_FETCHING: 'Fetching',
  ACTION_FETCH_FAILED: 'Fail to fetch!',
  IMAGE_STATUS: 'Action',
  SYSTEM_SETTINGS: 'System settings',
  PATHLOGY_ID_FROM: 'Source of pathology id',
  FROM_FILENAME: 'From filename',
  FROM_OCR: 'From OCR',
  DISK_CLEAN_THRESHOLD: 'Disk clean threshold',
  DISK_CLEAN_THRESHOLD_LOWER_LIMIT_WARNING: 'Disk clean threshold not lower than {num} %',
  DISK_CLEAN_THRESHOLD_UPPER_LIMIT_WARNING: 'Disk clean threshold not upper than {num} %',
  //toptab
  WAIT_INITIAL_DIAGNOSIS: 'Wait Initial Read',
  INITIAL_DIAGNOSIS: 'Initial Read',
  WAIT_RE_DIAGNOSIS: 'Wait Follow-up Visit',
  AGAIN_DIAGNOSIS: 'Follow-up',
  RE_DIAGNOSIS: 'Diagnosed',
  RE_TIMEOUT: 'Timeout',
  MY_COLLECTION: 'My collection',
  TO_BE_ALLOCATED: 'To be allocated',
  PUSHED: 'pushed',

  //status
  YANXING_DEGREE: 'Degree of inflammatory cells',
  YIWU_DEGREE: 'Covering degree of foreign matters',
  MOHU_DEGREE: 'Scanning quality',

  WORK_STATISTICS: 'Work statistics',
  STATISTICS_DOCTOR_TITLE: 'Liquid based cytological diagnosis',
  STATISTICS_YEAR_TITLE: 'Annual workload statistics',
  JUNIOR_STATISTICS: 'Initial Read',
  REPORT_STATISTICS: 'Follow-up',
  ALL_STATISTICS: 'ALL',

  MANUAL_ADJUSTMENT: 'Manual adjustment',
  UPLOAD_RESULT: 'Upload result',
  DOWNLOAD_LIST: 'Download list',
  DOWNLOAD_ERROR: 'There is no data in the export results. Please check your search criteria',
  DOWNLOAD_ERROR_MORE:
    'There is too mor data in the export results. Please check your search criteria',
  UPLOAD_RESULT_TEXT: 'Click or drag file to this area to upload',
  UPLOAD_RESULT_FILE: 'Ground truth must be zip format',
  UPLOAD_RESULT_ERROR: 'error',
  ZIP_NAME: 'Cell list',

  ALLOCATION_SETTINGS: 'Allocation settings',
  RECOVERY_TIME: 'Recovery time:',
  RECOVERY_TIME_MIN: 'The recovery time shall not be less than {num} day(s)',
  RECOVERY_TIME_MAX: 'The recovery time shall not be more than {num} day(s)',
  RECOVERY_TIME_UNIT: 'day(s)',

  AUTO_PLAY_SETTINGS: 'Autoplay settings',
  PLAY_SPAN: 'Playback interval:',
  PLAY_UNIT: 'second(s)',
  PLAY_SPAN_MIN: 'Auto play interval shall not be less than {num} second(s)',
  PLAY_SPAN_MAX: 'Auto play interval shall not be more than {num} second(s)',

  BASE_INFO: 'Basic information',
  CLINICAL_INFO: 'Clinical information',
  GYNEC_INFO: 'Gynecological information',
  SAMPLE_INFO: 'Sample information',
  APPLY_INFO: 'Application information',

  //tabletabs
  BATCH_ALLOCATION: 'Batch allocation',
  SINGLE_ALLOCATION: 'Signle allocation',
  ALLOCATION: 'Allocation',
  ASSIGNED: 'Assigned',
  TIMEOUT: 'Timeout',
  MICROBIOLOGY: 'Others',
  WAIT_ALLOCATION: 'To be allocated',
  CANNOT_ALLOCATION: 'Cannot allocate',
  SELECT_DOCTOR: 'Please select the assigned doctor',
  SELECTED_IMAGE_NUM: 'Select {num} slides',
  ALLOCATION_WARN: 'The number of doctors should be less than the number of data',

  BATCH_PUSH_NUM: 'Are you sure to push{num} case(s)?',
  BATCH_PUSH: 'Batch push',
  PUSH_SUBMIT: 'Export',
  BATCH_PUSH_MEG: 'success:{success},failed:{failed}',
  RECALCULATE: 'Recalculate',
  DEL_IMAGE: 'Delete image',
  IS_DEL_IMAGE: 'Delete image?',
  HSD_REVIEWS:'Had reviewed',
  SLIDE_SRC: 'Slide source',
  SCAN_TIMES: 'Multiple',
  AUTO_QUALITY_CTRL: 'Specimen adequacy',
  JUNIOR_TIME: ' Initial Date',
  SENIOR_TIME: 'report date',
  DISTRIBUTE_STAGE: 'allocation status',
  JUNIOR_REPORTER: 'Initial Reader',
  TIMEOUT_TITLE: 'You have a timeout report, please handle it as soon as possible！',
  TIMEOUT_READ: 'Read',
  TIMEOUT_HAS_READ: 'Readed',
  TIMEOUT_IGNORE: 'Ignore',
  TIMEOUT_LIST_TITLE: 'Message list',

  REPORT_STAGE_DISABLE: 'Disabled',
  REPORT_STAGE_WAIT_ALLOCATION: 'To be allocated',
  REPORT_STAGE_WAIT_JUNIOR: 'Waiting for first visit',
  REPORT_STAGE_JUNIORING: 'At the first visit',
  REPORT_STAGE_JUNIORED: 'First Visit',
  REPORT_STAGE_WAIT_SENIOR: 'To be reviewed',
  REPORT_STAGE_SENIORING: 'Reviewing',
  REPORT_STAGE_WAIT_SENIORED: 'Reviewed',
  REPORT_STAGE_WAIT_PRINTED: 'Printed',

  SUBMIT_SENIOR: 'Examine',

  IMAGE_QUERY_YANXING: 'The occlusion rate of Inflammatory cells is over 75%	',
  IMAGE_QUERY_XUEYE: 'The occlusion rate of Hematocyte is over 75%',

  REALNAME_NOT_LEN: 'Full name annot exceed 10 characters',
  UPLOAD_LOGO_ERROR: 'You can only upload JPG/PNG file!',
  UPLOAD_LOGO_SIZE_ERROR: 'Image must smaller than 512kb!',
  EMAIL_NOT_CHINESE: 'The E-mail cannot contain Chinese character',
  LABEL_ERROR_LEN: "Label's name annot exceed 10 characters",
  LABEL_ERROR_QUERY: 'The number of conditions cannot be greater than 5',
  Other: "Processing and errors",
  AUTO_IMAGE_SATISFACTORY: 'Satisfactory',
  AUTO_IMAGE_UNSATISFACTORY: 'Unsatisfactory',
  AUTO_LESS: 'Less cells',
  AUTO_LESS_MOHU_M: 'Less cells (Scan blur)',
  AUTO_LESS_MOHU_H: 'Less cells (Poor scanning quality)',
  AUTO_LESS_YANXING_H: 'Less cells(Inflammatory cells>75%)',
  AUTO_LESS_YIWU_H: 'Less cells(Blood cell occlusion rate>75%)',
  AUTO_LESS_YANXING_YIWU_H: 'Less cells (Inflammatory cells>75% & Blood cell occlusion rate>75%)',
  AUTO_NORMAL: 'Normal cell',
  ADD_TAB_ERROR: 'Condition cannot be repeated',

  CONFIDENCE: 'Confidence',
  NEGATIVELESION: 'Suspected Cells',
  CLASSIFICATION_TYPE: 'Classification style',
  CUSTOM_7CLASSIFICATION: 'Custom 7 Classification',
  CUSTOM_5CLASSIFICATION: 'Custom 5 Classification',
  TBS15CLASSIFICATION: 'TBS 15 Classification',
  Suspected_Anomalous_Size: 'Suspected exception display size',

  /*V14*********************************************************************** */

  Others: "Others",
  CHECK_PAGE: 'Register',
  CHECK_IMPORT: 'Import',
  PRODUCTION_PAGE: 'Production',
  REPORT_PAGE: 'Report',
  CHECK_NULL: 'Please enter the apply id',
  CHECK_FORM: 'Registration Form',
  APPLY_ID: 'Reference ID',
  BASIC_INFO: 'Essential info',
  INSPECTION_INFO: 'Inspection info',
  CHECK_SAMPLE_INFO: 'Specimen info',
  UNTITLE: '　',
  UNTITLE_NULL: '',
  CHECK_IN_NATION: 'Nation',
  CHECK_IN_WORK: 'Work',
  CHECK_IN_NAVTICEPLACE: 'Place',
  MARRIED: 'Married',
  UNMARRIED: 'Unmarried',
  MARRY: 'Marriage',
  M_END: 'Menopause',
  URGENT_CASE: 'Urgent cases',
  OUTPATIENT_DEP: 'Outpatient Department',
  HOSPITALIZATION: 'Hospitalization',
  PHUYSICAL_EXAM: 'Physical examination',
  CELLS: 'Cells',
  OWN_EXPENSE: 'Private',
  MEDICAL_INS: 'Cooperative medical service',
  CERVICAL_EXFOLIATED_CELLS: 'Cervical',
  CHECK_IN_YEAR: 'Years',
  CHECK_IN_MONTH: 'Months',
  CHECK_IN_DAY: 'Days',
  CARD_TITLE: 'Certificates',
  ID_CARD: 'ID',
  OFFICER_CARD: 'Official card',
  PASSPORT: 'Passport',
  APPLY_TIME: 'Application time',
  INSPECTION_TIME: 'Inspection time',
  PATHOLOGY_TYPE: 'Pathological type',
  INSPECTION_PERSON: 'Censorship person',
  INSPECTION_SEPARTMENT: 'Referred Site',
  REJECT_DOCTOR: 'Reject doctor',
  REJECT_TIME: 'Reject time',
  REJECT_REASON: 'Reject reason',
  SEE_TYPE: 'Purpose of Vist',
  PATIENT_ID: 'paient Id',
  OUTPATIENT_ID: 'Outpatient Id',
  HOSPITAL_ID: 'Inpatient Id',
  HOSPITAL_AREA: 'Inpatient Area',
  MONEY_NUM: 'Money',
  MONEY_TYPE: 'Fee',
  PAIENT_PRIVACY: 'Confidentials ',
  PAIENT_PRIVACY_NORMAL: 'General',
  PAIENT_PRIVACY_PRI: 'Privacy',
  SAMPLE_CONTAGION: 'Infectious',
  PATIENT_SUMMARY: 'Summary',
  CLINICAL_PATIENT: 'Follow-up',
  CHECK_LIST_TOTAL_COUNT: '{total} in total',
  CHECK_SUCCESS: 'Registration successful',
  ADD_SAMPLE: 'Add sample',
  APPLY_ERROR: 'Unable to obtain data, please query again',
  OPERATION_DIAGNOSIS: 'Surgical findings',
  OTHER_DIAGNOSIS: 'Other inspection',
  SPECIMEN_TYPE: 'Specimen type',
  SPECIMEN_DATE: 'Sampling date',
  SPACEMEN_LOC: 'Sampling position',
  SPACEMEN_NAME_PLACEHOLDER: 'Specimen',
  SPACEMEN_EVALUATE: 'Evaluation',
  CLINIC_DIAGNOSIS: 'Clinical',
  SPACEMEN_NUM: 'Specimen number',
  RECEIVE_TIME: 'Receive time',
  RECEIVE_PERSON: 'Receiver',
  CHECK_SAVE: 'Save and create',
  MODIFY_SAVE: 'Save',
  CHECK_ADD: 'Create',
  CHECK_REJECT: 'Rejection',
  CHECK_CANCEL: 'Cancel registration',
  CHECK_BACK: 'Back',
  BACK_TITLE: 'Cancel registration?',
  BACK_TEXT: 'Cancellation of registration will delete the case, please operate with caution',
  BACK_PLACEHOLDER: 'Please enter the application number',
  UPDATE_CHECK_SUCCESS: 'Saved successfully',

  HIS_TITLE: 'Before modification:{info}',
  SAVE_MUST_IS_NULL: 'please improve the information',
  REJECT_REASON1:
    'The contents in the application form are inconsistent with the name / quantity of the submitted samples',
  REJECT_REASON2: 'Inconsistent application form and incomplete information',
  REJECT_REASON3: 'The submitted samples are not labeled',
  REJECT_REASON4: 'The label submitted for inspection is damaged and cannot be identified',
  REJECT_REASON5: 'The specimens are seriously self melted, rotten and dried up',
  REJECT_REASON6: 'No tissue is found in the sample bottle (bag) submitted for examination',
  REJECT_REASON7: 'Stop or cancel the medical order of the sample application form',
  REJECT_REASON8:
    'Other conditions that may affect the feasibility and diagnostic accuracy of pathological examination',
  REJECT_REASONS: 'Please select the reason for rejection',

  CANCEL_TITLE: 'Cancel registration',
  CANCEL_TEXT: 'Cancel registration?',
  SAVE_TITLE: 'Lack of information',
  SAVE_TEXT: 'please improve the information',
  SAVE_APPLY_ID: 'Please import the application number',
  RE_REGISTER: 'Duplicate application number',
  SAVE_TEXT_ERROR: 'Information validation error, please check',
  BACK_ID_ERROR:
    'The application number is inconsistent with the application number information of the current level package',
  ADD_CHECK_ERROR: 'Application number already exists',
  EVALUATE_EXCELLENT: 'Excellent',
  EVALUATE_GOOD: 'Good',
  EVALUATE_BAD: 'Bad',
  EVALUATE_BAD_REASON1: 'Dirty background',
  EVALUATE_BAD_REASON2: 'Glue overflow',
  EVALUATE_BAD_REASON3: 'Overlap',
  EVALUATE_BAD_REASON4: 'Poor dyeing',
  EVALUATE_BAD_REASON5: 'Small number of cells',
  EVALUATE_BAD_REASON6: 'Bubble',
  SELECTED_DIS: 'Please select an assignor',

  CHECK_TAB1: 'Receiving specimens',
  CHECK_TAB2: 'Reject specimen',

  SUMMARY_TITLE: 'Case summary (please select all appropriate items)',
  SUMMARY_CHOICE1: 'Oral contraceptives or contraceptive needles',
  SUMMARY_CHOICE2: 'Intrauterine device(IUD)',
  SUMMARY_CHOICE3: 'Pregnant',
  SUMMARY_CHOICE4: 'Abnormal bleeding',
  SUMMARY_CHOICE5: 'lactation',
  SUMMARY_CHOICE6: '4 months postpartum',
  SUMMARY_HPV1: 'High risk HPV detection(+)',
  SUMMARY_HPV2: 'Detection of moderate risk HPV(+)',
  SUMMARY_HPV3: 'Detection of low-risk HPV(+)',
  SUMMARY_CELL_DATE: 'Previous cytological examination date',
  SUMMARY_HPV_DATE: 'Previous HPV inspection date',
  SUMMARY_REASULT: 'Result',
  OPERARION_AFTER: 'After operation',
  OPERARION_AFTER_OPTION1: 'Hysterectomy',
  OPERARION_AFTER_OPTION2: 'Conization or LEEP',
  OPERARION_AFTER_OPTION3: 'Oophorectomy',
  OPERARION_AFTER_OPTION4: 'Radiotherapy',
  SEARCH_TEXT: 'Name / Pathology ID',

  REGISTER_ID: 'Registration num',
  SAMPLE_NUM: 'Specimens num',
  PRO_ADD: 'Add slide',
  PRO_DISTRIBUTION: 'Production allocation',
  PRO_START: 'Start',
  PRO_END: 'Completed',
  PRO_FINISH: 'End',
  PRO_WAIT: 'Wait',
  PRO_ING: 'In progress',
  PRO_EVALUE: 'Evaluate',
  PRO_PRINT: 'Print label',
  PRO_IS_MINE: 'My Input',
  PRO_SELECTED_WARNING: 'Please select slide',
  PRO_SELECTED_MORE: 'The operation is out of range, please reselect',
  PRO_NO_PRODUCER: 'This slide is not assigned',
  SECTION_NUM: 'Number of slices',
  SECTION_INDEX: 'Slice number',
  SECTION_MAKER: 'film producer',
  SECTION_STATUS: 'Production status',
  SECTION_START_TIME: 'start time',
  SECTION_END_TIME: 'Complete time',
  TOTAL_COUNT: '{total} in total',
  PRINT_LIST: 'Print list',
  WAIT_PRINT: 'To be printed',
  WAIT_SEND: 'To be issued',
  RE_SEND: 'Issued',
  SEND_SUCCESS: 'Issued successfully',
  PRINT_SUCCESS: 'Print successful',
  PRINT_WARNING: 'Print complete?',
  UPDATE_TAG_ID: 'Modify tag Id',
  UPDATE_TAG_ID_IMAGE: 'Slide head image',
  UPDATE_TAG_ID_INPUT: 'Enter pathology id manually',
  SYNCHRO_AI: 'Synchronize',
  BATCH_SUMBIT: 'Batch audit',
  BATCH_CHANGE: 'Batch reassignment',
  CHANGE_DOCTOR: 'Reassignment',
  CHANGE_DOCTORS: 'Reassigned doctor',
  CHANGE_DOCTORS_SUCCESS: 'Successful reassignment',
  BATH_SEND: ' Batch distribution',
  WITHDRAW: 'Withdraw',
  WITHDRAW_SUCCESS: 'Withdrawal succeeded',
  PREVIEW: 'Preview',
  SEND: 'Grant',
  SERIAL_NUMBER: 'No.',
  EXPLAIN: 'Explain',
  DIS_SUCCESS: 'Allocation succeeded',
  RESTORE_SUCCESS: 'Retesting',
  RESTORE_IMAGE_ERROR: 'It contains the data being detected and cannot be recalculated',

  SETTINGS_LABEL: 'Product configuration',
  TAG_ID_SETTING: 'Pathology Id setting',
  SEND_DEPARTMENT_SETTING: 'Inspection Unit Settings',
  DISTRIBUTION_SETTING: 'Slide Delivery Settings',
  TAG_ID_SETTING_TITLE: 'Pathology Id setting (liquid based cytology)',

  SERVICE_SET: 'Get from report page',
  VIEW_API_SET: 'Get from the application page',
  SQL_TYPE: 'Database type',
  SQL_USERNAME: 'Database username',
  SQL_PWD: 'Database password',
  SQL_ADDR: 'Database address',
  SQL_NAME: 'Database name',
  VIEW_NAME: 'View name',
  MYSQL: 'Mysql',
  SQLSERVER: 'Sqlserver',
  CHINESE_TITLE: 'Chinese name',
  PARAMS_TITLE: 'Field name',

  PREFIX: 'Prefix',
  DEFAULT_NUMBER: 'Default number',
  CONNECTOR: 'Connector',
  NUM_LEN: 'Digital length',
  YYYY: 'YYYY',
  YY: 'YY',
  TAG_ID_SHORT: '-',
  TAG_ID_LINE: '一',
  TAG_ID_WAVE: '～',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  ADD_LABEL: 'Add',
  EDIT_LABEL: 'Edit',
  REMOVE_LABEL: 'Delete',

  TITLE_ORGANIZATION: 'Referred Site',
  TITLE_DEPARTMENT: 'Sample Department',
  TITLE_DOCTOR: 'Sample doctor',
  ADD_ORGANIZATION: 'Add institutions',
  EDIT_ORGANIZATION: 'Edit institutions',
  DEL_ORGANIZATION: 'Delete institutions',
  ADD_DEPARTMENT: 'Add Department',
  EDIT_DEPARTMENT: 'Edit Department',
  DEL_DEPARTMENT: 'Delete Department',
  ADD_DOCTOR: 'Add doctor',
  EDIT_DOCTOR: 'Edit doctor',
  DEL_DOCTOR: 'Delete doctor',
  DEL_WARNING: 'Are you sure to delete"{name}"',
  DEL_MODAL_WARNING: 'Are you sure to delete:',
  EDIT_WARING: 'Please select a piece of data',
  DIS_MODE: 'Delivery Method',
  DIS_SCOPE: 'Assignable range',
  DIS_ACTOR: 'Film sending role',
  AUTO_LABEL: 'Automatic',
  HAND_LABEL: 'Manual',
  DIS_TIME_LABEL: 'Specify the time for automatic film delivery',
  DIS_ERROR: 'Sending role is required',
  DIS_TIME_LAN: 'Serial number',
  DIS_TIME_AVER: 'Positive average',
  DIS_TIME_TIME: 'Every Day',
  INNER_LABEL: 'In hospital',
  OUTTER_LABEL: 'Out of hospital',
  AI: 'AI',
  EXPERT: 'Expert',
  OVERTIME_SET: 'Timeout setting:',
  REPORT_HISITATION_PERIOD: 'Reporting period:',
  HISITATION_WARN: 'The value range of the report hesitation period is 2-120 hours',
  HOURS: 'Hours',
  INCIDENCE_RATE: 'Incidence Rate (1/10 million)',
  DIAGNIOSIC_MODE: 'Node setting',
  DIAGNIOSIC_MODE_ONLINE: 'Full Procedure',
  NODE_MODE: 'Mode',
  NODE_TYPE: 'Type',
  DIAGNIOSIC_MODE_ALONE: 'Stand-alone',
  REGISTER_ACCOUNT: 'Registered account',
  REGISTER_ADD: 'New user',
  REGISTER_EDIT: 'Edit user',
  REGISTER_DEL: 'Delete user',
  REGISTER_ADD_EXPERT: 'New expert',
  REGISTER_EDIT_EXPERT: 'Edit expert',
  REGISTER_DEL_EXPERT: 'Delete expert',
  REGISTER_DEL_WARN: 'The selected users include users that cannot be deleted. Please select again',
  REGISTER_DEL_MESSAGE: 'Are you sure to delete the selected user?',
  ACCOUNT_LABEL: 'Account name',
  ACCOUNT_NAME: 'Username',
  ACCOUNT_ACTOR: 'Entity',
  ACCOUNT_UNITS: 'Unit',
  ACCOUNT_PWD: 'Password',
  ACCOUNT_RE_PWD: 'Repassword',
  ACCOUNT_TEL: 'Telephone',
  ACCOUNT_PHONE: 'Phone',
  ACCOUNT_EMAIL: 'Email',
  ACCOUNT_AUTOGRAPH: 'Upload signature photos',
  IMAGE_FILTER_SETTING: 'Image filtering settings',
  LIMIT_FILE: 'Limit file format',
  THRESHOLD: 'Threshold',
  PLEASE_SELECT: 'Please select',
  DIY_FILE: 'Custom',
  DATA_CLEAN: 'Data cleaning',
  NODE_MODE_LOC: 'Stand-alone',
  NODE_MODE_REMOTE: 'Center',
  NODE_MODE_COLONY: 'Colony',
  APPLY_BTN:"Apply",
  ACCEPTED:"Access succeeded",
  APPLYING:"Pending approval",
  REJECTED:"Rejected",
  LEAVING:"",
  LEAVED:"Paused",
  IP_ERROR:"Please check whether the IP address and port are correct",
  // report
  PRINT_BATCH_REPORT: 'Print Report',
  EXPORT_REPORT: 'Export Report',
  COLLECT_LABELS: 'Collection Cases',
  STAGE_EXPLAIN: 'Status Description',
  CANCEL_REPORT: 'Cancel Report',
  CANCEL_REPORT_TITLE: 'Please select the reason to cancel the report',
  CANCEL_REPORT_REASON1: 'Please select the reason to cancel the report',
  CANCEL_REPORT_REASON2: 'The production quality is unqualified. Please make a new production',
  SUGGEST_RE_SCAN: 'Re scanning is recommended',
  PLEASE_RE_SCAN: 'Please scan again',
  SUGGEST_RE_PRO: ' It is suggested to remake the production',
  PLEASE_RE_PRO: 'Please remake',
  CANCEL_SUCCESS: 'Cancel succeeded',
  CANCEL_FAILED: 'Cancel failed',
  SCAN_END: 'Analysis completed',
  JUNIOR_PART: 'Initial Reader Stage',
  OUTTER_DOCTOR: 'Specialists',
  RE_PART: 'Follow up stage',
  PRINT_TIME: 'Print date',
  SEND_TIME: 'Issue date',
  JUNIOR_RESULT: 'Initial Read',
  SENIOR_RESULT: 'Follow-up Read',
  DIS_TIME: 'Allocate time',
  BATCH_REPORT_DOENLOAD: 'Batch Report',
  DEL_SUCCESS: 'Deleted successfully',
  ADD_SUCCESS: 'Added successfully',
  INFO_CENTER: 'Message center',
  DATASOURCE: 'Data sources',
  DOMAIN_NAME: 'domain name',
  ACTION_SUCCESS: 'Operation succeeded',
  START_PALY_ERROR: 'The image is being labeled, and the playback function is suspended',
  NAME_NOT_NULL: 'Name cannot be empty, please check',
  RE_FILE: 'duplication file name!',
  TAG_ID_NOT_NULL: 'Pathology number cannot be empty',
  DIS_TO_ALL: 'Allocated / calculated({dis}/{all})',
  REPORT_IS_NULL:'Please interpret the report',
  
  //visualization
  HALF_YEAR: 'half a year',
  ONE_YEAR: 'a year',
  TWO_YEAR: 'two years',
  QUALITY_CTRL: 'QC Passing Rate',
  REPORT_CHECK_INFO: 'View registration form',
  ALGO_VERSION: 'Algorithm version :',
  POS_THRESHOLD: 'Positive threshold :',
  SUS_THRESHOLD: 'Suspected  threshold :',

  //中心端
  MONITOR_PREVIEW: 'Monitor Preview',
  CENTER_TITLE_SUB: 'Enter your account and password below',
  EXPERT_MANAGEMENT: 'Expert account management',
  HOSPITAL_CARD: 'Hospital certificate',
  MAPPING_ADDRESS: 'Mapping domain name',
  CARD_MARKER: 'identification',
  DOWNLOAD_LOG: 'Download log',
  ADD_HOSPITAL: 'Add Hospital',
  ADD_HOS_TEXT: 'Please click to select a file or drag the certificate into the area',
  DATA_PLATFORM: 'Big-data platform',
  DATA_PLATFORM_NAME: 'Cervical Cancer Cytology A.I. Big Data Platform',
  // 专家端
  ACCOUNT_TITLE: 'Account information',
  SETTING: 'set up',
  HAD_CANCEL: 'Cancelled',
  WAIT_DIAGNOSIS: 'To be diagnosed',
  DISTRIBUTABLE: 'Distributable',
  NOT_DISTRIBUTABLE: 'Not distributable',

  //error_code
  
  ERROR_5005: 'Not editable.',
  ERROR_5008: 'Report not recallable.',
  ERROR_5011: 'Not printable.',
  ERROR_5012: 'Not sendable.',
  ERROR_5015: 'You should distribute junior first',
  ERROR_502: '502:Internal network congestion',
  ERROR_5201: 'Group not found',
  ERROR_5103: 'Tried requesting third party, but failed.',
  ERROR_5203: 'Producing section exists.',
  ERROR_5501: 'Registeration already exists',
  ERROR_5602: 'HIS registration not exists.',
  ERROR_5604: 'third-party url not found.',
  ERROR_5650: 'View configuration error.',
  ERROR_5651: 'Database error.',
  ERROR_5652: 'No patient information found.',
  ERROR_5653: 'Remote diagnostics configuration missing information.',
  ERROR_5654: 'Failed to register with the central end.',
  ERROR_5701: 'Department already exists',
  ERROR_5707: 'Department root not found',
  ERROR_5803: 'Section not Reproducable',
  ERROR_6000: 'user not found',
  ERROR_6002: 'Account does not exist or wrong password',
  ERROR_6003: 'Admin key error',
  ERROR_6013: 'Something refers to user',
  ERROR_6014: 'Not allow to delete yourself',
  ERROR_6015: 'User has been deleted',
  ERROR_6019: 'User not found in current node',
  ERROR_9003: 'Account already exists.',
  ERROR_6300:'Applying or passed',
  ERROR_6301:'Wrong IP or port or incompatible backend version',
  //actor
  DEVOPS_ACTTOR: 'Maintain',
  ADMIN_ACTTOR: 'Administrators',
  DISTRIBUTOR_ACTTOR: 'Dispatcher',
  PRODUCER_ACTTOR: 'Cell producer',
  JUNIOR_DOCTOR_ACTTOR: 'Initial Reader',
  REGISTER_ACTTOR: 'Registrar',
  SENIOR_DOCTOR_ACTTOR: 'Follow-up Reader',
  REPORTER_ACTOR: 'Follow-up Reader',
  SUPER_ADMIN_ACTTOR: 'Super administrator',
  OUT_HOSPITAL_ACTOR: 'Personnel outside the hospital',
  EXPERT_SOURCE: 'Report Source',

  //user
  ACCOUNT_LENGTH: 'The account name is 1-20 characters long, and no blank space is allowed',
  ACCOUNT_NULL: 'Account name cannot be empty',
  USERNAME_LENGTH: "The user's name is 1-20 characters long, and no blank space is allowed",
  ACTOR_NULL: 'Please select a role',
  UNITS_NULL: 'Please select a company',
  TEL_ERROR: 'Contact phone format error',
  EMAIL_ERROR: 'Email format error',
  REPWD_ERROR: 'Please enter the password again',
  SPAN_ERROR: 'Spaces are not allowed',
  ACCOUNT_ADMIN_KEY: 'Secret key',
  ADMIN_KEY_LENGTH: 'The key length is 1-20 characters',
  ACCOUNT_AI: 'User name cannot start with AI',
  FAILED_REASON: 'Failure reason',
  DEL_SUCCESS_FAILSED: 'Deleted successfully:{success}/Delete failed:{failed}',

  //excel
  PRODUCTION_NAME: 'Product Name',
  EDITION_NAME: 'Product Version Information',
  PRINT_NAME: 'Print Information',
  AI_RES_CLASS: 'A.I. Result',
  EXCEL_NAME: 'AI aided diagnostic result of Pap smear by Cervical Sight',
  WARN_CHOOSE: 'Please select the data to export',

  //大屏
  POSITIVE_AGE: 'Age distribution of incidence rate',
  BACK:"Back",
  //自动质控
  // AUTO_CTRL_NUMS_GOOD:'Quantity of cells satisfied',
  // AUTO_CTRL_NUMS_LESS:'Low Quantity of cells',
  // CELL_SHELTER:"Inflammation/blood cell occlusion",
  // SCAN_QUAILITY_GOOD:"Good - Screening Quality",
  // SCAN_QUAILITY_VAGUE:"Screening partially blurred",
  // SCAN_QUAILITY_BAD:"Poor - Screening Quality",

  AUTO_CTRL_NUMS_GOOD:'Unsatisfactory',
  AUTO_CTRL_NUMS_LESS:'Unsatisfactory',
  CELL_SHELTER:"Unsatisfactory",
  SCAN_QUAILITY_GOOD:"Unsatisfactory",
  SCAN_QUAILITY_VAGUE:"Unsatisfactory",
  SCAN_QUAILITY_BAD:"Unsatisfactory",
};
