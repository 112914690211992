import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { Button, Drawer } from 'antd';
 
import CheckIn from '../CheckIn';
 
import { fetchCheckInfoByPathId,} from '../../actions/checkInAction';
class CheckInfoBtn extends Component {
 
  componentDidMount(){
 
  } 
  
  render() { 
    return (
      <div>
        <Button onClick={()=>{
          const tagId = this.props.currentImageInfo?.tagId;
        if (this.props.openRegistrationForm && tagId) {
          // this.props.fetchCheckInfoByPathId({ pathology_id: "T2300000"  });        
          this.props.fetchCheckInfoByPathId({ pathology_id:  tagId.trim() });          
        }
        this.props.onOpenRegistrationForm(true);
      }} >{intl.get('REPORT_CHECK_INFO')} </Button>
        <Drawer  
        title= {intl.get('REPORT_CHECK_INFO')}
        // bodyStyle={{ paddingBottom: 80, zIndex:99999 }}
        placement='right'
        width={980}
        onClose={()=>{
          this.props.onOpenRegistrationForm(false);
        }}
        visible={this.props.openRegistrationForm}
        extra={      
           <div>
             <Button onClick={()=>{
              this.props.onOpenRegistrationForm(false);
            }}>Cancel</Button>
            <Button type="primary" >
              OK
            </Button>
           </div>         
        }> 
         <CheckIn showPart="form" isModify={true} onOpenRegistrationForm={this.props.onOpenRegistrationForm} currentImageInfo={this.props.currentImageInfo} />
      </Drawer>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentImageInfo: state.imageSelection.currentImageInfo,
});

export default connect(mapStateToProps, { fetchCheckInfoByPathId },)(CheckInfoBtn);
