import React, { Component } from 'react';
import { connect } from 'react-redux';
import config from '../../config';
import RotatableImage from '../RotatableImage';
import setBaseUrl from '../../utils/urlUtils';
const ImgStyle = {
  boxShadow: "-6px 6px 8px 0px rgba(0, 0, 0, 0.2)",
  position: "absolute",
  left: 0,
  top: 0,
  zIndex: 1, /* Specify a stack order in case you're using a different order for other elements */
  cursor: 'pointer', /* Add a pointer on hover */
  background: "#faf7f5",
  width: "200px",
}
class ImageTag extends Component {
  render() {
    const { baseUrl } = config;
    const beforeUrl = setBaseUrl(baseUrl,this.props.currentImageInfo)
    // const tagUrl = `${beforeUrl}/api/image/associated/${this.props.currentImageKey}?type=label`;
    const tagUrl = `${beforeUrl}/imagetags/label/${this.props.currentImageKey}.jpg`;
    return this.props.currentImageKey && this.props.tagVisibility ? (
      <div>
        <RotatableImage src={tagUrl} imgStyle={ImgStyle} />
      </div>
    ) : (
      ''
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentImageKey: state.imageSelection.currentImageKey,
    tagVisibility: state.toolStatus.tagVisibility,
    currentImageInfo:state.imageSelection.currentImageInfo,
  };
};
export default connect(mapStateToProps, null)(ImageTag);
