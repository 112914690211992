import React,{Component} from 'react';
import { connect } from 'react-redux';
import styles from './index.less';
import intl from 'react-intl-universal';
class HistoryInfo extends Component {
   

  render() {
    const list = [
      { name: 'BASE_INFO' },
      { name: 'CLINICAL_INFO' },
      { name: 'GYNEC_INFO' },
      { name: 'APPLY_INFO' },
      { name: 'SAMPLE_INFO' }
    ];
    return (
      <div className={styles.historyInfo} ref={this.infoRef}>
        {list.map((item, index) => {
          return (
            <div key={index} className={styles.historyItem}>
              {intl.get(item.name)}
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});
export default connect(mapStateToProps, {})(HistoryInfo);
