/* jshint esversion: 9 */
/* @flow */
import { Empty, Spin } from 'antd';
import { OpenSeadragon } from 'openseadragon-fabricjs-overlay';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setCurrentZoom } from '../../actions';
import { getCureentAnnotation } from '../../selectors/annotationSelectors';
import CenterConsole from '../CenterConsole';
import ImageTag from '../ImageTag';
import NavigatorPanel from '../NavigatorPanel';
import AnnotationsOfNavigator from './annotationsOfNavigator';
import AnnotationsOfWSI from './annotationsOfWSI';
import ImageOpener from './ImageOpener';
import style from './index.less';
import PointAnnotation from './PointAnnotation';
import RectAnnotation from './RectAnnotation';
import SiderLeftControl from './SiderLeftControl';

class ImageViewer extends Component {
  componentDidMount() {
    this._initOpenSeadragon();
  }

  componentWillUnmount() {
    window.osdviewer && window.osdviewer.destroy();
    window.osdviewer && window.osdviewer.close();
    window.osdviewer = null;
  }

  _initOpenSeadragon() {
    this.OpenSeadragon = OpenSeadragon({
      id: 'seadragon-viewer',
      showNavigationControl: false,
      clickToZoom: 'false',
      gestureSettingsMouse: {
        clickToZoom: false
      },
      preserveImageSizeOnResize: true,
      defaultZoomLevel: 0, // detection result created at level 0
      minZoomLevel: 0,
      zoomInButton: 'zoom-in',
      visibilityRatio: 0.5,
      zoomPerScroll: 1.3,
      maxZoomPixelRatio: 4, // maximum zoom:80x
      maxZoomLevel: 0.00625,
      showNavigator: true,
      navigatorId: 'image-navigator',
      animationTime: 0,
      timeout: 600000,
    });
    this.OpenSeadragon.addHandler('zoom', (e) => {
      this.props.setCurrentZoom(this.OpenSeadragon.viewport.viewportToImageZoom(e.zoom * 20));
    });
    window.osdviewer = this.OpenSeadragon;
  }

  _showAnnotation20X = () => {
    const anno = this.props.currentAnnotation;
    if (anno && anno.px && anno.py && this.OpenSeadragon) {
      if (anno.type === 'Box') {
        const { x, y } = this.OpenSeadragon.viewport.imageToViewportCoordinates(
          Number(anno.px),
          Number(anno.py)
        );
        this.OpenSeadragon.viewport.zoomTo(this.OpenSeadragon.viewport.imageToViewportZoom(0.5));
        this.OpenSeadragon.viewport.panTo(new OpenSeadragon.Point(x, y));
      } else if (anno.type === 'Pointset' || anno.type === 'Polygon') {
        const ratio = 0.9;
        const wx = anno.x_range / ratio;
        const wy = anno.y_range / ratio;
        const cx = anno.px;
        const cy = anno.py;

        const rect = new OpenSeadragon.Rect(cx - wx / 2.0, cy - wy / 2.0, wx, wy);

        this.OpenSeadragon.viewport.fitBounds(rect);
      }else if(anno.type === "Circle") {   
        let scale = 0.3;    
        // const { x, y } = window.osdviewer.viewport.imageToViewportCoordinates(
        //   Number(anno.px) + 800.0 ,
        //   Number(anno.py) + 800.0 
        // );
        this.OpenSeadragon.viewport.zoomTo(this.OpenSeadragon.viewport.imageToViewportZoom(scale));
        this.OpenSeadragon.viewport.panTo(new OpenSeadragon.Point(anno.px, anno.py));
      }
    }
  };

  render() {
    const {currentImageInfo} = this.props
    this._showAnnotation20X();
    const imageInfo = this.props.imageVisualInfo;

    const loaded = this.props.imageStatus === 'success';
    const error = this.props.imageStatus === 'failure';
    const ready = window.osdviewer && imageInfo && loaded;
    return (
      <div
        id="seadragon-viewer"
        className={style.container}
        onContextMenu={(e) => e.preventDefault()}
      >
        {window.osdviewer && imageInfo && <ImageOpener error={error} keyIndex={imageInfo.slide_url} info={imageInfo} currentImageInfo={currentImageInfo}/>}
        {
          !ready && (
            error ? <Empty className={style.spinner__empty} image={Empty.PRESENTED_IMAGE_SIMPLE}/> : <Spin className={style.spinner__empty} size="large" />
          )
        }
        {ready && <RectAnnotation key={imageInfo.slide_url + 'RectAnnotation'} />}
        {ready && <PointAnnotation key={imageInfo.slide_url + 'PointAnnotation'} />}
        {window.osdviewer ? <AnnotationsOfNavigator /> : ''}
        {window.osdviewer ? <AnnotationsOfWSI /> : ''}
        <CenterConsole />
        {ready && <ImageTag key={imageInfo.slide_url + 'ImageTag'} />}
        <NavigatorPanel />
        <SiderLeftControl/>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentAnnotation: getCureentAnnotation(state),
  currentAnnotationId: state.annotationSelection.currentAnnotationId,
  imageVisualInfo: state.imageSelection.imageVisualInfo,
  imageStatus: state.imageSelection.status,
  currentImageInfo:state.imageSelection.currentImageInfo
});

export default connect(mapStateToProps, {setCurrentZoom })(ImageViewer);
