import React, { Component } from 'react';
import { Checkbox, Row, Col } from 'antd';
import intl from 'react-intl-universal';
import CheckboxRadio from '../CheckboxRadio';
import { getCurrentLocale } from '../../static/locales';
import styles from './index.less';
// import { getSelectInterpretation } from './SampleReport';
class SampleQuality extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSatisfiedChecked: this.props.sample && this.props.sample.sample_satisfaction
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange = (evt, name, type) => {
    let vals;
    if (type === 'radio') {
      vals = evt.target.value;
    } else {
      vals = evt;
    }
    let sample = { ...this.props.sample };
    sample[name] = vals;
    this.handleOnChange(sample);
  };

  onChangeSatisfied = (evt, name, type) => {
    const defaultSatisfied = {
      has_yixing: false,
      has_no_yixing: false
    };
    const defaultUnSatisfied = {
      shangpixibao: false,
      yiwu: false,
      yanxing: false,
      xueye: false,
      secha: false,
      mohu: false
    };
    let sample = { ...this.props.sample };
    sample[name] = evt;
    if (evt) {
      // 满意
      sample = { ...sample, ...defaultUnSatisfied };
    } else {
      sample = { ...sample, ...defaultSatisfied };
    }
    this.setState({ isSatisfiedChecked: evt });
    this.handleOnChange(sample);
  };

  onChangeYixing = (val, name) => {
    let sample = { ...this.props.sample };
    sample[name] = val;
    if (name === 'has_yixing') {
      if (val) sample['has_no_yixing'] = false;
    } else if (name === 'has_no_yixing') {
      if (val) sample['has_yixing'] = false;
    }
    this.handleOnChange(sample);
  };

  handleOnChange = (sample) => {
    this.props.onChange({
      sample,
      // interpretation: getSelectInterpretation(this.props.labels, sample)
    });
  };

  render() {
    let OptionsObj1 = [
      { label: intl.get('REPORT_SATISFIED'), value: true },
      { label: intl.get('REPORT_UNSATISFIED'), value: false }
    ];

    let OptionsObj2 = [
      {
        key: 'has_yixing',
        label: intl.get('IMAGE_QUALITY_CHENGFEN', { isChengfen: true }),
        value: true
      },
      {
        key: 'has_no_yixing',
        label: intl.get('IMAGE_QUALITY_CHENGFEN', { isChengfen: false }),
        value: false
      }
    ];

    let CheckboxGroup1 = [
      { label: intl.get('IMAGE_QUALITY_EPITHELIAL_LESS'), value: 'shangpixibao' },
      // { label: intl.get('IMAGE_QUALITY_SAMPLE_AMBIGUITY'), value: 'mohu' },
      // { label: intl.get('IMAGE_QUALITY_HAS_RARITY'), value: 'yiwu' },
      { label: intl.get('IMAGE_QUERY_XUEYE'), value: 'xueye' },
      { label: intl.get('IMAGE_QUERY_YANXING'), value: 'yanxing' }
    ];

    let { sample } = this.props;
    const is_zh = getCurrentLocale() === 'zh';
    return (
      <div className={styles.printing}>
        <p style={{ margin: '0px', color: '#169BD5' }}>
          {intl.get('IMAGE_QUALITY_SAMPLE_NUM', { count: sample.nuclei_number })}
        </p>
        <div className={styles.cervical__findings__div}>
          <div className={styles.cervical__item__satisfaction}>
            <CheckboxRadio
              classProp={styles.active__text}
              orientation="horizontal"
              options={OptionsObj1}
              value={sample.sample_satisfaction}
              onChange={(val) =>
                this.onChangeSatisfied(val, 'sample_satisfaction', 'checkbox_radio')
              }
            />
          </div>
          <div className={styles.cervical__item__other}>
            <div className={styles.cervical__item__item}>
              <Row>
                {OptionsObj2.map((option,index) => {
                  return is_zh ? (
                    <Col span={9} offset={index===0?0:0.5} key={option.key}>
                      <Checkbox
                        className={sample[option.key] ? styles.active__text : ''}
                        disabled={!sample.sample_satisfaction}
                        checked={sample[option.key]}
                        onChange={() => this.onChangeYixing(!sample[option.key], option.key)}
                      >
                        {option.label}
                      </Checkbox>
                    </Col>
                  ) : undefined;
                })}
              </Row>
            </div>
            <Row>
              <div className={styles.cervical__item__item}>
                {CheckboxGroup1.map((item,index) => {
                  return is_zh ? (
                    <Col span={8} offset={index===0?0:0.5} key={item.value} className={styles.cervical__item__item}>
                      <div >
                        
                        <Checkbox
                          className={sample[item.value] ? styles.active__text : ''}
                          disabled={sample.sample_satisfaction}
                          checked={sample[item.value]}
                          onChange={(evt) =>
                            this.onChange(evt.target.checked, item.value, 'checkbox')
                          }
                        >
                          {item.label}
                        </Checkbox>
                      </div>
                    </Col>
                  ): undefined;
                })}
              </div>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default SampleQuality;
