import { Card, Col, List, Result } from 'antd';
import ReactEcharts from 'echarts-for-react';
import { hasIn, omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loadImageFromNILMGrading } from '../../actions/analysisActions';
import colorTable from '../../static/colorTable';
import PopoverIconButton from '../PopoverIconButton';
import CardTitle from '../TitleWithHelp';
import style from './negativeAnalysis.less';
import NegativeDetail from './NegativeDetail';

class NegativeAnalysis extends Component {
  static propTypes = {
    data: PropTypes.array,
    nr: PropTypes.number,
    tnr: PropTypes.number,
    loadImageFromNILMGrading: PropTypes.func,
    cancerType: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      details: { 0: [this.props.nr, this.props.tnr, 1.0] }
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.nr !== this.props.nr || prevProps.tnr !== this.props.tnr) {
      this.updateDetails();
    }
  }

  updateDetails = () => {
    this.setState({ details: { 0: [this.props.nr, this.props.tnr] } });
  };

  renderNegativeAnalysis = () => {
    const xAxies = [];
    this.props.data.forEach((item) => {
      xAxies.push(item[0]);
    });

    const options = {
      grid: {
        x: 30,
        y: 30,
        x2: 30,
        y2: 30
      },
      tooltip: {
        trigger: 'axis',
        position: (pt) => [pt[0] + 10, '10%'],
        formatter: (params) => {
          const { data } = params[params.length - 1];
          return `${intl.get('NR')}: ${(data[0] * 100).toFixed(2)}%<br/>${intl.get('TNR')}: ${(
            data[1] * 100
          ).toFixed(2)}%<br />${intl.get('INR')}: ${(data[2] * 100).toFixed(2)}%<br />`;
        }
      },
      xAxis: {
        type: 'value',
        boundaryGap: false,
        min: 0,
        max: xAxies[xAxies.length - 1]
      },
      yAxis: {
        type: 'value',
        boundaryGap: [0, '100%'],
        max: 1.0
      },
      series: [
        {
          type: 'line',
          symbol: 'none',
          data: [
            [0, 1],
            [xAxies[xAxies.length - 1], 1]
          ],
          smooth: true,
          areaStyle: { color: colorTable.green1 }
        },
        {
          name: '阴性分析',
          type: 'line',
          data: this.props.data,
          smooth: true,
          showSymbol: false,
          sampling: 'average',
          itemStyle: {
            color: colorTable.green4
          },
          areaStyle: {
            color: colorTable.green2
          }
        }
      ]
    };
    return options;
  };

  onChartClick = (params) => {
    const { details } = this.state;
    if (!hasIn(details, params.dataIndex)) {
      details[params.dataIndex] = params.data;
      this.setState({ details });
    }
  };

  deleteDetails = (key) => {
    const { details } = this.state;
    if (hasIn(details, key)) {
      this.setState({ details: omit(details, [key]) });
    }
  };

  loadImage = (key) => {
    const { details } = this.state;
    this.props.loadImageFromNILMGrading(details[key][2] * 100, () => {
      this.props.history.push('/mainpage');
    });
  };

  renderDetail = (item, key) => {
    let actions = [];
    if (key !== '0') {
      actions = [
        <PopoverIconButton
          popContent={intl.get('REMOVE_NILM_GRADING_RECORD')}
          color="#ff4d4f"
          size="small"
          key="delete"
          icon="close"
          onClick={() => {
            this.deleteDetails(key);
          }}
        />,
        <PopoverIconButton
          popContent={intl.get('LOAD_PROP_IMAGES', { prob: (item[2] * 100).toFixed(2) })}
          size="small"
          key="load"
          color="#1890ff"
          icon="right"
          onClick={() => {
            this.loadImage(key);
          }}
        />
      ];
    }
    return (
      <List.Item key={key} actions={actions}>
        <NegativeDetail nr={item[0]} tnr={item[1]} />
      </List.Item>
    );
  };

  render() {
    const { cancerType } = this.props;
    const { details } = this.state;
    return (
      <Card
        className={style.negativeAnalysisChartContainer}
        title={
          <CardTitle
            title={intl.get('NEGATIVE_ANALYSIS')}
            detail={intl.getHTML('NILM_GRADING_EXPLAINATION')}
          />
        }
        headStyle={{ height: 5 }}
        bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
      >
        {cancerType === 'cervical' ? (
          <>
            <Col span={16}>
              <ReactEcharts
                className={style.negativeAnalysisChart}
                option={this.renderNegativeAnalysis()}
                lazyUpdate={true}
                onEvents={{ click: this.onChartClick }}
              />
            </Col>
            <Col span={8}>
              <List size="small" split={false} className={style.negativeDetailList}>
                {Object.keys(details).map((k) => this.renderDetail(details[k], k))}
              </List>
            </Col>
          </>
        ) : (
          <Result
            status="error"
            title={intl.get('ANALYSIS_CANCER_TYPE_ERROR')}
            subTitle={intl.get('ANALYSIS_ONLY_SUPPORT_CERVICAL')}
          />
        )}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { loadImageFromNILMGrading })(withRouter(NegativeAnalysis));
