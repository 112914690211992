import React, { Component } from 'react';
import { connect } from 'react-redux';
import DateSearch from '../CheckIn/DateSearch';
import { Col, Input, DatePicker, Checkbox } from 'antd';
import style from './index.less';
import intl from 'react-intl-universal';
import { getCurrentUser } from '../../selectors/userSelectors';
import { updateQueryInfo, resetQueryInfo } from '../../actions/tableActions';
import moment from 'moment';
import { setStartDate } from '../../utils/dateUtils';
import Icon from '../Iconfont';
import { getTableLoading } from '../../selectors/imageSelector';
import { isPartEnd } from '../../utils/urlUtils';
import HospitalSelect from '../CommonComponent/HospitalSelect';
import { getMachine } from '../../actions/userActions';
const { Search } = Input;
const { RangePicker } = DatePicker;
class SearchTabLeft extends Component {
  constructor(props) {
    super();
    this.mine = React.createRef();
    this.state = {
      searchValue: props.queryInfo.fuzzy
    };
  }

  componentDidMount() {
    isPartEnd() === 'centerEnd' && this.props.getMachine();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.queryInfo.fuzzy !== this.props.queryInfo.fuzzy) {
      this.setState({
        searchValue: this.props.queryInfo.fuzzy
      });
    }
  }

  changeDataRange(data) {
    let p = {
      registerFrom: data.From,
      registerTo: data.To
    };
    this.props.updateQueryInfo(p);
  }

  changeDate(value, dateString) {
    let p = {
      registerFrom: dateString[0],
      registerTo: dateString[1]
    };
    this.props.updateQueryInfo(p);
  }
  onSearch = (value) => {
    let p = {
      fuzzy: value,
      page: 0
    };
    this.props.updateQueryInfo(p);
  };

  changeFuzzy = (e) => {
    this.setState({
      searchValue: e.target.value
    });
  };
  isMine(e) {
    const { currentUser, queryInfo } = this.props;
    let id = e.target.checked ? currentUser.id : '';
    let p = {
      owner: id,
      page: 0
    };
    this.props.updateQueryInfo({ ...p });
    this.setState({ searchValue: queryInfo.fuzzy });
  }

  setSource(value) {
    let p = {
      page: 0,
      machines: value
    };
    this.props.updateQueryInfo({ ...p });
  }
  resetQuery() {
    this.props.resetQueryInfo();
    this.setState({ searchValue: '' });
  }
  disabledDate(current) {
    return setStartDate(current);
  }

  render() {
    const { currentUser, queryInfo } = this.props;
    const { searchValue } = this.state;
    let timeList =
      queryInfo.registerFrom !== '' && queryInfo.registerTo !== ''
        ? [moment(queryInfo.registerFrom), moment(queryInfo.registerTo)]
        : null;
    const partEnd = isPartEnd();
    return (
      <>
        <Col span={3}>
          <Search
            placeholder={intl.get('SEARCH_TEXT')}
            value={searchValue}
            onChange={this.changeFuzzy}
            onSearch={this.onSearch}
          />
        </Col>
        <Col span={5}>
          <RangePicker
            disabledDate={this.disabledDate.bind(this)}
            showTime={{
              format: 'HH:mm',
              defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')]
            }}
            value={timeList}
            onChange={this.changeDate.bind(this)}
            style={{ width: '100%' }}
          />
        </Col>
        <Col span={8} className={style.report__search_item}>
          <DateSearch
            parent={this}
            dateRange={{ From: queryInfo.registerFrom, To: queryInfo.registerTo }}
          />
          {/* 本人 */}
          {partEnd !== 'expert' && (
            <div style={{ width: '100px' }}>
              <Checkbox
                value={true}
                checked={queryInfo.owner === currentUser.id}
                onChange={this.isMine.bind(this)}
                style={{ lineHeight: '32px' }}
              >
                {intl.get('PRO_IS_MINE')}
              </Checkbox>
            </div>
          )}
          {/* 初始化刷新 */}
          <span onClick={this.resetQuery.bind(this)}>
            <Icon type="icon-fresh" />
          </span>
          {/* 报告来源 */}
          {partEnd === 'centerEnd' && (
            <HospitalSelect
              mode="single"
              onChange={this.setSource.bind(this)}
              width="200px"
              defaultValue={queryInfo?.machines}
              value={queryInfo?.machines}
              placeholder={intl.get('EXPERT_SOURCE')}
            />
          )}
        </Col>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: getCurrentUser(state),
    tabState: state.tabState,
    queryInfo: state.loadImages.queryInfo,
    selected: state.loadImages.selected,
    forceLoading: getTableLoading(state)
  };
};

export default connect(mapStateToProps, {
  updateQueryInfo,
  resetQueryInfo,
  getMachine
})(SearchTabLeft);
