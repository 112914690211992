 
import React from 'react';
import Classification from './Classification';
import Display from './Display';
import SuspectedAnomalous from './SuspectedAnomalous';
import {getCurrentUser} from '../../selectors/userSelectors'
import {useSelector} from 'react-redux'
import Can from '../Can'
import './index.less';
import CommonPanel from "../UserSetting/commonPanel"
export default function ConfigurationAI(props) {
  const currentUser = useSelector(getCurrentUser)
  return (
    <CommonPanel>
        <Display/>        
        <Classification />
        <Can
          permissions={currentUser.permissions}
          perform="client:view:devops"
          yes={()=><SuspectedAnomalous />}
          no={()=>('')}
        />
        </CommonPanel>
  );
}
