import { Button, Card, Form } from 'antd';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { updateAdminKey } from '../../actions/userActions';
import { getCurrentUser } from '../../selectors/userSelectors';
import { clearForm } from '../../utils/formUtils';
import { formItemLayout, formTailLayout } from './formProperty';
import generateFormItems from './generateFormItems';
import getChangeAdminKeyFields from './getChangeAdminKeyFields';

class ChangeAdminKeyForm extends Component {
  handleChangeAdminKey = (e) => {
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        this.props.updateAdminKey({ old: values.old_admin_key, new: values.new_admin_key });
        clearForm(form, ['old_admin_key', 'new_admin_key']);
      }
    });
  };
  render() {
    const fields = getChangeAdminKeyFields();
    const { form, currentUser } = this.props;
    const { getFieldDecorator } = form;
    return (
        <Card title={intl.get('CHANGE_ADMIN_KEY')}>
          <Form {...formItemLayout}>
            {generateFormItems(fields, currentUser, getFieldDecorator)}
            <Form.Item {...formTailLayout}>
              <Button type="primary" onClick={this.handleChangeAdminKey}>
                {intl.get('CONFIRM_CHANGE_PASSWORD')}
              </Button>
            </Form.Item>
          </Form>
        </Card>
    );
  }
}

const mapStateToProps = (state) => ({ currentUser: getCurrentUser(state) });

export default connect(mapStateToProps, { updateAdminKey })(
  Form.create({ name: 'ChangeAdminKeyForm' })(ChangeAdminKeyForm)
);
