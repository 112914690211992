import React, { Component } from 'react';
import 'echarts/map/js/china.js';
import echarts from 'echarts';
import style from './index.less';
import BaseChart from './BaseChart';
import { isDataPaltform } from './utils';
export default class ChinaMap extends Component {
  setShadow() {
    let geoList = [];
    for (let index = 0; index < 5; index++) {
      geoList.push({
        map: 'china',
        show: true,
        roam: false,
        label: {
          emphasis: {
            show: false
          }
        },
        layoutSize: '100%',
        itemStyle: {
          normal: {
            // borderColor: new echarts.graphic.LinearGradient(
            //   0,
            //   0,
            //   0,
            //   1,
            //   [
            //     {
            //       offset: 0,
            //       color: '#00F6FF'
            //     },
            //     {
            //       offset: 1,
            //       color: '#53D9FF'
            //     }
            //   ],
            //   false
            // ),
            borderWidth: 3,
            // shadowOffsetY: 0,
            // shadowBlur: 30
            shadowColor: 'rgba(70,195,246,0.8)',
            shadowOffsetY: index * 1.5,
            shadowOffsetX: index * -0.5
          }
        }
      });
    }
    return geoList;
  }

  getOption() {
    let max = 10000;
    let min = 0;
    let maxSize4Pin = 100;
    let minSize4Pin = 20;

    let outname = [];
    let outvalue = [];
    let outdata = [];
    const { data, selected } = this.props;
    let list = selected.total === 0 ? data : selected.data;
    list.forEach((l) => {
      let str = l.hospital_position.split('-')[0];
      if (!outname.includes(str)) {
        outname.push(str);
        outvalue.push(1);
      } else {
        let index = outname.indexOf(str);
        outvalue[index] += 1;
      }
    });

    for (let i = 0; i < outname.length; i++) {
      outdata.push({
        name: outname[i],
        value: outvalue[i],
        selected: true
      });
    }

    let geoCoordMap = {};
    /*获取地图数据*/
    let mapFeatures = echarts.getMap('china').geoJson.features;
    mapFeatures.forEach(function(v) {
      // 地区名称
      let name = v.properties.name;
      // 地区经纬度
      geoCoordMap[name] = v.properties.cp;
    });

    let convertData = function(outdata) {
      let res = [];
      for (let i = 0; i < outdata.length; i++) {
        let geoCoord = geoCoordMap[outdata[i].name];
        if (geoCoord) {
          res.push({
            name: outdata[i].name,
            value: geoCoord.concat(outdata[i].value)
          });
        }
      }
      return res;
    };

    let getCityNums = function(parentData) {
      let list = selected.total === 0 ? data : selected.data;
      let cityList = [];
      list.forEach((l) => {
        let parent = l.hospital_position.split('-')[0];
        let city = l.hospital_position.split('-')[1];
        let hasCity = cityList.filter((c) => c.name === city);
        if (parent === parentData && hasCity.length === 0) {
          cityList.push({ name: city, value: 1 });
        } else if (parent === parentData && hasCity.length > 0) {
          hasCity[0].value++;
        }
      });
      let cityInfo = '';
      cityList.forEach((c) => {
        cityInfo += '&nbsp;&nbsp;' + c.name + '(' + c.value + ')<br />';
      });
      return cityInfo;
    };

    let option = {
      tooltip: {
        show: true,
        formatter: function(params) {
          if (params.value.length > 1) {
            return (
              '&nbsp;&nbsp;' +
              params.name +
              '&nbsp;&nbsp;&nbsp;' +
              (isNaN(params.value) ? '' : params.value)
            );
          } else {
            return '&nbsp;&nbsp;' + params.name + '<br />' + getCityNums(params.name);
          }
        }
      },
      geo: [...this.setShadow()],
      series: [
        {
          name: '中国',
          type: 'map',
          mapType: 'china',
          aspectScale: 0.75,
          label: {
            normal: {
              show: false
            },
            emphasis: {
              show: true,
              textStyle: {
                color: 'rgba(255,255,255,1)',
                fontFamily: 'YouSheBiaoTiHei',
                textBorderColor: 'rgba(2, 24, 57, 0.84)'
              }
            }
          },
          itemStyle: {
            normal: {
              areaColor: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#458AED' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#458AED' // 100% 处的颜色
                  }
                ]
              },
              borderColor: '#66C9F5',
              borderWidth: 1,
              shadowColor: '#4E98FE',
              shadowOffsetY: 1.5,
              shadowOffsetX: -0.5
            },
            emphasis: {
              areaColor: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#6BECF5' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#6BECF5' // 100% 处的颜色
                  }
                ]
              }
            }
          },
          data: outdata
        },
        {
          type: 'effectScatter',
          coordinateSystem: 'geo',
          rippleEffect: {
            brushType: 'stroke',
            period: 5,
             scale: 10 
          },
          showEffectOn: 'render',
          itemStyle: {
            normal: {
              color: {
                type: 'radial',
                x: 0.5,
                y: 0.5,
                r: 0.5,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(255,224,0,0.2)'
                  },
                  {
                    offset: 0.8,
                    color: 'rgba(255,224,0,0.8)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(255,224,0,0.7)'
                  }
                ],
                global: false
              }
            }
          },
          symbol: 'circle',
          symbolSize: function(val) {
            if (val[2] === 0) {
              return 0;
            }
            let a = (maxSize4Pin - minSize4Pin) / (max - min);
            let b = maxSize4Pin - a * max;
            return (a * val[2] + b * 1.2) / 5;
          },
          data: convertData(outdata),
          zlevel: 1
        }
      ]
    };

    return option;
  }

  render() {
    const { parentName, otherStyle } = this.props;
    const isDataPlatform = isDataPaltform(parentName);
    return (
      <div className={isDataPlatform ? style.chart_container_map : style.chart_container}>
        <BaseChart option={this.getOption()} style={{ height: otherStyle.height, width: '100%' }} />
      </div>
    );
  }
}
