/* @flow */
import { connect } from 'react-redux';
import React, { Component } from 'react';
import TimePanel from './TimePanel';
import { updateComputedTime, fetchTimeoutImage } from '../../actions/timeoutAction';
import { updateQueryInfo } from '../../actions/tableActions';
let timer = 0;

class Timeout extends Component {
  componentDidMount() {
    this.props.updateComputedTime(0);
    timer = setInterval(() => {
      this.computedLoadTime();
    }, 60000);
  }

  componentWillUnmount() {
    clearInterval(timer);
  }

  computedLoadTime() {
    if (this.props.computedTime === 4) {
      this.props.updateComputedTime(0);
      this.getList();
    } else {
      let num = this.props.computedTime + 1;
      this.props.updateComputedTime(num);
    }
  }
  getList() {
    this.props.updateQueryInfo(this.props.queryInfo);
  }
  render() {
    const { notNoteTimeoutList } = this.props;
    return (
      // <div>
      //   {this.props.notNoteTimeoutList.data.map((item, i) => (
      //     <TimePanel dataSource={item} key={i} />
      //   ))}
      // </div>
      notNoteTimeoutList.total !== 0 && <TimePanel dataSource={notNoteTimeoutList.data} />
    );
  }
}
const mapStateToProps = (state) => ({
  computedTime: state.timeout.computedTime,
  queryInfo: state.loadImages.queryInfo,
  timeoutList: state.timeout.timeoutList,
  notNoteTimeoutList: state.timeout.notNoteTimeoutList
});

export default connect(mapStateToProps, { updateComputedTime, fetchTimeoutImage, updateQueryInfo })(
  Timeout
);
