import { isEqual } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import { setCurrentAnnotationId } from '../../actions/annotationSelectionActions';

class AnnotationOfNavigator extends Component {
  constructor(props) {
    super(props);
    this.anno = null;
  }

  componentDidMount() {
    this.addNavigatorAnnotation();
  }

  shouldComponentUpdate(nextProps) {
    const last = [this.props.hoveredAnnotationId, this.props.currentAnnotationId];
    const next = [nextProps.hoveredAnnotationId, nextProps.currentAnnotationId];
    const current = this.props.annotation.id;
    if (!isEqual(next, last) && (next.includes(current) || last.includes(current))) {
      return true;
    } else {
      return false;
    }
  }

  componentDidUpdate() {
    this.anno && window.osdviewer && window.osdviewer.navigator.removeOverlay(this.anno);
    this.addNavigatorAnnotation();
  }

  componentWillUnmount() {
    this.anno && window.osdviewer && window.osdviewer.navigator.removeOverlay(this.anno);
  }

  createRect(anno, highLight) {
    let div = document.createElement('div');
    let color = '#cca100';
    const classes = anno.classes;
    if (classes.length === 1) {
      switch (classes[0]) {
        case 'ASC-US':
        case 'LSIL':
          color = '#0048FFFF';
          break;
        case 'ASC-H':
        case 'HSIL':
        case 'SCC':
          color = '#BF2B2BFF';
          break;
        case 'AGC':
        case 'AGC-N':
        case 'ADC':
          color = '#8702E4FF';
          break;
        case 'FUNGI':
        case 'TV':
        case 'CC':
        case 'ACTINO':
        case 'HSV':
          color = '#D8B200FF';
          break;
        case 'NILM':
        case 'NILM+':
        case 'NILM++':
          color = '#15A602FF';
          break;
        default:
          color = '#303030FF';
      }
    } else {
      color = '#FDFF31FF';
    }

    div.style.border = '2px solid ' + color;
    highLight &&
      (div.style.boxShadow =
        '#28FF32FF 2px 0px  0px,#28FF32FF  0px  2px 0px, #28FF32FF -2px 0px 0px , #28FF32FF 0px -2px 0px ') &&
      (div.style.backgroundColor = '#28FF32FF');
    div.style.zIndex = 2;

    div.onclick = (e) => {
      this.props.setCurrentAnnotationId(anno.id);
    };

    return div;
  }

  addNavigatorAnnotation() {
    const anno = this.props.annotation;
    const extra =
      this.props.hoveredAnnotationId === anno.id || this.props.currentAnnotationId === anno.id;

    const annotationSize = Math.min(this.props.width, this.props.height) / 30;

    const div = this.createRect(anno, extra);
    window.osdviewer.navigator.addOverlay(
      div,
      window.osdviewer.viewport.imageToViewportRectangle(
        anno.x_start,
        anno.y_start,
        annotationSize,
        annotationSize
      )
    );

    this.anno = div;
  }

  render() {
    return null;
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    hoveredAnnotationId: state.annotationSelection.hoveredAnnotationId,
    currentAnnotationId: state.annotationSelection.currentAnnotationId
  };
};

export default connect(mapStateToProps, { setCurrentAnnotationId })(AnnotationOfNavigator);
