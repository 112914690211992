import MyIcon from '../components/Iconfont';
import React, { Component } from 'react';
import styles from './index.less';
import intl from 'react-intl-universal';
import { Badge } from 'antd';
import { setCurrentPage } from '../actions/configActions';
import { connect } from 'react-redux';
import { toggleTab } from '../actions/tabActions';
import { getCounting } from '../actions/api';
class UserInfoPart extends Component {
  constructor(props) {
    super();
    this.state = {
      counts: [0, 0, 0]
    };
  }
  componentDidMount() {
    this.props.menuList.forEach((i, index) => {
      i.query && this.getCount(index, i.query);
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentTab !== this.props.currentTab) {
      this.props.history.push('/mainpage');
    }
  }

  goToReport = (tab) => {
    this.props.setCurrentPage('report');
    if (tab === this.props.currentTab) {
      this.props.history.push('/mainpage');
    } else {
      this.props.toggleTab(tab);
    }
  };
  grToPage = (url) => {
    this.props.history.push(url);
  };

  getCount = (index, query) => {
    let counts = this.state.counts;
    getCounting({ owner: this.props.currentUser.id, ...query })
      .then((res) => {
        if (res) {
          counts[index] = res.data;
          this.setState(counts);
        }
      })
      .catch(() => {});
  };

  render() {
    return (
      <div className={styles.user_action}>
        {this.props.menuList.map((item, index) => {
          return (
            <div key={index} className={styles.action_item}>
              <div
                className={styles.action_item_content}
                onClick={
                  item.currentTab!==undefined
                    ? this.goToReport.bind(this, item.currentTab)
                    : this.grToPage.bind(this, item.url)
                }
              >
                {item.tag ? (
                  <Badge count={this.state.counts[index]} overflowCount={99}>
                    <div className={styles.action_item_icon} style={{ background: item.bgColor }}>
                      <MyIcon type={'icon-' + item.icon} />
                    </div>
                  </Badge>
                ) : (
                  <div className={styles.action_item_icon} style={{ background: item.bgColor }}>
                    <MyIcon type={'icon-' + item.icon} />
                  </div>
                )}
                <div className={styles.action_item_label}>{intl.get(item.label)}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentTab: state.tabState.currentTab,
    currentUser: state.currentUser
  };
};

export default connect(mapStateToProps, { setCurrentPage, toggleTab })(UserInfoPart);
