import { initReduxData } from '../utils/dataUtils';
const initial = {
  helpModalVisible: false,
  restarting: false,
  download: false,
  organization: {
    database:{},
    organizationList: [],
    departmentList: [],
    doctorList: [],
    checkedOrganization:[],
    checkedDepartment:[],
    checkedDoctor:[],
    checkedOrganizationList: [],
    checkedDepartmentList: [],
    checkedDoctorList: []
  },
  register: {
    registerUpdateVisible: false,
    isLoading:false,
    registerInfo: {},
    modalName: 'add',
    selected: [],
    registerList: [],
    total:0,
    queryInfo: {
      page: 0,
      limit: 10
    },
    selectedSingle:''
  }
};

export function settings(state = initial, action) {
  state.download = initReduxData(initial.download, state.download);
  state.organization = initReduxData(initial.organization, state.organization);
  state.register = initReduxData(initial.register, state.register);
  switch (action.type) {
    case 'TOGGLE_HELP_MODAL':
      return {
        ...state,
        helpModalVisible: action.show
      };
    case 'TOGGLE_RESTARTING_STATUS':
      return {
        ...state,
        restarting: action.restarting
      };
    case 'TOGGLE_DOWNLOAD':
      return {
        ...state,
        download: action.show
      };
    case 'SET_ORGANIZATION':
      return {
        ...state,
        organization: {
          ...state.organization,
          [action.name]: action.data
        }
      };
    case 'UPDATE_REGISTER':
      return {
        ...state,
        register: { ...state.register, ...action.data }
      };
    case 'UPDATE_REGISTER_QUERY_INFO':
      return {
        ...state,
        register: { ...state.register, queryInfo: { ...state.register.queryInfo, ...action.data } }
      };

    default:
      return state;
  }
}
