import React from 'react';
import intl from 'react-intl-universal';
import { validatePwd } from '../../utils/regUtils';
export default function getPasswordFormFields(that) {
  return [
    {
      field: 'old_password',
      icon: 'lock',
      type: 'password',
      editable: true,
      rules: [
        { required: true, message: intl.get('PASSWORD_NOT_EMPTY') },
      ],
    },
    {
      field: 'new_password',
      icon: 'lock',
      type: 'password',
      editable: true,
      rules: [
        { required: true, message: intl.get('PASSWORD_NOT_EMPTY') },
        { ...validatePwd, message: intl.get('PASSWORD_FORMAT') },
        { validator: that.compareToPasswordConfirm }
      ],
      maxLength:16
    },
    {
      field: 'new_password_confirm',
      icon: 'lock',
      type: 'password',
      editable: true,
      rules: [
        { required: true, message: intl.get('PASSWORD_NOT_EMPTY') },
        { ...validatePwd, message: intl.get('PASSWORD_FORMAT') },
        {
          validator: that.compareToPassword,
          message: <div>{intl.get('TWO_PASSWORD_NOT_MATCH')}</div>
        }
      ],
      maxLength:16
    }
  ];
}
