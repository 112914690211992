import React, { Component } from 'react';
import { connect } from 'react-redux';
import AnnotationThumbnail from '../Annotation/AnnotationThumbnail';
import { getReportAnnotations } from '../../selectors/annotationSelectors';
import styles from './index.less';

const GridMapArr = [
  {
    numRows: 1,
    numColumns: 1
  },
  {
    numRows: 2,
    numColumns: 1
  },
  {
    numRows: 2,
    numColumns: 2
  },
  {
    numRows: 2,
    numColumns: 2
  },
  {
    numRows: 3,
    numColumns: 2
  },
  {
    numRows: 3,
    numColumns: 2
  },
  {
    numRows: 4,
    numColumns: 2
  },
  {
    numRows: 4,
    numColumns: 2
  }
];
class Screenshots extends Component {
  render() {
    let imgsLen = this.props.annotations.length>8?8:this.props.annotations.length
    let { numColumns, numRows } = GridMapArr[imgsLen?(imgsLen- 1):0];
    const rowSize = 100 / numRows;
    const colSize = 100 / numColumns;
    let annos = this.props.annotations.slice(0,imgsLen)
    let removable = !this.props.printing
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateRows: `repeat(${numRows}, ${rowSize}%)`,
          gridTemplateColumns: `repeat(${numColumns}, ${colSize}%)`
        }}
      >
        {annos.map((anno,index) => {
            return (
              <div key={index} className={styles.cells__imgs__item}>
                <AnnotationThumbnail isReport={true} item={anno} removable={removable} />
              </div>
            );
          })}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  annotations: getReportAnnotations(state),
  currentImageKey: state.imageSelection.currentImageKey
});

export default connect(mapStateToProps)(Screenshots);
