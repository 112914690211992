import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
// import DateSearchContainer from './DateSearchContainer';
import { Pagination } from 'antd';
import { updateQueryInfo } from '../../actions/tableActions';
import ImageTable from '../ImageTable';
import styles from './index.less';
// import { isPartEnd } from '../../utils/urlUtils';
class InnerTabPane extends Component {
  changePageSize = (current, size) => {
    this.props.updateQueryInfo({ limit: size });
  };

  render() {
    const { images, tabs, selected, queryInfo, currentTab} = this.props;
    const isCollection = !!tabs[currentTab] && !!tabs[currentTab].query.collector;
    // const { counts } =  this.props.tabState;
    // const disCount = counts.length > 0 ? counts[counts.length - 1] - counts[0] : 0;
    // const allsCount = counts.length > 0 ? counts[counts.length - 1] : 0;
    return (
      <>
        {/* <DateSearchContainer /> */}
        <ImageTable isCollection={isCollection} />
        <div className={styles.table_bottom}>
          <div className={styles.table_bottom_dis}>
            {/* {currentTab === 0 && isPartEnd() !== 'expert'
              ? intl.get('DIS_TO_ALL', { dis: disCount, all: allsCount })
              : ''} */}
          </div>
          
          <Pagination
            current={queryInfo.page + 1}
            showTotal={(total) =>
              `${intl.get('SELECTED_IMAGE_COUNT', {
                count: selected.total
              })}, ${intl.get('LIST_TOTAL_COUNT', { total: total })}`
            }
            onChange={(page) => {
              this.props.updateQueryInfo({ page: page - 1 });
            }}
            size="small"
            pageSize={queryInfo.limit}
            pageSizeOptions={['10', '15', '20',]}
            total={images.total}
            showQuickJumper={true}
            showSizeChanger={true}
            onShowSizeChange={this.changePageSize}
            style={{ padding: 10, float: 'right' }}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.loadImages,
    ...state.tabState,
    tabState: state.tabState
  };
};
export default connect(mapStateToProps, {
  updateQueryInfo
})(InnerTabPane);
