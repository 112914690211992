import { Button, Row, Col, Modal, message } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import DoctorList from '../DoctorList';
import { batchChangeReportDoctor} from '../../actions/imageActions';
import styles from '../../less/modal.less';
import style from './index.less';
import {getDoctorNotMine} from "../../utils/reportUtils"

class BatchChange extends Component {
  static propTypes = {
    selecteds: PropTypes.object,
    images: PropTypes.object
  };

  constructor() {
    super();
    this.state = {
      batchVisible: false,
      selectedDoc: []
    };
  }
  
  componentDidUpdate(prevProps, prevState){
    if(this.state.batchVisible && this.props.selecteds.total===0){
      this.handleCancel()
    }
  }
  showBatchModal = () => {
    const { selecteds,currentUser} = this.props;
    let l = selecteds.data.filter(i=>i.reporter !== currentUser.username)
    if(l.length>0){
      message.warning(intl.get('PRO_SELECTED_MORE'));
      return false
    }

    this.setState({
      batchVisible: true
    });
  };
  getDoctor(data) {
    this.setState({
      selectedDoc: data
    });
  }
  handleOk = (e) => {
    this.props.setLoading()
    this.props.batchChangeReportDoctor(this.state.selectedDoc)
    this.setState({
      batchVisible: false
    });
  };

  handleCancel = (e) => {
    this.setState({
      batchVisible: false
    });
  };

  render() {
    const { selecteds ,roleUsers,currentUser,forceLoading} = this.props;
    const { batchVisible } = this.state;
    return (
      <>
        <Button
          type="primary"
          key="loadimage"
          onClick={this.showBatchModal}
          disabled={(selecteds.total > 0 && !forceLoading) ? false : true}
        >
          {intl.get('BATCH_CHANGE')}
        </Button>

        <Modal
          title={intl.get('BATCH_CHANGE')}
          visible={batchVisible}
          onOk={this.handleOk.bind(this)}
          onCancel={this.handleCancel.bind(this)}
          className={styles.checkModal+" "+style.change__doc}
        >
             <Row>
              <Col span={4} className={style.change__doc__label}>
                {intl.get('CHANGE_DOCTORS')}:
              </Col>
              <Col  span={16}>
              <DoctorList
              selected={this.getDoctor.bind(this)}
              parentName="batch"
              keyValue={[]}
              width="400px"
              doctorList={roleUsers.senior_doctor!==undefined?getDoctorNotMine(roleUsers,currentUser):[]}
            />
              </Col>
            </Row>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    images: state.loadImages.images,
    selecteds: state.loadImages.selected,
    currentTab: state.tabState.currentTab,
    roleUsers:state.report.roleUsers,
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps, {batchChangeReportDoctor})(BatchChange);
