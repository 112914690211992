import React, { Component } from 'react';
import style from '../../less/modal.less';
import intl from 'react-intl-universal';
import { Row, Col, Modal, Input, message } from 'antd';
import config from '../../config';
import RotatableImage from '../RotatableImage';
import { connect } from 'react-redux';
import { editByImageId } from '../../actions/imageActions';
import { canRender } from '../Can';
import setBaseUrl from '../../utils/urlUtils';
class UpdateTagId extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      inputVal: ''
    };
  }
  showEditTag = (record) => {
    this.setState({
      isModalVisible: true
    });
  };

  handleOk = (imageId) => {
    if(this.state.inputVal.trim()===""){
      message.error(intl.get('TAG_ID_NOT_NULL'))
      return ;
    }
    this.props.editByImageId(imageId, { patient_tag_id: this.state.inputVal.trim() });
    this.setState({
      inputVal: ''
    });
    this.handleCancel()
  };
  handleCancel = () => {
    this.setState({
      isModalVisible: false
    });
  };
  render() {
    const { image, currentUser } = this.props;
    const { baseUrl } = config;
    const { inputVal } = this.state;
    const imageId = image.id;
    const beforeUrl = setBaseUrl(baseUrl,image)
    const tagApi = `${beforeUrl}/imagetags/label/${imageId}.jpg`;
 
    return (
      <>
        {canRender(currentUser.permissions, 'image:modify') ? (
          <span style={{ cursor: 'pointer' }} onClick={this.showEditTag}>
            {intl.get('UPDATE_TAG_ID')}
          </span>
        ) : (
          <span style={{ cursor: 'auto', color: '#999' }}>{intl.get('UPDATE_TAG_ID')}</span>
        )}

        <Modal
          title={intl.get('UPDATE_TAG_ID')}
          visible={this.state.isModalVisible}
          onOk={this.handleOk.bind(this, imageId)}
          onCancel={this.handleCancel}
          className={style.checkModal}
        >
          <Row>
            <Col span={9} offset={1} className={style.checkModalIcon}>
              {intl.get('UPDATE_TAG_ID_IMAGE')}
            </Col>
            <Col span={9}>
              <RotatableImage src={tagApi} style={{ border: 'none' }} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={9} offset={1} className={style.checkModalIcon}>
              {intl.get('UPDATE_TAG_ID_INPUT')}
            </Col>
            <Col span={9}>
              <Input
              maxLength={20}
                value={inputVal}
                onChange={(e) => {
                  this.setState({ inputVal: e.target.value });
                }}
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser
  };
};

export default connect(mapStateToProps, { editByImageId })(UpdateTagId);
