import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import PrintReport from '../../components/Report/PrintReport';
import ReactToPrint from 'react-to-print';
import { getPrintResultList } from '../../actions/reportAction';
import { Modal, Button, message } from 'antd';
import style from '../../less/modal.less';
import { exportPDF } from '../../utils/reportUtils';
import { batchPrintReport } from '../../actions/imageActions';
import ConfirmContent from '../CommonComponent/ConfirmContent';
class ExportReport extends Component {
  constructor() {
    super();
    this.print = React.createRef();
    this.state = {
      reports: [],
      isModalVisible: false,
      isPrintVisible: false
    };
  }
  componentDidMount() {}
  download = () => {
    exportPDF(intl.get('BATCH_REPORT_DOENLOAD'), 'reportList');
  };
  showModal = (record) => {
    const { data } = this.props.selected;
    let l = data.filter((i) => i.rescan === true);
    if (l.length > 0) {
      message.error(intl.get('PRO_SELECTED_MORE'));
      return;
    }
    let idList = data.map((item) => item.id);
    let promises = getPrintResultList(idList);
    Promise.all(promises)
      .then((results) => {
        let reports = results.map((item) => item.data);
        this.setState({
          reports: reports,
          isModalVisible: true
        });
        return results;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  afterPrint = () => {
    this.setState({
      isPrintVisible: true,
      isModalVisible: false
    });
  };

  batchPrint = () => {
    this.props.batchPrintReport();
    this.handleCancel('isPrintVisible');
  };
  handleCancel = (name) => {
    this.setState({
      [name]: false
    });
  };
  render() {
    const { name, selected,forceLoading } = this.props;
    return (
      <>
        <Button
          type="primary"
          onClick={this.showModal.bind(this)}
          disabled={(selected.total > 0 && !forceLoading) ? false : true}
        >
          {intl.get(name)}
        </Button>
        <Modal
          title={intl.get(name)}
          width="800px"
          visible={this.state.isModalVisible}
          onCancel={this.handleCancel.bind(this, 'isModalVisible')}
          className={style.checkModal}
          footer={
            name === 'PRINT_BATCH_REPORT'
              ? [
                  <Button key="back" onClick={this.handleCancel.bind(this, 'isModalVisible')}>
                    {intl.get('CANCEL')}
                  </Button>,
                  <ReactToPrint
                    key="submit"
                    trigger={() => <Button type="primary">{intl.get(name)}</Button>}
                    content={() => this.print.current}
                    onAfterPrint={this.afterPrint.bind(this)}
                    onPrintError={this.handleCancel.bind(this, 'isModalVisible')}
                  />
                ]
              : [
                  <Button key="back" onClick={this.handleCancel.bind(this, 'isModalVisible')}>
                    {intl.get('CANCEL')}
                  </Button>,
                  <Button key="submit" type="primary" onClick={this.download.bind(this)}>
                    {intl.get(name)}
                  </Button>
                ]
          }
        >
          <span ref={this.print} id="reportList">
            {this.state.reports.map((item, index) => {
              return <PrintReport key={index} report={item} type={name} />;
            })}
          </span>
        </Modal>
        <Modal
          title={intl.get(name)}
          visible={this.state.isPrintVisible}
          className={style.checkModal}
          onCancel={this.handleCancel.bind(this, 'isPrintVisible')}
          footer={[
            <Button key="back" onClick={this.handleCancel.bind(this, 'isPrintVisible')}>
              {intl.get('NO_END')}
            </Button>,
            <Button key="submit" type="primary" onClick={this.batchPrint.bind(this)}>
              {intl.get('IS_END')}
            </Button>
          ]}
        >
          <ConfirmContent>
            <span>{intl.get('PRINT_WARNING')}</span>
          </ConfirmContent>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selected: state.loadImages.selected
  };
};

export default connect(mapStateToProps, { batchPrintReport })(ExportReport);
