import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { Form, Modal, Col, Button, Row, Select, Input, message } from 'antd';
import styles from './index.less';
import { rejectList, initalCheckInfo } from './checkInfo';
import { setValueOptions } from '../../utils/dataUtils';
import formatTimestamp from '../../utils/dateUtils';
 
import {
  setChckInInfo,
  updateChckInType,
  registration,
  backCheckInfo,
  updateRegistration,
  setHisInfo
} from '../../actions/checkInAction';
import { canRender } from '../Can';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import ConfirmContent from '../CommonComponent/ConfirmContent';
class TailInfo extends Component {
  constructor() {
    super();
    this.state = {
      isModalVisible: false,
      nowModal: ' ',
      backId: '',
      rejectValue: ''
    };
  }
  componentDidMount() {
  }
  showModal(name) {
    this.setState({
      isModalVisible: true,
      nowModal: name
    });
  }
  handleOk = (name) => {
    name === 'CANCEL_TITLE' && this.resetPage();
    name === 'BACK_TITLE' && this.back();
    name === 'CHECK_REJECT' && this.reject();
  };
  //撤销登记
  back() {
    const { backId } = this.state;
    if (backId === this.props.checkInfo.apply_id) {
      this.props.backCheckInfo(this.props.checkInfo.id);
      this.resetPage();
      this.setState({
        backId: ''
      });
    } else {
      message.error(intl.get('BACK_ID_ERROR'));
    }
  }

  resetPage() {
    this.setState({ isModalVisible: false, nowModal: ' ' });
    this.props.setChckInInfo(initalCheckInfo);
    this.props.setHisInfo(initalCheckInfo);
    this.setInitalValue();
  }
  // 格式化参数
  formatCheckInfo(data, type) {
    let format = cloneDeep(data);
    let timeList = [
      'last_m',
      'apply_date',
      'sample_sent_date',
      'sample_date',
      'sample_recevie_date',
      'cell_examination_date',
      'hpv_examination_date'
    ];
    let cannontNullKeyList = [...timeList, 'sample_index', 'fee'];
    if (type === 'update') {
      cannontNullKeyList = ['sample_index', 'fee'];
    }
    timeList.forEach((item) => {
      format[item] = [null, undefined, ''].includes(format[item])
        ? ''
        : new Date(new Date(format[item]).getTime());
    });
    cannontNullKeyList.forEach((item) => {
      format[item] === '' && delete format[item];
    });

    if (format.age_type === 'M') {
      format.age = (format.age / 12).toFixed(2);
    }

    return format;
  }
  // 拒收
  reject() {
    if (this.validateInfo() && this.props.subm()) {
      const { currentUser } = this.props;
      let rejected_reason = this.state.rejectValue;
      let c = this.props.checkInfo;
      c.sample_recevie_date = moment().format('YYYY-MM-DD HH:mm:ss');
      c.sample_recevier = currentUser.id;
      c = this.formatCheckInfo(c, 'add');
      let p = {
        ...c,
        rejected_reason: rejected_reason,
        is_rejected: true
      };
      let numInfoForm = this.props.getnumInfoForm();
      this.props.registration(p, numInfoForm).then((res) => {
        this.resetPage();
      });
    }
  }
  handleCancel() {
    this.setState({ isModalVisible: false, nowModal: ' ' });
  }
  //保存
  updateCheck() {
    const { currentUser, checkInfo } = this.props;
    let c = cloneDeep(checkInfo);
    c = this.formatCheckInfo(c, 'update');
    c.sample_recevier = currentUser.id;
    if (this.validateInfo() && this.props.subm()) {
      this.props.updateRegistration(checkInfo.id, c, currentUser.realname);
    }
  }
  alertError() {
    message.error(intl.get('SAVE_TEXT_ERROR'));
    return false;
  }
  //form验证
  validateInfo() {
    const { checkInfo } = this.props;
    const { age, name, gender, sample_sent_date } = checkInfo;
    let mustList = [age, name, gender, sample_sent_date];
    let isNull = mustList.includes('');

    // 非空
    if (!this.props.subm()) {
      if (checkInfo.pathology_id === '') {
        message.error(intl.get('SAVE_APPLY_ID'));
        return false;
      }
      return this.alertError();
    }
    if (isNull && !this.props.isModify) {
      message.error(intl.get('SAVE_TEXT'));
      return false;
    }
    return true;
  }
  setInitalValue() {
    this.props.updateInital();
  }
  //新建并保存
  addCheck() {
    if (this.validateInfo() && this.props.subm()) {
      const { currentUser } = this.props;
      let c = this.props.checkInfo;
      let numInfoForm = this.props.getnumInfoForm();
      c.sample_recevie_date = moment().format('YYYY-MM-DD HH:mm:ss');
      c.sample_recevier = currentUser.id;
      c.is_rejected = false;
      c = this.formatCheckInfo(c, 'add');
      this.props.registration(c, numInfoForm).then((res) => {
        if(!this.props.isModify){
          this.setInitalValue();
        }        
      });
    }
  }
  // 取消登记
  initalCheck() {
    this.props.updateChckInType('add');
    this.setInitalValue();
    this.props.setChckInInfo(initalCheckInfo);
  }
  checkBackId(event) {
    this.setState({
      backId: event.target.value
    });
  }
  onChangeSelect(value) {
    this.setState({
      rejectValue: value
    });
  }
  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        lg: { span: 8 },
        xl: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        lg: { span: 16 },
        xl: { span: 16 }
      },
      gutter: [16, 14]
      //   labelAlign:'left'
    };
    const { isModalVisible, nowModal, backId } = this.state;
    const rejectOption = setValueOptions('select', rejectList);
    const { currentUser, checkInfo } = this.props;

    return (
      <>
        <Form {...formItemLayout} className={styles.tail_info}>
          <Row>
            <Col span={8}>
              <Form.Item name="RECEIVE_TIME" label={intl.get('RECEIVE_TIME')}>
                <span>
                  {checkInfo.sample_recevie_date === '' || checkInfo.sample_recevie_date === null
                    ? ''
                    : formatTimestamp(checkInfo.sample_recevie_date, 'YYYY-MM-DD HH:mm:ss')}
                </span>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name="RECEIVE_PERSON" label={intl.get('RECEIVE_PERSON')}>
                <span>{checkInfo.sample_recevier}</span>
              </Form.Item>
            </Col>
            {this.props.checkType === 'add' && (
              this.props.isModify ? <Col span={9} className={styles.tail_btn}>               
              <Button type="primary" onClick={() => {
                if(this.props.checkInfo.id){
                  this.updateCheck('SAVE_TITLE')
                }else{
                  this.addCheck()
                }                
              }}>
                {intl.get('MODIFY_SAVE')}
              </Button> 
             </Col>:
              <Col span={9} className={styles.tail_btn}>               
                <Button type="primary" onClick={() => this.addCheck()}>
                  {intl.get('CHECK_SAVE')}
                </Button>
                <Button type="danger" onClick={() => this.showModal('CHECK_REJECT')}>
                  {intl.get('CHECK_REJECT')}
                </Button>
                <Button type="primary" onClick={() => this.showModal('CANCEL_TITLE')}>
                  {intl.get('CHECK_CANCEL')}
                </Button>
              </Col>
            )}
            {this.props.checkType === 'update' && (
                this.props.isModify ? <Col span={9} className={styles.tail_btn}>               
                <Button type="primary" onClick={() => {
                  if(this.props.checkInfo.id){
                    this.updateCheck('SAVE_TITLE')
                  }else{
                    this.addCheck()
                  } 
                }}>
                  {intl.get('MODIFY_SAVE')}
                </Button> 
               </Col>:
              <Col span={9} className={styles.tail_btn}>
                <Button
                  type="primary"
                  disabled={
                    canRender(currentUser.permissions, 'registration:modify') ? false : true
                  }
                  onClick={() => this.updateCheck('SAVE_TITLE')}
                >
                  {intl.get('REPORT_SAVE')}
                </Button>
                <Button
                  type="primary"
                  disabled={canRender(currentUser.permissions, 'registration:add') ? false : true}
                  onClick={() => this.initalCheck()}
                >
                  {intl.get('CHECK_ADD')}
                </Button>
                <Button
                  type="primary"
                  disabled={
                    canRender(currentUser.permissions, 'registration:delete') ? false : true
                  }
                  onClick={() => this.showModal('BACK_TITLE')}
                >
                  {intl.get('CHECK_BACK')}
                </Button>
              </Col>
            )}
          </Row>
        </Form>
        <Modal
          title={intl.get(nowModal)}
          visible={isModalVisible}
          onOk={this.handleOk.bind(this, nowModal)}
          onCancel={this.handleCancel.bind(this)}
          className={styles.checkModal}
        >
          <ConfirmContent>
            {nowModal === 'CHECK_REJECT' && (
              <Select
                placeholder={intl.get('REJECT_REASONS')}
                allowClear
                style={{ width: 300 }}
                onChange={(e) => {
                  this.onChangeSelect(e);
                }}
              >
                {rejectOption}
              </Select>
            )}
            {nowModal === 'CANCEL_TITLE' && (
              <div>{intl.get('CANCEL_TEXT')}</div>
            )}
            {nowModal === 'BACK_TITLE' && (
              <>
                <div>{intl.get('BACK_TEXT')}</div>
                <div className={styles.back_applyId}>
                  <Input
                    placeholder={intl.get('BACK_PLACEHOLDER')}
                    value={backId}
                    onChange={(e) => this.checkBackId(e)}
                  />
                </div>
              </>
            )}
          </ConfirmContent>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    checkInfo: state.checkIn.checkInfo,
    checkType: state.checkIn.checkType,
  };
};

export default connect(mapStateToProps, {
  setChckInInfo,
  updateChckInType,
  registration,
  backCheckInfo,
  updateRegistration,
  setHisInfo
})(Form.create()(TailInfo));
