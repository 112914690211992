import React, { Component } from 'react';
import { connect } from 'react-redux';
import ToolButton from '../ToolButton'
import MyIcon from '../Iconfont'
import {Tooltip,Popover} from 'antd'
import intl from 'react-intl-universal';
import MsgBox from '../MsgBox'
import styles from  './MsgBtn.less'
class MsgBtn extends Component {
  
  state={
    visible:false
  }
  onCancel=()=>{
    this.setState({
      visible:false
    })
  }

  handleVisibleChange = visible => {
    this.setState({
      visible: visible
    });
  };

  render() {
    const com = this.props.comments?this.props.comments:[]
    return (
      <Popover 
        overlayClassName={styles.pop} 
        content={<MsgBox onCancel={this.onCancel}/>} 
        title={intl.get('COMMENTS_NOTE')}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
      >
        <Tooltip placement="bottom" title={intl.get('COMMENTS_NOTE')}>
          <div style={{paddingRight:'20px'}}>
          <ToolButton 
            icon={<MyIcon type="icon-toolcomment"/>}
            status={this.props.tagVisibility ?"active":"normal"}
            value={com.length}
            width={com.length>9?"40px":"30px"}
          />
          </div>
        </Tooltip>
      </Popover>

    );
  }
}

const mapStateToProps = (state) => ({
  comments: state.comments.data,
});

export default connect(mapStateToProps, {  })(MsgBtn);


