import React from 'react';
import ChinaMap from '../Visualization/ChinaMap';
import CardNumbers from './CardNumbers';
import style from './index.less';
function DataMap(props){
  const { data,selected } = props;
  return (
    <div className={style.data_map}>
       <ChinaMap
          data={data}
          selected={selected}
          parentName="dataPlatform"
          otherStyle={{ height: '100%' }}
        />
        <CardNumbers data={data} selected={selected}/>
    </div>
  );
}

export default DataMap;
