import React, { Component } from 'react';
import intl from 'react-intl-universal';
import styles from './index.less';
import { Col, Row } from 'antd';
import moment from 'moment';
import { getValuesFromVisEles } from '../../utils/reportUtils';
import { setPlanReporter } from '../../actions/reportAction';
import { connect } from 'react-redux';
import DoctorList from '../DoctorList';
import { filterJson } from '../../utils/dataUtils';
class ReportTail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      reportDate: moment()
    };
  }

  componentDidMount() {
    const { currentImageInfo, report, currentUser } = this.props;
    if ([6, 7].includes(currentImageInfo.stage) && report.senior_doctor === currentUser.realname) {
      this.getPlanReporter(currentUser.id);
    }
  }

  getPlanReporter(data) {
    let reporters = this.props.planReporter;
    let info = {
      imageId: this.props.currentImageInfo.id,
      reporter: data
    };
    if (reporters === undefined || reporters.length === 0) {
      reporters.push(info);
    } else {
      for (let i = 0; i < reporters.length; i++) {
        if (reporters[i].imageId === this.props.currentImageInfo.id) {
          reporters[i].reporter = data;
          break;
        }
        if (i === reporters.length - 1) {
          reporters.push(info);
        }
      }
    }
    this.props.setPlanReporter(reporters);
  }

  getColStyle = (index) => {
    let mod = index % 3;
    let colStyle;
    switch (mod) {
      case 0:
        colStyle = styles.tail__aligin__left;
        break;
      case 1:
        colStyle = styles.tail__aligin__center;
        break;
      case 2:
        colStyle = styles.tail__aligin__right;
        break;
    }
    return colStyle;
  };
  getDoctorList = () => {
    const { roleUsers, general } = this.props;
    const list = general.senior_distribute_roles.map((item) => roleUsers[item]);
    return filterJson(list.flat(),'id').filter((r) => r.distributable === true)
  };
  setTailVal = (item) => {
    const { currentUser, currentImageInfo, report, printing } = this.props;
    if (item === 'report_time') {
      return this.state.reportDate.format('YYYY-MM-DD');
    } else if (
      item === 'senior_doctor' &&
      [6, 7].includes(currentImageInfo.stage) &&
      report[item] === currentUser.realname
    ) {
      return printing ? (
        report.senior_doctor
      ) : (
        <DoctorList
          selected={this.getPlanReporter.bind(this)}
          parentName="report"
          width="100px"
          doctorList={
            this.getDoctorList() !== undefined
              ? this.getDoctorList()
              : []
          }
        />
      );
    } else if (item === 'junior_doctor') {
      return report.junior_roles
        ? report.junior_roles.includes('ai')
          ? report.senior_doctor
          : report.junior_doctor
        : '';
    } else {
      return report[item];
    }
  };
  render() {
    
    const { visible_elements } = this.props.reportConf;
    // const { currentUser, currentImageInfo,roleUsers } = this.props;
    let info = getValuesFromVisEles(visible_elements)['footer_info'];
    let MappedObj = {
      junior_doctor: intl.get('JUNIOR_DOCTOR') + ': ',
      senior_doctor: intl.get('SENIOR_DOCTOR') + ': ',
      report_doctor: intl.get('REPORT_DOCTOR') + ': ',
      responsibility_statement: intl.get('REPORT_HINT_SIMPLE'),
      report_time: intl.get('REPORT_TIME') + ': ',
      company_name: intl.get('COMPANY_NAME_TEXT'),
      company_telephone: intl.get('COMPANY_TELEPHONE') + ': 0755-22675803',
      company_web: <a href="http://www.imsightmed.com">http://www.imsightmed.com</a>
    };
    return (
      <div className={styles.tail}>
        <Row>
          {info.map((item, index) => {
            return (
              <Col key={item} span={8} className={this.getColStyle(index)}>
                <span>
                  {MappedObj[item]}
                  {this.setTailVal(item)}
                </span>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentImageInfo: state.imageSelection.currentImageInfo,
  planReporter: state.report.planReporter,
  currentUser: state.currentUser,
  roleUsers: state.report.roleUsers,
  general: state.config.general
});
export default connect(mapStateToProps, { setPlanReporter })(ReportTail);
