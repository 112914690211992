import React from 'react';
import DistributionBar from '../Visualization/DistributionBar';
import CommonPanel from './CommonPanel';
import intl from 'react-intl-universal';
import { fontColor } from './dataInfo';

function QualifiedRate(props){
  const { data } = props;
  return (
    <CommonPanel name={intl.get('QUALITY_CTRL')}>
      <DistributionBar
        data={data}
        parentName="dataPlatform"
        otherStyle={{ xyColor: fontColor,height:"90%" }}
        series={{
          background: {
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(110, 193, 244, 0.2)'
            }
          },
          itemStyle: { borderWidth: 5, borderColor: 'rgba(42,157,255,0.4)' }
        }}
      />
    </CommonPanel>
  );
}
export default QualifiedRate;
