import intl from 'react-intl-universal';
import { setValueOptions } from '../../utils/dataUtils';
const typeList = [
  {
    value: 'mysql',
    label: 'MYSQL'
  },
  {
    value: 'mssql',
    label: 'SQLSERVER'
  }
];
export function getInfo() {
  return [
    {
      name: intl.get('SQL_TYPE'),
      type: 'select',
      key: 'engine',
      options: setValueOptions('select', typeList),
      rules: [],
      span: 4,
      offset: 0
    },
    {
      name: intl.get('SQL_USERNAME'),
      type: 'input',
      key: 'username',
      rules: [],
      span: 4,
      offset: 0
    },
    {
      name: intl.get('SQL_PWD'),
      type: 'input',
      key: 'password',
      rules: [],
      span: 4,
      offset: 0
    },
    {
      name: intl.get('SQL_ADDR'),
      type: 'input',
      key: 'host',
      rules: [],
      span: 4,
      offset: 0
    },
    {
      name: intl.get('SQL_NAME'),
      type: 'input',
      key: 'dbName',
      rules: [],
      span: 4,
      offset: 0
    },
    {
      name: intl.get('VIEW_NAME'),
      type: 'input',
      key: 'view',
      rules: [],
      span: 4,
      offset: 0
    }
  ];
}

export function getParameters() {
  return [
    //   1
    {
      name: intl.get('APPLY_ID'),
      key: 'apply_id',
      must: true
    },
    {
      name: intl.get('PATIENT_NAME_LABEL'),
      key: 'name',
      must: true
    },
    {
      name: intl.get('GENDER'),
      key: 'gender',
      must: true
    },
    {
      name: intl.get('AGE'),
      key: 'age',
      must: true
    },
    {
      name: intl.get('CHECK_IN_NATION'),
      key: 'ethnic_group',
      must: false
    },
    {
      name: intl.get('CHECK_IN_WORK'),
      key: 'occupation',
      must: false
    },
    {
      name: intl.get('CHECK_IN_NAVTICEPLACE'),
      key: 'origin',
      must: false
    },
    {
      name: intl.get('ACCOUNT_PHONE'),
      key: 'patient_telephone',
      must: false
    },
    {
      name: intl.get('ID_CARD'),
      key: 'identity',
      must: false
    },
    // 2
    {
      name: intl.get('MARRY'),
      key: 'marital_status',
      must: false
    },
    {
      name: intl.get('URGENT_CASE'),
      key: 'is_urgent',
      must: false
    },
    {
      name: intl.get('LAST_M'),
      key: 'last_m',
      must: false
    },
    {
      name: intl.get('M_END'),
      key: 'is_m_end',
      must: false
    },
    {
      name: intl.get('SAMPLE_UNIT'),
      key: 'sample_unit',
      must: false
    },
    {
      name: intl.get('SAMPLE_DEPARTMENT'),
      key: 'sample_department',
      must: false
    },
    {
      name: intl.get('SAMPLE_SENT_DOCTOR'),
      key: 'apply_doctor',
      must: false
    },
    {
      name: intl.get('APPLY_TIME'),
      key: 'apply_date',
      must: false
    },
    {
      name: intl.get('SEE_TYPE'),
      key: 'clinic_type',
      must: false
    },
    {
      name: intl.get('INSPECTION_TIME'),
      key: 'sample_sent_date',
      must: false
    },
    // 3
    {
      name: intl.get('PATHOLOGY_TYPE'),
      key: 'pathology_type',
      must: true
    },
    {
      name: intl.get('PATIENT_ID'),
      key: 'case_id',
      must: false
    },
    {
      name: intl.get('OUTPATIENT_ID'),
      key: 'clinic_id',
      must: false
    },
    {
      name: intl.get('HOSPITAL_AREA'),
      key: 'ward',
      must: false
    },
    {
      name: intl.get('HOSPITAL_ID'),
      key: 'admission_id',
      must: false
    },
    {
      name: intl.get('MONEY_TYPE'),
      key: 'fee_type',
      must: false
    },
    {
      name: intl.get('MONEY_NUM'),
      key: 'fee',
      must: false
    },
    {
      name: intl.get('BED_ID'),
      key: 'bed_id',
      must: false
    },
    //4
    {
      name: intl.get('PAIENT_PRIVACY'),
      key: 'sensitivity',
      must: false
    },
    {
      name: intl.get('SAMPLE_CONTAGION'),
      key: 'infectivity',
      must: false
    },
    {
      name: intl.get('SPECIMEN_TYPE'),
      key: 'sample_type',
      must: true
    },
    {
      name: intl.get('SPECIMEN_DATE'),
      key: 'sample_date',
      must: false
    },
    {
      name: intl.get('SPACEMEN_LOC'),
      key: 'sample_region',
      must: false
    },
    {
      name: intl.get('SPACEMEN_NAME_PLACEHOLDER'),
      key: 'sample_name',
      must: false
    },
    {
      name: intl.get('SPACEMEN_NUM'),
      key: 'sample_index',
      must: false
    },
    {
      name: intl.get('REPORT_DIAGNOSIS_HISTORY_LABEL'),
      key: 'clinic_diagnosis',
      must: false
    },
    //5
    {
      name: intl.get('PATIENT_SUMMARY'),
      key: 'clinic_summary',
      must: false
    },
    {
      name: intl.get('CLINICAL_PATIENT'),
      key: 'clinic_record',
      must: false
    },
    {
      name: intl.get('OPERATION_DIAGNOSIS'),
      key: 'surgery_record',
      must: false
    },
    {
      name: intl.get('OTHER_DIAGNOSIS'),
      key: 'other_examination',
      must: false
    }
  ];
}
