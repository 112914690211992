import React from 'react'
import styles from './index.module.css'
import {Icon} from 'antd'
function getClassName(status) {
  if (status === 'disabled') return styles.disabled
  if (status === 'active') return styles.active
  return styles.normal
}
function index(props) {
  const { status, width, height } = props
  return (
    <div onClick={props.onClick} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} style={{ width: width, height: height }} className={getClassName(status)}>
      {props.icon ? props.icon : props.text}
      <sup style={{fontSize:"12px",fontWeight:'600',color:"#1890FF"}}>{props.value?props.value:''}</sup>
    </div>
  )
}
index.propTypes = {

}
index.defaultProps = {
  status: "normal",
  width: '30px',
  height: '30px',
  icon: <Icon type="user"/>,
  value:""
}
export default index



