export const tagIdlabels = ['PREFIX', 'DEFAULT_NUMBER', 'CONNECTOR', 'NUM_LEN'];

const defaultNum = [
  { value: '%y', label: 'YY' },
  { value: '%Y', label: 'YYYY' }
];

const connectList = [
  { value: '-', label: 'TAG_ID_SHORT' },
  { value: '一', label: 'TAG_ID_LINE' },
  { value: '～', label: 'TAG_ID_WAVE' }
];

const lenList = [
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' }
];

export const tagIdContent = [
  {
    name: 'PREFIX',
    key: 'pathology_id_prefix_alphabet',
    type: 'input',
    defaultValue: 'T'
  },
  {
    name: 'DEFAULT_NUMBER',
    key: 'pathology_id_prefix_time',
    type: 'select',
    options: defaultNum,
    defaultValue: '%y',
    disabled:false,
  },
  {
    name: 'CONNECTOR',
    key: 'pathology_id_prefix_linker',
    type: 'select',
    options: connectList,
    disabled:false,
  },
  {
    name: 'NUM_LEN',
    key: 'pathology_id_number_count',
    type: 'select',
    options: lenList,
    defaultValue: 5,
    disabled:true,
  }
];

export const gradeNameList = [
  {
    name: 'checkedOrganization',
    otherName: 'organization',
    value: 'ORGANIZATION',
    children: [
      { name: 'checkedDepartment', otherName: 'department', label: 'DEPARTMENT' },
      { name: 'checkDoctor', otherName: 'doctor', label: 'DOCTOR' }
    ]
  },
  {
    name: 'checkedDepartment',
    value: 'DEPARTMENT',
    otherName: 'department',
    children: [{ name: 'checkDoctor', otherName: 'doctor', label: 'DOCTOR' }]
  },
  {
    name: 'checkedDoctor',
    value: 'DOCTOR',
    otherName: 'doctor',
    children: []
  }
];
export const modeLabels = ['JUNIOR_STATISTICS', 'REPORT_STATISTICS'];

export const modeList = [
  {
    value: true,
    label: 'AUTO_LABEL'
  },
  {
    value: false,
    label: 'HAND_LABEL'
  }
];


export const numModeList = [
  {
    value: 0,
    label: 'DIS_TIME_LAN'
  },
  {
    value: 1,
    label: 'DIS_TIME_AVER'
  }
];