import { Affix, Button, Card, Col, Result, Row, Spin, Tabs } from 'antd';
import { isEqual } from 'lodash';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import {
  clearAnalysis,
  exportAnalysisResult,
  getAnalysisResult,
  toggleLoadingAnalysisPage
} from '../../actions/analysisActions';
import {
  getLoadingStatus,
  getMatrixes,
  getNegativeAnalysis,
  getNR,
  getStatistics
} from '../../selectors/analysisSelector';
import { getQueryInfo } from '../../selectors/imageSelector';
import Icon from '../Iconfont';
import QuerySettingDrawer from '../QuerySettingDrawer';
import StatisticsPanel from '../StatisticsPanel';
import style from './index.less';
import MatrixAnalysis from './MatrixAnalysis';
import MatrixTable from './MatrixTable';
import NegativeAnalysis from './NegativeAnalysis';
import { COLOR_TABLE, fullNameHeaders, shortHeaders } from './titles';
import config from '../../config';
import ThresholdInfo from "./ThresholdInfo"
const { TabPane } = Tabs;

class Analysis extends Component {
  constructor(props) {
    super(props);
    const additionQueryInfo = this.props.location.state ? { ...this.props.location.state } : { clazz: config.defaultCancerType }
    this.state = {
      settingVisible: false,
      queryInfo: { ...props.queryInfo, ...additionQueryInfo },
      tabs: [],
      errorCancerType: false
    };
    const { clazz } = this.state.queryInfo;
    if (clazz === 'cervical') {
      this.state.tabs = [
        intl.get('CRUDE_CLASSIFY'),
        intl.get('DETAIL_CLASSIFY'),
        intl.get('MICROBE_CLASSIFY')
      ];
    } else if (clazz === 'urine') {
      this.state.tabs = [intl.get('DETAIL_CLASSIFY')];
    }
  }

  componentDidMount() {
    this.fetchAnalysis();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState.queryInfo, this.state.queryInfo)) {
      this.fetchAnalysis();
    }
  }

  componentWillUnmount() {
    this.props.clearAnalysis();
  }

  fetchAnalysis = () => {
    this.props.getAnalysisResult(this.state.queryInfo);
  };

  exportAnalysisResult = () => {
    this.props.exportAnalysisResult(this.state.queryInfo);
  };

  showSetting = () => {
    this.setState({ settingVisible: true });
  };

  onConfirmQueryInfoChange = (newQueryInfo) => {
    if (!isEqual(newQueryInfo, this.state.queryInfo)) {
      const query = { ...this.state.queryInfo, ...newQueryInfo };
      this.setState({ queryInfo: query, settingVisible: false });
      this.props.toggleLoadingAnalysisPage(false);
    }
  };

  render() {
    const { statistics, matrixes, negativeAnalysis, negativeRate } = this.props;
    const { errorCancerType, queryInfo, tabs } = this.state;
    const { clazz } = queryInfo;
    if (errorCancerType) {
      return (
        <Result
          status="error"
          title={intl.get('ANALYSIS_CANCER_TYPE_ERROR')}
          subTitle={intl.get('ANALYSIS_ONLY_SUPPORT_CERVICAL')}
        />
      );
    }
    return (
      <div>
        <ThresholdInfo />
        <Affix style={{ position: 'absolute', bottom: 50, right: 50, zIndex: 1000 }}>
          <Button type="primary" icon="setting" onClick={this.showSetting} />
        </Affix>
        <QuerySettingDrawer
          queryInfo={queryInfo}
          visible={this.state.settingVisible}
          placement="right"
          onClose={() => {
            this.setState({ settingVisible: false });
          }}
          onConfirm={this.onConfirmQueryInfoChange}
        />
        <Spin
          spinning={this.props.loading}
          indicator={<Icon type="loading" style={{ fontSize: 50 }} />}
        >
          <Row>
            <Col span={5}>
              <Col>
                <StatisticsPanel
                  className={style.statisticsPanel}
                  content={statistics}
                  style={{ height: '40%' }}
                />
              </Col>
              <Col>
                <Card bodyStyle={{ padding: 10 }}>
                  <MatrixTable
                    size="small"
                    matrix={matrixes[0]}
                    header={shortHeaders[clazz][0]}
                    fullHeader={fullNameHeaders[clazz][0]}
                  />
                </Card>
              </Col>
            </Col>
            {negativeAnalysis.length ? (
              <Col span={18} offset={1}>
                <NegativeAnalysis
                  data={negativeAnalysis}
                  nr={negativeRate.nr}
                  tnr={negativeRate.tnr}
                  cancerType={clazz}
                />
              </Col>
            ) : (
              ''
            )}
          </Row>

          {tabs.length ? (
            <div className={style.analysisTab}>
              <Tabs
                type="card"
                tabBarExtraContent={
                  <Button type="primary" onClick={this.exportAnalysisResult}>
                    {intl.get('EXPORT_ANALYSIS_RESULT')}
                  </Button>
                }
                defaultActiveKey="1"
              >
                {tabs.map((tab, i) => {
                  if (i <= 1 && clazz === 'cervical') {
                    return (
                      <TabPane tab={tab} key={i}>
                        <MatrixAnalysis
                          matrix={matrixes.slice(1, 3)}
                          header={fullNameHeaders[clazz].slice(1, 3)}
                          colorTable={COLOR_TABLE[clazz].slice(1, 3)}
                          formHeader={fullNameHeaders[clazz][i + 1]}
                          formMatrix={matrixes[i + 1]}
                        />
                      </TabPane>
                    );
                  } else {
                    return (
                      <TabPane tab={tab} key={i}>
                        <MatrixAnalysis
                          matrix={matrixes.slice(i + 1, i + 2)}
                          header={fullNameHeaders[clazz].slice(i + 1, i + 2)}
                          colorTable={COLOR_TABLE[clazz].slice(i + 1, i + 2)}
                          formHeader={fullNameHeaders[clazz][i + 1]}
                          formMatrix={matrixes[i + 1]}
                        />
                      </TabPane>
                    );
                  }
                })}
              </Tabs>
            </div>
          ) : (
            ''
          )}
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    queryInfo: getQueryInfo(state),
    loading: getLoadingStatus(state),
    statistics: getStatistics(state),
    matrixes: getMatrixes(state),
    negativeAnalysis: getNegativeAnalysis(state),
    negativeRate: getNR(state)
  };
};

export default connect(mapStateToProps, {
  exportAnalysisResult,
  toggleLoadingAnalysisPage,
  getAnalysisResult,
  clearAnalysis
})(Analysis);
