import intl from 'react-intl-universal';
import { validatePwd } from '../../utils/regUtils';
export default function getChangeAdminKeyFields() {
  return [
    {
      field: 'old_admin_key',
      icon: 'lock',
      type: 'password',
      editable: true,
      rules: [{ required: true, message: intl.get('OLD_ADMIN_KEY_NOT_EMPTY') }],
    },
    {
      field: 'new_admin_key',
      icon: 'lock',
      type: 'password',
      editable: true,
      rules: [
        { required: true, message: intl.get('NEW_ADMIN_KEY_NOT_EMPTY') },
        {
          ...validatePwd,
          message: intl.get('ADMIN_KEY_FORMAT')
        }
      ],
      maxLength:16
    }
  ];
}
