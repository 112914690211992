import React from 'react';
import LesionDistribution from '../Visualization/LesionDistribution';
// import LesionDistributionDynamic from '../Visualization/LesionDistributionDynamic';

import CommonPanel from './CommonPanel';
import intl from 'react-intl-universal';
import { fontColor } from './dataInfo';
import style from './index.less';

function DisplayPart(props){
  const { data } = props;
  return (
    <div className={style.display_content} style={{ height: '30%' }}>
      <div>
        <CommonPanel name={intl.get('CERVICAL_LESIONS_DISTRIBUTION')} height="100%">
          <LesionDistribution
            data={data.ai_endothelial}
            parentName="dataPlatform"
            otherStyle={{ xyColor: fontColor, height: '100%' }}
          />
            {/* <LesionDistributionDynamic
            data={data.ai_endothelial}
            parentName="dataPlatform"
            otherStyle={{ xyColor: fontColor, height: '100%' }}
          /> */}
        </CommonPanel>
      </div>
      <div>
        <CommonPanel name={intl.get('CERVICAL_MICROBIOLOGY_DISTRIBUTION')} height="100%">
          <LesionDistribution
            data={data.ai_microbe}
            parentName="dataPlatform"
            otherStyle={{ xyColor: fontColor, height: '100%' }}
          />
            {/* <LesionDistributionDynamic
            data={data.ai_microbe}
            parentName="dataPlatform"
            otherStyle={{ xyColor: fontColor, height: '100%' }}
          /> */}
        </CommonPanel>
      </div>
    </div>
  );
}

export default DisplayPart;
