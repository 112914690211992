import React, { Component } from 'react';
import style from './index.less';
import LoadingSpin from './LoadingSpin';
import BaseChart from './BaseChart';
import { formatNDigit, isDataPaltform } from './utils';
import intl from 'react-intl-universal';
import { titleStyle, girdInfo, axisLineX, axisLineY } from './echartInfo';
export class PositiveRate extends Component {
  getOption() {
    const { data, parentName, otherStyle, series } = this.props;
    const isp = isDataPaltform(parentName);
    let option = {};
    if (data[data.length - 1]) {
      let lastMonth = Number(data[data.length - 1].time.split('-')[1]);
      let lastYear = Number(data[data.length - 1].time.split('-')[0]);

      let fromatData = new Array(12).fill('-');
      let formatXAxis = [];

      for (let i = 0; i < 12; i++) {
        let year = lastYear;
        let month = lastMonth - i;
        if (month <= 0) {
          year--;
          month = (month + 12) % 13;
        }
        formatXAxis.push(year + '-' + month);

        data.forEach((item, jj) => {
          if (Number(item.time.split('-')[1]) === month) {
            fromatData[i] = formatNDigit(item.data * 100, 0);
          }
        });
      }

      option = {
        title: {
          show: !isp,
          left: 'left',
          text: intl.get('POSITIVE_RATE'),
          top: 10,
          textStyle: titleStyle
        },
        tooltip: {
          formatter: '{b}\n{c}%'
        },
        color: ['#83bff6', '#EE6666'],
        grid: girdInfo,
        xAxis: {
          data: formatXAxis.reverse(),
          axisTick: {
            show: false
          },
          axisLine: axisLineX,
          axisLabel: {
            textStyle: {
              color: otherStyle.xyColor,
              fontSize: 12
            }
          }
        },
        yAxis: [
          {
            type: 'value',
            axisTick: {
              show: false
            },
            axisLine: axisLineY,
            axisLabel: {
              show: true,
              interval: 'auto',
              formatter: '{value} %', //以百分比显示
              textStyle: {
                color: otherStyle.xyColor
              }
            },
            splitArea: {
              areaStyle: {
                color: 'rgba(255,255,255,.5)'
              }
            },
            splitLine: {
              show: !isp,
              lineStyle: {
                color: '#666',
                width: 0.5,
                type: 'dashed'
              }
            }
          }
        ],
        series: [
          {
            data: fromatData.reverse(),
            type: series.type,
            symbol: 'circle',
            symbolSize: 15,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: otherStyle.xyColor
              },
              formatter: '{c}%'
            },
            ...series
          }
        ]
      };
    }
    return option;
  }
  render() {
    const { parentName, data, otherStyle } = this.props;
    const isDataPlatform = isDataPaltform(parentName);
    return (
      <div className={isDataPlatform ? style.chart_container_positive : style.chart_container}>
        {data ? (
          <BaseChart
            style={{ height: otherStyle.height, width: '100%' }}
            option={this.getOption()}
          />
        ) : (
          <LoadingSpin />
        )}
      </div>
    );
  }
}

export default PositiveRate;
