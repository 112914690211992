import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import {
  Form,
  Input,
  Select,
  Col,
  Radio,
  DatePicker,
  Checkbox,
  Row,
  Cascader,
  Tooltip
} from 'antd';
import { debounce } from 'lodash';
import styles from './index.less';
import { updateChckInInfoParams } from '../../actions/checkInAction';
import { formItemLayout, getHisInfo } from './checkInfo';
import formatTimestamp from '../../utils/dateUtils';
import moment from 'moment';
import { isNoVal } from '../../utils/dataUtils';
import { getCurrentLocale } from '../../static/locales';
class BasicInfo extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
  }

  delayedChange = debounce((value, name) => {
    this.props.updateChckInInfoParams({ [name]: value });
  }, 500);

  onChangeInput = (event, name) => {
    this.delayedChange(event.target.value, name);
  };

  onChangeSelect(value, name) {
    this.props.updateChckInInfoParams({ [name]: value });
  }
  selectUnit(value, name) {
    const { checkInfo } = this.props;
    let na = name.slice(0, name.indexOf('_'));
    if (value !== checkInfo[name]) {
      this.props.updateChckInInfoParams({ [name]: value, [na]: '' });
      this.props.form.setFieldsValue({
        [na]: ''
      });
    } else {
      this.props.updateChckInInfoParams({ [name]: value });
    }
  }
  onChangeRadio(event, name) {
    this.props.updateChckInInfoParams({ [name]: event.target.value });
  }
  onChangeDate(data, dateString, name) {
    this.props.updateChckInInfoParams({ [name]: dateString });
  }
  onChangeCheck(event, name) {
    this.props.updateChckInInfoParams({ [name]: event.target.checked });
  }
  onChangeCheckGroup(value, name) {
    this.props.updateChckInInfoParams({ [name]: value });
  }
  onCascader(value) {
    let p = {
      sample_unit: value[0] ? value[0] : '',
      sample_department: value[1] ? value[1] : '',
      apply_doctor: value[2] ? value[2] : ''
    };
    this.props.updateChckInInfoParams(p);
  }
  setBeFOrAftOpt(list, val) {
    let l = list.filter((item) => item.value === val);
    if (l.length === 0) {
      return intl.get(list[0].label);
    } else {
      return intl.get(l[0].label);
    }
  }

  disabledDate(current) {
    return current > moment();
  }

  handleSubmit() {
    let res;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        res = true;
        return true;
      } else {
        res = false;
        return false;
      }
    });
    return res;
  }

  updateFormInitalValue(data) {
    this.props.column.forEach((element) => {
      if (element.key !== undefined) {
        if (element.type === 'DatePicker') {
          let f = [null, undefined, ''].includes(data[element.key])
            ? null
            : moment(data[element.key]);
          this.props.form.setFieldsValue({
            [element.key]: f
          });
        } else if (element.type === 'Cascader') {
          this.props.form.setFieldsValue({
            [element.key]: !isNoVal(data.sample_unit)
              ? [data.sample_unit, data.sample_department, data.apply_doctor]
              : []
          });
        } else {
          this.props.form.setFieldsValue({
            [element.key]: data[element.key]
          });
        }
      }
    });
  }

  setHis(type, val, optionsList, showTime) {
    if (['select', 'radio'].includes(type)) {
      return getHisInfo(val, optionsList);
    } else if (type === 'DatePicker') {
      let f = showTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';
      return [undefined, null, ''].includes(val) ? '' : formatTimestamp(val, f);
    } else if (type === 'CheckBox') {
      return intl.get(optionsList.label);
    } else {
      return val;
    }
  }
  filter(inputValue, path) {
    return path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  }
  getFields() {
    const column = this.props.column;
    const children = [];
    const { getFieldDecorator } = this.props.form;
    const { info, hisInfo, location } = this.props;
    const cannotEdit = location === 'report' ? true : false;
    for (let i = 0; i < column.length; i++) {
      children.push(
        <Col span={column[i].span} key={i}>
          <Tooltip
            placement="top"
            title={
              hisInfo[column[i].key] === info[column[i].key] ||
              [null, undefined, ''].includes(hisInfo[column[i].key])
                ? ''
                : intl.get('HIS_TITLE', {
                    info: this.setHis(
                      column[i].type,
                      hisInfo[column[i].key],
                      column[i].optionsList,
                      column[i].showTime
                    )
                  })
            }
          >
            <Form.Item
              label={column[i].name}
              colon={false}
              key={column[i].key}
              wrapperCol={column[i].wrapperCol}
              labelCol={column[i].labelCol}
            >
              {column[i].type === 'input' &&
                getFieldDecorator(column[i].key, {
                  rules: column[i].rules,
                  initialValue: info[column[i].key]
                })(
                  <Input
                    addonAfter={
                      column[i].addonAfterOptions !== undefined ? (
                        <Select
                          onChange={(e) => {
                            this.selectUnit(e, column[i].key + '_type');
                          }}
                          value={this.setBeFOrAftOpt(
                            column[i].valueList,
                            info[column[i].key + '_type']
                          )}
                          disabled={cannotEdit}
                        >
                          {column[i].addonAfterOptions}
                        </Select>
                      ) : (
                        ''
                      )
                    }
                    addonBefore={
                      column[i].addonBeforeOptions !== undefined ? (
                        <Select
                          onChange={(e) => {
                            this.selectUnit(e, column[i].key + '_type');
                          }}
                          value={this.setBeFOrAftOpt(
                            column[i].valueList,
                            info[column[i].key + '_type']
                          )}
                          disabled={cannotEdit}
                        >
                          {column[i].addonBeforeOptions}
                        </Select>
                      ) : (
                        ''
                      )
                    }
                    onChange={(e) => this.onChangeInput(e, column[i].key)}
                    placeholder={column[i].placeholder}
                    name={column[i].key}
                    disabled={cannotEdit}
                    maxLength={column[i].maxLength}
                  />
                )}
              {column[i].type === 'select' &&
                getFieldDecorator(column[i].key, {
                  rules: column[i].rules,
                  initialValue: info[column[i].key]
                })(
                  <Select
                    showSearch
                    allowClear
                    onChange={(e) => {
                      this.onChangeSelect(e, column[i].key);
                    }}
                    placeholder={column[i].placeholder}
                    disabled={cannotEdit}
                  >
                    {column[i].options}
                  </Select>
                )}
              {column[i].type === 'radio' &&
                getFieldDecorator(column[i].key, {
                  rules: column[i].rules,
                  initialValue: info[column[i].key]
                })(
                  <Radio.Group
                    onChange={(e) => {
                      this.onChangeRadio(e, column[i].key);
                    }}
                    disabled={cannotEdit}
                  >
                    {column[i].options}
                  </Radio.Group>
                )}

              {column[i].type === 'DatePicker' &&
                getFieldDecorator(column[i].key, {
                  rules: column[i].rules,
                  initialValue: ![null, undefined, ''].includes(info[column[i].key])
                    ? moment(info[column[i].key])
                    : null
                })(
                  <DatePicker
                    disabledDate={this.disabledDate.bind(this)}
                    showTime={column[i].showTime}
                    format={column[i].showTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'}
                    onChange={(data, dateString) =>
                      this.onChangeDate(data, dateString, column[i].key)
                    }
                    style={{ minWidth: '100%' }}
                    disabled={cannotEdit}
                  />
                )}

              {column[i].type === 'CheckBoxGroup' &&
                getFieldDecorator(column[i].key, {
                  rules: column[i].rules,
                  initialValue: info[column[i].key]
                })(
                  <Checkbox.Group
                    options={column[i].options}
                    onChange={(e) => this.onChangeCheckGroup(e, column[i].key)}
                    disabled={cannotEdit}
                  />
                )}
              {column[i].type === 'CheckBox' && (
                <Checkbox
                  key={column[i].options.value}
                  value={column[i].options.value}
                  checked={info[column[i].key]}
                  onChange={(e) => this.onChangeCheck(e, column[i].key)}
                  disabled={cannotEdit}
                >
                  {intl.get(column[i].options.label)}
                </Checkbox>
              )}
              {column[i].type === 'Cascader' &&
                getFieldDecorator(column[i].key, {
                  rules: column[i].rules,
                  initialValue:
                  !isNoVal(info.sample_unit)
                      ? [info.sample_unit, info.sample_department, info.apply_doctor]
                      : []
                })(
                  <Cascader
                    options={column[i].options}
                    onChange={this.onCascader.bind(this)}
                    placeholder={column[i].placeholder}
                    disabled={cannotEdit}
                    showSearch={this.filter.bind(this)}
                  >
                    <Input
                      placeholder={column[i].placeholder}
                      disabled={cannotEdit}
                      value={
                        !isNoVal(info.sample_unit)
                          ? info.sample_unit +
                            '/' +
                            info.sample_department +
                            '/' +
                            info.apply_doctor
                          : ''
                      }
                    />
                  </Cascader>
                )}
            </Form.Item>
          </Tooltip>
        </Col>
      );
    }
    return children;
  }

  render() {
    return (
      <>
        <Row gutter={10}>
          <Col span={8}>
            <Col span={getCurrentLocale() === 'zh'?6:10} className={getCurrentLocale() === 'zh'?styles.check__title:styles.check__title__en}>
              {intl.get(this.props.titleName)}
            </Col>
          </Col>
        </Row>
        <Form {...formItemLayout}>
          <Row gutter={10}>{this.getFields()}</Row>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    checkType: state.checkIn.checkType,
    checkInfo: state.checkIn.checkInfo
  };
};

export default connect(mapStateToProps, { updateChckInInfoParams })(Form.create()(BasicInfo));
