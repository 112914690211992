import React, { Component } from 'react';
import { connect } from 'react-redux';
import SearchTab from './SearchTab';
import style from './index.less';
import ProTable from './ProTable';
import { Pagination } from 'antd';
import { setProQueryInfo, getRole } from '../../actions/productionAction';
import intl from 'react-intl-universal';
class Production extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    const { queryInfo } = this.props;
    this.props.setProQueryInfo(queryInfo);
    // this.props.getRole()
  }
  changePageSize = (current, size) => {
    this.props.setProQueryInfo({ limit: size });
  };
  render() {
    const { queryInfo, productionList } = this.props;
    return (
      <div className={style.production__page}>
        <div className={style.production__inner__page}>
          <SearchTab />
        </div>
        <div className={style.production__inner__page}>
          <ProTable />
          <div className={style.table__pagination}>
            <Pagination
              current={queryInfo.page + 1}
              showTotal={(total) => `${intl.get('TOTAL_COUNT', { total: productionList.total })}`}
              size="small"
              onChange={(page) => {
                this.props.setProQueryInfo({ page: page - 1 });
              }}
              pageSize={queryInfo.limit}
              total={productionList.total}
              showQuickJumper={true}
              showSizeChanger={true}
              onShowSizeChange={this.changePageSize}
              style={{ padding: '40px 40px 0 0', float: 'right' }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    queryInfo: state.production.queryInfo,
    productionList: state.production.productionList
  };
};

export default connect(mapStateToProps, { setProQueryInfo, getRole })(Production);
