
// import { getCurrentUser } from '../selectors/userSelectors';
// import store from '../store';
// const user = getCurrentUser(store.getState())
// [
//   {"_id": 0, "Other": 0, "MO": 0, "Neg": 0, "Sus": 0, "Pos": 0, "All": 0}		#全部
//   {"_id": 1, "Other": 0, "MO": 0, "Neg": 0, "Sus": 0, "Pos": 0, "All": 0}		# --
//   {"_id": 2, "Other": 0, "MO": 0, "Neg": 0, "Sus": 0, "Pos": 0, "All": 0}		#可分配
//   {"_id": 3, "Other": 0, "MO": 0, "Neg": 0, "Sus": 0, "Pos": 0, "All": 0}		#初诊
//   {"_id": 4, "Other": 0, "MO": 0, "Neg": 0, "Sus": 0, "Pos": 0, "All": 0}		#复诊
//   {"_id": 5, "Other": 0, "MO": 0, "Neg": 0, "Sus": 0, "Pos": 0, "All": 0}		#已诊断
//   {"_id": 6, "Other": 0, "MO": 0, "Neg": 0, "Sus": 0, "Pos": 0, "All": 0}		#超时
//   {"_id": 7, "Other": 0, "MO": 0, "Neg": 0, "Sus": 0, "Pos": 0, "All": 0}		# --
//   {"_id": 8, "Other": 0, "MO": 0, "Neg": 0, "Sus": 0, "Pos": 0, "All": 0}		# --
//   {"_id": 9, "Other": 0, "MO": 0, "Neg": 0, "Sus": 0, "Pos": 0, "All": 0}		#待打印
//   {"_id": 10, "Other": 0, "MO": 0, "Neg": 0, "Sus": 0, "Pos": 0, "All": 0}	#待发放
//   {"_id": 11, "Other": 0, "MO": 0, "Neg": 0, "Sus": 0, "Pos": 0, "All": 0}	#已发放
//   ]
  
// #"Other": 其他, "MO": 微生物, "Neg": 阴性, "Sus": 疑似, "Pos": 阳性, "All": 全部
const defaultTopTabs = [
  { text: 'TO_BE_ALLOCATED','typeID': 2, icon:"icon-daifenpei", iconActive:"icon-daifenpeiactive", origin: true, query: {distribute_stage:"2,6",},mode:["all",'local',],node:0},
  { text: 'INITIAL_DIAGNOSIS', 'typeID': 3,icon:"icon-chuzhen", iconActive:"icon-chuzhenactive", origin: true, query: { distribute_stage:'3',stage: "3,4,5"},mode:["all",'local','expert'],node:1 },
  { text: 'AGAIN_DIAGNOSIS','typeID': 4, icon:"icon-fuzhen",iconActive:"icon-fuzhenactive", origin: true, query: { distribute_stage:'3', stage: "6,7" } ,mode:["all",'local','expert'],node:1},
  { text: 'RE_DIAGNOSIS', 'typeID': 5,icon:"icon-yizhenduan", iconActive:"icon-yizhenduanactive", origin: true, query: { distribute_stage:'3',stage: "8"},mode:["all",'local','expert'],node:1 },
  { text: 'RE_TIMEOUT', 'typeID': 6,icon:"icon-chaoshi", iconActive:"icon-chaoshiactive", origin: true, query: {distribute_stage:"4,5"},mode:["all",'local','expert'],node:1 },
  { text: 'WAIT_PRINT', 'typeID': 9,icon:"icon-daidayin", iconActive:"icon-daidayinactive", origin: true, query: {stage: "9"} ,mode:['all'],node:0},
  { text: 'WAIT_SEND', 'typeID': 10,icon:"icon-daifafang",iconActive:"icon-daifafangactive", origin: true, query: {stage: "10"},mode:['all'] ,node:0},
  { text: 'RE_SEND', 'typeID': 11,icon:"icon-yifafang",iconActive:"icon-yifafangactive", origin: true, query: {stage: "11"} ,mode:['all'],node:0},
  { text: 'ALL', 'typeID': 0, icon:"icon-quanbu",iconActive:"icon-quanbuactive", origin: true, query: {},mode:["all",'local','expert'] ,node:1},
];

export  const checkInTabs=[
  { text: 'CHECK_TAB1', origin: true, query: { is_rejected:false} },
  { text: 'CHECK_TAB2', origin: true, query: { is_rejected:true } },
]


export const disReportInfo=[
  {
    name:'senior_doctor',
    label:'REPORTER',
  }, {
    name:'expert',
    label:'OUTTER_DOCTOR',
  },
]

export const disJuniorInfo=[
    {
      name:'junior_doctor',
      label:'JUNIOR_DOCTOR',
    },
    ...disReportInfo
]

export const disDocList=[
  {
    title:"JUNIOR_PART",
    role:'junior_distribute_roles',
    disValue:"ai",
    name:'junior_reporter',
    content:disJuniorInfo
  },
  {
    title:"RE_PART",
    role:'senior_distribute_roles',
    disValue:"",
    name:'senior_reporter',
    content:disReportInfo
  }
]

export default defaultTopTabs;
