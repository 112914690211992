
const initial = { 
    value: '',
    imageId:"",
  };
export function searchBox(state = initial, action) {
    switch (action.type) {
        case 'SET_SEARCH_BOX_VALUE':
            return {
                ...state,
                ...action
            };
        case 'SET_SEARCH_BOX_IMAGEID':
            return {
                 ...state,
                ...action
            };
        default:
            return state;
    }
}  