import { SET_CURRENT_IMAGE_INFO } from '../actions';

const initial = {
  imageVisualInfo: null,
  showAnnotationClass: 'ALL',
  status: 'loading',
  currentImageInfo: {}
};

const imageSelection = (state = initial, action) => {
  if (state.currentImageInfo === undefined) state.currentImageInfo = initial.currentImageInfo;
  switch (action.type) {
    case SET_CURRENT_IMAGE_INFO:
      return {
        ...state,
        currentImageInfo: action.currentImageInfo,
        currentImageKey: action.currentImageInfo.key
      };
    case 'UPDATE_CURRENT_IMAGE_TYPE':
      return {
        ...state,
        currentImageInfo: { ...state.currentImageInfo, clazz: action.clazz }
      };
    case 'UPDATE_CURRENT_IMAGE_INFO':
      return {
        ...state,
        currentImageInfo: { ...state.currentImageInfo, ...action.update }
      };
    case 'UPDATE_CURRENT_IMAGE_INFO_PART':
      return {
        ...state,
        currentImageInfo: { ...state.currentImageInfo, ...action.data }
      };
    case 'SET_SHOW_ANNOTATION_CLASS':
      return {
        ...state,
        showAnnotationClass: action.showClass
      };
    case 'LOAD_IMAGE_REQUEST':
      return {
        ...state,
        currentImageKey: action.imageId,
        status: 'loading'
      };
    case 'LOAD_IMAGE_SUCCESS':
      return {
        ...state,
        imageVisualInfo: action.imageVisualInfo,
        status: 'success'
      };
    case 'LOAD_IMAGE_FAILURE':
      return {
        ...state,
        status: 'failure'
      };
    default:
      return state;
  }
};

export default imageSelection;
