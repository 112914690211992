import { Button, Tooltip } from 'antd';
import React from 'react';

export default function CardTitle(props) {
  const content = props.detail.props.dangerouslySetInnerHTML.__html || props.detail;
  const splitter = props.detail.props.dangerouslySetInnerHTML ? '<br>' : '/n';
  const lines = content.split(splitter);
  const length = Math.floor(lines.reduce((acc, curr) => acc + curr.length, 0) / lines.length);
  const minWidth = Math.floor(length / 3) * 14;
  return (
    <div>
      <span>{props.title}</span>

      {props.detail ? (
        <Tooltip title={props.detail} placement="rightTop" overlayStyle={{ minWidth }}>
          <Button icon="question-circle" shape="circle" style={{ border: 0 }} />
        </Tooltip>
      ) : (
        <span />
      )}
    </div>
  );
}
