import React from 'react';
import { connect, useSelector } from 'react-redux';
import { toggleUploadGT } from '../../actions/tabActions';
import colorTable from '../../static/colorTable';
import intl from 'react-intl-universal';
import PopoverIconButton from '../PopoverIconButton';
import { getCurrentUser } from '../../selectors/userSelectors';
import Can from '../Can'

function UploadGTBtn(props){
  const onClick=()=>{
    props.toggleUploadGT(true)
  }

  const currentUser = useSelector(getCurrentUser)

  return <Can 
    permissions={currentUser.permissions}
    perform='image:modify:batch'
    yes={()=> (
      <PopoverIconButton
        id='upload-gt-btn'
        popContent={intl.get('UPLOAD_GT')}
        icon='icon-upload1'
        color={colorTable.blue}
        onClick={onClick}
        place="bottom"
      />)}
    no = {()=>('')}
  />
}

export default connect((state) => ({}), { toggleUploadGT })(UploadGTBtn);
