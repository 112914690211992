const colorTable = {
  green1: '#D0F2DC',
  green2: '#9DE7C6',
  green3: '#2AC966',
  green4: '#349255',
  blue1: '#3DD4FA',
  blue2: '#4FB9F0',
  blue3: '#00CCFF',
  blue4: '#00A8FF',
  blue5: '#409eff',
  pink1: '#FECECA',
  pink2: '#FEA9BD',
  pink3: '#E5899E',
  pink4: '#FFB0A9',
  pink5: '#FC8BA6',
  pink6: '#FD3462',
  purple1: '#8378EA',
  purple2: '#9B91F5',
  purple3: '#7460BF',
  purple4: '#998EFF',
  purple5: '#7768FF',
  purple5_5: '#5755CD',
  purple6: '#57449D',
  yellow1: '#FFF3B7',
  yellow2: '#FFE1A1',
  yellow3: '#F9E089',
  yellow4: '#FFE47C',
  yellow5: '#FFD27E',
  yellow6: '#F5CC01',
  yellow7: '#FFAE00',
  yellow8: '#D9AC01',
  yellow9: '#C68700',
  yellow10: '#FFD27E',
  red: '#E94B4B',
  red1:'#f56c6c',
  blue: '#39A5FE',
  white: '#FFFFFF',
  grey: '#d9d9d9'
};

export default colorTable;
