import React, { Component } from 'react';
import StageStatistic from './StageStatistic';
import YearStatistics from './YearStatistics';
import styles from './index.less';
import moment from 'moment';
import intl from 'react-intl-universal';
import { getStatisWorkloadSummary, getStatisWorkloadUser } from '../../../actions/statistics';
import { connect } from 'react-redux';
import { DatePicker, Card, Row, Col } from 'antd';
const { RangePicker } = DatePicker;
class WorkStatistics extends Component {
  componentDidMount() {
    this.props.getStatisWorkloadSummary();
    this.props.getStatisWorkloadUser('');
  }

  componentWillUnmount = () => {
    this.setState = (state, callback) => {
      return;
    };
  };
  handleChange = (dates, dateStrings) => {
    this.props.getStatisWorkloadUser(dateStrings);
  };

  render() {
    return (
      <div className={styles.statistic}>
        <Row className={styles.chart}>
          <Col span={9}>
            <Card title={intl.get('STATISTICS_DOCTOR_TITLE')} >
              <div className={styles.item__div}>
                <RangePicker
                  showTime={{
                    format: 'HH:mm',
                    defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')]
                  }}
                  onChange={this.handleChange}
                  className={styles.dataInput}
                />
                <StageStatistic />
              </div>
            </Card>
          </Col>
          <Col span={14} offset={1}>
            {' '}
            <YearStatistics />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { getStatisWorkloadSummary, getStatisWorkloadUser })(
  WorkStatistics
);
