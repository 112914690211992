import { tctGroupMapping } from './static/tctMapping';

const config = {
  baseUrl: '',
  isCloud: false,
  debug: false,
  version: "14.1.15.20231215",
  frontVersion:"14.1.15.20231215",
  AlgoVersion:'2.1.0-alpha',
  groundTruthList: {
    cervical: {
      eca: ['ADC','AIS','AGC-N','AGC','SCC','HSIL','ASC-H','LSIL','ASC-US','NILM'],
      organism: ['FUNGI','TV','CC','ACTINO','HSV','NORMAL']
    },
    urine: { urine_abn: ['NHGUC', 'AUC', 'SHGUC', 'HGUC', 'LGUN', 'Other'] }
  },
  visibleConfidence: false,
  visibleNegativeLesion: false,
  visibleLabel: false,
  suspectedAnomalousDiameter: 4000,
 
  currentClassification: 5, 
  classifications:{
    class5:[      
      { name: "GlandularEpithelium", groundTruth: ["ADC", "AIS", "AGC-N", "AGC"]},
      { name: "HighGradeSquamous", groundTruth: ["SCC", "HSIL", "ASC-H"]},
      { name: "LowGradeSquamous", groundTruth: ["LSIL", "ASC-US"]},
      { name: "Microorganisms", groundTruth: ["TV", "CC", 'ACTINO', 'HSV', 'FUNGI',]},
      { name: "Endometrial", groundTruth:["EMC"]}, 
      { name: "Draft", groundTruth:["Draft"]},    
    ],
    class7:[
      { name: "AGC", groundTruth: ["ADC", "AIS", "AGC-N", "AGC"]},
      // { name: "SCC", groundTruth: ["SCC"]},
      { name: "HSIL", groundTruth: ["HSIL"]},
      { name: "ASC_H", groundTruth: ["ASC-H"]},
      { name: "LSIL", groundTruth: ["LSIL"]},
      { name: "ASC_US", groundTruth: ["ASC-US"]},
      { name: "Others", groundTruth: ["TV", "CC", 'ACTINO', 'HSV', 'FUNGI', "EMC"]},
      { name: "Draft", groundTruth:["Draft"]}, 
    ],
  },

  cervicalGroundTruthList: [
    'ADC',
    'AIS',
    'AGC-N',
    'AGC',
    'SCC',
    'HSIL',
    'ASC-H',
    'LSIL',
    'ASC-US',
    'NILM',
    'FUNGI',
    'TV',
    'CC',
    'ACTINO',
    'HSV',
    'NORMAL',
  ],
  cervicalResultTagColorTable: {
    _: 'grey',
    NILM: 'green3',
    'ASC-US': 'blue3',
    LSIL: 'blue4',
    'ASC-H': 'pink4',
    HSIL: 'pink5',
    SCC: 'pink6',
    AGC: 'purple4',
    'AGC-N': 'purple5',
    AIS: 'purple5_5',
    ADC: 'purple6',
    NORMAL: 'green3',
    FUNGI: 'yellow6',
    TV: 'yellow7',
    CC: 'yellow8',
    ACTINO: 'yellow9',
    HSV: 'yellow10',
    NHGUC: 'green3',
    AUC: 'blue2',
    SHGUC: 'pink2',
    HGUC: 'purple4',
    LGUN: 'yellow5',
    Other: 'grey',
    ABN: 'purple4',
    NOR: 'green3',
    N: 'green3',
    P: 'purple4'
  },
  tabs: require('./static/defaultTabs').default,
  tctGroupMapping: tctGroupMapping(),
  reportStatus: require('./static/reportStatus').default,
  reportStatusInfo:require('./static/reportStatus').statusInfo,
  pushStatus: require('./static/pushStatus').default,
  pushStatusInfo: require('./static/pushStatus').statusInfo,
  genderStatus:require('./static/genderStatus').default,
  imageStatus: require('./static/imageStatus').default,
  imageStatusInfo: require('./static/imageStatus').statusInfo,
  allocationStatus:require('./static/allocationStatus').default,
  allocationStatusInfo:require('./static/allocationStatus').statusInfo,
  autoCtrlStatus:require('./static/autoCtrlStatus').default,
  detectionTypes: require('./static/detectionTypes').default,
  textAreaMaxLength: 400,
  inputMaxLength: 20,
  restart_pass_phrase: '3w&;RrWHRdX4F_^M7+c!3^hXT4=tmRt.Hf,#3#.kF.P~s3<*!]V>3;}pVMT3!,mM',
  defaultCancerType: 'cervical',
  defaultReportStatus: "",
  defaultPushStatus: "",
  defaultGenderStatus:"",
  defaultAllocationStatus:"",
  imageListColumns: require('./static/defaultImageListColumns').default,
  warningStorageInGB: 7 * 1 * 100, // assuming 7 days, 1GB/wsi, 100 wsi/day
  errorStorageInGB: 10, // there should be at least 10 GB stroage
  hotKeys: [
    { toolName: 'PreviousImage', commandName: 'updatePreviousImage', groupName: "ImageFileList", label: 'arrowup', keys: ["38", "up"] },
    { toolName: 'NextImage', commandName: 'updateNextImage', groupName: "ImageFileList", label: 'arrowdown', keys: ["40", "down"] },
    { toolName: 'UpAnnotation', commandName: 'updateUpAnnotation', groupName: "DetailsOfAnnotations", label: 'arrowup', keys: ["38", "up"] },
    { toolName: 'DownAnnotation', commandName: 'updateDownAnnotation', groupName: "DetailsOfAnnotations", label: 'arrowdown', keys: ["40", "down"] },
    { toolName: 'LeftAnnotation', commandName: 'updateLeftAnnotation', groupName: "DetailsOfAnnotations", label: 'arrowleft', keys: ["37", "left"] },
    { toolName: 'RightAnnotation', commandName: 'updateRightAnnotation', groupName: "DetailsOfAnnotations", label: 'arrowright', keys: ["39", "right"] },
  ]
};
Object.assign(config, window.config);

export default config;
