/**
 * generate data url of the generated csv file
 * @param {*} data
 * @param {*} options
 * @returns {String} dataUrl of generated csv file
 */
function genUrl(data, options, type) {
  let dataBlob;
  type === 'application/zip'
    ? (dataBlob = new Blob([data], { type: type }))
    : (dataBlob = new Blob([`\ufeff${data}`], { type: type }));
  return window.URL.createObjectURL(dataBlob);
}

/**
 * generate csv file from data, and automatically download the file for browser
 * @param {*} data
 * @param {*} options
 * @param {*} fileName
 */
function downloadCsv(data, options, fileName, type) {
  const url = genUrl(data, options, type);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
}

export default downloadCsv;
