import React from 'react'
import { connect } from 'react-redux'
import styles from './SiderControl.module.css'
import {Icon} from "antd"
import {setSiderLeftVisibility} from '../../actions/toolActions'
export const SiderLeftControl = (props) => {

  return (
    <div className={styles.sider__control__left} onClick={props.setSiderLeftVisibility}>
      {
        props.siderLeftVisibility ?
          <Icon type="double-left" />
          :<Icon type="double-right" />
      }
    </div>
  )
}

const mapStateToProps = (state) => ({
  siderLeftVisibility:state.toolStatus.siderLeftVisibility
})

const mapDispatchToProps = {
  setSiderLeftVisibility
}

export default connect(mapStateToProps, mapDispatchToProps)(SiderLeftControl)
