import React, { PureComponent } from 'react';
import { Checkbox, Row, Col } from 'antd'
import PropTypes from 'prop-types';

class CheckboxRadio extends PureComponent {

    static propTypes = {
      value: PropTypes.any,
      onChange: PropTypes.func,
      className: PropTypes.string,
      disabled: PropTypes.bool,
      name: PropTypes.string,
      options: PropTypes.any,
    };


    static defaultProps = {
      orientation: "vertical",
      disabled: false
    }

    constructor(props) {
      super(props);
      this.state = {
        value: this.props.value,
      }
    }

    componentDidUpdate(prevProps) {
      if (this.props.value !== prevProps.value) {
        this.setState({
          value: this.props.value
        });
      }
    }

    onChange = (value) => {
      let lastValue = this.state.value === value ? !value : value;

      if (this.props.onChange) {
        this.props.onChange(lastValue);
        this.setState({
          value: lastValue,
        });
      }
    }

    render() {
      const {options, orientation, disabled } = this.props;
      const { value } = this.state;
      return (
        <div className='antd-radio-abandon'>
          {
            orientation === "horizontal" ?
              <Row>
                {
                  options.map((option) => {
                    return (
                      <Row key={option.value}>
                        <Checkbox className={value === option.value?this.props.classProp:""} disabled={disabled} checked={value === option.value} onChange={() => this.onChange(option.value)}>{option.label}</Checkbox>
                      </Row>
                    )
                  })
                }
              </Row> :
              <Row>
                {
                  options.map((option) => {
                    return (
                      <Col span={8} key={option.value}>
                        <Checkbox className={value === option.value?this.props.classProp:""} disabled={disabled} checked={value === option.value} onChange={() => this.onChange(option.value)}>{option.label}</Checkbox>
                      </Col>
                    )
                  })
                }
              </Row>
          }
        </div>
      );
    }


}

export default CheckboxRadio