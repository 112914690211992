import React from 'react';
import AlgoPerformance from '../Visualization/AlgoPerformance';
import CommonPanel from './CommonPanel';
import intl from 'react-intl-universal';

function Algo(props) {
  const { data } = props;
  return (
    <CommonPanel name={intl.get('ALGO_PERFORMANCE')}>
      <AlgoPerformance data={data} parentName="dataPlatform" height="90%" />
    </CommonPanel>
  );
}

export default Algo;
