import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col} from 'antd';
import style from './index.less';
import { isPartEnd, setActionDisabled } from '../../utils/urlUtils';
import { getMachine } from '../../actions/userActions';
import SearchTabRight from './SearchTabRight';
import SearchTabLeft from './SearchTabLeft';
class SearchTab extends Component {
  constructor(props) {
    super();

  }
  componentDidMount() {
    isPartEnd() === 'centerEnd' && this.props.getMachine();
  }
  render() {
    const partStyle = setActionDisabled();
    return (
      <Row gutter={30} className={style.report__search} style={{ marginLeft: 0, marginRight: 0 }}>
        <SearchTabLeft />
        <Col span={7} offset={1} className={style.report__btn} style={partStyle}>
          <SearchTabRight />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  };
};

export default connect(mapStateToProps, {
  getMachine
})(SearchTab);
