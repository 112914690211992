import { combineReducers } from 'redux';
import analysis from './analysisPage';
import annotationLoad from './annotationLoad';
import annotationSelection from './annotationSelection';
import annotationUsers from './annotationUsers';
import authReducers from './auth';
import config from './config';
import currentUser from './currentUser';
import imageSelection from './imageSelection';
import loadImages from './loadImages';
import openSeadragonOperation from './openSeadragonOperation';
import progress from './progress';
import { settings } from './settings';
import { devops } from './devops';
import tabAction from './tabAction';
import toolStatus from './toolStatus';
import wsiListAction from './wsiLists';
import { reportConf } from './reportConf';
import { report } from './report';
import { statistics } from './statistics';
import comments  from './comments';
import {searchBox} from './searchBox';
import service from './service';
import {timeout} from './timeout';
import checkIn from './checkIn';
import production from "./production"
export default combineReducers({
  loadImages,
  openSeadragonOperation,
  imageSelection,
  annotationSelection,
  annotationUsers,
  toolStatus,
  annotationLoad,
  ...progress,
  ...tabAction,
  ...wsiListAction,
  currentUser,
  settings,
  devops,
  ...authReducers,
  analysis,
  config,
  reportConf,
  report,
  statistics,
  comments,
  searchBox,
  service,
  timeout,
  checkIn,
  production
});
