const autoCtrlStatus = [
  {  value:"0",query:{image_level:"",mohu:"",yanxing:"",yiwu:"",shangpixibao:""}, color:"green",translate: 'ALL' },
  {  value:"1",query:{image_level:"H",mohu:"",yanxing:"",yiwu:"",shangpixibao:""}, color:"green",translate: 'AUTO_IMAGE_SATISFACTORY' },
  {  value:"2",query:{image_level:"L",mohu:"L",yanxing:"",yiwu:"",shangpixibao:true},color:"orange", translate: 'AUTO_LESS' },
  {  value:"3",query:{image_level:"L",mohu:"M",yanxing:"",yiwu:"",shangpixibao:true}, color:"orange", translate: 'AUTO_LESS_MOHU_M' },
  {  value:"4",query:{image_level:"L",mohu:"H",yanxing:"",yiwu:"",shangpixibao:true}, color:"orange",translate: 'AUTO_LESS_MOHU_H' },
  {  value:"5",query:{image_level:"L",mohu:"",yanxing:"H",yiwu:"",shangpixibao:true}, color:"orange", translate: 'AUTO_LESS_YANXING_H' },
  {  value:"6",query:{image_level:"L",mohu:"",yanxing:"",yiwu:"H",shangpixibao:true}, color:"orange", translate: 'AUTO_LESS_YIWU_H' },
  {  value:"7",query:{image_level:"L",mohu:"",yanxing:"H",yiwu:"H",shangpixibao:true},color:"orange", translate: 'AUTO_LESS_YANXING_YIWU_H' },
  {  value:"8",query:{image_level:"L",mohu:"",yanxing:"",yiwu:"",shangpixibao:false},color:"green", translate: 'AUTO_NORMAL' },
];

export const statusList=[
  {query:{reason:"reason1"},color:"#F56C6C", translate: 'PLEASE_RE_SCAN'},
  {query:{reason:"reason2"},color:"#E6A23C", translate: 'PLEASE_RE_PRO'},
  {query:{valut:["3","4"],res:"NILM"},color:"#409EFF", translate: 'SUGGEST_RE_SCAN'},
  {query:{valut:["5","6","7"],res:"NILM"},color:"#409EFF", translate: 'SUGGEST_RE_PRO'},
]

export default autoCtrlStatus;
