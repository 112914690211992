import React from 'react'
import { Spin } from "antd"
import style from './index.less';
import intl from 'react-intl-universal'
function LoadingSpin() {
    return (
        <div className={style.loadingSpin}><Spin spinning>{intl.get("LOADING_TEXT")}</Spin></div>
    )
}

export default LoadingSpin
