import { Button, Row, Col, Modal, Select, message } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import DoctorList from '../DoctorList';
import { batchDisReport, updateSelectedImages } from '../../actions/imageActions';
import styles from '../../less/modal.less';
import style from './index.less';
import { disDocList } from '../../static/defaultTopTabs';
const { Option } = Select;

const children = [];

for (let i = 10; i < 36; i++) {
  children.push(<Option key={'id' + i}>{i.toString(36) + i}</Option>);
}

class BatchAllocation extends Component {
  static propTypes = {
    selecteds: PropTypes.object,
    images: PropTypes.object
  };

  constructor() {
    super();
    this.state = {
      batchVisible: false,
      selectedDoc: [],
      senior_reporter: [],
      junior_reporter: []
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.batchVisible && this.props.selecteds.total === 0) {
      this.handleCancel();
    }
  }
  showBatchModal = () => {
    this.setState({
      batchVisible: true
    });
  };

  getDoctor(parentName, index, data) {
    let list = this.state[parentName];
    list[index] = data;
    this.setState({
      [parentName]: list
    });
  }
  handleOk = (e) => {
    let params = {
      junior_users: this.state.junior_reporter.flat(),
      senior_users: this.state.senior_reporter.flat()
    };
    const { general, selecteds } = this.props;
    let jlen = params.junior_users.length;
    let slen = params.senior_users.length;
    if (general.junior_distribute_roles.includes('ai') && slen === 0) {
      message.error(intl.get('SELECT_DOCTOR'));
      return false;
    } else if (!general.junior_distribute_roles.includes('ai') && (jlen === 0 || slen === 0)) {
      message.error(intl.get('SELECT_DOCTOR'));
      return false;
    } else if (jlen > selecteds.total || slen > selecteds.totals) {
      message.error(intl.get('ALLOCATION_WARN'));
      return false;
    }
    this.props.setLoading()
    this.props.batchDisReport(params);
    this.props.updateSelectedImages({ data: [], total: 0 });
    this.setState({
      batchVisible: false
    });
  };

  handleCancel = (e) => {
    this.setState({
      batchVisible: false
    });
  };

  render() {
    const { selecteds, roleUsers, general,forceLoading } = this.props;
    const { batchVisible } = this.state;
    return (
      <>
        <Button
          type="primary"
          key="loadimage"
          onClick={this.showBatchModal}
          disabled={selecteds.total > 0 && !forceLoading ? false : true}
        >
          {intl.get('BATCH_ALLOCATION')}
        </Button>

        <Modal
          width={800}
          title={intl.get('BATCH_ALLOCATION')}
          visible={batchVisible}
          onOk={this.handleOk.bind(this)}
          onCancel={this.handleCancel.bind(this)}
          className={styles.checkModal}
        >
          {disDocList.map((part, idx) => {
            return (
              <div className={style.dis__Doc} key={idx}>
                {general[part.role].length !== 0 && !general[part.role].includes(part.disValue) && (
                  <div className={style.dis__Doc}>
                    <Row>
                      <Col span={12} className={style.dis__title}>
                        {intl.get(part.title)}:
                      </Col>
                    </Row>
                    <Row>
                      {part.content.map((item, index) => {
                        return (
                          general[part.role].includes(item.name) && (
                            <Col span={8} key={index}>
                              <span className={style.dis__label}>
                                {intl.get(item.label)}:&nbsp;&nbsp;
                              </span>
                              <DoctorList
                                selected={this.getDoctor.bind(this, part.name, index)}
                                parentName="batch"
                                keyValue={[]}
                                width="180px"
                                doctorList={
                                  roleUsers[item.name] !== undefined ? roleUsers[item.name].filter(r=>r.distributable===true) : []
                                }
                              />
                            </Col>
                          )
                        );
                      })}
                    </Row>
                  </div>
                )}
              </div>
            );
          })}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    images: state.loadImages.images,
    selecteds: state.loadImages.selected,
    currentTab: state.tabState.currentTab,
    roleUsers: state.report.roleUsers,
    general: state.config.general
  };
};

export default connect(mapStateToProps, { batchDisReport, updateSelectedImages })(BatchAllocation);
