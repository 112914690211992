import ReactEcharts from 'echarts-for-react';
import React, { Component } from 'react';
import styles from './index.less';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';

class StageStatistic extends Component {
  getOption = () => {
    const colorList=['#FFC52F','#FF7A5B','#5B9AFF'];
    return {
      title: {
        text: this.props.currentUser.realname,
        x: 'left',
        left:'20px',
        top:'8px',
        textStyle: {
          fontSize: 12,
          color: 'rgba(0,0,0,1)',
          lineHeight:16
        }
      },
      color: '#5B9BD5',
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {},
      xAxis: {
        type: 'value',
        axisLine: {
          show: false
        }
      },
      yAxis: {
        type: 'category',
        data: ['REPORT_STATISTICS', 'JUNIOR_STATISTICS', 'ALL_STATISTICS'].map((item) =>
          intl.get(item)
        )
      },
      series: [
        {
          type: 'bar',
          data: this.props.userWorkList,
          barWidth: 30,
          itemStyle: {
            normal: {
                color: function(params) { 
                    return colorList[params.dataIndex] 
                }
            },
            emphasis: {
                opacity: 1
            }
        },
        }
      ]
    };
  };

  render() {
    return (
      <div className={styles.innerStatistic}>
        <ReactEcharts
          option={this.getOption()}
          notMerge={true}
          lazyUpdate={true}
          style={{ width: '100%', height: '370px' }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userWorkList: state.statistics.userWorkList,
  currentUser: state.currentUser
});

export default connect(mapStateToProps, {})(StageStatistic);
