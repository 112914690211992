import React, { Component } from 'react';
import style from '../../less/modal.less';
import intl from 'react-intl-universal';
import { Row, Col, Modal } from 'antd';
import { connect } from 'react-redux';
import { rdistributeDoctor } from '../../actions/reportAction';
import DoctorList from '../DoctorList';
import {getDoctorNotMine} from "../../utils/reportUtils"
class ChangeDoctor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      selectedDoc: ''
    };
  }
  showModal = () => {
    this.setState({
      isModalVisible: true
    });
  };
  getDoctor(data) {
    this.setState({
      selectedDoc: data
    });
  }
  handleOk = () => {
    const { image } = this.props;
    this.props.rdistributeDoctor(image.id, { reporter: this.state.selectedDoc }, 'list');
    this.handleCancel();
  };
  handleCancel = () => {
    this.setState({
      isModalVisible: false
    });
  };

  render() {
    const { opt,roleUsers ,currentUser} = this.props;
    
    return (
      <>
        {opt ? (
          <span style={{ cursor: 'pointer' }} onClick={this.showModal}>
            {intl.get('CHANGE_DOCTOR')}
          </span>
        ) : (
          <span>{intl.get('CHANGE_DOCTOR')}</span>
        )}

        <Modal
          title={intl.get('CHANGE_DOCTOR')}
          visible={this.state.isModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          className={style.checkModal}
        >
          <Row>
            <Col span={4} offset={1} className={style.checkModalIcon}>
              {intl.get('CHANGE_DOCTORS')}
            </Col>
            <Col span={9}>
              <DoctorList
                selected={this.getDoctor.bind(this)}
                parentName="single"
                keyValue=""
                width="100%"
                doctorList={roleUsers.senior_doctor!==undefined?getDoctorNotMine(roleUsers ,currentUser):[]}
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roleUsers:state.report.roleUsers,
    currentUser:state.currentUser

  };
};

export default connect(mapStateToProps, { rdistributeDoctor })(ChangeDoctor);
