import { Popover, Tag} from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import config from '../../config';
import FallbackImage from '../FallbackImage';
import RotatableImage from '../RotatableImage';
import setBaseUrl from '../../utils/urlUtils';
import intl from 'react-intl-universal';

class ImageTag extends Component {
  static propTypes = {
    image: PropTypes.object
  };
  render() {
    const { baseUrl } = config;
    const beforeUrl = setBaseUrl(baseUrl,this.props.image)
    const imageId = this.props.image.id;
    // const tagApi = `${beforeUrl}/api/image/associated/${imageId}?type=label`;
    // const macroLabelApi = `${beforeUrl}/api/image/associated/${imageId}?type=macro-label`;
    // const macroApi = `${beforeUrl}/api/image/associated/${imageId}?type=macro`;
    const tagApi = `${beforeUrl}/imagetags/label/${imageId}.jpg`;
    const macroLabelApi = `${beforeUrl}/imagetags/macrolabel/${imageId}.jpg`;
    const macroApi = `${beforeUrl}/imagetags/macro/${imageId}.jpg`;

    return (
      <Popover
        placement="right"
        trigger="click"
        content={
          <div>
            <RotatableImage src={tagApi} style={{ border: 'none' }} />
            <FallbackImage src={macroApi} width="200" height="150" className="cellImage"/>            
          </div>
        }
      >
        {this.props.image.image_status ? <Tag color="red" style={{position: "absolute"}}>{intl.get('IMAGE_REMOVED')}</Tag> : undefined}  
        <FallbackImage
          className="macro-label-img"
          src={macroLabelApi}
          alt=""
          width="100"
          height="40"
        />    
      </Popover>
    );
  }
}

export default ImageTag;