import React, { Component } from 'react';
import { Button } from 'antd';
import style from './index.less';
import { getImageTotalCount, getImageList } from '../../selectors';
import { getImagePlace, getLoadImageMode, getQueryInfo } from '../../selectors/imageSelector';
import { connect } from 'react-redux';
import {
  updateNextImage,
  updatePreviousImage,
  fetchNextPageImages,
  setCachePage,
  fetchImagesLoad
} from '../../actions/imageActions';
import { cloneDeep } from 'lodash';
class ImageSwitcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: this.props.query.page
    };
  }
  componentDidMount() {
    this.props.mode === 'query' && this.props.fetchImagesLoad();
    this.props.setCachePage('query', [this.state.page]);
  }

  componentDidUpdate() {
    if (
      this.props.current < 0 &&
      this.props.mode === 'search' &&
      this.props.searched.data[0]?.tagId === this.props.searchBox.value
    ) {
      this.nextImage();
    }
  }

  previousImage = () => {
    if (this.props.current === 0) {
      this.getNextPageImages('pre');
    } else {
      this.props.updatePreviousImage();
    }
  };

  nextImage = () => {
    if (this.props.current === this.props.imageList.length - 1) {
      this.getNextPageImages('next');
    } else {
      this.props.updateNextImage();
    }
  };

  getNextPageImages = (name) => {
    const { mode } = this.props;
    if (mode !== 'select') {
      let cache = cloneDeep(this.props.cachePage[mode]);
      let cacheLen = cache.length;
      let page = name === 'pre' ? cache[0] - 1 : cache[cacheLen - 1] + 1;
      if (!this.props.cachePage[mode].includes(page)) {
        this.props.fetchNextPageImages(page, name).then((res) => {
          this.setState({ page });
          name === 'pre' ? cache.unshift(page) : cache.push(page);
          this.props.setCachePage(mode, cache);
          name === 'pre' ? this.props.updatePreviousImage() : this.props.updateNextImage();
        });
      } else {
        this.setState({ page });
      }
    }
  };

  render() {
    const { mode } = this.props;
    let index = parseInt(this.props.current / this.props.query.limit, 10);
    // let currentNum =index
    let currentNum =
      mode !== 'select'
        ? this.props.cachePage[mode][index] * this.props.query.limit +
          (this.props.current % this.props.query.limit) +
          1
        : this.props.current + 1;
    const place = `${currentNum}/${this.props.total}`;
    return (
      <div className={style.switcher}>
        <Button className={style.previous} icon="caret-left" onClick={this.previousImage} />
        <p className={style.counter}>{place}</p>
        <Button className={style.next} icon="caret-right" onClick={this.nextImage} />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    total: getImageTotalCount(state),
    imageList: getImageList(state),
    current: getImagePlace(state),
    query: getQueryInfo(state),
    mode: getLoadImageMode(state),
    cachePage: state.loadImages.cachePage,
    searchBox: state.searchBox,
    searched: state.loadImages.searched
  };
};

export default connect(mapStateToProps, {
  fetchNextPageImages,
  updateNextImage,
  updatePreviousImage,
  setCachePage,
  fetchImagesLoad
})(ImageSwitcher);
