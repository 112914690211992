import React, { Component } from 'react';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import style from './index.less';
import { setCurrentPage } from '../../actions/configActions';
import { isPartEnd } from '../../utils/urlUtils';
const pageList = [
  {
    value: 'check',
    label: 'CHECK_PAGE',
    mode: ['all']
  },
  {
    value: 'production',
    label: 'PRODUCTION_PAGE',
    mode: ['all']
  },
  {
    value: 'report',
    label: 'REPORT_PAGE',
    mode: ['all', 'local']
  }
];
class pageTab extends Component {
  componentDidMount() {}
  choosePage(data) {
    this.props.setCurrentPage(data);
  }
  render() {
    const { pageName, general } = this.props;
    let tabLocal = pageList.filter((i) => i.mode.includes('local'));
    const partEnd = isPartEnd();
    let initPage = general.product_mode === 0 && partEnd !== 'expert' ? pageList : tabLocal;
    return (
      <div className={style.pageTab}>
        {initPage.map((item, index) => {
          return (
            <div
              className={pageName === item.value ? style.chooesd : ''}
              onClick={this.choosePage.bind(this, item.value)}
              key={index}
            >
              {intl.get(item.label)}
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  pageName: state.config.pageName,
  general: state.config.general
});

export default connect(mapStateToProps, { setCurrentPage })(pageTab);
