import React, { Component } from 'react';
import { connect } from 'react-redux';
import OtherInfo from './OtherInfo';
import BasicInfo from './BasicInfo';
import CheckTable from './CheckTable';
import intl from 'react-intl-universal';
// import SampleInfo from './SampleInfo';
import TailInfo from './TailInfo';
import { Row, Col } from 'antd';
import {
  otherInfo,
  getBasicInfo,
  getInspectionInfo,
  getSampleInfo,
  initalCheckInfo
} from './checkInfo';
import styles from './index.less';
import NumInfo from './NumInfo';
import { fetchDepartmentAll, updateChckInInfoParams, fetchCheckInfoList} from '../../actions/checkInAction';
import { canRender } from '../Can';
import { isPartEnd } from '../../utils/urlUtils';
class CheckIn extends Component {
  constructor() {
    super();
    this.state = {
      disable: false
    };
  }
  componentDidMount() {
    this.props.fetchCheckInfoList(this.props.currentImageInfo?.tagId);
    this.props.fetchDepartmentAll();
    this.showDisPanel();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.checkType !== this.props.checkType) {
      this.showDisPanel();
    }
  }
  showDisPanel() {
    const { checkType, currentUser } = this.props;
    let dis;
    if (isPartEnd() === 'centerEnd') {
      dis = true;
    } else {
      if (checkType === 'add') {
        dis = canRender(currentUser.permissions, 'registration:add') ? false : true;
      } else if (checkType === 'update') {
        dis =
          canRender(currentUser.permissions, 'registration:modify') ||
          canRender(currentUser.permissions, 'registration:delete')
            ? false
            : true;
      }
    }

    this.setState({ disable: dis });
  }
  onRef0 = (ref) => {
    this.child0 = ref;
  };
  onRef1 = (ref) => {
    this.child1 = ref;
  };
  onRef2 = (ref) => {
    this.child2 = ref;
  };
  onRef3 = (ref) => {
    this.child3 = ref;
  };

  render() {
    const { checkInfo, hisInfo, showPart, location} = this.props;
    const { disable } = this.state;
    const basicInfo = getBasicInfo();
    const inspectionInfo = getInspectionInfo();
    const sampleInfo = getSampleInfo();
    const commonSpan = {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
      lg: { span: 24 }
    };
    const colSpanFormLeft = {
      ...commonSpan,
      xl: { span: 11 }
    };
    const colSpanTable = {
      ...commonSpan,
      xl: { span: 13 }
    };
    const colSpanFormAll = {
      ...commonSpan,
      xl: { span: 24 }
    };
    const disForm = showPart === 'form' ? colSpanFormAll : colSpanFormLeft;
    return (
      <div className={styles.check__form}>
        <Row>
          <Col {...disForm} className={styles.checkFormCol + ' ' + styles.formLeft}>
            <div className={styles.check__form__inner} id="checkFormInner">
              <div className={styles.check__form__title}>{intl.get('CHECK_FORM')}</div>
              <NumInfo
                isModify = {this.props.isModify}
                info={checkInfo}                
                onRef={this.onRef0}
                updateData={(data) => {                  
                  this.child1.updateFormInitalValue(data);
                  this.child2.updateFormInitalValue(data);
                  this.child3.updateFormInitalValue(data);
                }}
                location={location}
              />
              <BasicInfo
                info={checkInfo}
                column={basicInfo}
                titleName="BASIC_INFO"
                onRef={this.onRef1}
                hisInfo={hisInfo}
                location={location}
              />
              <BasicInfo
                info={checkInfo}
                column={inspectionInfo}
                titleName="INSPECTION_INFO"
                onRef={this.onRef2}
                hisInfo={hisInfo}
                location={location}
              />
              <BasicInfo
                info={checkInfo}
                column={sampleInfo}
                titleName="CHECK_SAMPLE_INFO"
                onRef={this.onRef3}
                hisInfo={hisInfo}
                location={location}
              />
              <OtherInfo
                column={otherInfo}
                info={checkInfo}
                hisInfo={hisInfo}
                location={location}
              />         
                <TailInfo
                  isModify = {this.props.isModify}
                  subm={() => {
                    this.props.onOpenRegistrationForm(false, true);
                    this.child0.handleSubmit();
                    this.child1.handleSubmit();
                    this.child2.handleSubmit();
                    this.child3.handleSubmit();
                    return (
                      this.child0.handleSubmit() &&
                      this.child1.handleSubmit() &&
                      this.child2.handleSubmit() &&
                      this.child3.handleSubmit()
                    );
                  }}
                  updateInital={() => {
                    let check_info = initalCheckInfo;
                    // check_info.pathology_id 
                    if(!this.props.isModify){
                      this.child0.updateFormInitalValue(check_info);
                      this.child1.updateFormInitalValue(check_info);
                      this.child2.updateFormInitalValue(check_info);
                      this.child3.updateFormInitalValue(check_info);
                    }
                  }}
                  getnumInfoForm={() => {
                    return this.child0.getForm();
                  }}
                />        
            </div>
            {showPart !== 'form' && (
              <div
                className={styles.disable_panel}
                style={{ display: disable ? 'block' : 'none' }}
              />
            )}
          </Col>
          {showPart !== 'form' && (
            <Col {...colSpanTable} className={styles.checkFormCol + ' ' + styles.form__right}>
              <CheckTable
                updateData={(data) => {
                  this.child0.updateFormInitalValue(data);
                  this.child1.updateFormInitalValue(data);
                  this.child2.updateFormInitalValue(data);
                  this.child3.updateFormInitalValue(data);
                  this.props.updateChckInInfoParams(data);
                }}
              />
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => { 
  let checkInfo = state.checkIn.checkInfo;
  checkInfo.pathology_id = state.imageSelection.currentImageInfo.tagId
  if(checkInfo.apply_id === ""){
    checkInfo.apply_id = state.imageSelection.currentImageInfo.tagId
  }
  // console.log("checkInfo", checkInfo)
  // console.log("currentImageInfo", state.imageSelection.currentImageInfo)
  // checkInfo.pathology_id = state.imageSelection.currentImageInfo.tagId
 
  return {
    checkInfo,
    currentUser: state.currentUser,
    hisInfo: state.checkIn.hisInfo,
    checkType: state.checkIn.checkType,    
  };
};

export default connect(mapStateToProps, { fetchDepartmentAll, updateChckInInfoParams,fetchCheckInfoList})(CheckIn);
