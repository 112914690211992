import PropTypes from 'prop-types';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { getCurrentLocale } from '../../static/locales';
import style from './negativeDetail.less';

export class DetailNumber extends Component {
  static propTypes = {
    number: PropTypes.number
  };
  render() {
    let { number } = this.props;
    const fontSize = getCurrentLocale() === 'zh' ? 20 : 16;
    number *= 100;
    const fix = number >= 100 ? 1 : 2;
    const prefix = number < 10 && number !== 0 ? '0' : '';
    return (
      <span className={style.negativeDetailNumber} style={{ fontSize }}>
        {prefix + number.toFixed(fix) + '%'}
      </span>
    );
  }
}

class NegativeDetail extends Component {
  static propTypes = {
    tnr: PropTypes.number,
    nr: PropTypes.number
  };

  render() {
    return (
      <div className={style.negativeAnalysisDetail}>
        <span>{intl.get('NR')}:</span>
        <DetailNumber number={this.props.nr} />
        &nbsp;&nbsp;
        <span>{intl.get('TNR')}:</span>
        <DetailNumber number={this.props.tnr} />
      </div>
    );
  }
}

export default NegativeDetail;
