import { Component } from 'react';
import { connect } from 'react-redux';
import { fabric } from 'openseadragon-fabricjs-overlay';
import uuidv4 from 'uuid/v4';
import { setRectangleMode } from '../../actions/toolActions';
import { setCurrentAnnotationId } from '../../actions/annotationSelectionActions';
import { addAnnotationAsync, setShowAnnotationClass } from '../../actions/annotationActions';
import { getLoginUser } from '../../selectors';
import { message } from 'antd'
import intl from 'react-intl-universal';
class RectAnnoation extends Component {
  constructor(props) {
    super(props);
    this.canvas = null;
    this.drawing = false;
    this.start = null;
    this.rect = null;
  }

  componentDidUpdate(prevProps) {
    if (window.osdoverlay && !this.canvas) {
      this.canvas = window.osdoverlay.fabricCanvas();
    }
    if (this.props.rectangleMode && !prevProps.rectangleMode && this.canvas) {
      this.enterDrawingMode();
    }
    if (!this.props.rectangleMode && prevProps.rectangleMode && this.canvas) {
      this.exitDrawingMode();
      this.endDrawing();
    }
  }

  componentWillUnmount() {
    this.exitDrawingMode();
  }

  enterDrawingMode = () => {
    window.osdviewer && window.osdviewer.setMouseNavEnabled(false);
    window.osdviewer && window.osdviewer.outerTracker.setTracking(false);
    this.canvas && this.canvas.on('mouse:down', this.mouseDown);
    this.canvas && this.canvas.on('mouse:move', this.mouseMove);
    this.canvas && this.canvas.on('mouse:up', this.mouseUp);
  };

  exitDrawingMode = () => {
    window.osdviewer && window.osdviewer.setMouseNavEnabled(true);
    window.osdviewer && window.osdviewer.outerTracker.setTracking(true);
    this.canvas && this.canvas.off('mouse:down');
    this.canvas && this.canvas.off('mouse:move');
    this.canvas && this.canvas.off('mouse:up');
    this.rect && this.canvas.remove(this.rect);
  };

  mouseDown = (e) => {
    this.drawing = true;
    this.start = this.canvas.getPointer(e.e);
    this.addRect(this.start);
    this.canvas.renderAll();
  };

  mouseMove = (e) => {
    if (!this.drawing) {
      return false;
    }
    const p = this.canvas.getPointer(e.e);
    if (p.x < this.start.x) {
      this.rect.set({ left: p.x });
    }
    if (p.y < this.start.y) {
      this.rect.set({ top: p.y });
    }
    this.rect.set({ width: Math.abs(p.x - this.start.x) });
    this.rect.set({ height: Math.abs(p.y - this.start.y) });
    this.canvas.renderAll();
  };

  mouseUp = (e) => {
    this.endDrawing();
  };

  endDrawing = () => {
    if (this.drawing) {
      this.drawing = false;
      this.props.setRectangleMode(false);

      if (this.rect.width === 0 || this.rect.height === 0) {
        this.props.setRectangleMode(true);
        return
      }else if(this.rect.width > 2000 || this.rect.height >2000){
        this.rect.width = 2000
        this.rect.height = 2000
        message.warning(intl.get("DRAG_TO_DRAW_RECT"));
      }
      
      const annotation = {
        id: uuidv4(),
        name: 'annotation',
        px: Math.round(this.rect.left + this.rect.width / 2.0),
        py: Math.round(this.rect.top + this.rect.height / 2.0),
        x_start: Math.round(this.rect.left),
        y_start: Math.round(this.rect.top),
        x_range: Math.round(this.rect.width),
        y_range: Math.round(this.rect.height),
        size: Math.round(this.rect.width),
        type: 'Box',
        isFinished: true,
        prob: 1,
        is_koilocyte: false,
        nc_size: -1,
        nc_ratio: '-1',
        changes: [{ id: uuidv4(), old: null, new: 'Draft', user: this.props.loginUser }]
      };
      this.props.addAnnotationAsync(annotation, this.props.currentImageKey);
      this.props.setShowAnnotationClass('Draft');
      this.props.setCurrentAnnotationId(annotation.id);
      this.props.setRectangleMode(true);
    }   
  };

  addRect = (start) => {
    this.rect = new fabric.Rect({
      left: start.x,
      top: start.y,
      fill: 'rgba(0,0,0,0)',
      stroke: 'rgba(255,0,0,0.8)',
      strokeWidth: 8,
      width: 0,
      height: 0,
      editable: false,
      selectable: false
    });
    this.canvas.add(this.rect);
  };

  render() {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    overlay: state.openSeadragonOperation.overlay,
    rectangleMode: state.toolStatus.rectangleMode,
    currentImageKey: state.imageSelection.currentImageKey,
    loginUser: getLoginUser(state)
  };
};

export default connect(mapStateToProps, {
  setRectangleMode,
  setShowAnnotationClass,
  setCurrentAnnotationId,
  addAnnotationAsync
})(RectAnnoation);
