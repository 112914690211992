import { connect, useSelector } from 'react-redux';
import {getPatientName} from '../../selectors/imageSelector';
import detailBuilder from './detailBuilder';

const PatientNameUnWrapped = ()=>{
  const content = useSelector(getPatientName)
  return detailBuilder('PATIENT_NAME_LABEL',content)
}

const PatientName = connect((state) => ({}))(PatientNameUnWrapped);
export default PatientName;