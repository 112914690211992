import Icon from '../components/Iconfont';
import React, { Component } from 'react';
import styles from './index.less';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import userPic from '../static/images/userPic.png';
import { getUserInfoById } from '../actions/userActions';
class UserInfoPart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {}
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentUser !== this.props.currentUser) {
      this.props.getUserInfoById(this.props.currentUser.id).then((res) => {
        this.setState({ user: res });
      });
    }
  }

  getInfo() {
    const { user } = this.state;
    return [
      {
        label: 'STATUS',
        value: user.distributable ? intl.get('DISTRIBUTABLE') : intl.get('NOT_DISTRIBUTABLE')
      },
      {
        label: 'USERNAME',
        value: user.username
      },
      {
        label: 'ACCOUNT_TEL',
        value: user.phone
      }
    ];
  }
  render() {
    const infoList = this.getInfo();
    const { currentUser } = this.props;
    return (
      <div className={styles.user_info}>
        <div className={styles.user_top}>
          <div>
            <img src={userPic} />
          </div>
          <div className={styles.user_top_name}>{currentUser.realname}</div>
        </div>
        <div className={styles.user_account}>
          <div className={styles.account}>
            <Icon type="icon-account" />
            &nbsp;&nbsp;
            <span>{intl.get('ACCOUNT_TITLE')}</span>
          </div>
          {infoList.map((item, index) => {
            return (
              <div className={styles.user_item} key={index}>
                <div className={styles.labels}>{intl.get(item.label)}</div>
                <div className={styles.values}>{item.value}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser
  };
};

export default connect(mapStateToProps, { getUserInfoById })(UserInfoPart);
