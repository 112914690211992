import React, {  } from 'react';
import CommonPanel from '../commonPanel';
import { connect } from 'react-redux';
import TagIdSource from "../../System/TagIdSource"
import TagId from './TagId';

function TagIdSet(props) {
  return (
    <CommonPanel>
     <TagIdSource />
     <TagId />
    </CommonPanel>
  );
}

const mapStateToProps = (state, ownProps) => ({
});
export default connect(mapStateToProps, { })(TagIdSet);
