/* @flow */
import { Spin, List, Icon, Button, Input, message } from 'antd';
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import style from './index.less';
import InfiniteScroll from 'react-infinite-scroll-component';
import intl from 'react-intl-universal';
import { useFetch } from '../../hooks/useHttp';
import { getLoginUser } from '../../selectors';
import { fetchComments, createComment, removeComment } from '../../actions/comments';
import formatTimestamp from '../../utils/dateUtils';
import { isPartEnd } from '../../utils/urlUtils';
const height = 260;

const MsgBox = (props) => {
  const { currentImageKey, loginUser } = props;
  const comments = props.comments ? props.comments : [];
  const scrollRef = useRef(null);
  let [loading, error] = useFetch(props.fetchComments, currentImageKey);
  const [val, setVal] = useState('');

  useEffect(() => {
    let el = scrollRef.current.el;
    el.scrollTop = el.scrollHeight;
    return () => {};
  }, []);

  const onChange = ({ target: { value } }) => {
    setVal(value);
  };

  const onSubmit = () => {
    if (!val) {
      message.warning(intl.get('TEXT_NOT_EMPTY'));
      return;
    }
    props.createComment(currentImageKey, { text: val }).then((res) => {
      setVal('');
      setTimeout(() => {
        let el = scrollRef.current.el;
        el.scrollTop = el.scrollHeight;
      }, 200);
    });
  };

  const onCancel = () => {
    setVal('');
    props.onCancel();
  };

  const onDel = (commentId) => {
    props.removeComment(currentImageKey, commentId);
  };

  return (
    <div className={style.list}>
      {!error || loading ? (
        <>
          <InfiniteScroll
            dataLength={comments.length}
            loader={<Spin />}
            height={height}
            className="scrollBarHidden"
            style={{ overflowX: 'hidden' }}
            ref={scrollRef}
          >
            <List
              itemLayout="horizontal"
              dataSource={comments}
              renderItem={(item) => {
                return (
                  <List.Item style={{ border: 'none' }} key={item.id}>
                    <div className={style.comment__div}>
                      <div
                        className={
                          item.user === loginUser ? style.top__div__reverse : style.top__div
                        }
                      >
                        <span
                          className={
                            item.user === loginUser ? style.self__name__span : style.name__span
                          }
                        >
                          {item.user}
                        </span>
                        <span>
                          <span className={style.date__span}>
                            {formatTimestamp(item.created_time, 'YYYY-MM-DD HH:mm:ss')}
                          </span>
                          {item.user === loginUser && (
                            <Icon
                              onClick={() => onDel(item.id)}
                              key="del"
                              type="delete"
                              className={style.del__icon}
                            />
                          )}
                        </span>
                      </div>
                      <div
                        className={
                          item.user === loginUser ? style.self__center__div : style.center__div
                        }
                      >
                        <span>{item.text}</span>
                      </div>
                    </div>
                  </List.Item>
                );
              }}
            />
          </InfiniteScroll>
          <div className={style.comment__add__div}>
            <Input.TextArea
              rows={2}
              value={val}
              onChange={onChange}
              placeholder={intl.get('LIMIT_TEXT_LENGTH', { n: 100 })}
              maxLength={100}
              size="small"
              allowClear
              autoSize={false}
              disabled={isPartEnd() === 'centerEnd' ? true : false}
            />
            <div className={style.commend__btn__div}>
              <Button onClick={onSubmit} className={style.commend__btn} size="small" type="primary">
                {intl.get('YES')}
              </Button>
              <Button onClick={onCancel} className={style.commend__btn} size="small">
                {intl.get('CANCEL')}
              </Button>
            </div>
          </div>
        </>
      ) : (
        <Spin loading />
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  comments: state.comments.data,
  loginUser: getLoginUser(state),
  currentImageKey: state.imageSelection.currentImageKey
});

export default connect(mapStateToProps, {
  fetchComments,
  createComment,
  removeComment
})(MsgBox);
