import React, { Component } from 'react';
import './index.css';
import MainConsole from './MainConsole';
import ImageSwitcher from './ImageSwitcher';
import { connect } from 'react-redux';

class CenterConsole extends Component {
  render() {
    return <div>
      <MainConsole />
      <ImageSwitcher />
    </div>
  }
}

const mapStateToProps = (state) => {
  return {
   
  };
};

export default connect(mapStateToProps)(CenterConsole);
