import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import Icon from '../Iconfont';
import { Button, Col, Row, Modal } from 'antd';
import {
  collectImageAsync,
  getCollectionAsync,
  discardImageAsync,
  markImageAsync
} from '../../actions/imageActions';
import { getRemark, isCurrentImageCollected } from '../../selectors/imageSelector';
import PatientID from '../Details/PatientID';
import PatientName from '../Details/PatientName';
import PatientAge from '../Details/PatientAge';
import TextArea from 'antd/lib/input/TextArea';
import styles from './Collection.less';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      remark: this.props.remark
    };
  }
  componentDidUpdate(prevProps,prevState){
    if(prevProps.remark!==this.props.remark){
      this.setState({ remark: this.props.remark});
    }  
  }

    onCollect = () => {
      console.log(this.state.remark)
      this.props.isCollected
        ? this.props.markImageAsync(this.props.currentImageKey, this.state.remark)
        : this.props.collectImageAsync(this.props.currentImageKey, this.state.remark);
      this.setState({ visible: false });
    };

    onDiscard = () => {
      this.props.isCollected && this.props.discardImageAsync(this.props.currentImageKey);
      this.setState({ visible: false });
    };

    closeModal = () => {
      this.setState({ visible: false });
    };

    showModal = () => {
      this.props.isCollected && this.props.getCollectionAsync(this.props.currentImageKey);
      this.setState({ visible: true });
    };

    remarkChange = (e) => {
      this.setState({ remark: e.target.value });
    };

    render() {
      const collected = this.props.isCollected;
      const title = intl.get('COLLECTION_TITLE', { collected });
      return (
        <>
          <span onClick={this.showModal}>
            <Icon
              className={collected ? styles.collected__icon : styles.discard__icon}
              type={collected ? 'icon-star' : 'icon-starline'}
            />
            <span className={styles.text__span}>{intl.get('COLLECTION')}</span>
          </span>

          <Modal
            title={title}
            visible={this.state.visible}
            onOk={this.onCollect}
            onCancel={this.closeModal}
            footer={[
              <Button key="discard" onClick={this.onDiscard}>
                {intl.get('COLLECTION_CANCEL', { collected })}
              </Button>,
              <Button key="collect" type="primary" onClick={this.onCollect}>
                {intl.get('COLLECTION_CONFIRM', { collected })}
              </Button>
            ]}
          >
            <div style={{ marginBottom: 20 }}>
              <Row align="middle">
                <Col span={10} className={styles.text_overflow}>
                  <PatientID />
                </Col>
                <Col span={7}>
                  <PatientName />
                </Col>
                <Col span={7}>
                  <PatientAge />
                </Col>
              </Row>
            </div>
            <div>
              <Row align="middle">
                <Col span={6}>{intl.get('COLLECTION_REMARK_INPUT')}</Col>
                <Col span={18}>
                  <TextArea
                  className={styles.text__area}
                    key={this.props.currentImageKey + this.props.remark}
                    onChange={this.remarkChange}
                    value={this.state.remark}
                    allowClear={true}
                    maxLength={100}
                  />
                </Col>
              </Row>
            </div>
          </Modal>
        </>
      );
    }
}

const mapStateToProps = (state) => ({
  currentImageKey: state.imageSelection.currentImageKey,
  isCollected: isCurrentImageCollected(state),
  remark: getRemark(state)
});

export default connect(mapStateToProps, {
  getCollectionAsync,
  collectImageAsync,
  discardImageAsync,
  markImageAsync
})(Index);
