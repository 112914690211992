import { ConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';
import Cookies from 'js-cookie';
import React from 'react';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
// import NoMatch from './components/Exceptions/404';
// import HelpModal from './components/HelpModal';
// import LoginModal from './components/LoginPage/LoginModal';
// import ImagesBrowser from './ImagesBrowser';
// import Login from './Login';
// import MainPage from './MainPage';
// import Preference from './Preference';
import { initLanguage } from './static/locales';
import store from './store';
import { setAuthToken } from './utils/authUtils';
// import Expert from "./Expert";
// import CenterEnd from "./CenterEnd"
import AppRouter from './components/AppRouter'
initLanguage();
setAuthToken();

window.addEventListener('storage', (res) => {
  if (res.key === 'refresh_token') {
    if(![null,undefined,''].includes(res.newValue)){
      window.location.reload()
    }
  }
});

function App() {
  const lang = Cookies.get('lang');
  const locale = lang === 'zh' ? zhCN : enUS;
  return (
    <ConfigProvider locale={locale}>
      <DndProvider backend={Backend}>
        <Provider store={store}>
         <AppRouter />
        </Provider>
      </DndProvider>
    </ConfigProvider>
  );
}

export default App;
