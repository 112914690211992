import React, { useEffect, useState } from 'react';
import { InputNumber, message, Row, Col } from 'antd';
import intl from 'react-intl-universal';
import { debounce } from 'lodash';
import styles from './index.less';
import { fetchGeneralConfig, updateGeneralConfig } from '../../actions/configActions';
import { connect } from 'react-redux';
import { limitDecimals } from '../../utils/formUtils';
function ReportHesitation(props) {
  const [period, setPeriod] = useState('');
  const [days, setDays] = useState('');
  useEffect(() => {
    props.fetchGeneralConfig().then((res) => {
      if (res) {
        setDays(res.report_overdue_seconds / 86400);
        setPeriod(res.report_recall_timeout_seconds / 3600);
      }
    });
  }, []);

  const onHesitation = (data) => {
    if (data < 2 || data > 120) {
      message.warning(intl.get('HISITATION_WARN'));
      setPeriod(period);
      return false;
    }
    let seconds = data * 3600;
    props.updateGeneralConfig({ report_recall_timeout_seconds: seconds }).then((res) => {
      if(res){
        message.success(intl.get('MARK_MODIFY_SUCCESS'));
        setPeriod(data);
      }
    });
  };

  const onBack = (data) => {
    if (data < 1) {
      message.warning(intl.get('RECOVERY_TIME_MIN', { num: 1 }));
      setPeriod(days);
      return false;
    }
    if (data > 10) {
      message.warning(intl.get('RECOVERY_TIME_MAX', { num: 10 }));
      setPeriod(days);
      return false;
    }
    let seconds = data * 86400;
    props.updateGeneralConfig({ report_overdue_seconds: seconds }).then((res) => {
      if(res){
        message.success(intl.get('MARK_MODIFY_SUCCESS'));
        setDays(data);
      }

    });
  };

  return (
    <Row className={styles.report_hesitation}>
      <Col span={12}>
        <span className={styles.hesitation__label}>{intl.get('REPORT_HISITATION_PERIOD')}</span>
        <InputNumber
          formatter={limitDecimals}
          parser={limitDecimals}
          value={period}
          onChange={debounce(onHesitation, 500)}
        />
        <span className={styles.hesitation__unit}>{intl.get('HOURS')}</span>
      </Col>

      <Col span={12}>
        <span ><span className={styles.hesitation__label}>{intl.get('OVERTIME_SET')}</span>{intl.get('RECOVERY_TIME')}</span>
        <InputNumber
          value={days}
          formatter={limitDecimals}
          parser={limitDecimals}
          onChange={debounce(onBack, 500)}
        />
        <span className={styles.hesitation__unit}>{intl.get('RECOVERY_TIME_UNIT')}</span>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state, ownProps) => ({});
export default connect(mapStateToProps, { fetchGeneralConfig, updateGeneralConfig })(ReportHesitation);
