import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import {
  getAnnotationsOfClasses,
  getCureentAnnotationOfClass,
  getHoveredAnnotationOfClass
} from '../../selectors/annotationSelectors';
import { getLinesToUserOfClass } from '../../selectors/classSelectors';
import { getCurrentClassification } from '../../selectors';
import config from '../../config';
import style from './index.less';

class AnnotationListTab extends Component {
  isInFiveClasses() {
    const { classes} = this.props;
    const currentClassification = getCurrentClassification();
    const current_classification = config.classifications['class'+ currentClassification];
    const c = current_classification.filter((l) =>l.name===classes[0]);
    return c.length > 0;
  }

  render() {
    const hovered = !!this.props.hoveredAnnotationOfClass;
    const selected = !!this.props.currentAnnotationOfClass;
    const length = this.props.annotations.reduce((sum, anno) => {
      return sum + (anno.type === 'Pointset' && anno.points.length > 0 ? anno.points.length : 1);
    }, 0);

    // console.log("++++++++++++++--this.props.annotations", this.props.annotations)

    let class_name =
      (15 !== getCurrentClassification()) &&
      !this.props.classes.includes("Draft") &&
      this.isInFiveClasses()
        ? intl.get(this.props.classes)
        : this.props.classes;

    // if (class_name.includes('Suspected_Anomalous_Areas')) {
    //   console.log("---class_name", class_name)
    //   class_name = intl.get(class_name);
    // }

    let text = this.props.isAll ? `${intl.get('ALL_CLASSES')}` : `${class_name}`;
    const isPoints = this.props.classes.length === 1 && this.props.classes[0] === 'Points';
    text = isPoints ? `${intl.get('POINTS')}` : text;

    const isDraft = this.props.classes.length === 1 && this.props.classes[0] === 'Draft';
    text = isDraft ? `${intl.get('CLASS_OF_DRAFT')}` : text;
    text = (text === "Suspected_Anomalous_Areas") ? `${intl.get('Suspected_Anomalous_Areas')}` : text;

    let styleName = selected && !this.props.isAll ? style.selectedTab : style.plainTab;

    styleName = hovered && !this.props.isAll ? style.hoveredTab : styleName;

    styleName = this.props.isOver ? style.dropTab : styleName;

    let textLoc =  config.classification5 === getCurrentClassification()?'left':'center'

    return length ? (this.props.classification15
      ? this.props.connectDropTarget(<div className={styleName} style={{textAlign:textLoc}}> {`${text} [${length}]`} </div>)
      : this.props.connectDropTarget(
          <div className={styleName} style={{textAlign:textLoc}}>
             {text}<span> ({length}) </span>
          </div>
        )) : null;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    hoveredAnnotationOfClass: getHoveredAnnotationOfClass(state, ownProps),
    currentAnnotationOfClass: getCureentAnnotationOfClass(state, ownProps),
    annotations: getAnnotationsOfClasses(state, ownProps)
  };
};

export default DropTarget(
  'ANNOTATION_CHANGE',
  {
    drop: (props, monitor) => {
      const item = monitor.getItem();
      const old = getLinesToUserOfClass(item.lines).find((l) => l.user === props.loginUser);
      const change = {
        old: old ? old.clazz : null,
        new: props.classes.find((c) => true),
        user: props.loginUser,
        status: 'uploading'
      };
      props.addAnnotationChangeAsync(change, item.id);
    }
  },
  (connect, monitor) => {
    return {
      connectDropTarget: connect.dropTarget(),
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop()
    };
  }
)(connect(mapStateToProps)(AnnotationListTab));
