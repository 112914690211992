import {
  apifetchProductionList,
  apiAddSection,
  apiUpdateSection,
  apifetchRole,
  apiStartPro,
  apiEndPro,
  apiDistributeProducer,
  apiBatchStartPro,
  apiBatchEndPro,
  apiUpdateBatchSection
} from './api';
import store from '../store';
import { getProductionQueryInfo, getProductionSelected } from '../selectors/productionSelector';
import { put, takeLatest } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import { message } from 'antd';
export function setProductionList(data) {
  return { type: 'SET_PRODUCTION_LIST', data };
}
export function setProSelectedList(data) {
  return { type: 'SET_PRODUCTION_SELECTED_LIST', data };
}
export function setProQueryInfo(data) {
  return { type: 'SET_PRODUCTION_QUERY_INFO', data };
}
export function initProQueryInfo(data) {
  return { type: 'INIT_PRODUCTION_QUERY_INFO', data};
}

export function setProSelectedSingle(data) {
  return { type: 'SET_PRODUCTION_SELECTED_SINGLE', data };
}

export function setProSelectedProducer(data) {
  return { type: 'SET_PRODUCTION_SELECTED_PRODUCER', data };
}

export function* watchUpdateProductionQueryInfo() {
  yield takeLatest('SET_PRODUCTION_QUERY_INFO', function*(action) {
    yield put(fetchProductionList());
  });
}

export function fetchProductionList() {
  const queryInfo = getProductionQueryInfo(store.getState());
  return async (dispatch) => {
    apifetchProductionList({ ...queryInfo })
      .then((resp) => {
        if (resp && resp.data) {
          dispatch(setProductionList(resp.data));
        }
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

function _addSection(dispatch) {
  let selected = getProductionSelected(store.getState());
  const promises = [];
  selected.list.forEach((item, i) => {
    promises.push(apiAddSection(item.id));
  });

  Promise.all(promises)
    .then((resp) => {
      message.success(intl.get('MARK_MODIFY_SUCCESS'));
      dispatch(fetchProductionList());
      dispatch(setProSelectedList({ list: [], total: 0 }));
    })
    .catch((err) => {
      console.log(err);
    });
}

export function addSection() {
  return async (dispatch) => {
    _addSection(dispatch);
  };
}

export function updateSection(id, data) {
  return async (dispatch) => {
    apiUpdateSection(id, data)
      .then((resp) => {
        message.success(intl.get('MARK_MODIFY_SUCCESS'));
        dispatch(fetchProductionList());
        dispatch(setProSelectedList({ list: [], total: 0 }));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function updateBatchSection(data) {
  let selected = getProductionSelected(store.getState());
  let s = selected.list.map((i) => i.id);
  let p = {
    sections: s,
    ...data
  };
  return async (dispatch) => {
    apiUpdateBatchSection(p)
      .then((resp) => {
        message.success(intl.get('MARK_MODIFY_SUCCESS'));
        dispatch(fetchProductionList());
        dispatch(setProSelectedList({ list: [], total: 0 }));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}



export function getRole() {
  return async (dispatch) => {
    apifetchRole()
      .then((resp) => {
        // console.log(resp.data);
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function startPro(id) {
  return async (dispatch) => {
    apiStartPro(id)
      .then((resp) => {
        message.success(intl.get('MARK_MODIFY_SUCCESS'));
        dispatch(fetchProductionList());
        dispatch(setProSelectedList({ list: [], total: 0 }));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function startBatchPro() {
  let selected = getProductionSelected(store.getState());
  let s = selected.list.map((i) => i.id);
  return async (dispatch) => {
    apiBatchStartPro({ sections: s })
      .then((resp) => {
        message.success(intl.get('MARK_MODIFY_SUCCESS'));
        dispatch(fetchProductionList());
        dispatch(setProSelectedList({ list: [], total: 0 }));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function endBatchPro() {
  let selected = getProductionSelected(store.getState());
  let s = selected.list.map((i) => i.id);
  return async (dispatch) => {
    apiBatchEndPro({ sections: s })
      .then((resp) => {
        message.success(intl.get('MARK_MODIFY_SUCCESS'));
        dispatch(fetchProductionList());
        dispatch(setProSelectedList({ list: [], total: 0 }));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function endPro(id) {
  return async (dispatch) => {
    apiEndPro(id)
      .then((resp) => {
        message.success(intl.get('MARK_MODIFY_SUCCESS'));
        dispatch(fetchProductionList());
        dispatch(setProSelectedList({ list: [], total: 0 }));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function distributeProducer(data) {
  return async (dispatch) => {
    apiDistributeProducer(data)
      .then((resp) => {
        message.success(intl.get('MARK_MODIFY_SUCCESS'));
        dispatch(fetchProductionList());
        dispatch(setProSelectedList({ list: [], total: 0 }));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}
