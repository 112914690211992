import { Card, Col, Radio, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { PieChart } from './Charts';
import style from './matrixAnalysis.less';
import MatrixTable from './MatrixTable';

class MatrixAnalysis extends Component {
  static propTypes = {
    matrix: PropTypes.array,
    header: PropTypes.array,
    formHeader: PropTypes.array,
    colorTable: PropTypes.array,
    formMatrix: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.state = {
      onlyPositive: false,
      isGT: true,
      showPercent: false
    };
  }

  switchGTAI = (e) => {
    this.setState({ isGT: e.target.value });
  };

  chartsHeader = () => {
    const { header, shortHeader } = this.props;
    if (header.length > 5) return header;
    return shortHeader;
  };

  render() {
    const { matrix, header, colorTable, formHeader, formMatrix } = this.props;
    const { isGT, onlyPositive } = this.state;
    return (
      <Row className={style.row}>
        <Col span={11}>
          <Card className={style.noBorderCard}>
            <Row>
              <div className={style.displayControl}>
                <Radio.Group
                  className={style.buttonGroup}
                  buttonStyle="solid"
                  value={this.state.isGT}
                  onChange={this.switchGTAI}
                >
                  <Radio.Button className={style.customBtn} value={true}>
                    GT
                  </Radio.Button>
                  <Radio.Button className={style.customBtn} value={false}>
                    AI
                  </Radio.Button>
                </Radio.Group>
              </div>
            </Row>
            <Row>
              <PieChart
                matrix={matrix}
                header={header}
                colorTable={colorTable}
                isGT={isGT}
                onlyPositive={onlyPositive}
              />
            </Row>
          </Card>
        </Col>
        <Col span={12} offset={1} className={style.noBorderCard}>
          <div className={style.matrixTable}>
            <div className={style.togglePercentage}>
              <Radio.Group
                className={style.buttonGroup}
                onChange={(e) => {
                  this.setState({ showPercent: e.target.value });
                }}
                buttonStyle="solid"
                defaultValue={this.state.showPercent}
              >
                <Radio.Button className={style.customBtn} value={true}>
                  {intl.get('PERCENTAGE')}
                </Radio.Button>
                <Radio.Button className={style.customBtn} value={false}>
                  {intl.get('NUMBER')}
                </Radio.Button>
              </Radio.Group>
            </div>
            <MatrixTable
              matrix={formMatrix}
              header={formHeader}
              showPercent={this.state.showPercent}
            />
          </div>
        </Col>
      </Row>
    );
  }
}

export default MatrixAnalysis;
