import { Layout } from 'antd';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Menubar from '../components/Menubar';
import PanelRouter from './PanelRouter';
import './preference.css';
import Sidebar from './Sidebar';
import styles from "./index.less";
import {fetchGeneralConfig } from '../actions/configActions';
import { sideBarItemKeys, sideBarItems } from './menuItems';
import { connect } from 'react-redux';
class Preference extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(){
    this.props.fetchGeneralConfig();
  }

  render() {
    return (
      <Layout style={{ height: '100%' }}>
        <div className="header" style={{ width: '100%' }}>
          <Menubar />
        </div>

        <Layout className={styles.perference}>
          <Sidebar sideBarItemKeys={sideBarItemKeys} sideBarItems={sideBarItems}/>
          <PanelRouter />
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {

  };
};

export default connect(mapStateToProps, { fetchGeneralConfig})(
  withRouter(Preference) 
);
