import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { Route, Switch } from 'react-router-dom';
// import RegisterForm from '../components/LoginPage/RegisterForm';
import SwitchLanguage from '../components/SwitchLanguage';
import LoginCenterForm from '../components/LoginPage/LoginCenterForm';
import cfg from '../config';
import style from './login.less';
import company_logo from '../static/images/company_logo.png';
// import tib_company_logo from '../static/images/tib_company_logo.png';
import { setTimeoutLabel } from '../actions/timeoutAction';
import { connect } from 'react-redux';
import { isPartEnd } from '../utils/urlUtils';
import {fetchGeneralConfig } from '../actions/configActions';
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { current: 'login' };
  }

  componentDidMount() {
    this.props.fetchGeneralConfig();
    if (localStorage.getItem('access_token')) {
      this.props.history.push('mainpage');
    } else {
      this.props.setTimeoutLabel(false);
    }
  }

  render() {   
    let loginClass =
      ['all','local'].includes(isPartEnd())
        ? 'loginPage'
        : isPartEnd() === 'centerEnd'
        ? 'login_center_page'
        : 'loginPage';
      if(window.config.isModeTIB){
        loginClass = "loginPageTIB"
      }
        

    return (      
      <div className={style[loginClass]}>
        <div className={style.configGroup}>
          <SwitchLanguage />
        </div>
        <div className={loginClass === 'login_expert_page' ? style.expert_form : (window.config.isModeTIB ? style.tib_center_form : style.center_form)}>
          <div className={style.center_form_logo}>
           {window.config.isModeTIB ? undefined : <img src={company_logo} /> }  
          </div>
          <p className={style.title}>
           {window.config.isModeTIB ? intl.get('TIB_TRADEMARK_SUB') : intl.get('TRADEMARK')}   
           {window.config.isModeTIB ? undefined : <sup>®</sup>}
           {window.config.isModeTIB ? undefined : intl.get('PLATFORM_NAME')}
          </p>
          {/* <p className={style.title_sub}>{intl.get('CENTER_TITLE_SUB')}</p> */}
          <Switch>
            <Route exact path="/" component={LoginCenterForm} />
            <Route path="/login" component={LoginCenterForm} />
          </Switch>
        </div>
        <div className={style.bottom_marker}>
          {intl.getHTML('PLATFORM_VERSION', { middleV: cfg.version })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    general: state.config.general
  };
};

export default connect(mapStateToProps, { setTimeoutLabel,fetchGeneralConfig })(Login);
