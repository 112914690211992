export const toggleHelp = (show) => ({
  type: 'TOGGLE_HELP_MODAL',
  show
});

export const toggleDownload = (show) => ({
  type: 'TOGGLE_DOWNLOAD',
  show
});

export const setOrganization = (name,data) => ({
  type: 'SET_ORGANIZATION',
  name,
  data
});

export const updateRegister = (data) => ({
  type: 'UPDATE_REGISTER',
  data
});

export const updateRegisterQueryInfo = (data) => ({
  type: 'UPDATE_REGISTER_QUERY_INFO',
  data
});
