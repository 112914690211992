import { zip } from 'lodash';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { getCurrentLocale } from '../static/locales';

const localeMapping = {
  zh: 'zh-cn',
  en: 'en'
};

export default function formatTimestamp(timestamp, format = 'YYYY-MM-DD') {
  const date = moment(timestamp);
  const locale = getCurrentLocale();
  return date.locale(localeMapping[locale]).format(format);
}

export function formatTimeLoc(date, format = 'YYYY-MM-DD') {
  const locale = getCurrentLocale();
  return date.locale(localeMapping[locale]).format(format);
}
export function setStartDate(current) {
  let nowDay = formatTimeLoc(moment());
  const days = (date, otherDate) => Math.ceil(Math.abs(date - otherDate) / 86400000);
  let result = days(new Date(nowDay), new Date('2000-01-01'));
  return current > moment().endOf( 'days') || current < moment().subtract(result+1, 'days');
}
export function getDisplayDates(dateFrom, dateTo) {
  if (!dateFrom && !dateTo) return [];
  const df = moment(dateFrom.split('|')[0]);
  const dt = moment(dateTo.split('|').pop());
  if (df.isValid()) {
    return [moment(df), moment(dt)];
  }
  return [];
}

export function zipDates(df, dt) {
  if (df && dt) {
    return zip(
      df.split('|').map((d) => moment(d)),
      dt.split('|').map((d) => moment(d))
    );
  }
  return [];
}

export function datesToStrs(dates) {
  return dates.map((d) => d.format('YYYY-MM-DD HH:mm:ss'));
}

export function GetDateDiff(startTime, endTime, diffType) {
  //将xxxx-xx-xx的时间格式，转换为 xxxx/xx/xx的格式
  let start = startTime.replace(/\-/g, '/');
  let end = endTime.replace(/\-/g, '/');
  let sTime = new Date(start); //开始时间
  let eTime = new Date(end); //结束时间
  //作为除数的数字
  let timeType = 1;
  switch (diffType) {
    case 'second':
      timeType = 1000;
      break;
    case 'minute':
      timeType = 1000 * 60;
      break;
    case 'hour':
      timeType = 1000 * 3600;
      break;
    case 'day':
      timeType = 1000 * 3600 * 24;
      break;
    default:
      break;
  }
  return parseInt((eTime.getTime() - sTime.getTime()) / parseInt(timeType, 10), 10);
}

export function getYmdhms() {
  let now = new Date();
  let year = now.getFullYear(); //得到年份
  let month = now.getMonth(); //得到月份
  let date = now.getDate(); //得到日期
  let hour = now.getHours(); //得到小时
  let minu = now.getMinutes(); //得到分钟
  let sec = now.getSeconds(); //得到秒
  month = month + 1;
  if (month < 10) month = '0' + month;
  if (date < 10) date = '0' + date;
  if (hour < 10) hour = '0' + hour;
  if (minu < 10) minu = '0' + minu;
  if (sec < 10) sec = '0' + sec;
  let time = '';
  time = year + '-' + month + '-' + date + ' ' + hour + ':' + minu + ':' + sec;
  return time;
}

export function getNowData(){
  let date = new Date();
  return date.toLocaleDateString()
}