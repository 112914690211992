import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setFullscreen } from '../../actions/toolActions';
import {Tooltip} from 'antd'
import intl from 'react-intl-universal';
import ToolButton from '../ToolButton'
import MyIcon from '../Iconfont'
class FullscreenBtn extends Component {
  render() {
    return (
      <Tooltip placement="top" title={intl.get('FULLSCREEN')}>
        <ToolButton 
          icon={<MyIcon type="icon-toolfull"/>}
          onClick={() => this.props.setFullscreen()}
          status={this.props.fullscreen ?"active":"normal"}
        />
      </Tooltip>
    );
  }
}

const mapStateToProps = (state) => ({
  fullscreen: state.toolStatus.fullscreen
});

export default connect(mapStateToProps, { setFullscreen })(FullscreenBtn);






