import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setNavigatorVisibility } from '../../actions/toolActions';
import ToolButton from '../ToolButton'
import MyIcon from '../Iconfont'
import {Tooltip} from 'antd'
import intl from 'react-intl-universal';

class NavigatorBtn extends Component {
  render() {
    return (
      <Tooltip placement="top" title={intl.get('NAVIGATOR_BUTTON')}>
        <ToolButton 
          icon={<MyIcon type="icon-toolnav"/>}
          onClick={() => this.props.setNavigatorVisibility(!this.props.navigatorVisibility)}
          status={this.props.navigatorVisibility ?"active":"normal"}
        />
      </Tooltip>
    );
  }
}

const mapStateToProps = (state) => ({
  navigatorVisibility: state.toolStatus.navigatorVisibility
});

export default connect(mapStateToProps, { setNavigatorVisibility })(NavigatorBtn);





