import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import {saveReportResult} from '../../actions/reportAction';
import { pipelineEdited} from '../../actions/imageActions';
class SubAction extends Component {
  componentDidMount() {}

  sub(id) {
    this.props.pipelineEdited(id,'list');
  }
  render() {
    const { image, currentUser } = this.props;
    const isJunior = currentUser.username === image.junior_reporter;
    const disStyle = { color: '#999', cursor: 'auto', display: 'inline-block', width: '80px' }
    return image.stage === 3? (
      isJunior ? (
        <span style={{ display: 'inline-block', width: '80px' }} onClick={this.sub.bind(this, image.id)}>
          {intl.get('SYNCHRO_AI')}
        </span>
      ) : (
        <span style={disStyle}>
          {intl.get('SYNCHRO_AI')}
        </span>
      )
    ) : isJunior ? (
      <span
        style={{ display: 'inline-block', width: '80px' }}
        onClick={this.sub.bind(this, image.id)}
      >
        {intl.get('REPORT_SUBMIT')}
      </span>
    ) : (
      <span style={disStyle}>
        {intl.get('REPORT_SUBMIT')}
      </span>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  };
};

export default connect(mapStateToProps, { pipelineEdited,saveReportResult })(SubAction);
