import { Component } from 'react';
import { connect } from 'react-redux';
import { fabric } from 'openseadragon-fabricjs-overlay';
import uuidv4 from 'uuid/v4';
import { setCurrentAnnotationId } from '../../actions/annotationSelectionActions';
import { getPointSetAnnotations } from '../../selectors/annotationSelectors';
import {
  addAnnotationAsync,
  setShowAnnotationClass,
  addPointsOfPointSetAsync
} from '../../actions/annotationActions';
import { getLoginUser } from '../../selectors';
import { setPointMode } from '../../actions/toolActions';

export const newPointOverlay = (point, color, radius) => {
  const id = uuidv4();
  const p = new fabric.Rect({
    selectable: false,
    hasControls: false,
    hasRotatingPoint: false,
    width: radius * 2,
    height: radius * 2,
    left: point.x - radius * 1.5,
    top: point.y - radius * 1.5,
    stroke: color,
    strokeWidth: radius,
    fill: color,
    objectCaching: false,
    id: id
  });
  return p;
};

export const newCircleOverlay = (point, color, radius) => {
  const id = uuidv4();
  const p = new fabric.Circle({
    selectable: true,
    hasControls: true,
    hasRotatingPoint: false,
    radius: radius,
    left: point.x - radius,
    top: point.y - radius,
    stroke: color,
    strokeWidth: radius * 0.2,
    fill: 'rgba(0,0,0,0)',
    objectCaching: false,
    id: id
  });
  return p;
};

export const pointText = (txt,point,radius)=>{
  const text = new fabric.Text(txt, {
    left: point.x - 2*radius,
    top: point.y - 2*radius,
    textAlign: 'left',
    fontSize: 2*radius,
    selectable: false,
    falseClearCache: true,
    fill: 'rgba(255,255,0,1)',
    backgroundColor: 'rgba(0,0,0,0)',
    fontFamily: 'Microsoft YaHei',
    hoverCursor: 'pointer'
  });
  return text
}

class PointAnnotation extends Component {
  constructor(props) {
    super(props);
    this.canvas = null;
    this.rect = null;
  }

  componentDidUpdate(prevProps) {
    if (window.osdoverlay && !this.canvas) {
      this.canvas = window.osdoverlay.fabricCanvas();
    }
    if (this.props.pointMode && !prevProps.pointMode && this.canvas) {
      this.enterDrawingMode();
    }
    if (!this.props.pointMode && prevProps.pointMode && this.canvas) {
      this.exitDrawingMode();
    }
  }

  componentWillUnmount() {
    this.exitDrawingMode();
    this.props.setPointMode(false);
  }

  enterDrawingMode = () => {
    window.osdviewer && window.osdviewer.setMouseNavEnabled(false);
    window.osdviewer && window.osdviewer.outerTracker.setTracking(false);
    this.canvas && this.canvas.on('mouse:down', this.mouseDown);
    this.canvas && this.canvas.on('mouse:move', this.mouseMove);
    this.canvas && this.canvas.on('mouse:up', this.mouseUp);
  };

  exitDrawingMode = () => {
    window.osdviewer && window.osdviewer.setMouseNavEnabled(true);
    window.osdviewer && window.osdviewer.outerTracker.setTracking(true);
    this.canvas && this.canvas.off('mouse:down');
    this.canvas && this.canvas.off('mouse:move');
    this.canvas && this.canvas.off('mouse:up');
  };

  mouseDown = (e) => {};

  mouseMove = (e) => {};

  mouseUp = (e) => {
    const point = this.canvas.getPointer(e.e);
    const anno = this.getRectOfPoint(point);
    if (anno) {
      this.props.addPointsOfPointSetAsync([{ ...point }], anno.id);
    } else {
      const viewport = window.osdviewer && window.osdviewer.viewport.getBounds();
      this.rect = this.getShrinkRect(viewport, 0.9, 0.9);
      this.addPointSetRect(point);
    }
    const p = newCircleOverlay(point, 'rgba(255,0,0,1)', 16);
    this.canvas.add(p);
    this.canvas.renderAll();
  };

  getRectOfPoint = (point) => {
    const inRects = this.props.pointSets.filter((set) => {
      return (
        set.x_start < point.x &&
        set.y_start < point.y &&
        set.x_start + set.x_range > point.x &&
        set.y_start + set.y_range > point.y
      );
    });
    return inRects.find(() => true);
  };

  getShrinkRect = (rect, ratiow = 0.9, ratioh = 0.7) => {
    const { x, y, width, height } = rect;
    const newx = x + width * (1 - ratiow) * 0.5;
    const newy = y + height * (1 - ratioh) * 0.5;
    const neww = width * ratiow;
    const newh = height * ratioh;
    const newRect = { x: newx, y: newy, width: neww, height: newh };
    return newRect;
  };

  addPointSetRect = (point) => {
    const annotation = {
      id: uuidv4(),
      name: 'annotation',
      px: Math.round(this.rect.x + this.rect.width / 2.0),
      py: Math.round(this.rect.y + this.rect.height / 2.0),
      x_start: Math.round(this.rect.x),
      y_start: Math.round(this.rect.y),
      x_range: Math.round(this.rect.width),
      y_range: Math.round(this.rect.height),
      size: Math.round(this.rect.width),
      type: 'Pointset',
      isFinished: true,
      points: [{ ...point }],
      changes: [{ id: uuidv4(), old: null, new: 'Points', user: this.props.loginUser }]
    };
    this.props.addAnnotationAsync(annotation, this.props.currentImageKey);
    this.props.setShowAnnotationClass('Points');
  };

  addRect = (start) => {
    this.rect = new fabric.Rect({
      left: start.x,
      top: start.y,
      fill: 'rgba(0,0,0,0)',
      stroke: 'rgba(255,0,0,0.8)',
      strokeWidth: 8,
      width: 0,
      height: 0,
      editable: false,
      selectable: false
    });
    this.canvas.add(this.rect);
  };

  render() {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    overlay: state.openSeadragonOperation.overlay,
    pointMode: state.toolStatus.pointMode,
    currentImageKey: state.imageSelection.currentImageKey,
    loginUser: getLoginUser(state),
    pointSets: getPointSetAnnotations(state)
  };
};

export default connect(mapStateToProps, {
  setPointMode,
  setShowAnnotationClass,
  setCurrentAnnotationId,
  addAnnotationAsync,
  addPointsOfPointSetAsync
})(PointAnnotation);
