import React from 'react';
// import { Tag } from 'antd';
import intl from 'react-intl-universal';

const setSpanVal =(res)=>{
  return (
    <span style={{ color:res.color }}>
      <span style={{ fontWeight: 'bold' }}>·</span>&nbsp;{intl.get(res.text)}
    </span>
  );
}


const setExplainVal = (auto_ctrl,img) => {
  if (img.rescan ) {
      return {
        color: '#F56C6C' ,
        text:'PLEASE_RE_SCAN'
      }
  } else {
    let sugRescan = img.shangpixibao && img.yangxing !== 'H' && img.mohu !== 'L';
    let sugPro = img.yangxing === 'H';
    if (sugRescan && img.imageLevelResult === 'NILM') {
      return {
        color: '#409EFF' ,
        text:'SUGGEST_RE_SCAN'
      }
    } else if (sugPro && img.imageLevelResult === 'NILM') {
      return {
        color: '#409EFF' ,
        text:'SUGGEST_RE_PRO'
      }
    } else {
      return {
        color: 'rgba(0,0,0,0.7)' ,
        text:'SCAN_END'
      }
    }
  }
};

export const setExplainText =(auto_ctrl,img)=>{
  return intl.get(setExplainVal(auto_ctrl,img).text)
}

export const StageExplain = ({ auto_ctrl, img }) => {
  return setSpanVal(setExplainVal( auto_ctrl, img ));
};





export default StageExplain;
