import React, { useEffect,  } from 'react';
import CommonPanel from '../commonPanel';
import { connect } from 'react-redux';
import { getMachine } from '../../../actions/userActions';
import DataClean from "../../System/DataClean"
import SourceList from "./SourceList"
function DataSource(props) {

  useEffect(() => {
    props.getMachine();
  }, []);

  return (
    <CommonPanel>
      <SourceList />
      <DataClean />
    </CommonPanel>
  );
}

const mapStateToProps = (state, ownProps) => ({
  units: state.currentUser.units
});
export default connect(mapStateToProps, { getMachine })(DataSource);
