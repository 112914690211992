import React, { useState, useEffect } from 'react';
import styles from './index.less';
import intl from 'react-intl-universal';
import { formatNDigit, isDataPaltform } from './utils';
import SearchTab from './SearchTab';
import { Table } from 'antd';
import { updateQueryInfo } from '../../actions/tableActions';
import { setCurrentPage } from '../../actions/configActions';
import { useDispatch, connect } from 'react-redux';
import { isPartEnd } from '../../utils/urlUtils';
import { RatioBetween } from '../../utils/lessUtils';
import { uniq } from 'lodash';
import { filterJson, isNoVal } from '../../utils/dataUtils';
import { updateStatisticsQueryInfo, updateSelectedHospital } from '../../actions/statistics';
import formatTimestamp, { GetDateDiff, getNowData } from '../../utils/dateUtils';
import { beautifulTableCol } from './echartInfo';
function BeautifulTable(props) {
  const dispatch = useDispatch();
  const { rollTime = 100, rollTop = 1.5, flag = true } = props;

  const [timer, setTimer] = useState(); // 定时器

  useEffect(() => {
    InitialScroll(props.data);
    return () => {
      clearInterval(timer);
    };
  }, []);

  // 开启定时器
  const InitialScroll = (data) => {
    let v = document.getElementsByClassName('ant-table-body')[0];
    if (isRoll() && flag) {
      let time = setInterval(() => {
        v.scrollTop += Number(rollTop);
        if (Math.ceil(v.scrollTop) >= parseFloat(v.scrollHeight - v.clientHeight)) {
          v.scrollTop = 0;
        }
      }, Number(rollTime));
      setTimer(time);
    }
  };
  const isRoll = () => {
    let tableH = document.querySelector('#beaTable');
    let screenH = window.screen.height * 0.21;

    if (tableH && tableH.offsetHeight > screenH) {
      return true;
    } else {
      return false;
    }
  };
  let colHeaders = beautifulTableCol.filter(b=>b.part.includes('platform')).map((col) => {
    return {
      key: col.key,
      dataIndex: col.key,
      title: intl.get(col.name),
      align: 'center',

      render: (_, record) => {
        return col.key === 'running_at' ? (
          <span>
            {isNoVal(record.running_at)
              ? '-'
              : GetDateDiff(formatTimestamp(record.running_at), getNowData(), 'day')}
            {col.unit}
          </span>
        ) : (
          <span>
            {col.data.map((item, index) => {
              let d = isNoVal(record[item]) ? '-' : formatNDigit(record[item], 0);
              return index !== 0 ? '/' + d : d;
            })}
            {col.unit}
          </span>
        );
      }
    };
  });

  const toImagePage = (record) => {
    dispatch(updateQueryInfo({ machines: record.id, owner: '' }));
    dispatch(setCurrentPage('report'));
    props.history.push('/mainpage');
  };
  const selectRows = (keys, rows) => {
    let selectedRows = props.selected.data;
    let ks = uniq(keys);
    selectedRows = filterJson(selectedRows.concat(rows), 'id').filter((item) => {
      return ks.indexOf(item.id) !== -1;
    });
    props.updateStatisticsQueryInfo({
      ...props.queryInfo,
      machines: ks.length > 0 ? ks : []
    });
    props.updateSelectedHospital({ data: selectedRows, total: ks.length });
  };

  const partEnd = isPartEnd();

  const rowSelection = {
    selectedRowKeys: props.selected.data.map((item) => item.id),
    onChange: selectRows,
    columnWidth: '40px'
  };
  const isp = isDataPaltform(props.parentName);
  return (
    <div
      className={
        isp
          ? styles.styled__table_out_platform + ' ' + styles.dynamic_platform
          : styles.styled__table_out
      }
    >
      <SearchTab isplatform={isp} />
      <div
        onMouseOver={() => {
          clearInterval(timer);
        }}
        onMouseOut={() => {
          InitialScroll(props.data);
        }}
      >
        <Table
          columns={colHeaders}
          rowSelection={rowSelection}
          dataSource={props.data}
          key={(record) => record.id}
          rowKey={(record) => record.id}
          pagination={false}
          id="beaTable"
          style={{ minHeight: '21vh' }}
          scroll={{
            x: '100%',
            y: RatioBetween(document) ? '21vh' : '18vh',
            scrollToFirstRowOnChange: true
          }}
          className={styles.styled__table}
          rowClassName={partEnd === 'centerEnd' ? styles.style__centerEnd : ''}
          onRow={(record) => ({
            onDoubleClick: () => {
              partEnd === 'centerEnd' && toImagePage(record);
            }
          })}
          {...props}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    queryInfo: state.statistics.queryInfo,
    selected: state.statistics.selected
  };
};

export default connect(mapStateToProps, { updateStatisticsQueryInfo, updateSelectedHospital })(
  BeautifulTable
);
