import { Button, Modal } from 'antd';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { deleteImages } from '../../actions/tableActions';
import { getImages, getSelectedImages } from '../../selectors/imageSelector';
import styles from '../../less/modal.less';
import ConfirmContent from '../CommonComponent/ConfirmContent';
class DeleteImageBtn extends Component {
  constructor() {
    super();
    this.state = {
      isModalVisible: false
    };
  }
  componentDidMount() {}
  showModal() {
    this.setState({
      isModalVisible: true
    });
  }
  handleCancel() {
    this.setState({ isModalVisible: false });
  }
  deleteImages = () => {
    this.props.setLoading()
    this.props.deleteImages();
    this.handleCancel();
  };
  render() {
    const { isModalVisible } = this.state;
    const { selected } = this.props;
    return (
      <>
        <Button
          onClick={this.showModal.bind(this)}
          type="primary"
          disabled={selected.total > 0 ? false : true}
        >
          {intl.get('DEL_IMAGE')}
        </Button>
        <Modal
          title={intl.get('DEL_IMAGE')}
          visible={isModalVisible}
          onOk={this.deleteImages.bind(this)}
          onCancel={this.handleCancel.bind(this)}
          className={styles.checkModal}
        >
          <ConfirmContent>
            <span>{intl.get('IS_DEL_IMAGE')}</span>
          </ConfirmContent>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    images: getImages(state),
    selected: getSelectedImages(state)
  };
};

export default connect(mapStateToProps, { deleteImages })(DeleteImageBtn);
