import { connect, useSelector } from 'react-redux';
import {getPatientID} from '../../selectors/imageSelector';
import detailBuilder from './detailBuilder';

const PatientIDUnWrapped = ()=>{
  const content = useSelector(getPatientID)
  return detailBuilder('DETAIL_PATIENT_ID',content)
}

const PatientID = connect((state) => ({}))(PatientIDUnWrapped);
export default PatientID;
