import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import style from "./index.less"
import  config from '../../config'
function ThresholdInfo(props) {
  const infoList = [
    {
      name: 'ALGO_VERSION',
      value: config.AlgoVersion
    },
    {
      name: 'POS_THRESHOLD',
      value: props.general.suspect_and_abnormal_threshold
    },
    {
      name: 'SUS_THRESHOLD',
      value: '0.500'
    }
  ];
  return (
    <div>
      {infoList.map((i, index) => {
        return (
          <div key={index} className={style.analysis_info}>
            {intl.get(i.name)}{i.value}
          </div>
        );
      })}
    </div>
  );
}

const mapStateToProps = (state) => ({
    general:state.config.general
});
export default connect(mapStateToProps, {})(ThresholdInfo);
