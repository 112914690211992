import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Radio, Checkbox, message } from 'antd';
import intl from 'react-intl-universal';
import styles from '../commonPanel.less';
import { setValueOptions } from '../../../utils/dataUtils';
import { modeList, modeLabels } from '../../../static/settingInfo';
import { fetchGeneralConfig, updateGeneralConfig } from '../../../actions/configActions';
import { getCurrentLocale } from '../../../static/locales';
function DisSet(props) {
  const [checkedRadio, setCheckedRadio] = useState(false);
  const [disType, setDisType] = useState(false);
  const [juniorList, setJuniorList] = useState([]);
  const [reporterLsit, setReporterLsit] = useState([]);
  const radio = setValueOptions('radio', modeList);

  useEffect(() => {
    props.fetchGeneralConfig().then((res) => {
      if (res) {
        setJuniorList(res.junior_distribute_roles);
        setReporterLsit(res.senior_distribute_roles);
        setDisType(res.auto_distribute_report);
        if (res && res.junior_distribute_roles.includes('ai')) {
          setCheckedRadio(true);
        }
      }
    });
  }, []);

  const onChangeRadio = (event) => {
    props.updateGeneralConfig({ auto_distribute_report: event.target.value }).then((res) => {
      if (res) {
        setDisType(event.target.value);
      }
    });
  };

  const changeJunior = (e) => {
    let juniorList = [];
    let reportList = [];
    if (e.includes('ai')) {
      setCheckedRadio(true);
      juniorList = ['ai'];
    } else {
      setCheckedRadio(false);
      juniorList = e;
    }
    (juniorList.includes('junior_doctor') || juniorList.includes('senior_doctor')) &&
      reportList.push('senior_doctor');
    juniorList.includes('expert') && reportList.push('expert');
    setJuniorList(juniorList);
    setReporterLsit(reportList);
    if (juniorList.length > 0 && reportList.length > 0) {
      save(juniorList, reportList);
    } else {
      message.error(intl.get('DIS_ERROR'));
    }
  };

  const changeReporter = (e) => {
    let j = [];
    if (!juniorList.includes('ai')) {
      if (e.includes('senior_doctor')) {
        j.push('junior_doctor', 'senior_doctor');
      }
      if (e.includes('expert')) {
        j.push('expert');
      }
      setJuniorList(j);
    }
    setReporterLsit(e);
    if (!juniorList.includes('ai')) {
      save(j, e);
    } else {
      save(['ai'], e);
    }
  };
  const save = (j, r) => {
    props
      .updateGeneralConfig({ junior_distribute_roles: j, senior_distribute_roles: r })
      .then((res) => {
        if (res) {
          message.success(intl.get('MARK_MODIFY_SUCCESS'));
        }
      });
  };
  return (
    <div className={styles.distribution}>
      <Row className={styles.dis_title}>
        <Col span={6} offset={2}>
          {intl.get('DIS_MODE')}
        </Col>
        <Col span={16}>{intl.get('DIS_ACTOR')}</Col>
      </Row>
      <Row className={styles.dis_content}>
        <Col span={2}>
          {modeLabels.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  getCurrentLocale() === 'zh'
                    ? styles.dis_item + ' ' + styles.dis_item1
                    : styles.dis_item + ' ' + styles.dis_item1_en
                }
              >
                {intl.get(item)}
              </div>
            );
          })}
        </Col>
        <Col span={6}>
          <Radio.Group
            onChange={(e) => {
              onChangeRadio(e);
            }}
            className={getCurrentLocale() === 'zh'?styles.dis_item2:styles.dis_item2_en}
            value={disType}
          >
            {radio}
          </Radio.Group>
        </Col>
        <Col span={16}>
          <div className={styles.dis_item + ' ' + styles.dis_item3}>
            <Checkbox.Group style={{ width: '100%' }} onChange={changeJunior} value={juniorList}>
              <Row>
                <Col span={6}>
                  <Checkbox value="ai" className={styles.dis_item_radio}>
                    AI
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="junior_doctor" disabled={checkedRadio}>
                    {intl.get('JUNIOR_REPORTER')}
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="senior_doctor" disabled={checkedRadio}>
                    {intl.get('REPORTER')}
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="expert" disabled={checkedRadio}>
                    {intl.get('OUTTER_DOCTOR')}
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </div>
          <div className={styles.dis_item + ' ' + styles.dis_item3}>
            <Checkbox.Group
              style={{ width: '100%' }}
              value={reporterLsit}
              onChange={changeReporter}
            >
              <Row>
                <Col span={6}>
                  <Checkbox value="senior_doctor">{intl.get('REPORTER')}</Checkbox>
                </Col>
                <Col span={6} offset={12}>
                  <Checkbox value="expert">{intl.get('OUTTER_DOCTOR')}</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </div>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({});
export default connect(mapStateToProps, { fetchGeneralConfig, updateGeneralConfig })(DisSet);
