import React, { Component } from 'react';
import style from './index.less';
import { Progress, Tooltip } from 'antd';
import LoadingSpin from './LoadingSpin';
import intl from 'react-intl-universal';
import { formatNDigit,isDataPaltform} from './utils';

function getProcessedValue({ numerator, denominator }) {
  if (!denominator || denominator === 0) return 0;
  let val = formatNDigit((numerator / denominator) * 100, 1);
  return Number(val);
}

function formatProgress(percent, item) {
  const { numerator, denominator } = item;
  if (!denominator || denominator === 0) return 'NA';
  if (numerator === 0) return '0%';
  return percent + '%';
}
export class AlgoPerformance extends Component {
  render() {
    const { parentName ,height} = this.props;
    const strokeColor = {
      from: '#108ee9',
      to: '#108ee9'
    };
    const {
      exclude,
      sensitivity,
      sensitivity_L,
      sensitivity_microbe,
      specific,
      specific_microbe
    } = this.props.data;
    const dataList = [
      { name: 'NR', val: exclude },
      { name: 'SENSITIVITY', val: sensitivity },
      { name: 'LSIL_SENSITIVITY', val: sensitivity_L },
      { name: 'SPECIFIC', val: specific },
      { name: 'M_SENSITIVITY', val: sensitivity_microbe },
      { name: 'M_SPECIFIC', val: specific_microbe }
    ];
    const windWidth = window.innerWidth;
    const isDataPlatform = isDataPaltform(parentName);
    return (
      <div
        className={isDataPlatform ? style.chart_container_dataPlatform : style.chart_container}
        style={{ height:height}}
      >
        {!isDataPlatform && (
          <h3
            style={{
              textAlign: 'left',
              color: '#000',
              margin: '10px auto',
              fontSize: '14px',
              fontWeight: 700,
              textIndent: '5px',
              padding: '10px 0'
            }}
          >
            {intl.get('ALGO_PERFORMANCE')}
          </h3>
        )}

        {this.props.data && exclude ? (
          <div className={style.performance__container}>
            {dataList.map((item, index) => {
              return (
                <div className={style.performance__div} key={index}>
                  <Tooltip
                    title={windWidth < 1920 ? intl.get(item.name) : ''}
                    placement="top"
                    className={style.performance__item1}
                  >
                    {intl.get(item.name)}
                  </Tooltip>
                  <div className={style.performance__item2}>
                    <Progress
                      format={(perc) => formatProgress(perc, item.val)}
                      strokeColor={strokeColor}
                      percent={getProcessedValue(item.val)}
                      size="small"
                    />
                  </div>
                  <Tooltip
                    title={item.val.numerator + '/' + item.val.denominator}
                    placement="top"
                    className={style.performance__item3}
                  >
                    <div>
                      {item.val.numerator}
                      {'/'}
                      {item.val.denominator}
                    </div>
                  </Tooltip>
                </div>
              );
            })}
          </div>
        ) : (
          <LoadingSpin />
        )}
      </div>
    );
  }
}

export default AlgoPerformance;
