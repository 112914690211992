import { fetchWsi, fetchDiskUsage } from './api';

export const updateWsiList = (position, wsiList) => ({
  type: 'UPDATE_WSI_LIST',
  position,
  wsiList
});

export const toggleLoadingWsiLists = (loading) => ({
  type: 'TOGGLE_LOADING_WSI_LISTS',
  loading
});

export const updateDiskUsage = (position, usage) =>({
  type: 'UPDATE_DISK_USAGE',
  usage
})

export function getWsiList(position) {
  return async (dispatch) => {
    fetchWsi(position).then((resp) => {
      dispatch(updateWsiList(position, resp.data.items));
    });
  };
}


export function getDiskUsage(position){
  return async (dispatch) =>{
    fetchDiskUsage(position).then((resp) => {
      dispatch(updateDiskUsage(position, resp.data));
    });
  }
}