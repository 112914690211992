import { connect, useSelector } from 'react-redux';
import {getPatientAge} from '../../selectors/imageSelector';
import detailBuilder from './detailBuilder';

const PatientAgeUnWrapped = ()=>{
  const content = useSelector(getPatientAge)
  return detailBuilder('PATIENT_AGE_LABEL',content)
}

const PatientAge = connect((state) => ({}))(PatientAgeUnWrapped);
export default PatientAge;