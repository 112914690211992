import React, { Component } from 'react';
import BaseChart from './BaseChart';
import style from './index.less';
import LoadingSpin from './LoadingSpin';
import { formatNDigit, isDataPaltform } from './utils';
import intl from 'react-intl-universal';
import { titleStyle, axisLineX, axisLineY, gridInFo } from './echartInfo';
import barTop from '../../static/images/barTop.png';
export class DistributionBar extends Component {
  getOption() {
    const { data, parentName, otherStyle, series } = this.props;
    const isDataPlatform = isDataPaltform(parentName);
    const colorList = ['#5B9AFF', '#82F3BE', '#8AE0FF', '#FFC52F', '#FF7A5B'];
    let fromatData = [];
    let formatXAxis = [];
    let defaultData = [];
    data.forEach((item) => {
      fromatData.push(formatNDigit(item.data * 100, 0));
      formatXAxis.push(
        item.time.slice(5, 6) === '0'
          ? item.time.slice(6, 7) + intl.get('CHECK_IN_MONTH')
          : item.time.slice(5, 7) + intl.get('CHECK_IN_MONTH')
      );
      defaultData.push(100);
    });
    let option = {
      title: {
        show: !isDataPlatform,
        left: 'left',
        text: intl.get('QUALITY_CTRL'),
        top: 10,
        textStyle: titleStyle
      },
      tooltip: {
        formatter: (params) => {
          return params.seriesName === 'distri' ? params.name + ':' + params.value + '%' : '';
        }
      },

      grid: gridInFo(parentName),
      yAxis: [
        {
          data: formatXAxis,
          axisTick: {
            show: false
          },
          inverse: true,
          axisLine: axisLineX,
          axisLabel: {
            interval: 0, //横轴信息全部显示
            rotate: formatXAxis.length > 8 ? 30 : '', //30度角倾斜显示
            textStyle: {
              color: otherStyle.xyColor,
              fontSize: 10
            }
          }
        },
        {
          type: 'category',
          inverse: true,
          axisTick: 'none',
          axisLine: 'none',
          show: true,
          axisLabel: {
            textStyle: {
              color: '#fff',
              fontSize: '10'
            },
            formatter: function(value) {
              return value + ' %';
            }
          },
          data: fromatData
        }
      ],
      xAxis: [
        {
          type: 'value',
          show: true,
          axisTick: {
            show: false
          },
          axisLine: axisLineY,
          axisLabel: {
            show: true,
            interval: 'auto',
            formatter: '{value} %', //以百分比显示
            textStyle: {
              color: otherStyle.xyColor
            }
          },
          splitArea: {
            areaStyle: {
              color: 'rgba(255,255,255,.5)'
            }
          },
          splitLine: {
            show: isDataPlatform ? false : true,
            lineStyle: {
              color: '#666',
              width: 0.5,
              type: 'dashed'
            }
          }
        }
      ],
      series: [
        {
          name: 'distri',
          type: 'bar',
          barCategoryGap: '30%',
          symbol: 'rectangle',
          barMaxWidth: '20px',
          zlevel: 1,
          label: {
            show: !isDataPlatform,
            formatter: '{c}%',
            position: 'right',
            distance: 4,
            color: otherStyle.xyColor,
            fontSize: '10px',
            offset: [0, 0]
          },
          ...series.background,
          itemStyle: {
            normal: {
              color: isDataPlatform
                ? {
                    colorStops: [
                      { offset: 0, color: '#4BB3D2' },
                      { offset: 1, color: '#36EAAF' }
                    ]
                  }
                : function(params) {
                    return colorList[params.dataIndex % 5];
                  }
            },
            emphasis: {
              opacity: 1
            }
          },
          data: fromatData
        },
        {
          name: '背景',
          type: 'bar',
          barMaxWidth: '20px',
          barGap: '-100%',
          data: defaultData,
          itemStyle: {
            normal: {
              color: 'rgba(255, 255, 255, 0.1)',
              barBorderRadius: 0
            }
          }
        },
        isDataPlatform && {
          name: 'XXX',
          type: 'pictorialBar',
          symbol: 'image://' + barTop,
          symbolPosition: 'end',
          symbolSize: [2, '110%'],
          symbolOffset: [2, 0],
          z: 20,
          data: fromatData
        }
      ]
    };

    return option;
  }

  render() {
    const { data, parentName, otherStyle } = this.props;
    const isDataPlatform = isDataPaltform(parentName);
    return (
      <div
        className={isDataPlatform ? style.chart_container_DistributionBar : style.chart_container}
      >
        {data ? (
          <BaseChart
            style={{ height: otherStyle.height, width: '100%' }}
            option={this.getOption()}
          />
        ) : (
          <LoadingSpin />
        )}
      </div>
    );
  }
}

export default DistributionBar;
