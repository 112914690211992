import React, { Component } from 'react';
import TopTabs from './TopTabs';

class TableTabs extends Component {

  render() {
    return (
      <TopTabs />
    );
  }
}


export default TableTabs;
