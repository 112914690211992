

const pushStatus = [
  { value: "", translate: 'ALL' },
  { value: "0", translate: 'PUSH_BEFORE' },
  { value: "1", translate: 'PUSH_PENDING' },
  { value: "2", translate: 'PUSH_SUCCESS' },
  { value: "3", translate: 'PUSH_FAIL' },
];

export const statusInfo = {
  0: {
    translate: 'PUSH_BEFORE',
    color: "rgba(230,162,60,0.7)"
  },
  1: {
    translate: 'PUSH_PENDING',
    color: "rgba(64,158,255,0.7)"
  },
  2: {
    translate: 'PUSH_SUCCESS',
    color: "rgba(149,212,117,0.7)"
  },
  3: {
    translate: 'PUSH_FAIL',
    color: "rgba(245,108,108,0.7)"
  },
}

export default pushStatus;
