import { Spin, List, Empty } from 'antd';
import React, { useRef, useEffect } from 'react';
import style from '../MsgBox/index.less';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import formatTimestamp from '../../utils/dateUtils';
import { loadModeImages } from '../../actions/imageActions';
import { useHistory } from 'react-router-dom';
import intl from 'react-intl-universal';
import {readsingleImageById} from "../../actions/timeoutAction"
const height = 300;

const TimeoutMsg = (props) => {
  const scrollRef = useRef(null);
  const history = useHistory();
  const { timeoutList, images } = props;
  useEffect(() => {
    let el = scrollRef.current.el;
    el.scrollTop = el.scrollHeight;
    return () => {};
  }, []);

  const goToBrower = (item) => {
    let path = loadModeImages(timeoutList, images, item);
    history.push(path);
  };

  const setRead =(id)=>{
    props.readsingleImageById(id)
  }

  return (
    <div className={style.list}>
      <InfiniteScroll
        dataLength={timeoutList.data.length}
        loader={<Spin />}
        height={height}
        className="scrollBarHidden"
        style={{ overflowX: 'hidden' }}
        ref={scrollRef}
      >
        {timeoutList.data.length > 0 ? (
          <List
            itemLayout="horizontal"
            dataSource={timeoutList.data}
            style={{cursor: 'auto' }}
            renderItem={(item) => {
              return (
                <List.Item style={{ border: 'none' }} key={item.id}>
                  <div className={style.comment__div}>
                    <div className={style.top__div}>
                        <span className={style.date__span}>
                          {formatTimestamp(item.registerDate, 'YYYY-MM-DD HH:mm:ss')}
                        </span>
                        <span
                          className={
                            item.distribute_stage === 4 ? style.stage__notread : style.stage__read
                          }
                          onClick={item.distribute_stage === 4 ? () => setRead(item.id) : ()=>{}}
                          // onClick={ () => setRead(item.id)}
                        >
                          {item.distribute_stage === 4 ? intl.get("TIMEOUT_READ") : intl.get("TIMEOUT_HAS_READ") }
                        </span>
                    </div>
                    <div
                      className={
                        item.distribute_stage === 4
                          ? style.center__div_bell_notRead
                          : style.center__div_bell
                      }
                      onClick={item.distribute_stage === 4 ? () => goToBrower(item) : ()=>{}}
                    >
                      <span>{item.filename}</span>
                    </div>
                  </div>
                </List.Item>
              );
            }}
          />
        ) : (
          <Empty
            style={{ width: '300px', marginTop: '100px' }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </InfiniteScroll>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  timeoutList: state.timeout.timeoutList,
  images: state.timeout.timeoutList,
});

export default connect(mapStateToProps, {readsingleImageById})(TimeoutMsg);
