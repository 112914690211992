import { cloneDeep, omit } from 'lodash';
import { normalize, schema } from 'normalizr';
import { getAnnotationClassOfUsers } from '../components/Annotation/mergeClass';
import config from '../config';
import formatTimestamp from './dateUtils';
import { dbToArray } from './imageLevelResultUtils';
import intl from 'react-intl-universal';
import React from 'react';
import { Select, Radio } from 'antd';
import { seeTypeList, sampleNameList } from '../components/CheckIn/checkInfo';
import { formAutoCtrl2 } from './statusUtils';
import { getFindings } from './reportUtils';
import { setExplainText } from '../components/ImageTable/StageExplain';
import { defaultTabsInfo } from '../static/defaultTabs';
import setBaseUrl from './urlUtils';
const { Option } = Select;
export default function rawToImageData(raw) {
  const { baseUrl } = config;
  const dataSource = raw.items.reduce((total, image) => {
    const beforeUrl = setBaseUrl(baseUrl, image);
    const imageUrl = beforeUrl + '/api/image';
    total.push({
      detectedNucleiNumber: image.nuclei_number,
      key: image.id,
      id: image.id,
      filename: image.filename,
      patient_name: image.patient_name,
      tag: `${imageUrl}/associated/${image.id}?type=macro-label`,
      thumbnail: `${imageUrl}/associated/${image.id}?type=thumbnail`,
      uploadDate: formTimeHMS(image.upload_date),
      registerDate: formTimeHMS(image.register_date),
      uploader: image.uploader,
      tagId: image.patient_tag_id ? image.patient_tag_id: image.filename.split('.')[0],
      slide_id: image.slide_id,
      imageLeve: image.image_level,
      imageLevelResult: dbToArray(image.image_level_result_AI).join(','),
      prob: image.prob,
      groundTruthResult: getFindings(dbToArray(image.ground_truth_result)),
      clazz: image.clazz,
      status: image.status,
      report: {},
      collectors: image.collectors,
      reporter: image.reporter,
      stage: image.stage,
      satisfactory: image.satisfactory,
      push_status: image.push_status,
      pull_status: image.pull_status,
      image_status: image.image_status,
      gender:
        image.gender === 'F'
          ? intl.get('REPORT_PATIENT_GENDER_F')
          : image.gender === 'M'
          ? intl.get('REPORT_PATIENT_GENDER_M')
          : '',
      age: image.age,
      ageValue: setAgeVal(image.age),
      distribute_stage: image.distribute_stage,
      senior_time: formTimeHMS(image.senior_time),
      junior_time: formTimeHMS(image.junior_time),
      junior_reporter: image.junior_roles.includes('ai') ? image.reporter : image.junior_reporter,
      scan_times: image.scan_times,
      sample_unit: image.sample_unit,
      auto_quality_ctrl:
        image.shangpixibao + ' ' + image.yanxing + ' ' + image.mohu + ' ' + image.yiwu,

      remark: image.remark,
      shangpixibao: image.shangpixibao,
      yanxing: image.yanxing,
      mohu: image.mohu,
      yiwu: image.yiwu,
      auto_ctrl_val: formAutoCtrl2(
        image.imageLeve,
        image.mohu,
        image.yanxing,
        image.yiwu,
        image.shangpixibao
      ),
      rescan: image.rescan,
      junior_dtime: formTimeHMS(image.junior_dtime),
      senior_dtime: formTimeHMS(image.senior_dtime),
      print_time: formTimeHMS(image.print_time),
      send_time: formTimeHMS(image.send_time),
      senior_result: getFindings(dbToArray(image.senior_result)),
      junior_result: getFindings(dbToArray(image.junior_result)),
      register_date: formTimeHMS(image.register_date),
      region_host: image.region_host,
      region_port: image.region_port,
      web_host: image.web_host,
      web_port: image.web_port,
      is_urgent: image.is_urgent,
      page: raw.page - 1
    });
    return total;
  }, []);
  return {
    data: dataSource,
    total: raw.total
  };
}

function formTimeHMS(time) {
  return time === null ? '' : formatTimestamp(time, 'YYYY-MM-DD HH:mm:ss');
}

export function normalizeAnnotations(annotations) {
  const reduced = annotations.map((anno) => {
    const data = getAnnotationClassOfUsers(anno);
    const lines = data.map((l) => {
      return { id: l.user + ',' + l.clazz, ...l };
    });
    const classes = data.map((c) => {
      return { id: c.clazz, clazz: c.clazz };
    });
    const users = data.map((c) => {
      return { id: c.user, user: c.user };
    });
    return { ...omit(anno, 'changes'), lines, users, classes };
  });
  const user = new schema.Entity('users');
  const clazz = new schema.Entity('classes');
  const line = new schema.Entity('lines');
  const annotationSchema = new schema.Entity('annotations', {
    users: [user],
    classes: [clazz],
    lines: [line]
  });
  const normalized = normalize(reduced, [annotationSchema]);
  return normalized;
}

export function rawAnnotationToAnnotationObj(raw) {
  const annotations = { ALL: [] };
  if (raw !== null || raw !== undefined) {
    raw.slice(1).map((anno, index) => {
      if (annotations[anno.class] === undefined) {
        annotations[anno.class] = [];
      }
      const a = {
        isKoilocyte: anno.is_koilocyte,
        ncRatio: anno.nc_ratio,
        nucleiSize: anno.nc_size,
        prob: anno.prob,
        left: anno.px,
        top: anno.py,
        size: anno.size,
        class: anno.class,
        isChecked: anno.isChecked,
        toReport: anno.toReport,
        index: index,
        id: anno.id
      };
      annotations[anno.class].push(a);
      annotations.ALL.push(a);
      return anno;
    });
  }
  return annotations;
}

export function initReduxData(initialData, data) {
  if (data === undefined) {
    return initialData;
  } else {
    return data;
  }
}

export function setOptions(list) {
  const children = [];
  if (list !== undefined) {
    for (let i = 0; i < list.length; i++) {
      children.push(
        <Option key={list[i]} value={list[i]}>
          {list[i]}
        </Option>
      );
    }
  }

  return children;
}

export function setValueOptions(type, list, ownLabels) {
  const children = [];

  if (list !== undefined) {
    if (type === 'select') {
      for (let i = 0; i < list.length; i++) {
        children.push(
          <Option key={list[i].value} value={list[i].value}>
            {ownLabels === 'ownLabels' ? list[i].label : intl.get(list[i].label)}
          </Option>
        );
      }
    } else if (type === 'radio') {
      for (let i = 0; i < list.length; i++) {
        children.push(
          <Radio key={list[i].value} value={list[i].value}>
            {ownLabels === 'ownLabels' ? list[i].label : intl.get(list[i].label)}
          </Radio>
        );
      }
    } else if (type === 'CheckBoxGroup') {
      for (let i = 0; i < list.length; i++) {
        children.push({
          value: list[i].value,
          label: ownLabels === 'ownLabels' ? list[i].label : intl.get(list[i].label)
        });
      }
    }
    return children;
  } else {
    return '';
  }
}

function getLabelByVal(val, list) {
  let l = list.filter((item) => item.value === val);
  if (l.length > 0) {
    return intl.get(l[0].label);
  } else {
    return val;
  }
}

export function setAgeVal(age) {
  let res;
  if (age) {
    if (age < 1 && age > 0) {
      res = (age * 12).toFixed(0) + '月';
    } else {
      res = parseInt(age, 10) + '岁';
    }
  } else {
    res = '';
  }
  return res;
}

export function setReduxAge(info) {
  let res = info;
  if (res.age < 1 && res.age > 0) {
    res.age = (res.age * 12).toFixed(0);
    res.age_type = 'M';
  }
  return res;
}

function setGender(gender) {
  if (gender === 'F') {
    return intl.get('REPORT_PATIENT_GENDER_F');
  } else if (gender === 'M') {
    return intl.get('REPORT_PATIENT_GENDER_M');
  } else if (gender === 'other') {
    return intl.get('GENEREL');
  } else {
    return gender;
  }
}
export function formCheckTableInfo(data) {
  const dataSource = data.items.reduce((total, check) => {
    total.push({
      pathology_id: check.pathology_id,
      apply_id: check.apply_id,
      name: check.name,
      age: check.age,
      ageValue: setAgeVal(check.age),
      gender: setGender(check.gender),
      admission_id: check.admission_id,
      sample_unit: check.sample_unit,
      sample_department: check.sample_department,
      apply_doctor: check.apply_doctor,
      clinic_type: getLabelByVal(check.clinic_type, seeTypeList),
      sample_num: 1,
      sample_name: getLabelByVal(check.sample_name, sampleNameList),
      sample_recevier: check.sample_recevier,
      sample_recevie_date: formTimeHMS(check.sample_recevie_date),
      sample_date: formTimeHMS(check.sample_date),
      is_urgent: check.is_urgent,
      rejected_reason: check.rejected_reason === '' ? '' : intl.get(check.rejected_reason),
      fee: check.fee,
      occupation: check.occupation,
      ethnic_group: check.ethnic_group,
      origin: check.origin,
      patient_telephone: check.patient_telephone,
      identity_type: check.identity_type,
      identity: check.identity,
      marital_status: check.marital_status,
      last_m: check.last_m,
      is_m_end: check.is_m_end,
      apply_date: check.apply_date,
      sample_sent_date: check.sample_sent_date,
      pathology_type: check.pathology_type,
      case_id: check.case_id,
      clinic_id: check.clinic_id,
      ward: check.ward,
      bed_id: check.bed_id,
      fee_type: check.fee_type,
      sensitivity: check.sensitivity,
      infectivity: check.infectivity,
      sample_type: check.sample_type,
      sample_region: check.sample_region,
      sample_index: check.sample_index,
      clinic_diagnosis: check.clinic_diagnosis,
      clinic_summary: check.clinic_summary,
      clinic_history: check.clinic_history,
      other_note: check.other_note,
      other_surgery: check.other_surgery,
      cell_examination_date: check.cell_examination_date,
      cell_examination_result: check.cell_examination_result,
      hpv_examination_date: check.hpv_examination_date,
      hpv_examination_result: check.hpv_examination_result,
      clinic_record: check.clinic_record,
      surgery_record: check.surgery_record,
      other_examination: check.other_examination,
      his: check.his
    });
    return total;
  }, []);
  return {
    data: dataSource,
    total: data.total
  };
}

export function formProTableInfo(data) {
  const dataSource = data.items.reduce((total, pro) => {
    total.push({
      pathology_id: pro.pathology_id,
      apply_id: pro.apply_id,
      name: pro.name,
      age: pro.age,
      ageValue: setAgeVal(pro.age),
      apply_doctor: pro.apply_doctor,
      distribute_stage: pro.distribute_stage,
      gender: setGender(pro.gender),
      id: pro.id,
      producer: pro.producer,
      sample_count: pro.sample_count,
      sample_department: pro.sample_department,
      sample_name:
        pro.sample_name === 'Cervical' ? intl.get('CERVICAL_EXFOLIATED_CELLS') : pro.sample_name,
      sample_unit: pro.sample_unit,
      slide_count: pro.slide_count,
      stage: pro.stage,
      start: pro.start === null ? '' : formatTimestamp(pro.start, 'YYYY-MM-DD HH:mm:ss'),
      end: pro.end === null ? '' : formatTimestamp(pro.end, 'YYYY-MM-DD HH:mm:ss'),
      evaluation: pro.evaluation,
      reproduce: pro.reproduce,
      section_index: pro.section_index + 1,
      is_urgent: pro.is_urgent
    });
    return total;
  }, []);
  return {
    data: dataSource,
    total: data.total
  };
}

export function isNoVal(data) {
  return [undefined, null, ''].includes(data) ? true : false;
}

export function formImageText(data) {
  let d = cloneDeep(data);

  d.map((image) => {
    const info = image.auto_ctrl_val.translate.split(",").map(v=>intl.get(v))
    const text = info.filter((it, i, arr)=>(arr.indexOf(it, 0) === i)).join(",") 
    image.auto_quality_ctrl_text =  text;
    image.auto_ctrl_val_text = setExplainText(image.auto_ctrl_val, image);    
    image.push_status_text =
      image.push_status !== '' && config.pushStatusInfo[image.push_status]
        ? intl.get(config.pushStatusInfo[image.push_status]?.translate)
        : '';
    image.image_level_text = defaultTabsInfo[image.imageLeve]
      ? intl.get(defaultTabsInfo[image.imageLeve].translate)
      : '';
    image.clazz_text = intl.get(`CANCER_${image.clazz.toUpperCase()}`);
    image.prob_text = (image.prob * 100).toFixed(2) + '%';
    return image;
  });
  return d;
}

//json去重
export function filterJson(data, Name) {
  let dest = [];
  for (let i = 0; i < data.length; i++) {
    let ai = data[i];
    if (i === 0) {
      dest.push(ai);
    } else {
      let filterData = dest.filter(function(e) {
        return e[Name] === ai[Name];
      });
      if (filterData.length === 0) {
        dest.push(ai);
      }
    }
  }
  return dest;
}
