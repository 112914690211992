import React, { useEffect, useState } from 'react';
import styles from './index.less';
import intl from 'react-intl-universal';
import { formatNDigit, isDataPaltform } from './utils';
import SearchTab from './SearchTab';
import { Table, Pagination } from 'antd';
import { updateQueryInfo } from '../../actions/tableActions';
import { setCurrentPage } from '../../actions/configActions';
import { useDispatch, connect } from 'react-redux';
import { isPartEnd } from '../../utils/urlUtils';
import { uniq } from 'lodash';
import { filterJson, isNoVal } from '../../utils/dataUtils';
import { updateStatisticsQueryInfo, updateSelectedHospital } from '../../actions/statistics';
import formatTimestamp, { GetDateDiff, getNowData } from '../../utils/dateUtils';
import { beautifulTableCol } from './echartInfo';
import BeautifulTableState from './BeautifulTableState.js';
function BeautifulTable(props) {
  const [page, setPage] = useState(0);
  const [tableData,setTableData] =useState([])
  const dispatch = useDispatch();

  useEffect(() => {
    let list = props.data.map((d) => {
      if (['applying','rejected'].includes(d?.state)) {
        return {id:d.id, state: d.state, ip: d.ip, web_port: d.web_port };
      }
      return d;
    });
    setTableData(list)
  }, [props.data]);

  let colHeaders = beautifulTableCol
    .filter((b) => b.part.includes('statisics'))
    .map((col) => {
      return {
        key: col.key,
        dataIndex: col.key,
        title: intl.get(col.name),
        align: 'center',
        render: (_, record) => {
          return (
            <>
              {col.key === 'running_at' && (
                <span>
                  {isNoVal(record.running_at)
                    ? '-'
                    : GetDateDiff(formatTimestamp(record.running_at), getNowData(), 'day')}
                  {col.unit}
                </span>
              )}
              {col.key === 'action' && <BeautifulTableState record={record} />}
              {!['action', 'running_at'].includes(col.key) && (
                <span>
                  {col.data.map((item, index) => {
                    let d = isNoVal(record[item]) ? '-' : formatNDigit(record[item]);
                    return index !== 0 ? col.concentLine + d : d;
                  })}
                  {col.unit}
                </span>
              )}
            </>
          );
        }
      };
    });

  const toImagePage = (record) => {
    dispatch(updateQueryInfo({ machines: record.id, owner: '' }));
    dispatch(setCurrentPage('report'));
    props.history.push('/mainpage');
  };
  const selectRows = (keys, rows) => {
    let selectedRows = props.selected.data;
    let ks = uniq(keys);
    selectedRows = filterJson(selectedRows.concat(rows), 'id').filter((item) => {
      return ks.indexOf(item.id) !== -1;
    });
    props.updateStatisticsQueryInfo({
      ...props.queryInfo,
      machines: ks.length > 0 ? ks : []
    });
    props.updateSelectedHospital({ data: selectedRows, total: ks.length });
  };

  const partEnd = isPartEnd();

  const rowSelection = {
    selectedRowKeys: props.selected.data.map((item) => item.id),
    onChange: selectRows,
    columnWidth: '40px',
    fixed: 'left'
  };
  const isp = isDataPaltform(props.parentName);
  return (
    <div className={isp ? styles.styled__table_out_platform : styles.styled__table_out}>
      <SearchTab isplatform={isp} />
      <Table
        columns={colHeaders}
        rowSelection={rowSelection}
        dataSource={tableData.slice(6 * page, 6 * (page + 1))}
        key={(record) => record.id}
        rowKey={(record) => record.id}
        pagination={false}
        style={{ minHeight: '225px' }}
        className={styles.styled__table}
        rowClassName={partEnd === 'centerEnd' ? styles.style__centerEnd : ''}
        onRow={(record) => ({
          onDoubleClick: () => {
            partEnd === 'centerEnd' && toImagePage(record);
          }
        })}
      />
      <div className={styles.styled__page}>
        <Pagination
          current={page + 1}
          onChange={(page) => setPage(page - 1)}
          size="small"
          total={props.data.length}
          showQuickJumper={true}
          pageSize={6}
          style={{ paddingRight: '10px', float: 'right' }}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    queryInfo: state.statistics.queryInfo,
    selected: state.statistics.selected
  };
};

export default connect(mapStateToProps, { updateStatisticsQueryInfo, updateSelectedHospital })(
  BeautifulTable
);
