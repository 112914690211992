export function formatNDigit(num, n = 2) {
    if (typeof num !== "number") return num
    return isInteger(num) ? num : num.toFixed(n)
}

export function isInteger(num) {
    return num % 1 === 0
}

export function isDataPaltform(parentName){
   return  parentName === 'dataPlatform' ? true : false;
}