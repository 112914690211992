export const SET_CURRENT_IMAGE_INFO = 'SET_CURRENT_IMAGE_INFO';

export const SET_CURRENT_ZOOM = 'SET_CURRENT_ZOOM';

export const loadQueryInfo = (queryInfo) => ({
  type: 'LOAD_QUERY_INFO',
  queryInfo
});

export const setTableLoadingStatus = (loading) => ({
  type: 'LOADING_TABLE',
  loading: loading
});

export const addImages = (images, total,name) => ({
  type: 'ADD_IMAGES',
  images,
  total,
  name
});


export function versionUpdate(version) {
  return {
    type :'VERSION_UPDATE',
    version : version
  };
}

export const setCurrentImageInfo = (currentImageInfo) => ({
  type: SET_CURRENT_IMAGE_INFO,
  currentImageInfo
});

export const setCurrentZoom = (zoom) => ({
  type: SET_CURRENT_ZOOM,
  zoom
});

export const setAnnotationInfoShow = (showAnnotationInfo) => ({
  type: 'SET_ANNOTATION_INFO_SHOW',
  showAnnotationInfo
});
