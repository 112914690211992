import React, { Component } from 'react';
import { connect } from 'react-redux';
// import style from './index.less';
import intl from 'react-intl-universal';
class ProPrintLabel extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div>
        {this.props.selected.list.map((item, index) => {
         return  <div key={index}>{intl.get('PATHOLOGY_ID') + ':' + item.pathology_id}</div>;
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selected: state.production.selected
  };
};

export default connect(mapStateToProps, {})(ProPrintLabel);
