import React, { Component } from 'react';
import { Row, Col, Input, Select } from 'antd';
// import intl from 'react-intl-universal';
import { getInfo } from './ServiceInfo';
import { connect } from 'react-redux';
import styles from './index.less';
import { editService } from '../../actions/service';
import { debounce } from 'lodash';
class ViewSet extends Component {

  constructor(props) {
    super();
    this.state = {
      viewList: {}
    };
  }

  componentDidMount() {
    const { aim } = this.props;
    this.setState({ viewList: aim[0] ? aim[0].configurations.db_info : {} });
  }
  componentDidUpdate(prevProps,prevState){
    if(prevProps.aim!==this.props.aim){
      this.setState({ viewList: this.props.aim[0] ? this.props.aim[0].configurations.db_info : {} });
    }
  }

  onChangeSelect(value, name) {
    const { aim } = this.props;
    this.changeViewList(value, name)
    this.update(aim[0].id, { [name]: value });
  }
  delayedChange = debounce((value, name) => {
    const { aim } = this.props;
    this.update(aim[0].id, { [name]: value });
  }, 500);
  onChangeInput = (event, name) => {
    this.changeViewList(event.target.value, name)
    this.delayedChange(event.target.value, name);
  };

  update(id, p) {
    const { aim } = this.props;
    this.props.editService(id, {
      configurations: {
        db_info: {
          ...aim[0].configurations.db_info,
          ...p
        }
      }
    });
  }

  changeViewList(value,name){
    let info = this.state.viewList
    info[name] = value
    this.setState({
      viewList:{...info}
    })
  }
  render() {
    const info = getInfo();
    // const { aim } = this.props;
    const {viewList} = this.state
    return (
      <Row className={styles.service_view_set}>
        {info.map((item, index) => {
          return (
            <Col key={index} span={item.span} offset={item.offset} className={styles.view_item}>
              <Row gutter={10}>
                <Col span={9} className={styles.view_label}>
                  {item.name}:
                </Col>
                <Col span={15}>
                  {item.type === 'input' && (
                    <Input
                      onChange={(e) => this.onChangeInput(e, item.key)}
                      value={viewList?viewList[item.key]:""}
                    />
                  )}
                  {item.type === 'select' && (
                    <Select
                      className={styles.view_Select}
                      showSearch
                      allowClear
                      onChange={(e) => {
                        this.onChangeSelect(e, item.key);
                      }}
                      placeholder={item.placeholder}
                      value={viewList?viewList[item.key]:""}
                    >
                      {item.options}
                    </Select>
                  )}
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  aim: state.service.HisParty.length>0?state.service.HisParty:[]
});

export default connect(mapStateToProps, { editService })(ViewSet);
