import { Card, Checkbox} from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
// import cfg from '../../config';
// import { allImageListColumns } from '../../utils/displayUtils';
 
import {useDispatch} from 'react-redux'
// import useQueryInfo from '../../hooks/useQueryInfo';
import {saveVisibleConfidence, saveVisibleNegativeLesion, saveVisibleLabel} from '../../actions/configActions';
import {visibleConfidence, visibleNegativeLesion, visibleLabel} from '../../selectors';
export default function Display(props) {
    const dispatch = useDispatch();
    // const {reloadQueryInfo} = useQueryInfo();
    // const onChange = (checkedValue) =>{
    //     saveVisibleColumnsAsync(checkedValue)(dispatch)
    //     reloadQueryInfo(checkedValue)
    // }

    return (
        <Card title={intl.get('DISPALY')}>
            <Checkbox defaultChecked={visibleNegativeLesion()} key="VisibleNegativeLesion" onChange={e=>{
                let checkedValue = e.target.checked;
                saveVisibleNegativeLesion(checkedValue)(dispatch)
            }}>
                {intl.get("NEGATIVELESION")}
            </Checkbox>    
            <br />
            <Checkbox defaultChecked={visibleConfidence()} key="VisibleConfidence" onChange={e=>{
                let checkedValue = e.target.checked;
                saveVisibleConfidence(checkedValue)(dispatch)
            }}>
                {intl.get(("Confidence").toUpperCase())}
            </Checkbox>       
            <br />
            <Checkbox defaultChecked ={visibleLabel()} key="VisibleLabel" onChange={e=>{
                let checkedValue = e.target.checked;
                saveVisibleLabel(checkedValue)(dispatch)
            }}>
                {intl.get(("Label").toUpperCase())}
            </Checkbox> 
        </Card>
    );
}

Display.propTypes = {};
  