export const menuList1 = [
  {
    label: 'WAIT_DIAGNOSIS',
    icon: 'chuzhenactive',
    bgColor: 'linear-gradient(46.86deg, #FB6C95 7.96%, #F9B8B1 97.74%)',
    tag:true,
    currentTab:0,
    query:{ distribute_stage:'3',stage: "3,4,5"},
  },
  {
    label: 'RE_DIAGNOSIS',
    icon: 'yizhenduanactive',
    bgColor: 'linear-gradient(46.86deg, #42DDBE 7.96%, #CAFFC9 97.74%)',
    tag:true,
    currentTab:2,
    query: { distribute_stage:'3',stage: "8"}
  },
  {
    label: 'VISUALIZATION',
    icon: 'tongjiactive',
    bgColor: 'linear-gradient(46.86deg, #4284FC 7.96%, #A7CFFC 97.74%)',
    tag:false,
    url:'/preference/workStatistics'
  }
];
export const menuList2 = [
  {
    label: 'RE_TIMEOUT',
    icon: 'chaoshiactive',
    bgColor: ' linear-gradient(46.86deg, #FE462E 7.96%, #FFB494 97.74%)',
    tag:true,
    currentTab:3,
    query: {distribute_stage:"4,5"},
  },
  {
    label: 'ALL',
    icon: 'quanbuactive',
    bgColor: 'linear-gradient(46.86deg, #2F55F1 7.96%, #76D2FA 97.74%)',
    tag:true,
    currentTab:4,
    query: {},
  },
  {
    label: 'SETTING',
    icon: 'settingactive',
    bgColor: 'linear-gradient(46.86deg, #1AB7E1 7.96%, #B4F2FF 97.74%)',
    tag:false,
    url:'/preference/profile'
  }
];


