import { message } from 'antd';
import { findIndex, isEqual } from 'lodash';
import intl from 'react-intl-universal';
import { call, put, select, take } from 'redux-saga/effects';
import { getTabs } from '../selectors/tabSelectors';
import { rawToStatistics, sum } from '../utils/analysisUtils';
import downloadCsv from '../utils/csvUtils';
import { fetchAnalysisResult, fetchExportAnalysisResult } from './api';
import { addTab, removeTab, toggleTab } from './tabActions';

const LOAD_IMAGE_NILM_GRADING = 'LOAD_IMAGE_NILM_GRADING';

export const loadImageFromNILMGrading = (nr, callback) => ({
  type: LOAD_IMAGE_NILM_GRADING,
  nr,
  onSuccess: callback || null
});

function* removeTabForNR(nr) {
  const tabs = yield select(getTabs);
  const index = findIndex(tabs, (t) => isEqual(t.query.prob, String(nr)));
  if (index !== -1) yield put(removeTab(index));
}

function* addTabForNR(nr) {
  yield call(removeTabForNR, nr);
  yield put(
    addTab({
      text: intl.get('PROB_LT_NR', { nr: `${nr.toFixed(2)}%` }),
      origin: false,
      query: { prob: String(nr), sortBy: 'prob', sortAsc: false }
    })
  );
  const tabs = yield select(getTabs);
  yield put(toggleTab(tabs.length - 1));
}

function* handleLoadNILMGrading(nr) {
  yield call(addTabForNR, nr);
}

export function* watchLoadImageNILMGrading() {
  while (true) {
    const action = yield take(LOAD_IMAGE_NILM_GRADING);
    const { nr, onSuccess } = action;
    yield call(handleLoadNILMGrading, nr);
    yield call(onSuccess);
  }
}

export function toggleLoadingAnalysisPage(loading) {
  return { type: 'LOADING_ANALYSIS_PAGE', loading };
}

export function exportAnalysisResult(payload) {
  return function (dispatch) {
    dispatch(toggleLoadingAnalysisPage(true));
    fetchExportAnalysisResult(payload)
      .then((resp) => {
        if (resp.status === 200) downloadCsv(resp.data, {}, 'analysis.csv','text/plain;charset=utf-8');
        else message.error(intl.get('NETWORK_ERROR'));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(toggleLoadingAnalysisPage(false));
      });
  };
}

function updateStatistics(raw, clazz = 'cervical') {
  const statistics = rawToStatistics(raw, clazz);
  return { type: 'UPDATE_STATISTICS', statistics, tnr: raw.cell.tnr, nr: raw.cell.minus_neg };
}

function updateNegativeAnalysis(raw) {
  const data = raw.cell.negative_analysis;
  return { type: 'UPDATE_NEGATIVE_ANALYSIS', negativeAnalysis: data };
}

function updateMatrixAnalysis(raw, clazz = 'cervical') {
  let matrixes = null;
  const { classify } = raw.matrix;
  const tn = classify[0][0];
  const fn = sum(classify[0].slice(1));
  const fp = sum(classify.slice(1).map((item) => item[0]));
  const tp = sum(classify.slice(1).map((item) => sum(item.slice(1))));
  const bin = [
    [tn, fn],
    [fp, tp]
  ];
  if (clazz === 'cervical') matrixes = [bin, raw.matrix.crude, classify, raw.matrix.microbe];
  else matrixes = [bin, classify];
  return { type: 'UPDATE_MATRIX_ANALYSIS', matrixes };
}

export function getAnalysisResult(payload) {
  return function (dispatch) {
    dispatch(toggleLoadingAnalysisPage(true));
    fetchAnalysisResult(payload)
      .then((resp) => {
        const { clazz } = payload;
        if (resp.data.code === 1000) {
          dispatch(updateStatistics(resp.data.result.statistic, clazz));
          if (clazz === 'cervical') dispatch(updateNegativeAnalysis(resp.data.result.statistic));
          dispatch(updateMatrixAnalysis(resp.data.result, clazz));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(toggleLoadingAnalysisPage(false));
      });
  };
}

export function clearAnalysis() {
  return { type: 'CLEAR_ANALYSIS' };
}
