import React, { Component } from 'react';
import BaseChart from './BaseChart';
import style from './index.less';
import LoadingSpin from './LoadingSpin';
import intl from 'react-intl-universal';
import {titleStyle,girdInfo} from "./echartInfo"
import {isDataPaltform} from "./utils"
export class IncidenceRatePla extends Component {
  getOption() {
    const { data,otherStyle } = this.props;
    let option = {
      title: {
        left: 'left',
        text: intl.get('INCIDENCE_RATE'),
        top: 10,
        textStyle: titleStyle
      },
      grid:girdInfo,
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c}%'
      },
      xAxis: {
        data:data.map(i=>i.age),
        type: 'category',
        name: intl.get('CHECK_IN_YEAR'),
        boundaryGap: false,
        axisLabel: {
          interval: 0,
          textStyle: {
            color: otherStyle.xyColor,
            fontSize: 10
          }
        },
        nameTextStyle: {
          color: otherStyle.xyColor,
            fontSize: 10
        },
      },
      yAxis: {
        type: 'value',
        splitLine: {
          show: false
        },
        axisLabel: {
          interval: 0,
          textStyle: {
            color: otherStyle.xyColor,
            fontSize: 10
          }
        },
        nameTextStyle: {
          color: otherStyle.xyColor,
            fontSize: 10
        },
      },
      series: {
        // name: item.name,
        data: data.map(i=>(i.data*100).toFixed(2)),
        type: 'line',
        symbol:'circle',
        symbolSize: 8,
        lineStyle: {
          color:'#129CFF',
          width: 2
        },
        itemStyle: {
          color:'#fff',
          borderWidth: 3,
          borderColor: '#129CFF',
          
        },
        areaStyle:{
            color:{
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                    offset: 0, color: 'rgba(37, 105, 183, 0.85)',
                }, {
                    offset: 0.6, color: 'rgba(37, 85, 147, 0.09)', 
                }],
            },
        },
      }
    };

    return option;
  }

  render() {
    const { data, parentName ,otherStyle} = this.props;
    const isDataPlatform = isDataPaltform(parentName);
    return (
      <div className={isDataPlatform?style.chart_container_incidence:style.chart_container}>
        {data ? (
          <BaseChart style={{ height: otherStyle.height, width: '100%' }} option={this.getOption()} />
        ) : (
          <LoadingSpin />
        )}
      </div>
    );
  }
}

export default IncidenceRatePla;
