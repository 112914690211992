import React, { Component } from 'react';
import DetailsOfAnnotations from '../DetailsOfAnnotations';
import style from './index.less';

class Details extends Component {
  render() {
    return (
      <div className={style.details}>
        <DetailsOfAnnotations onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave} />
      </div>
    );
  }
}

export default Details;
