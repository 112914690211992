import React, { Component } from 'react';
import PatientInfo from './PatientInfo';
import ReportTitle from './ReportTitle';
import SampleQuality from './SampleQuality';
import SampleCells from './SampleCells';
import SampleReport from './SampleReport';
import ReportTail from './ReportTail';
import { Divider, Button, Icon, message } from 'antd';
import ReactToPrint from 'react-to-print';
import { connect } from 'react-redux';
import {
  saveReportPrinted,
  saveReportResult,
  submitReportResult,
  setHistoryVisible,
  rdistributeDoctor,
  updateRolesUsers
} from '../../actions/reportAction';
import style from './index.less';
import intl from 'react-intl-universal';
import {
  updateCurrentImageInfo,
  pipelineEdited,
  pipelineEditing
} from '../../actions/imageActions';
import { selectEditReportDisabled } from '../../selectors/reportSelector';
import HistoryInfo from './HistoryInfo';
import CancelReport from './CancelReport';
import { getReportConf } from '../../actions/reportConfAction';
import { fetchGeneralConfig } from '../../actions/configActions';
import CheckInfoBtn from '../Menubar/CheckInfoBtn';
import SubmitBtn from './SubmitBtn';
function isReportSettingsPage() {
  return window.location.pathname.includes('/preference/reportsettings');
}

class Report extends Component {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    this.state = {
      printing: false,
      editing: '',
      openRegistrationForm: false
    };
  }

  componentDidMount() {
    const isSettingsPage = isReportSettingsPage();
    this.props.getReportConf();
    this.props.fetchGeneralConfig();
    this.props.updateRolesUsers();
    this.setState({ isSettingsPage });
    this.props.setHistoryVisible(false);
  }
  componentDidUpdate(prevProps, prevState) {
    let leftSider = document.querySelector('.ant-layout-sider');
    if (leftSider) {
      if (this.props.historyVisible) {
        leftSider.style.overflow = 'visible';
      } else {
        leftSider.style.overflowX = 'hidden';
      }
    }
    if (prevProps.currentImageInfo !== this.props.currentImageInfo) {
      this.editingRep();
    }
  }

  onChange = (params) => {
    if (this.state.isSettingsPage) return;
    let imageId = this.props.currentImageKey;
    let p = params;
    p.patient !== undefined && delete p.patient.pathology_id;
    this.props.saveReportResult(imageId, p);
  };

  onOpenRegistrationForm = (opend, changed=false)=>{
    this.setState({openRegistrationForm: opend});
    if(changed){
      this.onChange({})
    }    
  }

  handleEdit = (status) => {
    this.setState({
      editing: status
    });
  };
  handleSavePatientId = (pathology_id) => {
    this.setState({
      editing: 'loading'
    });
    const { patient } = this.props.report;
    let imageId = this.props.currentImageKey;
    this.props
      .saveReportResult(imageId, {
        patient: { ...patient, pathology_id: pathology_id }
      })
      .then((res) => {
        this.props.updateCurrentImageInfo({ patient_tag_id: pathology_id }, imageId);
        this.setState({
          editing: ''
        });
      })
      .catch((err) => {
        this.setState({
          editing: ''
        });
      });
  };

  editingRep = () => {
    let imageId = this.props.currentImageKey;
    let stage = this.props.currentImageInfo.stage;
    stage === 3 && !this.props.disabled && this.props.pipelineEditing(imageId);
  };

  handleSubmitReport = () => {
    let imageId = this.props.currentImageKey;
    let stage = this.props.currentImageInfo.stage;
    const { comments, diagnosis, patient, sample } = this.props.report;

    if (diagnosis.length === 0) {
      message.warning(intl.get('REPORT_IS_NULL'));
      return;
    }
    [3, 4, 5, 6, 7].includes(stage) &&
      this.props
        .saveReportResult(
          imageId,
          {
            diagnosis,
            patient,
            sample,
            comments
          },
          'noMessage'
        )
        .then(() => {
          this.props.pipelineEdited(imageId, 'report');
        });

    [8].includes(stage) && this.props.submitReportResult(imageId, 'report');
  };

  handleSaveReportPrinted = () => {
    let imageId = this.props.currentImageKey;
    const { diagnosis, patient, sample, comments } = this.props.report;
    this.props.saveReportPrinted(imageId, { diagnosis, patient, sample, comments });
  };

  getPlanReporter = () => {
    let imageId = this.props.currentImageKey;
    let v = this.props.planReporter.filter((item) => item.imageId === imageId);
    let defaultPlanReporter = '';
    v.length && (defaultPlanReporter = v[0].reporter);
    return defaultPlanReporter;
  };

  changeReporter = () => {
    let imageId = this.props.currentImageKey;
    let plan = this.getPlanReporter();
    if (plan !== '') {
      this.props.rdistributeDoctor(imageId, { reporter: plan }, 'report');
    }
  };
  render() {
    const { printing, isSettingsPage, editing } = this.state;
    const {
      reportConf,
      report,
      disabled,
      currentImageInfo,
      historyVisible,
      currentUser
    } = this.props;
    const { sample, diagnosis, patient, comments, interpretation } = report;
    let labels = diagnosis && diagnosis.labels ? diagnosis.labels : [];
    let plan = this.getPlanReporter();
    const notCancel = !currentImageInfo.rescan;
    return (
      <>
        <div
          className={
            isSettingsPage
              ? style.reportContainer_setting
              : disabled
              ? style.reportContainerDisabled
              : style.reportContainer
          }
        >
          <div className={style.print_all} ref={this.componentRef}>
            <ReportTitle
              editing={editing}
              enableEdit={!disabled}
              handleEdit={this.handleEdit}
              handleSavePatientId={this.handleSavePatientId}
              isSettingsPage={isSettingsPage}
              patient={patient}
              reportConf={reportConf}
            />
            <Divider className={style.divider} style={{ height: '2px' }} />
            <PatientInfo
              editing={editing}
              patient={patient}
              reportConf={reportConf}
              onChange={this.onChange}
            />

            {historyVisible ? <HistoryInfo /> : ''}
            <Divider className={style.divider} />
            <SampleQuality
              labels={labels}
              sample={sample}
              reportConf={reportConf}
              onChange={this.onChange}
            />
            <Divider className={style.divider} />
            <SampleCells
              sample={sample}
              labels={labels}
              isSettingsPage={isSettingsPage}
              reportConf={reportConf}
              printing={printing}
              onChange={this.onChange}
            />
            <SampleReport
              interpretation={interpretation}
              labels={labels}
              sample={sample}
              comments={comments}
              onChange={this.onChange}
              reportConf={reportConf}
              printing={printing}
            />
            <Divider className={style.divider} />
            <ReportTail report={report} reportConf={reportConf} printing={printing} />
          </div>
          <div className={disabled ? style.report__actions__disabled : style.report__actions}>
            {!isSettingsPage && (
              <SubmitBtn
                currentImageInfo={currentImageInfo}
                editing={editing === 'loading'}
                handleSubmitReport={this.handleSubmitReport.bind(this)}
              />
            )}
            {notCancel && (
              <ReactToPrint
                trigger={() => (
                  <Button disabled={editing === 'loading'} type="primary">
                    <Icon type="printer" />
                    {intl.get('PRINT_REPORT')}
                  </Button>
                )}
                content={() => this.componentRef.current}
                onBeforeGetContent={() => {
                  this.handleSaveReportPrinted();
                  return new Promise((resolve, reject) => {
                    this.setState({ printing: true }, () => resolve());
                  });
                }}
                onAfterPrint={() => this.setState({ printing: false })}
              />
            )}

            <CancelReport image={currentImageInfo} report={report} />
            {[6, 7].includes(currentImageInfo.stage) && (
              <Button
                type="primary"
                disabled={
                  plan === currentUser.id || [undefined, null, ''].includes(plan) ? true : false
                }
                className={style.change}
                onClick={this.changeReporter}
              >
                {intl.get('CHANGE_DOCTOR')}
              </Button>
            )}
          </div>
          {currentImageInfo.rescan && (
            <div className={style.has_cancel}>
              <Button type="danger">{intl.get('HAD_CANCEL')}</Button>
            </div>
          )}
          {disabled ? <div className={style.watermark}>{intl.get('REPORT_OCCUPIED')}</div> : null}
        </div>
        {disabled ? undefined:
        <div className={isSettingsPage ? style.check_info_btn_setting : style.check_info_btn}>
          <CheckInfoBtn openRegistrationForm={this.state.openRegistrationForm} onOpenRegistrationForm={this.onOpenRegistrationForm} />
        </div>}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentImageKey: state.imageSelection.currentImageKey,
  currentImageInfo: state.imageSelection.currentImageInfo || {},
  reportConf: state.reportConf,
  report: state.report,
  disabled: selectEditReportDisabled(state),
  planReporter: state.report.planReporter,
  historyVisible: state.report.historyVisible,
  currentUser: state.currentUser
});

export default connect(mapStateToProps, {
  saveReportResult,
  saveReportPrinted,
  submitReportResult,
  updateCurrentImageInfo,
  pipelineEdited,
  setHistoryVisible,
  rdistributeDoctor,
  getReportConf,
  fetchGeneralConfig,
  updateRolesUsers,
  pipelineEditing
})(Report);
