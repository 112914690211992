import React, { Component } from 'react';
import style from "./index.less"
// import { Decoration10 } from '@jiaminghi/data-view-react'
class BottomPart extends Component {
  render() {
    return (
      <div className={style.bottom_style} style={{width:this.props.width}}/>
    );
  }
}

export default BottomPart;
