import autoCtrlStatus from '../static/autoCtrlStatus';
export function formAutoCtrl(imageLeve, mohu, yanxing, yiwu, shangpixibao) {
  let query = {
    image_level: imageLeve,
    mohu: mohu,
    yanxing: yanxing,
    yiwu: yiwu,
    shangpixibao: shangpixibao
  };
  let l = [];
  if (query.image_level === 'H') {
    l = autoCtrlStatus.filter((i) => i.value === '1');
  } else {
    if (query.shangpixibao === true) {
      if (query.mohu !== '') {
        l = autoCtrlStatus.filter((i) => i.query.mohu === query.mohu);
      } else {
        if (query.yiwu !== '' || query.yangxing !== '') {
          l = autoCtrlStatus.filter(
            (i) => i.query.yiwu === query.yiwu && i.query.yanxing === query.yanxing
          );
        } else {
          l = [{ value: '', color: 'orange', translate: 'GENEREL' }];
        }
      }
    } else {
      l = autoCtrlStatus.filter((i) => i.value === '8');
    }
  }
  return l.length > 0 ? l[0] : { value: '', color: 'orange', translate: 'GENEREL' };
}

export function formAutoCtrl2(imageLeve, mohu, yanxing, yiwu, shangpixibao) {
  let query = {
    image_level: imageLeve,
    mohu: mohu,
    yanxing: yanxing,
    yiwu: yiwu,
    shangpixibao: shangpixibao
  };
  let l = { value: '', color: 'green', translate: '' };
  if (query.image_level === 'H') {
    l = { value: '', color: 'green', translate: 'AUTO_IMAGE_SATISFACTORY' };
  } else {
    // l = { value: '', color: 'red', translate: 'AUTO_IMAGE_UNSATISFACTORY' };
    l.color ='orange';    
    l.translate += shangpixibao ? 'AUTO_CTRL_NUMS_LESS' : 'AUTO_CTRL_NUMS_GOOD';
    l.translate +=
    mohu === 'L'
      ? ',SCAN_QUAILITY_GOOD'
      : mohu === 'M'
      ? ',SCAN_QUAILITY_VAGUE'
      : ',SCAN_QUAILITY_BAD';
    l.translate += yanxing==='H' ? ',CELL_SHELTER' : '';
  }
  return l
}
