import React, { Component } from 'react';
import { connect } from 'react-redux';
import style from './index.less';
import intl from 'react-intl-universal';
import { isPartEnd } from '../../utils/urlUtils';
import { Button, Icon } from 'antd';
class SubmitBtn extends Component {
  render() {
    const { currentImageInfo, editing } = this.props;
    return [8].includes(currentImageInfo.stage) && isPartEnd() === 'expert' ? (
      <Button disabled={true} className={style.save__report} type="default">
        {intl.get('HSD_REVIEWS')}
      </Button>
    ) : (
      <Button
        disabled={editing}
        onClick={this.props.handleSubmitReport}
        className={style.save__report}
        type="primary"
      >
        <Icon type="save" />
        {[3, 4, 5].includes(currentImageInfo.stage) && intl.get('REPORT_SUBMIT')}
        {[6, 7].includes(currentImageInfo.stage) && intl.get('SUBMIT_SENIOR')}
        {[8].includes(currentImageInfo.stage) &&  intl.get('PUSH_SUBMIT')}
      </Button>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});
export default connect(mapStateToProps, {})(SubmitBtn);
