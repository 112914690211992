import React, { Component } from 'react';
// import PositiveRate from './PositiveRate';
// import ChinaMap from './ChinaMap';
// import QualityControl from './QualityControl';
import { connect } from 'react-redux';
import { initStatis } from '../../actions/statistics';
import style from './index.less';
import { Col, Row } from 'antd';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router-dom';
import Algo from './Algo';
import ConsistencyPlatform from './ConsistencyPlatform';
import QualifiedRate from './QualifiedRate';
import AnnualWork from './AnnualWork';
import DataMap from './DataMap';
import DataTable from './DataTable';
import BottomPart from './BottomPart';
import AgeData from './AgeData';
import DisplayPart from './DisplayPart';
import PositivePart from './PositivePart';
import back from '../../static/images/back.png';
class Visualization extends Component {
  componentDidMount() {
    this.props.initStatis();
  }
  backToPage(){
    this.props.history.push('/preference/monitor');
  }
  render() {
    const {
      summary,
      workload,
      positive,
      consistency,
      distribution,
      performance,
      qualityMonth,
      // incidence,
      selected,
      incidenceAll
    } = this.props.statistics;
    return (
      <div className={style.data_platform}>
        <div className={style.data_platform_name}>
          {intl.get('TRADEMARK')}
          <sup>®</sup>
          {intl.get('DATA_PLATFORM_NAME')}
          <div className={style.data_platform_back} onClick={this.backToPage.bind(this)}>
            <img src={back} />
            <span>{intl.get('BACK')}</span>
          </div>
        </div>
        <div className={style.data_platform_content}>
          <Row>
            <Col span={7} className={style.data_platform_col}>
              <Algo data={performance} />
              <ConsistencyPlatform data={consistency} />
              <QualifiedRate data={qualityMonth} />
              <AnnualWork data={workload} />
              <BottomPart width="90%" />
            </Col>
            <Col span={10} className={style.data_platform_col}>
              <DataMap data={summary} selected={selected} />
              <DataTable data={summary} history={this.props.history} />
              <BottomPart width="100%" />
            </Col>
            <Col span={7} className={style.data_platform_col}>
              <AgeData data={incidenceAll} />
              <DisplayPart data={distribution} />
              <PositivePart data={positive} />
              <BottomPart width="90%" />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  statistics: state.statistics
});

export default connect(mapStateToProps, {
  initStatis
})(withRouter(Visualization));
