import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from '../TableTabs/DateSearchContainer.less';
import intl from 'react-intl-universal';
import moment from 'moment/moment';
import { GetDateDiff } from '../../utils/dateUtils';
const configs = ['HALF_YEAR', 'ONE_YEAR', 'TWO_YEAR', 'DATE_UNLIMIT'];

function getRangeDate(num) {
  let day = new Date();
  day.setDate(day.getDate() - num);
  let dateFrom = moment(day).format('YYYY-MM-DD') + ' 00:00:00';
  let dateTo = moment(new Date().getTime()).format('YYYY-MM-DD') + ' 23:59:59';
  return {
    From: dateFrom,
    To: dateTo
  };
}

export const DateSearch = (props) => {
  const [activeKey, setActiveKey] = useState('');

  useEffect(() => {
    let nowDate = moment(new Date().getTime()).format('YYYY-MM-DD') + ' 23:59:59';
    if (props.dateRange.To === nowDate) {
      let dayDif = GetDateDiff(props.dateRange.From, nowDate, 'day');
      if (dayDif === 179) {
        setActiveKey('HALF_YEAR');
      } else if (dayDif === 364) {
        setActiveKey('ONE_YEAR');
      } else if (dayDif === 729) {
        setActiveKey('TWO_YEAR');
      } else {
        setActiveKey('');
      }
    } else {
      setActiveKey('');
    }
    if (!props.dateRange.From || !props.dateRange.To) {
      setActiveKey('DATE_UNLIMIT');
    }
    return;
  }, [props.dateRange.From, props.dateRange.To]);

  const onChange = (key) => {
    let rangeDate = { From: '', To: '' };

    switch (key) {
      case 'HALF_YEAR':
        rangeDate = getRangeDate(179);
        break;
      case 'ONE_YEAR':
        rangeDate = getRangeDate(364);
        break;
      case 'TWO_YEAR':
        rangeDate = getRangeDate(729);
        break;
      default:
        break;
    }
    setActiveKey(key);
    props.parent.changeDataRange({ ...rangeDate });
  };

  return (
    <div className={props.isplatform?styles.date__search__div_plat:styles.date__search__div}>
      <ul>
        {configs.map((key) => {
          return (
            <li
              key={key}
              onClick={() => onChange(key)}
              className={activeKey === key ? styles.active__li : styles.normal__li}
            >
              {intl.get(key)}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(DateSearch);
