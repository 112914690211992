import { Dropdown, Menu, Badge } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Icon from '../Iconfont';
import style from './Bell.less';
import BellMsg from './BellMsg';
import { updateSelectedImages } from '../../actions/imageActions';
import intl from 'react-intl-universal';
class BellBtn extends Component {
  constructor() {
    super();
    this.state = {
      showMenu: false
    };
  }
  menu = () => {
    return (
      <Menu style={{ right: '15px' }}>
        <Menu.Item style={{ cursor: 'auto' }}>{intl.get('TIMEOUT_LIST_TITLE')}</Menu.Item>
        <Menu.Item>
          <BellMsg />
        </Menu.Item>
      </Menu>
    );
  };

  changeSelected = () => {
    const { notReadTimeoutList } = this.props;
    let show = this.state.showMenu;
    if (show) {
      this.props.updateSelectedImages({ data: [], total: 0 });
    } else {
      this.props.updateSelectedImages({
        data: notReadTimeoutList.data,
        total: notReadTimeoutList.data.length
      });
    }
    this.setState({
      showMenu: !show
    });
  };
  render() {
    const { notReadTimeoutList } = this.props;
    const dataList = notReadTimeoutList.data;
    const { showMenu } = this.state;
    return (
      <Dropdown
        key="dropdown"
        placement="bottomLeft"
        overlay={this.menu()}
        visible={showMenu}
        className={style.bell}
        onVisibleChange={this.changeSelected}
      >
        {dataList !== undefined ? (
          <span>
            <span>
              <Icon type="icon-gongzuotai" className={style.bellIcon} />
            </span>
            <span  className={style.bellIcon_info}>{intl.get('INFO_CENTER')}</span>
            <Badge
              count={dataList.length}
              overflowCount={99}
              showZero={false}
              className={style.bellBadge}
              offset={dataList.length > 9 ? [8, 0] : ''}
            />
          </span>
        ) : (
          ''
        )}
{/* 
        {dataList !== undefined ? (
          <Badge
            count={dataList.length}
            overflowCount={99}
            showZero={false}
            className={style.bellBadge}
            offset={dataList.length > 9 ? [8, 0] : ''}
          >
            <span>
              <Icon type="icon-gongzuotai" className={style.bellIcon} />
              <span>{intl.get('INFO_CENTER')}</span>
            </span>
          </Badge>
        ) : (
          ''
        )} */}
      </Dropdown>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    timeoutList: state.timeout.timeoutList,
    notReadTimeoutList: state.timeout.notReadTimeoutList
  };
};

export default connect(mapStateToProps, { updateSelectedImages })(BellBtn);
