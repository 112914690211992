import React from 'react';
import intl from 'react-intl-universal';
import { setValueOptions } from '../../../utils/dataUtils';
import RegAction from './RegAction';
import StopDis from './StopDis';
import store from '../../../store';
// import { getUserUnits } from '../../../selectors/userSelectors';
// import { getRegister } from '../../../selectors/settingSelectors';
import { getOrganization } from '../../../selectors/settingSelectors';
import { validatePwd, validatePhone, validateEmail } from '../../../utils/regUtils';
import ReportSource from './ReportSource';
import { isPartEnd } from '../../../utils/urlUtils';

const expertPart = ['expert'];
const userPart = ['user'];
const allPart = [...expertPart, ...userPart];
export function setRegisterColums() {
  return [
    {
      title: intl.get('ACCOUNT_LABEL'),
      width: 80,
      dataIndex: 'username',
      key: 'username',
      align: 'center',
      partName: allPart
    },
    {
      title: intl.get('ACCOUNT_NAME'),
      width: 80,
      dataIndex: 'realname',
      align: 'center',
      key: 'realname',
      partName: allPart
      // fixed: 'left'
    },
    {
      title: intl.get('ACCOUNT_ACTOR'),
      width: 100,
      dataIndex: 'roleName',
      align: 'center',
      key: 'roles',
      partName: allPart
    },
    {
      title: intl.get('ACCOUNT_UNITS'),
      dataIndex: 'departmentName',
      key: 'departmentName',
      align: 'center',
      width: 100,
      partName: userPart
    },
    {
      title: intl.get('ACCOUNT_TEL'),
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      width: 80,
      partName: allPart
    },
    {
      title: intl.get('ACCOUNT_EMAIL'),
      dataIndex: 'email',
      key: 'email',
      align: 'center',
      width: 120,
      partName: allPart
    },
    {
      title: intl.get('EXPERT_SOURCE'),
      dataIndex: 'groups',
      key: 'groups',
      align: 'center',
      width: 160,
      partName: expertPart,
      render: (_, record) => {
        return <ReportSource infoList={record} />;
      }
    },
    {
      title: intl.get('STOP_DIS'),
      dataIndex: 'distributable',
      key: 'distributable',
      align: 'center',
      width: 90,
      partName: expertPart,
      render: (_, record) => {
        return <StopDis infoList={record} />;
      }
    },
    {
      title: intl.get('ACTION'),
      dataIndex: '',
      key: '',
      width: 90,
      align: 'center',
      partName: allPart,
      render: (_, record) => {
        return <RegAction infoList={record} />;
      }
    }
  ];
}

export const expertValue = 'general_doctor|expert|viewer|general';
export const actorList = [
  {
    value: 'ai|general',
    label: 'AI'
  },
  {
    value: 'register|general',
    label: 'REGISTER_ACTTOR'
  },
  {
    value: 'validator|viewer|distributor|general',
    label: 'DISTRIBUTOR_ACTTOR'
  },
  {
    value: 'producer|general',
    label: 'PRODUCER_ACTTOR'
  },
  {
    value: 'validator|general_doctor|viewer|junior_doctor|general',
    label: 'JUNIOR_DOCTOR_ACTTOR'
  },
  {
    value: 'validator|general_doctor|viewer|senior_doctor|general',
    label: 'SENIOR_DOCTOR_ACTTOR'
  },
  {
    value: 'report_exporter|general',
    label: 'REPORTER_ACTOR'
  },
  {
    value:
      'register|validator|distributor|producer|viewer|general_doctor|junior_doctor|senior_doctor|admin|general|hr',
    label: 'ADMIN_ACTTOR'
  },
  {
    value: 'devops|hr',
    label: 'DEVOPS_ACTTOR'
  },
  {
    value:
      'devops|register|validator|distributor|producer|viewer|general_doctor|junior_doctor|senior_doctor|admin|general|hr|super',
    label: 'SUPER_ADMIN_ACTTOR'
  },
  {
    value: expertValue,
    label: 'EXPERT'
  }
];
const setActorListNoAI = actorList.filter((i) => i.label !== 'AI');
const setActorListNoAIANDExp = setActorListNoAI.filter((i) => i.label !== 'EXPERT');
export function setRoles(user) {
  let labelsList = [];
  let valueList = [];
  actorList.forEach((act) => {
    let actRoles = act.value.split('|');
    for (let i = 0; i < actRoles.length; i++) {
      if (!user.roles.includes(actRoles[i])) {
        break;
      }
      if (i === actRoles.length - 1) {
        labelsList.push(intl.get(act.label));
        valueList.push(act.value);
      }
    }
  });
  return { label: labelsList.join('/'), value: valueList };
}

export function setDepartmentIdByLabel(user) {
  let units = getOrganization(store.getState()).organizationList;
  let l = units.filter((i) => i.label === user.departmentName);
  if (l.length > 0) {
    return l[0].value;
  } else {
    return undefined;
  }
}

export function getBeforeAccount() {
  return [
    {
      name: intl.get('ACCOUNT_LABEL'),
      type: 'input',
      key: 'username',
      rules: [
        {
          required: true,
          pattern: /^\S{1,20}$/g,
          message: intl.get('ACCOUNT_LENGTH')
        },
        {
          pattern: /^(?!ai).*/g,
          message: intl.get('ACCOUNT_AI')
        }
      ],
      maxLength: 20,
      partName: allPart
    },

    {
      name: intl.get('ACCOUNT_NAME'),
      type: 'input',
      key: 'realname',
      rules: [
        {
          required: true,
          pattern: /^\S{1,20}$/g,
          message: intl.get('USERNAME_LENGTH')
        }
      ],
      maxLength: 20,
      partName: allPart
    },
    {
      name: intl.get('ACCOUNT_ACTOR'),
      type: 'select',
      key: 'roles',
      options: setValueOptions(
        'select',
        isPartEnd === 'centerEnd' ?  setActorListNoAI: setActorListNoAIANDExp
      ),
      optionsList: setActorListNoAI,
      defaultValue: '',
      mode: 'multiple',
      rules: [
        {
          required: true,
          message: intl.get('ACTOR_NULL')
        }
      ],
      partName: userPart
    },
    {
      name: intl.get('ACCOUNT_UNITS'),
      type: 'select',
      key: 'department',
      mode: 'single',
      options: setValueOptions(
        'select',
        getOrganization(store.getState()).organizationList,
        'ownLabels'
      ),
      optionsList: getOrganization(store.getState()).organizationList,
      defaultValue: '',
      rules: [
        {
          required: true,
          message: intl.get('UNITS_NULL')
        }
      ],
      partName: userPart
    }
  ];
}

export function getAfterAccount() {
  return [
    {
      name: intl.get('ACCOUNT_TEL'),
      type: 'input',
      key: 'phone',
      rules: [
        {
          ...validatePhone,
          message: intl.get('TEL_ERROR')
        }
      ],
      maxLength: 11,
      partName: allPart
    },
    {
      name: intl.get('ACCOUNT_EMAIL'),
      type: 'input',
      key: 'email',
      rules: [
        {
          ...validateEmail,
          message: intl.get('EMAIL_ERROR')
        }
      ],
      maxLength: 30,
      partName: allPart
    }
  ];
}

export function getAddAccount() {
  return [
    ...getBeforeAccount(),

    {
      name: intl.get('ACCOUNT_PWD'),
      type: 'password',
      key: 'password',
      rules: [
        {
          required: true,
          message: intl.get('PASSWORD_FORMAT')
        },
        {
          ...validatePwd,
          message: intl.get('PASSWORD_FORMAT')
        }
      ],
      maxLength: 16,
      partName: allPart
    },
    {
      name: intl.get('ACCOUNT_RE_PWD'),
      type: 'password',
      key: 'repwd',
      rules: [
        {
          required: true,
          message: intl.get('REPWD_ERROR')
        }
        // { validator: compareToFirstPassword }
      ],
      maxLength: 16,
      partName: allPart
    },
    {
      name: intl.get('ACCOUNT_ADMIN_KEY'),
      type: 'input',
      key: 'admin_key',
      rules: [
        {
          required: true,
          pattern: /^.{1,20}$/g,
          message: intl.get('ADMIN_KEY_LENGTH')
        }
      ],
      maxLength: 20,
      partName: allPart
    },
    ...getAfterAccount()
  ];
}

export function getEditAccount() {
  return [
    ...getBeforeAccount(),

    {
      name: intl.get('ACCOUNT_PWD'),
      type: 'password',
      key: 'password',
      rules: [
        {
          ...validatePwd,
          message: intl.get('PASSWORD_FORMAT')
        }
      ],
      partName: allPart
    },
    {
      name: intl.get('ACCOUNT_RE_PWD'),
      type: 'password',
      key: 'repwd',
      rules: [
        // { validator: compareToFirstPassword }
      ],
      partName: allPart
    },
    ...getAfterAccount()
  ];
}

export function setFailedReasonTitle() {
  return [
    { name: 'ACCOUNT_LABEL', span: 6 },
    { name: 'ACCOUNT_NAME', span: 6 },
    { name: 'FAILED_REASON', span: 12 }
  ];
}

export function getRegisterPathName() {
  const pathname = window.location.pathname;
  if (pathname === '/preference/expertManagement') return 'expert';
  if (pathname === '/preference/registerAccount') return 'user';
}
