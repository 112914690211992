/* jshint esversion: 6 */
import React from 'react';
import { Layout } from 'antd';
import styles from './SiderMain.module.css';
const { Sider } = Layout;

function SiderMain(props) {
  return (
    <Sider className={styles.sider__layout} trigger={null} collapsible collapsed={props.collapsed} collapsedWidth={0} width={props.width} >
      {props.children}
    </Sider>
  );
}

export default SiderMain;
 