import React, { Component } from 'react';
import style from './index.less';
import BaseChart from './BaseChart';
import LoadingSpin from './LoadingSpin';
import intl from 'react-intl-universal';
import { titleStyle,  axisLineX, axisLineY,gridInFo } from './echartInfo';
const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
import { isDataPaltform } from './utils';
export class AnnualData extends Component {
  getOption() {
    const { data, otherStyle, parentName, series } = this.props;
    const isDataPlatform = isDataPaltform(parentName);
    let fromatData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let formatXAxis = [];
    months.forEach((month, ii) => {
      data.forEach((item, jj) => {
        if (Number(item.time.split('-')[1]) - 1 === ii) {
          fromatData[ii] = item.data;
        }
      });
    });
    formatXAxis = months.map((mon) => intl.get(mon));
    return {
      title: {
        show:!isDataPlatform,  
        left: 'left',
        text: intl.get('ANNUAL_WORKLOAD'),
        top: 8,
        textStyle: titleStyle
      },
      tooltip: {
        formatter: function(params) {
          return params.name + ' : ' + params.value;
        }
      },
      grid: gridInFo(parentName),
      xAxis: {
        data: formatXAxis,
        axisTick: {
          show: false
        },
        axisLine: axisLineX,
        axisLabel: {
          textStyle: {
            color: otherStyle.xyColor,
            fontSize: 12
          }
        }
      },
      yAxis: [
        {
          axisTick: {
            show: false
          },
          axisLine: axisLineY,
          axisLabel: {
            textStyle: {
              color: otherStyle.xyColor
            }
          },
          splitArea: {
            areaStyle: {
              color: 'rgba(255,255,255,.5)'
            }
          },
          splitLine: {
            show: !isDataPlatform,
            lineStyle: {
              color: '#666',
              width: 0.5,
              type: 'dashed'
            }
          }
        }
      ],
      series: [
        {
          name: 'Direct',
          type: 'bar',
          barWidth: '25%',
          barCategoryGap: '25%',
          symbol: 'rectangle',
          barMaxWidth: '40px',
          label: {
            show: isDataPlatform ? false : true,
            position: 'top',
            distance: 8,
            color: otherStyle.xyColor,
            fontSize: '10px'
          },
          itemStyle: {
            normal: {
              color: '#409EFF',
              ...series.itemStyle
              // borderWidth:10, borderColor: 'red'
            },
            emphasis: {
              opacity: 1
            }
          },
          data: fromatData
        }
      ]
    };
  }

  render() {
    const { data, parentName,otherStyle } = this.props;
    const isDataPlatform = isDataPaltform(parentName);
    return (
      <div className={isDataPlatform ? style.chart_container_anualData : style.chart_container}>
        {data ? (
          <BaseChart style={{ height: otherStyle.height}} option={this.getOption()} />
        ) : (
          <LoadingSpin />
        )}
      </div>
    );
  }
}

export default AnnualData;
