import { Card, InputNumber} from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import {getSuspectedAnomalousDiameter} from '../../selectors';
// import cfg from '../../config';
// import useConfig from '../../hooks/useConfig';
// import { allImageListColumns } from '../../utils/displayUtils';
 
import {useDispatch} from 'react-redux'
// import useQueryInfo from '../../hooks/useQueryInfo';
import {saveSuspectedAnomalousDiameter} from '../../actions/configActions';

export default function SuspectedAnomalous(props) {
    // const { config } = useConfig();
    const dispatch = useDispatch();
    // const {reloadQueryInfo} = useQueryInfo();
    // const onChange = (checkedValue) =>{
    //     saveVisibleColumnsAsync(checkedValue)(dispatch)
    //     reloadQueryInfo(checkedValue)
    // }

    return (
        <Card title={intl.get('Suspected_Anomalous_Size')}>
            <div>{intl.get('Diameter')} <InputNumber min={1} max={10000} defaultValue={getSuspectedAnomalousDiameter()} onChange={value=>{
                console.log("value", value)               
                saveSuspectedAnomalousDiameter(value)(dispatch)
            }} /> Pixel</div>
            {/* <Checkbox defaultChecked={config.visibleConfidence} key="VisibleConfidence" onChange={e=>{
                let checkedValue = e.target.checked;
                saveVisibleConfidence(checkedValue)(dispatch)
            }}>
                {intl.get(("Confidence").toUpperCase())}
            </Checkbox>       
            <br />
            <Checkbox defaultChecked ={config.visibleLabel} key="VisibleLabel" onChange={e=>{
                let checkedValue = e.target.checked;
                saveVisibleLabel(checkedValue)(dispatch)
            }}>
                {intl.get(("Label").toUpperCase())}
            </Checkbox>  */}
        </Card>
    );
}

SuspectedAnomalous.propTypes = {};
  