import { has } from 'lodash';

const statusMapping = {
  // pending
  10: { message: 'DETECTING', icon: 'iconloading' },
  11: { message: 'WAITING', icon: 'clock-circle' },
  12: { message: 'QUEUING', icon: 'ellipsis' },
  //unsatisfied
  40003: { message: 'HEADER_UNSATISFIED', icon: 'close-circle' },

  // failure
  20: { message: 'DETECTION_FAILURE', icon: 'close-circle' },
  21: { message: 'UPLOAD_FAILURE', icon: 'close-circle' },
  22: { message: 'SLIDE_ID_USED', icon: 'tag' },
  23: { message: 'PIPELINE_TIMEOUT', icon: 'close-circle' },
  24: { message: 'BROKEN_FILE', icon: 'file-unknown' },
  25: { message: 'NETWORK_ERROR', icon: 'disconnect' },
  26: { message: 'BAD_JSON_FILE', icon: 'close-circle' },
  27: { message: 'SLIDE_ID_NOT_REGISTER', icon: 'tag' },
  28: { message: 'SLIDE_ID_INVALID', icon: 'tag' },
  29: { message: 'JSON_FILE_NOT_FOUND', icon: 'file-unknown' },
  30: { message: 'VERIFICATION_SERVICE_DOWN', icon: 'disconnect' },
  31: { message: 'FEATURE_NOT_EXISTS', icon: 'key' },
  41: { message: 'FEATURE_ID_EXPIRED', icon: 'key' },
  84: { message: 'SHARING_VIOLATION', icon: 'key' },
  1001: { message: 'MESSAGE_QUEUE_FAIL', icon: 'close-circle' },
  1002: { message: 'INVALID_TASK_TYPE', icon: 'close-circle' },
  1010: { message: 'FEATURE_ID_NOT_A_NUMBER', icon: 'tag' },
  1101: { message: 'PIPELINE_A_ERROR', icon: 'close-circle' },
  1201: { message: 'PIPELINE_B_ERROR', icon: 'close-circle' },
  5000: { message: 'PIPELINE_NOT_FOUND', icon: 'close-circle' },
  5001: { message: 'PIPELINE_INCONSISTENT_USER', icon: 'close-circle' },
  5002: { message: 'PIPELINE_NOT_REVERSABLE', icon: 'close-circle' },
  5003: { message: 'PIPELINE_NOT_SKIPPABLE', icon: 'close-circle' },
  9999: { message: 'UNKOWN_DETECTION_FAILURE', icon: 'close-circle' },
};

export default function getStatusMessage(statusCode) {
  if (has(statusMapping, statusCode)) {
    return statusMapping[statusCode];
  }
  return statusMapping[20];
}

export function isPending(status) {
  return status < 20 && status >= 10;
}

export function isSlideIDValid(status) {
  return status !== 22 && status !== 27 && status !== 28 && status !== 1010
}
