import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Checkbox } from 'antd';
import { setOrganization } from '../../../actions/setting';
// import intl from 'react-intl-universal';
import styles from '../commonPanel.less';
import { cloneDeep } from 'lodash';

function OrganizationPart(props) {
  const { list, grade, title, checkName, organization, parentsName, childrenName } = props;
  const mineIsEmpty = organization[checkName].length !== 1;
  const [latest, setLatestList] = useState([]);

  useEffect(() => {
    setLatestList(organization[checkName + 'List']);
  }, [organization[checkName + 'List']]);

  const ParentkIsEmpty = () => {
    if (parentsName === undefined) {
      return false;
    } else {
      let l = parentsName.filter((item) => organization[item + 'List'].length !== 1);
      return l.length > 0 ? true : false;
    }
  };

  const getList = (grade, checkName, checkedValues) => {
    let l = list.filter((item) => checkedValues.includes(item.value));
    props.setOrganization(checkName, l);
    //   单选时
    if (checkedValues.length === 1) {
      props.setOrganization(grade, l[0].children);
      if (childrenName !== undefined) {
        childrenName.forEach((element,index) => {
          let e = element.slice(7).toLowerCase();
          index!==0 &&  props.setOrganization(e + 'List', []);
          props.setOrganization(element + 'List', []);
          props.setOrganization(element, []);
        });
      }
    }
    //多选时
    else {
      if (childrenName !== undefined) {
        childrenName.forEach((element) => {
          let e = element.slice(7).toLowerCase();
          props.setOrganization(e + 'List', []);
          props.setOrganization(element + 'List', []);
          props.setOrganization(element, []);
        });
      }
    }
    props.setOrganization(checkName + 'List', checkedValues);
  };

  const getListPre = (grade, checkName, e) => {
    let checkedValues = e.target.value;
    let l = cloneDeep(latest);
    if (!latest.includes(checkedValues)) {
      if (checkName === 'checkedDoctor') {
        l.push(checkedValues);
      } else {
        l = [checkedValues];
      }
    } else {
      l.splice(l.indexOf(checkedValues), 1);
    }
    setLatestList(l);
    getList(grade, checkName, l);
  };

  return (
    <div>
      <div className={styles.content_title}>{title}</div>
      <div className={styles.content_list}>
        {list.map((item, index) => {
          return (
            <div
              key={index}
              className={
                !mineIsEmpty && organization[checkName][0].value === item.value
                  ? styles.content_check_active
                  : styles.content_check
              }
            >
              <Checkbox
                value={item.id}
                disabled={ParentkIsEmpty() ? true : false}
                onChange={(e) => getListPre(grade, checkName, e)}
                checked={latest.includes(item.value)}
              >
                <span>{item.label}</span>
              </Checkbox>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  organization: state.settings.organization
});
export default connect(mapStateToProps, { setOrganization })(OrganizationPart);
