import React, { Component } from 'react';
import style from './index.less';
import { connect } from 'react-redux';
import { setShowAnnotationClass } from '../../actions/annotationActions';
import { setCurrentAnnotationId } from '../../actions/annotationSelectionActions';
import { setAnimationStatus } from '../../actions/toolActions';
import { getSelectedAnnotationIds } from '../../selectors/annotationSelectors';
import PopoverIconButton from '../PopoverIconButton';
import intl from 'react-intl-universal';
import { Icon, message } from 'antd';
import PropTypes from 'prop-types';
import { fetchConfigurationsPlayTime } from '../../actions/configActions';
class AnnotationPlayer extends Component {
  static propTypes = {
    preset: PropTypes.bool,
    classes: PropTypes.array,
    size: PropTypes.string
  };
  constructor() {
    super();
    this.state = {
      interval: 2
    };
  }

  componentDidMount() {
    this.getPalyInterval();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.rectangleMode !== this.props.rectangleMode && this.props.rectangleMode) {
      this.stopWalk();
    }
  }
  componentWillUnmount() {
    this.stopWalk();
  }

  getPalyInterval = () => {
    fetchConfigurationsPlayTime().then((res) => {
      this.setState({
        interval: res * 1000
      });
    });
  };

  walkPromise = (id) => {
    return new Promise((resolve) => {
      if (this.props.walking) {
        setTimeout(() => {
          if (id) {
            this.props.walking && this.props.setCurrentAnnotationId(id);
            resolve(id);
          }
        }, this.state.interval);
      } else {
        resolve(id);
      }
    });
  };

  startWalk = () => {
    if(this.props.rectangleMode){
      message.error(intl.get('START_PALY_ERROR'))
      return false
    }
    const selectedAnnotationIds = [...this.props.selectedAnnotationIds];
    const firstId = selectedAnnotationIds[0];
    this.props.setAnimationStatus('playing');
    selectedAnnotationIds
      .reduce(
        (prev, next) => {
          return prev.then(() => {
            return this.walkPromise(next);
          });
        },
        new Promise((resolve) => {
          resolve(firstId);
        })
      )
      .then(() => {
        this.props.setAnimationStatus('stopped');
      });
  };

  stopWalk = () => {
    this.props.setAnimationStatus('stopped');
  };

  render() {
    const presetHint =
      this.props.classes.length > 0 ? intl.get('PLAY_BUTTON') : intl.get('NOTHING_TO_PLAY');
    const selectHint = intl.get('CHOOSE_TO_PLAY');
    return (
      <PopoverIconButton
        btnClass={style.button}
        onClick={this.props.walking ? this.stopWalk : this.startWalk}
        iconClass={Icon}
        popContent={this.props.preset ? presetHint : selectHint}
        place="bottom"
        icon={this.props.walking ? 'stop' : 'play-circle'}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedAnnotationIds: getSelectedAnnotationIds(state),
    walking: state.toolStatus.animationStatus === 'playing',
    rectangleMode: state.toolStatus.rectangleMode
  };
};

export default connect(mapStateToProps, {
  setCurrentAnnotationId,
  setAnimationStatus,
  setShowAnnotationClass,
  fetchConfigurationsPlayTime
})(AnnotationPlayer);
