const initial = {
  navigatorVisibility: true,
  tagVisibility: false,
  contrastVisibility: false,
  showAnnotationInfo: false,
  rectangleMode: false,
  circleMode: false,
  polygonMode: false,
  pointMode: false,
  animationStatus: 'stopped',
  fullscreen:false,
  siderLeftVisibility:true,
};

const resetedModes = (mode, state) => ({
  circleMode: mode ? false : state.circleMode,
  polygonMode: mode ? false : state.polygonMode,
  rectangleMode: mode ? false : state.rectangleMode,
  pointMode: mode ? false : state.pointMode
});

const toolStatus = (state = initial, action) => {
  switch (action.type) {
    case 'SET_SIDER_LEFT_VISIBILIYT':
      return {
        ...state,
        siderLeftVisibility:!state.siderLeftVisibility
      };
    case 'SET_FULL_SCREEN':
      return {
        ...state,
        fullscreen:!state.fullscreen,
        siderLeftVisibility: !state.fullscreen?false:true
      };
    case 'SET_RECTANGLE_MODE':
      return {
        ...state,
        ...resetedModes(action.rectangleMode, state),
        rectangleMode: action.rectangleMode
      };
    case 'SET_CIRCLE_MODE':
      return {
        ...state,
        ...resetedModes(action.circleMode, state),
        circleMode: action.circleMode
      };
    case 'SET_POLYGON_MODE':
      return {
        ...state,
        ...resetedModes(action.polygonMode, state),
        polygonMode: action.polygonMode
      };
    case 'SET_POINT_MODE':
      return {
        ...state,
        ...resetedModes(action.pointMode, state),
        pointMode: action.pointMode,
        navigatorVisibility: !action.pointMode
      };
    case 'SET_NAVIGATOR_VISIBILITY':
      return {
        ...state,
        navigatorVisibility: action.navigatorVisibility
      };
    case 'SET_CONTRAST_VISIBILIYT':
      return {
        ...state,
        contrastVisibility: action.contrastVisibility
      };
    case 'SET_TAG_VISIBILIYT':
      return {
        ...state,
        tagVisibility: action.tagVisibility
      };
    case 'SET_ANNOTATION_INFO_SHOW':
      return {
        ...state,
        showAnnotationInfo: action.showAnnotationInfo
      };
    case 'SET_ANIMATION_STATUS':
      return {
        ...state,
        animationStatus: action.status
      };
    case 'LOAD_IMAGE_REQUEST':
      return {
        ...state,
        circleMode: false,
        rectangleMode: false,
        polygonMode: false,
        animationStatus: 'stopped'
      };
    default:
      return state;
  }
};

export default toolStatus;
