import { Card, Col, Empty, Row, Statistic } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import style from './index.less';

class StatisticsPanel extends Component {
  static propTypes = {
    content: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.node]),
        key: PropTypes.string
      })
    )
  };
  render() {
    const { content } = this.props;
    if (content !== undefined && content.length > 0)
      return (
        <Card
          className={style.statisticsContainer}
          bodyStyle={{ paddingTop: 10, paddingBottom: 10 }}
        >
          <Row>
            {content.map((item) => (
              <Col key={item.key} span={8}>
                <Statistic
                  className={style.statisticContent}
                  title={item.title}
                  value={item.value || 0}
                  key={item.key}
                  suffix="%"
                  precision={2}
                />
              </Col>
            ))}
          </Row>
        </Card>
      );
    else
      return (
        <Card>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
        </Card>
      );
  }
}

export default StatisticsPanel;
