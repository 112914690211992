import React, { Component } from 'react';
import { Col, Row } from 'antd';
import styles from "./commonPanel.less"

class commonPanel extends Component {
  render() {
    const {span} = this.props
    return (
      <div className={styles.common_panel}>
        <Row gutter={20}>
          <Col  span={span===undefined ?10:span}>
            {this.props.children}
          </Col>
        </Row>
      </div>
    );
  }
}

export default commonPanel;
