import React, { useEffect, useState } from 'react';
import CommonPanel from '../commonPanel';
import { connect } from 'react-redux';
import { Card, InputNumber, Select, Icon, Divider, Input, message } from 'antd';
import intl from 'react-intl-universal';
import styles from '../commonPanel.less';
import { updateGeneralConfig } from '../../../actions/configActions';
import { cloneDeep, debounce } from 'lodash';
const { Option } = Select;
const defFileOp = ['tif', 'tiff', 'svs', 'scn', 'vmn', 'vms', 'ndpi'];
function ImageFilter(props) {
  const [lowerMB, setLowerMB] = useState(props.general.image_size_lower_bound_in_MB);
  const [filterFile, setFilterFile] = useState(props.general.image_file_format_to_filter);
  const [options, setOption] = useState(defFileOp);
  const [new_item, setNewItem] = useState('');
  const [dropdown_open, setDropdownOpen] = useState(false);
  const [onBlur_disable, setOnBlurDisable] = useState(false);

  useEffect(() => {
    let op = cloneDeep(defFileOp);
    props.general.image_file_format_to_filter &&
      props.general.image_file_format_to_filter.forEach((item) => {
        !defFileOp.includes(item) && op.push(item);
      });
    setOption(op);
  }, []);
  const setNewOption = () => {
    new_item !== ''
      ? options.includes(new_item)
        ? message.error(intl.get('RE_FILE'))
        : setOption(options.concat(new_item))
      : message.error(intl.get('NAME_NOT_NULL'));
  };

  const limitDecimals = (value) => {
    return value.replace(/\D+/g, '');
    // return value.replace(/[^0-9\-\+]/g,'');
  };
  const changeSelect = (e) => {
    props.updateGeneralConfig({ image_file_format_to_filter: e }).then((res) => {
      if(res){
        message.success(intl.get('MARK_MODIFY_SUCCESS'));
        setFilterFile(e);
      }
    });
    // setFilterFile(e);
  };
  const changeMB = (e) => {
    props.updateGeneralConfig({ image_size_lower_bound_in_MB: e }).then((res) => {
      if(res){
        message.success(intl.get('MARK_MODIFY_SUCCESS'));
        setLowerMB(e);
      }
    });
    // setLowerMB(e);
  };

  return (
    <CommonPanel>
      <Card title={intl.get('IMAGE_FILTER_SETTING')}>
        <div className={styles.img_file}>
          <div>{intl.get('LIMIT_FILE')}</div>
          <div>
            {intl.get('THRESHOLD')}&nbsp;&nbsp;
            <InputNumber
              formatter={limitDecimals}
              parser={limitDecimals}
              min={1}
              defaultValue={10}
              value={lowerMB}
              onChange={debounce(changeMB, 500)}
            />
            &nbsp;&nbsp;M
          </div>
        </div>
        <div className={styles.img_file_select}>
          <Select
            mode="multiple"
            // defaultValue=""
            style={{ width: '100%' }}
            open={dropdown_open}
            onChange={(e) => changeSelect(e)}
            onDropdownVisibleChange={(open) => {
              if (!onBlur_disable) {
                setDropdownOpen(open);
              }
            }}
            value={filterFile}
            placeholder={intl.get('PLEASE_SELECT')}
            dropdownRender={(menu) => (
              <div>
                {menu}
                <Divider style={{ margin: '4px 0' }} />
                <div
                  style={{ padding: '10px', cursor: 'pointer' }}
                  onMouseEnter={() => setOnBlurDisable(true)}
                  onMouseLeave={() => {
                    setOnBlurDisable(false)
                    setDropdownOpen(false);
                  }}
                >
                  <div>
                    <span
                      onClick={() => {
                        setNewItem('');
                        setOnBlurDisable(false);
                        setNewOption();
                      }}
                    >
                      <Icon type="plus" />
                      &nbsp;&nbsp;
                    </span>
                    <Input
                      style={{ marginBottom: '10px', width: '200px' }}
                      value={new_item}
                      onChange={(e) => setNewItem(e.target.value)}
                      autoFocus
                      maxLength={20}
                      placeholder={intl.get('DIY_FILE')}
                    />
                  </div>
                </div>
              </div>
            )}
          >
            {options.map((people) => {
              return (
                <Option key={people} value={people}>
                  {people}
                </Option>
              );
            })}
          </Select>
        </div>
      </Card>
    </CommonPanel>
  );
}

const mapStateToProps = (state, ownProps) => ({
  general: state.config.general
});
export default connect(mapStateToProps, { updateGeneralConfig })(ImageFilter);
