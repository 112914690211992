export const setSearchBoxValue = (value) => ({
    type: 'SET_SEARCH_BOX_VALUE',
    value
  });

  export const setSearchBoxImageId = (imageId) => ({
    type: 'SET_SEARCH_BOX_IMAGEID',
    imageId
  });  

  export function updateSearchBoxInfo(data){
    return function (dispatch) {
      dispatch(setSearchBoxValue(data))
    }
  }

  export function updateSearchBoxImageId(imageId){
    return function (dispatch) {
      dispatch(setSearchBoxImageId(imageId))
    }
  }