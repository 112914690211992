import React, { Component } from 'react';
import intl from 'react-intl-universal';
import colorTable from '../../static/colorTable';
import getStatusMessage from '../../static/detectionStatusMapping';
import Icon from '../Iconfont';
import style from './statusMessage.less';
import {  Tooltip } from 'antd';
class StatusMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      spin: props.status === 10,
      color: props.status < 20 ? colorTable.blue : colorTable.red
    };
  }
  render() {
    const { color, spin } = this.state;
    const content = getStatusMessage(this.props.status);
    return (
      <Tooltip title={intl.get(content.message)}>
        <div>
          <Icon type={content.icon} className={style.icon} style={{ color }} spin={spin} />
          <span>{intl.get(content.message)}</span>
        </div>
      </Tooltip>
    );
  }
}

export default StatusMessage;
