import {
  addAnnotationChangeToServer,
  addAnnotationWithChanges,
  loadAnnotationsFromServer,
  addPointsOfPointSetToServer,
  apiRemoveAnnotationFromMaskList,
  apiAddAnnotationToMaskList
} from './api';
import { normalizeAnnotations } from '../utils/dataUtils';
import { getCurrentClassification } from '../selectors';
import { getAnnotationClassesOfUsers } from '../selectors/classSelectors';
import { setCurrentAnnotationId } from '../actions/annotationSelectionActions';
import { getVisibleAnnotationIds } from '../selectors/annotationSelectors';
import store from '../store';
import { uniq } from 'lodash';
export const triggerAnnotationLimit = (limited) => ({
  type: 'UPDATE_ANNOTATION_LIMIT',
  limited
});
export const triggerMarkAnnotationLimit = (limited) => ({
  type: 'UPDATE_MARK_ANNOTATION_LIMIT',
  limited
});

export const setShowAnnotationClass = (showClass) => ({
  type: 'SET_SHOW_ANNOTATION_CLASS',
  showClass
});

const loadAnnotationsRequest = () => ({
  type: 'LOAD_ANNOTATIONS_REQUEST'
});

const loadAnnotationsSuccess = (annotations, users) => ({
  type: 'LOAD_ANNOTATIONS_SUCCESS',
  annotations,
  users
});

const loadAnnotationsFailure = (error) => ({
  type: 'LOAD_ANNOTATIONS_FAILURE'
});

export function loadAnnotationsAsync(imageId) {
  return function(dispatch) {
    dispatch(loadAnnotationsRequest());
    dispatch(triggerAnnotationLimit(true));
    return loadAnnotationsFromServer(imageId).then(
      (response) => {
        //
        let { imageLeve } = store.getState().imageSelection.currentImageInfo;
        let { items } = response.data;
        // console.log("isAnnotationOfUsersAndClasses imageLeve", imageLeve, items)
        let is_classification5 = getCurrentClassification() !== 15;
        if (imageLeve === 'L' || imageLeve === 'LS') {
          let l = items.filter((it) => {
            let f = true;
            const userAI = uniq(it.changes.map((change) => change.user)).filter((u) =>
              u.startsWith('ai_')
            );
            if (imageLeve === 'L' && userAI.length > 0 && it.type !== 'Circle') {
              f = false;
            }
            if (imageLeve === 'LS' && userAI.length > 0) {
              let labels = it.changes.map((v) => v.new);
              if (
                it.type !== 'Circle' &&
                labels.every((l) => !['TV', 'CC', 'ACTINO', 'HSV', 'FUNGI'].includes(l))
              ) {
                f = false;
              }
            }
            return f;
          });
          items = l;
        }
        // else if(imageLeve === "LS"){
        //   items = items.filter(it=>{
        //     let labels = it.changes.map(v=>v.new)
        //     let not_includes = labels.every(l=> !(["TV", "CC", 'ACTINO', 'HSV', 'FUNGI',].includes(l)));
        //     return !not_includes
        //   })
        //   console.log(items)
        // }
        else {
          items = items.filter((it) => it.type !== 'Circle');
        }

        const normalized = normalizeAnnotations(items);
        dispatch(
          loadAnnotationsSuccess(
            normalized.entities.annotations
              ? formdataAnnos(Object.values(normalized.entities.annotations))
              : [],
            normalized.entities.users ? Object.keys(normalized.entities.users) : []
          )
        );
        let groups = getAnnotationClassesOfUsers(store.getState());
        let defaultActiveKey = 'ALL';
        let tabHeight = '46px';

        if (groups.includes('Suspected_Anomalous_Areas') || is_classification5) {
          defaultActiveKey = 'Suspected_Anomalous_Areas';
          tabHeight = '96px';
        }
        document.body.style.setProperty('--tabHeight', tabHeight);
     
   
        dispatch(setShowAnnotationClass(defaultActiveKey));
      },
      (error) => {
        dispatch(loadAnnotationsFailure());
      }
    );
  };
}

function formdataAnnos(annotations) {
  if (annotations) {
    return annotations.map((anno) => {
      if (
        anno.users.filter((u) => !u.startsWith('ai_')).length <= 0 &&
        (anno.x_range < 200 || anno.y_range < 200)
      ) {
        if (anno.x_range < 200) anno.x_range = 200;
        if (anno.y_range < 200) anno.y_range = 200;
      }
      return anno;
    });
  } else {
    return [];
  }
}

const addAnnotationChangeRequest = (change, annotationId) => ({
  type: 'ADD_ANNOTATION_CHANGE_REQUEST',
  change,
  annotationId
});

const addAnnotationChangeSuccess = (change, annotationId) => ({
  type: 'ADD_ANNOTATION_CHANGE_SUCCESS',
  change,
  annotationId
});

const addAnnotationChangeFailure = (change, annotationId) => ({
  type: 'ADD_ANNOTATION_CHANGE_FAILURE',
  change,
  annotationId
});

export function addAnnotationChangeAsync(change, annotationId) {
  return function(dispatch) {
    dispatch(addAnnotationChangeRequest(change, annotationId));
    return addAnnotationChangeToServer(change, annotationId).then(
      (response) => {
        dispatch(addAnnotationChangeSuccess(change, annotationId));
        window.osdoverlay.fabricCanvas().renderAll();
      },
      (error) => {
        dispatch(addAnnotationChangeFailure(change, annotationId));
      }
    );
  };
}

const addPointsOfPointSetRequest = (points, annotationId) => ({
  type: 'ADD_POINTS_OF_POINTSET_TO_REQUEST',
  points,
  annotationId
});

const addPointsOfPointSetSuccess = (points, annotationId) => ({
  type: 'ADD_POINTS_OF_POINTSET_TO_SUCCESS',
  points,
  annotationId
});

const addPointsOfPointSetFailure = (points, annotationId) => ({
  type: 'ADD_POINTS_OF_POINTSET_TO_FAILURE',
  points,
  annotationId
});

export function addPointsOfPointSetAsync(change, annotationId) {
  return function(dispatch) {
    dispatch(addPointsOfPointSetRequest(change, annotationId));
    return addPointsOfPointSetToServer(change, annotationId).then(
      (response) => {
        dispatch(addPointsOfPointSetSuccess(change, annotationId));
      },
      (error) => {
        dispatch(addPointsOfPointSetFailure(change, annotationId));
      }
    );
  };
}

const addAnnotationRequest = (annotation, imageId) => ({
  type: 'ADD_ANNOTATION_REQUEST',
  annotation,
  imageId
});

const addAnnotationSuccess = (annotation, newId) => ({
  type: 'ADD_ANNOTATION_SUCCESS',
  annotation,
  newId
});

const addAnnotationFailure = (annotation, error) => ({
  type: 'ADD_ANNOTATION_FAILURE',
  annotation,
  error
});

export function addAnnotationAsync(annotation, imageId) {
  return function(dispatch) {
    dispatch(addAnnotationRequest(annotation, imageId));
    return addAnnotationWithChanges(annotation, imageId).then(
      (response) => {
        dispatch(addAnnotationSuccess(annotation, response.data.id));
        if (!getVisibleAnnotationIds(store.getState()).includes(response.data.id)) {
          dispatch(triggerAnnotationLimit(true));
        }
        dispatch(setCurrentAnnotationId(response.data.id));
      },
      (error) => {
        dispatch(addAnnotationFailure(annotation, error));
      }
    );
  };
}

export function removeAnnotationsOfUsers(changes) {
  return function(dispatch) {
    changes.map((change) => {
      return dispatch(addAnnotationChangeAsync(change.change, change.annotationId));
    });
  };
}

const updateAnnotationInfo = (annotationId, update) => ({
  type: 'UPDATE_ANNOTATION_INFO',
  update,
  annotationId
});

export function addAnnotationToMaskList(annotation_id) {
  return function(dispatch) {
    return apiAddAnnotationToMaskList(annotation_id).then(
      (response) => {
        if (response.data && response.data.markers) {
          dispatch(updateAnnotationInfo(annotation_id, { markers: response.data.markers }));
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

export function removeAnnotationFromMaskList(annotation_id) {
  return function(dispatch) {
    return apiRemoveAnnotationFromMaskList(annotation_id).then(
      (response) => {
        if (response.data && response.data.markers) {
          dispatch(updateAnnotationInfo(annotation_id, { markers: response.data.markers }));
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
}
