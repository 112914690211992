export function getLoadingStatus(state) {
  return state.analysis.loading;
}

export function getStatistics(state) {
  return state.analysis.statistics;
}

export function getMatrixes(state) {
  return state.analysis.matrixes;
}

export function getNegativeAnalysis(state) {
  return state.analysis.negativeAnalysis;
}

export function getNR(state) {
  return {
    nr: state.analysis.nr,
    tnr: state.analysis.tnr
  };
}
