import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { Form, Input, Col, Row, Button, message } from 'antd';
import { formItemLayout, numInfo, numInfoModify, initalCheckInfo } from './checkInfo';
import {
  updateChckInInfoParams,
  getCheckInfoByApplyId,
  hasRegistation,
  setChckInInfo,
  setHisInfo,
  fetchPathologyId
} from '../../actions/checkInAction';
class NumInfo extends Component {
  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
  }

  getForm() {
    return this.props.form;
  }
  handleSubmit() {
    let res;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.props.info.pathology_id === '') {
          this.props.form.setFields({ pathology_id: { errors: [new Error('')] } });
          res = false;
          return false;
        } else {
          res = true;
          return true;
        }
      } else {
        res = false;
        return false;
      }
    });
    return res;
  }

  updateFormInitalValue(data) {
    if(this.props.isModify){      
      numInfoModify.forEach((element) => {
        if (element.key !== undefined) {
          this.props.form.setFieldsValue({
            [element.key]: data[element.key]
          });
        }
      });
    }else{
      numInfo.forEach((element) => {
        if (element.key !== undefined) {
          this.props.form.setFieldsValue({
            [element.key]: data[element.key]
          });
        }
      });
    }
  }
  checkIn = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        hasRegistation(this.props.info.apply_id).then((res) => {
          if (res === 0) {
            getCheckInfoByApplyId(this.props.info.apply_id, 'add').then((result) => {
              if (result && result.data) {
                this.props.setHisInfo(result.data);
                this.props.fetchPathologyId(result.data);
                this.props.updateData(result.data);
              } else {
                this.props.fetchPathologyId({})
                this.clearCheckInfo({apply_id:this.props.info.apply_id})
              }
            });
            this.props.form.setFields({ pathology_id: {} });
          } else if (res > 0) {
            message.error(intl.get('RE_REGISTER'));
            this.clearCheckInfo({apply_id:this.props.info.apply_id})
            this.props.form.setFields({ apply_id: { errors: [new Error('')] } });
          } else {
            this.props.form.setFields({ apply_id: { errors: [new Error('')] } });
          }
        });
      } else {
        message.error(intl.get('CHECK_NULL'));
      }
    });
  };

  clearCheckInfo=(data)=>{
    this.props.setHisInfo({});
    this.props.setChckInInfo({...initalCheckInfo,...data});
    this.props.updateData(initalCheckInfo);
  }

  onChangeInput = (event, name) => {
    this.props.updateChckInInfoParams({
      [name]: event.target.value.replace(/\s*/g, ''),
      pathology_id: ''
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { info, checkType ,location} = this.props;
    const cannotEdit = location === 'report' ? true : false;

    return (
      <Form {...formItemLayout}>
        <Row>
          {(this.props.isModify? numInfoModify : numInfo).map((item) => {
            return (
              <Col span={8} key={item.key}>
                <Form.Item name={item.name} label={intl.get(item.name)} colon={false}>
                  {getFieldDecorator(item.key, {
                    rules: item.rules,
                    initialValue: info[item.key]
                  })(
                    <Input
                      placeholder={intl.get(item.name)}
                      value={info[item.key]}
                      onChange={(e) => this.onChangeInput(e, item.key)}
                      disabled={(checkType === 'update' || cannotEdit || this.props.isModify) ? true : item.disabled}
                      maxLength={item.maxLength}
                    />
                  )}
                </Form.Item>
              </Col>
            );
          })}
          <Col span={6} offset={1}>
            {(checkType === 'add' && !cannotEdit && !this.props.isModify) && (
              <Button type="primary" htmlType="submit" onClick={this.checkIn.bind(this)} >
                {intl.get('CHECK_IMPORT')}
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    checkType: state.checkIn.checkType
  };
};

export default connect(mapStateToProps, {
  updateChckInInfoParams,
  setChckInInfo,
  setHisInfo,
  fetchPathologyId
})(Form.create()(NumInfo));
