import { message, Modal, Upload } from 'antd';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { toggleUploadGT } from '../../actions/tabActions';
import config from '../../config';
import { getUploadGTVisibles } from '../../selectors/tabSelectors';
import Icon from '../Iconfont';
import Title from '../TitleWithHelp';

const { Dragger } = Upload;

class GTUpload extends Component {
  constructor(){
    super()
    this.state = {
      fileList:[]
    };
  }
  uploadGT = (info) => {
    const { status, response } = info.file;
    let fileList = [...info.fileList];
    if (status === 'done') {
      let success= {success:response.total - response.failed}
      message.success(intl.get('UPLOAD_GT_SUCCESS', { ...response, ...success}));
      this.props.toggleUploadGT(false);
    } else if (status === 'error') {
      message.error(intl.get('UPLOAD_GT_FAILED'));
    }

    fileList = fileList.map(file => {
      if (file.status==='error') {
        file.response = intl.get('UPLOAD_RESULT_ERROR');
      }
      return file;
    });
    this.setState({ fileList });
  };

  render() {
    const { uploadVisible, onClose } = this.props;
    const headers={
      authorization: `Bearer ${localStorage.getItem('access_token')}` 
    }
    return (
      <Modal
        visible={uploadVisible}
        closable={false}
        footer={null}
        destroyOnClose={true}
        afterClose={onClose}
        title={<Title title={intl.get('UPLOAD_GT')} detail={intl.getHTML('UPLOAD_GT_HELP')} />}
        onOk={() => {
          this.props.toggleUploadGT(false);
        }}
        onCancel={() => {
          this.props.toggleUploadGT(false);
        }}
      >
        <Dragger
          onChange={this.uploadGT}
          action={`${config.baseUrl}/api/image/ground-truth/upload`}
          method="post"
          name="gt"
          headers={headers}
          fileList={this.state.fileList}
        >
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">{intl.get('DRAG_UPLOAD_GT')}</p>
          <p className="ant-upload-hint">{intl.get('DRAG_UPLOAD_GT_HINT')}</p>
        </Dragger>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return { uploadVisible: getUploadGTVisibles(state) };
};

export default connect(mapStateToProps, { toggleUploadGT })(GTUpload);
