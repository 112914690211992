/*eslint complexity: ["error", 22]*/
import React, { Component } from 'react';
import { Dropdown, Menu, Tag, Icon, Input, Tooltip } from 'antd';
import config from '../../config';
import style from './index.less';
import intl from 'react-intl-universal';
import { uniq } from 'lodash';
import ImageLevelResultTag from '../ImageLevelResultTag';
import PropTypes from 'prop-types';
import { visibleLabel, visibleConfidence } from '../../selectors';
import { isPartEnd } from '../../utils/urlUtils';
const { cervicalGroundTruthList } = config;

export default class AnnotationClassMenu extends Component {
  static propTypes = {
    onMenuItemClicked: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      inputting: false,
      inputValue: ''
    };
  }

  handleInputChange = (e) => {
    if (e.target.value.length <= 20) {
      this.setState({ inputValue: e.target.value });
    }
  };

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    if (inputValue) {
      const item = { key: inputValue };
      this.props.onMenuItemClicked(item);
    }
    this.setState({
      inputting: false,
      inputValue: ''
    });
  };

  showInput = () => {
    this.setState({ inputting: true }, () => this.input.focus());
  };

  makeGradient = (classes) => {
    const lgth = 100 / classes.length;
    const str = classes.reduce((total, c, idx) => {
      return (
        total +
        ', ' +
        this.getColor(c) +
        ' ' +
        lgth * idx +
        'px,' +
        this.getColor(c) +
        ' ' +
        lgth * (idx + 1) +
        'px'
      );
    }, '');
    return str;
  };

  getColor = (clazz) => {
    let color = '#9DE7C6';
    switch (clazz) {
      case 'ASC-US':
        color = '#3dd4fa';
        break;
      case 'LSIL':
        color = '#4fb9f0';
        break;
      case 'ASC-H':
        color = '#fececa';
        break;
      case 'HSIL':
        color = '#fea9bd';
        break;
      case 'SCC':
        color = '#e5899e';
        break;
      case 'AGC':
        color = '#8378ea';
        break;
      case 'AGC-N':
        color = '#9b91f5';
        break;
      case 'ADC':
        color = '#7460bf';
        break;
      case 'CAN':
        color = '#fff3b7';
        break;
      case 'trich':
        color = '#ffe1a1';
        break;
      case 'CC':
        color = '#f9e089';
        break;
      case 'ACTINO':
        color = '#ffe47c';
        break;
      case 'HSV':
        color = '#ffd27e';
        break;
      case 'AIS':
        color = '#5755CD';
        break;
      case 'FUNGI':
        color = '#F5CC01';
        break;
      case 'TV':
        color = '#FFAE00';
        break;
      default:
        color = '#9DE7C6';
    }

    return color;
  };
  ISAI(lines) {
    for (let i = 0; i < lines.length; i++) {
      let list = lines[i].split(',');
      if (!list[0].startsWith('ai_') && list[1] !== 'null') {
        return false;
      }
    }
    return true;
  }

  divStyle() {
    const partEnd = isPartEnd();
    const hasConflict = uniq(this.props.classes).length > 1;
    const colorStr = this.makeGradient(this.props.classes);
    const poniterStyle = {
      pointerEvents: partEnd === 'centerEnd' ? 'none' : 'auto'
    };
    const gradient = {
      background: `repeating-linear-gradient(90deg${colorStr})`,
      ...poniterStyle
    };
    const general = {
      ...poniterStyle
    };
    return hasConflict ? gradient : general;
  }

  render() {
    const menu = (
      <Menu defaultSelectedKeys={uniq(this.props.classes)}>
        {cervicalGroundTruthList.slice(0, 9).map((group) => {
          return (
            <Menu.Item onClick={this.props.onMenuItemClicked} key={group}>
              <ImageLevelResultTag result={group} />
            </Menu.Item>
          );
        })}
        {cervicalGroundTruthList.slice(10).map((group) => {
          return (
            <Menu.Item onClick={this.props.onMenuItemClicked} key={group}>
              <ImageLevelResultTag result={group} />
            </Menu.Item>
          );
        })}
        <Menu.Item>
          <Tag
            onClick={this.showInput}
            style={{ border: 'none', backgroundColor: 'transparent', width: '100%' }}
          >
            <Icon type="plus" />
          </Tag>
        </Menu.Item>
      </Menu>
    );
    const hasConflict = uniq(this.props.classes).length > 1;
    let clazz = hasConflict ? intl.get('CONFLICT') : this.props.classes.find((c) => true);
    clazz = clazz === 'Draft' ? intl.get('ANNOTATION_DRAFT') : clazz;
    const numText =
      clazz === 'Points' && this.props.points
        ? `${this.props.points.length}`
        : this.ISAI(this.props.lines)
        ? `${clazz}:${this.props.prob}%`
        : `* ${clazz}:${this.props.prob}%`;
    let text = hasConflict ? `${clazz}` : numText;
    let Textclass = '';
    if (!hasConflict && (visibleConfidence() || visibleLabel())) {
      Textclass = style.single;
      if (!(visibleConfidence() && visibleLabel())) {
        if (visibleConfidence()) {
          text = `${this.props.prob}%`;
        } else if (visibleLabel()) {
          text = `${clazz}`;
        }
      }
    }
    text += this.props.clump ? '.' : '';
    const st = this.divStyle();
    return (
      <div className={hasConflict ? style.conflict : Textclass} style={st}>
        {this.state.inputting && (
          <Input
            ref={(input) => {
              this.input = input;
            }}
            type="text"
            size="small"
            style={{ height: '1.2em' }}
            value={this.state.inputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleInputConfirm}
            onPressEnter={this.handleInputConfirm}
          />
        )}
        {(visibleConfidence() || visibleLabel()) && !this.state.inputting && (
          <Dropdown overlay={menu} trigger={['click']}>
            {text.length > 20 ? (
              <Tooltip placement="top" title={text}>
                <div className={style.tagText}>{text}</div>
              </Tooltip>
            ) : (
              <div>{text}</div>
            )}
          </Dropdown>
        )}
      </div>
    );
  }
}
