import { message } from 'antd';
import {
  fetchReportResult,
  postReportResult,
  pushReportResult,
  apiUserAll,
  apiBatchPushReport,
  apiCancelReport,
  apiWithdrawReport,
  apiRedistribute,
  apifetchRolesUser
} from './api';
import intl from 'react-intl-universal';
import getStatusMessage from '../static/detectionStatusMapping';
import { updateImageInfo,updateCurrentImageInfoPart } from './imageActions';
import { getQueryInfo, getSelectedImages } from '../selectors/imageSelector';
import store from '../store';
import { toggleLoadingTable,updateQueryInfo } from './tableActions';
import { isPartEnd } from '../utils/urlUtils';
export const updateReportResult = (data) => ({
  type: 'UPDATE_REPORT_RESULT',
  data
});

export const updateReportNested = (field, data) => ({
  type: 'UPDATE_REPORT_NESTED',
  data,
  field
});

export function initReportResult(data) {
  return {
    type: 'INIT_REPORT_RESULT',
    data
  };
}

export const setHistoryVisible = (show) => {
  return {
    type: 'SET_HISTORY_VISIBLE',
    show
  };
};

export const setDoctorList = (data) => {
  return {
    type: 'SET_DOCTOR_LIST',
    data
  };
};

export function setPlanReporter(data) {
  return {
    type: 'SET_PLAN_REPORTER',
    data
  };
}

export const setRolesUsers = (data) => {
  return {
    type: 'SET_ROLES_USERS',
    data
  };
};

export function updateDoctorList() {
  return async (dispatch) => {
    apiUserAll()
      .then((resp) => {
        let list = resp.data.users.filter((i) =>
          i.permissions[i.permissions.length - 1].includes('static:client:view:general')
        );
        dispatch(setDoctorList(list));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function updateRolesUsers() {
  return async (dispatch) => {
    apifetchRolesUser()
      .then((resp) => {
        // console.log(resp.data.roles)
        dispatch(setRolesUsers(resp.data.roles));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function saveReportResult(imageId, data, msg) {
  return async (dispatch) => {
    postReportResult(imageId, data)
      .then((resp) => {
        dispatch(updateReportResult(resp.data));
        // dispatch(fetchPipeline(imageId))
        dispatch(updateImageInfo(imageId));
        msg !== 'noMessage' && message.success(intl.get('MARK_MODIFY_SUCCESS'));
      })
      .catch((err) => {
        console.warn(err);
        if (err.response) {
          const resp = err.response;
          if (resp.data.error_code) {
            message.error(intl.get(getStatusMessage(resp.data.error_code).message));
          }
        }
      });
  };
}

export function submitReportResult(imageId, typeName) {
  const queryInfo = getQueryInfo(store.getState());
  return async (dispatch) => {
    pushReportResult(imageId)
      .then((resp) => {
        message.success(intl.get('PUSH_SUCCESS'));
        typeName==='list' && dispatch(updateQueryInfo(queryInfo));
      })
      .catch((err) => {
        console.warn(err);
        typeName==='list' && dispatch(updateQueryInfo(queryInfo));
      });
  };
}

export function getReportResult(imageId) {
  return async (dispatch) => {
    fetchReportResult(imageId)
      .then((resp) => {
        dispatch(updateReportResult(resp.data));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function getReportRowResult(imageId) {
  return fetchReportResult(imageId)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      console.warn(err);
    });
}

export function getPrintResult(imageId) {
  return fetchReportResult(imageId)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      console.warn(err);
    });
}

export function getPrintResultList(imageIdList) {
  const promises = [];
  imageIdList.forEach((item, i) => {
    promises.push(fetchReportResult(item));
  });
  return promises;
}

export function getReportResultList() {
  const queryInfo = getQueryInfo(store.getState());
  const selectedImages = getSelectedImages(store.getState());
  const images = selectedImages.data.map((i) => i.id);
  let params = {
    ...queryInfo,
    images: images
  };
  return async (dispatch) => {
    apiBatchPushReport(params)
      .then((resp) => {
        message.success(intl.get('BATCH_PUSH_MEG', { ...resp.data }));
        dispatch(updateQueryInfo(queryInfo));
      })
      .catch((err) => {
        console.warn(err);
        toggleLoadingTable(false)
      });
  };
}

export function saveReportPrinted(imageId, data) {
  return async (dispatch) => {
    dispatch(saveReportResult(imageId, data));
  };
}

export function cancelReport(id, data, interpretation) {
  return async (dispatch) => {
    postReportResult(id, interpretation)
      .then((resp) => {
        dispatch(updateReportResult(resp.data));
        dispatch(cancelReportAction(id, data));
        dispatch(updateCurrentImageInfoPart({stage:2}))
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

function cancelReportAction(id, data,) {
  return async (dispatch) => {
    apiCancelReport(id, data)
    .then((resp) => {
      let partEnd = isPartEnd()
      message.success(partEnd === 'expert' ?intl.get('CANCEL_SUCCESS_EXPERT'):intl.get('CANCEL_SUCCESS'));
      dispatch(updateImageInfo(id));
    })
    .catch((err) => {
      message.success(intl.get('CANCEL_FAILED'));
      console.warn(err);
    });
  }

}

export function withdrawReport(id) {
  const queryInfo = getQueryInfo(store.getState());
  return async (dispatch) => {
    apiWithdrawReport(id)
      .then((resp) => {
        message.success(intl.get('WITHDRAW_SUCCESS'));
        dispatch(updateQueryInfo(queryInfo));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function rdistributeDoctor(id, data, name) {
  const queryInfo = getQueryInfo(store.getState());
  return async (dispatch) => {
    apiRedistribute(id, data)
      .then((resp) => {
        message.success(intl.get('CHANGE_DOCTORS_SUCCESS'));
        name === 'list' && dispatch(updateQueryInfo(queryInfo));
        name === 'report' && dispatch(updateImageInfo(id));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}
