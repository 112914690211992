import { Layout } from 'antd';
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import NoMatch from '../components/Exceptions/404';
import Setting from '../components/Setting';
import { canRender } from '../components/Can';
import { connect } from 'react-redux';
import { sideBarItems } from './menuItems';
import { isPartEnd } from '../utils/urlUtils';
// import MissingWsi from '../components/MissingWsi';
// import ManualAdjustment from '../components/ManualAdjustment';
// import Configuration from '../components/Configuration';
const { Content } = Layout;

class PanelRouter extends Component {
  
  render() {
    const { currentUser } = this.props;
    const pathname=  this.props.history.location.pathname
    let divStyle =
    pathname === '/preference/visualization' || pathname === '/preference/monitor' 
        ? { padding: '10px 20px 0px 20px', minHeight: 280 }
        : { padding: 24, minHeight: 280 };
    const partEnd =isPartEnd()
    return (
      // style={{backgroundColor:"#15122E"}}
      <Content>
        <div style={divStyle}>
          <Switch>
            <Route exact path="/preference" component={Setting} />
            {sideBarItems.map((item,index) => {
              if (item.children) {
                return item.children.map((c) => {
                  return canRender(currentUser.permissions, item.permission) &&
                    c.partName.includes(partEnd) ? (
                    <Route key={index} path={'/preference' + c.path} component={c.component} />
                  ) : (
                    ''
                  );
                });
              } else {
                return canRender(currentUser.permissions, item.permission) &&
                  item.partName.includes(partEnd) ? (
                  <Route key={index}  path={'/preference' + item.path} component={item.component} />
                ) : (
                  ''
                );
              }
            })}
            <Route component={NoMatch} />
          </Switch>
        </div>
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  general: state.config.general
});

export default connect(mapStateToProps)(withRouter(PanelRouter));
