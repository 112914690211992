import { Button } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { switchLanguage } from '../../static/locales';
import Icon from '../Iconfont';
import style from './index.less';

class SwitchLanguage extends Component {
  static propTypes = {
    placement: PropTypes.string
  };

  handleSwitchLanguage = () => {
    switchLanguage();
    window.location.reload(true);
  };

  render() {
    if (this.props.placement === 'menubar') {
      return (
        <span className={style.switcher} onClick={this.handleSwitchLanguage}>
          <Icon type="global" className={style.icon} />
          {intl.get('CURRENTLOCALE')}
        </span>
      );
    } else {
      return (
        <Button
          className={style.switcher}
          size="small"
          icon="global"
          onClick={this.handleSwitchLanguage}
        >
          {intl.get('CURRENTLOCALE')}
        </Button>
      );
    }
  }
}

export default SwitchLanguage;
