import { message } from 'antd';
import intl from 'react-intl-universal';
import { call, fork, put, select, take, takeLatest, cancelled } from 'redux-saga/effects';
import { addImages, setCurrentImageInfo } from '.';
import {
  getQueryInfo,
  getLastImageInfo,
  getNextImageInfo,
  getLoadImageMode,
  getSearchQuery,
  getSelectedImages
} from '../selectors/imageSelector';
import { getCurrentTab, getCurrentTabIdx, getSecondCurrentTab } from '../selectors/tabSelectors';
import { getCurrentUser } from '../selectors/userSelectors';
import store from '../store';
import rawToImageData from '../utils/dataUtils';
import setBaseUrl from '../utils/urlUtils';
import { loadAnnotationsAsync } from './annotationActions';
import {
  fetchUpdateImage,
  fetchUploadImage,
  getImages,
  loadImageVisualInfoFromServer,
  addUploadedImageToDatabase,
  triggerDetectionOfImage,
  getCollectionImageAPI,
  collectImageAPI,
  discardImageAPI,
  fetchTagIdValidation,
  markImageAPI,
  apiPostPipeline,
  apiFetchPipeline,
  getImageById,
  apiFetchImageInfo,
  apiPipelineEdit,
  apiPipelineEditing,
  apiPipelineEdited,
  apiPipelineReEdit,
  apiSendReport,
  apiPrintReport,
  apiBatchSendReport,
  apiBatchPrintReport,
  apiBatchRedistribute,
  apiBatchDisReport,
  apiBatchPipelineEdited,
  apiEditByImageId,
  apiBatchRestore
} from './api';
import getStatusMessage from '../static/detectionStatusMapping';
import { toggleLoadingWsiLists } from './devopsActions';
import { toggleTab, updateCounting } from './tabActions';
import { toggleLoadingTable, updateQueryInfo } from './tableActions';
import { getReportResult } from './reportAction';
import { fetchComments } from './comments';
// import { fetchTimeoutImage } from './timeoutAction';
import { initTimeoutImage } from './timeoutAction';
import { getIsInit } from '../selectors/timeoutSelectors';
import config from '../config';
import axios from 'axios';
import { getCurrentImageInfo } from '../selectors';
export const loadImages = (images) => ({
  type: 'LOAD_IMAGES',
  images
});

const loadImageRequest = (imageId) => ({
  type: 'LOAD_IMAGE_REQUEST',
  imageId
});

const loadImageSuccess = (imageVisualInfo) => ({
  type: 'LOAD_IMAGE_SUCCESS',
  imageVisualInfo
});

const loadImageFailure = (error) => ({
  type: 'LOAD_IMAGE_FAILURE',
  error
});

export function unreadImage(filename) {
  return {
    type: 'UNREAD_IMAGE',
    filename
  };
}

export function setFromImageId(fromImageId) {
  return {
    type: 'SET_FROM_IMAGE_ID',
    fromImageId
  };
}

export function setSearchedFromImageId(fromImageId) {
  return {
    type: 'SET_SEARCHED_FROM_IMAGE_ID',
    fromImageId
  };
}

export const setCachePage = (name, data) => ({
  type: 'SET_CACHE_PAGE',
  name,
  data
});

export function fetchImagesAsync(query, name) {
  const mode = getLoadImageMode(store.getState());
  let tab = getCurrentTab(store.getState());
  let sec = getSecondCurrentTab(store.getState());
  let topTab = tab === undefined ? { query: {} } : tab;
  let secendTab = sec === undefined ? { query: {} } : sec;
  const q = mode === 'search' ? { ...query } : { ...query, ...topTab.query, ...secendTab.query };
  return function(dispatch) {
    return getImages(q).then((response) => {
      if (response && response.data) {
        const dataSource = rawToImageData(response.data);
        dispatch(addImages(dataSource.data, dataSource.total, name));
      }
    });
  };
}

export function fetchImagesLoad() {
  const queryInfo = getQueryInfo(store.getState());
  let tab = getCurrentTab(store.getState());
  let sec = getSecondCurrentTab(store.getState());
  let topTab = tab === undefined ? { query: {} } : tab;
  let secendTab = sec === undefined ? { query: {} } : sec;
  return function(dispatch) {
    return getImages({
      ...queryInfo,
      ...topTab.query,
      ...secendTab.query
    })
      .then((response) => {
        if (response && response.data) {
          const images = rawToImageData(response.data);
          dispatch(loadImages(images));
          dispatch(toggleLoadingTable(false));
        }
      })
      .catch(() => {
        dispatch(toggleLoadingTable(false));
      });
  };
}

export function fetchNextPageImages(page, name) {
  return function(dispatch) {
    const mode = getLoadImageMode(store.getState());
    const queryInfo = getQueryInfo(store.getState());
    const searchQuery = getSearchQuery(store.getState());
    const q = mode === 'search' ? { fuzzy: searchQuery.fuzzy, page } : { ...queryInfo, page };
    return dispatch(fetchImagesAsync(q, name));
  };
}

export function fetchSearchImageAsync(query) {
  return function(dispatch) {
    return getImages(query).then(
      (response) => {
        const dataSource = rawToImageData(response.data);
        if (dataSource.data.length > 0) {
          dispatch(updateSearchQuery(query));
          dispatch(updateSearchImages({ data: dataSource.data, total: dataSource.total }));
        } else {
          message.warning(intl.get('IMAGE_NOT_FOUND'));
        }
        return response;
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

export function loadImageAsync(imageId, filename) {
  return function(dispatch) {
    dispatch(loadImageRequest(imageId));
    return loadImageVisualInfoFromServer(filename)
      .then(
        (response) => {
          dispatch(loadImageSuccess({ ...response.data, filename }));
        },
        (error) => {
          dispatch(loadImageFailure(error));
        }
      )
      .then(() => {
        dispatch(loadAnnotationsAsync(imageId));
      });
  };
}

export function loadImageByPath(imageId, filename) {
  const currentImageInfo = getCurrentImageInfo(store.getState());
  const imgBeforeUrl = setBaseUrl(config.baseUrl, currentImageInfo);

  return function(dispatch) {
    dispatch(loadImageRequest(imageId));
    return axios({
      url: imgBeforeUrl + '/deepzoom/load',
      method: 'POST',
      headers: {},
      data: { filename }
    })
      .then(
        (response) => {
          dispatch(loadImageSuccess({ ...response.data, filename }));
        },
        (error) => {
          dispatch(loadImageFailure(error));
        }
      )
      .then(() => {
        dispatch(loadAnnotationsAsync(imageId));
      });
  };
}

export function validateAndTriggerImageAsync(info) {
  return function(dispatch) {
    return fetchTagIdValidation(info.slide_id).then(
      (response) => {
        const { result } = response.data;
        if (result) {
          triggerDetectionOfImage(info.trigger);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

export function addAndTriggerImageAsync(info) {
  return function(dispatch) {
    return addUploadedImageToDatabase(info.add).then(
      (response) => {
        info.shouldDetect && triggerDetectionOfImage(info.trigger);
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

export const updateCurrentImageInfo = (update, key) => ({
  type: 'UPDATE_CURRENT_IMAGE_INFO',
  update,
  key
});

export const updateCurrentImageType = (clazz) => ({
  type: 'UPDATE_CURRENT_IMAGE_TYPE',
  clazz
});

export const updateCurrentImageInfoPart = (data) => ({
  type: 'UPDATE_CURRENT_IMAGE_INFO_PART',
  data
});
export function* watchLoadImage() {
  while (true) {
    const action = yield take('LOAD_IMAGE_SUCCESS');
    yield put({ type: 'READ_IMAGE', filename: action.imageVisualInfo.filename });
  }
}

export function* fetchImage() {  
  const queryInfo = yield select(getQueryInfo);
  let tab = yield select(getCurrentTab);
  let sec = yield select(getSecondCurrentTab);
  let topTab = tab === undefined ? { query: {} } : tab;
  let secendTab = sec === undefined ? { query: {} } : sec;
  try {
    const resp = yield call(getImages, {
      ...queryInfo,
      ...topTab.query,
      ...secendTab.query
    });
    const images = rawToImageData(resp.data);
    yield put(loadImages(images));
  } catch {
    cancelled();
  } finally {
    yield put(toggleLoadingTable(false));
  }
}

export const updateLoadImageMode = (mode) => ({
  type: 'UPDATE_LOAD_IMAGE_MODE',
  mode
});

export const updateSelectedImages = (selection) => ({
  type: 'UPDATE_IMAGE_SELECTION',
  selection
});

export const updateSearchQuery = (query) => ({
  type: 'UPDATE_SEARCH_QUERY',
  query
});

export const updateSearchImages = (resp) => ({
  type: 'UPDATE_SEARCH_IMAGES',
  resp
});

// 加载所选 与 加载全部
export function loadModeImages(selected, imgs, current) {
  let images = {};
  if (selected.total === 0) {
    images = imgs;
    updateLoadImageMode('query');
  } else {
    images = selected.data;
    updateLoadImageMode('select');
  }
  const path = {
    pathname: '/images-browser',
    state: {
      images,
      current
    }
  };
  return path;
}

export function* watchUpdateQueryInfo() {
  
  yield takeLatest('UPDATE_QUERY_INFO', function*(action) {
    yield put(toggleLoadingTable(true));
    if (window.location.pathname === '/mainpage') {  
      
      const isInit = getIsInit(store.getState());
      yield put(updateCounting());
      // yield put(updateSecondCount());
      // yield put(updateSelectedImages({ data: [], total: 0 }))
      yield fork(fetchImage);      
      if (isInit) {
        // yield put(fetchTimeoutImage());
      } else {
        const user = getCurrentUser(store.getState());
        if (user.id !== '') {
          yield put(initTimeoutImage());
        }
      }
    }
  });
}

export function collectImageAsync(imageId, remark = '') {
  return function(dispatch) {
    return collectImageAPI(imageId, remark).then(
      (response) => {
        message.success(intl.get('COLLECT_SUCCESS'));
        const user = getCurrentUser(store.getState());
        dispatch(updateCurrentImageInfo({ remark, collectors: [user.id] }, imageId));
      },
      (error) => {
        message.error(intl.get('COLLECT_FAILED'));
      }
    );
  };
}

export function getCollectionAsync(imageId) {
  return function(dispatch) {
    return getCollectionImageAPI(imageId).then(
      (resp) => {
        const remark = resp.data.remark;
        dispatch(updateCurrentImageInfo({ remark }, imageId));
      },
      (error) => {
        message.error(intl.get('GET_COLLECTION_FAILED'));
      }
    );
  };
}

export function markImageAsync(imageId, remark = '') {
  return function(dispatch) {
    return markImageAPI(imageId, remark).then(
      (response) => {
        message.success(intl.get('MARK_MODIFY_SUCCESS'));
        dispatch(updateCurrentImageInfo({ remark }, imageId));
      },
      (error) => {
        message.error(intl.get('MARK_MODIFY_FAILED'));
      }
    );
  };
}

export function discardImageAsync(imageId) {
  return function(dispatch) {
    return discardImageAPI(imageId).then(
      (response) => {
        message.success(intl.get('DISCARD_SUCCESS'));
        dispatch(updateCurrentImageInfo({ remark: null, collectors: [] }, imageId));
      },
      (error) => {
        message.error(intl.get('DISCARD_FAILED'));
      }
    );
  };
}

export function updateImage(imageId, option) {
  return async (dispatch) => {
    fetchUpdateImage(imageId, option)
      .then((resp) => {
        if (resp.status === 204) {
          Object.keys(option).forEach((key) => {
            message.success(
              intl.get('UPDATE_SUCCESS', { what: intl.get(key.toUpperCase()), message: '' })
            );
          });
        } else {
          message.error(resp.data.message);
        }
        if (window.location.pathname.indexOf('mainpage') !== -1) {
          const currentTab = getCurrentTabIdx(store.getState());
          dispatch(toggleTab(currentTab));
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 404) {
            Object.keys(option).forEach((key) => {
              message.error(
                intl.get('UPDATE_FAILED', {
                  what: intl.get(key.toUpperCase()),
                  message: intl.get('IMAGE_NOT_EXISTS')
                })
              );
            });
          } else if (err.response.status === 400) {
            Object.keys(option).forEach((key) => {
              message.error(
                intl.get('UPDATE_FAILED', {
                  what: intl.get('key.toUpperCase()'),
                  message: intl.get('ERROR_PARAMS_ERROR')
                })
              );
            });
          }
        }
      });
  };
}

function _toggleLoading(dispatch, position, loading) {
  if (position === 'wsi' || position === 'samba') {
    dispatch(toggleLoadingWsiLists(loading));
  }
}

export function uploadImage(position, option) {
  return async (dispatch) => {
    _toggleLoading(dispatch, position, true);
    fetchUploadImage(option)
      .then((resp) => {
        message.success(intl.get('UPLOAD_IMAGE_SUCCESS', { filename: option.filename }));
      })
      .catch((err) => {
        console.log(err);
        message.error(intl.get('UPLOAD_IMAGE_FAILED', { filename: option.filename }));
      })
      .finally(() => {
        _toggleLoading(dispatch, position, false);
      });
  };
}

export function updateImageInfo(imageId) {
  return async (dispatch) => {
    return getImageById(imageId)
      .then((response) => {
        const dataSource = rawToImageData(response.data);
        let image = dataSource.data && dataSource.data[0];
        image && dispatch(updateCurrentImageInfo({ ...image }, imageId));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function PipelineEdit(imageId, data) {
  const user = getCurrentUser(store.getState()).id;
  return async (dispatch) => {
    return apiPipelineEdit(data)
      .then((response) => {
        if (imageId !== '' && data.users[0] === user) {
          dispatch(pipelineEditing(imageId));
        }
        const queryInfo = getQueryInfo(store.getState());
        dispatch(updateQueryInfo(queryInfo));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function disDocToImage(id, data) {
  const queryInfo = getQueryInfo(store.getState());
  return async (dispatch) => {
    return apiPipelineReEdit(id, data)
      .then((response) => {
        message.success(intl.get('DIS_SUCCESS'));
        dispatch(updateQueryInfo(queryInfo));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function pipelineEditing(imageId) {
  return async (dispatch) => {
    return apiPipelineEditing(imageId)
      .then((response) => {
        dispatch(updateImageInfo(imageId));
        dispatch(getReportResult(imageId));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}
export function pipelineEdited(imageId, type) {
  const queryInfo = getQueryInfo(store.getState());
  return async (dispatch) => {
    return apiPipelineEdited(imageId)
      .then((response) => {
        if (type === 'report') {
          message.success(intl.get('ACTION_SUCCESS'));
          dispatch(updateImageInfo(imageId));
          dispatch(getReportResult(imageId));
        } else if (type === 'list') {
          dispatch(updateQueryInfo(queryInfo));
          dispatch(updateSelectedImages({ data: [], total: 0 }));
        }
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function batchPipelineEdited() {
  const queryInfo = getQueryInfo(store.getState());
  const selectedImages = getSelectedImages(store.getState());
  const images = selectedImages.data.map((i) => i.id);
  let params = {
    images: images
  };
  return async (dispatch) => {
    return apiBatchPipelineEdited(params)
      .then((response) => {
        message.success(intl.get('ACTION_SUCCESS'));
        dispatch(updateQueryInfo(queryInfo));
        dispatch(updateSelectedImages({ data: [], total: 0 }));
      })
      .catch((err) => {
        console.warn(err);
        toggleLoadingTable(false);
      });
  };
}

export function postPipeline(imageId, data) {
  return function(dispatch) {
    return apiPostPipeline(imageId, data).then(
      (response) => {
        if (response && response.data) {
          dispatch(
            updateCurrentImageInfo(
              { stage: response.data.stage, reporter: response.data.user },
              imageId
            )
          );
        }
      },
      (err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.data.error_code) {
            dispatch(fetchPipeline(imageId));
            console.log(getStatusMessage(resp.data.error_code).message);
          }
        }
      }
    );
  };
}

export function fetchPipeline(imageId) {
  return function(dispatch) {
    return apiFetchPipeline(imageId).then(
      (response) => {
        dispatch(
          updateCurrentImageInfo(
            { stage: response.data.stage, reporter: response.data.user },
            imageId
          )
        );
      },
      (err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.data.error_code && resp.data.error_code !== 5001) {
            message.error(intl.get(getStatusMessage(resp.data.error_code).message));
          }
        }
      }
    );
  };
}

export function updateCurrentImage(image) {
  return function(dispatch) {
    if (image) {
      dispatch(setCurrentImageInfo(image));
      // dispatch(loadImageAsync(image.key, image.filename));
      dispatch(loadImageByPath(image.key, image.filename));
      dispatch(getReportResult(image.key));
      dispatch(fetchComments(image.key));
      // dispatch(postPipeline(image.key, { stage: 7 }));
    }else{
      message.warning(intl.get('NOT_HAS_IMAGE'))
    }
  };
}

export function updateNextImage() {
  return function(dispatch) {
    const next = getNextImageInfo(store.getState());
    const mode = getLoadImageMode(store.getState());
    if (next) {
      dispatch(updateCurrentImage(next));
      if (mode !== 'search') {
        dispatch(setSearchedFromImageId(next.id));
      }
    }
  };
}

export function updatePreviousImage() {
  return function(dispatch) {
    const previous = getLastImageInfo(store.getState());
    const mode = getLoadImageMode(store.getState());
    if (previous) {
      dispatch(updateCurrentImage(previous));
      if (mode !== 'search') {
        dispatch(setSearchedFromImageId(previous.id));
      }
    }
  };
}

export function fetchImageInfo(imageId) {
  return function(dispatch) {
    return apiFetchImageInfo(imageId).then(
      (response) => {
        if (response && response.data) {
          const image = rawToImageData(response.data);
          dispatch(updateCurrentImage(image.data ? image.data[0] : []));
        }
      },
      (err) => {
        if (err.response) {
          const resp = err.response;
          if (resp.data.error_code) {
            message.error(intl.get(getStatusMessage(resp.data.error_code).message));
          }
        }
      }
    );
  };
}

export function sendReport(imageId) {
  const queryInfo = getQueryInfo(store.getState());
  return async (dispatch) => {
    apiSendReport(imageId)
      .then((resp) => {
        message.success(intl.get('SEND_SUCCESS'));
        dispatch(updateQueryInfo(queryInfo));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function printReport(imageId) {
  const queryInfo = getQueryInfo(store.getState());
  return async (dispatch) => {
    apiPrintReport(imageId)
      .then((resp) => {
        message.success(intl.get('PRINT_SUCCESS'));
        dispatch(updateQueryInfo(queryInfo));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function batchSendReport() {
  const queryInfo = getQueryInfo(store.getState());
  const selectedImages = getSelectedImages(store.getState());
  const images = selectedImages.data.map((i) => i.id);
  let params = {
    images: images
  };
  return async (dispatch) => {
    apiBatchSendReport(params)
      .then((resp) => {
        message.success(intl.get('SEND_SUCCESS'));
        dispatch(updateQueryInfo(queryInfo));
      })
      .catch((err) => {
        console.warn(err);
        toggleLoadingTable(false);
      });
  };
}

export function batchChangeReportDoctor(users) {
  const queryInfo = getQueryInfo(store.getState());
  const selectedImages = getSelectedImages(store.getState());
  const images = selectedImages.data.map((i) => i.id);
  let params = {
    images: images,
    users: users
  };
  return async (dispatch) => {
    apiBatchRedistribute(params)
      .then((resp) => {
        message.success(intl.get('MARK_MODIFY_SUCCESS'));
        dispatch(updateQueryInfo(queryInfo));
      })
      .catch((err) => {
        console.warn(err);
        toggleLoadingTable(false);
      });
  };
}

export function batchPrintReport() {
  const queryInfo = getQueryInfo(store.getState());
  const selectedImages = getSelectedImages(store.getState());
  const images = selectedImages.data.map((i) => i.id);
  let params = {
    images: images
  };
  return async (dispatch) => {
    apiBatchPrintReport(params)
      .then((resp) => {
        message.success(intl.get('PRINT_SUCCESS'));
        dispatch(updateQueryInfo(queryInfo));
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}

export function batchDisReport(data) {
  const queryInfo = getQueryInfo(store.getState());
  const selectedImages = getSelectedImages(store.getState());
  const images = selectedImages.data.map((i) => i.id);
  let params = {
    images: images,
    ...data
  };
  return async (dispatch) => {
    apiBatchDisReport(params)
      .then((resp) => {
        message.success(intl.get('DIS_SUCCESS'));
        dispatch(updateQueryInfo(queryInfo));
      })
      .catch((err) => {
        console.warn(err);
        toggleLoadingTable(false);
      });
  };
}

export function editByImageId(imageId, params) {
  const queryInfo = getQueryInfo(store.getState());
  return async (dispatch) => {
    apiEditByImageId(imageId, params)
      .then((resp) => {
        message.success(intl.get('MARK_MODIFY_SUCCESS'));
        dispatch(updateQueryInfo(queryInfo));
      })
      .catch((err) => {
        message.error(intl.get('MARK_MODIFY_FAILED'));
        console.warn(err);
      });
  };
}

export function batchRestore() {
  const queryInfo = getQueryInfo(store.getState());
  const selectedImages = getSelectedImages(store.getState());
  const images = selectedImages.data.map((i) => i.id);
  let params = {
    images: images
  };
  return async (dispatch) => {
    apiBatchRestore(params)
      .then((resp) => {
        message.success(intl.get('RESTORE_SUCCESS'));
        dispatch(updateQueryInfo(queryInfo));
        dispatch(updateSelectedImages({ data: [], total: 0 }));
      })
      .catch((err) => {
        console.warn(err);
        toggleLoadingTable(false);
      });
  };
}
