import React, { Component } from 'react';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import { Button } from 'antd';
import style from './index.less';
import { isNoVal } from '../../utils/dataUtils';
import { updateSwarmBymachineId, delSwarmBymachineId } from '../../actions/statistics';
const stateBtnInfo = {
  accepted: {
    btnClass: 'danger_btn',
    btnText: 'BREAKOFF'
  },
  leaved: {
    btnClass: 'parimary_btn',
    btnText: 'CONCENT'
  }
};
class BeautifulTableState extends Component {
  constructor() {
    super();
    this.state = {};
  }

  changeSwarmState() {
    this.props.updateSwarmBymachineId(this.props.record.id);
  }
  rejectSwarm() {
    this.props.delSwarmBymachineId(this.props.record.id);
  }

  render() {
    const { record } = this.props;
    return (
      <div className={style.node_state}>
        {record.state === 'applying' ? (
          <>
            <Button className={style.parimary_btn} onClick={() => this.changeSwarmState()}>
              {intl.get('AGREE')}
            </Button>
            <Button className={style.danger_btn} onClick={() => this.rejectSwarm()}>
              {intl.get('REJECT')}
            </Button>
          </>
        ) : (
          !isNoVal(record.state) && (
            <>
              <span className={style['state_' + record.state]}>
                <span style={{ fontWeight: 'bold' }}>·</span>&nbsp;
                {intl.get(record.state?.toUpperCase() + '_STATE')}
              </span>
              {stateBtnInfo[record.state]?.btnText && (
                <Button
                  className={style[stateBtnInfo[record.state]?.btnClass]}
                  onClick={() => this.changeSwarmState()}
                >
                  {intl.get(stateBtnInfo[record.state]?.btnText)}
                </Button>
              )}
            </>
          )
        )}
      </div>
    );
  }
}

export default connect((state) => ({}), { updateSwarmBymachineId, delSwarmBymachineId })(
  BeautifulTableState
);
