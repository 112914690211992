export const formItemLayout = {
  labelCol: {
    xs: { span: 30 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 30 },
    sm: { span: 14 }
  }
};

export const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 4, offset: 20 }
};
