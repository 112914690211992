import { Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Icon from '../Iconfont';
import style from './index.less';

class PopoverIconButton extends Component {
  static propTypes = {
    type: PropTypes.string,
    shape: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func,
    popContent: PropTypes.node,
    iconClass: PropTypes.any,
    btnClass:PropTypes.string,
    place:PropTypes.string,
    onMouseEnter: PropTypes.func,
  };
  static defaultProps = {
    disabled: false
  }

  render() {
    const { id, popContent, shape, type, icon, color, onClick, disabled,btnClass,place,onMouseOver,onMouseOut} = this.props;
    return (
      <Tooltip title={popContent}  placement={place}>
        <Button disabled={disabled} id={id} className={btnClass?btnClass:style.iconButton} shape={shape} type={type} onClick={onClick} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
          <Icon type={icon} style={{ color }} />
        </Button>
      </Tooltip>
    );
  }
}

export default PopoverIconButton;
