import { Button } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {getClassLimit,getClassMarkLimit} from '../../selectors/index'
import {triggerAnnotationLimit,triggerMarkAnnotationLimit} from '../../actions/annotationActions'
import intl from 'react-intl-universal';
import style from './index.less';

class EndMessage extends Component {

    handldeLimitChanged = (checked)=>{
      if(this.props.role==="mark"){
        this.props.triggerMarkAnnotationLimit(!checked)
      }else{
        this.props.triggerAnnotationLimit(!checked)
      }
    }

    render() {
      return (
        <div className={style.endMessage}>
          {intl.get("ANNOTATION_LIST_END_MESSAGE",{hasMore:this.props.canLoadMore, count:this.props.total})}
          {this.props.canLoadMore ?
            <Button
              type="link"
              onClick={this.handldeLimitChanged}
            >{intl.get("LOAD_MORE_ANNOTATIONS")}</Button> : ''}
        </div>
      )
    }
}

const mapStateToProps = (state,ownProps) => {
  return {
    limit: ownProps.role==="mark"?getClassMarkLimit(state):getClassLimit(state)
  };
};
export default connect(mapStateToProps, {triggerMarkAnnotationLimit,triggerAnnotationLimit})(EndMessage);
