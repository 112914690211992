import React, { Component } from 'react';
import { Select, message } from 'antd';
import { connect } from 'react-redux';
const { Option } = Select;
import intl from 'react-intl-universal';

class DoctorList extends Component {
  state = {
    pageSize: 100,
    scrollPage: 1,
    keyWords: '',
    optionData: this.props.doctorList.slice(0, 100),
    selectedSingle: '',
    selectedList: []
  };

  componentDidMount() {
    this.setPage();
    this.props.parentName === 'report' && this.setDefault();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.currentImageInfo !== this.props.currentImageInfo &&
      this.props.parentName === 'report'
    ) {
      this.setPage();
      this.setDefault();
    }
  }

  setPage() {
    this.setState(
      {
        scrollPage: parseInt(this.props.doctorList.length / 100 + 1, 10),
        keyWords: ''
      },
      () => {
        this.loadOption(this.state.scrollPage + 1);
      }
    );
  }

  setDefault() {
    // let v = this.props.planReporter.filter(
    //   (item) => item.imageId === this.props.currentImageInfo.id
    // );
    // if (v.length) {
    //   this.setState({ selectedSingle: v[0].reporter });
    // } else {
    //   this.setState({ selectedSingle: this.props.currentUser.id });
    // }
    if(this.props.doctorList.filter(item=>item.id===this.props.currentUser.id).length<=0){
      this.setState({ selectedSingle: this.props.currentUser.realname });
    }else{
      this.setState({ selectedSingle: this.props.currentUser.id });
    }
  
  }

  onChange = (value) => {
    let list = value;
    this.setState({
      optionData: this.props.doctorList.slice(0, 100)
    });
    if (this.props.parentName === 'batch') {
      if (value.length > this.props.selecteds.total) {
        message.warning(intl.get('ALLOCATION_WARN'));
        list = list.slice(0, this.props.selected.total);
        return;
      }
      this.setState({
        selectedList: list
      });
    } else {
      this.setState({
        selectedSingle: value
      });
    }
    this.props.selected(list);
  };

  onBlur = () => {
    this.setPage();
  };

  onSearch = (val) => {
    if (!this.timer) {
      const that = this;
      this.timer = setTimeout(function() {
        that.searchValue(val);
        that.timer = null;
      }, 300);
    }
    this.setState({ keyWords: val });
  };

  searchValue = (value) => {
    let data_ = this.props.doctorList.filter((item) => item.realname.indexOf(value) > -1);
    if (data_.length > 100 || value === '') {
      data_ = data_.slice(0, 100);
    }
    this.setState({ optionData: data_ });
  };

  loadOption = (pageIndex) => {
    const { pageSize, keyWords } = this.state;
    const newPageSize = pageSize * (pageIndex || 1);
    let newOptionsData = [];
    let len;
    if (this.props.doctorList.length > newPageSize) {
      len = newPageSize;
    } else {
      len = this.props.doctorList.length;
    }
    if (keyWords) {
      let data_ =
        this.props.doctorList.filter((item) => item.realname.indexOf(keyWords) > -1) || [];
      data_.forEach((item, index) => {
        if (index < len) {
          newOptionsData.push(item);
        }
      });
    } else {
      this.props.doctorList.forEach((item, index) => {
        if (index < len) {
          newOptionsData.push(item);
        }
      });
    }
    this.setState({ optionData: newOptionsData });
  };

  handleScroll = (e) => {
    e.persist();
    const { target } = e;
    const rmHeight = target.scrollHeight - target.scrollTop;
    const clHeight = target.clientHeight;
    if (rmHeight === 0 && clHeight === 0) {
      this.setState({ scrollPage: 1 });
    } else {
      if (rmHeight < clHeight + 5) {
        const { scrollPage } = this.state;
        this.setState({ scrollPage: scrollPage + 1 });
        this.loadOption(scrollPage + 1);
      }
    }
  };

  render() {
    const { optionData, selectedSingle, selectedList } = this.state;
    const { parentName, width, keyValue } = this.props;
    return (
      <Select
        showSearch
        allowClear
        mode={parentName === 'batch' ? 'multiple' : ''}
        onPopupScroll={this.handleScroll}
        style={{ width: width }}
        placeholder={intl.get('SELECT_DOCTOR')}
        optionFilterProp="children"
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onSearch={this.onSearch}
        key={parentName === 'batch' ? [] : keyValue}
        value={parentName === 'batch' ? selectedList : selectedSingle}
        filterOption={(input, option) => {
          return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
      >
        {optionData.map((item) => (
          <Option key={item.id} value={item.id}  title={item.realname}>
            {item.realname}
          </Option>
        ))}
      </Select>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selecteds: state.loadImages.selected,
    currentImageInfo: state.imageSelection.currentImageInfo,
    planReporter: state.report.planReporter,
    currentUser: state.currentUser
  };
};

export default connect(mapStateToProps, {})(DoctorList);
