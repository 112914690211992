import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { withdrawReport, getReportRowResult, submitReportResult } from '../../actions/reportAction';
import { sendReport, pipelineEdited } from '../../actions/imageActions';
import { connect } from 'react-redux';
import ChangeDoctor from './ChangeDoctor';
import { canRender } from '../Can';
import PrintAction from './PrintAction';
import SubAction from './SubAction';
import UpdateTagId from './UpdateTagId';
import PreAllocation from '../../components/TableTabs/PreAllocation';
import { isPartEnd } from '../../utils/urlUtils';
class StageActions extends Component {
  withDraw(id) {
    this.props.withdrawReport(id);
  }
  submitImage(id) {
    this.props.pipelineEdited(id, 'list');
  }
  send(id) {
    this.props.sendReport(id);
  }
  pushStatus() {
    const { image } = this.props;
    getReportRowResult(image.id).then((res) => {
      this.props.submitReportResult(image.id,'list');
    });
  }

  render() {
    const { image, currentUser } = this.props;
    const isReporter = currentUser.username === image.reporter;
    const disStyle = { color: '#999', cursor: 'auto' };
    return (
      <>
        {[2].includes(image.stage) && (
          <>
            {canRender(currentUser.permissions, 'pipeline:modify') && (
              <PreAllocation image={image} />
            )}
            <UpdateTagId image={image} />
          </>
        )}

        {[3, 4, 5].includes(image.stage) && (
          <>
            <SubAction image={image} currentUser={currentUser} />
            <UpdateTagId image={image} />
          </>
        )}
        {[6, 7].includes(image.stage) &&
          (isReporter ? (
            <>
              <span onClick={this.submitImage.bind(this, image.id)}>
                {intl.get('SUBMIT_SENIOR')}
              </span>
              <ChangeDoctor image={image} opt={isReporter} />
            </>
          ) : (
            <>
              <span style={disStyle}>{intl.get('SUBMIT_SENIOR')}</span>
              <span style={disStyle}>
                <ChangeDoctor />{' '}
              </span>
            </>
          ))}
        {[8].includes(image.stage) &&
          (isReporter ? (
            <>
              <span style={{ cursor: 'pointer' }} onClick={() => this.withDraw(image.id)}>
                {intl.get('WITHDRAW')}
              </span>
              {![1, 2].includes(image.push_status) && isPartEnd()!=='expert' && (
                <span onClick={this.pushStatus.bind(this, image.id)}>
                  {intl.get('PUSH_SUBMIT')}
                </span>
              ) }
            </>
          ) : (
            <>
              <span style={disStyle}>{intl.get('WITHDRAW')}</span>
              {![1, 2].includes(image.push_status)  && isPartEnd()!=='expert' && (
                <span style={disStyle}>{intl.get('PUSH_SUBMIT')}</span>
              )}
            </>
          ))}
        {[9].includes(image.stage) && (
          <>
            <PrintAction image={image} title="preview" stage={9}/>
            <PrintAction image={image} title="print" stage={9}/>
          </>
        )}
        {[10].includes(image.stage) && (
          <>
            {canRender(currentUser.permissions, 'client:report:sent') ? (
              <span onClick={this.send.bind(this, image.id)}> {intl.get('SEND')}</span>
            ) : (
              <span style={disStyle}> {intl.get('SEND')}</span>
            )}
            <PrintAction image={image} title="print" stage={10}/>
          </>
        )}
        {[11].includes(image.stage) && <span style={disStyle}> {intl.get('RE_SEND')}</span>}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser
  };
};

export default connect(mapStateToProps, {
  withdrawReport,
  sendReport,
  submitReportResult,
  pipelineEdited
})(StageActions);
