import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAnnotationUsers } from '../../selectors/userSelectors';
import { getImageLevelResult } from '../../selectors/imageSelector';
import UserCheckbox from './UserCheckbox';
import style from './index.less';
import ImageLevelResultTag from '../ImageLevelResultTag';
import intl from 'react-intl-universal'
class AnnotationUserSelector extends Component {
  render() {
    return (
      <div className={style.users}>
        {this.props.annotationUsers.length>0?
          this.props.annotationUsers.map((u,i) => (
            <UserCheckbox key={u} user={u} ii={i} imageLevelResult={this.props.imageLevelResult}/>
          )):
          <>
            {intl.get("AI_JUDGE")}：
            <ImageLevelResultTag result={this.props.imageLevelResult} shouldBefiltered={true} />
          </>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    annotationUsers: getAnnotationUsers(state),
    imageLevelResult: getImageLevelResult(state)
  };
};

export default connect(mapStateToProps)(AnnotationUserSelector);
