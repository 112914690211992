import React, { Component } from 'react';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import { Button,message } from 'antd';
import { canRender } from '../Can';
import {
    addSection,
  } from '../../actions/productionAction';
class AddPro extends Component {
    addPro() {
        const { selected } = this.props;
        if (this.props.isNoSelect()) return false;
        let l = selected.list.filter((s) => s.reproduce !== true);
        if (l.length > 0) {
          message.warning(intl.get('PRO_SELECTED_MORE'));
          return false;
        } else {
          this.props.addSection();
        }
      }

  render() {
      const {currentUser} = this.props
    return (
      <Button
        type="primary"
        disabled={canRender(currentUser.permissions, 'section:add') ? false : true}
        onClick={() => this.addPro()}
      >
        {intl.get('PRO_ADD')}
      </Button>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selected: state.production.selected,
  };
};

export default connect(mapStateToProps, {addSection})(AddPro);
