import { initReduxData } from '../utils/dataUtils';
export const initial = {
  productionList: {
    items: [],
    total: 0
  },
  selected: {
    list: [],
    total: 0
  },
  queryInfo: {
    page: 0,
    limit: 10,
    registrate_from: '',
    registrate_to: '',
    sortBy: 'latest_time',
    sortAsc: false,
    producer: ''
  },
  selectedSingle: {
    id: ''
  },
  selectedProducer: ''
};

function production(state = initial, action) {
  state.selected = initReduxData(initial.selected, state.selected);
  state.queryInfo = initReduxData(initial.queryInfo, state.queryInfo);
  switch (action.type) {
    case 'SET_PRODUCTION_LIST':
      return { ...state, productionList: action.data };
    case 'SET_PRODUCTION_SELECTED_LIST':
      return { ...state, selected: action.data };
    case 'SET_PRODUCTION_QUERY_INFO':
      return { ...state, queryInfo: { ...state.queryInfo, ...action.data } };
    case 'SET_PRODUCTION_SELECTED_SINGLE':
      return { ...state, selectedSingle: action.data };
    case 'SET_PRODUCTION_SELECTED_PRODUCER':
      return { ...state, selectedProducer: action.data };
    case 'INIT_PRODUCTION_QUERY_INFO':
      return { ...state, queryInfo: { ...state.queryInfo, ...action.data } };

    default:
      return state;
  }
}

export default production;
