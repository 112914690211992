import cfg from '../config';
import { initReduxData } from '../utils/dataUtils';
const initial = {
  imageListColumns: cfg.imageListColumns,
  defaultCancerType: cfg.defaultCancerType,
  cancerTypes: cfg.detectionTypes,
  visibleConfidence: cfg.visibleConfidence,
  visibleLabel: cfg.visibleLabel,
  currentClassification: cfg.currentClassification,
  suspectedAnomalousDiameter: cfg.suspectedAnomalousDiameter,
  frontVersion: cfg.frontVersion,
  pageName:"check",
  general:{
    junior_distribute_roles:[],
    senior_distribute_roles:[],
    product_mode:1,
    auto_distribute_mode:0,
    auto_distribute_report:false,
    auto_distribute_times:[],
    image_size_lower_bound_in_MB:10,
    image_file_format_to_filter:[],
    node_mode:0
  }
};

export default function config(state = initial, action) {
  state.general = initReduxData(initial.general, state.general);
  switch (action.type) {
    case 'UPDATE_IMAGE_LIST_COLUMNS':
      return { ...state, imageListColumns: action.imageListColumns };
    case 'RESET_IMAGE_LIST_COLUMNS':
      return {
        ...state,
        imageListColumns: action.imageListColumns
          ? action.imageListColumns
          : initial.imageListColumns
      };
    case 'UPDATE_DEFAULT_CANCER_TYPE':
      return { ...state, defaultCancerType: action.cancerType };
    case 'UPDATE_CANCER_TYPES':
      return { ...state, cancerTypes: action.cancerTypes };
    case 'UPDATE_VISIBLE_CONFIDENCE':
      return { ...state, visibleConfidence: action.visibleConfidence };
    case 'UPDATE_VISIBLE_NEGATIVE_LESION':
      return { ...state, visibleNegativeLesion: action.visibleNegativeLesion};
    case 'UPDATE_VISIBLE_LABEL':
      return { ...state, visibleLabel: action.visibleLabel };
    case 'UPDATE_CURRENT_CLASSIFICATION':
      return { ...state, currentClassification: action.currentClassification };
    case 'UPDATE_SUSPECTED_ANOMALOUS_DIAMETER':
      return { ...state, suspectedAnomalousDiameter: action.suspectedAnomalousDiameter };
    case 'VERSION_UPDATE':
      return { ...state, frontVersion: action.version };
    case 'SET_CURRENT_PAGE':
      return { ...state, pageName: action.page };
    case 'SET_GENERAL':
        return { ...state, general: action.data };
      
    default:
      return state;
  }
}
