import React, { Component } from 'react';
import FallbackImage from '../FallbackImage';

class RotatableImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0
    };
  }

  rotate = () => {
    const { current } = this.state;
    this.setState({ current: (current + 90) % 360 });
  };

  render() {
    const { src, imgStyle } = this.props;
    const { current } = this.state;
    return (
      <FallbackImage
        src={src}
        imgStyle={{ ...imgStyle, transform: `rotate(${current}deg)` }}
        onClick={this.rotate}
        width="200"
        height="200"
        imgClass="roateImg"
      />
    );
  }
}

export default RotatableImage;
