import React, { Component } from 'react'
import ReactECharts from 'echarts-for-react';

export class BaseChart extends Component {

    render() {
        return (
            <ReactECharts
            option={this.props.option}
            notMerge={true}
            style={this.props.style}
            lazyUpdate={true}
            theme="theme_name"
        />

        )
    }
}

export default BaseChart
