import { Col, Row, Checkbox, Input, DatePicker } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { summaryInfo1, summaryInfo3, opertaionAfterList } from './checkInfo';
import intl from 'react-intl-universal';
import style from './index.less';
import styles from '../Report/SampleCells.less';
import { updateChckInInfoParams } from '../../actions/checkInAction';
import formatTimestamp from '../../utils/dateUtils';
import moment from 'moment';
import { isNoVal } from '../../utils/dataUtils';
class SummaryInfo extends Component {
  constructor() {
    super();
    this.state = {
      operation: [],
      operationInfo: '',
      cell_examination_date: '',
      cell_examination_result: '',
      hpv_examination_date: '',
      hpv_examination_result: '',
      other_surgery: '',
      other_note: ''
    };
  }
  componentDidMount() {
    this.props.onRef(this);
    this.initalSummaryValue();
  }
  initalSummaryValue() {
    const {
      clinic_history,
      cell_examination_date,
      cell_examination_result,
      hpv_examination_date,
      hpv_examination_result,
      other_surgery,
      other_note
    } = this.props.checkInfo;
    this.setState({
      operation: clinic_history,
      cell_examination_date,
      cell_examination_result,
      hpv_examination_date,
      hpv_examination_result,
      other_surgery,
      other_note,
    },()=>{
      this.onChange(clinic_history)
    });
  }
  onChange(checkedValues) {
    let val = this.isCheckHasSur(checkedValues);
    let info = '';
    val.forEach((item) => {
      summaryInfo1.forEach((summary) => {
        if (item === summary.value) {
          if (item === 'other_note') {
            info = intl.get(summary.label) + '(' + this.state.other_note + ')' + info;
          } else {
            info = intl.get(summary.label) + ',' + info;
          }
        }
      });
      opertaionAfterList.forEach((operation) => {
        if (item === operation.value) {
          if (item === 'other_surgery') {
            info = intl.get(operation.text) + '(' + this.state.other_surgery + '),' + info;
          } else {
            info = intl.get(operation.text) + ',' + info;
          }
        }
      });
    });
    !val.includes('other_surgery') && this.setState({ other_surgery: '' });
    !val.includes('other_note') && this.setState({ other_note: '' });

    this.setState({
      operationInfo: info,
      operation: val
    });
  }
  saveSummaryInfo() {
    const {
      cell_examination_date,
      cell_examination_result,
      hpv_examination_date,
      hpv_examination_result,
      other_surgery,
      other_note
    } = this.state;
    let cell_date_title = intl.get('SUMMARY_CELL_DATE') + ':';
    let res_title = intl.get('SUMMARY_REASULT') + ':';
    let hpv_date_title = intl.get('SUMMARY_HPV_DATE') + ':';
    let cell_date = !isNoVal(cell_examination_date)
      ? cell_date_title + formatTimestamp(cell_examination_date, 'YYYY-MM-DD') + ','
      : cell_date_title + '　';
    let cell_res = !isNoVal(cell_examination_result)
      ? res_title + cell_examination_result + ';'
      : res_title + '　';
    let hpv_date = !isNoVal(hpv_examination_date)
      ? hpv_date_title + formatTimestamp(hpv_examination_date, 'YYYY-MM-DD') + ','
      : hpv_date_title + '　';
    let hpv_res = !isNoVal(hpv_examination_result)
      ? res_title + hpv_examination_result + ';'
      : res_title + '　';
    let cell_hpv = cell_date + cell_res + hpv_date + hpv_res;
    let fin_cell_hpv = (!isNoVal(hpv_examination_result) || !isNoVal(hpv_examination_date) || !isNoVal(cell_examination_result) || !isNoVal(cell_examination_date)) ?cell_hpv:''
    let info = this.state.operationInfo + '\n' + fin_cell_hpv;

    let p = {
      clinic_history: this.state.operation,
      clinic_summary: info,
      cell_examination_date: cell_examination_date,
      cell_examination_result: cell_examination_result,
      hpv_examination_date: hpv_examination_date,
      hpv_examination_result: hpv_examination_result,
      other_surgery: other_surgery,
      other_note: other_note
    };
    this.props.updateChckInInfoParams(p);
  }
  getStyles(checkedText) {
    const { operation } = this.state;
    return operation.includes(checkedText) ? styles.active__checkbox : styles.normal__checkbox;
  }

  onChangeDate(data, dateString, name) {
    this.setState({
      [name]: dateString
    });
  }

  onChangeInput = (event, name) => {
    this.setState(
      {
        [name]: event.target.value
      },
      () => {
        if (name === 'other_note' || name === 'other_surgery') {
          this.onChange(this.state.operation);
        }
      }
    );
  };

  isCheckHasSur = (checkedValues) => {
    const list = ['hysterectomy', 'conization', 'oophorotomy', 'radiotherapy', 'other_surgery'];
    let operation = checkedValues;
    let idx = operation.indexOf('has_surgery');
    for (let i = 0; i < list.length; i++) {
      if (operation.includes(list[i])) {
        idx <= -1 && operation.push('has_surgery');
        return operation;
      }
    }
    idx > -1 && operation.splice(idx, 1);
    return operation;
  };

  render() {
    const { operation } = this.state;
    return (
      <div className={style.summary__modal}>
        <Row>
          <Checkbox.Group
            style={{ width: '100%' }}
            onChange={this.onChange.bind(this)}
            value={operation}
          >
            <Col span={16}>
              <Row>
                {summaryInfo1.map((item, index) => {
                  return (
                    <Col span={item.span} key={index}>
                      <Checkbox  value={item.value} name={item.label}>
                        {intl.get(item.label)}
                      </Checkbox>
                      {item.label === 'GENEREL' && (
                        <Input
                          disabled={operation.includes('other_note') ? false : true}
                          style={{ width: '300px' }}
                          onChange={(e) => this.onChangeInput(e, 'other_note')}
                          value={this.state.other_note}
                        />
                      )}
                    </Col>
                  );
                })}
              </Row>
            </Col>
            <Col span={8}>
              <div>
                <div className={styles.wrap__child8}>
                  <div className={styles.tree__left} style={{ width: '100px' }}>
                    <Checkbox value="has_surgery" />
                    <span className={styles.text__span}> {intl.get('OPERARION_AFTER')}</span>
                  </div>
                  <div className={styles.tree__right}>
                    {opertaionAfterList.map((item, key) => {
                      return (
                        <div key={key} className={styles.child__item}>
                          <Checkbox
                            // className={this.getStyles(item.value)}
                            name={item.label}
                            value={item.value}
                          >
                            {intl.get(item.text)}
                          </Checkbox>
                          {item.text === 'GENEREL' && (
                            <Input
                              disabled={operation.includes('other_surgery') ? false : true}
                              style={{ width: '100px' }}
                              onChange={(e) => this.onChangeInput(e, 'other_surgery')}
                              value={this.state.other_surgery}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Col>
          </Checkbox.Group>
        </Row>
        {summaryInfo3.map((item, index) => {
          return (
            <Row key={index} className={style.summary__date}>
              {item.children.map((child, i) => {
                return (
                  <Col span={12} key={i}>
                    <Col span={child.span}>{intl.get(child.label)}</Col>
                    <Col span={12}>
                      {child.type === 'DatePicker' && (
                        <DatePicker
                          format="YYYY-MM-DD"
                          showTime={child.showTime}
                          onChange={(data, dateString) =>
                            this.onChangeDate(data, dateString, child.name)
                          }
                          style={{ minWidth: '100%' }}
                          value={
                            this.state[child.name] !== '' && this.state[child.name] !== null
                              ? moment(this.state[child.name])
                              : null
                          }
                        />
                      )}
                      {child.type === 'Input' && (
                        <Input
                          value={this.state[child.name]}
                          onChange={(e) => this.onChangeInput(e, child.name)}
                        />
                      )}
                    </Col>
                  </Col>
                );
              })}
            </Row>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clinic_history: state.checkIn.checkInfo.clinic_history,
    checkInfo: state.checkIn.checkInfo
  };
};

export default connect(mapStateToProps, { updateChckInInfoParams })(SummaryInfo);
