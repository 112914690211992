import React, { Component } from 'react';
import { connect } from 'react-redux';
import style from './index.less';
// import intl from 'react-intl-universal';
import MyIcon from '../Iconfont';
import { Tooltip } from 'antd';
class ProPathId extends Component {
  setAction() {
    const { infoList } = this.props;
    if (infoList.reproduce) {
      return (
        <span>
          {infoList.pathology_id} <MyIcon type="icon-reproduce" />
        </span>
      );
    } else {
      return <span>{infoList.pathology_id}</span>;
    }
  }

  render() {
    const { infoList } = this.props;
    return (
      <Tooltip  title={infoList.pathology_id}>
        <div className={style.pro_status}>{this.setAction()}</div>
      </Tooltip>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(ProPathId);
