import PropTypes from 'prop-types';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { loadModeImages } from '../../actions/imageActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { shouldLoad } from './index';
import { getCurrentUser } from '../../selectors/userSelectors';
import { getImages, getSelectedImages } from '../../selectors/imageSelector';
import StageActions from './StageActions';
import Can from '../Can';
import './index.css';
import {setActionDisabled } from '../../utils/urlUtils';
class Action extends Component {
  static propTypes = {
    image: PropTypes.object,
    groundTruthList: PropTypes.object
  };

  render() {
    const { image, currentTab, currentUser } = this.props;
    const partStyle = setActionDisabled()
    return (
      <div
        style={{ color: '#409eff', ...partStyle }}
        className="table_action"
      >
        {/* 阅片 */}
        {[0, 1, 2, 3, 4, 8].includes(currentTab) && ![9, 10, 11].includes(image.stage) && (
          <Can
            key="VIEW"
            permissions={currentUser.permissions}
            perform="client:image:view"
            yes={() => (
              <span
                style={{ cursor: 'pointer', pointerEvents: 'auto', opacity: 1,color:'rgb(0,150,255)' }}
                onClick={(e) => {
                  const { selected, images, image } = this.props;
                  let path = loadModeImages(selected, images, image);
                  this.props.history.push(path);
                }}
              >
                {intl.get('ACTION_VIEW')}
              </span>
            )}
            no={() => (
              <span style={{ cursor: 'pointer', color: '#999' }}>{intl.get('ACTION_VIEW')}</span>
            )}
          />
        )}
        <StageActions image={image} />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    images: getImages(state),
    selected: getSelectedImages(state),
    currentUser: getCurrentUser(state),
    currentTab: state.tabState.currentTab,
    tabs: state.tabState.tabs
  }),
  {}
)(withRouter(Action));
