import { Tabs } from 'antd';
import React, { Component } from 'react';
import defaultTopTabs from '../../static/defaultTopTabs';
import intl from 'react-intl-universal';
import styles from './index.less';
import { getConfig } from '../../selectors/configSelector';
import { getCurrentUser } from '../../selectors/userSelectors';
import { connect } from 'react-redux';
import SecondTabs from './SecondTabs';
import GTUpload from '../GTUpload';
import config from '../../config';
import { initializeIO } from '../../actions/progressActions';
import { updateDoctorList, updateRolesUsers } from '../../actions/reportAction';
import { getRole } from '../../actions/productionAction';
import {
  initializeTabs,
  initializeSecondTabs,
  removeTab,
  toggleTab,
  stoggleSecondTab
} from '../../actions/tabActions';
import SearchTab from './SearchTab';
import Icon from '../Iconfont';
import { isPartEnd } from '../../utils/urlUtils';
const { TabPane } = Tabs;
class TopTabs extends Component {
  constructor() {
    super();
    this.state = {
      TopTabsList: [],
      SecondTabsList: []
    };
  }
  componentDidMount() {
    this.init();
  }

  init() {
    const { currentUser } = this.props;
    let partEnd = isPartEnd();
    //单机
    let tabLocal = defaultTopTabs.filter((i) => i.mode.includes('local'));
    //远程(专家端)
    let remoteTab = defaultTopTabs.filter((i) => i.node === 1);

    let initialTab =
      partEnd === 'local' ? tabLocal : partEnd === 'expert' ? remoteTab : defaultTopTabs;

    let secondlist = [];
    if (![0, 5, 6, 7].includes(this.props.currentTab) || partEnd === 'expert') {
      secondlist = config.tabs;
    }
    this.setState({
      TopTabsList: initialTab,
      SecondTabsList: secondlist
    });

    if (config.isCloud) {
      initialTab.forEach((tab) => {
        tab.query = { ...tab.query, uploader: currentUser.username };
      });
    }
    initialTab.forEach((tab) => {
      tab.query =
        'collector' in tab.query && !!currentUser
          ? { ...tab.query, collector: currentUser.username }
          : tab.query;
      tab.query =
        'owner' in tab.query && !!currentUser ? { ...tab.query, owner: currentUser.id } : tab.query;
    });
    this.props.initializeIO();
    this.props.initializeTabs(initialTab).then(() => {
      this.changeTopTab(this.props.currentTab);
    });
    this.props.initializeSecondTabs(secondlist);
    // this.props.updateDoctorList();
    this.props.updateRolesUsers();
    this.props.getRole();
  }

  removeTab = (key, e) => {
    e.stopPropagation();
    const intKey = parseInt(key, 10);
    this.props.removeTab(intKey);
  };

  getTabHeader = (tab, idx) => {
    const {currentTab, counts} = this.props;
    const icon = currentTab === idx ? tab.iconActive : tab.icon;
    const header = tab.origin ? intl.get(tab.text) || tab.text : tab.text;
 
    return (
      <span style={tab.styles ? tab.styles : {}}>
        <Icon type={icon} />
        {header}({counts[tab.typeID]})&nbsp;&nbsp;
        {tab.origin ? (
          ''
        ) : (
          <Icon
            type="close"
            onClick={(e) => {
              this.removeTab(idx, e);
            }}
          />
        )}
      </span>
    );
  };

  changeSecondTabs = (clicked) => {
    const partEnd = isPartEnd();
    let secondlist;
    if (![0].includes(clicked) || partEnd === 'expert') {
      secondlist = config.tabs;
    } else {
      secondlist = [];
    }
    this.setState({
      SecondTabsList: secondlist
    });
    this.props.initializeSecondTabs(secondlist);
    if (secondlist.length > 0) {
      this.props.stoggleSecondTab(5);
    }
  };
  clickTab = (key) => {
    const clicked = parseInt(key, 10);
    this.changeTopTab(key);
    this.changeSecondTabs(clicked);
  };

  changeTopTab = (key) => {
    const clicked = parseInt(key, 10);
    let c = clicked + 1;
    let tabList = document.querySelectorAll('.ant-tabs-nav .ant-tabs-tab');
    tabList.forEach((item) => {
      item.style.backgroundColor = '#fff';
    });
    let tab = document.querySelector('.ant-tabs-nav .ant-tabs-tab:nth-child(' + c + ')');
    const partEnd = isPartEnd();
    switch (clicked) {
      case 0:
        if (tab) {
          //初诊/待分配
          partEnd === 'expert'
            ? (tab.style.backgroundColor = '#78CBE8')
            : (tab.style.backgroundColor = '#7DDCBF');
        }
        break;
      case 1:
        //复核/初诊
        partEnd === 'expert'
          ? (tab.style.backgroundColor = '#AE9EFF')
          : (tab.style.backgroundColor = '#78CBE8');
        break;
      case 2:
        //已诊断/复核
        partEnd === 'expert'
          ? (tab.style.backgroundColor = '#EA96A3')
          : (tab.style.backgroundColor = '#AE9EFF');
        break;
      case 3:
        //超时/已诊断
        partEnd === 'expert'
          ? (tab.style.backgroundColor = '#FC7860')
          : (tab.style.backgroundColor = '#EA96A3');
        break;
      case 4:
        //全部/超时
        partEnd === 'expert'
          ? (tab.style.backgroundColor = '#A3C4F7')
          : (tab.style.backgroundColor = '#FC7860');
        break;
      case 5:
        tab.style.backgroundColor = '#F8CF7C';
        break;
      case 6:
        tab.style.backgroundColor = '#6F9AFB';
        break;
      case 7:
        tab.style.backgroundColor = '#5BAED2';
        break;
      case 8:
        tab.style.backgroundColor = '#A3C4F7';
        break;
      default:
        tab.style.backgroundColor = '#fff';
        break;
    }
    this.props.toggleTab(clicked);
  };

  render() {
    const { currentTab, tabs, secondTabs } = this.props;
    const partEnd = isPartEnd();
    
    return (
      <div
        className={
          partEnd === 'expert'
            ? styles.topTab + ' ' + styles.topTab_expert
            : styles.topTab + ' ' + styles.topTab_all
        }
      >
        <SearchTab />
        <Tabs
          className={styles.topTab_inner}
          onChange={this.clickTab}
          activeKey={currentTab.toString()}
          closable={false}
          defaultActiveKey={currentTab.toString()}
          hideAdd={true}
          type="card"
        >
          {tabs.map((item, idx) => {
            return (
              <TabPane tab={this.getTabHeader(item, idx)} key={idx} closable={!item.origin}>
                <SecondTabs SecondTabsList={secondTabs} />
              </TabPane>
            );
          })}
        </Tabs>
        <GTUpload
          onClose={() => {
            this.changeTopTab(currentTab);
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.loadImages,
    ...state.tabState,
    config: getConfig(state),
    currentUser: getCurrentUser(state),
    general: state.config.general
  };
};
export default connect(mapStateToProps, {
  toggleTab,
  initializeTabs,
  initializeSecondTabs,
  initializeIO,
  removeTab,
  updateDoctorList,
  updateRolesUsers,
  getRole,
  stoggleSecondTab
})(TopTabs);
