/* @flow */
import { message } from 'antd';
import axios from 'axios';
import intl from 'react-intl-universal';
import { toggleLoginModal } from '../actions/authActions';
import config from '../config';
import store from '../store';
import { refresh } from './authUtils';
import { setTimeoutLabel } from '../actions/timeoutAction';
const { baseUrl } = config;
const moniterUrl = [
  '/distribution',
  '/monitor',
  '/positive',
  '/consistency',
  '/workload',
  '/quality',
  '/performance',
  '/incidence'
];
const reportUrl = ['/user/roles', '/configuration/report', '/configuration/general'];
const limitUrl = [
  '/image/count',
  '/user/all',
  '/image',
  '/annotation',
  '/deepzoom/load',
  '/machine',
  ...moniterUrl,
  ...reportUrl
];
const notShowErrorList = [6003,6300, 6301];

const errorList = [
  5650,
  5651,
  5652,
  5653,
  5654,
  5008,
  5011,
  5602,
  5803,
  5701,
  5203,
  5201,
  5501,
  6014,
  6013,
  5012,
  5005,
  6002,
  5707,
  6000,
  5103,
  6019
];
axios.defaults.timeout = 30000; // 30s
axios.defaults.baseURL = baseUrl + '/api';

function isNetworkError(err) {
  return !!err.isAxiosError && !err.response;
}

export function errorMessage(url, error) {
  if (isNetworkError(error)) {
    message.error(intl.get('NETWORK_ERROR'));
    return;
  }

  if (error.code === 'ECONNABORTED') {
    message.error(intl.get('ERROR_URL_TIMEOUT', { url }));
    return;
  }
  if (error.response) {
    const resp = error.response;
    if (resp.status === 403) {
      message.error(intl.get('NO_AUTH_TO_DO'));
      return;
    }
  }
}

export default function request(url, requestParams) {
  const {
    method = 'GET',
    params = {},
    data = undefined,
    timeout = 30000,
    headers = {},
    responseType = ''
  } = requestParams;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method,
      params,
      data,
      timeout,
      headers: {
        ...headers,
        'Content-Type': 'application/json'
      },
      responseType
    })
      .then((response) => {
        if (response) {
          if (response.status < 300 && response.status >= 200) {
            resolve(response);
          } else {
            message.error(intl.get('ERROR_DATA_ERROR'));
          }
        } else {
          resolve(response);
        }
      })
      .catch((error) => {
        if (limitUrl.includes(url)) {
          return reject(error);
        } else {
          errorMessage(url, error);
        }
        reject(error);
      });
  });
}

let isRefreshing = false;
let requests = [];

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const errorConfig = error.config;
    if(error.response){
      if (error.response.status === 401) {
        if (errorConfig.url === '/auth/refresh') {
          store.dispatch(toggleLoginModal(true, intl.get('TOKEN_EXPIRED')));
          store.dispatch(setTimeoutLabel(false));
        }
        if (!isRefreshing) {
          isRefreshing = true;
          return refresh()
            .then((resp) => {
              const token = resp.data.access_token;
              errorConfig.headers['Authorization'] = 'Bearer ' + token; // send request with new auth token
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
              return axios(errorConfig);
            })
            .catch((error) => {
              store.dispatch(toggleLoginModal(true, intl.get('TOKEN_EXPIRED')));
              store.dispatch(setTimeoutLabel(false));
            })
            .finally(() => {
              requests.forEach((cb) => cb(axios.defaults.headers.common['Authorization']));
              requests = [];
              isRefreshing = false;
            });
        } else {
          return new Promise((resolve) => {
            requests.push((token) => {
              errorConfig.headers['Authorization'] = token; // send request with new auth token
              resolve(axios(errorConfig));
            });
          });
        }
      } else if (error.response.status === 502) {
        message.error(intl.get('ERROR_502'));
        return Promise.reject(error);
      } else if (
        error.response.status === 409 &&
        (error.response?.data?.error_code === 6008 || error.response?.data?.error_code === 6015)
      ) {
        store.dispatch(toggleLoginModal(true, intl.get('TOKEN_REVOKED')));
        store.dispatch(setTimeoutLabel(false));
        return Promise.reject(error);
      } else if (
        error.response?.status === 404 &&
        errorConfig.url === '/auth/refresh'
      ) {
        store.dispatch(toggleLoginModal(true, intl.get('TOKEN_EXPIRED')));
        store.dispatch(setTimeoutLabel(false));
        return Promise.reject(error);
      } else if( errorList.includes(error.response?.data?.error_code)){
        message.error(intl.get('ERROR_' + error.response?.data?.error_code));
        return Promise.reject(error);
      }else if(!notShowErrorList.includes(error.response?.data?.error_code)){
        message.error(error.response.statusText + "\t" + error.request.responseURL, 3);
        return Promise.reject(error);
      }
    }




    if (error.config && error.config.url.includes('refresh')) {
      return Promise.reject(error);
    }
    // if (error.message.includes('timeout')) {
    //   message.error(intl.get('ERROR_NETWORK_TIMEOUT'));
    //   return Promise.reject(error);
    // }
    return Promise.reject(error);
  }
);

export function error_code(code) {
  store.dispatch(toggleLoginModal(true, intl.get('ERROR_' + code)));
  store.dispatch(setTimeoutLabel(false));
}

export function getBaseUrlHost() {
  const { baseUrl } = config;
  const r = /https?:\/\/(.*)/;
  try {
    return r.exec(baseUrl)[1];
  } catch (e) {
    return r.exec(window.origin)[1];
  }
}
