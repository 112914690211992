import { getTimeoutList, getNotNoteTimeoutList } from '../selectors/imageSelector';
import { getCurrentUser } from '../selectors/userSelectors';
import store from '../store';
import rawToImageData from '../utils/dataUtils';
import { getImages, apiNoteImage } from './api';
import { cloneDeep } from 'lodash';
import { clean } from '../static/defaultQueryFields';
const timeoutQuery = {
  // distribute_stage: '4,5',
  // image_level: '',
  // registerFrom: '',
  // registerTo: '',
  // limit: 100
};
export const updateComputedTime = (data) => ({
  type: 'UPDATE_COMPUTED_TIME',
  data: data
});

export const setTimeoutList = (data) => ({
  type: 'SET_TIMEOUT_LIST',
  data: data
});
export const setPreTimeoutList = (data) => ({
  type: 'SET_PRE_TIMEOUT_LIST',
  data: data
});
export const setNotNoteTimeoutList = (data) => ({
  type: 'SET_NOT_NOTE_TIMEOUT_LIST',
  data: data
});

export const setNotReadTimeoutList = (data) => ({
  type: 'SET_NOT_READ_TIMEOUT_LIST',
  data: data
});
export const clearTimeout = () => ({
  type: 'CLEAR_TIMEOUT'
});
export const setTimeoutLabel = (data) => ({
  type: 'SET_INIT_LABEL',
  data: data
});

export function initTimeoutImage() {
  const user = getCurrentUser(store.getState());
  let jr = { owner: user.id };
  return function(dispatch) {
    return getImages({
      ...clean,
      ...timeoutQuery,
      ...jr
    })
      .then((response) => {
        if (response && response.data) {
          const dataSource = rawToImageData(response.data);
          dispatch(setPreTimeoutList(dataSource));
          dispatch(setTimeoutList(dataSource));
          dispatch(setNotRead(response.data));
          dispatch(setNotNoteTimeoutList({ data: [], total: 0 }));
          dispatch(setTimeoutLabel(true));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
function setNotRead(data) {
  return function(dispatch) {
    let notreads = cloneDeep(data);
    let notRead = [];
    data.items.forEach((item) => {
      if (item.distribute_stage === 4) {
        notRead.push(item);
      }
    });

    notreads.items = notRead;
    notreads.total = notRead.length;
    const notreadsList = rawToImageData(notreads);
    dispatch(setNotReadTimeoutList(notreadsList));
  };
}

export function fetchTimeoutImage() {
  const user = getCurrentUser(store.getState());
  let jr = { owner: user.id };
  const timeoutList = getTimeoutList(store.getState());
  return function(dispatch) {
    return getImages({
      ...clean,
      ...timeoutQuery,
      ...jr
    })
      .then((response) => {
        if (response && response.data) {
          let ids = response.data.items.map((i) => i.id);
          let preIds = timeoutList.data.map((i) => i.id);
          let newIds = [];
          ids.forEach((item) => {
            if (!preIds.includes(item)) {
              newIds.push(item);
            }
          });
          let NotNote = cloneDeep(response.data);
          let items = [];
          if (newIds.length !== 0) {
            response.data.items.forEach((item) => {
              newIds.forEach((i) => {
                if (item.id === i && item.distribute_stage === 4) {
                  items.push(item);
                }
              });
            });
          }

          NotNote.items = items;
          NotNote.total = items.length;
          dispatch(setPreTimeoutList(timeoutList));
          const dataSource = rawToImageData(response.data);
          dispatch(setTimeoutList(dataSource));
          const notNotes = rawToImageData(NotNote);
          dispatch(setNotNoteTimeoutList(notNotes));
          dispatch(setNotRead(response.data));
          //   let init={
          //     data:[],
          //     total:0
          //   }
          //   dispatch(setPreTimeoutList(init));
          //   dispatch(setTimeoutList(init));
          //  dispatch(setNotNoteTimeoutList(init));
          //  dispatch(setNotReadTimeoutList(init));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
export function updateNotNoteById(id, action) {
  const NotNoteTimeoutList = getNotNoteTimeoutList(store.getState());
  let item = NotNoteTimeoutList.data.filter((i) => i.id !== id);
  return function(dispatch) {
    if (NotNoteTimeoutList.data.length === 1) {
      dispatch(fetchTimeoutImage());
    } else {
      dispatch(setNotNoteTimeoutList({ data: item, total: item.length }));
      if (action === 'read') {
        dispatch(setNotReadTimeoutList({ data: item, total: item.length }));
      }
    }
  };
}
export function updateNotNote() {
  return function(dispatch) {
    dispatch(setNotNoteTimeoutList({ data: [], total: 0 }));
    dispatch(fetchTimeoutImage());
  };
}

export function readsingleImageById(id) {
  return function(dispatch) {
    return apiNoteImage(id).then((response) => {
      if (response) {
        dispatch(fetchTimeoutImage());
      }
    });
  };
}

export function readsingleImage(data, path) {
  return function(dispatch) {
    _readsingleImage(dispatch, data, path);
  };
}

function _readsingleImage(dispatch, data, path) {
  const promises = [];
  data.forEach((item, i) => {
    promises.push(apiNoteImage(item.id));
  });
  console.log(data);
  Promise.all(promises)
    .then(() => {
      if (path === 'panel') {
        dispatch(updateNotNote());
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
