import React, { Component } from 'react';
import { Checkbox } from 'antd';
import { connect } from 'react-redux';
import { toggleAnnotationUserVisibility, getUserInfoAsync } from '../../actions/userActions';
import intl from 'react-intl-universal'
import ImageLevelResultTag from '../ImageLevelResultTag';
class UserCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user
    };
  }

  componentDidMount() {
    this.props.getUserInfoAsync(this.props.user);
  }

  render() {
    const {imageLevelResult} = this.props
    const u = this.props.user;
    const info =
      this.props.infos &&
      this.props.infos.find((info) => {
        return info.username === this.props.user;
      });
    let text =  u
    if(info){
      text = info.realname
    }
    return (
      <Checkbox
        defaultChecked={true}
        style={{ padding: '0px', margin: "0px" }}
        checked={this.props.annotationShowUsers && this.props.annotationShowUsers.indexOf(u) > -1}
        onChange={(e) => {
          this.props.toggleAnnotationUserVisibility(u);
        }}
      >
        { text&&text.includes('ai_')?intl.get("AI_JUDGE"):text }
        <span style={{color:"#1890ff"}}>
          {text&&text.includes('ai_')?<> ：<ImageLevelResultTag result={imageLevelResult} shouldBefiltered={true} /></>:""}
        </span>
      </Checkbox>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    annotationShowUsers: state.annotationUsers.annotationShowUsers,
    infos: state.annotationUsers.infos
  };
};

export default connect(mapStateToProps, { toggleAnnotationUserVisibility, getUserInfoAsync })(
  UserCheckbox
);
