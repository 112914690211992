import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, DatePicker, Spin, } from 'antd';
import config from '../../config'
import { getDiskUsage } from '../../actions/devopsActions'
import intl from 'react-intl-universal';
import moment from 'moment';
import { setStartDate } from '../../utils/dateUtils';
import { cleanDisk } from '../../actions/api';
const { RangePicker } = DatePicker;

class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
  }

  componentDidMount(){
    this.props.getDiskUsage('wsi')
  }

  disabledDate(current) {
    return setStartDate(current);
  }

  changeDate(value, dateString) {
    const dateFromTo = {
      registerFrom: dateString[0],
      registerTo: dateString[1]
    };
    this.setState({dateFromTo})
  }

  render() { 
    // console.log("--this.props", this.props.left , config.warningStorageInGB)
    return (
      <div style={{marginRight:"10px"}}>
        {this.props.status === 1 ? <Spin tip={intl.get('DISK_CLEAN')+"..."} /> :<Modal
          title={intl.get('DISK_USAGE_WARNING')}
          visible={this.props.left < config.warningStorageInGB && this.state.visible}    
          okText={intl.get('DISK_CLEAN')}
          cancelText={intl.get('CANCEL')}
          onOk={()=>{
            cleanDisk({...this.state.dateFromTo, clazz: 'cervical'})
            this.setState({visible:false})
          }}
          onCancel={()=>{
            this.setState({visible:false})
          }} >
          
          <p>{intl.get('DISK_CLEAN_INFO')}</p>
          <RangePicker
            disabledDate={this.disabledDate.bind(this)}
            showTime={{
              format: 'HH:mm',
              defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')]
            }}
            // value={this.state.dateFromTo}
            onChange={this.changeDate.bind(this)}
            style={{ width: '100%' }}
          />
        </Modal>}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  percent: state.devops.percent,
  left: state.devops.left,
  status: state.devops.status,
});

export default connect(mapStateToProps,{getDiskUsage})(Messages);
