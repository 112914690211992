import { getUpAnnotation, getDownAnnotation, getLeftAnnotation, getRightAnnotation } from '../selectors/annotationSelectors'
import store from '../store';

export const setHoveredAnnotationId = (id) => ({
  type: 'SET_HOVERED_ANNOTATION_ID',
  id
});

export const setCurrentAnnotationId = (id) => ({
  type: 'SET_CURRENT_ANNOTATION_ID',
  id
});

export const addReportAnnotationIds = (ids) => ({
  type: 'ADD_REPORT_ANNOTATION_IDS',
  ids
});

export const removeReportAnnotationId = (id) => ({
  type: 'REMOVE_REPORT_ANNOTATION_ID',
  id
});

export function updateUpAnnotation() {
  return function (dispatch) {
    const up = getUpAnnotation(store.getState())
    up && dispatch(setCurrentAnnotationId(up.id))
  }
}

export function updateDownAnnotation() {
  return function (dispatch) {
    const down = getDownAnnotation(store.getState())
    down && dispatch(setCurrentAnnotationId(down.id))
  }
}

export function updateLeftAnnotation() {
  return function (dispatch) {
    const left = getLeftAnnotation(store.getState())
    left && dispatch(setCurrentAnnotationId(left.id))
  }
}

export function updateRightAnnotation() {
  return function (dispatch) {
    const right = getRightAnnotation(store.getState())
    right && dispatch(setCurrentAnnotationId(right.id))
  }
}